import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "test-component-1",
    ref: "testComponent1",
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => _cache[4] || (_cache[4] = [
      _createTextVNode(" Test Component 1 ")
    ])),
    body: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" loading ")
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("legend", { class: "sr-only" }, [
              _createElementVNode("h4", { class: "legend-title" }, " test component 1 ")
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_select_input, {
                  selectId: "referralInformation-sex",
                  name: "Sex",
                  modelValue: _ctx.editState.sex,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.sex) = $event)),
                  options: _ctx.sexOptions
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_select_input, {
                  selectId: "referralInformation-ethnicity",
                  name: "Ethnicity",
                  modelValue: _ctx.editState.ethnicity,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.ethnicity) = $event)),
                  options: _ctx.ethnicityLookup
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_select_input, {
                  selectId: "referralInformation-country",
                  name: "Country",
                  modelValue: _ctx.editState.country,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.country) = $event)),
                  options: _ctx.countryLookup
                }, null, 8, ["modelValue", "options"])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["lookups-to-load"]))
}