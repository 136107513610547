import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "container login-container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-sm-12 col-md-10 col-lg-7 col-xl-6" }
const _hoisted_4 = { class: "nav-top" }
const _hoisted_5 = { class: "my-4 brand py-2" }
const _hoisted_6 = {
  href: "/",
  class: "app-logo-login"
}
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  class: "brand-title d-none d-md-flex",
  id: "app-short-name"
}
const _hoisted_9 = {
  class: "brand-title d-xs-flex d-md-none",
  id: "app-long-name"
}
const _hoisted_10 = { class: "card shadow-none" }
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = { class: "login-title" }
const _hoisted_13 = { class: "system-title d-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, [
              (_ctx.getLogo)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.getLogo,
                    alt: _ctx.$t('application_short_name')
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('application_long_name')), 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('application_short_name')), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t('log_in')), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.uiEnvVersion), 1),
            _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
            _renderSlot(_ctx.$slots, "content")
          ])
        ])
      ])
    ])
  ]))
}