// Master list of prototypes
export enum PROTOTYPES {
  ChecklistReorder = 'proto_checklists_reorder',
  CommunicationInformation = 'proto_communication_information',
  TransportationAndMobility = 'proto_transportation_and_mobility',
  PaceAndEngagement = 'proto_pace_and_engagement',
  PrioritizationReport = 'proto_prioritization_report',
  SchedulingPreferences = 'proto_scheduling_preferences',
  ProtoRecipientsListings = 'proto_recipient_listings',
  CareGivers = 'proto_care_givers',
  LiverSpecificDetails = 'proto_liver_specific_details',
  LiverSodiumMeld = 'proto_liver_sodium_meld',
  ShowBloodType = 'proto_blood_type',
  KidneySpecificDetails = 'proto_kidney_specific_details',
  NotesMultipleAutocomplete = "proto_notes_multiple_autocomplete",
  HeartSpecificDetails = 'proto_heart_specific_details',
  LiverInformation = 'proto_liver_information',
  KidneyInformation = 'proto_kidney_information',
  KidneyLivingDonation = 'proto_kidney_living_donation',
  HeartCardiacCatheterization = 'proto_heart_cardiac_catheterization',
  HeartEchoResult = 'proto_heart_echo_results',
  PatientHistory = 'proto_patient_history',
  MedicalHistory = 'proto_medical_history',
  SurgicalHistory = 'proto_surgical_history',
  SocialHistory = 'proto_social_history',
  ProtoLivingDonorDemographics = 'proto_living_donor_demographics',
  ProtoOffers = 'proto_offers',
  ProtoCurrentOffers = "proto_current_offers",
  ProtoOffersHistory = "proto_offers_history",
  ProtoOffersStatistics = "proto_offers_statistics",
  HeartMyocardialPerfusion = 'proto_heart_myocardial_perfusion',
  SmallBowelSpecificDetails = "proto_small_bowel_specific_details",
  SmallBowelInformation = "proto_small_bowel_information",
  OtherCardiacCirculatorySupport = "proto_other_cardiac_circulatory_support",
  ProtoLDContactInformation = "proto_living_donor_contact_information",
  ProtoLDPatientContactDetails = "proto_living_donor_patient_contact_details",
  ProtoLDPatientAddress = "proto_living_donor_patient_address",
  HeartVadLvad = "proto_heart_vad_lvad"
}
