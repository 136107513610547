import i18n from '@/i18n';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response, ListFinalDecisions, FinalDecision } from '@/store/decisions/types';

// Getters
export const getters: GetterTree<DecisionsState, RootState> = {
  showDecision(state): Decision | undefined {
    return state.selectedDecision;
  },
  showAttachment(state): Attachment | undefined {
    return state.selectedAttachment;
  },
  showResponse(state): Response | undefined {
    return state.selectedResponse;
  },
  showFinalResponse(state): Response | undefined {
    return state.selectedResponse;
  },
  selectedDecisionId(state): string | null {
    const id = state.selectedDecision && state.selectedDecision._id && state.selectedDecision._id;
    return id || null;
  },
  getExemptions(state): Decision[] {
    return state.listDecisions || [];
  },
  getAttachments(state): Attachment[] {
    return state.listAttachments || [];
  },
  getResponses(state): any {
    return state.listResponses && state.listResponses.entries ? state.listResponses.entries : [];
  },
  getFinalDecisions(state): FinalDecision[] {
    return state.listFinalDecisions && state.listFinalDecisions.entries ? state.listFinalDecisions.entries : [];
  }
};
