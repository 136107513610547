import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = {
  key: 1,
  class: "row"
}
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = {
  key: 1,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_field = _resolveComponent("loading-field")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_loading_row = _resolveComponent("loading-row")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, { "form-id": "proto-ld-patient-address-form" }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.selection.isNew ? _ctx.$t('new_patient_address_heading') : _ctx.$t('selected_patient_address_heading')), 1)
          ])
        ]),
        contents: _withCtx(() => [
          (_ctx.isLoadingForm)
            ? (_openBlock(), _createBlock(_component_loading_field, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_select_input, {
                    rules: "required",
                    "select-id": "proto-ld-address-category",
                    name: _ctx.$t('address_field.category'),
                    options: _ctx.categoryOptions,
                    modelValue: _ctx.editState.categoryType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.categoryType) = $event)),
                    onChange: _ctx.handleCategoryChanged
                  }, null, 8, ["name", "options", "modelValue", "onChange"])
                ])
              ])),
          (_ctx.editState.categoryType && _ctx.isLoadingSubForm)
            ? (_openBlock(), _createBlock(_component_loading_row, { key: 2 }))
            : (_ctx.editState.categoryType)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  (_ctx.addressFormatComponentName)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.addressFormatComponentName), {
                        key: 0,
                        ref: "ProtoLDAddressFormatComponent",
                        editState: _ctx.editState,
                        onCountryChanged: _ctx.handleCountryChanged
                      }, null, 40, ["editState", "onCountryChanged"]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_4, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'exclamation-circle'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('address_error.missing_format_configuration', { formatCode: _ctx.selectedCategoryFormatCode })), 1)
                      ]))
                ], 64))
              : _createCommentVNode("", true)
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.showSaveToolbar,
            ref: "saveProtoLDPatientAddress",
            label: _ctx.$t('save_patient_address'),
            cancelButton: true,
            onSave: _ctx.performSave,
            onCancel: _ctx.handleCancel
          }, null, 8, ["show", "label", "onSave", "onCancel"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}