<template>
  <sticky-summary>
    <template v-slot:summaryContent v-if="editState">
      <ul class="donor-summary">
        <li class="list-inline-item">
          <span class="patient-name">{{$t('name')}}: <b>{{deceasedDonorDisplayName}}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('deceased_donor_id')}}: <b>{{ donor.deceased_donor_id || '-' }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('hospital')}}: <b>{{ editState.referral_hospital }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('nat_short_ID')}}: <b>{{ editState.national_donor_id ? editState.national_donor_id : '-'}}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('abo')}}:  <b>{{ editState.abo }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('sex')}}: <b>{{ editState.sex }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('age')}}: <b>{{ editState.age }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('height')}}: <b>{{ editState.height }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('weight')}}: <b>{{ editState.weight }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('exd')}}: <b>{{ editState.exceptional_distribution }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('donor_type')}}: <b>{{ editState.donor_type }}</b></span>
        </li>
        <li class="list-inline-item">
          <router-link :to="{ name: 'edit-deceased-donor', params: { id: donor.client_id }}" class="nav-link">{{$t('donor_information')}}</router-link>
        </li>
        <li class="list-inline-item">
           <router-link :to="{ name: 'edit-deceased-donor', params: { id: donor.client_id }, hash: '#hla-typing' }" class="nav-link">{{$t('hla_information')}}</router-link>
        </li>
        <li class="list-inline-item">
          <div class="dropdown">
           <router-link :to="{}" class="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{$t('allocation_reports')}}</router-link>
          </div>
        </li>
        <li class="list-inline-item" v-if="canAccessNowList">
          <div class="dropdown">
            <a @click="openNowModal()" class="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{$t('now_list')}}</a>
          </div>
        </li>
      </ul>
      <ul class="organ-summary">
        <li v-for="(consentedOrgan, idx) in selectedDonorConsentedOrganList" :key="idx" :class="listItemClass(consentedOrgan)">
          <router-link :to="consentedOrgan.route" class="nav-link">
            <organ-icon :organ-code="consentedOrgan.organ_code" :summary-bar-icon="true" />
            {{$t(`${consentedOrgan.display_name}`) }}
          </router-link>
        </li>
      </ul>
    </template>
  </sticky-summary>
</template>

<i18n src="./_locales/commonPatientShared.json"></i18n>
<i18n src="../_locales/Organs.json"></i18n>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import StickySummary from '@/components/shared/StickySummary.vue';
import { Getter, State } from 'vuex-facing-decorator';
import { DeceasedDonor, DeceasedDonorOrgan, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { Hospital } from '@/store/hospitals/types';
import { isMasked, mergeClasses } from '@/utils';
import { Allocations } from '@/store/allocations/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { ClassObject } from '@/types';

interface DonorStickySummaryForm {
  national_donor_id?: string;
  donor_name?: string;
  sex?: string;
  age?: number;
  abo?: string|null;
  donor_type?: string;
  height?: number;
  weight?: number
  cause_of_death?: string;
  region?: string;
  serology?: string;
  referral_hospital?: string|undefined;
  exceptional_distribution?: string;
}

@Component({
  components: {
    OrganIcon,
    StickySummary
  }
})
export default class DonorStickySummary extends Vue {
  @Prop({ default: null }) openNowModal!: any; // Now Modal

  @State(state => state.deceasedDonors.selected) donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.donorStickySummary) editState!: DonorStickySummaryForm;
  @State(state => state.allocations.isLoadingAllocations) private isLoadingAllocations!: boolean;

  // Getters
  @Getter('deceasedDonorDisplayName', { namespace: 'deceasedDonors' }) deceasedDonorDisplayName!: string;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('getHospitalById', { namespace: 'hospitals' }) getHospitalById!: (hospitalCode?: string|null) => Hospital|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];
  @Getter('canAccessNowList', { namespace: 'users' }) private canAccessNowList!: boolean;

  @Prop({ default: 'list-inline-item' }) inputClass!: string; // Class string for list

  /**
   * Initializes the summary form state after donor events, lookups, and all subsections have finished loading/mounting
   *
   * Emits a loaded event to notify its page layout that loading of this card section has completed
   *
   * @emits loaded
   */
  private mounted(): void {
    Promise.all([
      this.$store.dispatch('hospitals/load')
    ]).finally(() => {
      this.initializeForm();
    });
  }


  @Watch('donor', { immediate: true, deep: true })
  private onDeceasedDonorChanged(): void {
    this.initializeForm();
  }

  //Getter - Selected organ shown as active
  get listItemClass(){
    return (consentedOrgan: any): ClassObject => {
      const option = this.$route.params.option;
      const organCode = this.$route.params.organ_code;
      const consentedOrganRoute = consentedOrgan.route.params.option;
      const consentedOrganCode = consentedOrgan.organ_code;
      let isActive : any;

      if(consentedOrgan.route.params.option)
      {
        isActive = consentedOrgan.display_name.toLowerCase().includes(option) && organCode == consentedOrganCode.toString();
      } else {
        isActive = organCode == consentedOrganCode.toString();
      }
      //Define dynamic class based on the selected organ
      const conditionalClass = {
        'active' : isActive
      };
      return mergeClasses(this.inputClass, conditionalClass);
    };
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'donorStickySummary', // assign unique state identifier
      value: this.buildDonorStickySummary(this.donor)
    });
  }

  public buildDonorStickySummary(donor?: DeceasedDonor): DonorStickySummaryForm {
    // Return empty object if there is no data document
    if (!donor) {
      return {};
    }

     // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const patientProfile = donor.patient_profile || {};
    const referral = donor.referral || {};
    const blood = donor.blood || {};
    const donorMeasurements = donor.measurements || [];
    const death = donor.death || {};
    const indicators = donor.indicators || {};

    const addresses = patientProfile.addresses || {};
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[donorMeasurements.length-1] : {};

    const hospitalId = referral.referring_hospital_id ? referral.referring_hospital_id : undefined;
    const referral_hospital = isMasked(hospitalId) ? hospitalId : this.getHospitalAbbreviation(hospitalId);

    // Return parameters extracted from data document based on structure of form state interface
    return {
      national_donor_id: patientProfile && patientProfile.ctr ? patientProfile.ctr!.national_donor_id : undefined,
      donor_name: patientProfile.first_name && patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      age: patientProfile.age,
      abo: blood.sub_type ? blood.sub_type : blood.type,
      donor_type: death?.neurological_death ? "NDD" : "DCD",
      height: measurement.height,
      weight: measurement.weight,
      cause_of_death: this.lookupValue(death.cause_of_death_code?.toString(), 'cause_of_death_donor'),
      referral_hospital: referral_hospital || undefined,
      exceptional_distribution: indicators?.exceptional_distribution ? "YES" : "NO"
    };
  }
}
</script>
