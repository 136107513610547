import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card summary-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "summary-name" }
const _hoisted_7 = { class: "nav card-nav" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "row summary-list" }
const _hoisted_12 = { class: "shortitem" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "summary-items" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "summary-organ" }
const _hoisted_17 = { class: "organ-summary-wrap" }
const _hoisted_18 = ["title"]
const _hoisted_19 = ["title"]
const _hoisted_20 = ["title"]
const _hoisted_21 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_organ_icon = _resolveComponent("organ-icon")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, { "sub-section-id": "summary" }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'id-card'],
                      "fixed-width": ""
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.livingDonorDisplayName), 1),
                    _createElementVNode("ul", _hoisted_7, [
                      _createElementVNode("li", _hoisted_8, [
                        _createVNode(_component_router_link, {
                          to: { name: 'edit-living-donor', params: { id: _ctx.livingDonor.client_id } },
                          class: "nav-link"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('living_donor_information')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _createElementVNode("li", _hoisted_9, [
                        _createVNode(_component_router_link, {
                          to: { name: 'edit-living-donor', params: { id: _ctx.livingDonor.client_id }, hash: '#hla-typing' },
                          class: "nav-link"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('hla_information')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('date_of_birth')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.date_of_birth), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('living_donor_id')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.living_donor_id), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('sex')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.sex), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('age')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.age), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('height')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.height), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('weight')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.weight), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('insurance')) + " #", 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.insurance_number), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('virology_results')), 1),
                    _createVNode(_component_router_link, {
                      to: { name: 'edit-living-donor', params: { id: _ctx.editState.client_id }, hash: '#virology' }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("[" + _toDisplayString(_ctx.$t('view')) + "]", 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _createElementVNode("dl", _hoisted_12, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('abo')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.abo_sub_type || _ctx.editState.abo), 1)
                  ]),
                  (_ctx.editState.transplant_program)
                    ? (_openBlock(), _createElementBlock("dl", _hoisted_13, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('referral_hospital')), 1),
                        _createElementVNode("dd", null, _toDisplayString(_ctx.editState.transplant_program), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedLivingDonorConsentedOrganList, (consentedOrgan, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "summary-journey-item summary-item",
                        key: idx
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.listItemClass(consentedOrgan))
                        }, [
                          _createVNode(_component_router_link, {
                            to: consentedOrgan.route,
                            class: "organ-row"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_organ_icon, {
                                "organ-code": consentedOrgan.organ_code
                              }, null, 8, ["organ-code"]),
                              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t(`${consentedOrgan.display_name}`)), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]),
                          _createElementVNode("div", _hoisted_17, [
                            (_ctx.isLoadingAllocations)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "skeleton-box w-50" }, null, -1)),
                                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "skeleton-box w-100" }, null, -1))
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (consentedOrgan.allocationId)
                                    ? (_openBlock(), _createElementBlock("ul", {
                                        key: 0,
                                        class: "organ-summary-list",
                                        title: _ctx.$t('allocation_id')
                                      }, [
                                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('allocation_id')) + " " + _toDisplayString(consentedOrgan.allocationId), 1)
                                      ], 8, _hoisted_18))
                                    : _createCommentVNode("", true),
                                  (consentedOrgan.allocationId)
                                    ? (_openBlock(), _createElementBlock("ul", {
                                        key: 1,
                                        class: "organ-summary-list",
                                        title: _ctx.$t('allocation_run_datetime')
                                      }, [
                                        _createElementVNode("li", null, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['far', 'clock'],
                                            "fixed-width": ""
                                          }),
                                          _createTextVNode(" " + _toDisplayString(_ctx.formatDateTime(consentedOrgan.allocationRunDateTime)), 1)
                                        ])
                                      ], 8, _hoisted_19))
                                    : _createCommentVNode("", true),
                                  (consentedOrgan.allocationId)
                                    ? (_openBlock(), _createElementBlock("ul", {
                                        key: 2,
                                        class: "organ-summary-list",
                                        title: _ctx.$t('allocation_stage_field')
                                      }, [
                                        _createElementVNode("li", null, _toDisplayString(_ctx.$t(consentedOrgan.allocationStage)), 1)
                                      ], 8, _hoisted_20))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("ul", {
                                    class: "organ-summary-list",
                                    title: _ctx.$t('consented_date_label')
                                  }, [
                                    _createElementVNode("li", null, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: ['far', 'clock'],
                                        "fixed-width": ""
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_ctx.formatDate(consentedOrgan.consented_date)), 1)
                                    ])
                                  ], 8, _hoisted_21)
                                ], 64))
                          ])
                        ], 2)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}