import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "filter-section-action-row" }
const _hoisted_3 = { class: "filter-section-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_5 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-12" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-12" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-12" }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "col-12" }
const _hoisted_30 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_action_toolbar = _resolveComponent("action-toolbar")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoCareGiversEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_care_givers" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_sub_section, {
              "sub-section-id": "proto-care-givers-section",
              title: "CARE GIVERS"
            }, {
              contents: _withCtx(() => [
                _createVNode(_component_table_toolbar, {
                  createButton: true,
                  createText: "Create Care Giver",
                  onTableCreateRow: _ctx.clearForm
                }, {
                  "button-bar": _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_filter_component, {
                          fieldID: "contactFilter",
                          showFilter: true,
                          showArchived: true,
                          onSetFilters: _ctx.handleSetFilters
                        }, null, 8, ["onSetFilters"])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["onTableCreateRow"]),
                _createVNode(_component_table_list, {
                  ref: "careGiversTable",
                  tabbableColumn: "lastUpdatedDisplay",
                  "table-id": "care-givers-table",
                  "table-config": _ctx.careGiversTableConfig,
                  onTableRowClick: _ctx.editSelected,
                  highlightSelection: true,
                  rowStyleClass: _ctx.rowStyleClass
                }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
                _createElementVNode("form", null, [
                  _createVNode(_component_form_layout, { "form-id": "recipientCareGiver-form" }, {
                    title: _withCtx(() => [
                      _createElementVNode("legend", null, [
                        (_ctx.isNew)
                          ? (_openBlock(), _createElementBlock("h5", _hoisted_4, " NEW CARE GIVER "))
                          : (_openBlock(), _createElementBlock("h5", _hoisted_5, " EDIT CARE GIVER "))
                      ])
                    ]),
                    action: _withCtx(() => [
                      _createVNode(_component_action_toolbar, {
                        ref: "careGiversActionToolbar",
                        permittedActions: ['create', 'update'],
                        isArchiving: _ctx.isArchiving,
                        isRestoring: _ctx.isRestoring,
                        loading: _ctx.isLoadingActions
                      }, null, 8, ["isArchiving", "isRestoring", "loading"])
                    ]),
                    contents: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_text_input, {
                            rules: "required",
                            inputId: "careGiver-name",
                            name: "Name",
                            modelValue: _ctx.editState.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.name) = $event)),
                            disabled: _ctx.editState.status == 'archived'
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_text_input, {
                            rules: "required",
                            inputId: "careGiver-relationship",
                            name: "Relationship",
                            modelValue: _ctx.editState.relationship,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.relationship) = $event)),
                            disabled: _ctx.editState.status == 'archived'
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_checkbox_input, {
                            name: "Primary Care Giver?",
                            labelName: "Primary Care Giver?",
                            inputId: "careGiver-primary-care",
                            modelValue: _ctx.editState.isPrimary,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.isPrimary) = $event)),
                            label: "Yes",
                            disabled: _ctx.editState.status == 'archived'
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_text_input, {
                            rules: "required",
                            inputId: "careGiver-location",
                            name: "Location",
                            modelValue: _ctx.editState.location,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.location) = $event)),
                            disabled: _ctx.editState.status == 'archived'
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[15] || (_cache[15] = _createElementVNode("label", null, "Consented For", -1)),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_checkbox_input, {
                                name: "consent-appointments",
                                hideLabel: "true",
                                labelName: "Appointments",
                                inputId: "consent-appointments",
                                modelValue: _ctx.editState.consentAppointments,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.consentAppointments) = $event)),
                                label: "Appointments",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_checkbox_input, {
                                name: "consent-phi",
                                hideLabel: "true",
                                labelName: "PHI",
                                inputId: "consent-phi",
                                modelValue: _ctx.editState.consentPHI,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.consentPHI) = $event)),
                                label: "PHI",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", null, "Responsibility", -1)),
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_checkbox_input, {
                                name: "responsibility-finances",
                                hideLabel: "true",
                                labelName: "Budgeting / Finances",
                                inputId: "responsibility-finances",
                                modelValue: _ctx.editState.responsibilityFinances,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.responsibilityFinances) = $event)),
                                label: "Budgeting / Finances",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              _createVNode(_component_checkbox_input, {
                                name: "responsibility-reminders",
                                hideLabel: "true",
                                labelName: "Calendaring / Reminders",
                                inputId: "responsibility-reminders",
                                modelValue: _ctx.editState.responsibilityReminders,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.responsibilityReminders) = $event)),
                                label: "Calendaring / Reminders",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(_component_checkbox_input, {
                                name: "responsibility-living-donor",
                                hideLabel: "true",
                                labelName: "Living Donor",
                                inputId: "responsibility-living-donor",
                                modelValue: _ctx.editState.responsibilityLivingDonor,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.responsibilityLivingDonor) = $event)),
                                label: "Living Donor",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_component_checkbox_input, {
                                name: "responsibility-medication",
                                hideLabel: "true",
                                labelName: "Medication",
                                inputId: "responsibility-medication",
                                modelValue: _ctx.editState.responsibilityMedication,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.responsibilityMedication) = $event)),
                                label: "Medication",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_checkbox_input, {
                                name: "responsibility-transportation",
                                hideLabel: "true",
                                labelName: "Transportation",
                                inputId: "responsibility-transportation",
                                modelValue: _ctx.editState.responsibilityTransportation,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.responsibilityTransportation) = $event)),
                                label: "Transportation",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, [
                              _createVNode(_component_checkbox_input, {
                                name: "responsibility-decision-making",
                                hideLabel: "true",
                                labelName: "Understanding / Decision Making",
                                inputId: "responsibility-decision-making",
                                modelValue: _ctx.editState.responsibilityDecision,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.responsibilityDecision) = $event)),
                                label: "Understanding / Decision Making",
                                disabled: _ctx.editState.status == 'archived'
                              }, null, 8, ["modelValue", "disabled"])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_text_area_input, {
                            "input-id": "careGiver-comments",
                            name: "Comments",
                            rows: "4",
                            modelValue: _ctx.editState.comments,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.comments) = $event)),
                            disabled: _ctx.editState.status == 'archived'
                          }, null, 8, ["modelValue", "disabled"])
                        ])
                      ])
                    ]),
                    save: _withCtx(() => [
                      _createVNode(_component_save_toolbar, {
                        show: true,
                        ref: "saveCareGiver",
                        label: "Save Care Giver Information",
                        cancelButton: true,
                        onSave: _cache[13] || (_cache[13] = ($event: any) => (_ctx.performSave())),
                        onCancel: _cache[14] || (_cache[14] = ($event: any) => (_ctx.clearForm()))
                      }, null, 512)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}