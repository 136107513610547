import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav-wrapper" }
const _hoisted_4 = { class: "page-content" }
const _hoisted_5 = { class: "content-wrap" }
const _hoisted_6 = { class: "container-fluid" }
const _hoisted_7 = { class: "nav-wrapper" }
const _hoisted_8 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_side_nav = _resolveComponent("loading-side-nav")!
  const _component_loading_donor_page = _resolveComponent("loading-donor-page")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_living_donor_sticky_summary = _resolveComponent("living-donor-sticky-summary")!
  const _component_side_nav_living_donor_organ = _resolveComponent("side-nav-living-donor-organ")!
  const _component_living_donor_summary = _resolveComponent("living-donor-summary")!
  const _component_living_donor_offers = _resolveComponent("living-donor-offers")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.livingDonor)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", {
                class: "skeleton-box",
                style: {"width":"200px"}
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_loading_side_nav),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_loading_donor_page)
                ])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'list-living-donors' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("living_donors")), 1)
                ]),
                _: 1
              }),
              _cache[2] || (_cache[2] = _createTextVNode(" / ")),
              _createVNode(_component_router_link, {
                to: { name: 'edit-living-donor', params: { id: _ctx.clientId } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.livingDonorDisplayName), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" / " + _toDisplayString(_ctx.livingDonorOrganName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_living_donor_sticky_summary),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_side_nav_living_donor_organ),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_living_donor_summary, {
                    ref: "livingDonorSummary",
                    onLoaded: _ctx.loaded
                  }, null, 8, ["onLoaded"]),
                  _createVNode(_component_living_donor_offers, {
                    ref: "allocations",
                    onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reloadSummary())),
                    onLoaded: _ctx.loaded
                  }, null, 8, ["onLoaded"])
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}