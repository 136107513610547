import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "form-group col-sm-6" }
const _hoisted_4 = { for: "recipient-client-id" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text" }
const _hoisted_7 = {
  key: 0,
  class: "hint"
}
const _hoisted_8 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_9 = { class: "form-group col-sm-6" }
const _hoisted_10 = {
  key: 1,
  class: "row"
}
const _hoisted_11 = { class: "col-sm-12" }
const _hoisted_12 = { class: "alert alert-warning" }
const _hoisted_13 = { class: "col-4" }
const _hoisted_14 = { class: "col-4" }
const _hoisted_15 = { class: "col-4" }
const _hoisted_16 = {
  key: 2,
  class: "row"
}
const _hoisted_17 = { class: "col-sm-12" }
const _hoisted_18 = { class: "alert alert-warning" }
const _hoisted_19 = {
  key: 3,
  class: "row"
}
const _hoisted_20 = { class: "form-group col-sm-12" }
const _hoisted_21 = {
  key: 0,
  class: "modal-footer-body"
}
const _hoisted_22 = ["disabled"]
const _hoisted_23 = ["onClick", "disabled"]
const _hoisted_24 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "addRecipientValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "add-recipient",
        ref: "addRecipient",
        class: "modal-sticky-header",
        centered: true,
        closeButton: false
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('add_recipient_allocation_recommendation')), 1)
        ]),
        body: _withCtx(() => [
          (!_ctx.allocation || !_ctx.editState)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!!_ctx.addRecipientToAllocationError)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.addRecipientToAllocationError), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_validation_provider, {
                      rules: { no_invalid_tags: { tags: _ctx.editState.recipientIdTags } },
                      name: _ctx.$t('recipient_id'),
                      vid: "recipient-client-id",
                      class: "generic-tags"
                    }, {
                      default: _withCtx(({ errors }) => [
                        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('recipient_id')), 1),
                        _createVNode(_component_vue_tags_input, {
                          id: "recipient-client-id",
                          disabled: !!_ctx.editState.transplantProgramId,
                          placeholder: "",
                          "add-on-key": [13, ' '],
                          "allow-edit-tags": false,
                          "autocomplete-min-length": 1,
                          "max-tags": 1,
                          separators: [' '],
                          validation: _ctx.validateRecipientIdTag(),
                          modelValue: _ctx.editState.recipientIdInput,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.recipientIdInput) = $event)),
                          "autocomplete-items": _ctx.filteredRecipientIdAutocompleteOptions,
                          onBeforeAddingTag: _ctx.beforeRecipientIdChanged,
                          onTagsChanged: _ctx.onRecipientIdChanged,
                          tags: _ctx.editState.recipientIdTags,
                          class: _normalizeClass({ 'is-invalid': errors[0] })
                        }, {
                          "autocomplete-item": _withCtx((props) => [
                            _createElementVNode("div", {
                              onClick: ($event: any) => (props.performAdd(props.item))
                            }, [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(props.item.text), 1),
                              (props.item.hint)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(props.item.hint), 1))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_5)
                          ]),
                          _: 2
                        }, 1032, ["disabled", "validation", "modelValue", "autocomplete-items", "onBeforeAddingTag", "onTagsChanged", "tags", "class"]),
                        (errors[0])
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors, (error, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: `recipient-client-id-error-${index}`
                                }, [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: ['far', 'exclamation-circle'],
                                    "fixed-width": ""
                                  }),
                                  _createTextVNode(" " + _toDisplayString(error), 1)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["rules", "name"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_select_input, {
                      selectId: "oop-program",
                      name: _ctx.$t('oop_program'),
                      disabled: !!_ctx.editState.recipientId,
                      options: _ctx.hospitalOptions,
                      modelValue: _ctx.editState.transplantProgramId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.transplantProgramId) = $event))
                    }, null, 8, ["name", "disabled", "options", "modelValue"])
                  ])
                ]),
                (_ctx.recipient)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('add_this_recipient_to_allocation_recommendation')), 1)
                      ]),
                      _createElementVNode("dl", _hoisted_13, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('name')), 1),
                        _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDisplayName), 1)
                      ]),
                      _createElementVNode("dl", _hoisted_14, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('ohip_number')), 1),
                        _createElementVNode("dd", null, _toDisplayString(_ctx.ohipNumber || '-'), 1)
                      ]),
                      _createElementVNode("dl", _hoisted_15, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('transplant_program')), 1),
                        _createElementVNode("dd", null, _toDisplayString(_ctx.transplantProgram || '-'), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.recipient && _ctx.editState.transplantProgramId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('add_this_program_to_allocation_recommendation')), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.recipient || _ctx.editState.transplantProgramId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_text_area_input, {
                          inputId: "reason",
                          name: _ctx.$t('reason'),
                          rules: "required",
                          modelValue: _ctx.editState.reason,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.reason) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        footer: _withCtx(() => [
          (_ctx.editState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("button", {
                  type: "button",
                  "data-dismiss": "modal",
                  class: "btn btn-secondary",
                  disabled: _ctx.isLoadingAllocation || _ctx.isAddingRecipient
                }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_22),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-success",
                  onClick: ($event: any) => (handleSubmit(_ctx.saveRecipient)),
                  disabled: !_ctx.canSave || _ctx.isLoadingAllocation || _ctx.isAddingRecipient
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('ok')) + " ", 1),
                  (_ctx.isAddingRecipient)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_24, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "fa-spin",
                          icon: ['far', 'spinner-third']
                        })
                      ]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_23)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1536)
    ]),
    _: 1
  }, 512))
}