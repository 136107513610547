import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, withModifiers as _withModifiers, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "form-group col-sm-6" }
const _hoisted_4 = {
  key: 0,
  class: "form-group col-sm-6"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  key: 0,
  class: "form-group col-sm-6"
}
const _hoisted_7 = {
  key: 1,
  class: "form-group col-sm-6"
}
const _hoisted_8 = {
  key: 1,
  class: "row"
}
const _hoisted_9 = { class: "form-group col-sm-6" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-4" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "form-group col-sm-6" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "modal-footer-body" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = ["onClick", "disabled"]
const _hoisted_18 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "offerValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "offer-modal",
        ref: "offerModal",
        class: "modal-sticky-header",
        onHide: _cache[12] || (_cache[12] = (options) => _ctx.modalEvent(options)),
        centered: true,
        closeButton: false
      }, _createSlots({
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('make_offer_to')), 1)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("button", {
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-secondary",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.clearAllocationOfferState())),
              disabled: _ctx.isMakingOffer
            }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_16),
            _createElementVNode("button", {
              class: "btn btn-success",
              onClick: _withModifiers(($event: any) => (handleSubmit(_ctx.makeOffer)), ["prevent"]),
              disabled: _ctx.isMakingOffer
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('make_offer')) + " ", 1),
              (_ctx.isMakingOffer)
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-spin",
                      icon: ['far', 'spinner-third']
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_17)
          ])
        ]),
        _: 2
      }, [
        (!_ctx.editState.offer)
          ? {
              name: "body",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              key: "0"
            }
          : {
              name: "body",
              fn: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('select_offer_to')), 1),
                  _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))
                ]),
                (!!_ctx.editState.offer.offerErrorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.editState.offer.offerErrorMessage), 1))
                  : _createCommentVNode("", true),
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_select_input, {
                      selectId: "offer-type",
                      name: _ctx.$t('offer_type'),
                      rules: "required",
                      modelValue: _ctx.editState.offer.type,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.offer.type) = $event)),
                      options: _ctx.availableOfferTypes,
                      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setShowManualAllocationRationale()))
                    }, null, 8, ["name", "modelValue", "options"])
                  ]),
                  (_ctx.showOrganSpecification)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_select_input, {
                          "select-id": "offer-organ-specification",
                          name: _ctx.$t('organ_specification'),
                          rules: "required",
                          modelValue: _ctx.editState.offer.organSpecification,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.offer.organSpecification) = $event)),
                          options: _ctx.organSpecificationLookup,
                          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setShowManualAllocationRationale()))
                        }, null, 8, ["name", "modelValue", "options"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.showNoOfferOptions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_select_input, {
                          "select-id": "offer-reason-category",
                          name: _ctx.$t('no_offer_reason_category'),
                          rules: "required",
                          modelValue: _ctx.editState.offer.reasonCategory,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.offer.reasonCategory) = $event)),
                          options: _ctx.noOfferReasonCategoryOptions,
                          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearOfferReason()))
                        }, null, 8, ["name", "modelValue", "options"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.showNoOfferOptions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_select_input, {
                          "select-id": "offer-reason",
                          name: _ctx.$t('no_offer_reason'),
                          rules: "required",
                          modelValue: _ctx.editState.offer.reason,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.offer.reason) = $event)),
                          options: _ctx.noOfferReasonOptions
                        }, null, 8, ["name", "modelValue", "options"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.isPrimary)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_boolean_radio_input, {
                          "input-id": "re-offer-indicator",
                          labelName: _ctx.$t('re-offer-indicator'),
                          acceptLabel: "Yes",
                          declineLabel: "No",
                          disabled: true,
                          modelValue: _ctx.editState.offer.reOfferScenario,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.offer.reOfferScenario) = $event))
                        }, null, 8, ["labelName", "modelValue"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("dl", _hoisted_11, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('offer_by')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.currentUser || '-'), 1)
                  ])
                ]),
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_text_area_input, {
                      "input-id": "offer-comment",
                      name: _ctx.$t('offer_comment'),
                      modelValue: _ctx.editState.offer.comment,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.offer.comment) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ])
                ]),
                (_ctx.showManualAllocationRationale)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_select_other_input, {
                          rules: "required",
                          "select-id": "manual-allocation-rationale",
                          name: _ctx.$t('manual_allocation_rationale'),
                          modelValue: _ctx.editState.offer.manualAllocationRationale,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.offer.manualAllocationRationale) = $event)),
                          options: _ctx.manualAllocationRationaleOptions,
                          onChange: _ctx.onManualAllocationRationaleChanged,
                          numeric: true,
                          reduceColumnWidth: "form-group selectWithOther col-6",
                          colStyling: "form-group selectWithOther col-12"
                        }, {
                          other: _withCtx(() => [
                            _createVNode(_component_text_input, {
                              rules: "required",
                              "input-id": "manual_allocation_rationale_other",
                              name: _ctx.$t('manual_allocation_rationale_other'),
                              modelValue: _ctx.editState.offer.manualAllocationRationaleOther,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.offer.manualAllocationRationaleOther) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["name", "modelValue", "options", "onChange"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              key: "1"
            }
      ]), 1536)
    ]),
    _: 1
  }, 512))
}