<template>
  <div v-if="isProtoRecipientOffersEnabled">
    <loading-recipient-page v-if="!dispatchEventsComplete" />
    <template v-else>
      <page-top>
        <list-recipients-link />
        / {{recipientDisplayName}}
      </page-top>
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-recipient-proto-offers />
            <div class="page-content">
              <recipient-summary />
              <proto-offer-statistics />
              <proto-current-offers />
              <proto-offers-history/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Vue } from "vue-facing-decorator";
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import { Component } from 'vue-facing-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ListRecipientsLink from '@/components/shared/page-top/ListRecipientsLink.vue';
import LoadingSideNav from '@/components/shared/loading-skeletons/LoadingSideNav.vue';
import { useCurrentPageStore, setPageTitle } from '@/stores/currentPage';
import LoadingRecipientPage from '@/components/shared/loading-skeletons/LoadingRecipientPage.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import SideNavRecipientProtoOffers from '@/prototypes/side-nav/SideNavRecipientProtoOffers.vue';
import ProtoOfferStatistics from '@/prototypes/offers/ProtoOfferStatistics.vue';
import ProtoCurrentOffers from '@/prototypes/offers/ProtoCurrentOffers.vue';
import ProtoOffersHistory from '@/prototypes/offers/ProtoOffersHistory.vue';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    ListRecipientsLink,
    LoadingSideNav,
    RecipientSummary,
    LoadingRecipientPage,
    SideNavRecipientProtoOffers,
    ProtoOfferStatistics,
    ProtoCurrentOffers,
    ProtoOffersHistory
  },
})
export default class ProtoOffers extends Vue {
  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;

  private dispatchEventsComplete = false;

  get isProtoRecipientOffersEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoOffers);
  }

  get recipientDisplayName(): string {
    return useCurrentPageStore().currentRecipient?.displayName || '';
  }


  // Vue lifecycle hooks
  public async mounted(): Promise<void> {
    const recipientIdentifier = this.clientId as string;
    if (!recipientIdentifier) {
      console.warn('missing recipient client_id');
    }
    await useCurrentPageStore().currentRecipient?.load();
    setPageTitle(this.$t('page.recipient.edit', { recipient: this.recipientDisplayName })),
    Promise.all([
      this.$store.dispatch('validations/loadEdit', { view: 'recipients', action: 'edit', clientId: recipientIdentifier }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }
}
</script>

<style>
</style>
