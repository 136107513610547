<template>
  <div v-if="isProtoPatientHistoryEnabled">
  <card-section
    section-id="proto_patient_history"
  >
    <template v-slot:header>
      Patient History
    </template>
    <template v-slot:body>
      <form>
        <proto-medical-history/>
        <proto-surgical-history/>
        <proto-social-history/>
      </form>
    </template>
  </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ProtoMedicalHistory from '@/prototypes/profile/patient_history/ProtoMedicalHistory.vue';
import ProtoSurgicalHistory from '@/prototypes/profile/patient_history/ProtoSurgicalHistory.vue';
import ProtoSocialHistory from '@/prototypes/profile/patient_history/ProtoSocialHistory.vue';

import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';


@Component({
  components: {
    CardSection,
    ProtoMedicalHistory,
    ProtoSurgicalHistory,
    ProtoSocialHistory
  }
})
export default class ProtoPatientHistory extends Vue {


  get isProtoPatientHistoryEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.PatientHistory);
  }
}
</script>
