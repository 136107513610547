<template>
  <!-- Sub-section container -->
    <sub-section
      sub-section-id="proto_living_donor_patient_contact_details"
      :title="$t('contact_details_title')"
    >
    <template v-slot:contents>
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="showCreateButton"
        :createText="$t('contact_details_create_button')"
        @table-create-row="handleTableCreateRow"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="protoLDContactFilter"
                :showFilter="true"
                :showArchived="true"
                @setFilters="[]"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="protoLDpatientContactDetailsTable"
        tabbableColumn="lastUpdatedDisplay"
        table-id="patient-contact-details-table"
        :table-config="patientContactDetailsTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
      <contact-detail-form
        form-id="proto-ld-patient-contact-details-form"
        ref="protoLDPatientContactDetailsForm"
        :selection="selection"
        :newRecipient="newRecipient"
        :canSave="canSave"
        @save="performSave"
        @cancel="resetSelection"
      />
    </template>
  </sub-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { UIContactDetail } from "@/UIModels/contactDetail";
import { SaveResult } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from '@/components/shared/filter/FilterComponent.vue';
import TableList from '@/components/shared/TableList.vue';
import ContactDetailForm from '@/prototypes/livingDonor/contactInformation/protoLDContactDetails/ProtoLDContactDetailForm.vue';
import { UIError } from '@/UIModels/error';
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { ContactDetailCategoryLookup } from '@/store/lookups/types';
import { i18nMessages } from '@/i18n';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { sortByDate } from '@/utils';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

interface PatientContactDetailsComponentRow {
  id: string|null;
  isNew: boolean;
  lastUpdatedDisplay: string;
  categoryType: string|null;
  categoryDisplay: string;
  details: string;
  phoneNumber: string,
  email: string,
  comments: string|null;
  status: string;
  isArchived: boolean;
  isPreferred: boolean;
  preferred: boolean;
  okayTo: any;
  formatCode: string;
}
const EDITSTATE_KEY = 'editState';
// What are all the possible per-page sizes allowed in the table list pagination bar?
const PAGE_SIZES = [10, 25, 50];

@Component({
  components: {
    SubSection,
    TableToolbar,
    FilterComponent,
    TableList,
    ContactDetailForm,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/prototypes/livingDonor/contactInformation/_locales/ProtoLDContactDetails.json'),
  ]),
})
export default class ProtoLDPatientContactDetails extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.contact_detail_categories) contactDetailCategories!: ContactDetailCategoryLookup[];

  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private contactInformation: any = [];

  private selection: PatientContactDetailsComponentRow = {
    id: "",
    lastUpdatedDisplay: "",
    categoryType: "",
    categoryDisplay: "",
    details: "",
    email: '',
    okayTo: [],
    comments: "",
    status: "",
    isArchived: false,
    isPreferred:false,
    isNew: true,
    phoneNumber: '',
    preferred: false,
    formatCode: '',
  };

  
  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  public performSave(payload: any): any {
    // Call the 'startSaving' method to begin save animation

    let contactInformation = this.contactInformation || [];

    if (!this.selection.isNew ){  
      payload.id = this.selection.id;
      const foundIndex = contactInformation.findIndex((contact: any) => contact.id == this.selection.id);
      
      if (foundIndex != -1) {
        contactInformation[foundIndex] = payload;
      }
    } else {  
      contactInformation.push(payload);
    }
    
    this.protoBucket.setItem(EDITSTATE_KEY, contactInformation); 
    this.resetSelection();   
  } 



  // Local component state related to table filters
  private filterAssignments: UIFilterAssignments = {
    include_archived: false,
  }

  // Reference to Patient Address table list
  get protoLDpatientContactDetailsTable(): TableList {
    return this.$refs.protoLDpatientContactDetailsTable as TableList;
  }

  /**
   * Get the positional index in the category lookup of the specified category for sorting purposes
   *
   * @param categoryCode string code of the category lookup
   * @returns {number} 0-based index if code found in lookup, -1 if not found
   */
  private categoryIndex(categoryCode: string|null): number {
    if (!this.contactDetailCategories) return -1;

    const result = this.contactDetailCategories.findIndex((categoryLookup: ContactDetailCategoryLookup) => {
      return categoryLookup.code === categoryCode;
    });

    return result;
  }

  get showCreateButton(): boolean {
    return this.canSave && !this.newRecipient;
  }

  get tableRows() {
    const contactInformation = this.contactInformation || [];

    if (contactInformation.length > 0) {

    return contactInformation.map((contact: any) => {
      return {
      id: contact.id,
      lastUpdatedDisplay: contact.lastUpdatedDateObject,
      categoryType: contact.categoryType,
      categoryDisplay: contact.categoryType ? this.lookupValue(contact.categoryType, 'contact_detail_categories') : '-',
      details: contact.formatCode && contact.formatCode == "phone_number" ? contact.phoneNumber : contact.email,
      phoneNumber: contact.phoneNumber,
      email: contact.email,
      formatCode: contact.formatCode,
      call: this.parseYesNoBlank(contact.call),
      voicemail: this.parseYesNoBlank(contact.voicemail),
      text: this.parseYesNoBlank(contact.text),
      comments: contact.comments,
      status: contact.status && contact.status == 'active'? 'Active' : '-',
      isArchived: contact.status === 'status.archived',
      isPreferred: contact.preferred,
      };
    });
   }
  }

  // Configure the list table
  get patientContactDetailsTableConfig(): TableConfig {
    return {
      data: this.tableRows || [],
      columns: [
        { label: this.$t('contact_detail_column.last_updated').toString(), field: 'lastUpdatedDisplay', width: '125px'},
        { label: this.$t('contact_detail_column.category_name').toString(), field: 'categoryDisplay',  width: '145px' },
        { label: this.$t('contact_detail_column.details').toString(), field: 'details',  width: '170px' },
        { label: this.$t('contact_detail_column.call').toString(), field: 'call',  width: '65px'},
        { label: this.$t('contact_detail_column.voicemail').toString(), field: 'voicemail',  width: '75px' },
        { label: this.$t('contact_detail_column.text').toString(), field: 'text',  width: '65px' },
        { label: this.$t('contact_detail_column.comments').toString(), field: 'comments', width: '200px' },
        { label: this.$t('contact_detail_column.status').toString(), field: 'status', width: '105px'},
      ],
      empty: this.canSave ? this.$t('contact_detail_table_empty.can_save').toString() : this.$t('contact_detail_table_empty.read_only').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  private resetSelection(): any {
    this.selection.id = "";
    this.selection.categoryType = "";
    this.selection.email = "";
    this.selection.okayTo = [];
    this.selection.comments = "";
    this.selection.status = "";
    this.selection.isNew = true;
    this.selection.phoneNumber= "";
    this.selection.formatCode = "";
    this.selection.preferred = false;
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: PatientContactDetailsComponentRow): string|null {
    if (!row) return null;

    const rowClasses = [];
    if (row.isArchived) rowClasses.push('highlight-archived');
    if (row.isPreferred) rowClasses.push('emphasize-preferred'); // Contact methods that are Preferred appear in bold font (AP-775)
    return rowClasses.join(' ');
  }

  // Derive 'Yes', 'No', or blank '--' for one of the "Okay to" options in the table
  private parseYesNoBlank(val: string|null) {
    return val !== null ? this.$t(val).toString() :  '--' ;
  }


  // Clear selection based on a create button click event
  private handleTableCreateRow(): void {
    this.protoLDpatientContactDetailsTable.resetSelection();
    this.resetSelection();
  }


  private editSelected(row: any) {
    let okayTo = [];
    if (row.row.call == "Yes") {
      okayTo.push('call');
    } 
    if (row.row.text == "Yes") {
       okayTo.push('text');
    }
    if (row.row.voicemail == "Yes") {
       okayTo.push('voicemail');
    }
      if (row) {      
        this.selection.id = row.row.id;
        this.selection.lastUpdatedDisplay = row.row.lastUpdatedDisplay;
        this.selection.categoryType = row.row.categoryType;
        this.selection.phoneNumber = row.row.phoneNumber;
        this.selection.email = row.row.email;
        this.selection.okayTo = okayTo;
        this.selection.comments = row.row.comments;
        this.selection.status= row.row.status;
        this.selection.isArchived= row.row.isArchived;
        this.selection.preferred = row.row.isPreferred;
        this.selection.isPreferred = row.row.isPreferred;
        this.selection.isNew = false;
        this.selection.formatCode = row.row.formatCode;
      }
    }

    // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
    async mounted() {
    // No-op if prototypes not enabled
    if (!useCurrentPageStore().configuration.prototypes) return;

    // Make a per-recipient prototype bucket
    this.protoBucket = new UIPrototypeBucket(PROTOTYPES.ProtoLDContactInformation, { recipientId: this.$route.params.id });

    // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
    const response: PatientContactDetailsComponentRow = await this.protoBucket.getItem(EDITSTATE_KEY);
    if (!response) return;

    this.contactInformation = response;
  }
}

</script>
