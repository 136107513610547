<template>
  <sub-section
    :title="$t('dialysis_subtitle')"
    sub-section-id="sub-section-dialysis"
    v-if="isRecipientDialysisEnabled"
  >
    <template v-slot:contents>
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="uiRecipient.canSave"
        :createText="$t('dialysis_create_record')"
        @table-create-row="handleTableCreateRow"
      />

      <!-- List of Items, or History List -->
      <table-list
        ref="recipientDialysisTable"
        table-id="recipient-dialysis-table"
        :table-config="tableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        :firstColumnTabbable="true"
      />

      <dialysis-form
        form-id="recipientDialysisForm"
        ref="recipientDialysisForm"
        :selection="selection"
        :canSave="uiRecipient.canSave"
        @success="handleSuccess"
      />

    </template>
  </sub-section>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIRecipient } from '@/UIModels/recipient';
import { useCurrentPageStore } from '@/stores/currentPage';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { i18nMessages } from "@/i18n";
import { SaveResult } from '@/types';
import { TableConfig } from '@/types';
import DialysisForm from '@/components/recipients/egfrDialysis/DialysisForm.vue';
import { UIRecipientDialysis } from '@/UIModels/recipients/recipientDialysis';
import { GenericCodeValue } from '@/store/types';

interface TableRow {
  id: string|null;
  reason_code: string|null;
  start_date: string|null;
  end_date: string|null;
  dialysis_type_code: string|null;
  location_type_code: string|null;
}

const PAGE_SIZES = [5, 10, 25];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0]; // 5

@Component({
  components: {
    SubSection,
    TableToolbar,
    TableList,
    DialysisForm
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/RecipientEgfrDialysis.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class DialysisSubSection extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.dialysis_access_mode) accessModeOptions!: GenericCodeValue[];
  @State(state => state.lookups.dialysis_laterality) lateralityOptions!: GenericCodeValue[];
  @State(state => state.lookups.dialysis_location_type) locationOptions!: GenericCodeValue[];
  @State(state => state.lookups.dialysis_reason) reasonOptions!: GenericCodeValue[];
  @State(state => state.lookups.dialysis_type) typeOptions!: GenericCodeValue[];

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  // Check if Recipient Dialysis is enabled
  get isRecipientDialysisEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientDialysis.enabled;
  }

  // Selection instance
  private selection = new UIListFormSelection();

  // Editable view model for the form
  private editState = new UIRecipientDialysis();

  get recipientDialysisTable(): TableList {
    return this.$refs.recipientDialysisTable as TableList;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Clear selection
  private handleSuccess(_success: SaveResult): void {
    this.recipientDialysisTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  get getRecords(): UIRecipientDialysis[] {
    return this.uiRecipient.recipientDialysis || [];
  }

  // Prepare recipient attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const rows: TableRow[] = this.getRecords.map((item: UIRecipientDialysis) => {
      return {
        id: item.id,
        reason_code: this.translateReasonCode(item.reason_code) || '-',
        start_date: this.parseDisplayDateUi(item.start_date) || '-',
        end_date: this.parseDisplayDateUi(item.end_date) || '-',
        dialysis_type_code: this.translateDialysisType(item.dialysis_type_code) || '-',
        location_type_code: this.translateLocationType(item.location_type_code) || '-',
      };
    });
    return rows;
  }

  translateReasonCode(code: string|null): string|null {
    if (!code || !this.reasonOptions) return null;

    const found = this.reasonOptions.find(( item: GenericCodeValue ) => {
      return item.code === code;
    });

    return found ? found.value : null;
  }

  translateDialysisType(code: string|null): string|null {
    if (!code || !this.typeOptions) return null;

    const found = this.typeOptions.find(( item: GenericCodeValue ) => {
      return item.code === code;
    });

    return found ? found.value : null;
  }

  translateLocationType(code: string|null): string|null {
    if (!code || !this.locationOptions) return null;

    const found = this.locationOptions.find(( item: GenericCodeValue ) => {
      return item.code === code;
    });

    return found ? found.value : null;
  }

  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('dialysis_reason_for_dialysis')}`,
        field: 'reason_code',
        sortable: false
      },
      { label: `${this.$t('dialysis_start_date')}`,
        field: 'start_date',
        sortable: false
      },
      { label: `${this.$t('dialysis_end_date')}`,
        field: 'end_date',
        sortable: false
      },
      { label: `${this.$t('dialysis_type')}`,
        field: 'dialysis_type_code',
        sortable: false
      },
      { label: `${this.$t('dialysis_location')}`,
        field: 'location_type_code',
        sortable: false
      }
    ];

    return {
      data: this.tableRows,
      columns: tableConfig,
      empty: this.uiRecipient.canSave ? this.$t('dialysis_new_result_text').toString() : this.$t('dialysis_no_results_text').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        position: 'bottom'
      }
    };
  }

  private handleTableCreateRow(): void {
    this.recipientDialysisTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  /**
   * Event handle run when clicking on the edit button on a row in the post transplant follow up table.
   */
  handleTableRowClick(event: { row: TableRow }): void {
    const listItem = this.getRecords.find((item: UIRecipientDialysis) => { return item.id === event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
  }
}
</script>
