export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all matching items"])}
        },
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of this row"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFFLO"])},
        "return_to_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to previous page"])}
      },
      "fr": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tous les éléments correspondants"])}
        },
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chargement en cours…"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionner"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de cette ligne"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFFLO"])},
        "return_to_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à la page précédente"])}
      },
      "en_tgln": {
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Allocation and Transplant System"])}
      },
      "fr_tgln": {
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'attribution et de transplantation d'organes"])}
      }
    }
  })
}
