import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_blood_type_form = _resolveComponent("proto-blood-type-form")!
  const _component_blood_type_form = _resolveComponent("blood-type-form")!
  const _component_measurements_form = _resolveComponent("measurements-form")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _ctx.handleLoaded,
    "section-id": "gci"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('general_clinical_information')), 1)
    ]),
    body: _withCtx(() => [
      (_ctx.isProtoCpraEnabled)
        ? (_openBlock(), _createBlock(_component_proto_blood_type_form, {
            key: 0,
            canSave: _ctx.uiRecipient.canSave
          }, null, 8, ["canSave"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.isRecipientGeneralClinicalBloodEnabled)
              ? (_openBlock(), _createBlock(_component_blood_type_form, {
                  key: 0,
                  ref: "bloodTypeForm",
                  newRecipient: _ctx.newRecipient,
                  canSave: _ctx.uiRecipient.canSave
                }, null, 8, ["newRecipient", "canSave"]))
              : _createCommentVNode("", true)
          ], 64)),
      (_ctx.isRecipientGeneralClinicalMeasurementsEnabled)
        ? (_openBlock(), _createBlock(_component_measurements_form, {
            key: 2,
            ref: "measurementsForm",
            newRecipient: _ctx.newRecipient,
            canSave: _ctx.uiRecipient.canSave
          }, null, 8, ["newRecipient", "canSave"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["lookupsToLoad", "onLoaded"]))
}