import { GetterTree } from 'vuex';
import { RootState, ObjectId } from '@/store/types';
import { ResponsiblePhysiciansState, ResponsiblePhysician, ResponsiblePhysicianHospitalAssignment, ResponsiblePhysicianOrganSpecialization, ResponsiblePhysicianOption } from '@/store/responsiblePhysicians/types';

export const getters: GetterTree<ResponsiblePhysiciansState, RootState> = {
  /**
   * Get a select-input "friendly" list of responsible physicians
   * 
   * @returns {[{code: string, value: string, hospital_id: string, organ_code: string}]} array of responisble physicians
   */
  responsiblePhysicianOptions(state, getters): ResponsiblePhysicianOption[] {
    const physicians: ResponsiblePhysician[] = state.responsiblePhysicians;
    
    if (physicians) {
      return physicians.map((physician: ResponsiblePhysician): ResponsiblePhysicianOption => {
        return {
          code: physician._id,
          value: `${physician.first_name} ${physician.last_name}`,
          hospital_assignments: physician.hospital_assignments,
        };
      });
    } else {
      return [];
    }
  },

  /**
   * Get a select-input "friendly" list of responsible physicians filtered by the passed hospital id and passed organ code
   * 
   * @param byHospitalId hospital id
   * @param byOrganCode organ code
   * @returns {ResponsiblePhysicianOption[]} array of options for responsible physicians belonging to the hospital and specified organ code
   */
   responsiblePhysiciansByHospitalAndOrgan(state, getters, rootState, rootGetters) {
    return (byHospitalId: string, byOrganCode: string): ResponsiblePhysicianOption[] => {
      if (!byHospitalId || !byOrganCode) return [];
      const physicians: ResponsiblePhysician[] = state.responsiblePhysicians || [];
      const filtered: ResponsiblePhysician[] = physicians.filter((physician: ResponsiblePhysician) => {
        const hospitalAssignments = physician?.hospital_assignments || [];
        const applicableAssignments = hospitalAssignments.filter((assignment: ResponsiblePhysicianHospitalAssignment) => {
          const hospitalId = assignment?.hospital_id || '';
          const organSpecializations = assignment?.organ_specializations || [];
          const organCodes = organSpecializations.map((specialization: ResponsiblePhysicianOrganSpecialization): string => {
            return (specialization?.organ_code || 0).toString();
          });
          // check for expired (no expired in physician's hospital & organ assignments but exists in physician's root)
          const isExpired = assignment.expiry_date ? rootGetters["utilities/isExpired"](assignment.expiry_date) : false;
          if (!isExpired) {
            // if not expired, match hospitalId and organCode
            return hospitalId === byHospitalId.toString() && organCodes.includes(byOrganCode.toString());
          }
        });
        return applicableAssignments.length > 0;
      });
      const options = filtered.map((physician: ResponsiblePhysician): ResponsiblePhysicianOption => {
        return {
          code: physician._id,
          value: `${physician.first_name} ${physician.last_name}`,
          hospital_assignments: physician.hospital_assignments,
        };
      });
      return options;
    };
  },
};
