import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-wrapper"
}
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "filter-dropmenu",
  "aria-labelledby": "dropdownMenuButton"
}
const _hoisted_4 = {
  key: 0,
  class: "legend-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_filter_links = _resolveComponent("filter-links")!

  return (_ctx.showFilter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("button", {
                class: "btn btn-outline btn-sm filterButton",
                type: "button",
                id: "dropdownMenuButton",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)), ["prevent"])),
                "aria-label": _ctx.$t('toggle_accordion_section')
              }, [
                _createTextVNode(_toDisplayString(_ctx.getTitle) + " ", 1),
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', 'caret-down'],
                  class: "accordion-icon"
                })
              ], 8, _hoisted_2),
              (_ctx.showDropdown)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("button", {
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)), ["prevent"])),
                      class: "btn btn-sm closeDropdown"
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'times'],
                        class: "nav-caret",
                        "fixed-width": ""
                      })
                    ]),
                    _createVNode(_component_filter_links, {
                      fieldID: _ctx.fieldID,
                      showFilter: _ctx.showNotesFilter,
                      showNotesFilter: _ctx.showNotesFilter,
                      showChecklistFilter: _ctx.showChecklistFilter,
                      showImportDetailsModalFilter: _ctx.showImportDetailsModalFilter,
                      keywordSearch: _ctx.keywordSearch,
                      tagOptions: _ctx.tagOptions,
                      authorOptions: _ctx.authorOptions,
                      showArchived: _ctx.showArchived,
                      excludeCompleted: _ctx.excludeCompleted,
                      statusOptions: _ctx.statusOptions,
                      onSetFilters: _ctx.setFilters,
                      onClearFilters: _ctx.clearFilters,
                      selectedFilters: _ctx.selectedFilters
                    }, null, 8, ["fieldID", "showFilter", "showNotesFilter", "showChecklistFilter", "showImportDetailsModalFilter", "keywordSearch", "tagOptions", "authorOptions", "showArchived", "excludeCompleted", "statusOptions", "onSetFilters", "onClearFilters", "selectedFilters"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.title)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString(_ctx.getTitle), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.getFilterCss)
              }, [
                _createVNode(_component_filter_links, {
                  fieldID: _ctx.fieldID,
                  showFilter: _ctx.showNotesFilter,
                  showNotesFilter: _ctx.showNotesFilter,
                  showChecklistFilter: _ctx.showChecklistFilter,
                  showImportDetailsModalFilter: _ctx.showImportDetailsModalFilter,
                  keywordSearch: _ctx.keywordSearch,
                  tagOptions: _ctx.tagOptions,
                  authorOptions: _ctx.authorOptions,
                  showArchived: _ctx.showArchived,
                  excludeCompleted: _ctx.excludeCompleted,
                  statusOptions: _ctx.statusOptions,
                  hideSpacer: _ctx.hideSpacer,
                  onSetFilters: _ctx.setFilters,
                  onClearFilters: _ctx.clearFilters,
                  selectedFilters: _ctx.selectedFilters
                }, null, 8, ["fieldID", "showFilter", "showNotesFilter", "showChecklistFilter", "showImportDetailsModalFilter", "keywordSearch", "tagOptions", "authorOptions", "showArchived", "excludeCompleted", "statusOptions", "hideSpacer", "onSetFilters", "onClearFilters", "selectedFilters"])
              ], 2)
            ], 64))
      ]))
    : _createCommentVNode("", true)
}