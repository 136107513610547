import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "nav-wrapper" }
const _hoisted_5 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_recipient_page = _resolveComponent("loading-recipient-page")!
  const _component_list_recipients_link = _resolveComponent("list-recipients-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_side_nav_recipient_proto_offers = _resolveComponent("side-nav-recipient-proto-offers")!
  const _component_recipient_summary = _resolveComponent("recipient-summary")!
  const _component_proto_offer_statistics = _resolveComponent("proto-offer-statistics")!
  const _component_proto_current_offers = _resolveComponent("proto-current-offers")!
  const _component_proto_offers_history = _resolveComponent("proto-offers-history")!

  return (_ctx.isProtoRecipientOffersEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.dispatchEventsComplete)
          ? (_openBlock(), _createBlock(_component_loading_recipient_page, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_page_top, null, {
                default: _withCtx(() => [
                  _createVNode(_component_list_recipients_link),
                  _createTextVNode(" / " + _toDisplayString(_ctx.recipientDisplayName), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_side_nav_recipient_proto_offers),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_recipient_summary),
                      _createVNode(_component_proto_offer_statistics),
                      _createVNode(_component_proto_current_offers),
                      _createVNode(_component_proto_offers_history)
                    ])
                  ])
                ])
              ])
            ], 64))
      ]))
    : _createCommentVNode("", true)
}