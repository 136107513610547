import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "customize-panel" }
const _hoisted_2 = { class: "form-row mb-3" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "form-row mb-3" }
const _hoisted_5 = { class: "col-sm-12 col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    class: "card-body card-options p-0 collapse",
    id: _ctx.id,
    ref: "validations"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('panel_options')), 1),
        _createElementVNode("form", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_select_input, {
                name: "panel-style",
                "select-id": `${_ctx.id}-style`,
                modelValue: _ctx.editState.style,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.style) = $event)),
                label: _ctx.$t('panel_style'),
                options: _ctx.getPanelStyles,
                rules: "required"
              }, null, 8, ["select-id", "modelValue", "label", "options"])
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sub-divider" }, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_checkbox_input, {
                "input-id": `${_ctx.id}-visible`,
                modelValue: _ctx.editState.visible,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.visible) = $event)),
                label: _ctx.$t('show_this_panel'),
                disabled: _ctx.editState.required
              }, null, 8, ["input-id", "modelValue", "label", "disabled"])
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "sub-divider" }, null, -1)),
          _createVNode(_component_save_toolbar, {
            show: true,
            ref: `${_ctx.id}-saveWidgetOptions`,
            label: _ctx.$t('save_panel_options'),
            cancelButton: true,
            buttonClass: "btn btn-sm btn-wide btn-success",
            cancelButtonClass: "btn btn-sm btn-wide btn-secondary ml-auto",
            onSave: _ctx.onSave,
            onCancel: _ctx.onCancel
          }, null, 8, ["label", "onSave", "onCancel"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}