import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group-large" }
const _hoisted_4 = { for: "attachment" }
const _hoisted_5 = {
  key: 0,
  class: "invalid-feedback",
  id: "attachment-error"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "modal-footer-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "import-file-card",
    showLegend: false
  }, {
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('import_file'),
        "sub-section-id": "import-file-section"
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_validation_observer, { ref: "validations" }, {
            default: _withCtx(() => [
              _createVNode(_component_form_layout, {
                disabled: !_ctx.canSave,
                "form-id": "import-form"
              }, {
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_select_input, {
                        rules: "required",
                        "select-id": "transplant_program",
                        name: _ctx.$t('transplant_program'),
                        modelValue: _ctx.editState.transplant_program,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.transplant_program) = $event)),
                        options: _ctx.hospitalOptions
                      }, null, 8, ["name", "modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_validation_provider, {
                        rules: "required",
                        ref: "attachment-provider",
                        label: _ctx.$t('patient_data_csv'),
                        name: "filename",
                        modelValue: _ctx.editState.filename,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.filename) = $event))
                      }, {
                        default: _withCtx(({ errors }) => [
                          _createElementVNode("label", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_ctx.$t('patient_data_csv')) + " ", 1),
                            _cache[5] || (_cache[5] = _createElementVNode("i", null, "*", -1))
                          ]),
                          _createElementVNode("input", {
                            accept: ".csv",
                            "input-id": "attachment",
                            ref: "fileUploader",
                            type: "file",
                            class: _normalizeClass(["form-control", { 'is-invalid': errors[0] }]),
                            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAttachmentChanged($event)))
                          }, null, 34),
                          (errors[0])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: ['far', 'exclamation-circle'],
                                  "fixed-width": ""
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.$t('import_title'))), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["label", "modelValue"])
                    ])
                  ])
                ]),
                save: _withCtx(() => [
                  _createVNode(_component_save_toolbar, {
                    show: _ctx.canSave,
                    ref: "saveToolbar",
                    class: "card-footer action-row temp-saving row",
                    label: _ctx.$t('import_file'),
                    cancelButton: true,
                    onSave: _ctx.handleSave,
                    onCancel: _ctx.handleCancel,
                    "success-text": _ctx.$t('import_initiated')
                  }, null, 8, ["show", "label", "onSave", "onCancel", "success-text"]),
                  _createVNode(_component_modal_section, {
                    modalId: "confirmImport",
                    ref: "confirmImport",
                    centered: true,
                    closeButton: false,
                    size: "md",
                    wide: false
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)
                    ]),
                    body: _withCtx(() => [
                      _createElementVNode("fieldset", null, [
                        _createElementVNode("small", {
                          innerHTML: _ctx.$t('please_confirm_details', { transplant_program: _ctx.buildTransplantCentreValue(this.editState.transplant_program), file_name: this.editState.filename })
                        }, null, 8, _hoisted_6)
                      ])
                    ]),
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("button", {
                          type: "button",
                          "data-dismiss": "modal",
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.importConfirmButton && _ctx.importConfirmButton(...args))),
                          class: "btn btn-success"
                        }, _toDisplayString(_ctx.$t('confirm')), 1),
                        _createElementVNode("button", {
                          type: "button",
                          "data-dismiss": "modal",
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.importCancelButton && _ctx.importCancelButton(...args))),
                          class: "btn btn-secondary"
                        }, _toDisplayString(_ctx.$t('cancel')), 1)
                      ])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }))
}