import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ObjectId } from '@/store/types';
import { AllocationState, Allocations, Allocation, ExclusionRules, AllocationChecklistResponse, AllocationChecklistForm, AllocationChecklist, RecipientDetails, DonorDetails } from '@/store/allocations/types';

export const mutations: MutationTree<AllocationState> = {
  setExclusionRules(state: AllocationState, exclusionRules: ExclusionRules[]) {
    state.exclusionRules = exclusionRules;
  },
  setAllocation(state: AllocationState, allocation: Allocation) {
    state.selected = allocation;
  },
  /**
   * Mutation to clear out a selected allocation so that the we can reset it.
   * @param state
   */
  clearAllocation(state: AllocationState) {
    [
      'selected',
      'exclusionRules',
      'checklist',
      'lastLoadedAllocationDonorId'
    ].forEach((key: string) => (state as any)[key] = undefined);
  },
  setAllocations(state: AllocationState, allocations: Allocations[]) {
    state.allAllocations = allocations;
  },
  setActiveAllocations(state: AllocationState, allocations: Allocations[]) {
    state.activeAllocations = allocations;
  },
  setInactiveAllocations(state: AllocationState, allocations: Allocations[]) {
    state.inactiveAllocations = allocations;
  },
  startCreatingAllocation(state: AllocationState) {
    state.isCreatingAllocation = true;
  },
  stopCreatingAllocation(state: AllocationState) {
    state.isCreatingAllocation = false;
  },
  setLastLoadedAllocationDonorId(state: AllocationState, donorId: string) {
    state.lastLoadedAllocationDonorId = donorId;
  },
  startLoading(state: AllocationState) {
    state.isLoadingAllocation = true;
  },
  startLoadingAllocations(state: AllocationState) {
    state.isLoadingAllocations = true;
  },
  stopLoading(state: AllocationState) {
    state.isLoadingAllocation = false;
  },
  stopLoadingAllocations(state: AllocationState) {
    state.isLoadingAllocations = false;
  },
  startGeneratingReport(state: AllocationState) {
    state.isGeneratingAllocationReport = true;
  },
  stopGeneratingReport(state: AllocationState) {
    state.isGeneratingAllocationReport = false;
  },
  startAddingRecipient(state: AllocationState) {
    state.isAddingRecipient = true;
  },
  stopAddingRecipient(state: AllocationState) {
    state.isAddingRecipient = false;
  },
  startDiscontinuingOneAllocation(state: AllocationState) {
    state.isDiscontinuingOneAllocation = true;
  },
  stopDiscontinuingOneAllocation(state: AllocationState) {
    state.isDiscontinuingOneAllocation = false;
  },
  startDiscontinuingAllAllocations(state: AllocationState) {
    state.isDiscontinuingAllAllocations = true;
  },
  stopDiscontinuingAllAllocations(state: AllocationState) {
    state.isDiscontinuingAllAllocations = false;
  },
  startDiscontinuingOneOffer(state: AllocationState) {
    state.isDiscontinuingOneOffer = true;
  },
  stopDiscontinuingOneOffer(state: AllocationState) {
    state.isDiscontinuingOneOffer = false;
  },
  startMakingOffer(state: AllocationState) {
    state.isMakingOffer = true;
  },
  stopMakingOffer(state: AllocationState) {
    state.isMakingOffer = false;
  },
  startRespondingOffer(state: AllocationState) {
    state.isRespondingOffer = true;
  },
  stopRespondingOffer(state: AllocationState) {
    state.isRespondingOffer = false;
  },
  startDecliningMultiple(state: AllocationState) {
    state.isDecliningMultiple = true;
  },
  stopDecliningMultiple(state: AllocationState) {
    state.isDecliningMultiple = false;
  },
  setDonorDetails(state: AllocationState, allocation: DonorDetails) {
    state.donorDetails = allocation;
  },
  clearDonorDetails(state: AllocationState) {
    state.donorDetails = undefined;
  },
  setRecipientDetails(state: AllocationState, recipientDetails: RecipientDetails) {
    state.recipientDetails = recipientDetails;
  },
  clearRecipientDetails(state: AllocationState) {
    state.recipientDetails = undefined;
  },
  setChecklist(state: AllocationState, checklist: AllocationChecklistForm) {
    state.checklist = checklist;
  },
  setChecklistDetails(state: AllocationState, checklist: AllocationChecklistResponse) {
    state.checklistDetails = checklist;
  },
  setChecklistHistory(state: AllocationState, checklist: AllocationChecklistResponse[]) {
    state.checklistHistory = checklist;
  }
};
