import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-12 mb-4" }
const _hoisted_6 = { id: "wrapper-scheduling" }
const _hoisted_7 = { class: "table-wrapper" }
const _hoisted_8 = { class: "table table-sm table-bordered" }
const _hoisted_9 = {
  scope: "row",
  class: "sp-th-row-heading th-row"
}
const _hoisted_10 = {
  scope: "row",
  class: "scheduling-row"
}
const _hoisted_11 = {
  scope: "row",
  class: "scheduling-row"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_14 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoSchedulingPreferencesEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_scheduling_preferences" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "scheduling-preferences-section-form" }, {
              contents: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  title: "SCHEDULING PREFERENCES",
                  "sub-section-id": "scheduling-preferences-section-internal",
                  hideDivider: true
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("fieldset", null, [
                          _cache[6] || (_cache[6] = _createElementVNode("small", { class: "w-100 d-flex mb-4" }, "Does the patient have any preferences for when to schedule appointments?", -1)),
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("table", _hoisted_8, [
                                    _createElementVNode("tbody", null, [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("th", _hoisted_9, [
                                          _cache[3] || (_cache[3] = _createElementVNode("td", {
                                            width: "12.5%",
                                            class: "empty-cell"
                                          }, null, -1)),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.schedulingPrefs, (item) => {
                                            return (_openBlock(), _createElementBlock("td", {
                                              width: "12.5%",
                                              scope: "column",
                                              class: "sp-th-row-heading",
                                              key: item.id
                                            }, _toDisplayString(item.label), 1))
                                          }), 128))
                                        ])
                                      ]),
                                      _createElementVNode("tr", _hoisted_10, [
                                        _cache[4] || (_cache[4] = _createElementVNode("td", { width: "12.5%" }, [
                                          _createElementVNode("span", { class: "empty-label" }, "AM")
                                        ], -1)),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.schedulingPrefs, (item, index) => {
                                          return (_openBlock(), _createElementBlock("td", {
                                            width: "12.5%",
                                            key: index
                                          }, [
                                            _createElementVNode("span", {
                                              class: _normalizeClass(["input-padding", item.availability_am.value ? `prefs-${(_ctx.getSchedulingValue(item.availability_am.value)).toLowerCase()}` : ''])
                                            }, [
                                              _createVNode(_component_select_input, {
                                                "select-id": "recipientAttachment-category",
                                                hideLabel: "true",
                                                name: item.availability_am.value,
                                                options: _ctx.schedulingOptions,
                                                modelValue: item.availability_am.value,
                                                "onUpdate:modelValue": ($event: any) => ((item.availability_am.value) = $event),
                                                onChange: ($event: any) => (_ctx.inputChanged('am', item.availability_am, index))
                                              }, null, 8, ["name", "options", "modelValue", "onUpdate:modelValue", "onChange"])
                                            ], 2)
                                          ]))
                                        }), 128))
                                      ]),
                                      _createElementVNode("tr", _hoisted_11, [
                                        _cache[5] || (_cache[5] = _createElementVNode("td", { width: "12.5%" }, [
                                          _createElementVNode("span", { class: "empty-label" }, "PM")
                                        ], -1)),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.schedulingPrefs, (item, index) => {
                                          return (_openBlock(), _createElementBlock("td", {
                                            width: "12.5%",
                                            key: index
                                          }, [
                                            _createElementVNode("span", {
                                              class: _normalizeClass(["input-padding", item.availability_pm.value ? `prefs-${(_ctx.getSchedulingValue(item.availability_pm.value)).toLowerCase()}` : ''])
                                            }, [
                                              _createVNode(_component_select_input, {
                                                "select-id": "recipientAttachment-category",
                                                hideLabel: "true",
                                                name: item.availability_pm.value,
                                                options: _ctx.schedulingOptions,
                                                modelValue: item.availability_pm.value,
                                                "onUpdate:modelValue": ($event: any) => ((item.availability_pm.value) = $event),
                                                onChange: ($event: any) => (_ctx.inputChanged('pm', item.availability_pm, index))
                                              }, null, 8, ["name", "options", "modelValue", "onUpdate:modelValue", "onChange"])
                                            ], 2)
                                          ]))
                                        }), 128))
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_checkbox_input, {
                                name: "employment",
                                labelName: "Employment",
                                inputId: "employment",
                                modelValue: _ctx.editState.employment,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.employment) = $event)),
                                label: "Yes"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_select_input, {
                                "select-id": "dialysis_modality",
                                name: "Dialysis Modality",
                                options: _ctx.dialysisModalityOptions,
                                modelValue: _ctx.editState.dialysis_modality,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.dialysis_modality) = $event))
                              }, null, 8, ["options", "modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_text_area_input, {
                                "input-id": "scheduling-comments",
                                name: "Comments",
                                rows: "4",
                                modelValue: _ctx.editState.comments,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.comments) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveSchedulingPreferences",
                  onSave: _ctx.performSave,
                  label: `Save ${_ctx.title}`,
                  cancelButton: true
                }, null, 8, ["onSave", "label"])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _cache[7] || (_cache[7] = _createTextVNode(" > "))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}