<template>
  <div class="card card-form" :class="getAccordionClass">
    <fieldset
      :id="sectionId"
      :disabled="saveButton ? disabled : false"
      >
      <legend v-if="showLegend">
        <h3 class="legend-title">
          <button v-if="enableAccordion" tabindex="0" type="button" class="btn btn-sm accordion-toggle" @click="toggleCardSection" aria-label="Toggle the section">
            <font-awesome-icon :icon="['fas', 'caret-down']" class="accordion-icon" :class="{ rotate: show }" />
          </button>
          <!-- If action buttons are rendered, the header text needs some extra classes to be properly aligned -->
          <span :class="{ 'pt-1': showActions, 'd-inline-block': showActions }">
            <slot name="header">
              {{$t('default_header')}}
            </slot>
          </span>
          <div class="btn-group float-right" role="group" aria-label="card section button group" v-if="showActions">
            <slot v-if="showActions" name="actions" />
          </div>
        </h3>
      </legend>
      <loading-section v-if="isLoading || showLoading"/>
      <template v-else>
        <transition
          name="accordion"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <div v-show="show" class="card-body">
            <slot name="body" />
            <save-toolbar
              class="action-row temp-saving"
              :show="saveButton"
              :disabled="disabled"
              buttonClass="btn btn-wide btn-success sec-submit"
              :label="saveButtonText"
              :ref="saveToolbarRef()"
              @save="performSave"
            />
          </div>
        </transition>
      </template>
    </fieldset>
  </div>
</template>

<style scoped>
  .accordion-icon {
    transform: rotate(270deg);
    transition-duration: 0.3s;
    cursor: pointer;
  }
  .accordion-icon.rotate {
    transform: rotate(360deg);
    transition-duration: 0.3s;
  }
</style>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveProvider, SaveResult } from '@/types';
import LoadingSection from '@/components/shared/loading-skeletons/LoadingSection.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    SaveToolbar,
    LoadingSection
  },
  ...i18nMessages([
    require('@/components/shared/_locales/CardSection.json'),
  ]),
  emits: [
    'loaded',
    'save',
  ],
})
export default class CardSection extends Vue implements SaveProvider {
  @Prop({ required: true }) sectionId!: string;
  @Prop({ default: true }) enableAccordion!: boolean; // show accordion control
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: false }) showLoading!: boolean;
  @Prop({ default: true }) showLegend!: boolean;
  @Prop({ default: false }) saveButton!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 'Save' }) saveButtonText!: string;
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];
  @Prop({ required: false }) confirmation?: string;

  // Set this to true to show the action button slot. Using a dedicated boolean flag lets the style classes adapt.
  @Prop({ default: false }) showActions!: boolean;

  public show = true;
  public isLoading = false;

  private lookupsLoaded: string[] = [];
  private laboratoriesLoaded: string[] = [];
  private hospitalsLoaded: string[] = [];

  private toggleCardSection(): void {
    this.show = !this.show;
  }

  private get getAccordionClass(): string|null {
    return this.enableAccordion && !this.show ? 'accordion-closed' : null; 
  }

  get numberOfItemsToLoad(): number {
    const lookupsCheck = this.lookupsToLoad || [];
    const labsCheck = this.laboratoriesToLoad || [];
    const hospitalCheck = this.hospitalsToLoad || [];
    return lookupsCheck.length + labsCheck.length + hospitalCheck.length;
  }

  get numberOfItemsLoaded(): number {
    return this.lookupsLoaded.length + this.laboratoriesLoaded.length + this.hospitalsLoaded.length;
  }

  public mounted(): void {
    // Load any lookups if we have them
    if (this.numberOfItemsToLoad > 0) {
      this.getLookups(this.lookupsToLoad, this.laboratoriesToLoad, this.hospitalsToLoad);
    } else {
      this.$emit('loaded');
    }
  }

  getLookups(lookupsToLoad: string[], laboratoriesToLoad: string[], hospitalsToLoad: string[]) {
    this.isLoading = true;

    lookupsToLoad.forEach(item => {
      // load them
      this.$store.dispatch('lookups/queueLookup', {lookup: item})
      .then(() => {
        this.lookupsLoaded.push(item);
        this.checkLoaded();
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });

    laboratoriesToLoad.forEach(item => {
      // load them
      this.$store.dispatch('laboratories/load', item)
      .then(() => {
        this.laboratoriesLoaded.push(item);
        this.checkLoaded();
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });

    hospitalsToLoad.forEach(item => {
      this.$store.dispatch('hospitals/load')
      .then(() => {
        this.hospitalsLoaded.push(item);
        this.checkLoaded();
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });
  }

  private checkLoaded(): void {
    if (this.numberOfItemsLoaded === this.numberOfItemsToLoad) {
      this.isLoading = false;
      this.$emit('loaded');
    }
  }

  beforeEnter(el: HTMLElement) {
    el.style.height = '0';
  }
  enter(el: HTMLElement) {
    el.style.height = el.scrollHeight + 'px';
  }
  beforeLeave(el: HTMLElement) {
    el.style.height = el.scrollHeight + 'px';
  }
  leave(el: HTMLElement) {
    el.style.height = '0';
  }

  // Generate a unique reference for the save toolbar using the card section ID
  private saveToolbarRef(): string {
    return `save-${this.sectionId}`;
  }

  // Use dynamic reference to refer to the save toolbar associated with this specific card section
  private saveToolbar(): SaveToolbar {
    return this.$refs[this.saveToolbarRef()] as SaveToolbar;
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Show confirmation prompt if necessary
    if (this.confirmation !== undefined) {
      const confirmed = confirm(this.confirmation);
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
    }
    // Show saving notification
    this.saveToolbar().startSaving();
    // Report save event to parent
    this.$emit('save');
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    // Show appropriate saving notification
    this.saveToolbar().stopSaving(result);
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    const saveToolbar = this.saveToolbar();
    if(saveToolbar) this.saveToolbar().reset();
  }
}
</script>
