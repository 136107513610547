<template>
  <!-- measurements type -->
  <sub-section
    :title='$t("measurements_title")'
    sub-section-id="gci-measurements"
    ref="measurements">
    <template v-slot:contents>
      <!-- measurements content -->

      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="canSave"
        :createText="$t('create_measurement')"
        @table-create-row="handleTableCreateRow"
      />

      <!-- List of Items, or History List -->
      <table-list
        ref="patientMeasurementsTable"
        table-id="patient-measurements-table"
        tabbableColumn="date"
        :table-config="patientMeasurementsTableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
        :isLoading="isLoading"
      />

      <validation-observer ref="validations">
        <form-layout
          :disabled="!enableForm"
          form-id="measurement_form">
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 v-if="!selection?.isNew" class="legend-title">
                {{$t('selected_measurement')}}
              </h5>
              <h5 v-else class="legend-title">
                {{$t('new_measurement')}}
              </h5>
            </legend>
          </template>

          <template v-slot:contents>
            <!-- Data Entry Form (Add, View, and Edit modes) -->
            <div class="row d-flex">
              <div class="measurements-form-group">
                <date-input
                  ruleKey="recipient_measurements.date"
                  inputId="gci-measurements-date"
                  :name="$t('measurement_date')"
                  v-model="editState.date"
                />
              </div>
              <div class="measurements-form-group">
                <text-input
                  inputId="gci-measurements-bmi"
                  :name="$t('bmi')"
                  v-model="editState.bmi"
                  :calculated="true"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="row d-flex">
              <div class="measurements-form-group measureUnits">
                <div class="inner">
                  <div class="form-group">
                    <p>{{$t('metric')}}</p>
                    <number-input
                      ruleKey="recipient_measurements.weight_kg"
                      inputId="gci-measurements-weight_kg"
                      :name="$t('weight')"
                      :append="true"
                      appendText="kg"
                      step="0.1"
                      :calculated="true"
                      calculatedText=""
                      @change="calculateMeasurementFromKg($event?.target?.value)"
                      v-model="editState.weight_kg"
                    />
                  </div>
                  <div class="form-group">
                    <number-input
                      ruleKey="recipient_measurements.height_cm"
                      inputId="gci-measurements-height_cm"
                      :name="$t('height')"
                      :append="true"
                      appendText="cm"
                      step="0.1"
                      :calculated="true"
                      calculatedText=""
                      @change="calculateMeasurementFromCm($event?.target?.value)"
                      v-model="editState.height_cm"
                    />
                  </div>
                </div>
              </div>
              <div class="measurements-form-group measureUnits last">
                <div class="inner">
                  <p>{{$t('imperial')}}</p>
                  <div class="form-group">
                    <number-input
                      inputId="gci-measurements-weight_lbs"
                      :name="$t('weight')"
                      :append="true"
                      appendText="lbs"
                      step="0.1"
                      :calculated="true"
                      calculatedText=""
                      @change="calculateMeasurementFromLbs($event?.target?.value)"
                      v-model="editState.weight_lbs"
                    />
                  </div>
                  <div class="form-group">
                    <number-input
                      inputId="gci-measurements-height_in"
                      :name="$t('height')"
                      :append="true"
                      appendText="in"
                      step="0.1"
                      :calculated="true"
                      calculatedText=""
                      @change="calculateMeasurementFromIn($event?.target?.value)"
                      v-model="editState.height_in"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="showSaveToolbar"
              ref="saveMeasurement"
              class="card-footer action-row temp-saving row"
              :label="$t('save_measurement')"
              :cancelButton="true"
              @save="handleSave()"
              @cancel="handleCancel()"
            />
          </template>      

        </form-layout>
      </validation-observer>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State } from 'vuex-facing-decorator';
import { BmiResult } from '@/store/tools/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { SaveResult, APIPermittedActions, APIResponseWithPermittedActions } from "@/types";
import { Component, Prop } from 'vue-facing-decorator';
import { RecipientValidations } from '@/store/recipients/types';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { IdLookupProvider } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import { UIMeasurement } from "@/UIModels/recipients/measurements";
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { i18nMessages } from "@/i18n";
import { parseFormErrors, toFixed } from '@/utils';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { EP } from '@/api-endpoints';
import { BmiCalculationParams } from '@/store/tools/actions';

interface MeasurementRow {
  id: string;
  date: string;
  height: string;
  weight: string;
  bmi: string;
}

// What are all the possible per-page sizes allowed in the table list pagination bar?
// NOTE: The list displays 10 records by default, with pagination (AP-1490)
// See: https://shorecg.atlassian.net/wiki/spaces/AP/pages/1581383697/Paginated+Table
// TODO: TECH_DEBT: can page sizes be moved to application level somehow
const PAGE_SIZES = [5, 10, 25];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[1]; // 10

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput,
    TableList,
    TableToolbar,
    FormLayout
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
})
export default class MeasurementsForm extends mixins(DateUtilsMixin) implements IdLookupProvider {
  // State
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;
  @State(state => state.tools.bmiResult) bmiResult!: BmiResult;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Selection instance
  private selection = new UIListFormSelection();

  // Editable view model for the form
  private editState = UIMeasurement.buildNew();
  private permittedActions: string[] = [];

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Lookup tables to be loaded by the CardSection component
  private isLoading = true;

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: any): string|null {
    if (!row) return null;
    return null;
  }

  private async mounted(): Promise<void> {
    if (!this.currentRecipient) return;

    try {
      await this.queryNewRules();
      await this.currentRecipient.loadMeasurements();
      this.isLoading = false;
    } catch (uiError: unknown) {
      this.isLoading = false;
      console.warn('Something unexpected happened when attempting to get measurements information', uiError);
    }
  }

  // Load #edit rules
  private queryEditRules(): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.measurements.show,
      payload: [[':recipientId', this.currentRecipient.clientId], [':id', this.selection.id]],
      prefix: 'recipient_measurements',
    });
  }

  // Load #new rules
  private queryNewRules(): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.measurements.index,
      payload: [[':recipientId', this.currentRecipient.clientId]],
      prefix: 'recipient_measurements',
    });
  }

  private async prepareNewEntry(): Promise<void> {
    await this.queryNewRules();
    this.editState = UIMeasurement.buildNew();
    this.permittedActions = [];
    this.resetFormErrors();
  }

  // Select a Measurement UI Model List item from the list based on a row click event
  private async prepareEditEntry(): Promise<void> {
    try {
      await this.queryEditRules();
      const uiMeasurements: UIMeasurement[] = this.currentRecipient?.measurements || [];
      const listItem = uiMeasurements.find((listItem: UIMeasurement) => { return listItem.id == this.selection.id; });
      if (!listItem) return;

      this.editState = listItem.copyViewModel();
      this.permittedActions = this.editState.permitted_actions;

      // Sync metric/imperial/bmi
      this.calculateMeasurementFromKg(this.editState.weight_kg);
    } catch(err) {
      console.warn(err, 'Something unexpected happen when attempting to load measurement details');
    }
    this.resetFormErrors();
  }

  // Configure the list table
  get patientMeasurementsTableConfig(): TableConfig {
    return {
      data: this.patientMeasurementsRows,
      columns: [
        { label: this.$t('measurement_date').toString(), field: 'date' },
        { label: this.$t('weight').toString(), field: 'weight', class: 'small-width-column' },
        { label: this.$t('height').toString(), field: 'height', class: 'small-width-column' },
        { label: this.$t('bmi').toString(), field: 'bmi', class: 'x-small-width-column' },
      ],
      empty: this.$t('measurements_table_empty').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'measurement_date', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // How many measurement items does this Patient have based on the current filter assignments?
  // NOTE: make sure to do filtering before counting records
  get totalRecords(): number {
    if (!this.patientMeasurementsRows) return 0;

    return this.patientMeasurementsRows.length;
  }

  // Map from UI Measurements view models from current page pinia store UI Recipient view model to row interface
  get patientMeasurementsRows(): MeasurementRow[] {
    if (!this.currentRecipient) return [];

    const uiMeasurements: UIMeasurement[] = this.currentRecipient.measurements || [];
    const rows = uiMeasurements.map((measurement: UIMeasurement): MeasurementRow => {
      const row: MeasurementRow = this.buildRow(measurement);
      return row;
    });
    return rows;
  }

  // Map from UI Address view model to row interface for this component's table
  private buildRow(uiModel: UIMeasurement): MeasurementRow {
    return {
      id: uiModel.id || '',
      date: this.parseDisplayDateUiFromDateTime(uiModel.date) || '-',
      height: uiModel.height_cm == null ? '-' : `${uiModel.height_cm} ${this.$t('cm')} (${uiModel.height_in} ${this.$t('in')})`,
      weight: uiModel.weight_kg == null ? '-' : `${uiModel.weight_kg} ${this.$t('kg')} (${uiModel.weight_lbs} ${this.$t('lbs')})`,
      bmi: uiModel.bmi == null ? '-' : uiModel.bmi.toString(),
    };
  }

  get patientMeasurementsTable(): TableList {
    return this.$refs.patientMeasurementsTable as TableList;
  }

  private handleTableCreateRow(): void {
    this.patientMeasurementsTable.resetSelection();
    this.selection = new UIListFormSelection();

    this.prepareNewEntry();
    this.resetSaveToolbar();
  }

  // Select an address UI Model List item from the list based on a row click event
  private handleTableRowClick(event: { row: MeasurementRow }): void {
    const uiMeasurements: UIMeasurement[] = this.currentRecipient?.measurements || [];
    const listItem = uiMeasurements.find((listItem: UIMeasurement) => { return listItem.id == event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.prepareEditEntry();
    this.resetSaveToolbar();
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process save button click event
  public async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();

    const params = {
      selected: this.editState,
      recipient: this.currentRecipient
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private async handleSuccess(success: SaveResult): Promise<void> {
    if (this.saveToolbar) {
      this.saveToolbar.stopSaving(success);
    }
    try {
      this.patientMeasurementsTable.resetSelection();
      this.selection = new UIListFormSelection();
      this.prepareNewEntry();
      this.isLoading = true;
      await this.currentRecipient.loadMeasurements();
      this.isLoading = false;
    } catch(err) {
      console.warn(err, 'Something unexpected happen when attempting to load measurement details');
    }
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    // Re-initialize form
    if (this.selection.isNew) {
      this.prepareNewEntry();
    } else {
      this.prepareEditEntry();
    }
    this.resetSaveToolbar();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveMeasurement as SaveToolbar;
  }

  public idLookup(): {[key: string]: string} {
    const result = {
      'recipient_measurements.date'      : 'gci-measurements-date',
      'recipient_measurements.height_cm' : 'gci-measurements-height_cm',
      'recipient_measurements.weight_kg' : 'gci-measurements-weight_kg',
    };
    return result;
  }

  // Updates BMI, Height and Weight from API triggered on change
  // from height / weight fields, or selecting a measurement
  private async calculateMeasurementBmi(params: BmiCalculationParams): Promise<void> {
    try {
      await this.$store.dispatch('tools/loadBmiHeightWeight', params);
      // update pageState with new values
      this.editState.updateFromBMIResult(this.bmiResult);
    } catch (error: any) {
      console.warn(error.description);
    }
  }

  private calculateMeasurementFromKg(newWeightKgValue: string|null): void {
    this.calculateMeasurementBmi({
      height: this.editState.height_cm,
      height_unit: 'cm',
      weight: newWeightKgValue,
      weight_unit: 'kg',
    });
  }

  private calculateMeasurementFromCm(newHeightCmValue: string|null): void {
    this.calculateMeasurementBmi({
      height: newHeightCmValue,
      height_unit: 'cm',
      weight: this.editState.weight_kg,
      weight_unit: 'kg',
    });
  }

  private calculateMeasurementFromLbs(newWeightKgValue: string|null): void {
    this.calculateMeasurementBmi({
      height: this.editState.height_cm,
      height_unit: 'cm',
      weight: newWeightKgValue,
      weight_unit: 'lbs',
    });
  }

  private calculateMeasurementFromIn(newHeightInValue: string|null): void {
    this.calculateMeasurementBmi({
      height: newHeightInValue,
      height_unit: 'in',
      weight: this.editState.weight_kg,
      weight_unit: 'kg',
    });
  }
}
</script>
