<template>
  <div class="filter-wrapper" v-if="showFilter">
    <template v-if="isMobile">
      <button class="btn btn-outline btn-sm filterButton" type="button" id="dropdownMenuButton"
        @click.prevent="toggleDropdown" :aria-label="$t('toggle_accordion_section')">
        {{ getTitle }}
        <font-awesome-icon :icon="['fas', 'caret-down']" class="accordion-icon"/>
      </button>

      <div v-if="showDropdown" class="filter-dropmenu" aria-labelledby="dropdownMenuButton">
        <button @click.prevent="toggleDropdown" class="btn btn-sm closeDropdown" >
          <font-awesome-icon :icon="['fas', 'times']" class="nav-caret" fixed-width />
        </button>
        <filter-links
          :fieldID="fieldID" 
          :showFilter="showNotesFilter"
          :showNotesFilter="showNotesFilter"
          :showChecklistFilter="showChecklistFilter"
          :showImportDetailsModalFilter="showImportDetailsModalFilter"
          :keywordSearch="keywordSearch" 
          :tagOptions="tagOptions" 
          :authorOptions="authorOptions" 
          :showArchived="showArchived"
          :excludeCompleted="excludeCompleted"
          :statusOptions="statusOptions"
          @setFilters="setFilters" 
          @clearFilters="clearFilters"
          :selectedFilters="selectedFilters"
        />
      </div>     
    </template>

    <template v-else>
      <h5 class="legend-title" v-if="title">
        {{ getTitle }}
      </h5>

      <div :class="getFilterCss">
        <filter-links
          :fieldID="fieldID" 
          :showFilter="showNotesFilter"
          :showNotesFilter="showNotesFilter"
          :showChecklistFilter="showChecklistFilter"
          :showImportDetailsModalFilter="showImportDetailsModalFilter"
          :keywordSearch="keywordSearch" 
          :tagOptions="tagOptions" 
          :authorOptions="authorOptions" 
          :showArchived="showArchived"
          :excludeCompleted="excludeCompleted"
          :statusOptions="statusOptions"
          :hideSpacer="hideSpacer"
          @setFilters="setFilters" 
          @clearFilters="clearFilters"
          :selectedFilters="selectedFilters"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import FilterLinks from '@/components/shared/filter/FilterLinks.vue';
import { GenericCodeValue } from '@/store/types';
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    TextInput,
    SelectInput,
    DateInput,
    FilterLinks
  },
  ...i18nMessages([
    require('@/components/shared/filter/FilterComponent.json'),
  ]),
  emits: [
    'setFilters'
  ],
})
export default class FilterComponent extends Vue {
  @Prop({ default: null }) title!: string;
  @Prop({ required: true }) fieldID!: string;
  @Prop({ default: false }) showFilter!: boolean;
  @Prop({ default: false }) showNotesFilter!: boolean;
  @Prop({ default: false }) showChecklistFilter!: boolean;
  @Prop({ default: false }) showImportDetailsModalFilter!: boolean;
  @Prop({ default: () => { return [] } }) tagOptions!: GenericCodeValue[];
  @Prop({ default: () => { return [] } }) authorOptions!: GenericCodeValue[];
  @Prop({ default: () => { return [] } }) statusOptions!: GenericCodeValue[];
  @Prop({ default: null }) keywordSearch!: string;
  @Prop({ default: false }) showArchived!: boolean;
  @Prop({ default: false }) excludeCompleted!: boolean;
  @Prop({ default: false}) hideSpacer!: boolean;
  @Prop({ default: false}) alignLeft!: boolean;

  selectedFilters: UIFilterAssignments = {
    date_from: null,
    date_to: null,
    note_tags: [],
    user_id: [],
    keywords: null,
    include_archived: false,
    exclude_completed: false,
    checklist_status: null 
  };

  clearFilters(): void {
    this.selectedFilters = {
      date_from: null,
      date_to: null,
      user_id: [],
      note_tags: [],
      keywords: null,
      include_archived: false,
      exclude_completed: false,
      checklist_status: null
    };
    this.$emit('setFilters', this.selectedFilters);
    this.toggleDropdown();
  }

  get getFilterCss(): string {
    return this.alignLeft ? 'filter-section filter-section-left' : 'filter-section';
  }

  get getTitle(): string {
    return this.title ? this.title : this.$t('title').toString();
  }

  private showDropdown = false;
  private isMobile = false;

  private mounted(): void {
    window.addEventListener('resize', this.checkwidth);
    this.checkwidth();
  }

  public checkwidth() {
    this.isMobile = window.innerWidth < 1400;
  }

  toggleDropdown() {
    if (this.isMobile) {
      this.showDropdown = !this.showDropdown;
    }
  }

  private setFilters(FilterObject: UIFilterAssignments): void {
    this.$emit('setFilters', FilterObject);
    this.toggleDropdown();
  }
}
</script>
