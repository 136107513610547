import { GetterTree } from 'vuex';
import { RootState, GenericCodeValue } from '@/store/types';
import { SurgicalPersonsState, SurgicalPerson, SurgicalPersonHospitalAssignemnts, SurgicalPersonOrganClearances, SurgicalPersonOptions } from '@/store/surgicalPersons/types';

export const getters: GetterTree<SurgicalPersonsState, RootState> = {

  surgicalPersonOptions(state, getters): SurgicalPersonOptions[] {
    const people: SurgicalPerson[] = state.surgicalPeopleFiltered;
    if (people && people.length > 0) {
      return people.map((person: SurgicalPerson) => {
        const hospital_assignments = person.hospital_assignments ? person.hospital_assignments.map((item: SurgicalPersonHospitalAssignemnts) => { return item.hospital_id; }) : [];
        const organ_clearances = person.organ_clearances ? person.organ_clearances.map((item: SurgicalPersonOrganClearances) => { 
          return {
            organ_code: item.organ_code.toString(),
            organ_recovery: item.organ_recovery,
            organ_transplant: item.organ_transplant,
            expiry_date: item.expiry_date,
          };
        }) : [];
        return {
          code: person._id,
          value: `${person.first_name} ${person.last_name}`,
          hospital_assignments: hospital_assignments,
          organ_clearances: organ_clearances,
        };
      });
    }
    return [];
  },
  /**
   * Return surgical persons assigned to the passed hospital id
   * 
   * @param hospitalId hospital oid
   * @returns {SurgicalPersonOptions[]} array of surgical persons assigned to hospital
   */
  surgicalPersonsByHospitalId(state, getters, rootState, rootGetters) {
    return (hospitalId: string): SurgicalPersonOptions[] => {
      const surgicalPeople = getters.surgicalPersonOptions;
      let filteredPhysicians = [];
      if (hospitalId) {
        filteredPhysicians = surgicalPeople.filter((item: SurgicalPersonOptions) => {
          return item.hospital_assignments.includes(hospitalId);
        });
      } else {
        return [];
      }
      return filteredPhysicians;
    };
  },

  /**
   * Return surgical person options assigned to the hospitalId matching the organCode and have organ_transplant as true
   * 
   * @param hospitalId hospital oid
   * @param organCode organ code
   * @returns {SurgicalPersonOptions[]} array of surgical person options
   */
  surgicalPersonsByOrganClearance(state, getters, rootState, rootGetters) {
    return (hospitalId: string, organCode: string): SurgicalPersonOptions[] => {
      const surgicalPeopleByHospital = getters.surgicalPersonsByHospitalId(hospitalId);
      let filteredPhysicians = [];
      if (hospitalId) {
        const filterExpired = rootGetters['lookups/filterExpired'];
        filteredPhysicians = filterExpired(surgicalPeopleByHospital);
        filteredPhysicians = filteredPhysicians.filter((item: SurgicalPersonOptions) => { 
          const filteredClearances = item.organ_clearances.find((clearance: SurgicalPersonOrganClearances) => {
            const organ_codes = clearance.organ_code;
            const organ_transplant = clearance.organ_transplant; 
            return organ_codes.includes(organCode) && organ_transplant;
          });
          return filteredClearances;
        });
      } else {
        return [];
      }
      return filteredPhysicians;
    };
  },
};