<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item">
        <span class="nav-link">
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          <slot name="profile-return-link" />
        </span>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav" v-if="isProtoOfferStatisticsEnabled">
          <a class="nav-link" 
            :href="getUrl('proto_offers_statistics')">
            Offer Statistics
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="isProtoCurrentOffersEnabled">
          <a class="nav-link" 
            :href="getUrl('proto_current_offers')">
            Current Offers 
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="isProtoOffersHistoryEnabled">
          <a class="nav-link" 
            :href="getUrl('proto_offers_history')">
            Offer History
          </a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Recipient } from "@/store/recipients/types";
import { useCurrentPageStore } from '@/stores/currentPage';
import { i18nMessages } from '@/i18n';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

@Component({
  ...i18nMessages([
    require('@/components/organs/shared/_locales/OrganDetailsSection.json'),
    require('@/components/recipients/_locales/SelectedRecipientProfileLinks.json'),
  ]),
})
export default class ProtoOffersLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({ default: false }) active!: boolean;
  @Prop({ default: false }) newRecipient!: boolean;

  public show = true;


  get isProtoOffersHistoryEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoOffersHistory);
  }

  get isProtoOfferStatisticsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoOffersStatistics);
  }

  get isProtoCurrentOffersEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoCurrentOffers);
  }

  public toggleNav() {
    this.show = !this.show;
  }

  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'proto-offers-pages', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
</script>
