import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_form = _resolveComponent("loading-form")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": "recipientDialysis-form"
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            (_ctx.selection?.isNew)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('dialysis_new_record')), 1))
              : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('dialysis_selected_record')), 1))
          ])
        ]),
        contents: _withCtx(() => [
          (!_ctx.editState.loaded)
            ? (_openBlock(), _createBlock(_component_loading_form, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_select_input, {
                      ruleKey: "patient_dialysis.reason_code",
                      "select-id": "dialysis_reason_code",
                      name: _ctx.$t('dialysis_reason_for_dialysis'),
                      rules: "required",
                      options: _ctx.reasonOptions,
                      modelValue: _ctx.editState.reason_code,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.reason_code) = $event))
                    }, null, 8, ["name", "options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_date_input, {
                      ruleKey: "patient_dialysis.start_date",
                      inputId: "dialysis_start_date",
                      name: _ctx.$t('dialysis_start_date'),
                      rules: "required",
                      modelValue: _ctx.editState.start_date,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.start_date) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_date_input, {
                      ruleKey: "patient_dialysis.end_date",
                      inputId: "dialysis_end_date",
                      name: _ctx.$t('dialysis_end_date'),
                      modelValue: _ctx.editState.end_date,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.end_date) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_select_input, {
                      ruleKey: "patient_dialysis.dialysis_type_code",
                      "select-id": "dialysis_type_code",
                      name: _ctx.$t('dialysis_type'),
                      options: _ctx.typeOptions,
                      modelValue: _ctx.editState.dialysis_type_code,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.dialysis_type_code) = $event))
                    }, null, 8, ["name", "options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_select_input, {
                      ruleKey: "patient_dialysis.access_method_code",
                      "select-id": "dialysis_access_method_code",
                      name: _ctx.$t('dialysis_access_mode'),
                      options: _ctx.accessModeOptions,
                      modelValue: _ctx.editState.access_method_code,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.access_method_code) = $event))
                    }, null, 8, ["name", "options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_select_input, {
                      ruleKey: "patient_dialysis.access_laterality_code",
                      "select-id": "dialysis_access_laterality_code",
                      name: _ctx.$t('dialysis_access_laterality'),
                      options: _ctx.lateralityOptions,
                      modelValue: _ctx.editState.access_laterality_code,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.access_laterality_code) = $event))
                    }, null, 8, ["name", "options", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_select_input, {
                      ruleKey: "patient_dialysis.location_type_code",
                      "select-id": "dialysis_location_type_code",
                      name: _ctx.$t('dialysis_location'),
                      options: _ctx.locationOptions,
                      modelValue: _ctx.editState.location_type_code,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.location_type_code) = $event)),
                      onChange: _ctx.clearFacilityValues
                    }, null, 8, ["name", "options", "modelValue", "onChange"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_text_input, {
                      ruleKey: "patient_dialysis.facility_name",
                      inputId: "dialysis_facility_name",
                      name: _ctx.$t('dialysis_facility_name'),
                      disabled: !_ctx.onlyIfValue(_ctx.editState, 'patient_dialysis.facility_name'),
                      modelValue: _ctx.editState.facility_name,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.facility_name) = $event))
                    }, null, 8, ["name", "disabled", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_text_input, {
                      ruleKey: "patient_dialysis.facility_phone_number",
                      inputId: "dialysis_facility_phone_number",
                      name: _ctx.$t('dialysis_facility_phone_number'),
                      mask: _ctx.getMaskFromRules('patient_dialysis.facility_phone_number'),
                      disabled: !_ctx.onlyIfValue(_ctx.editState, 'patient_dialysis.facility_phone_number'),
                      modelValue: _ctx.editState.facility_phone_number,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.facility_phone_number) = $event))
                    }, null, 8, ["name", "mask", "disabled", "modelValue"])
                  ])
                ])
              ]))
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.enableSaveToolbar,
            ref: "saveToolbar",
            class: "card-footer action-row temp-saving row",
            label: _ctx.$t('dialysis_save_record'),
            cancelButton: true,
            onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleSave())),
            onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleCancel()))
          }, null, 8, ["show", "label"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 512))
}