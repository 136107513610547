import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group-6column-large-up" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_13 = { class: "standard-form-group-6column-large-up" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "clinical-information",
    saveButtonText: `${_ctx.$t('save_clinical_information')}`,
    ref: "saveClinicalInformation",
    "lookups-to-load": _ctx.lookupsToLoad,
    saveButton: !_ctx.newDonor,
    disabled: !_ctx.canSave,
    onSave: _cache[15] || (_cache[15] = ($event: any) => (_ctx.savePatch())),
    onLoaded: _cache[16] || (_cache[16] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('clinical_information')), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_select_input, {
                  ruleKey: "blood.type",
                  selectId: "clinicalInformation-abo",
                  rules: "required",
                  name: _ctx.$t("abo"),
                  modelValue: _ctx.editState.abo,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.abo) = $event)),
                  options: _ctx.bloodTypesForDonor,
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearBloodSubType()))
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              (_ctx.showAboSubType)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_select_input, {
                      ruleKey: "blood.sub_type",
                      selectId: "clinicalInformation-abo_sub_type",
                      name: _ctx.$t("abo_sub_type"),
                      modelValue: _ctx.editState.aboSubType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.aboSubType) = $event)),
                      options: _ctx.bloodSubTypeOptions
                    }, null, 8, ["name", "modelValue", "options"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_select_input, {
                  ruleKey: "blood.rh",
                  selectId: "clinicalInformation-rh",
                  name: _ctx.$t("rh"),
                  modelValue: _ctx.editState.rh,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.rh) = $event)),
                  options: _ctx.rhIndicatorsForDonor
                }, null, 8, ["name", "modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_number_input, {
                  ruleKey: "measurements.height",
                  inputId: "clinicalInformation-height",
                  name: _ctx.$t("height"),
                  append: true,
                  appendText: "cm",
                  step: "0.1",
                  modelValue: _ctx.editState.height,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.height) = $event)),
                  warningParams: { min: 30, max: 215, message: 'Warning: Normal range for Height is 30 to 215 cm'},
                  onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.calculateMeasurement()))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_number_input, {
                  ruleKey: "measurements.weight",
                  inputId: "clinicalInformation-weight",
                  name: _ctx.$t("weight"),
                  append: true,
                  appendText: "kg",
                  step: "0.1",
                  modelValue: _ctx.editState.weight,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.weight) = $event)),
                  warningParams: { min: 1, max: 225, message: 'Warning: Normal range for weight is 1 to 225 kg'},
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.calculateMeasurement()))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_text_input, {
                  inputId: "clinicalInformation-bmi",
                  name: _ctx.$t("bmi"),
                  modelValue: _ctx.editState.bmi,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.bmi) = $event)),
                  calculated: true,
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.editState.totalLungCapacity)
                  ? (_openBlock(), _createBlock(_component_number_input, {
                      key: 0,
                      ruleKey: "measurements.total_lung_capacity",
                      inputId: "clinicalInformation-total_lung_capacity",
                      name: _ctx.$t("total_lung_capacity"),
                      modelValue: _ctx.editState.totalLungCapacity,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.totalLungCapacity) = $event)),
                      disabled: true,
                      calculated: true,
                      step: "0.01",
                      min: "0",
                      max: "999.99",
                      warningParams: { min: 0, max: 999, message: _ctx.$t('warning_negative_tlc')}
                    }, null, 8, ["name", "modelValue", "warningParams"]))
                  : (_openBlock(), _createBlock(_component_text_input, {
                      key: 1,
                      inputId: "clinicalInformation-total_lung_capacity",
                      name: _ctx.$t("total_lung_capacity"),
                      value: _ctx.tlcNotEnoughInformation(),
                      calculated: true,
                      disabled: true,
                      warningParams: { showWarning: _ctx.SHOW_TLC_WARNING, message: _ctx.tlcWarningMessage() }
                    }, null, 8, ["name", "value", "warningParams"]))
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "row-break d-none d-xxl-block" }, null, -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_select_input, {
                  ruleKey: "death.cause_of_death_code",
                  selectId: "clinicalInformation-cause_of_death_code",
                  name: _ctx.$t("cause_of_death"),
                  modelValue: _ctx.editState.causeOfDeathCode,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.causeOfDeathCode) = $event)),
                  options: _ctx.causeOfDeathDonor
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_date_input, {
                  ruleKey: "organ_retrieval_surgery.life_support_withdrawal_date",
                  inputId: "clinicalInformation-withdrawal_of_life_support_date",
                  name: _ctx.$t("withdrawal_of_life_support_date"),
                  modelValue: _ctx.editState.withdrawalOfLifeSupportDate,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.withdrawalOfLifeSupportDate) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_time_input, {
                  inputId: "clinicalInformation-withdrawal_of_life_support_time",
                  name: _ctx.$t("withdrawal_of_life_support_time"),
                  modelValue: _ctx.editState.withdrawalOfLifeSupportTime,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.withdrawalOfLifeSupportTime) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "row-break d-none d-lg-block d-xl-none d-xxl-block" }, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_select_input, {
                  ruleKey: "death.hospital_id_declared",
                  selectId: "clinicalInformation-declaration_hospital",
                  name: _ctx.$t("declaration_hospital"),
                  modelValue: _ctx.editState.declarationHospital,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.declarationHospital) = $event)),
                  options: _ctx.referralHospitalsList
                }, null, 8, ["name", "modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_date_input, {
                  ruleKey: "death.first_declare_date",
                  inputId: "clinicalInformation-declaration_date",
                  name: _ctx.$t("declaration_date"),
                  modelValue: _ctx.editState.declarationDate,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.declarationDate) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ])
          ], 8, _hoisted_1))
    ]),
    _: 1
  }, 8, ["saveButtonText", "lookups-to-load", "saveButton", "disabled"]))
}