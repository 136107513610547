<template>
  <div>
    <div class="content-wrap page-top">
      <div class="container-fluid">
        <h2>{{ $t('dashboard') }}</h2>
        <div class="page-top-right">
          <div class="system-title d-inline">{{ uiEnvVersion }}&nbsp;</div>

          <div class="dropdown b-dropdown menu-big page-top-link">
            <a
              href="#"
              class="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="customizeDash"
            >
              <font-awesome-icon :icon="['far', 'cog']" /> {{ $t('customize') }}
            </a>

            <div
              class="dropdown-menu keep-open dropdown-menu-end"
              aria-labelledby="customizeDash"
              style="max-width: 350px;"
            >
              <div class="container">
                <div class="row">
                  <form>
                    <div class="col-sm-12">
                      <h5 class="menu-big-heading">{{$t('available_dashboard_panels')}}</h5>
                    </div>
                    <div class="col-sm-12" v-if="!uiDashboard.loaded">
                      <span class="skeleton-box w-100" style="height: 30px;" />
                    </div>
                    <div class="col-sm-12" v-else>
                      <span v-if="uiDashboard.widgets.length === 0">
                        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                        {{ $t('no_widgets_available') }}
                      </span>
                      <ul v-else class="nav flex-column" v-for="(uiWidget) in uiDashboard.widgets" :key="uiWidget.name">
                        <li>
                          <div class="checklist border-0">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input d-none"
                                :id="`${uiWidget.name}-visible`"
                                v-model="uiWidget.visible"
                                :disabled="uiWidget.required"
                                />
                              <label class="form-check-label" :for="`${uiWidget.name}-visible`">
                                <font-awesome-icon :icon="['far', uiWidget.visible ? 'check-square' : 'square']" /> {{ $t('show') }}
                                <span>{{ $t(uiWidget.name) }}</span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-sm-12" v-if="errorMessage">
                      <p>{{ errorMessage }}</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="big-menu-actions">
                        <button
                          type="button"
                          class="btn btn-sm btn-success mt-2 mb-3"
                          @click="saveCustomizeDashboardPreferences()"
                        >
                          {{ $t('save_preferences') }}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!--  /.container  -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrap dashboard-widgets">
      <div class="container-fluid">
        <!-- Dashboard row -->
        <div class="row" v-if="!uiDashboard.loaded">
          <loading-widget />
        </div>
        <div v-else-if="uiDashboard.empty">
          <div class="col-12">
            <div class="card full">
              <div class="card-header media">
                <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                {{ $t('no_widgets_visible') }}
              </div>
            </div>
          </div>
        </div>
  
        <widget-bookmarks ref="dashBookmark" />       
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import WidgetBookmarks from '@/components/dashboard/widgets/WidgetBookmarks.vue';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIDashboard } from '@/UIModels/dashboard';
import LoadingWidget from '@/components/shared/loading-skeletons/LoadingWidget.vue';
import { UIError } from '@/UIModels/error';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

@Component({
  components: {
    WidgetBookmarks,
    LoadingWidget,
  },
  ...i18nMessages([
    require('@/components/dashboard/_locales/Dashboard.json'),
  ]),
})
export default class Dashboard extends Vue {
  public errorMessage: string|null = null;

  get uiEnvVersion(): string {
    return useCurrentPageStore().configuration.uiEnvVersion;
  }
    
  get uiDashboard(): UIDashboard {
    return useCurrentPageStore().dashboard;
  }

  async mounted(): Promise<void> {
    await this.uiDashboard.load();
  }

  // Saves dashboard settings to user preferences
  public async saveCustomizeDashboardPreferences(): Promise<void> {
    try {
      await this.uiDashboard.save();
      this.dismissCustomizationMenu();
    } catch (error: unknown) {
      console.warn(error);
      this.errorMessage = ((error as UIError)?.activityErrors || []).join(', ') || this.$t('error.unexpected_error');
    }
  }

  public dismissCustomizationMenu(): void {
    $("#customizeDash").dropdown('toggle');
  }

}
</script>



