import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card summary-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "summary-name" }
const _hoisted_7 = { class: "nav card-nav" }
const _hoisted_8 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_11 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_12 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_13 = ["title", "disabled"]
const _hoisted_14 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = { class: "row summary-list" }
const _hoisted_17 = { class: "shortitem" }
const _hoisted_18 = { class: "summary-items" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "summary-organ" }
const _hoisted_21 = { class: "organ-summary-wrap" }
const _hoisted_22 = ["title"]
const _hoisted_23 = ["title"]
const _hoisted_24 = ["title"]
const _hoisted_25 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_donor_summary_card = _resolveComponent("loading-donor-summary-card")!
  const _component_discontinue_organs_modal = _resolveComponent("discontinue-organs-modal")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_organ_icon = _resolveComponent("organ-icon")!
  const _component_ctr_log_modal = _resolveComponent("ctr-log-modal")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    "sub-section-id": "summary",
    lookupsToLoad: _ctx.lookupsToLoad,
    hospitalsToLoad: _ctx.hospitalsToLoad,
    onLoaded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({
    loading: _withCtx(() => [
      _createVNode(_component_loading_donor_summary_card)
    ]),
    _: 2
  }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createVNode(_component_discontinue_organs_modal, {
              ref: "discontinueDonorModal",
              discontinueType: "1",
              onDisplayOutcomeNotification: _ctx.displayOutcomeNotification
            }, null, 8, ["onDisplayOutcomeNotification"]),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'id-card'],
                      "fixed-width": ""
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.deceasedDonorDisplayName), 1),
                    _createElementVNode("ul", _hoisted_7, [
                      (!_ctx.isTransplantCoordinator)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                            _createVNode(_component_router_link, {
                              to: { name: 'edit-deceased-donor', params: { id: _ctx.donor.client_id }, hash: _ctx.allowJumpToDonationInformation },
                              class: "nav-link"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('donor_information')), 1)
                              ]),
                              _: 1
                            }, 8, ["to"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("li", _hoisted_9, [
                        _createVNode(_component_router_link, {
                          to: { name: 'edit-deceased-donor', params: { id: _ctx.donor.client_id }, hash: '#hla-typing' },
                          class: "nav-link"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('hla_information')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      (_ctx.canAccessNowList)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                            _createElementVNode("a", {
                              href: "#",
                              class: "nav-link",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNowModal()))
                            }, _toDisplayString(_ctx.$t('national_organ_waitlist')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.donorCanAccessCtrLog)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                            _createElementVNode("a", {
                              href: "#",
                              class: "nav-link",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCtrModal()))
                            }, _toDisplayString(_ctx.$t('ctr_integration')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.showDiscontinue)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                            _createElementVNode("button", {
                              title: _ctx.$t('discontinue_all_allocations_for_this_donor'),
                              class: "btn btn-danger btn-sm ml-2 p-2",
                              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.discontinueOrgans()), ["prevent"])),
                              disabled: _ctx.isLoadingAllocation || _ctx.isDiscontinuingAllAllocations
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.$t('discontinue_donor')) + " ", 1),
                              (_ctx.isDiscontinuingAllAllocations)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                    _createVNode(_component_font_awesome_icon, {
                                      class: "fa-1x fa-spin",
                                      icon: ['far', 'spinner-third']
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_13)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('deceased_donor_id')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.deceased_donor_id), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('national_donor_id')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.national_donor_id), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('sex')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.sex), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('age')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.getDonorAge), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('height')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.height), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('weight')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.weight), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('exd')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.exceptional_distribution), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('donor_type')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.donor_type), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('cause_of_death')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.cause_of_death), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('serology_results')), 1),
                    _createVNode(_component_router_link, {
                      to: { name: 'edit-deceased-donor', params: { id: _ctx.donor.client_id }, hash: '#virology' }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("[" + _toDisplayString(_ctx.$t('view')) + "]", 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _createElementVNode("dl", _hoisted_17, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('abo')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.abo), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('referral_hospital')), 1),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.editState.referral_hospital), 1)
                  ]),
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('donor_documents')), 1),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_router_link, {
                        to: { name: 'edit-deceased-donor', params: { id: _ctx.donor.client_id }, hash: '#donor-documents' }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("[" + _toDisplayString(_ctx.$t('view')) + "]", 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ])
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDonorConsentedOrganList, (consentedOrgan, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "summary-journey-item summary-item",
                        key: idx
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.listItemClass(consentedOrgan))
                        }, [
                          _createVNode(_component_router_link, {
                            to: consentedOrgan.route,
                            class: "organ-row"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_organ_icon, {
                                "organ-code": consentedOrgan.organ_code
                              }, null, 8, ["organ-code"]),
                              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t(`${consentedOrgan.display_name}`)), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]),
                          _createElementVNode("div", _hoisted_21, [
                            (_ctx.isLoadingAllocations)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "skeleton-box w-50" }, null, -1)),
                                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "skeleton-box w-100" }, null, -1))
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (consentedOrgan.allocationId)
                                    ? (_openBlock(), _createElementBlock("ul", {
                                        key: 0,
                                        class: "organ-summary-list",
                                        title: _ctx.$t('allocation_id')
                                      }, [
                                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('allocation_id')) + " " + _toDisplayString(consentedOrgan.allocationId), 1)
                                      ], 8, _hoisted_22))
                                    : _createCommentVNode("", true),
                                  (consentedOrgan.allocationId)
                                    ? (_openBlock(), _createElementBlock("ul", {
                                        key: 1,
                                        class: "organ-summary-list",
                                        title: _ctx.$t('allocation_run_datetime')
                                      }, [
                                        _createElementVNode("li", null, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['far', 'clock'],
                                            "fixed-width": ""
                                          }),
                                          _createTextVNode(" " + _toDisplayString(_ctx.formatDateTime(consentedOrgan.allocationRunDateTime)), 1)
                                        ])
                                      ], 8, _hoisted_23))
                                    : _createCommentVNode("", true),
                                  (consentedOrgan.allocationId)
                                    ? (_openBlock(), _createElementBlock("ul", {
                                        key: 2,
                                        class: "organ-summary-list",
                                        title: _ctx.$t('allocation_stage_field')
                                      }, [
                                        _createElementVNode("li", null, _toDisplayString(_ctx.$t(consentedOrgan.allocationStage)), 1)
                                      ], 8, _hoisted_24))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("ul", {
                                    class: "organ-summary-list",
                                    title: _ctx.$t('consented-organs.consented_date')
                                  }, [
                                    _createElementVNode("li", null, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: ['far', 'clock'],
                                        "fixed-width": ""
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_ctx.formatDate(consentedOrgan.consented_date)), 1)
                                    ])
                                  ], 8, _hoisted_25)
                                ], 64))
                          ])
                        ], 2)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]),
            _createVNode(_component_ctr_log_modal, { ref: "ctrLogModal" }, null, 512)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["lookupsToLoad", "hospitalsToLoad"]))
}