import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { id: "administration-user-account" }
const _hoisted_3 = { class: "legend-title" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group-large" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group-large" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "row"
}
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "standard-form-group-large" }
const _hoisted_18 = { id: "administration-user-notifications" }
const _hoisted_19 = { class: "legend-title" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "standard-form-group-6column-large-up" }
const _hoisted_22 = { id: "administration-user-notifications" }
const _hoisted_23 = { class: "legend-title" }
const _hoisted_24 = { class: "table-responsive-md" }
const _hoisted_25 = {
  key: 0,
  class: "table table-bordered table-hover"
}
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { colspan: "2" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = {
  key: 1,
  class: "table table-bordered table-hover"
}
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = {
  key: 1,
  class: "form-btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_profile_page = _resolveComponent("loading-profile-page")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_card_section = _resolveComponent("card-section")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createBlock(_component_loading_profile_page, { key: 0 }))
      : (_ctx.editState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_validation_observer, {
              ref: "validations",
              autocomplete: "off",
              tag: "form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_card_section, {
                  ref: "userProfile",
                  "section-id": "user-profile",
                  "lookups-to-load": _ctx.lookupsToLoad,
                  onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loaded()))
                }, _createSlots({ _: 2 }, [
                  (_ctx.editState && _ctx.editState.user)
                    ? {
                        name: "header",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("user_profile")), 1)
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (_ctx.editState && _ctx.editState.user)
                    ? {
                        name: "body",
                        fn: _withCtx(() => [
                          _createElementVNode("fieldset", _hoisted_2, [
                            _createElementVNode("legend", null, [
                              _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("personal_information")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("dl", _hoisted_6, [
                                  _createElementVNode("dt", _hoisted_7, _toDisplayString(_ctx.$t('name')), 1),
                                  _createElementVNode("dd", _hoisted_8, _toDisplayString(_ctx.getUserFullName), 1)
                                ]),
                                _createElementVNode("dl", _hoisted_9, [
                                  _createElementVNode("dt", _hoisted_10, _toDisplayString(_ctx.$t('email')), 1),
                                  _createElementVNode("dd", _hoisted_11, _toDisplayString(_ctx.editState.user.email), 1)
                                ]),
                                _createElementVNode("dl", _hoisted_12, [
                                  _createElementVNode("dt", _hoisted_13, _toDisplayString(_ctx.$t('roles')), 1),
                                  _createElementVNode("dd", {
                                    class: "standard-form-group-large",
                                    innerHTML: _ctx.getRoleNames
                                  }, null, 8, _hoisted_14)
                                ]),
                                (_ctx.editState.user.effective_date)
                                  ? (_openBlock(), _createElementBlock("dl", _hoisted_15, [
                                      _createElementVNode("dt", _hoisted_16, _toDisplayString(_ctx.$t('access_started')), 1),
                                      _createElementVNode("dd", _hoisted_17, _toDisplayString(_ctx.parseDisplayDateUi(_ctx.editState.user.effective_date)), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ]),
                          _createElementVNode("fieldset", _hoisted_18, [
                            _createElementVNode("legend", null, [
                              _createElementVNode("h4", _hoisted_19, _toDisplayString(_ctx.$t("manage_delivery_mechanisms")), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("manage_delivery_methods_instructions")), 1),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createVNode(_component_text_input, {
                                  "input-id": "cell",
                                  name: _ctx.$t('cell_number'),
                                  mask: _ctx.getTelephoneMask,
                                  modelValue: _ctx.editState.user.sms,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.user.sms) = $event))
                                }, null, 8, ["name", "mask", "modelValue"])
                              ])
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("manage_delivery_methods_note")), 1)
                          ])
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1032, ["lookups-to-load"]),
                (_ctx.editState && _ctx.editState.user && _ctx.editState.user.notification_options)
                  ? (_openBlock(), _createBlock(_component_card_section, {
                      key: 0,
                      ref: "saveNotifications",
                      "section-id": "user-notifications"
                    }, _createSlots({
                      header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("notifications")), 1)
                      ]),
                      _: 2
                    }, [
                      (_ctx.editState && _ctx.editState.user)
                        ? {
                            name: "body",
                            fn: _withCtx(() => [
                              _createElementVNode("form", null, [
                                _createElementVNode("fieldset", _hoisted_22, [
                                  _createElementVNode("legend", null, [
                                    _createElementVNode("h4", _hoisted_23, _toDisplayString(_ctx.$t("manage_notifications")), 1)
                                  ]),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("notifications_mechanisms_blank")), 1),
                                  _createElementVNode("div", _hoisted_24, [
                                    (!_ctx.editState.user.not_grouped)
                                      ? (_openBlock(), _createElementBlock("table", _hoisted_25, [
                                          _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t("notification")), 1),
                                              (_ctx.showRequiredMethodsColumn)
                                                ? (_openBlock(), _createElementBlock("th", _hoisted_26, [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("primary_mechanisms")) + " ", 1),
                                                    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                                    _createElementVNode("small", null, [
                                                      _cache[3] || (_cache[3] = _createElementVNode("b", { class: "text-red" }, "*", -1)),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.$t("select_at_least_one")), 1)
                                                    ])
                                                  ]))
                                                : _createCommentVNode("", true),
                                              (_ctx.showOptionalMethodsColumn)
                                                ? (_openBlock(), _createElementBlock("th", _hoisted_27, _toDisplayString(_ctx.$t("optional_mechanisms")), 1))
                                                : _createCommentVNode("", true)
                                            ])
                                          ]),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.editState.user.notification_options_grouped), (entry, index) => {
                                            return (_openBlock(), _createElementBlock("tbody", {
                                              key: `${index}_notification_group`
                                            }, [
                                              _createElementVNode("tr", null, [
                                                _createElementVNode("th", _hoisted_28, _toDisplayString(_ctx.$t(entry[0])), 1)
                                              ]),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.user.notification_lookup, (channel, index) => {
                                                return (_openBlock(), _createElementBlock("tr", {
                                                  key: channel.code
                                                }, [
                                                  (channel.sub_group == entry[0])
                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                        _createElementVNode("td", {
                                                          innerHTML: _ctx.$t(channel.value)
                                                        }, null, 8, _hoisted_29),
                                                        (_ctx.showRequiredMethodsColumn)
                                                          ? (_openBlock(), _createElementBlock("td", _hoisted_30, [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.required_delivery_mechanisms, (mechanism) => {
                                                                return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                                  _createVNode(_component_checkbox_input, {
                                                                    class: "full-width",
                                                                    "input-id": `user-notifications-required-${channel.code}-${mechanism}`,
                                                                    modelValue: _ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism],
                                                                    "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism]) = $event),
                                                                    label: _ctx.$t(mechanism)
                                                                  }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                                ]))
                                                              }), 128))
                                                            ]))
                                                          : _createCommentVNode("", true),
                                                        (_ctx.showOptionalMethodsColumn)
                                                          ? (_openBlock(), _createElementBlock("td", _hoisted_31, [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.optional_delivery_mechanisms, (mechanism) => {
                                                                return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                                  _createVNode(_component_checkbox_input, {
                                                                    class: "full-width",
                                                                    "input-id": `user-notifications-optional-${channel.code}-${mechanism}`,
                                                                    modelValue: _ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism],
                                                                    "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism]) = $event),
                                                                    label: _ctx.$t(mechanism)
                                                                  }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                                ]))
                                                              }), 128))
                                                            ]))
                                                          : _createCommentVNode("", true)
                                                      ], 64))
                                                    : _createCommentVNode("", true)
                                                ]))
                                              }), 128))
                                            ]))
                                          }), 128))
                                        ]))
                                      : (_openBlock(), _createElementBlock("table", _hoisted_32, [
                                          _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t("notification")), 1),
                                              (_ctx.showRequiredMethodsColumn)
                                                ? (_openBlock(), _createElementBlock("th", _hoisted_33, [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("primary_mechanisms")) + " ", 1),
                                                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                                                    _createElementVNode("small", null, [
                                                      _cache[5] || (_cache[5] = _createElementVNode("b", { class: "text-red" }, "*", -1)),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.$t("select_at_least_one")), 1)
                                                    ])
                                                  ]))
                                                : _createCommentVNode("", true),
                                              (_ctx.showOptionalMethodsColumn)
                                                ? (_openBlock(), _createElementBlock("th", _hoisted_34, _toDisplayString(_ctx.$t("optional_mechanisms")), 1))
                                                : _createCommentVNode("", true)
                                            ])
                                          ]),
                                          _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.user.notification_lookup, (channel, index) => {
                                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                (channel && _ctx.editState.user.notification_options[index])
                                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                      _createElementVNode("td", {
                                                        innerHTML: _ctx.$t(channel.value)
                                                      }, null, 8, _hoisted_35),
                                                      (_ctx.showRequiredMethodsColumn)
                                                        ? (_openBlock(), _createElementBlock("td", _hoisted_36, [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.required_delivery_mechanisms, (mechanism) => {
                                                              return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                                _createVNode(_component_checkbox_input, {
                                                                  class: "full-width",
                                                                  "input-id": `user-notifications-required-${channel.code}`,
                                                                  modelValue: _ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism],
                                                                  "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism]) = $event),
                                                                  label: _ctx.$t(mechanism)
                                                                }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                              ]))
                                                            }), 128))
                                                          ]))
                                                        : _createCommentVNode("", true),
                                                      (_ctx.showOptionalMethodsColumn)
                                                        ? (_openBlock(), _createElementBlock("td", _hoisted_37, [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.optional_delivery_mechanisms, (mechanism) => {
                                                              return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                                _createVNode(_component_checkbox_input, {
                                                                  class: "full-width",
                                                                  "input-id": `user-notifications-optional-${channel.code}-${mechanism}`,
                                                                  modelValue: _ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism],
                                                                  "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism]) = $event),
                                                                  label: _ctx.$t(mechanism)
                                                                }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                              ]))
                                                            }), 128))
                                                          ]))
                                                        : _createCommentVNode("", true)
                                                    ], 64))
                                                  : _createCommentVNode("", true)
                                              ]))
                                            }), 128))
                                          ])
                                        ]))
                                  ])
                                ])
                              ])
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1536))
                  : _createCommentVNode("", true),
                (_ctx.editState && _ctx.editState.user)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createVNode(_component_save_toolbar, {
                        ref: "userProfileToolbar",
                        label: _ctx.$t('save_profile'),
                        onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.performSave()))
                      }, null, 8, ["label"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 512)
          ]))
        : _createCommentVNode("", true)
  ]))
}