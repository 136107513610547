import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-full-width-copy" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-full-width-copy" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = { class: "standard-form-group" }
const _hoisted_20 = { class: "standard-form-group" }
const _hoisted_21 = { class: "standard-form-group" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "standard-form-group-large" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "standard-form-group" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "standard-form-group" }
const _hoisted_28 = { class: "standard-form-group" }
const _hoisted_29 = { class: "standard-form-group" }
const _hoisted_30 = { class: "standard-form-group" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "standard-form-group" }
const _hoisted_33 = { class: "standard-form-group" }
const _hoisted_34 = ["disabled"]
const _hoisted_35 = { class: "row" }
const _hoisted_36 = { class: "standard-form-group" }
const _hoisted_37 = { class: "standard-form-group" }
const _hoisted_38 = { class: "standard-form-group" }
const _hoisted_39 = { class: "standard-form-group" }
const _hoisted_40 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "standard-form-group" }
const _hoisted_44 = { class: "row" }
const _hoisted_45 = { class: "standard-form-group-large" }
const _hoisted_46 = { class: "modal-footer-body" }
const _hoisted_47 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_48 = { class: "modal-footer-body" }
const _hoisted_49 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.editState)
    ? (_openBlock(), _createBlock(_component_card_section, {
        key: 0,
        "section-id": "recovery-information",
        ref: "saveRecoveryInformation",
        lookupsToLoad: _ctx.lookupsToLoad,
        onLoaded: _cache[27] || (_cache[27] = ($event: any) => (_ctx.loaded())),
        saveButton: _ctx.isLivingDonorTransplantComplete,
        saveButtonText: _ctx.$t('save_recovery_information'),
        disabled: !_ctx.canSave,
        onSave: _cache[28] || (_cache[28] = ($event: any) => (_ctx.validateSavePatch()))
      }, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('recovery_details')), 1)
        ]),
        body: _withCtx(() => [
          (!_ctx.editState)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.showTransplantCompleteButton && !_ctx.isLivingDonorTransplantComplete)
                  ? (_openBlock(), _createBlock(_component_sub_section, {
                      key: 0,
                      title: _ctx.$t('transplant_controls'),
                      "sub-section-id": "recovery-information-transplant_controls"
                    }, {
                      contents: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("div", _hoisted_2, [
                            (_ctx.transplantCompleteError)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.transplantCompleteError)), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-primary",
                              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.confirmTransplantComplete()), ["prevent"])),
                              disabled: _ctx.disableTransplantCompleteButton
                            }, _toDisplayString(_ctx.$t('transplant_complete')), 9, _hoisted_6)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('transplant_complete_body')), 1)
                        ]),
                        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "row-break d-none d-xxxl-block" }, null, -1))
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_sub_section, {
                  title: _ctx.$t('recovery_information'),
                  "sub-section-id": "recovery-information-recovery_details"
                }, {
                  contents: _withCtx(() => [
                    (_ctx.editState)
                      ? (_openBlock(), _createElementBlock("fieldset", {
                          key: 0,
                          disabled: !_ctx.canSave || !_ctx.isLivingDonorTransplantComplete
                        }, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_text_input, {
                                inputId: "recovery-information-referred_organ",
                                name: `${_ctx.$t('referred_organ')}`,
                                modelValue: _ctx.editState.referredOrganName,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.referredOrganName) = $event)),
                                disabled: true
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(_component_select_input, {
                                selectId: "recovery-information-organ_specification",
                                ruleKey: "recovery_info.organ_specification_code",
                                name: `${_ctx.$t('organ_specification')}`,
                                modelValue: _ctx.editState.organSpecification,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.organSpecification) = $event)),
                                options: _ctx.organSpecificationLookup,
                                rules: "required"
                              }, null, 8, ["name", "modelValue", "options"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_text_input, {
                                inputId: "recovery-information-surgeon_1",
                                ruleKey: "recovery_info.surgeon1",
                                name: `${_ctx.$t('surgeon_1')}`,
                                modelValue: _ctx.editState.surgeon1,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.surgeon1) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_component_text_input, {
                                inputId: "recovery-information-surgeon_2",
                                ruleKey: "recovery_info.surgeon2",
                                name: `${_ctx.$t('surgeon_2')}`,
                                modelValue: _ctx.editState.surgeon2,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.surgeon2) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_text_input, {
                                inputId: "recovery-information-transplant_program",
                                name: `${_ctx.$t('transplant_program')}`,
                                modelValue: _ctx.editState.hospitalName,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.hospitalName) = $event)),
                                disabled: true
                              }, null, 8, ["name", "modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_date_input, {
                                inputId: "recovery-information-start_date",
                                ruleKey: "recovery_info.start_date",
                                name: `${_ctx.$t('start_date')}`,
                                modelValue: _ctx.editState.startDate,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.startDate) = $event)),
                                crossValues: { endDate: _ctx.editState.endDate },
                                rules: "required_if_filled:@endDate"
                              }, null, 8, ["name", "modelValue", "crossValues"])
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_time_input, {
                                inputId: "recovery-information-start_time",
                                ruleKey: "recovery_info.start_time",
                                name: `${_ctx.$t('start_time')}`,
                                modelValue: _ctx.editState.startTime,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.startTime) = $event)),
                                crossValues: { endDate: _ctx.editState.endDate },
                                rules: "required_if_filled:@endDate"
                              }, null, 8, ["name", "modelValue", "crossValues"])
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createVNode(_component_date_input, {
                                inputId: "recovery-information-end_date",
                                ruleKey: "recovery_info.end_date",
                                name: `${_ctx.$t('end_date')}`,
                                modelValue: _ctx.editState.endDate,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.endDate) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_21, [
                              _createVNode(_component_time_input, {
                                inputId: "recovery-information-end_time",
                                ruleKey: "recovery_info.end_time",
                                name: `${_ctx.$t('end_time')}`,
                                modelValue: _ctx.editState.endTime,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.endTime) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(_component_text_area_input, {
                                inputId: "recovery-information-comments",
                                ruleKey: "recovery_info.comments",
                                name: _ctx.$t('comments'),
                                modelValue: _ctx.editState.comments,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.comments) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_component_boolean_radio_input, {
                                ruleKey: "recovery_info.completed",
                                inputId: "recovery-information-completed",
                                labelName: _ctx.$t("completed"),
                                acceptId: true,
                                declineId: false,
                                acceptLabel: _ctx.$t('yes'),
                                declineLabel: _ctx.$t('no'),
                                modelValue: _ctx.editState.completed,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.completed) = $event)),
                                rules: "required"
                              }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_date_input, {
                                inputId: "recovery-information-cross_clamp_date",
                                ruleKey: "recovery_info.cross_clamp_date",
                                name: `${_ctx.$t('cross_clamp_date')}`,
                                modelValue: _ctx.editState.crossClampDate,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.crossClampDate) = $event)),
                                crossValues: { completed: _ctx.editState.completed },
                                rules: "required_if:@completed"
                              }, null, 8, ["name", "modelValue", "crossValues"])
                            ]),
                            _createElementVNode("div", _hoisted_28, [
                              _createVNode(_component_time_input, {
                                inputId: "recovery-information-cross_clamp_time",
                                ruleKey: "recovery_info.cross_clamp_time",
                                name: `${_ctx.$t('cross_clamp_time')}`,
                                modelValue: _ctx.editState.crossClampTime,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.crossClampTime) = $event)),
                                crossValues: { completed: _ctx.editState.completed },
                                rules: "required_if:@completed"
                              }, null, 8, ["name", "modelValue", "crossValues"])
                            ]),
                            _createElementVNode("div", _hoisted_29, [
                              _createVNode(_component_date_input, {
                                inputId: "recovery-information-flush_date",
                                ruleKey: "recovery_info.flush_date",
                                name: `${_ctx.$t('flush_date')}`,
                                modelValue: _ctx.editState.flushDate,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.flushDate) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_30, [
                              _createVNode(_component_time_input, {
                                inputId: "recovery-information-flush_time",
                                ruleKey: "recovery_info.flush_time",
                                name: `${_ctx.$t('flush_time')}`,
                                modelValue: _ctx.editState.flushTime,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.flushTime) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_checkbox_input, {
                                inputId: "recovery-information-organ_shipped",
                                validationId: "organ_shipped",
                                ruleKey: "recovery_info.organ_shipped",
                                labelName: _ctx.$t('organ_shipped'),
                                modelValue: _ctx.editState.organShipped,
                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.organShipped) = $event)),
                                label: _ctx.$t('yes'),
                                onClick: _ctx.changeShippedSection
                              }, null, 8, ["labelName", "modelValue", "label", "onClick"])
                            ]),
                            _createElementVNode("div", _hoisted_33, [
                              _createVNode(_component_date_input, {
                                inputId: "recovery-information-transplant_date",
                                name: `${_ctx.$t('transplant_date')}`,
                                modelValue: _ctx.editState.transplantDate,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.transplantDate) = $event)),
                                disabled: true
                              }, null, 8, ["name", "modelValue"])
                            ])
                          ])
                        ], 8, _hoisted_9))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["title"]),
                (_ctx.editState.organShipped)
                  ? (_openBlock(), _createBlock(_component_sub_section, {
                      key: 1,
                      title: _ctx.$t('organ_shipped_subsection'),
                      "sub-section-id": "recovery-information-organ_shipped_subsection"
                    }, {
                      contents: _withCtx(() => [
                        (_ctx.editState)
                          ? (_openBlock(), _createElementBlock("fieldset", {
                              key: 0,
                              disabled: !_ctx.canSave || !_ctx.isLivingDonorTransplantComplete
                            }, [
                              _createElementVNode("div", _hoisted_35, [
                                _createElementVNode("div", _hoisted_36, [
                                  _createVNode(_component_boolean_radio_input, {
                                    inputId: "recovery-information-shipped_out_of_province",
                                    ruleKey: "recovery_info.organ_shipped_info.oop",
                                    labelName: _ctx.$t("shipped_out_of_province"),
                                    acceptId: true,
                                    declineId: false,
                                    acceptLabel: _ctx.$t('yes'),
                                    declineLabel: _ctx.$t('no'),
                                    modelValue: _ctx.editState.shippedOutOfProvince,
                                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.shippedOutOfProvince) = $event)),
                                    rules: "required"
                                  }, null, 8, ["labelName", "acceptLabel", "declineLabel", "modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_37, [
                                  _createVNode(_component_checkbox_input, {
                                    inputId: "recovery-information-perfusion_pump",
                                    validationId: "perfusion_pump",
                                    ruleKey: "recovery_info.organ_shipped_info.perfusion_pump",
                                    labelName: _ctx.$t('perfusion_pump'),
                                    modelValue: _ctx.editState.perfusionPump,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.perfusionPump) = $event)),
                                    label: _ctx.$t('yes')
                                  }, null, 8, ["labelName", "modelValue", "label"])
                                ]),
                                _createElementVNode("div", _hoisted_38, [
                                  _createVNode(_component_date_input, {
                                    inputId: "recovery-information-perfusion_date",
                                    ruleKey: "recovery_info.organ_shipped_info.perfusion_date",
                                    name: `${_ctx.$t('perfusion_date')}`,
                                    modelValue: _ctx.editState.perfusionDate,
                                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.perfusionDate) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_39, [
                                  _createVNode(_component_time_input, {
                                    inputId: "recovery-information-perfusion_time",
                                    ruleKey: "recovery_info.organ_shipped_info.perfusion_time",
                                    name: `${_ctx.$t('perfusion_time')}`,
                                    modelValue: _ctx.editState.perfusionTime,
                                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.perfusionTime) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                (_ctx.editState.shippedOutOfProvince)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                      _createVNode(_component_checkbox_input, {
                                        inputId: "recovery-information-organ_successfully_transplanted",
                                        validationId: "organ_successfully_transplanted",
                                        ruleKey: "recovery_info.organ_shipped_info.organ_successfully_transplanted",
                                        labelName: _ctx.$t('organ_successfully_transplanted'),
                                        modelValue: _ctx.editState.organSuccessfullyTransplanted,
                                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.organSuccessfullyTransplanted) = $event)),
                                        label: _ctx.$t('yes')
                                      }, null, 8, ["labelName", "modelValue", "label"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ], 8, _hoisted_34))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.getIntendedRecipientHasLiver)
                  ? (_openBlock(), _createBlock(_component_sub_section, {
                      key: 2,
                      title: _ctx.$t('liver_specific_information'),
                      "sub-section-id": "recovery-information-liver_specific_information"
                    }, {
                      contents: _withCtx(() => [
                        (_ctx.editState)
                          ? (_openBlock(), _createElementBlock("fieldset", {
                              key: 0,
                              disabled: !_ctx.canSave || !_ctx.isLivingDonorTransplantComplete
                            }, [
                              _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("div", _hoisted_43, [
                                  _createVNode(_component_number_input, {
                                    inputId: "recovery-information-liver_graft_weight",
                                    ruleKey: "recovery_info.liver_graft_weight",
                                    name: _ctx.$t("liver_graft_weight"),
                                    append: true,
                                    appendText: "g",
                                    step: "0.01",
                                    modelValue: _ctx.editState.graftWeightGrams,
                                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.graftWeightGrams) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_44, [
                                _createElementVNode("div", _hoisted_45, [
                                  _createVNode(_component_text_input, {
                                    inputId: "recovery-information-liver_abnormalities_concerns",
                                    ruleKey: "recovery_info.liver_abnormalities_concerns",
                                    name: `${_ctx.$t('liver_abnormalities_concerns')}`,
                                    modelValue: _ctx.editState.abnormalitiesOrConcerns,
                                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.abnormalitiesOrConcerns) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ])
                              ])
                            ], 8, _hoisted_41))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true)
              ], 64)),
          _createVNode(_component_modal_section, {
            modalId: "recovery-information-confirm-transplant-complete-modal",
            ref: "recoveryInformationConfirmTransplantCompleteModal",
            class: "modal-sticky-header",
            centered: true
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('transplant_complete')), 1)
            ]),
            body: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('confirm_transplant_complete_body')), 1)
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("button", _hoisted_47, _toDisplayString(_ctx.$t('cancel')), 1),
                _createElementVNode("a", {
                  class: "btn btn-success",
                  onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.savePatchTransplantComplete()))
                }, _toDisplayString(_ctx.$t('ok')), 1)
              ])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_modal_section, {
            modalId: "recovery-information-confirm-save-modal",
            ref: "recoveryInformationConfirmSaveModal",
            class: "modal-sticky-header",
            centered: true
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('recovery_information')), 1)
            ]),
            body: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('confirm_recovery_information_body')), 1)
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("button", _hoisted_49, _toDisplayString(_ctx.$t('cancel')), 1),
                _createElementVNode("a", {
                  class: "btn btn-success",
                  onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.saveValidatedPatch()))
                }, _toDisplayString(_ctx.$t('ok')), 1)
              ])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["lookupsToLoad", "saveButton", "saveButtonText", "disabled"]))
    : _createCommentVNode("", true)
}