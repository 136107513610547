import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card tip-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "standard-form-group sorting-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, { subSectionId: "transplant-in-progress-report" }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'file-chart-line'],
                      "fixed-width": ""
                    })
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "media-body flex-row align-items-center" }, [
                    _createElementVNode("h3", { class: "tip-report-name" }, " Transplant in progress report ")
                  ], -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_validation_observer, {
                  id: "transplant-in-progress-report-filters",
                  ref: "transplantInProgressReportFilters",
                  autocomplete: "off",
                  tag: "form"
                }, {
                  default: _withCtx(({ handleSubmit }) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_select_input, {
                          "select-id": "sort-type-report",
                          name: "Select default sorting",
                          class: "mb-3",
                          modelValue: _ctx.editState.sort,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.sort) = $event)),
                          options: _ctx.sortOptions,
                          rules: "required"
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _createVNode(_component_save_toolbar, {
                        class: "form-inline col-sm-12 action-row",
                        buttonClass: "btn btn-success",
                        ref: "generateReport",
                        savingText: "Generating",
                        successText: "Report generated",
                        defaultErrorText: "There was a problem generating the report",
                        onSave: ($event: any) => (handleSubmit(_ctx.generateReport))
                      }, {
                        label: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'file-chart-line'],
                            class: "mr-2"
                          }),
                          _cache[2] || (_cache[2] = _createTextVNode(" Generate Transplant In Progress Report "))
                        ]),
                        _: 2
                      }, 1032, ["onSave"])
                    ])
                  ]),
                  _: 1
                }, 512)
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}