import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dropdown user-dropdown" }
const _hoisted_2 = {
  id: "user-drop-down",
  class: "nav-link dropdown-toggle media",
  href: "#",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_3 = {
  class: "dropdown-menu dropdown-menu-right",
  "aria-labelledby": "user-drop-down"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "dropdown-item col-12" }
const _hoisted_6 = { class: "d-sm-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        icon: ['fad', 'user-circle'],
        class: "headerUserIcon"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: { name: 'profilePage' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['far', 'user'],
            class: "headerMenuIcon pr-2"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.getUserName), 1)
        ]),
        _: 1
      }),
      (_ctx.getHelpUrl)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "dropdown-item",
            href: _ctx.getHelpUrl,
            target: "_blank"
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'question-circle'],
              class: "headerMenuIcon pr-2"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('support')), 1)
          ], 8, _hoisted_4))
        : _createCommentVNode("", true),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "dropdown-divider" }, null, -1)),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: { name: 'administration' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['far', 'hammer'],
            class: "headerMenuIcon pr-2"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('administration')), 1)
        ]),
        _: 1
      }),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "dropdown-divider" }, null, -1)),
      (_ctx.localeOptions.length > 1)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_select_input, {
                name: `${_ctx.getCurrentLocale}`,
                "select-id": "lang-selector",
                modelValue: _ctx.$i18n.locale,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLocale($event))),
                options: _ctx.localeOptions,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.preventClose($event)))
              }, null, 8, ["name", "modelValue", "options"])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "dropdown-divider" }, null, -1))
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        class: "dropdown-item",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['far', 'sign-out'],
          class: "headerMenuIcon pr-2"
        }),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('log_out')), 1)
      ])
    ])
  ]))
}