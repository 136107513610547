import Vue from 'vue';
import { MutationTree } from 'vuex';
import { LabsState } from '@/store/labs/types';
import { idComparator } from '@/utils';

export const mutations: MutationTree<LabsState> = {
  clear(state, key) {
    (state as any)[key] = undefined;
  },
  setVirologies(state, virologies) {
    state.virologies = virologies;
  },
  selectVirology(state, virology) {
    state.selectedVirology = virology;
  },
  clearVirologies(state) {
    state.virologies = [];
  },
  saveVirologyResult(state, newEntry) {
    if (newEntry && state.virologies) {
      // Look for existing entry to edit
      const indexOfExistingEntry = state.virologies.findIndex((oldEntry: any) => {
        return idComparator(oldEntry, newEntry); // compares using _id
      });
      const existingEntry = state.virologies[indexOfExistingEntry];
      if (!existingEntry) {
        // Push new entry
        const sanitizedEntry = Object.assign({}, newEntry);
        state.virologies.unshift(sanitizedEntry);
      } else {
        // Modify existing entry
        state.virologies[indexOfExistingEntry] = newEntry;
      }
    } else {
      // TODO: proper error handling
      console.log('Could not save virology result.');
    }
  },
  setHlaTyping(state, hlaTyping) {
    state.hlaTyping = hlaTyping;
  },
  setHlaAntibodies(state, hlaAntibodies) {
    state.hlaAntibodies = hlaAntibodies;
  },
  selectHlaAntibodies(state, hlaAntibodies) {
    state.selectedHlaAntibodies = hlaAntibodies;
  },
  selectHlaSerumSummaryReview(state, hlaAntibodies) {
    state.selectedHlaSerumSummaryReview = hlaAntibodies;
  },
  setCrossmatchLabs(state, crossmatchLabs) {
    state.crossmatchLabs = crossmatchLabs;
  },
  selectCrossmatchLab(state, crossmatchLab) {
    state.selectedCrossmatch = crossmatchLab;
  },
  clearCrossmatchLab(state) {
    state.selectedCrossmatch = undefined;
  },
  selectCrossmatchSample(state, crossmatchSample) {
    state.selectedCrossmatchSample = crossmatchSample;
  },
  clearCrossmatchSample(state) {
    state.selectedCrossmatchSample = undefined;
  },
};
