
import { APIJourney } from '@/store/recipientJourney/types';
import { parseDisplayDateUiFromDateTime } from '@/utilities/date-utils';


enum SummaryStyle {
  Ready = 'ready',
  InProgress = 'in_progress',
  Pending = 'pending',
  Inactive = 'inactive',
}
enum SummaryTileClass {
  Ready = 'summary-tile-ready',
  InProgress = 'summary-tile-in-progress',
  Pending = 'summary-tile-pending',
}

enum SummaryCategory {
  Active = 'active',
  PostTransplant = 'post_transplant',
  Closed = 'closed',
}

export class UIJourneySummary {
  public apiSource: APIJourney;
  public id: string;

  public organCode: number;

  public summaryCategory: string;
  public summaryTileClass: { [key: string]: boolean };

  // Active
  public phase = '';
  public phaseEntryDate = '';
  public status = '';
  public reason: string|null = null;

  // Post-Transplant
  public transplantedDate: string|null = null;
  public transplantedDateObject: Date|null = null;

  // Closed
  public closedDate: string|null = null;

  // Map from API data structure to UI model structure
  public constructor(apiJourney: APIJourney) {
    this.apiSource = apiJourney;
    this.id = apiJourney._id || '';
    this.organCode = apiJourney.organ_code || 0;

    this.summaryCategory = apiJourney.current_state?.summary_tile_category || '';

    this.summaryTileClass = {};
    switch(apiJourney.current_state?.summary_tile_style) {
      case SummaryStyle.Ready:
        this.summaryTileClass[SummaryTileClass.Ready] = true;
        break;
      case SummaryStyle.InProgress:
        this.summaryTileClass[SummaryTileClass.InProgress] = true;
        break;
      case SummaryStyle.Pending:
        this.summaryTileClass[SummaryTileClass.Pending] = true;
        break;
    }

    switch(this.summaryCategory) {
      case SummaryCategory.Active:
        const phase = apiJourney.current_state?.phase || '';
        this.phase = phase || '';
        this.phaseEntryDate = parseDisplayDateUiFromDateTime(apiJourney.current_state?.phase_entry_datetime) || '';
        this.status = apiJourney.current_state?.status || '';
        this.reason = apiJourney.current_state?.reason || null;
        break;
      case SummaryCategory.PostTransplant:
        const anastomosis_datetime = apiJourney.stage_attributes?.transplant?.anastomosis_datetime;
        if (!anastomosis_datetime) break;
        this.transplantedDate = parseDisplayDateUiFromDateTime(anastomosis_datetime) || '';
        this.transplantedDateObject = new Date(anastomosis_datetime);
      case SummaryCategory.Closed:
        this.closedDate = parseDisplayDateUiFromDateTime(apiJourney.current_state?.effective_datetime) || null;
        break;
    }
  }
}
