import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "circle dashboard-icon circle-active-donor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_widget_links = _resolveComponent("widget-links")!
  const _component_widget_options = _resolveComponent("widget-options")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_bookmark_form = _resolveComponent("bookmark-form")!
  const _component_widget_section = _resolveComponent("widget-section")!
  const _component_base_widget = _resolveComponent("base-widget")!

  return (_openBlock(), _createBlock(_component_base_widget, {
    title: _ctx.$t('bookmarks'),
    widget: _ctx.uiWidget
  }, {
    icon: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'user'] })
      ])
    ]),
    linkID: _withCtx(() => [
      _createVNode(_component_widget_links, {
        "options-id": "idBookmarks",
        "section-id": "bodyBookmarks"
      })
    ]),
    options: _withCtx(() => [
      _createVNode(_component_widget_options, {
        id: "idBookmarks",
        widget: _ctx.uiWidget
      }, null, 8, ["widget"])
    ]),
    "widget-contents": _withCtx(() => [
      _createVNode(_component_widget_section, { id: "bodyBookmarks" }, {
        body: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: _ctx.tableConfig.createButton,
            createText: _ctx.tableConfig.createText,
            onTableCreateRow: _ctx.onTableCreateRow
          }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
          _createVNode(_component_table_list, {
            "table-id": "bookmarks-table",
            ref: "bookmarksTable",
            "table-config": _ctx.tableConfig,
            onTableRowClick: _ctx.onTableRowClick,
            highlightSelection: true,
            firstColumnTabbable: true
          }, null, 8, ["table-config", "onTableRowClick"]),
          _createVNode(_component_bookmark_form, {
            widget: _ctx.uiWidget,
            selection: _ctx.selection,
            onSuccess: _ctx.onSaveSuccess,
            onDeleted: _ctx.onDeleted
          }, null, 8, ["widget", "selection", "onSuccess", "onDeleted"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "widget"]))
}