<template>
  <sub-section v-if="isProtoSocialHistoryEnabled"
    sub-section-id="proto-social-history-section"
    :title="title"
  >
  <template v-slot:contents>
    <!-- Form layout -->
   
      <form-layout
      form-id="social_history-form">
     
      <template v-slot:contents>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              select-id="alcohol_use"
              name='Alcohol Use'
              :options="amountOptions"
              v-model="editState.alcohol_use"
              >               
            </select-input>
          </div>

          <div class="standard-form-group-large">
            <text-area-input input-id="alcohol_use-comment" name="Alcohol Use Comments" rows="4" v-model="editState.alcohol_use_comments"  />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              select-id="smoking_history"
              name='Smoking History'
              :options="amountOptions"
              v-model="editState.smoking_history"
              >               
            </select-input>
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="smoking_rate"
              name="Smoking Rate"
              :disabled="checkDisabled()"
              step="0.5"
              v-model="editState.smoking_rate"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="smoking_duration"
              name="Smoking Duration"
              :disabled="checkDisabled()"
              step="0.5"
              v-model="editState.smoking_duration"
            />
          </div>

          <div class="standard-form-group-large">
            <text-area-input input-id="smoking_history_comments" name="Smoking History Comments" rows="4" v-model="editState.smoking_history_comments"  />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              select-id="marijuana_history"
              name='History of Marijuana Use'
              :options="amountOptions"
              v-model="editState.marijuana_history"
              >               
            </select-input>
          </div>

          <div class="standard-form-group-large">
            <text-area-input input-id="marijuana_use_comments" name="Marijuana Use Comments" rows="4" v-model="editState.marijuana_use_comments"  />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              select-id="drug_use"
              name='Drug Use'
              :options="amountOptions"
              v-model="editState.drug_use"
              >               
            </select-input>
          </div>

          <div class="standard-form-group-large">
            <text-area-input input-id="drug_use_comments" name="Drug Use Comments" rows="4" v-model="editState.drug_use_comments"  />
          </div>
        </div>


      </template>
      <template v-slot:save>
        <save-toolbar
          :show="true"     
          ref="saveSurgery"
          label="Save Social History"
          :cancelButton="true"
          @save="performSave()"
          @cancel="clearForm()"
        />
      </template>
    </form-layout>    
  </template>
</sub-section>
</template>


<script lang="ts">
import {
Component,
Vue,
Prop,
Watch,
mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
SaveProvider,
TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface SocialHistoryForm {
alcohol_use: string,
alcohol_use_comments: string,
smoking_history: string,
smoking_rate: string,
smoking_duration: string,
smoking_history_comments: string,
marijuana_history: string,
marijuana_use_comments: string,
drug_use: string,
drug_use_comments: string,
}

@Component({
components: {
  CardSection,
  FilterComponent,
  SubSection,
  TableToolbar,
  TableList,
  TextAreaInput,
  SaveToolbar,
  FormLayout,
  ActionToolbar,
  DateInput,
  NumberInput,
  TextInput,
  SelectInput
},
emits: [
  'cancel',
],
})
export default class ProtoSocialHistorySection extends mixins(DateUtilsMixin) {

private title: any = "SOCIAL HISTORY";
private isNew: any = true;

private editState: SocialHistoryForm = {
  alcohol_use: "",
  alcohol_use_comments: "",
  smoking_history: "",
  smoking_rate: "",
  smoking_duration: "",
  smoking_history_comments: "",
  marijuana_history: "",
  marijuana_use_comments: "",
  drug_use: "",
  drug_use_comments: "",
};


private originalState: SocialHistoryForm = {
  alcohol_use: "",
  alcohol_use_comments: "",
  smoking_history: "",
  smoking_rate: "",
  smoking_duration: "",
  smoking_history_comments: "",
  marijuana_history: "",
  marijuana_use_comments: "",
  drug_use: "",
  drug_use_comments: "",
};



private amountOptions = [
    {
      code: '0',
      value: 'Yes'
    }, {
      code: '1',
      value: 'No'
    },
    {
      code: '2',
      value: 'Unknown'
    }
  ];

get isProtoSocialHistoryEnabled(): boolean {
  return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SocialHistory);
}

// Create a prototype bucket to store arbitrary key/value data
private protoBucket!: UIPrototypeBucket;

// Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
return this.$refs.saveSurgery as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
// No-op if prototypes not enabled
if (!useCurrentPageStore().configuration.prototypes) return;

// Make a per-recipient prototype bucket
this.protoBucket = new UIPrototypeBucket(PROTOTYPES.SocialHistory, { recipientId: this.$route.params.id });

// NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
const response: SocialHistoryForm = await this.protoBucket.getItem(EDITSTATE_KEY);
if (!response) return;

this.editState = {
    alcohol_use: response.alcohol_use,
    alcohol_use_comments: response.alcohol_use_comments,
    smoking_history: response.smoking_history,
    smoking_rate: response.smoking_rate,
    smoking_duration: response.smoking_duration,
    smoking_history_comments: response.smoking_history_comments,
    marijuana_history: response.marijuana_history,
    marijuana_use_comments: response.marijuana_use_comments,
    drug_use: response.drug_use,
    drug_use_comments: response.drug_use_comments,
  };
  
  this.originalState = Object.assign({}, this.editState);
}

public checkDisabled(): any {

    if (this.editState.smoking_history === '0') {
      return false;
    } else {
      return true;
    }
  
}

public performSave(): any {
// Call the 'startSaving' method to begin save animation
if (this.saveToolbar) this.saveToolbar.startSaving();


const payload: SocialHistoryForm = {
  alcohol_use: this.editState.alcohol_use,
    alcohol_use_comments: this.editState.alcohol_use_comments,
    smoking_history: this.editState.smoking_history,
    smoking_rate: this.editState.smoking_rate,
    smoking_duration: this.editState.smoking_duration,
    smoking_history_comments: this.editState.smoking_history_comments,
    marijuana_history: this.editState.marijuana_history,
    marijuana_use_comments: this.editState.marijuana_use_comments,
    drug_use: this.editState.drug_use,
    drug_use_comments: this.editState.drug_use_comments,
};

this.protoBucket.setItem(EDITSTATE_KEY, payload);

// Call the 'showFakeSuccess' method to complete successful save animation
if (this.saveToolbar) this.saveToolbar.showFakeSuccess();

} 

public clearForm(): any {
  this.editState = this.originalState;
}

public resetSaveToolbar(): void {
  // Refer to the save toolbar that handles this page
  const saveToolbar = this.$refs.saveSurgery as unknown as SaveProvider;

  saveToolbar.resetSaveToolbar();
}

}



</script>

<style>
</style>
