import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patient_contact_details = _resolveComponent("patient-contact-details")!
  const _component_patient_address = _resolveComponent("patient-address")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoLDContactInformationEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, {
          "section-id": "proto_living_donor_contact_information",
          disabled: !_ctx.canSave,
          onLoaded: _ctx.handleLoaded,
          lookupsToLoad: _ctx.lookupsToLoad,
          showLoading: _ctx.isLoading
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contact_information')), 1)
          ]),
          body: _withCtx(() => [
            (_ctx.isProtoLDPatientContactDetailsEnabled)
              ? (_openBlock(), _createBlock(_component_patient_contact_details, {
                  key: 0,
                  ref: "protoLDPatientContactDetails",
                  onErrors: _ctx.handleErrors,
                  onSave: _ctx.handleSave,
                  onClear: _ctx.handleClear,
                  newRecipient: _ctx.newRecipient,
                  canSave: _ctx.canSave
                }, null, 8, ["onErrors", "onSave", "onClear", "newRecipient", "canSave"]))
              : _createCommentVNode("", true),
            (_ctx.isProtoLDPatientAddressEnabled)
              ? (_openBlock(), _createBlock(_component_patient_address, {
                  key: 1,
                  ref: "protoLDPatientAddress",
                  onErrors: _ctx.handleErrors,
                  onSave: _ctx.handleSave,
                  onClear: _ctx.handleClear,
                  newRecipient: _ctx.newRecipient,
                  canSave: _ctx.canSave
                }, null, 8, ["onErrors", "onSave", "onClear", "newRecipient", "canSave"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onLoaded", "lookupsToLoad", "showLoading"])
      ]))
    : _createCommentVNode("", true)
}