import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav-wrapper" }
const _hoisted_4 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_side_nav = _resolveComponent("loading-side-nav")!
  const _component_loading_donor_page = _resolveComponent("loading-donor-page")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.deceasedDonor)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", {
                class: "skeleton-box",
                style: {"width":"200px"}
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_loading_side_nav),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_loading_donor_page)
                ])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
  ]))
}