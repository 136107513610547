<template>
  <sticky-summary>
    <template v-slot:summaryContent v-if="editState">
      <ul class="donor-summary">
        <li class="list-inline-item">
          <span>{{$t('name_colon')}} <b>{{ livingDonorDisplayName }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('living_donor_id_colon')}} <b>{{ editState.living_donor_id }}</b></span>
        </li>
        <li class="list-inline-item" v-if="editState.transplant_program">
          <span>{{$t('hospital')}}: <b>{{ editState.transplant_program }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('abo')}}: <b>{{ editState.abo }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('sex')}}: <b>{{ editState.sex }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('age')}}: <b>{{ editState.age }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('height')}}: <b>{{ editState.height }}</b></span>
        </li>
        <li class="list-inline-item">
          <span>{{$t('weight')}}: <b>{{ editState.weight }}</b></span>
        </li>
        <li class="list-inline-item">
          <router-link :to="{ name: 'edit-living-donor', params: { id: editState.system_id }}" class="nav-link">{{$t('living_donor_information')}}</router-link>
        </li>
        <li class="list-inline-item">
          <router-link :to="{ name: 'edit-living-donor', params: { id: editState.system_id }, hash: '#hla-typing' }" class="nav-link">{{ $t('hla_information') }}</router-link>
        </li>
      </ul>
      <ul class="organ-summary">
        <li v-for="(consentedOrgan, idx) in selectedLivingDonorConsentedOrganList" :key="idx" :class="listItemClass(consentedOrgan)">
          <router-link :to="consentedOrgan.route" class="nav-link">
            <organ-icon :organ-code="consentedOrgan.organ_code" :summary-bar-icon="true" />
            {{$t(`${consentedOrgan.display_name}`) }}
          </router-link>
        </li>
      </ul>
    </template>
  </sticky-summary>
</template>

<i18n src="@/views/_locales/common.json"></i18n>
<i18n src="@/components/_locales/Organs.json"></i18n>
<i18n src="@/components/deceasedDonors/_locales/commonPatientShared.json"></i18n>
<i18n src="@/components/livingDonors/_locales/LivingDonorStickySummary.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import StickySummary from "@/components/shared/StickySummary.vue";
import { Recipient } from "@/store/recipients/types";
import { RecipientJourney } from "@/store/recipientJourney/types";
import { LivingDonor, LivingDonorAllocationSummary, LivingDonorJourney } from "@/store/livingDonors/types";
import { ClassObject } from '@/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { isMasked, mergeClasses } from '@/utils';
import { Hospital } from '@/store/hospitals/types';

interface livingDonorStickySummaryForm {
  date_of_birth?: string|null|undefined;
  created_at?: string;
  living_donor_id?: string;
  system_id?: number;
  donor_name?: string;
  sex?: string;
  age?: string|number;
  abo?: string|null;
  abo_sub_type?: string|null;
  donor_type?: string;
  height?: number;
  weight?: number
  region?: string;
  serology?: string;
  transplant_program?: string|null;
}

@Component({
  components: {
    OrganIcon,
    StickySummary
  }
})
export default class LivingDonorStickySummary extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.livingDonorStickySummary) editState!: livingDonorStickySummaryForm;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  //Getters
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter("livingDonorDisplayName", { namespace: "livingDonors" }) private livingDonorDisplayName!: string;
  @Getter('getDonorAge', { namespace: 'livingDonors' }) getDonorAge!: number|undefined;
  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;

  @Watch('livingDonor', { immediate: true })
  private onLivingDonorChanged(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'livingDonorStickySummary');
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'livingDonorStickySummary',
      value: this.extractLivingDonorStickySummaryForm(this.livingDonor)
    });
  }

  // Format date for display
  public formatDateTime(dateValue: string): string|undefined {
    if (!isNaN(Date.parse(dateValue))) {
      return this.parseFormattedDateTimeUi(dateValue);
    } else {
      return dateValue;
    }
  }

  public extractLivingDonorStickySummaryForm(donor?: LivingDonor): livingDonorStickySummaryForm {
    // Return empty object if there is no data document
    if (!donor) {
      return {};
    }
    // Sanitize nested objects, so their parameters can easily be read as 'undefined' instead of raising type errors
    const patientProfile = donor.patient_profile || {};
    const referral = donor.referral || {};
    const blood = donor.blood || {};
    const donorMeasurements = donor.measurements || [];
    const birth = donor.patient_profile?.birth || {};

    const addresses = patientProfile.addresses || {};
    // Most recent measurement will be first in the array
    const measurement = donorMeasurements.length > 0 ? donorMeasurements[0] : {};

    const hospital = this.selectedLivingDonorJourney?.transplant_program ? this.getOntarioHospitalById(this.selectedLivingDonorJourney?.transplant_program?.transplant_hospital_id) : undefined;
    const hospitalName = hospital?.hospital_name_info.abbreviation;

    const birthDate = !isMasked(birth.date) ? this.parseDisplayDateUi(birth.date) : birth.date;

    // Return parameters extracted from data document based on structure of form state interface
    return {
      date_of_birth: birthDate,
      system_id: donor.client_id,
      living_donor_id: donor.living_donor_id ? `${donor.living_donor_id}` : '-',
      created_at: this.parseDateUiFromDateTime(donor.created_at),
      donor_name: patientProfile.first_name && patientProfile.last_name,
      sex: patientProfile.sex || undefined,
      age: isMasked(birth.date) ? '*******' : this.getDonorAge,
      abo: blood.sub_type ? blood.sub_type : blood.type,
      height: measurement.height,
      weight: measurement.weight,
      transplant_program: hospitalName || undefined
    };
  }

  //Getter - Selected organ shown as active
  get listItemClass(){
    return (consentedOrgan: any): ClassObject => {
      const option = this.$route.params.option;
      const organCode = this.$route.params.organ_code;
      const consentedOrganRoute = consentedOrgan.route.params.option;
      const consentedOrganCode = consentedOrgan.organ_code;
      let isActive : any;

      if(consentedOrgan.route.params.option)
      {
        isActive = consentedOrgan.display_name.toLowerCase().includes(option) && organCode == consentedOrganCode.toString();
      } else {
        isActive = organCode == consentedOrganCode.toString();
      }
      //Define dynamic class based on the selected organ
      const conditionalClass = {
        'active' : isActive
      };
      return conditionalClass;
    };
  }
}
</script>
