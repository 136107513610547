<template>
  <div v-if="isProtoOfferStatisticsEnabled">
    <card-section
      section-id="proto_offers_statistics"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout formId="proto_offers_statistics-section">
          <template v-slot:contents>
            <sub-section 
              title="OFFERS" 
              sub-section-id="proto_offers_statistics-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                  <div class="summary-list">
                    <dl>
                      <dt>Offer Rate</dt>
                      <dd>6 / month</dd>
                    </dl>
                    <dl>
                      <dt>Totals</dt>
                      <dd>33 offers (11 primary, 22 backup)</dd>
                    </dl>
                  </div>          
                </div>
              </template>
            </sub-section>
          </template>
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { useCurrentPageStore } from '@/stores/currentPage';

  @Component({
    components: {
      CardSection,
      SubSection,
      FormLayout
    }
  })
  export default class ProtoOfferStatistics extends Vue {

    private title: any = "Offer Statistics - Lung";

    get isProtoOfferStatisticsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoOffersStatistics);
  }

  }

</script>

