<template>
  <div v-if="isProtoCommunicationInformationEnabled">
    <card-section
      section-id="proto_communication_information"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout form-id="proto-considerations-section-form">
          <template v-slot:contents>
            <sub-section 
              title="COMMUNICATION INFORMATION" 
              sub-section-id="proto-considerations-section-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                    <!-- Uploaded Files -->
                    <div class="col-sm-12 considerations-section">
                      <fieldset>
                        <div class="row">
                          <div class="standard-form-group">
                            <checkbox-input            
                              name="interpreter-needed"
                              labelName="Interpreter Needed?"
                              inputId="interpreter-needed"
                              v-model="editState.interpreter"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group">
                              <text-input
                              input-id="primary-language"
                                name="Primary Language"
                                validation-id="insuranceStatus"
                                v-model="editState.language"
                              />
                          </div>
                          <div class="standard-form-group-small-checkbox">
                            <checkbox-input            
                              name="poa"
                              labelName="POA?"
                              inputId="poa"
                              v-model="editState.poa"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group">
                            <text-input
                                input-id="Care-partner-name-poa"
                                name="Care Partner Name (POA)"
                                validation-id="CarePartnerName"
                                v-model="editState.carePartnerName"
                              />
                          </div>
                          <div class="standard-form-group">
                            <checkbox-input            
                              name="decision-support"
                              labelName="Decision Support Needed?"
                              inputId="decision-support"
                              v-model="editState.decision"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group">             
                            <text-input
                              inputId="care-partner-for-support"
                              name="Care Partner Name (Decision Support)"
                              v-model="editState.supportPartner" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="standard-form-group-3-quarters">
                            <text-area-input
                              input-id="audiovisual-inpairments-devices"
                              name="Audiovisual Impairments and Devices"
                              rows="4"
                              :showToolTip="true"
                              toolTipText="Describe the patient's audiovisual impairments and devices and how they impact communication."
                              v-model="editState.inpairmentsDevices" />
                            </div>
                        </div>
                        <div class="row">
                          <div class="standard-form-group-3-quarters">
                            <text-area-input
                              input-id="audiovisual-comments"
                              name="Comments"
                              rows="4"
                              v-model="editState.comments" />
                            </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
              </template>
            </sub-section>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="true"
              ref="saveCommunicationInformation"
              :label="`Save ${title}`"
              :cancelButton="true" 
              @save="performSave"       
            />
          </template>
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';

  const EDITSTATE_KEY = 'editState';

  interface CommunicationInformationForm {
    interpreter: boolean,
    language: string,
    decision: boolean,
    supportPartner: string,
    inpairmentsDevices: string,
    comments: string,
    poa: boolean,
    carePartnerName: string,
  }

  @Component({
    components: {
      CardSection,
      SubSection,
      TextInput,
      TextAreaInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout
    },
    emits: [
      'cancel',
    ],
  })
  export default class ProtoCommunicationInformation extends Vue {

    private title: any = "Communication Information";

    private editState: CommunicationInformationForm = {
      interpreter: false,
      language: "",
      decision: false,
      supportPartner: "",
      inpairmentsDevices: "",
      comments: "",
      poa: false,
      carePartnerName: "",
    }

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;


    get isProtoCommunicationInformationEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.CommunicationInformation);
    }

        // Each save toolbar will need a unique ref
    get saveToolbar(): SaveToolbar {
      return this.$refs.saveCommunicationInformation as SaveToolbar;
    }

    async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.CommunicationInformation, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: CommunicationInformationForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        interpreter: response.interpreter,
        decision: response.decision,
        language: response.language,
        supportPartner: response.supportPartner,
        inpairmentsDevices: response.inpairmentsDevices,
        comments: response.comments, 
        poa: response.poa,
        carePartnerName: response.carePartnerName
      };
    }

    public performSave(): any {

      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: CommunicationInformationForm = {
        interpreter: this.editState.interpreter,
        language: this.editState.language,
        decision: this.editState.decision,
        supportPartner: this.editState.supportPartner,
        inpairmentsDevices: this.editState.inpairmentsDevices,
        comments: this.editState.comments,
        poa: this.editState.poa,
        carePartnerName : this.editState.carePartnerName
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 
  }

</script>

<style>


</style>
