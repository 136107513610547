import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/google-logo.png'


const _hoisted_1 = ["action"]
const _hoisted_2 = {
  type: "submit",
  class: "btn btn-dark login float-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    action: _ctx.actionUrl,
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
    method: "post"
  }, [
    _createElementVNode("button", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "logo-image-wrapper" }, [
          _createElementVNode("img", { src: _imports_0 })
        ], -1)),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('login_with_google_workspace')), 1)
      ])
    ])
  ], 40, _hoisted_1))
}