import { defineStore } from 'pinia';
import { CurrentPageState } from '@/stores/currentPage/types';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIPrototypes } from '@/UIModels/prototypes';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { UIDashboard } from '@/UIModels/dashboard';
import { UIPreferences } from '@/UIModels/preferences';
import { UIImportPatients } from '@/UIModels/importPatients';
import i18n from '@/i18n';

const state = (): CurrentPageState => ({
  preferences: new UIPreferences(),
  dashboard: new UIDashboard(),
  configuration: new UIConfiguration(),
  prototypes: new UIPrototypes(),
  currentRecipient: null,
  currentJourney: null,
  importPatients: new UIImportPatients()
});

export function setPageTitle(pageTitle: string): void {
  document.title = pageTitle;
}

export function resetPageTitle(): void {
  document.title = i18n.t('page.loading');
}

export const useCurrentPageStore = defineStore('currentPage', {
  state,
  actions: {
    setCurrentRecipient(uiRecipient: UIRecipient|null): Promise<void> {
      return new Promise<void> ((resolve, _reject) => {
        this.currentRecipient = uiRecipient;
        resolve();
      });
    },
    setCurrentJourney(uiJourney: UIJourney|null): Promise<void> {
      return new Promise<void> ((resolve, _reject) => {
        this.currentJourney = uiJourney;
        resolve();
      });
    },
    setCurrentImportPatients(uiImportPatients: UIImportPatients|null): Promise<void> {
      return new Promise<void> ((resolve, _reject) => {
        this.importPatients = uiImportPatients;
        resolve();
      });
    },
  },
});
