import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12 pace-section" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_6 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoPaceAndEngagementEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_pace_and_engagement" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "pace-and-engagement-section-form" }, {
              contents: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  title: "PACE AND ENGAGEMENT",
                  "sub-section-id": "pace-and-engagement-section-internal",
                  hideDivider: true
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("fieldset", null, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_checkbox_input, {
                                name: "centre-fast-track",
                                labelName: "Transplant Center Fast-Track?",
                                inputId: "centre-fast-track",
                                modelValue: _ctx.editState.fastTrack,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.fastTrack) = $event)),
                                label: "Yes"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                              _cache[4] || (_cache[4] = _createElementVNode("label", null, "Patient Preferred Pace / Motivation", -1)),
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "slow",
                                    hideLabel: "true",
                                    labelName: "Slow / minimize burden",
                                    inputId: "slow",
                                    modelValue: _ctx.editState.slow,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.slow) = $event)),
                                    label: "Slow / minimize burden",
                                    disabled: _ctx.editState.fast
                                  }, null, 8, ["modelValue", "disabled"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "fast",
                                    hideLabel: "true",
                                    labelName: "Go fast",
                                    inputId: "fast",
                                    modelValue: _ctx.editState.fast,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.fast) = $event)),
                                    label: "Go fast",
                                    disabled: _ctx.editState.slow
                                  }, null, 8, ["modelValue", "disabled"])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_text_area_input, {
                                "input-id": "additional-information",
                                name: "Patient Engagement",
                                rows: "4",
                                showToolTip: true,
                                toolTipText: "Is the patient engaged in this process?",
                                modelValue: _ctx.editState.patientEngagement,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.patientEngagement) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "savePaceAndEngagement",
                  label: `Save ${_ctx.title}`,
                  cancelButton: true,
                  onSave: _ctx.performSave
                }, null, 8, ["label", "onSave"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}