import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_6 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_fields = _resolveComponent("loading-fields")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_journey_state_section = _resolveComponent("journey-state-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "organ-details-section",
    key: _ctx.journeyId,
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loaded())),
    disabled: !_ctx.uiJourney.canSave,
    ref: "organDetails",
    confirmation: _ctx.confirmationText,
    "show-loading": !_ctx.editState
  }, {
    header: _withCtx(() => [
      (_ctx.creatingRecipient)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('journey_details')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('journey_overview', { organ: _ctx.organName ? _ctx.$t(_ctx.organName) : '' })), 1)
          ], 64))
    ]),
    body: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loading_fields, { key: 0 }))
        : (_ctx.editState)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_sub_section, {
                ref: "journeyDetailsRef",
                "sub-section-id": "journey-details",
                title: _ctx.$t('journey_details'),
                "save-button-text": _ctx.saveButtonText,
                "save-button": !_ctx.creatingRecipient,
                onSave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.savePatch()))
              }, _createSlots({ _: 2 }, [
                (_ctx.editState.transplant)
                  ? {
                      name: "contents",
                      fn: _withCtx(() => [
                        _createVNode(_component_validation_observer, { ref: "validations" }, {
                          default: _withCtx(() => [
                            _createElementVNode("fieldset", {
                              disabled: !_ctx.uiJourney.canSave
                            }, [
                              (_ctx.creatingRecipient)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, [
                                      _createVNode(_component_select_input, {
                                        selectId: "journey-details-organ",
                                        rules: "required",
                                        name: _ctx.$t('organ'),
                                        modelValue: _ctx.editState.organCode,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.organCode) = $event)),
                                        options: _ctx.buildSingleTypeOrgans
                                      }, null, 8, ["name", "modelValue", "options"])
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_4, [
                                (!_ctx.creatingRecipient)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      (_ctx.checkPropExists('transplant_program.transplant_coordinator_id'))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _createVNode(_component_select_input, {
                                              "select-id": "journey-details-coordinator",
                                              name: _ctx.$t('recipient_coordinator'),
                                              rules: "required",
                                              modelValue: _ctx.editState.transplant.recipientCoordinator,
                                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.transplant.recipientCoordinator) = $event)),
                                              options: _ctx.coordinatorOptions
                                            }, null, 8, ["name", "modelValue", "options"])
                                          ]))
                                        : _createCommentVNode("", true),
                                      (_ctx.isUrgentListingAllowed && _ctx.isUrgentListingEnabled)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createVNode(_component_checkbox_input, {
                                              validationId: "urgent",
                                              "input-id": "rd-urgent",
                                              labelName: _ctx.$t('urgent_journey_label'),
                                              disabled: _ctx.isUrgentListingLocked,
                                              modelValue: _ctx.editState.urgent,
                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.urgent) = $event)),
                                              label: _ctx.$t('yes')
                                            }, null, 8, ["labelName", "disabled", "modelValue", "label"])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ]),
                              (_ctx.isReferredWithEnabled && !_ctx.creatingRecipient)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("div", _hoisted_8, [
                                        _createVNode(_component_select_input, {
                                          "select-id": "referral-package-referred-with",
                                          name: _ctx.$t('referred_with_field'),
                                          modelValue: _ctx.editState.referralPackage.referredWith,
                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.referralPackage.referredWith) = $event)),
                                          options: _ctx.referredWithOptions,
                                          numeric: true,
                                          disabled: _ctx.isReferredWith
                                        }, null, 8, ["name", "modelValue", "options", "disabled"])
                                      ])
                                    ])
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_1)
                          ]),
                          _: 1
                        }, 512)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["title", "save-button-text", "save-button"]),
              _createVNode(_component_journey_state_section, {
                ref: "journeyStateSection",
                onHandleErrors: _ctx.handleErrors,
                newJourney: _ctx.newJourney,
                canSave: _ctx.uiJourney.canSave
              }, null, 8, ["onHandleErrors", "newJourney", "canSave"])
            ], 64))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["lookups-to-load", "disabled", "confirmation", "show-loading"]))
}