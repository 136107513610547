<template v-if="isDonorAcceptabilityEnabled && !newJourney">
  <card-section
    section-id="donor-acceptability-criteria"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('title') }}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('subtitle')"
        sub-section-id="sub_donor_acceptability_criteria"
      >
        <template v-slot:contents>
          <template v-if="isLoading">
            {{$t('loading')}}
          </template>
          <template v-else>
            <!-- Form layout -->
            <validation-observer ref="validations">
            <form-layout

              :disabled="!enableForm"
              form-id="donor-acceptability-details-form"
            >
              <template v-slot:contents>

                <div class="row d-flex justify-content-between">
                  <div class="standard-form-group">
                    <checkbox-group
                      ruleKey="donor_acceptability.accept_donor_types"
                      inputId="accept_donor_types"
                      :name="$t('preferred_donor_type')"
                      v-model="editState.accept_donor_types"
                      :options="currentJourney.applicableDonorTypes"
                    />
                  </div>

                  <div class="standard-form-group-large d-block d-md-flex flex-md-column">
                    <div class="justify-column-between d-flex align-items-center" :key="index" v-for="(condition, index) in editState.accept_criteria_conditions">
                      <span class="label" v-if="!isMobile">
                        {{ `${getDonorAcceptabilityCriteriaValue(condition.donor_condition)}` }}
                      </span>
                      <div class="standard-form-group-large ml-auto align-self-end">
                        <SelectInput
                          ruleKey="donor_acceptability.accept_criteria_conditions"
                          :selectId="`${condition.donor_condition}`"
                          :name="`${getDonorAcceptabilityCriteriaValue(condition.donor_condition)}`"
                          :hideLabel="!isMobile"
                          v-model="editState.accept_criteria_conditions[`${index}`].acceptable"
                          :options="getAcceptDeclineLookup"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-datatable p-datatable-custom scrollbox mt-3">
                  <div class="p-datatable-custom-top" />
                  <div class="p-datatable-table-container">
                    <table class='p-datatable-table' role="table">
                      <thead class='p-datatable-thead' role="rowgroup">
                        <tr>
                          <th style="width: 80px;min-width: 80px;max-width: 80px;overflow: hidden;text-overflow: ellipsis;" class="p-datatable-header-cell" role="columnheader"></th>
                          <th style="width: 255px;min-width: 255px;overflow: hidden;text-overflow: ellipsis;" class="p-datatable-header-cell highlight-yellow-bg" role="columnheader" colspan=2>
                            <div class="p-datatable-column-header-content">
                              {{ $t('min_requirements') }}
                            </div>
                          </th>
                          <th class="p-datatable-header-cell highlight-blue-bg" style="width: 255px; min-width: 255px; overflow: hidden;text-overflow: ellipsis;"  colspan=2 role="columnheader">
                            <div class="p-datatable-column-header-content">
                              {{ $t('max_requirements') }}
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody class="p-datatable-tbody">
                        <tr class="" tabindex="0" role="row">
                          <td role=cell><span>{{ $t("age") }}</span></td>
                          <td role=cell>
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.min_age_yrs"
                                inputId="min_age_yrs"
                                :name="$t('min_age_yrs')"
                                :append="true"
                                :appendText="$t('yr')"
                                :hideLabel="true"
                                v-model="editState.min_age_yrs">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell></td>
                          <td role=cell>
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.max_age_yrs"
                                inputId="max_age_yrs"
                                :name="$t('max_age_yrs')"
                                :hideLabel="true"
                                :append="true"
                                :appendText="$t('yr')"
                                v-model="editState.max_age_yrs">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell></td>
                        </tr>
                        <tr class="" tabindex="0" role="row">
                          <td role=cell>{{ $t("weight") }}</td>
                          <td role=cell>     
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.min_weight_kg"
                                inputId="min_weight_kg"
                                :name="$t('min_weight_kg')"
                                :append="true"
                                :appendText="$t('kg')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromKg($event?.target?.value, 'min'))"
                                v-model="editState.min_weight_kg">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell>
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.min_weight_lb"
                                inputId="min_weight_lb"
                                :name="$t('min_weight_lb')"
                                :append="true"
                                :appendText="$t('lb')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromLb($event?.target?.value, 'min'))"
                                v-model="editState.min_weight_lb">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell>     
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.max_weight_kg"
                                inputId="max_weight_kg"
                                :name="$t('max_weight_kg')"
                                :append="true"
                                :appendText="$t('kg')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromKg($event?.target?.value, 'max'))"
                                v-model="editState.max_weight_kg">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell>
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.max_weight_lb"
                                inputId="max_weight_lb"
                                :name="$t('max_weight_lb')"
                                :append="true"
                                :appendText="$t('lb')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromLb($event?.target?.value, 'max'))"
                                v-model="editState.max_weight_lb">
                              </number-input>
                            </span>
                          </td>
                        </tr>
                        <tr class="" tabindex="0" role="row">
                          <td role=cell>{{ $t("height") }}</td>
                          <td role=cell>     
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.min_height_cm"
                                inputId="min_height_cm"
                                :name="$t('min_height_cm')"
                                :append="true"
                                :appendText="$t('cm')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromCm($event?.target?.value, 'min'))"
                                v-model="editState.min_height_cm">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell>
                            <span class="d-flex flex-column flex-xl-row">
                              <number-input
                                ruleKey="donor_acceptability.min_height_ft"
                                inputId="min_height_ft"
                                :name="$t('min_height_ft')"
                                :append="true"
                                inputClass="mr-xl-2"
                                :appendText="$t('ft')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromFeet($event?.target?.value, editState.min_height_in, 'min'))"
                                v-model="editState.min_height_ft"
                                :integer="true">
                              </number-input>
                              <number-input
                                ruleKey="donor_acceptability.min_height_in"
                                inputId="min_height_in"
                                :name="$t('min_height_in')"
                                :append="true"
                                inputClass="ml-xl-2"
                                :appendText="$t('in')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromInches(editState.min_height_ft, $event?.target?.value, 'min'))"
                                v-model="editState.min_height_in">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell>     
                            <span>
                              <number-input
                                ruleKey="donor_acceptability.max_height_cm"
                                inputId="max_height_cm"
                                :name="$t('max_height_cm')"
                                :append="true"
                                :appendText="$t('cm')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromCm($event?.target?.value, 'max'))"
                                v-model="editState.max_height_cm">
                              </number-input>
                            </span>
                          </td>
                          <td role=cell>
                            <span class="d-flex flex-column flex-xl-row">
                              <number-input
                                ruleKey="donor_acceptability.max_height_ft"
                                inputId="max_height_ft"
                                :name="$t('max_height_ft')"
                                :append="true"
                                inputClass="mr-xl-2"
                                :appendText="$t('ft')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromFeet($event?.target?.value, editState.max_height_in, 'max'))"
                                v-model="editState.max_height_ft"
                                :integer="true">
                              </number-input>
                              <number-input
                                ruleKey="donor_acceptability.max_height_in"
                                inputId="max_height_in"
                                :name="$t('max_height_in')"
                                :append="true"
                                inputClass="ml-xl-2"
                                :appendText="$t('in')"
                                :hideLabel="true"
                                step="0.1"
                                @blur="performCalculation(editState.calculateFromInches(editState.max_height_ft, $event?.target?.value, 'max'))"
                                v-model="editState.max_height_in">
                              </number-input>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr/>

                <div class="row">
                  <div class="standard-comment-group">
                    <text-area-input
                      inputId="donor_acceptability_comments"
                      ruleKey="donor_acceptability.comments"
                      :name="$t('donor_acceptability_comments')"
                      v-model="editState.comments" />
                  </div>
                </div>
              </template>

              <template v-if="!newJourney && enableSaveToolbar" v-slot:save>
                <save-toolbar
                  :show="showSaveToolbar"
                  ref="saveToolbar"
                  class="card-footer action-row temp-saving row"
                  :label="$t('save_dac')"
                  :cancelButton="true"
                  @save="handleSave()"
                  @cancel="handleCancel()"
                />
              </template>
            </form-layout>
            </validation-observer>
          </template>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveResult, APIPermittedActions } from "@/types";
import SelectInput from "@/components/shared/SelectInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { IdLookup } from '@/store/validations/types';
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { i18nMessages } from "@/i18n";
import { UIDonorAcceptabilityDetails } from "@/UIModels/journey/donorAcceptabilityDetails";
import { UIJourney } from "@/UIModels/journey";
import CheckboxGroup from '@/components/shared/CheckboxGroup.vue';
import { parseFormErrors } from '@/utils';

@Component({
  components: {
    TextAreaInput,
    SelectInput,
    FormLayout,
    SaveToolbar,
    CardSection,
    SubSection,
    NumberInput,
    CheckboxGroup
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/DonorAcceptabilityDetails.json'),
  ]),
  emits: [
    'loaded',
    'handleErrors',
    'clear',
  ],
})
export default class DonorAcceptabilityDetails extends Vue {
  @State(state => state.lookups.organ) private organ!: any[];

  // Properties
  @Prop({ default: false }) newJourney!: boolean;

  private isLoading = false;

  // Immutable view model based on current journey (e.g for cancel)
  private selection = new UIDonorAcceptabilityDetails();

  private editState = this.selection.copyViewModel();

  private lookupsToLoad = ['organ'];

  private isMobile = false;

  get uiJourney(): UIJourney {
    return useCurrentPageStore().currentJourney as UIJourney;
  }

  get isDonorAcceptabilityEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.donorAcceptability.enabled;
  }

  private async performCalculation(functionToRun: any): Promise<void> {
    await functionToRun;

    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.validate();
  }

  private get getAcceptDeclineLookup(): {code: string, value: string}[] {
    const options = [
      { code: 'true', value: this.$t('accept') },
      { code: 'false', value: this.$t('decline') }
    ];
    return options;
  }

  public get getDonorAcceptabilityCriteriaLookup(): any {
    const organCode = this.currentJourney.organ_code;
    if (!organCode) return [];

    const specificOrgan = this.organ.find((organ: any) => {
      return organ.code === organCode;
    });

    const donorAcceptabilityCriteriaLookup = specificOrgan?.sub_tables?.donor_acceptability_criteria || [];
    return donorAcceptabilityCriteriaLookup;
  }

  public getDonorAcceptabilityCriteriaValue(code: string|null): string|null {
    const found = this.getDonorAcceptabilityCriteriaLookup.find((item: any) => {
      return item.code === code;
    });
    return found ? found.value : null;
  }

  // Which Journey view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  private get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens DonorAcceptability#loaded
   * @emits loaded
   */
   public loaded(): void {
    this.$emit('loaded', 'donor_acceptability_details');
  }

  /**
   * Populates the Recipient Death form state with data from the selected Recipient.
   */
   public async initializeForm(): Promise<void> {
    this.editState = this.selection.copyViewModel();
    await this.editState.calculateMeasurements();
    this.resetFormErrors();
  }

  // Resets Form Errors
  public resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  private async resetEditState(): Promise<void> {
    if (!this.selection) return;
    this.editState = this.selection.copyViewModel();
    await this.editState.calculateMeasurements();
  }

  public checkwidth() {
    if (window.innerWidth < 772) {
      this.isMobile = window.innerWidth < 772;
    } else {
      this.isMobile = false;
    }
  }

  async mounted(): Promise<void> {
    const currentPageStore = useCurrentPageStore();
    const uiJourney = currentPageStore.currentJourney;
    if (!uiJourney) return;

    this.checkwidth();
    window.addEventListener('resize', this.checkwidth);

    try {
      await uiJourney.loadDonorAcceptabilityDetails();
      const uiDonorAcceptabilityDetails = uiJourney.donorAcceptabilityDetails as UIDonorAcceptabilityDetails;
      if (uiDonorAcceptabilityDetails) {
        this.selection = uiDonorAcceptabilityDetails.copyViewModel();
      } 
      this.initializeForm();
      this.$emit('loaded', 'donor_acceptability_details');
      this.isLoading = false;
    } catch(uiError: any) {
      this.isLoading = false;
      console.warn('Something unexpected happen when attempting to get donor acceptability details', uiError);
    }
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();

    const currentPageStore = useCurrentPageStore();
    const uiJourney = currentPageStore.currentJourney;
    if (!uiJourney) return;

    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.validate();

    const params = {
      recipient: uiJourney.clientId,
      journey: uiJourney.journeyId
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      // reload record
      await uiJourney.loadDonorAcceptabilityDetails();
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);

    // re-load data
    this.resetSelection();
    this.resetEditState();

    // reset form errors
    this.resetFormErrors();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.resetSelection();
    this.resetEditState();
    this.resetSaveToolbar();
    this.$emit('clear', this.editState);
  }

  private resetSelection(): void {
    const selection = this.currentJourney.donorAcceptabilityDetails || null;
    if (selection) {
      this.selection = selection.copyViewModel();
    } 
  }

  // Reference to Pancreas Toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveToolbar;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  // Dismiss save toolbar success or error indicator
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.uiJourney.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.uiJourney.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.uiJourney.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    const permitted_actions = this.editState.permitted_actions;
    // If no permitted actions return false, do not show on read-only or new
    if (permitted_actions.length === 0) return false;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return permitted_actions.includes(APIPermittedActions.Update);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {
      'donor_acceptability_details.donor_acceptability.comments'        : 'donor_acceptability_comments',
      'donor_acceptability_details.donor_acceptability.max_age_yrs'     : 'max_age_yrs',
      'donor_acceptability_details.donor_acceptability.min_age_yrs'     : 'min_age_yrs',
      'donor_acceptability_details.donor_acceptability.max_height_cm'   : 'max_height_cm',
      'donor_acceptability_details.donor_acceptability.max_weight_kg'   : 'max_weight_kg',
      'donor_acceptability_details.donor_acceptability.min_height_cm'   : 'min_height_cm',
      'donor_acceptability_details.donor_acceptability.min_weight_kg'   : 'min_weight_kg',
    };
    return result;
  }
}
</script>
