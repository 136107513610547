import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "adaptive-top-scrollbar",
  ref: "adaptiveTopScrollbarVertical"
}
const _hoisted_2 = { class: "horizontal-view-wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["onMouseover", "onClick"]
const _hoisted_6 = {
  key: 0,
  class: "p-column-title"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["onMouseover"]
const _hoisted_9 = {
  key: 1,
  class: "marker-name"
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = ["title"]
const _hoisted_12 = { class: "text-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_ToolTipControl = _resolveComponent("ToolTipControl")!
  const _component_Column = _resolveComponent("Column")!
  const _component_more_less = _resolveComponent("more-less")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.tableConfig && _ctx.tableConfig.columns)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["mb-4", _ctx.getStyleClass()])
      }, [
        (_ctx.showTopScrollBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
              _createElementVNode("div", null, null, -1)
            ]), 512))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DataTable, {
            id: _ctx.tableId,
            ref: _ctx.tableId,
            showGridlines: true,
            value: this.tableConfig.data || [],
            loading: _ctx.isLoading,
            rowKey: "id",
            lazy: _ctx.isLazy,
            class: _normalizeClass(_ctx.getTableClass),
            totalRecords: _ctx.getNumberOfRecords,
            selectionMode: `${_ctx.highlightSelection ? 'single' : null}`,
            expandedRows: this.tableConfig.data || [],
            rowGroupMode: _ctx.getGroupMode,
            onScroll: _ctx.handleScroll,
            groupRowsBy: _ctx.getGroupBy,
            "filter-display": _ctx.filterDisplay,
            "show-filter-menu": false,
            "sort-mode": "single",
            "removable-sort": true,
            onSort: _ctx.onSort,
            onColumnResizeEnd: _ctx.columnResizeEnd
          }, _createSlots({
            groupheader: _withCtx(() => [
              _cache[3] || (_cache[3] = _createElementVNode("span", { "aria-hidden": "true" }, " ", -1))
            ]),
            empty: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'exclamation-circle'],
                "fixed-width": ""
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.tableConfig.empty || _ctx.$t('use_form_below_new_row')), 1)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableConfig.columns, (col) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (!col.expanded && !col.hidden)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        field: col.field,
                        class: _normalizeClass(col.class),
                        header: col.label,
                        sortable: col.sortable || false,
                        key: col.field,
                        style: _normalizeStyle(_ctx.buildColumnStyle(col)),
                        bodyClass: col.tdClass,
                        frozen: col.frozen || false,
                        alignFrozen: "left"
                      }, {
                        filter: _withCtx(() => [
                          (col.filterOptions)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (col.filterOptions.type == 'text')
                                  ? (_openBlock(), _createBlock(_component_text_input, {
                                      key: 0,
                                      "input-id": `${col.field}_filter`,
                                      name: col.label,
                                      "hide-label": true,
                                      "model-value": _ctx.searchParams[col.field],
                                      "onUpdate:modelValue": (event) => _ctx.onFilter(event, col)
                                    }, null, 8, ["input-id", "name", "model-value", "onUpdate:modelValue"]))
                                  : (col.filterOptions.type == 'dropdown')
                                    ? (_openBlock(), _createBlock(_component_select_input, {
                                        key: 1,
                                        "select-id": `${col.field}_filter`,
                                        name: col.label,
                                        "hide-label": true,
                                        "model-value": _ctx.searchParams[col.field],
                                        "onUpdate:modelValue": (event) => _ctx.onFilter(event, col),
                                        options: col.filterOptions.filterDropdownItems,
                                        undefinedText: col.filterOptions.placeholder
                                      }, null, 8, ["select-id", "name", "model-value", "onUpdate:modelValue", "options", "undefinedText"]))
                                    : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        header: _withCtx(() => [
                          (col.frozen)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                ref_for: true,
                                ref: "frozen-col",
                                title: col.label
                              }, _toDisplayString(col.label), 9, _hoisted_4))
                            : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(["t-clickable-column", _ctx.getColumnClass(col.field)]),
                                label: "Clear",
                                outlined: ""
                              }, [
                                (col.collection_comments)
                                  ? (_openBlock(), _createBlock(_component_ToolTipControl, {
                                      key: 0,
                                      isFormELement: false,
                                      toolTipText: col.collection_comments
                                    }, null, 8, ["toolTipText"]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("a", {
                                  href: "",
                                  onMouseover: ($event: any) => (_ctx.onColumnHover(col)),
                                  onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearHover())),
                                  onClick: _withModifiers(($event: any) => (_ctx.onColumnClick(col)), ["prevent"])
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(col.date), 1),
                                  _createElementVNode("span", null, _toDisplayString(col.time), 1)
                                ], 40, _hoisted_5)
                              ], 2))
                        ]),
                        body: _withCtx((slotProps) => [
                          (_ctx.isMobile)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(col.label), 1))
                            : _createCommentVNode("", true),
                          (col.html)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                innerHTML: slotProps.data[col.field]
                              }, null, 8, _hoisted_7))
                            : _createCommentVNode("", true),
                          (col.marker)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 2,
                                onMouseover: ($event: any) => (_ctx.onColumnHover(col)),
                                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearHover())),
                                class: _normalizeClass(`serology-marker ${_ctx.generateSerologyMarkerCss(col.field, slotProps.data)} ${_ctx.getColumnClass(col.field)}`)
                              }, [
                                (_ctx.getToolTipForMarker(col.field, slotProps.data))
                                  ? (_openBlock(), _createBlock(_component_ToolTipControl, {
                                      key: 0,
                                      isFormELement: false,
                                      toolTipText: _ctx.getToolTipForMarker(col.field, slotProps.data)
                                    }, null, 8, ["toolTipText"]))
                                  : _createCommentVNode("", true),
                                (slotProps.data[col.field])
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(slotProps.data[col.field]), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_10, "-"))
                              ], 42, _hoisted_8))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 3,
                                title: slotProps.data[col.field],
                                ref_for: true,
                                ref: "frozen-col"
                              }, [
                                _createElementVNode("span", _hoisted_12, _toDisplayString(slotProps.data[col.field]), 1)
                              ], 8, _hoisted_11))
                        ]),
                        _: 2
                      }, 1032, ["field", "class", "header", "sortable", "style", "bodyClass", "frozen"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ]),
            _: 2
          }, [
            (_ctx.getNameOfExpandedField)
              ? {
                  name: "expansion",
                  fn: _withCtx((slotProps) => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_more_less, {
                        descriptionText: slotProps.data[_ctx.getNameOfExpandedField]
                      }, null, 8, ["descriptionText"])
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["id", "value", "loading", "lazy", "class", "totalRecords", "selectionMode", "expandedRows", "rowGroupMode", "onScroll", "groupRowsBy", "filter-display", "onSort", "onColumnResizeEnd"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}