import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "standard-form-group-large"
}
const _hoisted_4 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_12 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_13 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_14 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_15 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_20 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_21 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_22 = { class: "row" }
const _hoisted_23 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_24 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_25 = {
  key: 0,
  class: "row"
}
const _hoisted_26 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_27 = {
  key: 1,
  class: "standard-form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoLivingDonorDemographicsEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, {
          "section-id": "proto_living_donor_demographics",
          "lookups-to-load": _ctx.lookupsToLoad,
          "hospitals-to-load": _ctx.hospitalsToLoad,
          disabled: !_ctx.canSave
        }, _createSlots({
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 2
        }, [
          (_ctx.editState)
            ? {
                name: "body",
                fn: _withCtx(() => [
                  _createVNode(_component_validation_observer, { ref: "validations" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_form_layout, { "form-id": "ld-demographics-form" }, {
                        contents: _withCtx(() => [
                          _createVNode(_component_sub_section, {
                            title: _ctx.$t('registration_information'),
                            "sub-section-id": "ld-demographics-internal",
                            disabled: !_ctx.canSave,
                            hideDivider: true
                          }, {
                            contents: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                (_ctx.checkPropExists('transplant_centre.hospital_id'))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                      _createVNode(_component_select_input, {
                                        ruleKey: "transplant_centre.hospital_id",
                                        "select-id": "ld-demographics-personal-transplant-program",
                                        name: _ctx.$t('transplant_program'),
                                        modelValue: _ctx.editState.transplant_program,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.transplant_program) = $event)),
                                        options: _ctx.hospitalOptions
                                      }, null, 8, ["name", "modelValue", "options"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.checkPropExists('transplant_centre.mrn'))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createVNode(_component_text_input, {
                                        ruleKey: "transplant_centre.mrn",
                                        inputId: "ld-demographics-personal-mrn",
                                        name: _ctx.$t('mrn'),
                                        modelValue: _ctx.editState.mrn,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.mrn) = $event))
                                      }, null, 8, ["name", "modelValue"])
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_5, [
                                  _createVNode(_component_text_input, {
                                    inputId: "ld-demographics-personal-client_id",
                                    name: _ctx.$t('client_id'),
                                    disabled: true,
                                    modelValue: _ctx.editState.afflo_id,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.afflo_id) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                (_ctx.checkPropExists('patient_profile.ctr.national_recipient_id'))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                      _createVNode(_component_text_input, {
                                        inputId: "ld-demographics-personal-ctr_id",
                                        name: _ctx.$t('CTR_Number'),
                                        disabled: true,
                                        modelValue: _ctx.editState.ctr_id,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.ctr_id) = $event))
                                      }, null, 8, ["name", "modelValue"])
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_7, [
                                  _createVNode(_component_date_input, {
                                    inputId: "ld-demographics-personal-registration_date",
                                    name: _ctx.$t('living_donor_registration_date'),
                                    disabled: true,
                                    modelValue: _ctx.editState.registration_date,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.registration_date) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_text_input, {
                                    inputId: "ld-demographics-personal-registration_time",
                                    name: _ctx.$t('living_donor_registration_time'),
                                    disabled: true,
                                    modelValue: _ctx.editState.registration_time,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.registration_time) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                (_ctx.isUrgentRecipientEnabled)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                      _createVNode(_component_checkbox_input, {
                                        ruleKey: "patient_profile.urgent",
                                        validationId: "urgent",
                                        "input-id": "ld-demographics-internal-urgent",
                                        labelName: _ctx.$t('urgent_recipient_label'),
                                        disabled: !_ctx.newRecipient,
                                        title: _ctx.isUrgent ? _ctx.$t('urgent_recipient_hover_explanation') : '',
                                        modelValue: _ctx.editState.urgent_recipient,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.urgent_recipient) = $event)),
                                        label: "Yes"
                                      }, null, 8, ["labelName", "disabled", "title", "modelValue"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 1
                          }, 8, ["title", "disabled"]),
                          (_ctx.isPersonalInfoEnabled)
                            ? (_openBlock(), _createBlock(_component_sub_section, {
                                key: 0,
                                title: _ctx.$t('personal_information'),
                                "sub-section-id": "ld-demographics-personal",
                                disabled: !_ctx.canSave,
                                hideDivider: true
                              }, {
                                contents: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_10, [
                                    (_ctx.checkPropExists('patient_profile.first_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.first_name",
                                            inputId: "ld-demographics-personal-firstname",
                                            name: _ctx.$t('first_name'),
                                            modelValue: _ctx.editState.first_name,
                                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.first_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.middle_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.middle_name",
                                            inputId: "ld-demographics-personal-middlename",
                                            name: _ctx.$t('middle_name'),
                                            modelValue: _ctx.editState.middle_name,
                                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.middle_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.last_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.last_name",
                                            inputId: "ld-demographics-personal-lastname",
                                            name: _ctx.$t('last_name'),
                                            modelValue: _ctx.editState.last_name,
                                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.last_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.preferred_name'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.preferred_name",
                                            inputId: "ld-demographics-personal-preferred_name",
                                            name: _ctx.$t('preferred_name'),
                                            modelValue: _ctx.editState.preferred_name,
                                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.preferred_name) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.preferred_pronouns'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.preferred_pronouns",
                                            inputId: "ld-demographics-personal-preferred_pronouns",
                                            name: _ctx.$t('preferred_pronouns'),
                                            modelValue: _ctx.editState.preferred_pronouns,
                                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.preferred_pronouns) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _createElementVNode("div", _hoisted_16, [
                                    (_ctx.checkPropExists('patient_profile.birth.date'))
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createElementVNode("div", _hoisted_17, [
                                            _createVNode(_component_date_input, {
                                              ruleKey: "patient_profile.birth.date",
                                              inputId: "ld-demographics-personal-dob",
                                              name: _ctx.$t('dob'),
                                              modelValue: _ctx.editState.date_of_birth,
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.date_of_birth) = $event)),
                                              minDateLimit: _ctx.getDobMinDateLimit,
                                              maxDateLimit: _ctx.getDobMaxDateLimit
                                            }, null, 8, ["name", "modelValue", "minDateLimit", "maxDateLimit"])
                                          ]),
                                          _createElementVNode("div", _hoisted_18, [
                                            _createVNode(_component_text_input, {
                                              inputId: "ld-demographics-personal-age",
                                              name: _ctx.$t('age'),
                                              disabled: true,
                                              calculated: true,
                                              modelValue: _ctx.editState.age_in_years,
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.age_in_years) = $event))
                                            }, null, 8, ["name", "modelValue"])
                                          ])
                                        ], 64))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.government_identification.number'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                          _createVNode(_component_text_input, {
                                            ruleKey: "patient_profile.government_identification.number",
                                            inputId: "ld-demographics-personal-government_identification_number",
                                            name: _ctx.$t('government_identification_number'),
                                            modelValue: _ctx.editState.government_identification_number,
                                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.government_identification_number) = $event))
                                          }, null, 8, ["name", "modelValue"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.race_codes'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                          _createVNode(_component_select_input, {
                                            ruleKey: "patient_profile.race_codes",
                                            selectId: "ld-demographics-personal-race",
                                            multiple: true,
                                            disabled: !_ctx.canSave,
                                            name: _ctx.$t('race'),
                                            modelValue: _ctx.editState.race,
                                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.race) = $event)),
                                            options: _ctx.raceOptions
                                          }, null, 8, ["disabled", "name", "modelValue", "options"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.ethnicity_code'))
                                      ? (_openBlock(), _createBlock(_component_select_other_input, {
                                          key: 3,
                                          ruleKey: "patient_profile.ethnicity_code",
                                          "select-id": "ld-demographics-personal-ethnicity",
                                          name: _ctx.$t('ethnicity'),
                                          validationId: "ethnicity_code",
                                          modelValue: _ctx.editState.ethnicity,
                                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.ethnicity) = $event)),
                                          options: _ctx.ethnicityLookup,
                                          onChange: _ctx.clearEthnicityOther,
                                          otherTitle: _ctx.$t('ethnicity_other'),
                                          enableOther: _ctx.showEthnicityOther,
                                          colStyling: "standard-form-group-with-other",
                                          reduceColumnWidth: "standard-form-group"
                                        }, {
                                          other: _withCtx(() => [
                                            _createVNode(_component_text_input, {
                                              ruleKey: "patient_profile.ethnicity_other",
                                              "input-id": "ld-demographics-personal-ethnicityother",
                                              name: _ctx.$t('ethnicity_other'),
                                              modelValue: _ctx.editState.ethnicity_other,
                                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.ethnicity_other) = $event))
                                            }, null, 8, ["name", "modelValue"])
                                          ]),
                                          _: 1
                                        }, 8, ["name", "modelValue", "options", "onChange", "otherTitle", "enableOther"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.indigenous_identity_codes'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                          _createVNode(_component_select_input, {
                                            ruleKey: "patient_profile.indigenous_identity_codes",
                                            selectId: "ld-demographics-personal-indigenous_identity_codes",
                                            multiple: true,
                                            disabled: !_ctx.canSave,
                                            name: _ctx.$t('indigenous_identity_codes'),
                                            modelValue: _ctx.editState.indigenous_identity_codes,
                                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.indigenous_identity_codes) = $event)),
                                            options: _ctx.indigenousIdentityOptions
                                          }, null, 8, ["disabled", "name", "modelValue", "options"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _createElementVNode("div", _hoisted_22, [
                                    (_ctx.checkPropExists('patient_profile.sex'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                          _createVNode(_component_select_input, {
                                            ruleKey: "patient_profile.sex",
                                            selectId: "ld-demographics-internal-sex",
                                            name: _ctx.$t('sex'),
                                            modelValue: _ctx.editState.sex_at_birth,
                                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.sex_at_birth) = $event)),
                                            options: _ctx.sexOptions
                                          }, null, 8, ["name", "modelValue", "options"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.checkPropExists('patient_profile.gender_sex_different'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                          _createVNode(_component_checkbox_input, {
                                            ruleKey: "patient_profile.gender_sex_different",
                                            validationId: "gender_sex_different",
                                            "input-id": "ld-demographics-personal-gender-different",
                                            labelName: _ctx.$t('gender_is_different'),
                                            modelValue: _ctx.editState.gender_sex_different,
                                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.gender_sex_different) = $event)),
                                            label: _ctx.$t('yes')
                                          }, null, 8, ["labelName", "modelValue", "label"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.editState.gender_sex_different && _ctx.checkPropExists('patient_profile.gender'))
                                      ? (_openBlock(), _createBlock(_component_select_other_input, {
                                          key: 2,
                                          ruleKey: "patient_profile.gender",
                                          "select-id": "ld-demographics-personal-gender",
                                          name: _ctx.$t('gender'),
                                          validationId: "gender",
                                          modelValue: _ctx.editState.gender_identity,
                                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.gender_identity) = $event)),
                                          options: _ctx.genderLookup,
                                          onChange: _ctx.clearGenderOther,
                                          otherTitle: _ctx.$t('gender_other'),
                                          colStyling: "standard-form-group-with-other",
                                          reduceColumnWidth: "standard-form-group",
                                          enableOther: _ctx.checkPropExists('patient_profile.gender_other')
                                        }, {
                                          other: _withCtx(() => [
                                            _createVNode(_component_text_input, {
                                              ruleKey: "patient_profile.gender_other",
                                              "input-id": "demographics-personal-other-gender",
                                              name: _ctx.$t('gender_other'),
                                              modelValue: _ctx.editState.gender_other,
                                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.gender_other) = $event))
                                            }, null, 8, ["name", "modelValue"])
                                          ]),
                                          _: 1
                                        }, 8, ["name", "modelValue", "options", "onChange", "otherTitle", "enableOther"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  (_ctx.checkAtLeastOnePropExists(['patient_profile.education.highest_education_level', 'patient_profile.education.academic_grade_level']))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                        (_ctx.checkPropExists('patient_profile.education.highest_education_level'))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                              _createVNode(_component_select_input, {
                                                ruleKey: "patient_profile.education.highest_education_level",
                                                selectId: "ld-demographics-personal-highest_education_level",
                                                name: _ctx.$t('highest_education_level'),
                                                modelValue: _ctx.editState.highest_education_level,
                                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.highest_education_level) = $event)),
                                                options: _ctx.highestEducationLevelOptions
                                              }, null, 8, ["name", "modelValue", "options"])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_ctx.checkPropExists('patient_profile.education.academic_grade_level'))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                              _createVNode(_component_select_input, {
                                                ruleKey: "patient_profile.education.academic_grade_level",
                                                selectId: "ld-demographics-personal-academic_grade_level",
                                                name: _ctx.$t('academic_grade_level'),
                                                modelValue: _ctx.editState.academic_grade_level,
                                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.academic_grade_level) = $event)),
                                                options: _ctx.academicGradeLevelOptions
                                              }, null, 8, ["name", "modelValue", "options"])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["title", "disabled"]))
                            : _createCommentVNode("", true)
                        ]),
                        save: _withCtx(() => [
                          _createVNode(_component_save_toolbar, {
                            show: _ctx.showSaveToolbar,
                            ref: "saveLDDemographics",
                            label: _ctx.newRecipient ? _ctx.$t('save_recipient') : _ctx.$t('save_demographics'),
                            onSave: _cache[25] || (_cache[25] = ($event: any) => (_ctx.performSave())),
                            cancelButton: true,
                            onCancel: _cache[26] || (_cache[26] = ($event: any) => (_ctx.clearForm()))
                          }, null, 8, ["show", "label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["lookups-to-load", "hospitals-to-load", "disabled"])
      ]))
    : _createCommentVNode("", true)
}