import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "content-wrap page-top" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "page-top-right" }
const _hoisted_4 = { class: "system-title d-inline" }
const _hoisted_5 = { class: "dropdown b-dropdown menu-big page-top-link" }
const _hoisted_6 = {
  href: "#",
  class: "dropdown-toggle",
  role: "button",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false",
  id: "customizeDash"
}
const _hoisted_7 = {
  class: "dropdown-menu keep-open dropdown-menu-end",
  "aria-labelledby": "customizeDash",
  style: {"max-width":"350px"}
}
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12" }
const _hoisted_11 = { class: "menu-big-heading" }
const _hoisted_12 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_13 = {
  key: 1,
  class: "col-sm-12"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "checklist border-0" }
const _hoisted_16 = { class: "form-check" }
const _hoisted_17 = ["id", "onUpdate:modelValue", "disabled"]
const _hoisted_18 = ["for"]
const _hoisted_19 = {
  key: 2,
  class: "col-sm-12"
}
const _hoisted_20 = { class: "col-sm-12" }
const _hoisted_21 = { class: "big-menu-actions" }
const _hoisted_22 = { class: "content-wrap dashboard-widgets" }
const _hoisted_23 = { class: "container-fluid" }
const _hoisted_24 = {
  key: 0,
  class: "row"
}
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "col-12" }
const _hoisted_27 = { class: "card full" }
const _hoisted_28 = { class: "card-header media" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_loading_widget = _resolveComponent("loading-widget")!
  const _component_widget_bookmarks = _resolveComponent("widget-bookmarks")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('dashboard')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.uiEnvVersion) + " ", 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, { icon: ['far', 'cog'] }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('customize')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("form", null, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("h5", _hoisted_11, _toDisplayString(_ctx.$t('available_dashboard_panels')), 1)
                    ]),
                    (!_ctx.uiDashboard.loaded)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[1] || (_cache[1] = [
                          _createElementVNode("span", {
                            class: "skeleton-box w-100",
                            style: {"height":"30px"}
                          }, null, -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          (_ctx.uiDashboard.widgets.length === 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: ['far', 'exclamation-circle'],
                                  "fixed-width": ""
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('no_widgets_available')), 1)
                              ]))
                            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.uiDashboard.widgets, (uiWidget) => {
                                return (_openBlock(), _createElementBlock("ul", {
                                  class: "nav flex-column",
                                  key: uiWidget.name
                                }, [
                                  _createElementVNode("li", null, [
                                    _createElementVNode("div", _hoisted_15, [
                                      _createElementVNode("div", _hoisted_16, [
                                        _withDirectives(_createElementVNode("input", {
                                          type: "checkbox",
                                          class: "form-check-input d-none",
                                          id: `${uiWidget.name}-visible`,
                                          "onUpdate:modelValue": ($event: any) => ((uiWidget.visible) = $event),
                                          disabled: uiWidget.required
                                        }, null, 8, _hoisted_17), [
                                          [_vModelCheckbox, uiWidget.visible]
                                        ]),
                                        _createElementVNode("label", {
                                          class: "form-check-label",
                                          for: `${uiWidget.name}-visible`
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['far', uiWidget.visible ? 'check-square' : 'square']
                                          }, null, 8, ["icon"]),
                                          _createTextVNode(" " + _toDisplayString(_ctx.$t('show')) + " ", 1),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t(uiWidget.name)), 1)
                                        ], 8, _hoisted_18)
                                      ])
                                    ])
                                  ])
                                ]))
                              }), 128))
                        ])),
                    (_ctx.errorMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-sm btn-success mt-2 mb-3",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveCustomizeDashboardPreferences()))
                        }, _toDisplayString(_ctx.$t('save_preferences')), 1)
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        (!_ctx.uiDashboard.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createVNode(_component_loading_widget)
            ]))
          : (_ctx.uiDashboard.empty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'exclamation-circle'],
                        "fixed-width": ""
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('no_widgets_visible')), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
        _createVNode(_component_widget_bookmarks, { ref: "dashBookmark" }, null, 512)
      ])
    ])
  ]))
}