import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id", "disabled"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "legend-title" }
const _hoisted_4 = {
  key: 1,
  class: "btn-group float-right",
  role: "group",
  "aria-label": "card section button group"
}
const _hoisted_5 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_loading_section = _resolveComponent("loading-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card card-form", _ctx.getAccordionClass])
  }, [
    _createElementVNode("fieldset", {
      id: _ctx.sectionId,
      disabled: _ctx.saveButton ? _ctx.disabled : false
    }, [
      (_ctx.showLegend)
        ? (_openBlock(), _createElementBlock("legend", _hoisted_2, [
            _createElementVNode("h3", _hoisted_3, [
              (_ctx.enableAccordion)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    tabindex: "0",
                    type: "button",
                    class: "btn btn-sm accordion-toggle",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCardSection && _ctx.toggleCardSection(...args))),
                    "aria-label": "Toggle the section"
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['fas', 'caret-down'],
                      class: _normalizeClass(["accordion-icon", { rotate: _ctx.show }])
                    }, null, 8, ["class"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: _normalizeClass({ 'pt-1': _ctx.showActions, 'd-inline-block': _ctx.showActions })
              }, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t('default_header')), 1)
                ], true)
              ], 2),
              (_ctx.showActions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_ctx.showActions)
                      ? _renderSlot(_ctx.$slots, "actions", { key: 0 }, undefined, true)
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLoading || _ctx.showLoading)
        ? (_openBlock(), _createBlock(_component_loading_section, { key: 1 }))
        : (_openBlock(), _createBlock(_Transition, {
            key: 2,
            name: "accordion",
            onBeforeEnter: _ctx.beforeEnter,
            onEnter: _ctx.enter,
            onBeforeLeave: _ctx.beforeLeave,
            onLeave: _ctx.leave
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true),
                _createVNode(_component_save_toolbar, {
                  class: "action-row temp-saving",
                  show: _ctx.saveButton,
                  disabled: _ctx.disabled,
                  buttonClass: "btn btn-wide btn-success sec-submit",
                  label: _ctx.saveButtonText,
                  ref: _ctx.saveToolbarRef(),
                  onSave: _ctx.performSave
                }, null, 8, ["show", "disabled", "label", "onSave"])
              ], 512), [
                [_vShow, _ctx.show]
              ])
            ]),
            _: 3
          }, 8, ["onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave"]))
    ], 8, _hoisted_1)
  ], 2))
}