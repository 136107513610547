<template>
  <sub-section
  v-if="isProtoHeartMyocardialPerfusionEnabled"
    sub-section-id="proto_heart_myocardial_perfusion"
    :title="title"
  >
  <template v-slot:contents>
    
    <!-- Filter Bar + Create Button -->
    <table-toolbar
      :createButton="true"
      createText="Create Myocardial Perfusion Scan"
      @table-create-row="clearForm"
    >
      <template v-slot:button-bar>
        <div class="filter-section-action-row">
          <div class="filter-section-wrapper">
            <filter-component
              fieldID="contactFilter"
              :showFilter="false"
              :showArchived="false"
            />
          </div>
        </div>
      </template>
    </table-toolbar>

    <!-- List of Items, or History List -->
    <table-list
      ref="myocardialPerfusionTable"
      tabbableColumn="scan_date"
      table-id="myocardial-perfusion-table"
      :table-config="myocardialPerfusionTableConfig"
      @table-row-click="editSelected"
      :highlightSelection="true"
      :rowStyleClass="rowStyleClass"
    />

    <!-- Form layout -->
  
    <form-layout
    form-id="echoResult-form">
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="isNew" class="legend-title">
            NEW MYOCARDIAL PERFUSION SCAN
          </h5>
          <h5 v-else class="legend-title has-guiding-text">
            SELECTED MYOCARDIAL PERFUSION SCAN
          </h5>
        </legend>
      </template>
      <template v-slot:contents>
        <div class="row">
          <div class="standard-form-group">
            <date-input
              inputId="myocardial-scan_date"
              name='Scan Date'
              v-model="editState.scan_date"   
            />
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="rhythm"
              name='Rhythm'
              :options="rhythmOptions"
              v-model="editState.rhythm">              
            </select-input>
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="arrhythmias"
              name='Arrhythmias'
              :options="arrhythmiasOptions"
              v-model="editState.arrhythmias">              
            </select-input>
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="iv_contrast"
              name='IV Contrast'
              :options="yesNoOptions"
              v-model="editState.iv_contrast">              
            </select-input>
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="ischemia"
              name='Ischemia'
              :options="yesNoOptions"
              v-model="editState.ischemia">              
            </select-input>
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="heart_rate"
              name="Heart Rate"
              :append="true"
              appendText="bpm"
              v-model="editState.heart_rate"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="heart_bp_systolic"
              name="BP Systolic"
              :append="true"
              appendText="mmHg"
              v-model="editState.bp_systolic"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="heart_bp_diastolic"
              name="BP Diastolic"
              :append="true"
              appendText="mmHg"
              v-model="editState.bp_diastolic"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-large">
            <text-area-input input-id="outcome" name="Outcome" rows="4" v-model="editState.outcome"  />
          </div>
        </div>
      </template>
      <template v-slot:save>
        <save-toolbar
          :show="true"     
          ref="saveScan"
          label="Save Myocardial Perfusion Scan"
          :cancelButton="true"
          @save="performSave()"
          @cancel="clearForm()"
        />
      </template>
    </form-layout>    
    </template>
  </sub-section>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from '@/components/shared/TimeInput.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface MyocardialPerfusionForm {
  id: string,
  scan_date: Date|null,
  rhythm: string,
  arrhythmias: string,
  iv_contrast: string,
  ischemia: string,
  heart_rate: number|null;
  bp_systolic: number|null;
  bp_diastolic:  number|null;
  outcome: string
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    TimeInput,
    NumberInput,
    SelectInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoMyocardialPerfusion extends mixins(DateUtilsMixin) {

  private title: any = "MYOCARDIAL PERFUSION SCAN";
  private isNew: any = true;
 
  private editState: MyocardialPerfusionForm = {
    id: '',
    scan_date: null,
    rhythm: "",
    arrhythmias: "",
    iv_contrast: "",
    ischemia: "",
    heart_rate: null,
    bp_systolic: null,
    bp_diastolic: null,
    outcome: ''
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private scans: any = [];

  public perPage = 10;
  public currentPageTable = 1;

  private rhythmOptions = [{
    code: 0,
    value: 'Normal Sinus Rhythm'
    }, {
      code: 1,
      value: 'Sinus Tachycardia'
    },
    {
      code: 2,
      value: 'Sinus Bradycardia'
    },
    {
      code: 3,
      value: 'Atrial Fibrillation'
    },
    {
      code: 4,
      value: 'Ventricular Tachycardia'
    }
  ];

  private arrhythmiasOptions = [{
    code: 0,
    value: 'None'
    }, {
      code: 1,
      value: 'Premature Ventricular Contractions (PVCs)'
    },
    {
      code: 2,
      value: 'Atrial Fibrillation (AFib)'
    },
    {
      code: 3,
      value: 'Ventricular Tachycardia (VT)'
    },
    {
      code: 4,
      value: 'Supraventricular Tachycardia (SVT)'
    }
  ];
  
  private yesNoOptions = [{
    code: 0,
    value: 'Yes'
    }, {
      code: 1,
      value: 'No'
    }
  ];
  

  get isProtoHeartMyocardialPerfusionEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.HeartMyocardialPerfusion);
  }

  get myocardialPerfusionTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Scan Date', field: 'scanDateDisplayValue', width: '125px'},
      { label: 'Rhythm', field: 'rhythmDisplayValue',  width: '200px' },
      { label: 'Arrhythmias', field: 'arrhythmiasDisplayValue',  width: '350px' },
      { label: 'Heart Rate', field: 'heart_rate',  width: '125px' },
      { label: 'BP Systolic', field: 'bp_systolic',  width: '125px' },   
      { label: 'BP Diastolic', field: 'bp_diastolic',  width: '125px' },        
      { label: 'Outcome', field: 'outcome',  width: '400px' },
    ],
    empty: 'use the form below to add a new row',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'scan_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveScan as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.HeartMyocardialPerfusion, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: MyocardialPerfusionForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.scans = response;
}

translateCategoryOption(code: any, data: any) {
  const found: any = data.find((item: any) => {
    return item.code == code;
  });
  if (found) {
    return found.value;
  }

}

get tableRows() {
  const scans = this.scans || [];

  if (scans.length > 0) {

  return scans.map((scan: any) => {

    return {
      id: scan.id,
      scan_date: scan.scan_date,
      scanDateDisplayValue: this.parseDisplayDateUi(scan.scan_date) || '-',
      rhythm: scan.rhythm || '-',
      rhythmDisplayValue: this.translateCategoryOption(scan.rhythm, this.rhythmOptions),
      arrhythmias: scan.arrhythmias || '-',
      arrhythmiasDisplayValue: this.translateCategoryOption(scan.arrhythmias, this.arrhythmiasOptions),
      heart_rate: scan.heart_rate || '-',
      bp_systolic: scan.bp_systolic || '-',
      bp_diastolic: scan.bp_diastolic || '-',
      outcome: scan.outcome
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let scans = this.scans || [];

  const payload: MyocardialPerfusionForm = {
    id: Math.random().toString(16).slice(2),
    scan_date: this.editState.scan_date,
    rhythm: this.editState.rhythm,
    arrhythmias: this.editState.arrhythmias,
    iv_contrast: this.editState.iv_contrast,
    ischemia: this.editState.ischemia,
    heart_rate: this.editState.heart_rate,
    bp_systolic: this.editState.bp_systolic,
    bp_diastolic: this.editState.bp_diastolic,
    outcome: this.editState.outcome
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = scans.findIndex((scan: any) => scan.id == this.editState.id);
    
    if (foundIndex != -1) {
      scans[foundIndex] = payload;
    }
  } else {  
    scans.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, scans);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
    if (row) {  
      this.editState.id = row.row.id;
      this.editState.scan_date = row.row.scan_date;
      this.editState.rhythm = row.row.rhythm;
      this.editState.arrhythmias = row.row.arrhythmias;
      this.editState.iv_contrast = row.row.iv_contrast;
      this.editState.ischemia = row.row.ischemia;
      this.editState.heart_rate = row.row.heart_rate;
      this.editState.bp_systolic = row.row.bp_systolic;
      this.editState.bp_diastolic = row.row.bp_diastolic;
      this.editState.outcome = row.row.outcome;
      this.isNew = false;
    }
  }

  public clearForm(): any {
    this.isNew = true;
    this.editState.scan_date = null;
    this.editState.rhythm = "";
    this.editState.arrhythmias = "";
    this.editState.iv_contrast = "";
    this.editState.ischemia = "";
    this.editState.heart_rate = null;
    this.editState.bp_systolic = null;
    this.editState.bp_diastolic = null;
    this.editState.outcome = "";
  }

  rowStyleClass(row: any) {
    // if row editable, change color
    return row.editable ? 'tr-highlight-green' : null;
  }

  // Reference to table
  get myocardialPerfusionTable(): any {
    return this.$refs.myocardialPerfusionTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.myocardialPerfusionTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.myocardialPerfusionTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveScan as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}

</script>
