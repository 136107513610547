import { APIConfiguration, APIShowConfigurationData } from '@/APIModels/configuration/types';
import { APIRoute, EP } from '@/api-endpoints';
import { APIShowResponse } from '@/types';
import axios from 'axios';
import { UIFeaturesConfig } from '@/UIModels/configuration/features';
import { FormatDefinition, Format } from '@/store/utilities/types';
import i18n from '@/i18n';
import { APIAppConfig } from '@/APIModels/configuration/app/types';

export class UIConfiguration {
  public apiSource?: APIConfiguration;
  public loaded = false;

  public systemTimeZone = 'America/Toronto';
  public systemTimeZoneAbbreviation = 'ET';
  public appBaseClass = 'afflo-application';
  public appLogo = '';
  public loginProvider = '';
  public userSelect = false;
  public bypassFusionAuth = false;
  public prototypes = false;

  public applicationNamePrefix?: string;
  public helpUrl?: string;

  public dateFormat = 'iso';
  public i18nEnabled = false;
  public defaultLocale = 'en';
  public availableLocales = ['en'];
  public regions = ['ON']

  public features: UIFeaturesConfig = UIFeaturesConfig.empty();

  // Get a string representing the current Node environment e.g. production, development
  get uiEnvVersion(): string {
    return process.env.VUE_APP_DEPLOY_VERSION || process.env.NODE_ENV;
  }

  public constructor(apiConfiguration?: APIConfiguration) {
    if (apiConfiguration) this.updateFromAPIConfiguration(apiConfiguration);
  }

  public updateFromAPIConfiguration(apiConfiguration: APIConfiguration) {
    if (!apiConfiguration) throw new Error('Cannot initialize configuration without API source');
    this.apiSource = apiConfiguration;

    const appConfig: APIAppConfig = apiConfiguration.app;
    if (!appConfig) throw new Error('Cannot initialize configuration without App configuration');

    const featuresConfig = apiConfiguration.features;
    if (!featuresConfig) throw new Error('Cannot initialize configuration without Features configuration');

    this.systemTimeZone = appConfig.time_zone;
    this.systemTimeZoneAbbreviation = appConfig.time_zone_abbreviation;
    this.appBaseClass = appConfig.base_class;
    this.appLogo = appConfig.logo;

    this.loginProvider = appConfig.login_provider;
    this.userSelect = appConfig.enable_user_select;
    this.bypassFusionAuth = appConfig.bypass_fusionauth;
    this.prototypes = appConfig.prototypes;

    this.applicationNamePrefix = appConfig.application_name_prefix;
    this.helpUrl = appConfig.help_url;

    const localizationConfig = appConfig.localization;
    this.dateFormat = localizationConfig.date_format;
    this.i18nEnabled = localizationConfig.enable_i18n;
    this.defaultLocale = localizationConfig.default_locale;
    this.availableLocales = localizationConfig.enable_i18n ? localizationConfig.enabled_locales : [localizationConfig.default_locale];
    this.regions = localizationConfig.regions;

    this.features = new UIFeaturesConfig(featuresConfig);

    this.loaded = true;
  }

  get dateFormatDefinition(): FormatDefinition {
    return Format(this.dateFormat);
  }

  get getTimezoneAbbreviation(): string {
    return i18n.t(this.systemTimeZoneAbbreviation);
  }

  // Checks for bad configuration, if incorrectly setup return false
  get sanityCheck(): boolean {
    if (!this.apiSource) return false;

    let result = true;
    // check configuration, if fail, set return to false
    if (!this.dateFormat) result = false;
    if (!this.appBaseClass) result = false;
    if (!this.systemTimeZone) result = false;
    if (!this.defaultLocale) result = false;
    return result;
  }

  public replaceTimezoneAbbreviation(key: string) {
    const timezoneAbbr = this.getTimezoneAbbreviation || '';
    return key.replaceAll('__timezone__', timezoneAbbr);
  }

  // returns true if region includes province code
  public regionIncludesProvinceCode(object: ObjectWithLocation): boolean {
    const province_code = object.location?.province_code || null;
    return province_code ? this.regions.includes(province_code) : false;
  }

  public async load(): Promise<void> {
    const url = APIRoute(EP.configuration.show);
    try {
      const response: APIShowResponse<APIShowConfigurationData> = await axios.get(url);
      const apiConfiguration: APIConfiguration = response?.data?.configuration;
      this.updateFromAPIConfiguration(apiConfiguration);
    } catch (error: unknown) {
      console.warn(error);
    }
  }
}
export interface ObjectWithLocation {
  location?: {
    province_code?: string
  }
}
