import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { id: "administration-user-account" }
const _hoisted_3 = { class: "legend-title" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "form-group standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-full-width-copy" }
const _hoisted_10 = { class: "standard-full-width-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_16 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_17 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_18 = { class: "row" }
const _hoisted_19 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_20 = { class: "table-responsive-md" }
const _hoisted_21 = { class: "table table-bordered table-hover" }
const _hoisted_22 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_23 = { class: "table-responsive-md" }
const _hoisted_24 = { class: "table table-bordered table-hover" }
const _hoisted_25 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_26 = { class: "table-responsive-md" }
const _hoisted_27 = { class: "table table-bordered table-hover" }
const _hoisted_28 = { id: "administration-user-notifications" }
const _hoisted_29 = { class: "legend-title" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "standard-form-group-6column-large-up" }
const _hoisted_32 = { id: "administration-user-notifications" }
const _hoisted_33 = { class: "legend-title" }
const _hoisted_34 = { class: "table-responsive-md" }
const _hoisted_35 = {
  key: 0,
  class: "table table-bordered table-hover"
}
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { colspan: "2" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { key: 1 }
const _hoisted_42 = {
  key: 1,
  class: "table table-bordered table-hover"
}
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { key: 1 }
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { key: 1 }
const _hoisted_48 = {
  key: 1,
  class: "form-btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_profile_page = _resolveComponent("loading-profile-page")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createBlock(_component_loading_profile_page, { key: 0 }))
      : (_ctx.editState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_card_section, {
              ref: "manageAccounts",
              "section-id": "administration-subscriptions",
              "lookups-to-load": _ctx.lookupsToLoad,
              onLoaded: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loaded()))
            }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("manage_accounts")), 1)
              ]),
              body: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  mode: "remote",
                  title: _ctx.$t('manage_users'),
                  "sub-section-id": "administration-list-user-accounts",
                  "style-class": "vue-good-table-bordered",
                  tabbableValue: "First Name",
                  "total-records": _ctx.getUserCount,
                  "table-config": _ctx.usersTableConfig,
                  onOnPageChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePagination($event))),
                  onOnPerPageChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePagination($event))),
                  onOnColumnFilter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterList($event))),
                  onOnSortChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterList($event))),
                  onTableRowClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectUser($event))),
                  onTableCreateRow: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createUser())),
                  highlightSelection: true
                }, null, 8, ["title", "total-records", "table-config"])
              ]),
              _: 1
            }, 8, ["lookups-to-load"]),
            _createVNode(_component_validation_observer, {
              ref: "validations",
              autocomplete: "off",
              tag: "form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_card_section, {
                  ref: "userProfile",
                  "section-id": "user-profile"
                }, _createSlots({ _: 2 }, [
                  (_ctx.editState && _ctx.editState.user)
                    ? {
                        name: "header",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(!_ctx.isNewUser ? _ctx.$t("create_user") : _ctx.$t("edit_user")), 1)
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (_ctx.editState && _ctx.editState.user)
                    ? {
                        name: "body",
                        fn: _withCtx(() => [
                          _createElementVNode("fieldset", _hoisted_2, [
                            _createElementVNode("legend", null, [
                              _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("personal_information")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_text_input, {
                                  rules: "required",
                                  disabled: _ctx.isNewUser,
                                  "input-id": "oauth2_user_identifier",
                                  name: _ctx.$t('oauth_user_id'),
                                  modelValue: _ctx.editState.user.oauth2_user_identifier,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.user.oauth2_user_identifier) = $event))
                                }, null, 8, ["disabled", "name", "modelValue"])
                              ]),
                              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "row-break d-none d-md-block d-xxl-none" }, null, -1)),
                              _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_text_input, {
                                  rules: "required",
                                  "input-id": "first_name",
                                  disabled: _ctx.isNewUser,
                                  name: _ctx.$t('first_name'),
                                  modelValue: _ctx.editState.user.first_name,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.user.first_name) = $event))
                                }, null, 8, ["disabled", "name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_text_input, {
                                  rules: "required",
                                  "input-id": "last_name",
                                  disabled: _ctx.isNewUser,
                                  name: _ctx.$t('last_name'),
                                  modelValue: _ctx.editState.user.last_name,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.user.last_name) = $event))
                                }, null, 8, ["disabled", "name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_text_input, {
                                  "input-id": "email",
                                  disabled: _ctx.isNewUser,
                                  name: _ctx.$t('email'),
                                  modelValue: _ctx.editState.user.email,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.user.email) = $event))
                                }, null, 8, ["disabled", "name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("role_not_editable")), 1),
                              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "row-break d-none d-xxxl-block" }, null, -1)),
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_select_input, {
                                  rules: "required",
                                  "select-id": "role_names",
                                  name: _ctx.$t('roles'),
                                  placeholder: _ctx.$t('add_role'),
                                  modelValue: _ctx.editState.user.role_names,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.user.role_names) = $event)),
                                  options: _ctx.availableRoles,
                                  multiple: true,
                                  disabled: !_ctx.isAdmin,
                                  onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.rolesChanged()))
                                }, null, 8, ["name", "placeholder", "modelValue", "options", "disabled"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "row-break d-none d-md-block d-xxl-none" }, null, -1)),
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_date_input, {
                                  rules: "required",
                                  "input-id": "effective_date",
                                  name: _ctx.$t('start_date'),
                                  disabled: !_ctx.isAdmin,
                                  modelValue: _ctx.editState.user.effective_date,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.user.effective_date) = $event))
                                }, null, 8, ["name", "disabled", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_date_input, {
                                  "input-id": "expiry_date",
                                  name: _ctx.$t('end_date'),
                                  disabled: !_ctx.isAdmin,
                                  modelValue: _ctx.editState.user.expiry_date,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.user.expiry_date) = $event))
                                }, null, 8, ["name", "disabled", "modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "row-break d-none d-md-block d-xxl-none" }, null, -1)),
                              (_ctx.hasRecipientCoordinatorAssignments)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("dl", null, [
                                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('recipient_coordinator_heading')), 1),
                                      _createElementVNode("dd", null, _toDisplayString(_ctx.getCoordinatorName), 1)
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.hasLivingDonorCoordinatorAssignments)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("dl", null, [
                                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('living_donor_coordinator_heading')), 1),
                                      _createElementVNode("dd", null, _toDisplayString(_ctx.getCoordinatorName), 1)
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.editState.user.responsible_physician)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    _createElementVNode("dl", null, [
                                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('responsible_physician_heading')), 1),
                                      _createElementVNode("dd", null, _toDisplayString(_ctx.getPhysicianName), 1)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              (_ctx.hasRecipientCoordinatorAssignments)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                    _createElementVNode("fieldset", null, [
                                      _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("table", _hoisted_21, [
                                          _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t("program")), 1)
                                            ])
                                          ]),
                                          _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coordinatorHospitals(_ctx.recipientCoordinatorAssignments), (hospital, index) => {
                                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                _createElementVNode("td", null, _toDisplayString(hospital.program_identifier), 1)
                                              ]))
                                            }), 128))
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.hasLivingDonorCoordinatorAssignments)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                    _createElementVNode("fieldset", null, [
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("table", _hoisted_24, [
                                          _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t("program")), 1)
                                            ])
                                          ]),
                                          _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coordinatorHospitals(_ctx.livingDonorCoordinatorAssignments), (hospital, index) => {
                                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                _createElementVNode("td", null, _toDisplayString(hospital.program_identifier), 1)
                                              ]))
                                            }), 128))
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.editState.user.responsible_physician)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                    _createElementVNode("fieldset", null, [
                                      _createElementVNode("div", _hoisted_26, [
                                        _createElementVNode("table", _hoisted_27, [
                                          _createElementVNode("thead", null, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t("program")), 1),
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t("location")), 1)
                                            ])
                                          ]),
                                          _createElementVNode("tbody", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.physicianHospitals(_ctx.responsiblePhysicianAssignments), (hospital, index) => {
                                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                _createElementVNode("td", null, _toDisplayString(hospital.program_identifier), 1),
                                                _createElementVNode("td", null, _toDisplayString(hospital.location ? hospital.location.city : ''), 1)
                                              ]))
                                            }), 128))
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _createElementVNode("fieldset", _hoisted_28, [
                            _createElementVNode("legend", null, [
                              _createElementVNode("h4", _hoisted_29, _toDisplayString(_ctx.$t("manage_delivery_mechanisms")), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("manage_delivery_methods_instructions")), 1),
                            _createElementVNode("div", _hoisted_30, [
                              _createElementVNode("div", _hoisted_31, [
                                _createVNode(_component_text_input, {
                                  "input-id": "cell",
                                  name: _ctx.$t('cell_number'),
                                  mask: _ctx.getTelephoneMask,
                                  modelValue: _ctx.editState.user.sms,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.user.sms) = $event))
                                }, null, 8, ["name", "mask", "modelValue"])
                              ])
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("manage_delivery_methods_note")), 1)
                          ])
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1536),
                (_ctx.editState && _ctx.editState.user && _ctx.editState.user.notification_options.length > 0)
                  ? (_openBlock(), _createBlock(_component_card_section, {
                      key: 0,
                      ref: "saveNotifications",
                      "section-id": "user-notifications"
                    }, {
                      header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("notifications")), 1)
                      ]),
                      body: _withCtx(() => [
                        _createElementVNode("form", null, [
                          _createElementVNode("fieldset", _hoisted_32, [
                            _createElementVNode("legend", null, [
                              _createElementVNode("h4", _hoisted_33, _toDisplayString(_ctx.$t("manage_notifications")), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("notifications_mechanisms_blank")), 1),
                            _createElementVNode("div", _hoisted_34, [
                              (!_ctx.editState.user.not_grouped)
                                ? (_openBlock(), _createElementBlock("table", _hoisted_35, [
                                    _createElementVNode("thead", null, [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("th", null, _toDisplayString(_ctx.$t("notification")), 1),
                                        (_ctx.showRequiredMethodsColumn)
                                          ? (_openBlock(), _createElementBlock("th", _hoisted_36, [
                                              _createTextVNode(_toDisplayString(_ctx.$t("primary_mechanisms")) + " ", 1),
                                              _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                                              _createElementVNode("small", null, [
                                                _cache[21] || (_cache[21] = _createElementVNode("b", { class: "text-red" }, "*", -1)),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t("select_at_least_one")), 1)
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_ctx.showOptionalMethodsColumn)
                                          ? (_openBlock(), _createElementBlock("th", _hoisted_37, _toDisplayString(_ctx.$t("optional_mechanisms")), 1))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.editState.user.notification_options_grouped), (entry, index) => {
                                      return (_openBlock(), _createElementBlock("tbody", {
                                        key: `${index}_notification_group`
                                      }, [
                                        _createElementVNode("tr", null, [
                                          _createElementVNode("th", _hoisted_38, _toDisplayString(_ctx.$t(entry[0])), 1)
                                        ]),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.user.notification_lookup, (channel, index) => {
                                          return (_openBlock(), _createElementBlock("tr", {
                                            key: channel.code
                                          }, [
                                            (channel.sub_group == entry[0])
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                  _createElementVNode("td", {
                                                    innerHTML: _ctx.$t(channel.value)
                                                  }, null, 8, _hoisted_39),
                                                  (_ctx.showRequiredMethodsColumn)
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_40, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.required_delivery_mechanisms, (mechanism) => {
                                                          return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                            _createVNode(_component_checkbox_input, {
                                                              class: "full-width",
                                                              "input-id": `user-notifications-required-${channel.code}-${mechanism}`,
                                                              modelValue: _ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism],
                                                              "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism]) = $event),
                                                              label: _ctx.$t(mechanism)
                                                            }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                          ]))
                                                        }), 128))
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (_ctx.showOptionalMethodsColumn)
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_41, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.optional_delivery_mechanisms, (mechanism) => {
                                                          return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                            _createVNode(_component_checkbox_input, {
                                                              class: "full-width",
                                                              "input-id": `user-notifications-optional-${channel.code}-${mechanism}`,
                                                              modelValue: _ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism],
                                                              "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism]) = $event),
                                                              label: _ctx.$t(mechanism)
                                                            }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                          ]))
                                                        }), 128))
                                                      ]))
                                                    : _createCommentVNode("", true)
                                                ], 64))
                                              : _createCommentVNode("", true)
                                          ]))
                                        }), 128))
                                      ]))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("table", _hoisted_42, [
                                    _createElementVNode("thead", null, [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("th", null, _toDisplayString(_ctx.$t("notification")), 1),
                                        (_ctx.showRequiredMethodsColumn)
                                          ? (_openBlock(), _createElementBlock("th", _hoisted_43, [
                                              _createTextVNode(_toDisplayString(_ctx.$t("primary_mechanisms")) + " ", 1),
                                              _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                                              _createElementVNode("small", null, [
                                                _cache[23] || (_cache[23] = _createElementVNode("b", { class: "text-red" }, "*", -1)),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t("select_at_least_one")), 1)
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_ctx.showOptionalMethodsColumn)
                                          ? (_openBlock(), _createElementBlock("th", _hoisted_44, _toDisplayString(_ctx.$t("optional_mechanisms")), 1))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _createElementVNode("tbody", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.user.notification_lookup, (channel, index) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                          key: channel.code
                                        }, [
                                          _createElementVNode("td", {
                                            innerHTML: _ctx.$t(channel.value)
                                          }, null, 8, _hoisted_45),
                                          (_ctx.showRequiredMethodsColumn)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_46, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.required_delivery_mechanisms, (mechanism) => {
                                                  return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                    _createVNode(_component_checkbox_input, {
                                                      class: "full-width",
                                                      "input-id": `user-notifications-required-${channel.code}-${mechanism}`,
                                                      modelValue: _ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism],
                                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].required_delivery_mechanisms[mechanism]) = $event),
                                                      label: _ctx.$t(mechanism)
                                                    }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true),
                                          (_ctx.showOptionalMethodsColumn)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_47, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(channel.optional_delivery_mechanisms, (mechanism) => {
                                                  return (_openBlock(), _createElementBlock("div", { key: mechanism }, [
                                                    _createVNode(_component_checkbox_input, {
                                                      class: "full-width",
                                                      "input-id": `user-notifications-optional-${channel.code}-${mechanism}`,
                                                      modelValue: _ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism],
                                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState.user.notification_options[index].optional_delivery_mechanisms[mechanism]) = $event),
                                                      label: _ctx.$t(mechanism)
                                                    }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label"])
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]))
                                      }), 128))
                                    ])
                                  ]))
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }, 512))
                  : _createCommentVNode("", true),
                (_ctx.editState && _ctx.editState.user)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                      _createVNode(_component_save_toolbar, {
                        ref: "userAccount",
                        label: _ctx.$t('save_user'),
                        onSave: _cache[16] || (_cache[16] = ($event: any) => (_ctx.performSave()))
                      }, null, 8, ["label"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 512)
          ]))
        : _createCommentVNode("", true)
  ]))
}