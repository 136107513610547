import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  href: "#allocation-recommendation-listing",
  class: "allocation-jump-link"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "table-responsive-md allocationOffers" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_6 = { class: "fixed-head" }
const _hoisted_7 = { scope: "col" }
const _hoisted_8 = ["id"]
const _hoisted_9 = {
  key: 0,
  class: "td-check"
}
const _hoisted_10 = { class: "checklist border-0" }
const _hoisted_11 = { class: "form-check" }
const _hoisted_12 = ["aria-checked", "id", "value", "checked", "onChange", "disabled"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = {
  key: 2,
  class: "text-center"
}
const _hoisted_16 = { class: "text-center font-weight-bold" }
const _hoisted_17 = ["href", "title"]
const _hoisted_18 = { key: 3 }
const _hoisted_19 = {
  key: 4,
  class: "set-link font-weight-bold"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "text-center font-weight-bold" }
const _hoisted_24 = { class: "text-center" }
const _hoisted_25 = { class: "text-center font-weight-bold" }
const _hoisted_26 = { class: "text-center" }
const _hoisted_27 = {
  key: 0,
  class: "text-center"
}
const _hoisted_28 = { class: "highlight-tip" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "no-hover action-table-row sticky-row" }
const _hoisted_31 = { colspan: "100%" }
const _hoisted_32 = { class: "nav action-row flex-align-end" }
const _hoisted_33 = { class: "offer-button-wrap" }
const _hoisted_34 = ["disabled"]
const _hoisted_35 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_36 = ["disabled"]
const _hoisted_37 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_38 = { class: "ml-auto offer-timers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_offer_response_accept = _resolveComponent("offer-response-accept")!
  const _component_offer_response_decline = _resolveComponent("offer-response-decline")!
  const _component_compare_modal = _resolveComponent("compare-modal")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_offer_icon = _resolveComponent("offer-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_offer_timers = _resolveComponent("offer-timers")!
  const _component_sorted_table = _resolveComponent("sorted-table")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('title_offer_responses'),
    "sub-section-id": "allocation-offer-responses",
    ref: "allocationOfferResponses",
    "save-button": false,
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loaded())),
    disabled: _ctx.isRespondingOffer
  }, _createSlots({ _: 2 }, [
    (_ctx.allocation)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createVNode(_component_offer_response_accept, {
              ref: "offerResponseAccept",
              onReloadTable: _ctx.reloadTable,
              onDisplayOutcomeNotification: _ctx.displayOutcomeNotificationFromAcceptModal
            }, null, 8, ["onReloadTable", "onDisplayOutcomeNotification"]),
            _createVNode(_component_offer_response_decline, { ref: "offerResponseDecline" }, null, 512),
            _createVNode(_component_compare_modal, { ref: "compareModal" }, null, 512),
            (!_ctx.showCombined)
              ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
                  _createTextVNode(_toDisplayString(`${_ctx.$t('allocation_recommendation_listing')}`) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'arrow-up'],
                    "fixed-width": ""
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.allocationResponseErrorMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.allocationResponseErrorMessage), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_sorted_table, {
                    values: _ctx.editState.rows || [],
                    class: "table table-bordered table-hover table-allocation table-offers",
                    sort: "rank"
                  }, {
                    body: _withCtx((sort) => [
                      _createElementVNode("tbody", null, [
                        _createElementVNode("tr", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (rowName) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (rowName === 'lastName')
                                ? (_openBlock(), _createElementBlock("td", {
                                    key: `${rowName}-lastName`
                                  }, [
                                    _createVNode(_component_text_input, {
                                      class: "form-group",
                                      inputId: `${rowName}_filter`,
                                      hideLabel: true,
                                      name: _ctx.$t('last_name'),
                                      type: "text",
                                      value: _ctx.searchParams[rowName] ? _ctx.searchParams[rowName].value : '',
                                      onInput: ($event: any) => (_ctx.updateFilters($event, rowName)),
                                      disabled: _ctx.isLoadingAllocation
                                    }, null, 8, ["inputId", "name", "value", "onInput", "disabled"])
                                  ]))
                                : (rowName === 'hospital_abbreviation')
                                  ? (_openBlock(), _createElementBlock("td", {
                                      key: `${rowName}-hospital_abbreviation`
                                    }, [
                                      _createVNode(_component_select_input, {
                                        "select-id": "offer-response-program-search-by",
                                        class: "form-group",
                                        name: _ctx.$t('transplant_program'),
                                        hideLabel: true,
                                        undefinedText: _ctx.$t('all'),
                                        modelValue: _ctx.editState.programFilterValue,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.programFilterValue) = $event)),
                                        options: _ctx.editState.programOptions,
                                        onChange: ($event: any) => (_ctx.updateFilters($event, rowName, true)),
                                        disabled: _ctx.isLoadingAllocation
                                      }, null, 8, ["name", "undefinedText", "modelValue", "options", "onChange", "disabled"])
                                    ]))
                                  : (rowName === 'selected')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-selected`
                                      }, [
                                        (_ctx.showControls)
                                          ? (_openBlock(), _createBlock(_component_checkbox_input, {
                                              key: 0,
                                              "input-id": "offer-response-select-all-matching-rows-response",
                                              label: _ctx.$t('select_all'),
                                              modelValue: _ctx.editState.selectAllMatchingRows,
                                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.selectAllMatchingRows) = $event)),
                                              onChange: _ctx.selectAllMatchingRows,
                                              disabled: _ctx.isLoadingAllocation
                                            }, null, 8, ["label", "modelValue", "onChange", "disabled"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-el`
                                      }, " "))
                            ], 64))
                          }), 256))
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sort.values, (row, idx) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            id: `offer_${!_ctx.checkMasked(row.client_id) ? row.client_id : idx}`,
                            class: _normalizeClass(_ctx.getRowStyle(row)),
                            key: idx
                          }, [
                            (_ctx.showCheckbox(row))
                              ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
                                  _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("div", _hoisted_11, [
                                      _createElementVNode("input", {
                                        type: "checkbox",
                                        role: "checkbox",
                                        "aria-checked": row.selected,
                                        id: `offer-response-${row.idx}`,
                                        class: "table-check-input",
                                        value: row.selected,
                                        checked: !!row.selected,
                                        onChange: ($event: any) => (_ctx.checkRow($event, row)),
                                        disabled: _ctx.isLoadingAllocation || !_ctx.showControls
                                      }, null, 40, _hoisted_12)
                                    ])
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("td", _hoisted_13, " ")),
                            _createElementVNode("td", _hoisted_14, [
                              _createVNode(_component_offer_icon, {
                                offer: row.offerType,
                                response: row.responseCode
                              }, null, 8, ["offer", "response"])
                            ]),
                            (_ctx.tableHeaders.includes('organ_spec_offered'))
                              ? (_openBlock(), _createElementBlock("td", _hoisted_15, _toDisplayString(_ctx.$t(row.organ_spec_offered)), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", _hoisted_16, _toDisplayString(row.rank), 1),
                            _createElementVNode("td", null, [
                              (_ctx.isLoadingAllocation || _ctx.checkMasked(row.client_id) || _ctx.showCombined)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(row.client_id), 1)
                                  ], 64))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: `#recommendation_${row.rank}`,
                                    title: _ctx.$t('link_recipient_in_offer_recommendation_table'),
                                    class: "table-link"
                                  }, _toDisplayString(row.client_id), 9, _hoisted_17))
                            ]),
                            (_ctx.isLoadingAllocation || _ctx.checkMasked(row.lastName) || row.disableCompareModal)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_18, _toDisplayString(row.lastName), 1))
                              : (_openBlock(), _createElementBlock("td", _hoisted_19, [
                                  _createElementVNode("a", {
                                    href: "#",
                                    "data-toggle": "modal",
                                    "data-target": "#recipientDetailDemo",
                                    onClick: ($event: any) => (_ctx.openCompareModal(row))
                                  }, _toDisplayString(row.lastName), 9, _hoisted_20)
                                ])),
                            _createElementVNode("td", null, _toDisplayString(row.hospital_abbreviation), 1),
                            _createElementVNode("td", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.listed_for, (organ, idx) => {
                                return (_openBlock(), _createElementBlock("div", { key: idx }, [
                                  (_ctx.highlightOrgan(row.organ_code, row.cluster_organ_code, row.listed_for_codes[idx], row.listed_for_codes))
                                    ? (_openBlock(), _createElementBlock("strong", _hoisted_21, _toDisplayString(organ), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(organ), 1))
                                ]))
                              }), 128))
                            ]),
                            _createElementVNode("td", null, _toDisplayString(row.offerDateTime), 1),
                            _createElementVNode("td", null, _toDisplayString(row.offeredBy), 1),
                            (_ctx.showCombined)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                  (_ctx.sameTransplantProgram(row))
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        (!row.offerType)
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _cache[5] || (_cache[5] = _createElementVNode("td", null, "-", -1)),
                                              _cache[6] || (_cache[6] = _createElementVNode("td", null, "-", -1)),
                                              _cache[7] || (_cache[7] = _createElementVNode("td", null, "-", -1)),
                                              _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                              _cache[8] || (_cache[8] = _createElementVNode("td", null, "-", -1))
                                            ], 64))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              (_ctx.isNoOffer(row))
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _cache[9] || (_cache[9] = _createElementVNode("td", null, "-", -1)),
                                                    _cache[10] || (_cache[10] = _createElementVNode("td", null, "-", -1)),
                                                    _cache[11] || (_cache[11] = _createElementVNode("td", null, "-", -1)),
                                                    _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                                    _cache[12] || (_cache[12] = _createElementVNode("td", null, "-", -1))
                                                  ], 64))
                                                : _createCommentVNode("", true),
                                              (_ctx.isPrimaryOrBackup(row))
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                    _createElementVNode("td", {
                                                      class: _normalizeClass(_ctx.getCellStyle(row))
                                                    }, [
                                                      _createVNode(_component_select_input, {
                                                        "select-id": `offer-${idx}-response`,
                                                        class: "form-group",
                                                        name: _ctx.$t('response'),
                                                        value: row.responseCode,
                                                        "hide-label": true,
                                                        hide_restricted_permissions: _ctx.showCombined,
                                                        options: _ctx.responseOptions(_ctx.rowByIdx(idx), _ctx.offerResponses),
                                                        onChange: ($event: any) => {_ctx.updateRow($event, idx, 'responseCode'); _ctx.clearValues(idx, ['responseCategoryCode', 'responseReasonCode'])},
                                                        disabled: _ctx.isLoadingAllocation || _ctx.disableResponseOptions(_ctx.rowByIdx(idx)) || !_ctx.showControls || !row.selected
                                                      }, null, 8, ["select-id", "name", "value", "hide_restricted_permissions", "options", "onChange", "disabled"])
                                                    ], 2),
                                                    _createElementVNode("td", _hoisted_23, [
                                                      _createVNode(_component_select_input, {
                                                        "select-id": `offer-${idx}-reason_category`,
                                                        class: "form-group",
                                                        name: _ctx.$t('reason_category'),
                                                        value: row.responseCategoryCode,
                                                        "hide-label": true,
                                                        options: _ctx.reasonCategoryOptions(_ctx.rowByIdx(idx), _ctx.offerResponses, _ctx.organCode),
                                                        onChange: ($event: any) => (_ctx.updateRow($event, idx, 'responseCategoryCode')),
                                                        disabled: _ctx.isLoadingAllocation || _ctx.disableResponseCategoryOptions(_ctx.rowByIdx(idx)) || !_ctx.showControls || !row.selected
                                                      }, null, 8, ["select-id", "name", "value", "options", "onChange", "disabled"])
                                                    ]),
                                                    _createElementVNode("td", null, [
                                                      _createVNode(_component_select_input, {
                                                        "select-id": `offer-${idx}-reason`,
                                                        class: "form-group",
                                                        name: _ctx.$t('reason'),
                                                        value: row.responseReasonCode,
                                                        "hide-label": true,
                                                        options: _ctx.reasonOptions(_ctx.rowByIdx(idx), _ctx.offerResponses, _ctx.organCode),
                                                        onChange: ($event: any) => (_ctx.updateRow($event, idx, 'responseReasonCode')),
                                                        disabled: _ctx.isLoadingAllocation || !_ctx.editState.rows[idx].responseCategoryCode || !_ctx.showControls || !row.selected
                                                      }, null, 8, ["select-id", "name", "value", "options", "onChange", "disabled"])
                                                    ]),
                                                    _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                                    _createElementVNode("td", _hoisted_24, [
                                                      _createVNode(_component_select_input, {
                                                        "select-id": `offer-${idx}-responsible-physician`,
                                                        class: "form-group",
                                                        name: _ctx.$t('responsible_physician'),
                                                        "hide-label": true,
                                                        value: row.responsiblePhysician,
                                                        options: _ctx.sortedResponsiblePhysicianOptions(row.hospitalId, _ctx.organCode),
                                                        onChange: ($event: any) => (_ctx.updateRow($event, idx, 'responsiblePhysician')),
                                                        disabled: _ctx.isLoadingAllocation || row.outOfProvince || !_ctx.showControls || !row.selected
                                                      }, null, 8, ["select-id", "name", "value", "options", "onChange", "disabled"])
                                                    ])
                                                  ], 64))
                                                : _createCommentVNode("", true)
                                            ], 64))
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        (!row.offerType)
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _cache[13] || (_cache[13] = _createElementVNode("td", null, "-", -1)),
                                              _cache[14] || (_cache[14] = _createElementVNode("td", null, "-", -1)),
                                              _cache[15] || (_cache[15] = _createElementVNode("td", null, "-", -1)),
                                              _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                              _cache[16] || (_cache[16] = _createElementVNode("td", null, "-", -1))
                                            ], 64))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              (_ctx.isNoOffer(row))
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _cache[17] || (_cache[17] = _createElementVNode("td", null, "-", -1)),
                                                    _cache[18] || (_cache[18] = _createElementVNode("td", null, "-", -1)),
                                                    _cache[19] || (_cache[19] = _createElementVNode("td", null, "-", -1)),
                                                    _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                                    _cache[20] || (_cache[20] = _createElementVNode("td", null, "-", -1))
                                                  ], 64))
                                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                    _createElementVNode("td", null, _toDisplayString(_ctx.lookupValue(row.responseCode, 'offer_responses') || '-'), 1),
                                                    _createElementVNode("td", null, _toDisplayString(_ctx.lookupOptionValue(_ctx.reasonCategoryOptions(_ctx.rowByIdx(idx), _ctx.offerResponses, _ctx.organCode), row.responseCategoryCode)), 1),
                                                    _createElementVNode("td", null, _toDisplayString(_ctx.lookupOptionValue(_ctx.reasonOptions(_ctx.rowByIdx(idx), _ctx.offerResponses, _ctx.organCode), row.responseReasonCode)), 1),
                                                    _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                                    _createElementVNode("td", null, _toDisplayString(_ctx.lookupOptionValue(_ctx.responsiblePhysicianOptions(row.hospitalId, _ctx.organCode), row.responsiblePhysician)), 1)
                                                  ], 64))
                                            ], 64))
                                      ], 64))
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                  _createElementVNode("td", {
                                    class: _normalizeClass(_ctx.getCellStyle(row))
                                  }, [
                                    _createVNode(_component_select_input, {
                                      "select-id": `offer-${idx}-response`,
                                      class: "form-group",
                                      name: _ctx.$t('response'),
                                      value: row.responseCode,
                                      "hide-label": true,
                                      hide_restricted_permissions: _ctx.showCombined,
                                      options: _ctx.responseOptions(_ctx.rowByIdx(idx), _ctx.offerResponses),
                                      onChange: ($event: any) => {_ctx.updateRow($event, idx, 'responseCode'); _ctx.clearValues(idx, ['responseCategoryCode', 'responseReasonCode'])},
                                      disabled: _ctx.isLoadingAllocation || _ctx.disableResponseOptions(_ctx.rowByIdx(idx)) || !_ctx.showControls || !row.selected
                                    }, null, 8, ["select-id", "name", "value", "hide_restricted_permissions", "options", "onChange", "disabled"])
                                  ], 2),
                                  _createElementVNode("td", _hoisted_25, [
                                    _createVNode(_component_select_input, {
                                      "select-id": `offer-${idx}-reason_category`,
                                      class: "form-group",
                                      name: _ctx.$t('reason_category'),
                                      value: row.responseCategoryCode,
                                      "hide-label": true,
                                      options: _ctx.reasonCategoryOptions(_ctx.rowByIdx(idx), _ctx.offerResponses, _ctx.organCode),
                                      onChange: ($event: any) => (_ctx.updateRow($event, idx, 'responseCategoryCode')),
                                      disabled: _ctx.isLoadingAllocation || _ctx.disableResponseCategoryOptions(_ctx.rowByIdx(idx)) || !_ctx.showControls || !row.selected
                                    }, null, 8, ["select-id", "name", "value", "options", "onChange", "disabled"])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createVNode(_component_select_input, {
                                      "select-id": `offer-${idx}-reason`,
                                      class: "form-group",
                                      name: _ctx.$t('reason'),
                                      value: row.responseReasonCode,
                                      "hide-label": true,
                                      options: _ctx.reasonOptions(_ctx.rowByIdx(idx), _ctx.offerResponses, _ctx.organCode),
                                      onChange: ($event: any) => (_ctx.updateRow($event, idx, 'responseReasonCode')),
                                      disabled: _ctx.isLoadingAllocation || !_ctx.editState.rows[idx].responseCategoryCode || !_ctx.showControls || !row.selected
                                    }, null, 8, ["select-id", "name", "value", "options", "onChange", "disabled"])
                                  ]),
                                  _createElementVNode("td", null, _toDisplayString(row.responseDateTime), 1),
                                  _createElementVNode("td", _hoisted_26, [
                                    _createVNode(_component_select_input, {
                                      "select-id": `offer-${idx}-responsible-physician`,
                                      class: "form-group",
                                      name: _ctx.$t('responsible_physician'),
                                      "hide-label": true,
                                      value: row.responsiblePhysician,
                                      options: _ctx.sortedResponsiblePhysicianOptions(row.hospitalId, _ctx.organCode),
                                      onChange: ($event: any) => (_ctx.updateRow($event, idx, 'responsiblePhysician')),
                                      disabled: _ctx.isLoadingAllocation || row.outOfProvince || !_ctx.showControls || !row.selected
                                    }, null, 8, ["select-id", "name", "value", "options", "onChange", "disabled"])
                                  ])
                                ], 64)),
                            _createElementVNode("template", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (rowName) => {
                                return (_openBlock(), _createElementBlock(_Fragment, null, [
                                  (rowName === 'responseBy')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-responseBy`
                                      }, _toDisplayString(row.responseBy), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'medical_status')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-medical_status`
                                      }, _toDisplayString(row.medical_status), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'secondary_medical_status')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-secondary_medical_status`
                                      }, _toDisplayString(row.secondary_medical_status), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'hsp')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-hsp`
                                      }, _toDisplayString(row.hsp), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'hsh')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-hsh`,
                                        class: "text-center font-weight-bold"
                                      }, [
                                        _createElementVNode("em", null, _toDisplayString(row.hsh), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (rowName === 'allocation_points')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-allocation_points`
                                      }, _toDisplayString(row.allocation_points), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'mpe_score')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-mpe_score`
                                      }, _toDisplayString(row.mpe_score), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'abo')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-abo`
                                      }, _toDisplayString(row.abo), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'sex')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-sex`
                                      }, _toDisplayString(row.sex), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'age')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-age`
                                      }, _toDisplayString(row.age), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'height')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-height`
                                      }, _toDisplayString(!isNaN(row.height) ? row.height.toFixed(1) : '-'), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'weight')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-weight`
                                      }, _toDisplayString(!isNaN(row.weight) ? row.weight.toFixed(1) : '-'), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'cpra')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-cpra`
                                      }, _toDisplayString(row.cpra), 1))
                                    : _createCommentVNode("", true),
                                  (rowName === 'recipientStatus')
                                    ? (_openBlock(), _createElementBlock("td", {
                                        key: `${rowName}-recipientStatus`,
                                        class: "font-weight-bold"
                                      }, [
                                        (row.tip_donor_client_id)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                              _createElementVNode("mark", _hoisted_28, _toDisplayString(_ctx.$t('transplant_in_progress')), 1),
                                              (_ctx.isLoadingAllocation)
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _createTextVNode(" #" + _toDisplayString(row.tip_deceased_donor_id), 1)
                                                  ], 64))
                                                : (_openBlock(), _createBlock(_component_router_link, {
                                                    key: 1,
                                                    to: { name: 'edit-deceased-donor', params: { id: row.tip_donor_client_id} }
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(" #" + _toDisplayString(row.tip_deceased_donor_id), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["to"]))
                                            ]))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              _createTextVNode(_toDisplayString(row.recipientStatus), 1)
                                            ], 64))
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 256))
                            ])
                          ], 10, _hoisted_8))
                        }), 128))
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", _hoisted_6, [
                          _createElementVNode("th", _hoisted_7, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('select')), 1)
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (rowName, idx) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (rowName === 'offerType')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-offerType`,
                                    scope: "col",
                                    class: "text-center"
                                  }, _toDisplayString(_ctx.$t('offer_type')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'organ_spec_offered')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-organ_spec_offered`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('organ_spec_offered')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'rank')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-rank`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('rank')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'client_id')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-client_id`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('client_id')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'lastName')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-lastName`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('last_name')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'hospital_abbreviation')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-hospital_abbreviation`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('transplant_program')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'organ')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-organ`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('listed_for')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'offerDateTime')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-offerDateTime`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('offer_date_time')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'offeredBy')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-offeredBy`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('offered_by')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'responseCode')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-responseCode`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('response')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'responseCategoryCode')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: rowName || idx,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('response_category')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'responseReasonCode')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-responseReasonCode`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('response_reason')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'responseDateTime')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-responseDateTime`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('response_date_time')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'responsiblePhysician')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-responsiblePhysician`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('responsible_physician')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'responseBy')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-responseBy`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('response_by')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'medical_status')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-medical_status`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('medical_status')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'secondary_medical_status')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-secondary_medical_status`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('secondary_medical_status')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'hsp')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-hsp`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('hsp')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'hsh')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-hsh`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('hsh')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'allocation_points')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-allocation_points`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('allocation_points')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'mpe_score')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-mpe_score`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('mpe_score')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'abo')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-abo`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('abo')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'sex')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-sex`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('sex')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'age')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-age`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('age')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'height')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-height`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('height')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'weight')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-weight`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('weight')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'cpra')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-cpra`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('cpra')), 1))
                                : _createCommentVNode("", true),
                              (rowName === 'recipientStatus')
                                ? (_openBlock(), _createElementBlock("th", {
                                    key: `${rowName}-recipientStatus`,
                                    scope: "col"
                                  }, _toDisplayString(_ctx.$t('recipientStatus')), 1))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ])
                      ]),
                      (_ctx.showControls)
                        ? (_openBlock(), _createElementBlock("tfoot", _hoisted_29, [
                            _createElementVNode("tr", _hoisted_30, [
                              _createElementVNode("td", _hoisted_31, [
                                _createElementVNode("nav", _hoisted_32, [
                                  _createElementVNode("div", _hoisted_33, [
                                    _createElementVNode("button", {
                                      type: "button",
                                      "data-toggle": "modal",
                                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.respondOffers && _ctx.respondOffers(...args))),
                                      disabled: !_ctx.checkForSelectedRows || _ctx.isLoadingAllocation,
                                      class: _normalizeClass(`btn btn-wide btn-sm btn-success w-auto ${(!_ctx.checkForSelectedRows || _ctx.isLoadingAllocation) ? 'disabled' : ''}`)
                                    }, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('confirm_changes')) + " ", 1),
                                      (_ctx.isRespondingOffer)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_35, [
                                            _createVNode(_component_font_awesome_icon, {
                                              class: "fa-spin",
                                              icon: ['far', 'spinner-third']
                                            })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 10, _hoisted_34),
                                    _createElementVNode("button", {
                                      type: "button",
                                      "data-toggle": "modal",
                                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.declineOffers && _ctx.declineOffers(...args))),
                                      disabled: !_ctx.checkForSelectedRows || _ctx.isLoadingAllocation || _ctx.hasMultipleProgramsInSelection,
                                      class: _normalizeClass(`btn btn-wide btn-sm btn-dark w-auto ${(!_ctx.checkForSelectedRows || _ctx.isLoadingAllocation) ? 'disabled' : ''}`)
                                    }, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('decline_multiple')) + " ", 1),
                                      (_ctx.isDecliningMultiple)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_37, [
                                            _createVNode(_component_font_awesome_icon, {
                                              class: "fa-spin",
                                              icon: ['far', 'spinner-third']
                                            })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 10, _hoisted_36)
                                  ]),
                                  _createElementVNode("div", _hoisted_38, [
                                    _createVNode(_component_offer_timers)
                                  ])
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["values"])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "lookupsToLoad", "disabled"]))
}