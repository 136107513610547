import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "skeleton-box w-100",
  style: {"height":"2.5rem"}
}
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "list-counter checklist-form-elements" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "form-group col-md-9" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "mt-5 col-md-3" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = {
  key: 0,
  class: "hr-break"
}
const _hoisted_13 = { class: "modal-footer-body" }
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "offer-outcome-modal",
    ref: "offerOutcomeModal",
    modalClass: { 'modal-content': true, 'modal-warning': !_ctx.isHardStopError,'modal-danger': _ctx.isHardStopError },
    centered: true,
    closeButton: false
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('offer_outcome_title')) + " " + _toDisplayString(_ctx.editState ? `- ${_ctx.$t(_ctx.editState.actionId)}` : ''), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.ctrErrorsHeading), 1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$tc('ctr_error_integration_context', _ctx.numCtrErrors, { operationPerformed: _ctx.$t(_ctx.editState.actionId), count: _ctx.numCtrErrors })), 1)
            ]),
            _createElementVNode("pre", {
              class: "integration-context",
              innerHTML: _ctx.editState.concatenatedCtrErrors
            }, null, 8, _hoisted_4),
            _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("h4", null, _toDisplayString(_ctx.ctrWarningMessagesHeading), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.warningMessages, (warningMessage, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `${idx}-warning`
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass({ 'alert': true, 'alert-warning': !_ctx.isHardStopError,'alert-danger': _ctx.isHardStopError })
                }, [
                  _createElementVNode("span", null, _toDisplayString(warningMessage), 1)
                ], 2)
              ]))
            }), 128)),
            _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
            (_ctx.isConsequenceMessageApplicable)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.consequencesHeading), 1),
                  _createElementVNode("p", { innerHTML: _ctx.consequencesMessage }, null, 8, _hoisted_5),
                  _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("ol", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.instructionsTemplates, (instruction, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${idx}-instruction`
                  }, [
                    _createElementVNode("li", null, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", {
                            class: "instruction-workflow",
                            innerHTML: instruction
                          }, null, 8, _hoisted_9)
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_checkbox_input, {
                                "label-name": _ctx.$t('checkbox_label_name'),
                                "hide-label": true,
                                inputId: `${idx}-instruction-checkbox`,
                                modelValue: _ctx.editState.checkboxes[idx],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.editState.checkboxes[idx]) = $event),
                                label: _ctx.$t('checkbox_selected_label')
                              }, null, 8, ["label-name", "inputId", "modelValue", "onUpdate:modelValue", "label"])
                            ])
                          ])
                        ])
                      ])
                    ]),
                    (idx < (_ctx.numInstructionCheckboxes - 1))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ], 64))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-success",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dismiss && _ctx.dismiss(...args))),
          disabled: !_ctx.allCheckboxesSelected
        }, _toDisplayString(_ctx.$t('offer_outcome_confirmation')), 9, _hoisted_14)
      ])
    ]),
    _: 1
  }, 8, ["modalClass"]))
}