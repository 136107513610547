import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-inner" }
const _hoisted_2 = { class: "nav-top" }
const _hoisted_3 = { class: "brand" }
const _hoisted_4 = {
  key: 0,
  class: "logo"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "brand-title d-block d-md-none" }
const _hoisted_7 = { class: "application-name-prefix" }
const _hoisted_8 = { class: "application-name-text" }
const _hoisted_9 = { class: "brand-title d-none d-md-block" }
const _hoisted_10 = { class: "application-name-prefix" }
const _hoisted_11 = { class: "application-name-text" }
const _hoisted_12 = { class: "brand-title d-block d-md-none" }
const _hoisted_13 = { class: "application-name-text" }
const _hoisted_14 = { class: "brand-title d-none d-md-block" }
const _hoisted_15 = { class: "application-name-text" }
const _hoisted_16 = { class: "d-md-none mobile-user-menu" }
const _hoisted_17 = {
  key: 0,
  class: "search-nav",
  role: "navigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_user_menu = _resolveComponent("user-menu")!
  const _component_main_menu = _resolveComponent("main-menu")!

  return (_openBlock(), _createElementBlock("header", {
    ref: "site-header",
    class: _normalizeClass(["header", { menuOpen: _ctx.isActive }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: { name: 'index' },
          exact: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("h1", _hoisted_3, [
              (_ctx.getLogo)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: _ctx.getLogo,
                      alt: _ctx.$t('application_short_name')
                    }, null, 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.applicationNamePrefix)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("span", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.applicationNamePrefix), 1),
                      _cache[1] || (_cache[1] = _createTextVNode(" ")),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('application_short_name')), 1)
                    ]),
                    _createElementVNode("span", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.applicationNamePrefix), 1),
                      _cache[2] || (_cache[2] = _createTextVNode(" ")),
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('application_long_name')), 1)
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("span", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('application_short_name')), 1)
                    ]),
                    _createElementVNode("span", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('application_long_name')), 1)
                    ])
                  ], 64))
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            type: "submit",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSearch())),
            class: _normalizeClass([_ctx.show ? 'active': '', "btn btn-outline-primary shadow-none"])
          }, [
            (!_ctx.show)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: ['fas', 'search']
                }))
              : _createCommentVNode("", true),
            (_ctx.show)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  icon: ['fas', 'times']
                }))
              : _createCommentVNode("", true),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "toggle-search", -1))
          ], 2),
          _createVNode(_component_user_menu)
        ])
      ]),
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_17, [
            _createVNode(_component_user_menu, { class: "d-none d-md-block" })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_main_menu, { onToggle: _ctx.mainMenuClicked }, null, 8, ["onToggle"])
  ], 2))
}