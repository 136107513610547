import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "filter-wrapper" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "filter-dropmenu",
  "aria-labelledby": "dropdownMenuButton"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "filter-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_proto_filter_links = _resolveComponent("proto-filter-links")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("button", {
            class: "btn btn-outline-secondary btn-sm filterButton",
            type: "button",
            id: "dropdownMenuButton",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args)), ["prevent"])),
            "aria-label": _ctx.$t('toggle_accordian_section')
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" Filters ")),
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'caret-down'],
              class: "accordion-icon"
            })
          ], 8, _hoisted_2),
          (_ctx.displayFilterSection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args)), ["prevent"])),
                  class: "btn btn-sm closeDropdown"
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'times'],
                    class: "nav-caret",
                    "fixed-width": ""
                  })
                ]),
                _createVNode(_component_proto_filter_links, {
                  displayFilter: _ctx.displayFilter,
                  keywordSearch: _ctx.keywordSearch,
                  checklistFilter: _ctx.checklistFilter,
                  fieldID: _ctx.fieldID,
                  checklistStatusOptions: _ctx.checklistStatusOptions,
                  serologyFilter: _ctx.serologyFilter,
                  notesFilter: _ctx.notesFilter,
                  notesTagOptions: _ctx.notesTagOptions,
                  onPrepareFilter: _ctx.prepareFilter,
                  completedFilter: _ctx.completedFilter,
                  archivedFilter: _ctx.archivedFilter,
                  notesAuthorOptions: _ctx.notesAuthorOptions,
                  appliedFilters: _ctx.appliedFilters
                }, null, 8, ["displayFilter", "keywordSearch", "checklistFilter", "fieldID", "checklistStatusOptions", "serologyFilter", "notesFilter", "notesTagOptions", "onPrepareFilter", "completedFilter", "archivedFilter", "notesAuthorOptions", "appliedFilters"])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliedFilters, (filter) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (filter.name == 'filterTag')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (filter.value.data.length > 0)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(filter.value.data, (tag, index) => {
                          return (_openBlock(), _createElementBlock("span", {
                            class: "filterTag",
                            key: index
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.getFilterName(filter)) + ": " + _toDisplayString(tag.name) + " ", 1),
                            _createElementVNode("button", {
                              onClick: _withModifiers(($event: any) => (_ctx.removeFilter(filter, tag.code)), ["prevent"]),
                              class: "removeFilterButton btn"
                            }, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: ['fas', 'times'],
                                class: "nav-caret",
                                "fixed-width": ""
                              })
                            ], 8, _hoisted_4)
                          ]))
                        }), 128))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (filter.value.value == true)
                      ? (_openBlock(), _createElementBlock("span", {
                          class: "filterTag",
                          key: filter.key
                        }, [
                          _createTextVNode(_toDisplayString(_ctx.getFilterName(filter)) + " ", 1),
                          _createElementVNode("button", {
                            onClick: _withModifiers(($event: any) => (_ctx.removeFilter(filter)), ["prevent"]),
                            class: "removeFilterButton btn"
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['fas', 'times'],
                              class: "nav-caret",
                              "fixed-width": ""
                            })
                          ], 8, _hoisted_5)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
            ], 64))
          }), 256))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_proto_filter_links, {
            displayFilter: _ctx.displayFilter,
            keywordSearch: _ctx.keywordSearch,
            checklistFilter: _ctx.checklistFilter,
            fieldID: _ctx.fieldID,
            checklistStatusOptions: _ctx.checklistStatusOptions,
            notesFilter: _ctx.notesFilter,
            serologyFilter: _ctx.serologyFilter,
            notesTagOptions: _ctx.notesTagOptions,
            notesAuthorOptions: _ctx.notesAuthorOptions,
            onPrepareFilter: _ctx.prepareFilter,
            completedFilter: _ctx.completedFilter,
            archivedFilter: _ctx.archivedFilter,
            appliedFilters: _ctx.appliedFilters,
            hideSpacer: _ctx.hideSpacer
          }, null, 8, ["displayFilter", "keywordSearch", "checklistFilter", "fieldID", "checklistStatusOptions", "notesFilter", "serologyFilter", "notesTagOptions", "notesAuthorOptions", "onPrepareFilter", "completedFilter", "archivedFilter", "appliedFilters", "hideSpacer"])
        ]))
  ]))
}