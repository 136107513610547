import { ObjectId } from '@/store/types';
import {ApplicationLoadingTracker} from "@/types";
export const HOSPITAL_OUTSIDE_ONTARIO = '-';
export const ACTIVE_REGION_TRANSPLANT_PROGRAM = 'region_transplant';
export const ACTIVE_OOP_TRANSPLANT_PROGRAM = 'oop_transplant';


export interface HospitalsState {
  loadingTracker: ApplicationLoadingTracker;
  all?: Hospital[];
  oop?: Hospital[];
  oop_transplant?: Hospital[];
  region_transplant?: Hospital[];
  transplantProgram: Hospital[];
}

export interface Hospital {
  _id: string;
  client_id: number;
  hospital_code: number;
  hospital_name_info: HospitalNameInfo;
  phone_number: string;
  organs_transplanted: HospitalOrgansTransplanted[];
  lhin_program_code: number;
  active_ontario_transplant_program: boolean;
  active_oop_transplant_program: boolean;
  location: HospitalLocation;
  program_identifier: string;
  donor_acceptability_defaults: DonorAcceptabilityDefaults[];
  updated_by?: string;
  dialysis?: boolean;
}

export interface HospitalNameInfo {
  name: string;
  abbreviation: string;
  short_name: string;
}

export interface HospitalOrgansTransplanted {
  organ_code: string;
  organ_description: string;
  expiry_date: string;
}

export interface HospitalLocation {
  country_code: string;
  province_code: string;
  city: string;
}

export interface DonorAcceptabilityDefaults {
  organ_code: string;
  hcv_ab_positive: boolean|null;
  hcv_nat_positive: boolean|null;
  a2_or_a2b_donor?: boolean|null;
  abo_incompatible?: boolean|null;
  ecd_donor?: boolean|null;
  hep_ab_core_positive?: boolean|null;
  display_in_ui?: DonorAcceptabilityCriteria;
}

export interface DonorAcceptabilityCriteria {
  hcv_ab_positive?: boolean|null;
  hcv_nat_positive?: boolean|null;
  a2_or_a2b_donor?: boolean|null;
  abo_incompatible?: boolean|null;
  ecd_donor?: boolean|null;
  hep_ab_core_positive?: boolean|null;
}

export interface HospitalOption {
  code: string;
  value: string;
  abbr?: string;
  organs_transplanted?: HospitalOrgansTransplanted[];
}

export const HospitalsState: HospitalsState = { transplantProgram: [], loadingTracker: {} };

export enum HospitalProgramIdentifierValue {
  torontoGeneral = 'TGH',
  sickKids = 'HSC',
  londonHealthSciences = 'LHSC',
  ottawaHeartInstitute = 'OHI',
}

export const HOSPITAL_CODE_OTHER = 'OTHER';

export enum Province {
  ontario = 'ON',
  quebec = 'QC'
}
