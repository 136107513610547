import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = { class: "standard-form-group" }
const _hoisted_20 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoHeartCardiacCatheterizationEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "proto-heart-cardiac-catheterization",
        title: "LEFT CARDIAC CATHETERIZATION"
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_form_layout, { "form-id": "cardiac-catheter-form" }, {
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_date_input, {
                    inputId: "left_cardiac_test_date",
                    name: "Test Date",
                    modelValue: _ctx.editState.left_cardiac_test_date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.left_cardiac_test_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_select_input, {
                    "select-id": "disease",
                    name: "Disease Severity",
                    options: _ctx.diseaseOptions,
                    modelValue: _ctx.editState.left_cardiac_disease_severity,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.left_cardiac_disease_severity) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_select_input, {
                    "select-id": "affected-vessels",
                    name: "Number of Vessels Affected",
                    options: _ctx.vesselsAffectedOptions,
                    modelValue: _ctx.editState.left_cardiac_vessels_affected,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.left_cardiac_vessels_affected) = $event))
                  }, null, 8, ["options", "modelValue"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_form_layout, { "form-id": "HemodynamicInformation-form" }, {
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                (_ctx.isNew)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_5, " RIGHT CARDIAC CATHETERIZATION "))
                  : _createCommentVNode("", true)
              ])
            ]),
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_date_input, {
                    inputId: "right_cardiac_test_date",
                    name: "Test Date",
                    modelValue: _ctx.editState.right_cardiac_test_date,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.right_cardiac_test_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_select_input, {
                    "select-id": "test-type",
                    name: "Type",
                    options: _ctx.testTypeOptions,
                    modelValue: _ctx.editState.right_cardiac_test_type,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.right_cardiac_test_type) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_checkbox_input, {
                    name: "continuous-monitoring",
                    labelName: "Continuous Monitoring",
                    inputId: "continuous_monitoring",
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.switchContinuousMonitoring($event))),
                    modelValue: _ctx.editState.right_cardiac_use_continuous_monitoring,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.right_cardiac_use_continuous_monitoring) = $event)),
                    label: "Yes"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_date_input, {
                    inputId: "right_cardiac_continuous_monitoring_startdate",
                    name: "Continuous Monitoring Start Date",
                    disabled: !_ctx.editState.right_cardiac_use_continuous_monitoring,
                    modelValue: _ctx.editState.right_cardiac_continuous_monitoring_startdate,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.right_cardiac_continuous_monitoring_startdate) = $event))
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_systolic_pa_pressure",
                    name: "Systolic PA Pressure",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_systolic_pa_pressure,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.right_cardiac_systolic_pa_pressure) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_diastolic_pa_pressure",
                    name: "Diastolic PA Pressure",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_diastolic_pa_pressure,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.right_cardiac_diastolic_pa_pressure) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_mean_pa_pressure",
                    name: "Mean PA Pressure",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_mean_pa_pressure,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.right_cardiac_mean_pa_pressure) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_pcw_pressure",
                    name: "Pulmonary Capillary Wedge (PCW) Pressure",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_pcw_pressure,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.right_cardiac_pcw_pressure) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_transpulmonary_gradient",
                    name: "Transpulmonary Gradient",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_transpulmonary_gradient,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.right_cardiac_transpulmonary_gradient) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_pvr_woods",
                    name: "PVR Woods",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_pvr_woods,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.right_cardiac_pvr_woods) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_select_input, {
                    "select-id": "right_cardiac_pvr_reactivity",
                    name: "PVR Reactivity",
                    options: _ctx.pvrReactivityOptions,
                    modelValue: _ctx.editState.right_cardiac_pvr_reactivity,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.right_cardiac_pvr_reactivity) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_output",
                    name: "Cardiac Output",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_output,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.right_cardiac_output) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_number_input, {
                    inputId: "right_cardiac_index",
                    name: "Cardiac Index",
                    step: "0.1",
                    modelValue: _ctx.editState.right_cardiac_index,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.right_cardiac_index) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_checkbox_input, {
                    name: "thermal-dilution",
                    labelName: "FICK or Thermal Dilution",
                    inputId: "right_cardiac_thermal_dilution",
                    modelValue: _ctx.editState.right_cardiac_thermal_dilution,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.right_cardiac_thermal_dilution) = $event)),
                    label: "Yes"
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveCardiacCatheterization",
                label: "Save Cardiac Catheterization",
                cancelButton: true,
                onSave: _cache[18] || (_cache[18] = ($event: any) => (_ctx.performSave())),
                onCancel: _cache[19] || (_cache[19] = ($event: any) => (_ctx.clearForm()))
              }, null, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}