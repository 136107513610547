import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "vertical-direction-navigation d-flex" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "has-guiding-text" }
const _hoisted_6 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_7 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_8 = { class: "row mb-2" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group-large" }
const _hoisted_12 = { class: "mb-4" }
const _hoisted_13 = { class: "legend-title" }
const _hoisted_14 = {
  key: 0,
  class: "asterisk"
}
const _hoisted_15 = {
  key: 0,
  class: "asterisk"
}
const _hoisted_16 = {
  key: 0,
  class: "asterisk"
}
const _hoisted_17 = {
  key: 0,
  class: "asterisk"
}
const _hoisted_18 = {
  key: 0,
  class: "asterisk"
}
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_fields = _resolveComponent("loading-fields")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_vertical_table_list = _resolveComponent("vertical-table-list")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_AutoCompleteInput = _resolveComponent("AutoCompleteInput")!
  const _component_Column = _resolveComponent("Column")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "recipient-serology",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('title')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('subtitle'),
        "sub-section-id": "record-recipient-serology"
      }, {
        contents: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_loading_fields, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("button", {
                    type: "button",
                    "data-dismiss": "modal",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollLeft && _ctx.scrollLeft(...args))),
                    disabled: !_ctx.hasMultipleSerologyRecords || _ctx.navigationButton == 'left' || !_ctx.scrollBarVisible,
                    class: "btn btn-sm btn-secondary mr-2 ml-auto mb-2"
                  }, _toDisplayString(_ctx.$t('left')), 9, _hoisted_3),
                  _createElementVNode("button", {
                    type: "button",
                    "data-dismiss": "modal",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollRight && _ctx.scrollRight(...args))),
                    disabled: !_ctx.hasMultipleSerologyRecords || _ctx.navigationButton == 'right' || !_ctx.scrollBarVisible,
                    class: "btn btn-sm btn-secondary mb-2"
                  }, _toDisplayString(_ctx.$t('right')), 9, _hoisted_4)
                ]),
                _createVNode(_component_table_toolbar, {
                  createButton: _ctx.uiRecipient.canSave && !_ctx.newRecipient,
                  createText: _ctx.$t('create'),
                  onTableCreateRow: _ctx.handleTableCreateRow
                }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
                _createVNode(_component_vertical_table_list, {
                  ref: "patientSerologyTable",
                  showTopScrollBar: true,
                  "table-id": "patient-serology-table",
                  "table-config": _ctx.patientSerologyTableConfig,
                  onTableColClick: _ctx.handleTableColClick,
                  highlightSelection: true,
                  horizontalScroll: true,
                  listingView: "false",
                  onUpdateScrollButton: _ctx.setScrollValue,
                  onColumnResizeEnd: _ctx.checkWidth
                }, null, 8, ["table-config", "onTableColClick", "onUpdateScrollButton", "onColumnResizeEnd"]),
                _createVNode(_component_validation_observer, { ref: "validations" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_form_layout, {
                      disabled: !_ctx.enableForm,
                      "form-id": "serology_form"
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("legend", _hoisted_5, [
                          (!_ctx.selection?.isNew)
                            ? (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_ctx.$t('selected_result')), 1))
                            : (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.$t('new_result')), 1)),
                          _createElementVNode("small", null, _toDisplayString(_ctx.$t('guiding_text')), 1)
                        ])
                      ]),
                      contents: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_date_input, {
                              ruleKey: "recipient_virology.sample_datetime",
                              inputId: "collection_date",
                              name: _ctx.$t('collection_date'),
                              modelValue: _ctx.editState.collection_date,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.collection_date) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_time_input, {
                              ruleKey: "recipient_virology.sample_datetime",
                              inputId: "collection_time",
                              name: _ctx.currentConfiguration.replaceTimezoneAbbreviation(_ctx.$t('collection_time')),
                              modelValue: _ctx.editState.collection_time,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.collection_time) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_text_area_input, {
                              ruleKey: "recipient_virology.sample_comment",
                              inputId: "collection_comments",
                              name: _ctx.$t('collection_comments'),
                              modelValue: _ctx.editState.collection_comments,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.collection_comments) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ]),
                        _createVNode(_component_sub_section, { "sub-section-id": "serology-markers" }, {
                          contents: _withCtx(() => [
                            _createElementVNode("legend", _hoisted_12, [
                              _createElementVNode("h5", _hoisted_13, _toDisplayString(_ctx.$t('results')), 1)
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.isMobile ? 'spaced-form-items' : '')
                            }, [
                              _createVNode(_component_DataTable, {
                                value: _ctx.editState.markers,
                                ref: "markerTable"
                              }, {
                                footer: _withCtx(() => [
                                  (_ctx.enableForm)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        ref: "markerAdd",
                                        type: "button",
                                        class: "btn btn-sm btn-primary-outline",
                                        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.markerAdd && _ctx.markerAdd(...args)))
                                      }, [
                                        _renderSlot(_ctx.$slots, "label", {}, () => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('add')), 1)
                                        ])
                                      ], 512))
                                    : _createCommentVNode("", true)
                                ]),
                                empty: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: ['far', 'exclamation-circle'],
                                    "fixed-width": ""
                                  }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('guiding_text')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_Column, { field: "test" }, {
                                    header: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('test')) + " ", 1),
                                      (_ctx.requiredMarkerArray['test'])
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, _cache[9] || (_cache[9] = [
                                            _createElementVNode("i", null, " *", -1)
                                          ])))
                                        : _createCommentVNode("", true)
                                    ]),
                                    body: _withCtx((slotProps) => [
                                      _createVNode(_component_AutoCompleteInput, {
                                        ruleKey: "recipient_virology.results.code",
                                        name: _ctx.$t('test'),
                                        options: _ctx.getFilteredVirologyCodesCombined,
                                        modelValue: slotProps.data.test,
                                        "onUpdate:modelValue": ($event: any) => ((slotProps.data.test) = $event),
                                        disabled: !_ctx.enableForm,
                                        inputId: `serology.markers.${slotProps.data.id}.test`,
                                        hideLabel: !_ctx.isMobile,
                                        showDropdown: true,
                                        placeholder: _ctx.$t('select'),
                                        hide_restricted_permissions: true
                                      }, null, 8, ["name", "options", "modelValue", "onUpdate:modelValue", "disabled", "inputId", "hideLabel", "placeholder"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_Column, { field: "result" }, {
                                    header: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('result')) + " ", 1),
                                      (_ctx.requiredMarkerArray['result'])
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _cache[10] || (_cache[10] = [
                                            _createElementVNode("i", null, " *", -1)
                                          ])))
                                        : _createCommentVNode("", true)
                                    ]),
                                    body: _withCtx((slotProps) => [
                                      _createVNode(_component_SelectInput, {
                                        ruleKey: "recipient_virology.results.result",
                                        selectId: `serology.markers.${slotProps.data.id}.result`,
                                        name: _ctx.$t('result'),
                                        hideLabel: !_ctx.isMobile,
                                        disabled: !_ctx.enableForm,
                                        modelValue: slotProps.data.result,
                                        "onUpdate:modelValue": ($event: any) => ((slotProps.data.result) = $event),
                                        options: _ctx.virologyResultsCombined
                                      }, null, 8, ["selectId", "name", "hideLabel", "disabled", "modelValue", "onUpdate:modelValue", "options"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_Column, { field: "comments" }, {
                                    header: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('result_comments')) + " ", 1),
                                      (_ctx.requiredMarkerArray['result_comments'])
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _cache[11] || (_cache[11] = [
                                            _createElementVNode("i", null, " *", -1)
                                          ])))
                                        : _createCommentVNode("", true)
                                    ]),
                                    body: _withCtx((slotProps) => [
                                      _createVNode(_component_text_input, {
                                        ruleKey: "recipient_virology.results.comments",
                                        inputId: `serology.markers.${slotProps.data.id}.comments`,
                                        name: _ctx.$t('result_comments'),
                                        hideLabel: !_ctx.isMobile,
                                        disabled: !_ctx.enableForm,
                                        modelValue: slotProps.data.comments,
                                        "onUpdate:modelValue": ($event: any) => ((slotProps.data.comments) = $event)
                                      }, null, 8, ["inputId", "name", "hideLabel", "disabled", "modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_Column, { field: "date" }, {
                                    header: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('result_date')) + " ", 1),
                                      (_ctx.requiredMarkerArray['result_date'])
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _cache[12] || (_cache[12] = [
                                            _createElementVNode("i", null, " *", -1)
                                          ])))
                                        : _createCommentVNode("", true)
                                    ]),
                                    body: _withCtx((slotProps) => [
                                      _createVNode(_component_date_input, {
                                        ruleKey: "recipient_virology.results.result_received_datetime",
                                        inputId: `serology.markers.${slotProps.data.id}.date`,
                                        name: _ctx.$t('result_date'),
                                        hideLabel: !_ctx.isMobile,
                                        disabled: !_ctx.enableForm,
                                        modelValue: slotProps.data.date,
                                        "onUpdate:modelValue": ($event: any) => ((slotProps.data.date) = $event)
                                      }, null, 8, ["inputId", "name", "hideLabel", "disabled", "modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_Column, { field: "time" }, {
                                    header: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.currentConfiguration.replaceTimezoneAbbreviation(_ctx.$t('result_time'))) + " ", 1),
                                      (_ctx.requiredMarkerArray['result_time'])
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _cache[13] || (_cache[13] = [
                                            _createElementVNode("i", null, " *", -1)
                                          ])))
                                        : _createCommentVNode("", true)
                                    ]),
                                    body: _withCtx((slotProps) => [
                                      _createVNode(_component_time_input, {
                                        ruleKey: "recipient_virology.results.result_received_datetime",
                                        inputId: `serology.markers.${slotProps.data.id}.time`,
                                        name: _ctx.currentConfiguration.replaceTimezoneAbbreviation(_ctx.$t('result_time')),
                                        hideLabel: !_ctx.isMobile,
                                        disabled: !_ctx.enableForm,
                                        modelValue: slotProps.data.time,
                                        "onUpdate:modelValue": ($event: any) => ((slotProps.data.time) = $event)
                                      }, null, 8, ["inputId", "name", "hideLabel", "disabled", "modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_Column, {
                                    field: "apiSource",
                                    header: "Actions"
                                  }, {
                                    body: _withCtx((slotProps) => [
                                      (_ctx.enableForm)
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            ref: "markerRemove",
                                            type: "button",
                                            class: "btn btn-sm btn-danger-outline removebutton",
                                            onClick: ($event: any) => (_ctx.markerRemove(slotProps.data.id))
                                          }, [
                                            _renderSlot(_ctx.$slots, "label", {}, () => [
                                              _createTextVNode(_toDisplayString(_ctx.$t('remove')), 1)
                                            ])
                                          ], 8, _hoisted_19))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 3
                                  })
                                ]),
                                _: 3
                              }, 8, ["value"]),
                              _createVNode(_component_error_message, { inputId: "marker_results_error" })
                            ], 2)
                          ]),
                          _: 3
                        })
                      ]),
                      save: _withCtx(() => [
                        _createVNode(_component_save_toolbar, {
                          show: _ctx.showSaveToolbar,
                          ref: "saveSerology",
                          class: "card-footer action-row temp-saving row",
                          label: _ctx.$t('save_result'),
                          cancelButton: true,
                          onSave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleSave())),
                          onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleCancel()))
                        }, null, 8, ["show", "label"])
                      ]),
                      _: 3
                    }, 8, ["disabled"])
                  ]),
                  _: 3
                }, 512)
              ]))
        ]),
        _: 3
      }, 8, ["title"])
    ]),
    _: 3
  }, 8, ["lookupsToLoad"]))
}