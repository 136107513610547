<template>
  <card-section
    section-id="contactinfo"
    :disabled="!uiRecipient.canSave"
    @loaded="handleLoaded"
    :lookupsToLoad="lookupsToLoad"
    :showLoading="isLoading"
  >
    <template v-slot:header>
      {{$t('contact_information')}}
    </template>
    <template v-slot:body>
      <patient-contact-details
        v-if="isPatientContactDetailsEnabled"
        ref="patientContactDetails"
        @errors="handleErrors"
        @save="handleSave"
        @clear="handleClear"
        :newRecipient="newRecipient"
      />
      <patient-address
        v-if="isPatientAddressEnabled"
        ref="patientAddress"
        @errors="handleErrors"
        @save="handleSave"
        @clear="handleClear"
        :newRecipient="newRecipient"
      />
    </template>
  </card-section>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { SaveResult } from '@/types';
import PatientContactDetails from '@/components/recipients/contactInformation/PatientContactDetails.vue';
import PatientAddress from '@/components/recipients/contactInformation/PatientAddress.vue';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from '@/UIModels/recipient';

@Component({
  components: {
    CardSection,
    PatientContactDetails,
    PatientAddress,
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/ContactInformation.json'),
  ]),
  emits: [
    'loaded',
    'save',
    'clear',
    'handleErrors',
  ],
})
export default class ContactInformation extends Vue {
  // Properties
  @Prop({ default: false }) newRecipient!: boolean;

  // Which lookups do we need?
  private lookupsToLoad = ['country', 'address_categories', 'contact_detail_categories'];
  private isLoading = true;

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  get isPatientContactDetailsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.contactInfo.contactDetails.enabled;
  }

  get isPatientAddressEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.contactInfo.addresses.enabled;
  }

  // Reference to Patient Contact Details component
  get patientContactDetailsComponent(): PatientContactDetails|null {
    return this.$refs.patientContactDetails as PatientContactDetails || null;
  }

  // Reference to Patient Address component
  get patientAddressComponent(): PatientAddress|null {
    return this.$refs.patientAddress as PatientAddress || null;
  }

  // Triggered when all the lookups have been loaded
  private handleLoaded(): void {
    this.isLoading = false;
    this.$emit('loaded', 'contactInfo');
  }

  // Emit event to parent so it can handle clearing validations when saving
  private handleSave(sectionName: string) {
    this.$emit('save', sectionName);
  }

  // Bubble up clear event
  private handleClear() {
    this.$emit('clear');
  }

  // Bubble up error event
  private handleErrors(error: SaveResult): void {
    this.$emit('handleErrors', error);
  }
}
</script>
