<template>
  <div v-if="isProtoLDContactInformationEnabled">
    <card-section
      section-id="proto_living_donor_contact_information"
      :disabled="!canSave"
      @loaded="handleLoaded"
      :lookupsToLoad="lookupsToLoad"
      :showLoading="isLoading"
    >
      <template v-slot:header>
        {{$t('contact_information')}}
      </template>
      <template v-slot:body>
        <patient-contact-details
          v-if="isProtoLDPatientContactDetailsEnabled"
          ref="protoLDPatientContactDetails"
          @errors="handleErrors"
          @save="handleSave"
          @clear="handleClear"
          :newRecipient="newRecipient"
          :canSave="canSave"
        />
        <patient-address
          v-if="isProtoLDPatientAddressEnabled"
          ref="protoLDPatientAddress"
          @errors="handleErrors"
          @save="handleSave"
          @clear="handleClear"
          :newRecipient="newRecipient"
          :canSave="canSave"
        />
      </template>
    </card-section>
  </div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { SaveResult } from '@/types';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import PatientContactDetails from '@/prototypes/livingDonor/contactInformation/ProtoLDContactDetails.vue';
import PatientAddress from '@/prototypes/livingDonor/contactInformation/ProtoLDAddress.vue';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    CardSection,
    PatientContactDetails,
    PatientAddress,
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/prototypes/livingDonor/contactInformation/_locales/ProtoLDContactInformation.json'),
  ]),
  emits: [
    'loaded',
    'save',
    'clear',
    'handleErrors',
  ],
})
export default class ContactInformation extends Vue {
  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Which lookups do we need?
  private lookupsToLoad = ['country', 'address_categories', 'contact_detail_categories'];
  private isLoading = true;


  get isProtoLDContactInformationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoLDContactInformation);
  }

  get isProtoLDPatientContactDetailsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoLDPatientContactDetails);
  }


  get isProtoLDPatientAddressEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoLDPatientAddress);
  }


  // Reference to Patient Contact Details component
  get protoLDPatientContactDetailsComponent(): PatientContactDetails|null {
    return this.$refs.protoLDPatientContactDetails as PatientContactDetails || null;
  }

  // Reference to Patient Address component
  get protoLDPatientAddressComponent(): PatientAddress|null {
    return this.$refs.protoLDPatientAddress as PatientAddress || null;
  }

  // Triggered when all the lookups have been loaded
  private handleLoaded(): void {
    this.isLoading = false;
    this.$emit('loaded', 'contactInfo');
  }

  // Emit event to parent so it can handle clearing validations when saving
  private handleSave(sectionName: string) {
    this.$emit('save', sectionName);
  }

  // Bubble up clear event
  private handleClear() {
    this.$emit('clear');
  }

  // Bubble up error event
  private handleErrors(error: SaveResult): void {
    this.$emit('handleErrors', error);
  }
}
</script>
