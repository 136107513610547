<template>
  <!-- blood type -->
  <sub-section
    title="BLOOD TYPE"
    sub-section-id="gci_blood_type"
    ref="blood_type">
    <template v-slot:contents>
      <validation-observer ref="validations">
      <form-layout
        :disabled="!canSave"
        form-id="blood_type_form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                ruleKey="diagnostics.blood.type"
                selectId="gci-blood.type"
                :name="$t('blood_type')"
                v-model="editState.type"
                :options="bloodTypeLookup"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                selectId="gci-blood.sub-type"
                name="Subtype"
                v-model="editState.sub_type"
                :options="bloodSubtypeOptions"
              />
            </div>

            
            <div class="standard-form-group">
              <select-input
                ruleKey="diagnostics.blood.rh_indicator"
                selectId="gci-blood.rh"
                :name="$t('rh_factor')"
                v-model="editState.rh_indicator"
                :options="rhIndicatorLookup"
              />
            </div>
            <div class="standard-form-group">
              <number-input
                inputId="cpra"
                label="cPRA"
                name="cpra"
                v-model="editState.cpra"
              />
            </div>
          </div>
        </template>

        <template v-slot:save>
          <save-toolbar
            :show="canSave"
            ref="saveBloodType"
            class="card-footer action-row temp-saving row"
            :label="$t('save_blood_type')"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>      
      </form-layout>      
      </validation-observer>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { SaveResult } from "@/types";
import { Component, Prop } from 'vue-facing-decorator';
import { BloodType, RhIndicator } from '@/store/lookups/types';
import { RecipientValidations } from '@/store/recipients/types';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIError } from '@/UIModels/error';
import { IdLookupProvider } from '@/types';
import { UIBloodType } from '@/UIModels/recipients/bloodType';
import { UIRecipient } from '@/UIModels/recipient';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';

const EDITSTATE_KEY = 'editState';

interface BloodTypeForm {
  type: string,
  sub_type: string,
  rh_indicator: string,
  cpra: number | null
}


@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput,
    FormLayout
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
})
export default class ProtoBloodTypeForm extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;

  private title: any = "Blood Type";
    // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  @Getter('blood_type', { namespace: 'lookups' }) private bloodTypeLookup!: BloodType;
  @Getter('rh_indicator', { namespace: 'lookups' }) private rhIndicatorLookup!: RhIndicator;

private editState: BloodTypeForm = {
  type: "",
  sub_type: "",
  rh_indicator: "",
  cpra: null,
};

// Create a prototype bucket to store arbitrary key/value data
private protoBucket!: UIPrototypeBucket;

private bloodSubtypeOptions = [{
    code: 0,
    value: 'A1'
    }, {
    code: 1,
    value: 'A2'
    },
    {
    code: 2,
    value: 'A1B'
    },
    {
    code: 3,
    value: 'A2B'
    }
  ];

get isProtoPaceAndEngagementEnabled(): boolean {
  return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ShowBloodType);
}

// Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveBloodType as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.ShowBloodType, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: BloodTypeForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.editState = {
    type: response.type,
    sub_type: response.sub_type,
    rh_indicator: response.rh_indicator,
    cpra: response.cpra
  };
}

  public performSave(): any {
    // Call the 'startSaving' method to begin save animation
    if (this.saveToolbar) this.saveToolbar.startSaving();

    const payload: BloodTypeForm = {
      type: this.editState.type,
      sub_type: this.editState.sub_type,
      rh_indicator: this.editState.rh_indicator,
      cpra: this.editState.cpra,
    };

    this.protoBucket.setItem(EDITSTATE_KEY, payload);

    // Call the 'showFakeSuccess' method to complete successful save animation
    if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  } 

  public clearForm(): any {
    this.editState.type = '';
    this.editState.sub_type = '';
    this.editState.rh_indicator = '';    
    this.editState.cpra = null;
  }
}

</script>

<style>


</style>
