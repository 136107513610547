import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoLivingDonationEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_kidney_living_donation" }, {
          header: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" Living Donation Information ")
          ])),
          body: _withCtx(() => [
            _createElementVNode("form", null, [
              _createVNode(_component_sub_section, { "sub-section-id": "proto-Kidney-information-section" }, {
                contents: _withCtx(() => [
                  _createVNode(_component_form_layout, { "form-id": "livingDonation-form" }, {
                    contents: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_checkbox_input, {
                            name: "directed-ld",
                            labelName: "Directed LD?",
                            inputId: "directed-ld",
                            modelValue: _ctx.editState.directed_ld,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.directed_ld) = $event)),
                            label: "Yes"
                          }, null, 8, ["modelValue"])
                        ]),
                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "standard-form-group" }, null, -1)),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_date_input, {
                            inputId: "ld-surgery-date",
                            name: "LD Surgery Date",
                            modelValue: _ctx.editState.ld_surgery_date,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.ld_surgery_date) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_checkbox_input, {
                            name: "list-exchange",
                            labelName: "List Exchange?",
                            inputId: "list-exchange",
                            modelValue: _ctx.editState.list_exchange,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.list_exchange) = $event)),
                            label: "Yes"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_date_input, {
                            inputId: "list-exchange-registration-date",
                            name: "List Exchange Registration Date",
                            modelValue: _ctx.editState.list_exchange_registration_date,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.list_exchange_registration_date) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_date_input, {
                            inputId: "list-exchange-surgery-date",
                            name: "List Exchange Surgery Date",
                            modelValue: _ctx.editState.list_exchange_surgery_date,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.list_exchange_surgery_date) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_date_input, {
                            inputId: "list-exchange-removal-date",
                            name: "List Exchange Removal Date",
                            modelValue: _ctx.editState.list_exchange_removal_date,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.list_exchange_removal_date) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_checkbox_input, {
                            name: "kpd",
                            labelName: "KPD?",
                            inputId: "kpd",
                            modelValue: _ctx.editState.kpd,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.kpd) = $event)),
                            label: "Yes"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_date_input, {
                            inputId: "kpd-registration-date",
                            name: "KPD Registration Date",
                            modelValue: _ctx.editState.kpd_registration_date,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.kpd_registration_date) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_date_input, {
                            inputId: "kpd-surgery-date",
                            name: "KPD Surgery Date",
                            modelValue: _ctx.editState.list_exchange_surgery_date,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.list_exchange_surgery_date) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_date_input, {
                            inputId: "kpd-removal-date",
                            name: "KPD Removal Date",
                            modelValue: _ctx.editState.kpd_removal_date,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.kpd_removal_date) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_text_area_input, {
                            "input-id": "living-donations-comments",
                            name: "Comments",
                            rows: "4",
                            modelValue: _ctx.editState.comments,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.comments) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]),
                    save: _withCtx(() => [
                      _createVNode(_component_save_toolbar, {
                        show: true,
                        ref: "saveLivingDonationInformation",
                        label: "Save Living Donation Details",
                        cancelButton: true,
                        onSave: _cache[11] || (_cache[11] = ($event: any) => (_ctx.performSave())),
                        onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clearForm()))
                      }, null, 512)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}