import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "card full" }, [
      _createElementVNode("div", { class: "card-header media" }, [
        _createElementVNode("span", {
          class: "skeleton-box w-100",
          style: {"height":"30px"}
        })
      ])
    ], -1)
  ])))
}