import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Prioritization Report ")
      ])),
      _: 1
    }),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"content-wrap\"><div class=\"container-fluid\"><div class=\"row\"><div class=\"col-sm-12\"><h3 class=\"page-title mb-2\"> Heading </h3><p class=\"mb-3 text-dark\"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae urna turpis. In ac sapien eget dolor vehicula dictum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Quisque interdum sagittis finibus. In odio nulla, volutpat quis luctus sed, placerat sed nibh. Pellentesque maximus turpis nec dolor gravida, at volutpat ex volutpat. Mauris ex sem, sagittis eu bibendum at, venenatis sit amet metus. Pellentesque quis dictum urna. Integer volutpat augue vel finibus interdum. Vestibulum vel sem pulvinar, eleifend tellus eget, malesuada augue. Donec in molestie mi. Nam imperdiet justo nec ligula auctor, vitae venenatis dui lobortis. Mauris porttitor non est rutrum faucibus. Curabitur mattis pulvinar metus et ornare. <br> <br> In ullamcorper massa ut pharetra sodales. Morbi mattis sed erat in vehicula. Cras non mi feugiat, consectetur risus ut, luctus lorem. Ut massa nisl, vulputate vel porttitor et, dignissim vel nibh. Ut in mauris mauris. Mauris ut risus suscipit, varius est quis, auctor orci. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque et lorem gravida, efficitur odio vel, fringilla lorem. Etiam a malesuada est, at dictum mauris. Donec leo urna, varius sed turpis et, mattis aliquet nisl. Mauris facilisis dictum leo sed tincidunt. Sed ac vehicula lacus. Proin dictum orci erat, a dapibus leo tempor vitae. Maecenas posuere massa quis est sagittis efficitur. </p></div></div></div></div>", 1))
  ]))
}