import { ObjectId } from '@/store/types';

export interface LaboratoriesState {
  [key: string]: Laboratory[];
}

export interface Laboratory {
  _id: string;
  client_id?: number;
  lab_code?: string;
  name?: string;
  lab_type?: string;
  location?: LaboratoryLocation;
  hla_test_kit_defaults?: HlaTestKitDefault[];
}

export interface LaboratoryLocation {
  _id?: string;
  country_code?: string;
  province_code?: string;
  city?: string;
  street?: string;
  postal_code?: string;
}

export interface HlaTestKitDefault {
  default_class_1_test_kit_code?: string;
  default_class_1_testing_method_code?: number;
  default_class_2_test_kit_code?: string;
  default_class_2_testing_method_code?: number;
}

export const laboratoriesState: LaboratoriesState = {};

export const LAB_CODE_OTHER = 'OTHER';
