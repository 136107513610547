import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-comment-group" }
const _hoisted_5 = { class: "standard-comment-group" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_13 = {
  key: 2,
  class: "standard-form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_override_mapping_modal = _resolveComponent("override-mapping-modal")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "hla-typing",
    "lookups-to-load": _ctx.lookupsToLoad,
    "laboratories-to-load": _ctx.laboratoriesToLoad,
    onLoaded: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loaded())),
    "save-button": !_ctx.newRecord,
    disabled: !_ctx.canSave,
    ref: "saveHlaTyping",
    "save-button-text": _ctx.$t('save_hla_typing'),
    onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.savePatch()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('typing_header')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_validation_observer, null, {
        default: _withCtx(() => [
          _createVNode(_component_sub_section, {
            title: _ctx.$t('entries'),
            "sub-section-id": "typing-entries"
          }, {
            contents: _withCtx(() => [
              (!_ctx.editState && !_ctx.isFinishedLoading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock("fieldset", {
                    key: 1,
                    disabled: _ctx.isFormDisabled
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_select_input, {
                          selectId: "typing-entries-hla_lab",
                          name: _ctx.$t('hla_lab'),
                          modelValue: _ctx.editState.hla_lab,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.hla_lab) = $event)),
                          options: _ctx.laboratoryLookup,
                          ruleKey: "lab_id"
                        }, null, 8, ["name", "modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_text_area_input, {
                          inputId: "typing-entries-comments",
                          name: _ctx.$t('comments'),
                          modelValue: _ctx.editState.comments,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.comments) = $event)),
                          ruleKey: "comments"
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_select_input, {
                          selectId: "typing-entries-technique_all_rows",
                          name: _ctx.$t('technique_all_rows'),
                          modelValue: _ctx.editState.technique_all_rows,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.technique_all_rows) = $event)),
                          options: _ctx.hlaTypingTechniqueLookup,
                          onChange: _ctx.changedTechniqueAllRows
                        }, null, 8, ["name", "modelValue", "options", "onChange"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_date_input, {
                          inputId: "typing-entries-last_update",
                          name: _ctx.$t('last_update'),
                          readonly: true,
                          modelValue: _ctx.editState.last_update,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.last_update) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_text_input, {
                          inputId: "typing-entries-last_updated_by",
                          name: _ctx.$t('last_updated_by'),
                          readonly: true,
                          modelValue: _ctx.editState.last_updated_by,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.last_updated_by) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_text_input, {
                          inputId: "typing-entries-last_updated_user_lab",
                          name: _ctx.$t('last_updated_user_lab'),
                          readonly: true,
                          modelValue: _ctx.editState.last_updated_user_lab,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.last_updated_user_lab) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      (_ctx.isHlaTypingIncompleteEnabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_checkbox_input, {
                              inputId: "typing-entries-typing_incomplete",
                              labelName: _ctx.$t('typing_incomplete'),
                              modelValue: _ctx.editState.typing_incomplete,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.typing_incomplete) = $event)),
                              label: _ctx.$t('yes'),
                              ruleKey: "typing_incomplete"
                            }, null, 8, ["labelName", "modelValue", "label"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_date_input, {
                          name: _ctx.$t('submit_date'),
                          "input-id": "typing-entries-submit-date",
                          modelValue: _ctx.editState.submitDate,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.submitDate) = $event)),
                          ruleKey: "test_date",
                          disabled: !_ctx.isHlaTypingSubmitDateEditable
                        }, null, 8, ["name", "modelValue", "disabled"])
                      ]),
                      (!_ctx.enableDonor && _ctx.isHlaStemCellTherapyEnabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_text_input, {
                              name: _ctx.$t('hsct_date'),
                              "input-id": "typing-entries-hsct-date",
                              value: _ctx.hsctDate,
                              disabled: true
                            }, null, 8, ["name", "value"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.getInitialLab)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_text_input, {
                              name: _ctx.$t('initial_lab'),
                              "input-id": "initial-lab",
                              value: _ctx.getInitialLab,
                              readonly: true
                            }, null, 8, ["name", "value"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                    _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "row" }, [
                      _createElementVNode("div", { class: "col-sm-12" }, [
                        _createElementVNode("div", { class: "table-responsive" })
                      ])
                    ], -1))
                  ], 8, _hoisted_1))
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 1
      }),
      _createVNode(_component_override_mapping_modal, {
        ref: "overrideMappingModal",
        onConfirmed: _ctx.handleOverrideMapping
      }, null, 8, ["onConfirmed"])
    ]),
    _: 1
  }, 8, ["lookups-to-load", "laboratories-to-load", "save-button", "disabled", "save-button-text"]))
}