import { ObjectId } from '@/store/types';

export const LIVING_DONOR_KEY = 'living_donor_id';
export const DECEASED_DONOR_KEY = 'deceased_donor_id';

export interface HistoryState {
  vxm: HistoryVxm[]|null;
  selectedVxm: HistoryVxm|null;
}

export interface HistoryVxm {
  // schema fields
  _id: string;
  donor_id: string;
  recipient_id: string;
  allocation_id: string|null;
  recipient_hla_antibody_lab_id: string|null;
  date: string;
  vxm_result: VxmResult;
  comments: string|null;
  created_at: string;
  created_by: string|string;
  updated_at: string;
  updated_by: string|string;
  // improvised fields
  donor_client_id: number;
  donor_deceased_donor_id: number|null; // TGLN ID i.e. "iTransplant ID"
  donor_living_donor_id: number|null; // TGLN ID i.e. "iTransplant ID"
  recipient_client_id: number;
}

export interface VxmResult {
  _id: string|null;
  result: string;
  cumulative: VxmResultCategory;
  current: VxmResultCategory;
}

export interface VxmResultCategory {
  _id: string|null;
  result: string;
  class1: VxmResultClass1;
  class2: VxmResultClass2;
}

export interface VxmResultClass1 {
  _id: string|null;
  result: string;
  indeterminate_allele_group: string[];
  indeterminate_allele_specific: string[];
  indeterminate_epitopes: string[];
  possible_allele_specific: string[];
  unacceptable_allele_group: string[];
  unacceptable_allele_specific: string[];
  unacceptable_epitopes: string[];
  untested: string[];
  untested_epitopes: string[];
  messages: string[];
}

export interface VxmResultClass2 {
  _id: string|null;
  result: string;
  indeterminate_allele_group: string[];
  indeterminate_allele_specific: string[];
  indeterminate_alpha_beta: string[];
  indeterminate_epitopes: string[];
  possible_allele_specific: string[];
  unacceptable_allele_group: string[];
  unacceptable_allele_specific: string[];
  unacceptable_alpha_beta: string[];
  unacceptable_epitopes: string[];
  untested: string[];
  untested_epitopes: string[];
  messages: string[];
}
