<template>
  <fieldset>
    <template v-if="editState">
      <div class="row">
        <div class="standard-form-group">
          <select-input
            ruleKey="patient_address.country_code"
            selectId="proto-ld-address-country"
            :name="$t('address_field.country')"
            v-model="editState.countryCode"
            :options="countryOptions"
            @change="handleCountryChanged"
            :filterByLookupRule="true"
          />
        </div>
        <template v-if="editState.countryCode === getCountryValue.Canada">
          <div class="standard-form-group">
            <select-input
              ruleKey="patient_address.province_code"
              selectId="proto-ld-address-province"
              :name="$t('address_field.province_or_territory')"
              validationId="address-province"
              v-model="editState.provinceCode"
              :options="provinceOptions"
            />
          </div>
        </template>
        <template v-if="editState.countryCode">
          <div class="row-break d-none d-lg-block d-xxl-none"></div>
          <div class="standard-form-group-large">             
            <text-input
              ruleKey="patient_address.street"
              inputId="proto-ld-address-street"
              :name="$t('address_field.street_address')"
              v-model="editState.streetAddress"
            />
          </div>
          <div class="standard-form-group">
            <text-input
              ruleKey="patient_address.city"
              inputId="proto-ld-address-city"
              :name="$t('address_field.city')"
              v-model="editState.city"
            />
          </div>
          <div class="standard-form-group" v-if="editState.showPostalCode">
            <text-input
              ruleKey="patient_address.postal_code"
              inputId="proto-ld-address-postalcode"
              :name="$t('address_field.postal_code')"
              v-model="editState.postalCode"
            />
          </div>
        </template>
      </div>
    </template>
  </fieldset>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { UIAddress } from "@/UIModels/address";
import TextInput from '@/components/shared/TextInput.vue';
import { Country, CountryValue, Province } from '@/store/lookups/types';
import { IdLookupProvider } from '@/types';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    SubSection,
    SelectInput,
    TextInput,
  },
  ...i18nMessages([
    require('@/components/recipients/contactInformation/_locales/PatientAddress.json'),
  ]),
  emits: [
    'countryChanged',
  ],
})
export default class CanadaOnlyFormat extends Vue implements IdLookupProvider {
  @Getter('country', { namespace: 'lookups' }) private countryOptions!: Country[];
  @Getter('province', { namespace: 'lookups' }) private provinceOptions!: Province[];

  // V-model
  @Prop({ required: true }) editState!: UIAddress;

  get getCountryValue(): any {
    return CountryValue;
  }

  private handleCountryChanged(event: any): void {
    this.editState.provinceCode = null;
    this.$emit('countryChanged', event);
  }

  // Validation mapping
  public idLookup(): IdLookup {
    return {
      'patient_address.country_code':  'address-country',
      'patient_address.province_code': 'address-province',
      'patient_address.street':        'address-street',
      'patient_address.city':          'address-city',
      'patient_address.postal_code':   'address-postalcode',
    };
  }
}
</script>
