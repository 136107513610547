import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sr-only" }
const _hoisted_2 = { class: "sr-only" }
const _hoisted_3 = { class: "side-nav-container" }
const _hoisted_4 = { class: "no-scroll open" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_side_nav_toolbar = _resolveComponent("side-nav-toolbar")!
  const _component_selected_recipient_profile_links = _resolveComponent("selected-recipient-profile-links")!
  const _component_selected_recipient_journey_links = _resolveComponent("selected-recipient-journey-links")!
  const _component_proto_offers_links = _resolveComponent("proto-offers-links")!
  const _component_selected_recipient_hla_links = _resolveComponent("selected-recipient-hla-links")!
  const _component_base_side_nav = _resolveComponent("base-side-nav")!

  return (_openBlock(), _createBlock(_component_base_side_nav, {
    class: _normalizeClass({ closed: _ctx.sideNavClosed })
  }, {
    "side-nav-content": _withCtx(() => [
      _createVNode(_component_side_nav_toolbar, { onToggle: _ctx.toggleSideNav }, {
        "return-link": _withCtx(() => [
          (_ctx.isProtoRecepientListingsEnabled)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "nav-link no-scroll",
                title: _ctx.$t('return_to_prev'),
                "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
                to: { name: 'proto-recipients' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'arrow-left'],
                    "fixed-width": ""
                  }),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('return_to_prev')), 1)
                ]),
                _: 1
              }, 8, ["title", "aria-label"]))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "nav-link no-scroll",
                title: _ctx.$t('return_to_prev'),
                "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
                to: { name: 'list-recipients' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'arrow-left'],
                    "fixed-width": ""
                  }),
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('return_to_prev')), 1)
                ]),
                _: 1
              }, 8, ["title", "aria-label"]))
        ]),
        _: 1
      }, 8, ["onToggle"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.newRecipient)
          ? (_openBlock(), _createBlock(_component_selected_recipient_profile_links, {
              key: 0,
              onToggle: _ctx.toggleSideNav,
              active: true,
              newRecipient: _ctx.newRecipient
            }, {
              "profile-return-link": _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('profile')), 1)
              ]),
              _: 1
            }, 8, ["onToggle", "newRecipient"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRecipientJourneysList, (journey) => {
                return (_openBlock(), _createBlock(_component_selected_recipient_journey_links, {
                  key: journey.id,
                  onToggle: _ctx.toggleSideNav,
                  journeyName: journey.name,
                  journeyId: journey.id,
                  organCode: journey.organCode,
                  transplantedDate: journey.transplantedDate
                }, null, 8, ["onToggle", "journeyName", "journeyId", "organCode", "transplantedDate"]))
              }), 128)),
              (_ctx.isProtoRecipientOffersEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_proto_offers_links, null, _createSlots({ _: 2 }, [
                      (!_ctx.newRecipient)
                        ? {
                            name: "profile-return-link",
                            fn: _withCtx(() => [
                              _createVNode(_component_router_link, {
                                class: "no-scroll open",
                                style: {"color":"#2e2e2e !important"},
                                to: { name: 'proto-offers-pages', params: { id: _ctx.recipient.client_id } }
                              }, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createTextVNode(" Offers ")
                                ])),
                                _: 1
                              }, 8, ["to"])
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1024),
                    _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_selected_recipient_profile_links, {
                onToggle: _ctx.toggleSideNav,
                active: true
              }, {
                "profile-return-link": _withCtx(() => [
                  _createVNode(_component_router_link, {
                    class: "no-scroll open",
                    style: {"color":"#2e2e2e !important"},
                    to: { name: 'edit-recipient', params: { id: _ctx.recipient.client_id } }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('profile')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _: 1
              }, 8, ["onToggle"]),
              _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
              _createVNode(_component_selected_recipient_hla_links, { onToggle: _ctx.toggleSideNav }, null, 8, ["onToggle"])
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}