import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoSmallBowelInformationEnabled)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createVNode(_component_sub_section, {
          "sub-section-id": "proto_small_bowel_information",
          title: _ctx.title
        }, {
          contents: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "small-bowel-Information-form" }, {
              contents: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_text_area_input, {
                      "input-id": "small-bowel-info-comments",
                      name: "General Comments",
                      rules: "max_length:5000",
                      rows: "4",
                      modelValue: _ctx.editState.comments,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.comments) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveSmallBowelInformation",
                  label: "Save Small Bowel Information",
                  cancelButton: true,
                  onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.performSave())),
                  onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearForm()))
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"])
      ]))
    : _createCommentVNode("", true)
}