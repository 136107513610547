<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>

          <router-link class="nav-link no-scroll" :title="$t('return_to_prev')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'proto-recipients' }" v-if="isProtoRecepientListingsEnabled">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_prev')}}
            </span>
          </router-link>
          <router-link class="nav-link no-scroll" :title="$t('return_to_prev')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'list-recipients' }" v-else>
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_prev')}}
            </span>
          </router-link>

        </template>
      </side-nav-toolbar>
      <div class="side-nav-container" @toggle="toggleSideNav" :active="true">
        <template v-if="newRecipient">
          <selected-recipient-profile-links @toggle="toggleSideNav" :active="true" :newRecipient="newRecipient">
            <template v-slot:profile-return-link>
              <span class="no-scroll open">
                {{$t('profile')}}
              </span>
            </template>
          </selected-recipient-profile-links>
        </template>
        <template v-else>
          <selected-recipient-journey-links
            v-for="journey in selectedRecipientJourneysList"
            :key="journey.id"
            @toggle="toggleSideNav"
            :journeyName="journey.name"
            :journeyId="journey.id"
            :organCode="journey.organCode"
          />
          <div v-if="isProtoRecipientOffersEnabled">
            <proto-offers-links :active="true" @toggle="toggleSideNav">
              <template v-slot:profile-return-link>
                <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'proto-offers-pages', params: { id: recipient.client_id } }">
                  Offers
                </router-link>
              </template>
            </proto-offers-links>
            <hr>
          </div>
          <selected-recipient-profile-links  >
            <template v-slot:profile-return-link>
              <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'edit-recipient', params: { id: recipient.client_id } }">
                {{$t('profile')}}
              </router-link>
            </template>
          </selected-recipient-profile-links>
          <hr>
          <selected-recipient-hla-links @toggle="toggleSideNav"/>
        </template>
      </div>
    </template>
  </base-side-nav>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientJourneyLinks from '@/components/recipients/side-nav/SelectedRecipientJourneyLinks.vue';
import SelectedRecipientProfileLinks from '@/components/recipients/side-nav/SelectedRecipientProfileLinks.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import { i18nMessages } from '@/i18n';
import { UIJourneySummary } from '@/UIModels/journey/journeySummary';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import ProtoOffersLinks from '@/prototypes/side-nav/ProtoOffersLinks.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientHlaLinks,
    SelectedRecipientJourneyLinks,
    SelectedRecipientProfileLinks,
    ProtoOffersLinks
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/recipients/_locales/SideNavRecipientProfile.json'),
    require('@/components/recipients/_locales/common.json'),
  ]),
})
export default class SideNavRecipientProfile extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({ default: false }) newRecipient!: boolean;

  public sideNavClosed = false;

  get isProtoRecepientListingsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoRecipientsListings);
  }

  get selectedRecipientJourneysList(): UIJourneySummary[] {
    return useCurrentPageStore().currentRecipient?.selectedRecipientJourneysList || [];
  }

  get isProtoRecipientOffersEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoOffers);
  }


  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
