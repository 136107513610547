import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_13 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_14 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_15 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_18 = {
  key: 1,
  class: "standard-form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": "post-transplant-follow-up-form"
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            (_ctx.selection.isNew)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('new_follow_up')), 1))
              : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('selected_follow_up')), 1))
          ])
        ]),
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_date_input, {
                ruleKey: "post_transplant_follow_ups.entry_date",
                name: _ctx.$t('entry_date'),
                "input-id": "post_transplant_follow_up_entry_date",
                modelValue: _ctx.editState.entry_date,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.entry_date) = $event)),
                disabled: true
              }, null, 8, ["name", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_select_input, {
                ruleKey: "post_transplant_follow_ups.follow_up_type",
                "select-id": "post_transplant_follow_up_type",
                name: _ctx.$t('follow_up_type'),
                options: _ctx.followUpTypeLookup,
                modelValue: _ctx.editState.follow_up_type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.follow_up_type) = $event))
              }, null, 8, ["name", "options", "modelValue"])
            ]),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.years_since_transplant'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_text_input, {
                    ruleKey: "post_transplant_follow_ups.years_since_transplant",
                    name: _ctx.$t('years_since_transplant'),
                    "input-id": "post_transplant_follow_up_years_since_transplant",
                    modelValue: _ctx.editState.years_since_transplant,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.years_since_transplant) = $event)),
                    "cross-values": { follow_up_type: _ctx.editState.follow_up_type }
                  }, null, 8, ["name", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_select_input, {
                ruleKey: "post_transplant_follow_ups.recipient_status_code",
                "select-id": "post_transplant_follow_up_recipient_status_code",
                name: _ctx.$t('recipient_status_code'),
                options: _ctx.followUpRecipientStatusLookup,
                modelValue: _ctx.editState.recipient_status_code,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.recipient_status_code) = $event))
              }, null, 8, ["name", "options", "modelValue"])
            ]),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.follow_up_date'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_date_input, {
                    ruleKey: "post_transplant_follow_ups.follow_up_date",
                    name: _ctx.$t('date_last_seen'),
                    "input-id": "post_transplant_follow_up_follow_up_date",
                    modelValue: _ctx.editState.follow_up_date,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.follow_up_date) = $event)),
                    "cross-values": { recipient_status_code: _ctx.editState.recipient_status_code }
                  }, null, 8, ["name", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_select_input, {
                ruleKey: "post_transplant_follow_ups.graft_status_code",
                "select-id": "post_transplant_follow_up_graft_status_code",
                name: _ctx.$t('graft_status_code'),
                options: _ctx.followUpGraftStatusLookup,
                modelValue: _ctx.editState.graft_status_code,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.graft_status_code) = $event)),
                "cross-values": { recipient_status_code: _ctx.editState.recipient_status_code }
              }, null, 8, ["name", "options", "modelValue", "cross-values"])
            ]),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.graft_rejection_date'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_date_input, {
                    ruleKey: "post_transplant_follow_ups.graft_rejection_date",
                    name: _ctx.$t('graft_rejection_date'),
                    "input-id": "post_transplant_follow_up_graft_rejection_date",
                    modelValue: _ctx.editState.graft_rejection_date,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.graft_rejection_date) = $event)),
                    "cross-values": { graft_status_code: _ctx.editState.graft_status_code }
                  }, null, 8, ["name", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.donor_specific_antibody'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_select_input, {
                    ruleKey: "post_transplant_follow_ups.donor_specific_antibody",
                    "select-id": "post_transplant_follow_up_donor_specific_antibody",
                    name: _ctx.$t('donor_specific_antibody'),
                    options: _ctx.followUpDonorSpecificAntibodyLookup,
                    modelValue: _ctx.editState.donor_specific_antibody,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.donor_specific_antibody) = $event)),
                    "cross-values": { graft_status_code: _ctx.editState.graft_status_code }
                  }, null, 8, ["name", "options", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.graft_failure_date'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_component_date_input, {
                    ruleKey: "post_transplant_follow_ups.graft_failure_date",
                    name: _ctx.$t('graft_failure_date'),
                    "input-id": "post_transplant_follow_up_graft_failure_date",
                    modelValue: _ctx.editState.graft_failure_date,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.graft_failure_date) = $event)),
                    "cross-values": { graft_status_code: _ctx.editState.graft_status_code }
                  }, null, 8, ["name", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.graft_failure_cause_code'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(_component_select_input, {
                    ruleKey: "post_transplant_follow_ups.graft_failure_cause_code",
                    "select-id": "post_transplant_follow_up_graft_failure_cause_code",
                    name: _ctx.$t('graft_failure_cause_code'),
                    options: _ctx.followUpGraftFailureCauseLookup,
                    modelValue: _ctx.editState.graft_failure_cause_code,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.graft_failure_cause_code) = $event)),
                    "cross-values": { graft_status_code: _ctx.editState.graft_status_code }
                  }, null, 8, ["name", "options", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.lost_to_follow_up_date'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_date_input, {
                    ruleKey: "post_transplant_follow_ups.lost_to_follow_up_date",
                    name: _ctx.$t('lost_to_follow_up_date'),
                    "input-id": "post_transplant_follow_up_lost_to_follow_up_date",
                    modelValue: _ctx.editState.lost_to_follow_up_date,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.lost_to_follow_up_date) = $event)),
                    "cross-values": { recipient_status_code: _ctx.editState.recipient_status_code }
                  }, null, 8, ["name", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.onlyIfValue(_ctx.editState, 'post_transplant_follow_ups.lost_to_follow_up_reason'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(_component_select_input, {
                    ruleKey: "post_transplant_follow_ups.lost_to_follow_up_reason",
                    "select-id": "post_transplant_follow_up_lost_to_follow_up_reason",
                    name: _ctx.$t('lost_to_follow_up_reason'),
                    options: _ctx.followUpLossOfContactReasonLookup,
                    modelValue: _ctx.editState.lost_to_follow_up_reason,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.lost_to_follow_up_reason) = $event)),
                    "cross-values": { recipient_status_code: _ctx.editState.recipient_status_code }
                  }, null, 8, ["name", "options", "modelValue", "cross-values"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.showSaveToolbar,
            ref: "saveToolbar",
            label: _ctx.$t('save_follow_up'),
            cancelButton: true,
            onSave: _ctx.handleSave,
            onCancel: _ctx.handleCancel
          }, null, 8, ["show", "label", "onSave", "onCancel"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 512))
}