import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "legend-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_checklist_outcome_form = _resolveComponent("checklist-outcome-form")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "validations",
    as: "div",
    class: "task-body"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": `checklist-${_ctx.editState.id}-form`
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.editState.isNew ? _ctx.$t("new_checklist_item") : _ctx.$t("selected_checklist_item")), 1)
          ])
        ]),
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_text_input, {
                "input-id": `checklist_${_ctx.editState.id}.name`,
                name: _ctx.$t("item_name"),
                label: _ctx.$t("item_name"),
                ruleKey: `checklist_${_ctx.editState.id}.name`,
                modelValue: _ctx.editState.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.name) = $event))
              }, null, 8, ["input-id", "name", "label", "ruleKey", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_checkbox_input, {
                "input-id": `checklist_${_ctx.editState.id}.external`,
                name: _ctx.$t("external"),
                labelName: _ctx.$t("external"),
                ruleKey: `checklist_${_ctx.editState.id}.external`,
                modelValue: _ctx.editState.external,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.external) = $event)),
                label: "Yes"
              }, null, 8, ["input-id", "name", "labelName", "ruleKey", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_select_input, {
                "select-id": `checklist_${_ctx.editState.id}.status`,
                ruleKey: `checklist_${_ctx.editState.id}.status`,
                name: _ctx.$t("status"),
                label: _ctx.$t("status"),
                options: _ctx.recipientJourneyChecklistItemStatus,
                modelValue: _ctx.editState.status,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.status) = $event))
              }, null, 8, ["select-id", "ruleKey", "name", "label", "options", "modelValue"])
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hr-break mt-3" }, null, -1)),
          (_ctx.isOutcomesEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("legend", null, [
                  _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t('outcomes')), 1)
                ]),
                _createVNode(_component_table_toolbar, {
                  createButton: _ctx.showCreateOutcome,
                  createText: _ctx.$t('create_outcome'),
                  onTableCreateRow: _ctx.handleCreateOutcome
                }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
                _createVNode(_component_table_list, {
                  ref: "checklistOutcomeTable",
                  "table-id": `checklist-outcomes-table_${_ctx.editState.id}`,
                  tabbableColumn: "service_date",
                  "table-config": _ctx.outcomeConfig,
                  onTableRowClick: _ctx.handleOutcomeTableRowClick,
                  highlightSelection: true,
                  isLoading: _ctx.isLoading
                }, null, 8, ["table-id", "table-config", "onTableRowClick", "isLoading"]),
                _createVNode(_component_checklist_outcome_form, {
                  "form-id": `checklist-outcome-form_${_ctx.editState.id}`,
                  ref: "checklistOutcomeForm",
                  selection: _ctx.outcomeSelection,
                  onDestroy: _ctx.handleDestroyOutcome,
                  checklistTask: _ctx.editState,
                  canSave: _ctx.showCreateOutcome
                }, null, 8, ["form-id", "selection", "onDestroy", "checklistTask", "canSave"])
              ]))
            : _createCommentVNode("", true)
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.enableForm,
            ref: "saveTask",
            class: "card-footer action-row temp-saving row",
            label: _ctx.$t('save'),
            cancelButton: true,
            onSave: _ctx.handleSave,
            onCancel: _ctx.handleCancel,
            labelSaveAndCreateAnother: _ctx.$t("save_and_create_another"),
            showSaveAndCreateAnother: _ctx.showSaveAndCreateAnother,
            onSaveAndCreateAnother: _ctx.handleSaveAndCreateAnother
          }, null, 8, ["show", "label", "onSave", "onCancel", "labelSaveAndCreateAnother", "showSaveAndCreateAnother", "onSaveAndCreateAnother"])
        ]),
        _: 1
      }, 8, ["disabled", "form-id"])
    ]),
    _: 1
  }, 512))
}