import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "content-wrap"
}
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = {
  key: 1,
  class: "content-wrap dashboard-widgets"
}
const _hoisted_5 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_import_page = _resolveComponent("loading-import-page")!
  const _component_import_file = _resolveComponent("import-file")!
  const _component_import_history = _resolveComponent("import-history")!

  return (_ctx.isImportPatientsEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_page_top, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('import_title')), 1)
          ]),
          _: 1
        }),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_loading_import_page)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_import_file),
                _createVNode(_component_import_history)
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}