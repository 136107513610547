import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { class: "card tip-card" }
const _hoisted_3 = { class: "card-body col-sm-12" }
const _hoisted_4 = { class: "content-wrap" }
const _hoisted_5 = { class: "mb-3 text-dark" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-12" }
const _hoisted_11 = {
  key: 0,
  class: "page-content p-0"
}
const _hoisted_12 = { class: "mb-3 text-dark" }
const _hoisted_13 = {
  key: 1,
  class: "page-content p-0"
}
const _hoisted_14 = { class: "mb-3 text-dark" }
const _hoisted_15 = {
  key: 2,
  class: "page-content p-0"
}
const _hoisted_16 = { class: "mb-3 text-dark" }
const _hoisted_17 = {
  key: 3,
  class: "page-content p-0"
}
const _hoisted_18 = { class: "mb-3 text-dark" }
const _hoisted_19 = {
  key: 4,
  class: "page-content p-0"
}
const _hoisted_20 = { class: "mb-3 text-dark" }
const _hoisted_21 = {
  key: 5,
  class: "page-content p-0"
}
const _hoisted_22 = { class: "mb-3 text-dark" }
const _hoisted_23 = {
  key: 6,
  class: "page-content p-0"
}
const _hoisted_24 = { class: "mb-3 text-dark" }
const _hoisted_25 = {
  key: 7,
  class: "page-content p-0"
}
const _hoisted_26 = { class: "mb-3 text-dark" }
const _hoisted_27 = {
  key: 8,
  class: "page-content p-0"
}
const _hoisted_28 = { class: "mb-3 text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_transplant_in_progress_report = _resolveComponent("transplant-in-progress-report")!
  const _component_transplant_activity_report = _resolveComponent("transplant-activity-report")!
  const _component_dialysis_report = _resolveComponent("dialysis-report")!
  const _component_change_status_report = _resolveComponent("change-status-report")!
  const _component_crossmatch_report = _resolveComponent("crossmatch-report")!
  const _component_waiting_list_report = _resolveComponent("waiting-list-report")!
  const _component_organ_yield_utilization_report = _resolveComponent("organ-yield-utilization-report")!
  const _component_allocation_report = _resolveComponent("allocation-report")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_national_organ_waitlist_modal = _resolveComponent("national-organ-waitlist-modal")!
  const _component_full_page_list_layout = _resolveComponent("full-page-list-layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('reports')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_full_page_list_layout, null, {
      content: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('available_reports')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('please_choose_report')), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("form", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_select_input, {
                      "select-id": "sort-type-report",
                      name: _ctx.$t('select_report'),
                      modelValue: _ctx.editState.currentReport,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.currentReport) = $event)),
                      options: _ctx.allowedReports,
                      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectReport($event)))
                    }, null, 8, ["name", "modelValue", "options"])
                  ])
                ])
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_validation_observer, { ref: "validations" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("form", _hoisted_10, [
                      (_ctx.isTransplantInProgressReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('please_select_default_sorting')), 1),
                            _createVNode(_component_transplant_in_progress_report)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isTransplantActivityReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('please_select_default_sorting')), 1),
                            _createVNode(_component_transplant_activity_report, {
                              ref: "transplantActivity",
                              onHandleErrors: _cache[2] || (_cache[2] = (errors) => _ctx.handleErrors(errors)),
                              onClear: _ctx.resetValidationErrors
                            }, null, 8, ["onClear"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isDialysisReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('please_select_required_filters')), 1),
                            _createVNode(_component_dialysis_report)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isChangeStatusReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('please_select_required_filters')), 1),
                            _createVNode(_component_change_status_report)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isCrossMatchReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('please_select_required_filters')), 1),
                            _createVNode(_component_crossmatch_report)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isAWaitingListReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('please_select_required_filters')), 1),
                            _createVNode(_component_waiting_list_report, {
                              ref: "waitingListReport",
                              report: _ctx.editState.currentReport
                            }, null, 8, ["report"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isOrganYieldUtilizationReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('please_select_required_filters')), 1),
                            _createVNode(_component_organ_yield_utilization_report, {
                              ref: "organYieldUtilization",
                              onHandleErrors: _cache[3] || (_cache[3] = (errors) => _ctx.handleErrors(errors)),
                              onClear: _ctx.resetValidationErrors
                            }, null, 8, ["onClear"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isARecipientDataAuditReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('please_select_required_filters')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isAllocationReport)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t('please_select_required_filters')), 1),
                            _createVNode(_component_allocation_report, {
                              ref: "allocationReport",
                              report: _ctx.editState.currentReport,
                              onHandleErrors: _cache[4] || (_cache[4] = (errors) => _ctx.handleErrors(errors)),
                              onClear: _ctx.resetValidationErrors
                            }, null, 8, ["report", "onClear"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 512)
            ])
          ])
        ]),
        _createVNode(_component_national_organ_waitlist_modal, { ref: "nationalOrganWaitlistModal" }, null, 512)
      ]),
      _: 1
    })
  ]))
}