import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["configId"]
const _hoisted_2 = { class: "dropdown menu-big show" }
const _hoisted_3 = {
  id: "customizeCols",
  href: "#",
  role: "button",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false",
  class: "btn btn-primary btn-sm dropdown-toggle"
}
const _hoisted_4 = {
  "aria-labelledby": "customizeCols",
  class: "dropdown-menu keep-open dropdown-menu-left"
}
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-12" }
const _hoisted_8 = { class: "menu-big-heading" }
const _hoisted_9 = { "aria-hidden": "true" }
const _hoisted_10 = { class: "nav flex-column" }
const _hoisted_11 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_12 = { class: "col-sm-12" }
const _hoisted_13 = { class: "big-menu-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_column_config_checkbox_input = _resolveComponent("column-config-checkbox-input")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        configId: _ctx.configId
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('columns')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h3", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_ctx.$t('available_columns')) + " ", 1),
                      _createElementVNode("button", {
                        tabindex: "-1",
                        type: "button",
                        class: "close",
                        "aria-label": "Close",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dismiss && _ctx.dismiss(...args)))
                      }, [
                        _createElementVNode("span", _hoisted_9, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'times-circle'],
                            class: "text-red",
                            "fixed-width": ""
                          })
                        ])
                      ])
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quintuples, (quintuple) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: quintuple.key,
                      class: "col-md-4"
                    }, [
                      _createElementVNode("ul", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quintuple.options, (option) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: option.code
                          }, [
                            _createVNode(_component_column_config_checkbox_input, {
                              inputId: `${_ctx.configId}-${option[_ctx.fieldKey]}`,
                              label: option.label,
                              modelValue: _ctx.configState[option[_ctx.fieldKey]],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.configState[option[_ctx.fieldKey]]) = $event),
                              onChange: ($event: any) => (_ctx.onCheckboxChange(option[_ctx.fieldKey], $event))
                            }, null, 8, ["inputId", "label", "modelValue", "onUpdate:modelValue", "onChange"])
                          ]))
                        }), 128))
                      ])
                    ]))
                  }), 128)),
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sub-divider" }, null, -1)),
                  (_ctx.errorMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "sub-divider" }, null, -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-sm btn-success mt-2 mb-3",
                        title: "save_columns",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveColumns()))
                      }, _toDisplayString(_ctx.$t('save_columns')), 1)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}