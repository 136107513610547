import { APIDonorAcceptabilityDetails, APIDonorAcceptabilityCondition } from '@/APIModels/journey/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveJourneyResponse } from '@/types';
import { SaveResult } from '@/types';
import { LengthCalcuation, WeightCalcuation } from '@/APIModels/tools/types';
import { toFixed, convertInchesToFeetAndInches } from '@/utils';

const DIGITS_AFTER_DECIMAL = 1;

export class UIDonorAcceptabilityCondition {
  public apiSource?: APIDonorAcceptabilityCondition;

  public id: string|null = null;
  public acceptable: string|null = null;
  public donor_condition: string|null = null;  

  public constructor(apiDonorAcceptabilityCondition?: APIDonorAcceptabilityCondition) {
    if (apiDonorAcceptabilityCondition) this.updateFromAPIResponse(apiDonorAcceptabilityCondition);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiDonorAcceptabilityCondition: APIDonorAcceptabilityCondition) {
    this.apiSource = apiDonorAcceptabilityCondition;

    this.id = apiDonorAcceptabilityCondition._id || null;
    this.acceptable = this.convertFromBoolean(apiDonorAcceptabilityCondition.acceptable);
    this.donor_condition = apiDonorAcceptabilityCondition.donor_condition || null;
  }

  // Convert boolean for string for ui
  public convertFromBoolean(value: boolean|null|undefined): string|null {
    if (value === null || value === undefined) return null;
    return value === true ? 'true' : 'false';
  }

  // Convert string to boolean for api
  public convertToBoolean(value: string|null): boolean|null {
    if (value === null || value === undefined) return null;
    return value === 'true' ? true : false;
  }
}

export class UIDonorAcceptabilityDetails {
  public apiSource?: APIDonorAcceptabilityDetails;

  public accept_criteria_conditions: UIDonorAcceptabilityCondition[] = [];

  public accept_donor_types: string[]|null = null;

  public min_age_yrs: number|null = null;

  public min_height_cm: number|null = null;
  public min_height_ft: number|null = null;
  public min_height_in: number|null = null;

  public min_weight_kg: number|null = null;
  public min_weight_lb: number|null = null;

  public max_age_yrs: number|null = null;

  public max_height_cm: number|null = null;
  public max_height_ft: number|null = null;
  public max_height_in: number|null = null;

  public max_weight_kg: number|null = null;
  public max_weight_lb: number|null = null;

  public comments: string|null = null;

  public permitted_actions: string[] = [];

  public constructor(apiDonorAcceptabilityDetails?: APIDonorAcceptabilityDetails) {
    if (apiDonorAcceptabilityDetails) this.updateFromAPIResponse(apiDonorAcceptabilityDetails);
  }

  // Map from API data structure to UI model structure
  public async updateFromAPIResponse(apiDonorAcceptabilityDetails: APIDonorAcceptabilityDetails): Promise<void> {
    this.apiSource = apiDonorAcceptabilityDetails;

    this.accept_criteria_conditions = [];

    (apiDonorAcceptabilityDetails.accept_criteria_conditions || []).map((item: APIDonorAcceptabilityCondition) => {
      if (item.donor_condition && !['living', 'dcc', 'dnc'].includes(item.donor_condition)) {
        const condition: UIDonorAcceptabilityCondition = new UIDonorAcceptabilityCondition(item);
        this.accept_criteria_conditions.push(condition);
      }
    });

    this.accept_donor_types = apiDonorAcceptabilityDetails.accept_donor_types || null;

    // init values
    this.min_age_yrs = apiDonorAcceptabilityDetails.min_age_yrs || null;
    this.min_height_cm = apiDonorAcceptabilityDetails.min_height_cm || null;
    this.min_height_ft = null;
    this.min_height_in = null;
    this.min_weight_kg = apiDonorAcceptabilityDetails.min_weight_kg || null;
    this.min_weight_lb = null;

    this.max_age_yrs = apiDonorAcceptabilityDetails.max_age_yrs || null;
    this.max_height_cm = apiDonorAcceptabilityDetails.max_height_cm || null;
    this.max_height_ft = null;
    this.max_height_in = null;
    this.max_weight_kg = apiDonorAcceptabilityDetails.max_weight_kg || null;
    this.max_weight_lb = null;

    this.comments = apiDonorAcceptabilityDetails.comments || null;
  }

  public async calculateMeasurements(): Promise<void> {
    // calculate values
    await this.calculateFromKg(this.min_weight_kg, 'min');
    await this.calculateFromKg(this.max_weight_kg, 'max');

    await this.calculateFromCm(this.min_height_cm, 'min');
    await this.calculateFromCm(this.max_height_cm, 'max');
  }

  // Build a copy of the view model
  public copyViewModel() {
    const response = new UIDonorAcceptabilityDetails(this.apiSource);
    response.permitted_actions = this.permitted_actions;
    return response;
  }

  // Save edit state to the backend
  public save(opts: { recipient: string|null, journey: string|null }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient;
      const journeyId = opts.journey;
      if (!recipientId) reject((new UIError('donor_acceptability_details')));

      const ep = APIRoute(EP.recipients.journeys.update, [[':recipientId', recipientId as string], [':journeyId', journeyId as string]]);
      const payload = {
        donor_acceptability: this.extractPatch()
      };
      axios.patch(ep, payload).then((response: APISaveJourneyResponse) => {
        if (response.data.errors) {
          reject((new UIError('donor_acceptability_details', response)).errorResult);
        } else {
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('donor_acceptability_details', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIDonorAcceptabilityDetails {
    const accept_criteria_conditions: any = this.accept_criteria_conditions.map((item: UIDonorAcceptabilityCondition) => {
      return {
        _id: item.id,
        acceptable: item.convertToBoolean(item.acceptable),
        donor_condition: item.donor_condition
      };
    });

    const patch: APIDonorAcceptabilityDetails = {
      accept_criteria_conditions: accept_criteria_conditions,
      accept_donor_types: this.accept_donor_types,
      min_age_yrs: this.min_age_yrs,
      min_height_cm: this.min_height_cm,
      min_weight_kg: this.min_weight_kg,
      max_age_yrs: this.max_age_yrs,
      max_height_cm: this.max_height_cm,
      max_weight_kg: this.max_weight_kg,
      comments: this.comments
    };

    return patch;
  }

  public setPermittedActions(permitted_actions: string[]) {
    this.permitted_actions = permitted_actions;
  }

  public async loadLength(payload: LengthCalcuation): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const url = APIRoute(EP.tools.length);
      try {
        const response: any = await axios.get(url, { params: payload });
        resolve(response.data);
      } catch (error: unknown) {
        reject();
        console.warn(error);
      }
    });
  }

  public async loadWeight(payload: WeightCalcuation): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const url = APIRoute(EP.tools.weight);
      try {
        const response: any = await axios.get(url, { params: payload });
        resolve(response.data);
      } catch (error: unknown) {
        reject();
        console.warn(error);
      }
    });
  }

  public async calculateFromFeet(newFeet: number, inches: number, type: string): Promise<void> {
    if (!newFeet) {
      if (type === 'min') {
        this.min_height_cm = null;
        this.min_height_in = null;
      } else {
        this.max_height_cm = null;
        this.max_height_in = null;
      }
      return;
    }

    await this.calculateFromFeetAndInches(newFeet, inches, type);
  }

  public async calculateFromInches(feet: number, newInches: number, type: string): Promise<void> {
    if (!newInches) {
      if (type === 'min') {
        this.min_height_ft = null;
        this.min_height_cm = null;
      } else {
        this.max_height_ft = null;
        this.max_height_cm = null;
      }
      return;
    }

    await this.calculateFromFeetAndInches(feet, newInches, type);
  }

  public async calculateFromFeetAndInches(newFeet: number, newInches: number, type: string): Promise<void> {
    const feet = isNaN(newFeet) ? 0 : Number(newFeet);
    const inches = isNaN(newInches) ? 0 : Number(newInches);

    try {
      // do the calculations outside the new field value, so as to not default inches or feet to 0
      // we want to preseve the null if they didn't enter anything
      const combinedInches = ((feet) * 12) + (inches);
      const apiResponse = combinedInches > 0 ? await this.loadLength({
        unit: 'in',
        length: combinedInches
      }) : null;

      const feetAndInches = this.validateFeetAndInches(feet, inches);
      if (type === 'min') {
        if (apiResponse) {
          this.min_height_ft = feetAndInches.feet;
          this.min_height_in = feetAndInches.inches;
          this.min_height_cm = toFixed(apiResponse.cm, DIGITS_AFTER_DECIMAL) || null;
        } else {
          this.min_height_cm = 0;
        }
      } else {
        if (apiResponse) {
          this.max_height_ft = feetAndInches.feet;
          this.max_height_in = feetAndInches.inches;
          this.max_height_cm = toFixed(apiResponse.cm, DIGITS_AFTER_DECIMAL) || null;
        } else {
          this.max_height_cm = 0;
        }
      }
    } catch(err: any) {
      console.log(err);
    }
  }

  public validateFeetAndInches(feet: number|null, inches: number|null): any {
    // if inches is above 12 then we'll need to re-calculate the feet & inches
    if (inches && inches > 12) {
      return convertInchesToFeetAndInches(inches);
    } else {
      return {
        feet: feet,
        inches: inches
      };
    }
  }

  public async calculateFromCm(newCm: number|null, type: string): Promise<void> {
    // if no value, reset ft/in
    if (!newCm) {
      if (type === 'min') {
        this.min_height_ft = null;
        this.min_height_in = null;
      } else {
        this.max_height_ft = null;
        this.max_height_in = null;
      }
      return;
    }

    try {
      const response = await this.loadLength({ unit: 'cm', length: newCm });
      if (type === 'min') {
        const calculated = convertInchesToFeetAndInches(response.in);
        this.min_height_ft = calculated.feet;
        this.min_height_in = calculated.inches;
        this.min_height_cm = newCm;
      } else {
        const calculated = convertInchesToFeetAndInches(response.in);
        this.max_height_ft = calculated.feet;
        this.max_height_in = calculated.inches;
        this.max_height_cm = newCm;
      }
    } catch(err: any) {
      console.log(err);
    }
  }

  public async calculateFromLb(newLb: number|null, type: string): Promise<void> {
    // if no value, reset kg
    if (!newLb) {
      if (type === 'min') {
        this.min_weight_kg = null;
      } else {
        this.max_weight_kg = null;
      }
      return;
    }

    try {
      const response = await this.loadWeight({ unit: 'lbs', weight: newLb });
      if (type === 'min') {
        this.min_weight_kg = response.kg > 0 ? toFixed(response.kg, DIGITS_AFTER_DECIMAL) : 0;
        this.min_weight_lb = newLb;
      } else {
        this.max_weight_kg = response.kg > 0 ? toFixed(response.kg, DIGITS_AFTER_DECIMAL) : 0;
        this.max_weight_lb = newLb;
      }
    } catch(err: any) {
      console.log(err);
    }
  }

  public async calculateFromKg(newKg: number|null, type: string): Promise<void> {
    // if no value, reset lb
    if (!newKg) {
      if (type === 'min') {
        this.min_weight_lb = null;
      } else {
        this.max_weight_lb = null;
      }
      return;
    }

    try {
      const response = await this.loadWeight({ unit: 'kg', weight: newKg });
      if (type === 'min') {
        this.min_weight_kg = newKg;
        this.min_weight_lb = response.lbs > 0 ? toFixed(response.lbs, DIGITS_AFTER_DECIMAL) : 0;
      } else {
        this.max_weight_kg = newKg;
        this.max_weight_lb = response.lbs > 0 ? toFixed(response.lbs, DIGITS_AFTER_DECIMAL) : 0;
      }
    } catch(err: any) {
      console.log(err);
    }
  }
}
  