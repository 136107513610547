<template>
  <!-- Import Details Modal -->
  <modal-section
    modalId="detailModal" 
    ref="detailModal" 
    class="detail-modal"
    :centered="true" 
    :closeButton="true" 
    size="md" 
    :wide="true">
    <template v-slot:title>
      {{ $t('import_details', { datetime: `${selected.date} ${selected.time}`, filename: selected.fileName } ) }}
    </template>

    <template v-slot:body>
      <!-- Filter Bar -->
      <table-toolbar>
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="notesFilter" 
                :showFilter="true"
                :showImportDetailsModalFilter="true"
                @setFilters="setFilters" 
                :hideSpacer="true"
                :alignLeft="true"
                />
            </div>
          </div>
        </template>
      </table-toolbar>

      <table-list
        ref="importDetailTable"
        table-id="import-detail-table"
        :table-config="tableDetailConfig"
        :firstColumnTabbable="true"
        :total-records="importRowInfo.recordCount || 0"
        @onPageNumber="onPageNumber($event); loadData()"
        @onPageSize="onPageSize($event); loadData()"
        mode="remote"
      />
    </template>
  </modal-section>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, mixins } from "vue-facing-decorator";
import { i18nMessages } from '@/i18n';
import SubSection from "@/components/shared/SubSection.vue";
import TableList from '@/components/shared/TableList.vue';
import { TableConfig } from '@/types';
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIImportDetailStatusTypesLookup, UIImportRowInfo, UIImportPatients, UIImportPatient, UIImportDetailRow } from '@/UIModels/importPatients';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Hospital } from "@/store/hospitals/types";
import { GenericCodeValue } from "@/store/types";
import CardSection from '@/components/shared/CardSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import { RemotePaginationMixin } from "@/mixins/remote-pagination-mixin";
import SelectInput from "@/components/shared/SelectInput.vue";
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import TableToolbar from '@/components/shared/TableToolbar.vue';

const DETAIL_PAGE_SIZES = [25, 50, 100];
const DETAIL_DEFAULT_PAGE_SIZE = DETAIL_PAGE_SIZES[0];

interface TableRow {
  row_number: string|null;
  status: string|null;
  error_message: string|null;
}

@Component({
  components: {
    CardSection,
    SubSection,
    TableList,
    ModalSection,
    DateInput,
    TextInput,
    SelectInput,
    FilterComponent,
    TableToolbar
  },
  ...i18nMessages([
    require('@/views/importPatient/_locales/import.json'),
  ])
})
export default class ImportDetailModal extends mixins(DateUtilsMixin, RemotePaginationMixin) {
  // State
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  private isLoading = true;
  private selected: UIImportPatient = new UIImportPatient();
  private importRowInfo: UIImportRowInfo = new UIImportRowInfo(); 

  private selectedFilters: UIFilterAssignments = {
    row_number: null,
    status: null
  };

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get importPatients(): UIImportPatients {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.importPatients as UIImportPatients;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  get importHistoryTable(): TableList {
    return this.$refs.importHistoryTable as TableList;
  }

  get tableDetailRows(): TableRow[] {
    const rows: TableRow[] = this.importRowInfo.detailRows.map((item: UIImportDetailRow) => {
      return {
        row_number: item.row_number?.toString() || '-',
        status: this.$t(item.status as string).toString() || '-',
        error_message: item.error_message || '-',
      };
    });
    return rows;
  }

  get tableDetailConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('row_number')}`,
        field: 'row_number',
        sortable: false,
      },
      { label: `${this.$t('status')}`,
        field: 'status',
        sortable: false,
      },
      { label: `${this.$t('error_messages')}`,
        field: 'error_message',
        sortable: false,
      }
    ];

    return {
      data: this.tableDetailRows,
      columns: tableConfig,
      empty: this.$t('no_history').toString(),
      pagination: true,
      sortOptions: {
        enabled: false
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: DETAIL_PAGE_SIZES,
        defaultPageSize: DETAIL_DEFAULT_PAGE_SIZE,
        setCurrentPage: this.pageNumber,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  get hospitalOptions(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: `${hospital.program_identifier} - ${hospital.hospital_name_info?.name}`,
      };
    });
    return mapped;
  }

  private async loadData(): Promise<void> {
    const params: any = { 
      id: this.selected.id,
      page_number: this.pageNumber,
      page_size: this.perPage,
    };
    if (this.selectedFilters.row_number) params.row_number = this.selectedFilters.row_number;
    if (this.selectedFilters.status) params.status = this.selectedFilters.status;

    this.isLoading = true;
    await this.importRowInfo.load(params);
    this.isLoading = false;
  }

  public async openModal(row: any): Promise<void> {
    this.perPage = DETAIL_DEFAULT_PAGE_SIZE;
    this.selected = row;
    this.toggleModal('detailModal'); // open modal
    this.loadData();
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  private setFilters(filterObject: UIFilterAssignments) {
    this.selectedFilters = filterObject;
    this.loadData();
  }
}
</script>
