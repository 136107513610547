import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "side-nav-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_side_nav_toolbar = _resolveComponent("side-nav-toolbar")!
  const _component_selected_recipient_journey_links = _resolveComponent("selected-recipient-journey-links")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_selected_recipient_profile_links = _resolveComponent("selected-recipient-profile-links")!
  const _component_selected_recipient_hla_links = _resolveComponent("selected-recipient-hla-links")!
  const _component_base_side_nav = _resolveComponent("base-side-nav")!

  return (_openBlock(), _createBlock(_component_base_side_nav, {
    class: _normalizeClass({ closed: _ctx.sideNavClosed })
  }, {
    "side-nav-content": _withCtx(() => [
      _createVNode(_component_side_nav_toolbar, { onToggle: _ctx.toggleSideNav }, {
        "return-link": _withCtx(() => [
          _createElementVNode("a", {
            class: _normalizeClass(`nav-link no-scroll ${_ctx.noHistory ? 'disabled' : ''}`),
            title: "Return to previous page",
            "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.historyBack()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'arrow-left'],
              "fixed-width": ""
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, " Return to previous page ", -1))
          ], 10, _hoisted_1)
        ]),
        _: 1
      }, 8, ["onToggle"]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRecipientJourneysList, (journey) => {
          return (_openBlock(), _createBlock(_component_selected_recipient_journey_links, {
            key: journey.id,
            onToggle: _ctx.toggleSideNav,
            journeyName: journey.name,
            journeyId: journey.id,
            organCode: journey.organCode
          }, null, 8, ["onToggle", "journeyName", "journeyId", "organCode"]))
        }), 128)),
        _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
        _createVNode(_component_selected_recipient_profile_links, { onToggle: _ctx.toggleSideNav }, {
          "profile-return-link": _withCtx(() => [
            _createVNode(_component_router_link, {
              class: "no-scroll open",
              style: {"color":"#2e2e2e !important"},
              to: { name: 'edit-recipient', params: { id: _ctx.recipient.client_id } }
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Profile ")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _: 1
        }, 8, ["onToggle"]),
        _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
        _createVNode(_component_selected_recipient_hla_links, {
          onToggle: _ctx.toggleSideNav,
          active: true
        }, null, 8, ["onToggle"])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}