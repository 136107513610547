import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_follow_up_form = _resolveComponent("follow-up-form")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "post-transplant-follow-up-details",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('title')), 1)
    ]),
    body: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createBlock(_component_sub_section, {
            key: 1,
            title: _ctx.$t('subtitle'),
            "sub-section-id": "post_transplant_follow_up_details"
          }, {
            contents: _withCtx(() => [
              _createVNode(_component_table_toolbar, {
                createButton: _ctx.showCreateButton,
                createText: _ctx.$t('create_follow_up'),
                onTableCreateRow: _ctx.handleTableCreateRow
              }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
              _createVNode(_component_table_list, {
                ref: "postTransplantFollowUpTable",
                "table-id": "post-transplant-follow-up-table",
                tabbableColumn: "entry_date",
                "table-config": _ctx.tableConfig,
                onTableRowClick: _ctx.handleTableRowClick,
                highlightSelection: true,
                isLoading: _ctx.isLoading
              }, null, 8, ["table-config", "onTableRowClick", "isLoading"]),
              _createVNode(_component_follow_up_form, {
                "form-id": "post-transplant-follow-up-form",
                ref: "postTransplantFollowUpForm",
                selection: _ctx.selection,
                onSuccess: _ctx.handleSuccess
              }, null, 8, ["selection", "onSuccess"])
            ]),
            _: 1
          }, 8, ["title"]))
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}