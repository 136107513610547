import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_AutoCompleteInput = _resolveComponent("AutoCompleteInput")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoMedicalHistoryEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "proto-medical-history-section",
        title: _ctx.title
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: true,
            createText: "Add Condition",
            onTableCreateRow: _ctx.clearForm
          }, null, 8, ["onTableCreateRow"]),
          _createVNode(_component_table_list, {
            ref: "conditionsTable",
            tabbableColumn: "calculated_date",
            "table-id": "condition-table",
            "table-config": _ctx.conditionTableConfig,
            onTableRowClick: _ctx.editSelected,
            highlightSelection: true,
            rowStyleClass: _ctx.rowStyleClass
          }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
          _createVNode(_component_form_layout, { "form-id": "condition-form" }, _createSlots({
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                (_ctx.isNew)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_1, " NEW CONDITION "))
                  : (_openBlock(), _createElementBlock("h5", _hoisted_2, " SELECTED CONDITION "))
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveCondition",
                label: "Save Condition",
                cancelButton: true,
                onSave: _ctx.performSave,
                onCancel: _ctx.clearForm
              }, null, 8, ["onSave", "onCancel"])
            ]),
            _: 2
          }, [
            (_ctx.editState)
              ? {
                  name: "contents",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_AutoCompleteInput, {
                          rules: "required",
                          name: "Condition",
                          options: _ctx.getFilteredConditionsOptions,
                          modelValue: _ctx.editState.condition,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.condition) = $event)),
                          inputId: "condition",
                          showDropdown: true,
                          label: "Condition",
                          placeholder: "Select"
                        }, null, 8, ["options", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_text_area_input, {
                          "input-id": "condition-comment",
                          name: "Comment",
                          rows: "4",
                          modelValue: _ctx.editState.comment,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.comment) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1024)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}