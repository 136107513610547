<template>
  <card-section
    section-id="post-transplant-follow-up-details"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('title') }}
    </template>
    <template v-slot:body>
      <template v-if="isLoading">
        {{$t('loading')}}
      </template>
      <template v-else>
        <sub-section
          :title="$t('subtitle')"
          sub-section-id="post_transplant_follow_up_details"
        >
          <template v-slot:contents>

            <table-toolbar
              :createButton="showCreateButton"
              :createText="$t('create_follow_up')"
              @table-create-row="handleTableCreateRow"
            />

            <table-list
              ref="postTransplantFollowUpTable"
              table-id="post-transplant-follow-up-table"
              tabbableColumn="entry_date"
              :table-config="tableConfig"
              @table-row-click="handleTableRowClick"
              :highlightSelection="true"
              :isLoading="isLoading"
            />

            <follow-up-form
              form-id="post-transplant-follow-up-form"
              ref="postTransplantFollowUpForm"
              :selection="selection"
              @success="handleSuccess"
            />
          </template>
        </sub-section>
      </template>
    </template>
  </card-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Prop, mixins } from "vue-facing-decorator";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { i18nMessages } from "@/i18n";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { UIPostTransplantFollowUp } from '@/UIModels/journey/postTransplantFollowUp';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { PostTransplantFollowUpValues } from '@/APIModels/journey/types';
import { TableConfig } from '@/types';
import { GenericCodeValue } from '@/store/types';
import FollowUpForm from '@/components/organs/shared/postTransplantFollowUp/FollowUpForm.vue';
import { SaveResult } from '@/types';

interface TableRow {
  id: string|null;
  entry_date?: string|null;
  follow_up_type?: string|null;
  recipient_status_code?: string|null;
  graft_status_code?: string|null;
  follow_up_date?: string|null;
}

const PAGE_SIZES = [5, 10, 25];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0]; // 5

@Component({
  components: {
    CardSection,
    SubSection,
    FollowUpForm,
    TableToolbar,
    TableList
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/common.json'),
    require('@/components/organs/shared/_locales/PostTransplantFollowUp.json'),
  ]),
  emits: [
    'loaded',
  ],
})

export default class PostTransplantFollowUp extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.follow_up_donor_specific_antibody) private followUpDonorSpecificAntibodyLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_graft_failure_cause) private followUpGraftFailureCauseLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_graft_status) private followUpGraftStatusLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_loss_of_contact_reason) private followUpLossOfContactReasonLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_provider) private followUpProviderLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_recipient_status) private followUpRecipientStatusLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_type) private followUpTypeLookup!: GenericCodeValue[];

  // Lookup tables to be loaded by the CardSection component
  private isLoading = true;

  // Selection instance to provide to child form component
  private selection = new UIListFormSelection();

  get uiJourney(): UIJourney {
    return useCurrentPageStore().currentJourney as UIJourney;
  }

  get showCreateButton(): boolean {
    return this.uiJourney.canSave && !this.currentJourney.isNew;
  }

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = [
    'follow_up_donor_specific_antibody',
    'follow_up_graft_failure_cause',
    'follow_up_graft_rejection_category',
    'follow_up_graft_status',
    'follow_up_loss_of_contact_reason',
    'follow_up_provider',
    'follow_up_recipient_status',
    'follow_up_type',
  ];

  get postTransplantFollowUpTable(): TableList {
    return this.$refs.postTransplantFollowUpTable as TableList;
  }

  // Clear selection
  private handleSuccess(_success: SaveResult): void {
    this.postTransplantFollowUpTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Which Journey view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  get tableRows(): any {
    if (!this.currentRecipient) return [];
    if (!this.currentJourney) return [];

    const uiPostTransplantFollowUps: UIPostTransplantFollowUp[] = this.getPostTransplantFollowUps || [];
    const rows = uiPostTransplantFollowUps.map((uiPostTransplantFollowUp: UIPostTransplantFollowUp): TableRow => {
      const row: TableRow = this.buildRow(uiPostTransplantFollowUp);
      return row;
    });
    return rows;
  }

  // Map from UI Post Transplant Follow Up view model to row interface for this component's table
  private buildRow(uiModel: UIPostTransplantFollowUp): TableRow {
    const row: TableRow = {
      id: uiModel.id || '',
      entry_date: this.parseDisplayDateUi(uiModel.entry_date) || '-',
      follow_up_type: uiModel.follow_up_type == null ? '-' : this.translateFollowUpType(uiModel),
      recipient_status_code: uiModel.recipient_status_code == null ? '-' : this.translateRecipientStatus(uiModel.recipient_status_code),
      graft_status_code: uiModel.graft_status_code == null ? '-' : this.translateGraftStatus(uiModel.graft_status_code),
    };

    // build values
    const lostToFollowUpDate = uiModel.lost_to_follow_up_date ? this.parseDisplayDateUi(uiModel.lost_to_follow_up_date) : '-';
    const followUpDate = uiModel.follow_up_date ? this.parseDisplayDateUi(uiModel.follow_up_date) : '-';

    // determine which value to show
    row.follow_up_date = uiModel.lost_to_follow_up_reason ? lostToFollowUpDate : followUpDate;

    return row;
  }

  private translateFollowUpType(uiModel: UIPostTransplantFollowUp): string {
    const lookupTable = this.followUpTypeLookup || null;
    const followUpType = uiModel.follow_up_type || null;
    if (!lookupTable) return '-';

    if (!followUpType) return '-';
   
    const found = this.followUpTypeLookup.find((item: any) => { return followUpType === item.code; });
    if (found) {
      return uiModel.follow_up_type === PostTransplantFollowUpValues.Annual ? `${found.value} (${this.$t('year')} ${uiModel.years_since_transplant})` : found.value;
    } else {
      return '-';
    }
  }

  private translateRecipientStatus(value: string|null): string {
    const lookupTable = this.followUpRecipientStatusLookup || null;
    if (!lookupTable) return '-';
    if (!value) return '-';
    
    const found = this.followUpRecipientStatusLookup.find((item: any) => { return value === item.code; });
    return found ? found.value : '-';
  }

  private translateGraftStatus(value: string|null): string {
    const lookupTable = this.followUpGraftStatusLookup || null;
    if (!lookupTable) return '-';
    if (!value) return '-';
    
    const found = this.followUpGraftStatusLookup.find((item: any) => { return value === item.code; });
    return found ? found.value : '-';
  }

  // Configure the list table
  get tableConfig(): TableConfig {
    return {
      // if no records to show, don't show any viruses
      data: this.tableRows.length > 0 ? this.tableRows : [],
      columns: [
        { label: this.$t('entry_date').toString(), field: 'entry_date' },
        { label: this.$t('follow_up_type').toString(), field: 'follow_up_type' },
        { label: this.$t('recipient_status_code').toString(), field: 'recipient_status_code' },
        { label: this.$t('graft_status_code').toString(), field: 'graft_status_code' },
        { label: this.$t('date_last_seen_table').toString(), field: 'follow_up_date' },
      ],
      empty: this.showCreateButton ? this.$t('new_result_text').toString() : this.$t('no_results_text').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        position: 'bottom'
      }
    };
  }

  get getPostTransplantFollowUps(): UIPostTransplantFollowUp[] {
    return this.currentJourney.postTransplantFollowUps || [];
  }

  private handleTableCreateRow(): void {
    this.postTransplantFollowUpTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  /**
     * Event handle run when clicking on the edit button on a row in the post transplant follow up table.
     */
  handleTableRowClick(event: { row: TableRow }): void {
    const listItem = this.getPostTransplantFollowUps.find((item: UIPostTransplantFollowUp) => { return item.id === event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    if (!this.currentRecipient) return;
    if (!this.currentJourney) return;

    try {
      this.currentJourney.postTransplantFollowUps = await UIPostTransplantFollowUp.loadFor(this.currentJourney);
      this.isLoading = false;
    } catch(err) {
      this.isLoading = false;
      console.warn(err, 'Something unexpected happen when attempting to get Post Transplant Follow Up information');
    }
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recipientDeath#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.isLoading = false;
    this.$emit('loaded', 'post_transplant_follow_ups');
  }
}
</script>
