<template>
  <card-section
    section-id="contactinfo"
    :saveButtonText="$t('save_contact_info')"
    ref="saveContactInfo"
    :lookupsToLoad="lookupsToLoad"
    :saveButton="!newLivingDonor"
    :disabled="!canSave"
    @save="savePatch(false)"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('contact_info')}}
    </template>
    <template v-slot:body v-if="!editState">
      {{$t('loading')}}
    </template>
    <template v-slot:body v-else>
      <sub-section
        :title="$t('permanent_address')"
        sub-section-id="contactinfo-permanent"
      >
        <template v-slot:contents v-if="editState.permanent">
          <fieldset :disabled="!canSave">
            <div class="row">
              <div class="standard-form-group-with-other">
                <country-input
                  ruleKey="patient_profile.addresses.country_code"
                  select-id="contactinfo-country"
                  :name="$t('country')"
                  v-model="editState.permanent.countryDropdown"
                  :options="countryLookup"
                  @change="clearProvince"
                >
                  <template v-slot:province>
                    <select-input
                      ruleKey="patient_profile.addresses.province_code"
                      select-id="contactinfo-province"
                      :name="$t('province')"
                      validation-id="province-state"
                      v-model="editState.permanent.province"
                      :options="provinceLookup"
                    />
                  </template>
                  <template v-slot:state>
                    <select-input
                      select-id="contactinfo-state"
                      :name="$t('state')"
                      validation-id="province-state"
                      v-model="editState.permanent.state"
                      :options="usStateLookup"
                    />
                  </template>
                  <template v-slot:other>
                    <text-input
                      ruleKey="patient_profile.addresses.country_other"
                      input-id="contactinfo-othercountry"
                      rules="required"
                      :name="$t('other_country')"
                      v-model="editState.permanent.countryOther"
                    />
                  </template>
                </country-input>
              </div>
            </div>
            <div class="row">
              <div class="standard-form-group-large">
                <text-input
                  ruleKey="patient_profile.addresses.street"
                  inputId="contactinfo-street"
                  :name="$t('street_address')"
                  v-model="editState.permanent.street"
                />
              </div>
              <div class="standard-form-group">
                <text-input
                  ruleKey="patient_profile.addresses.city"
                  inputId="contactinfo-city"
                  :name="$t('city')"
                  v-model="editState.permanent.city"
                />
              </div>
              <div class="standard-form-group">
                <text-input
                  ruleKey="patient_profile.addresses.postal_code"
                  inputId="contactinfo-postalcode"
                  :name="getPostalLabelText"
                  v-model="editState.permanent.postalCode"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </sub-section>
      <sub-section
        :title="$t('living_donor_contact_details')"
        sub-section-id="contactinfo-details"
      >
        <template v-slot:contents v-if="editState.contactDetails">
          <fieldset :disabled="!canSave">
            <div class="row">
              <div class="standard-form-group">
                <text-input
                  ruleKey="patient_profile.contact_details.phone_mobile"
                  inputId="contactinfo-details-mobilephone"
                  :name="$t('mobile_phone_num')"
                  :mask="getTelephoneMask"
                  v-model="editState.contactDetails.mobilePhone"
                />
              </div>
              <div class="standard-form-group">
                <text-input
                  ruleKey="patient_profile.contact_details.phone_primary"
                  inputId="contactinfo-details-homephone"
                  :name="$t('home_phone_num')"
                  :mask="getTelephoneMask"
                  v-model="editState.contactDetails.homePhone"
                />
              </div>
              <div class="standard-form-group">
                <text-input
                  ruleKey="patient_profile.contact_details.email"
                  inputId="contactinfo-details-email"
                  :name="$t('email')"
                  v-model="editState.contactDetails.email"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/livingDonors/_locales/ContactInformation.json"></i18n>
<i18n src="@/components/livingDonors/_locales/common.json"></i18n>

<script lang="ts">
import {parsePhoneUi} from '@/utils';
import {Getter, State} from "vuex-facing-decorator";
import {Component, Prop, Vue, Watch} from "vue-facing-decorator";
import { IdLookup } from '@/store/validations/types';
import {SaveableSection, SaveProvider, SaveResult} from "@/types";
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CountryInput from '@/components/shared/CountryInput.vue';
import {LivingDonor, LivingDonorAddress} from '@/store/livingDonors/types';
import {Country, CountryValue, Province} from '@/store/lookups/types';

interface ContactInformationForm {
  permanent?: FormAddress;
  contactDetails?: {
    mobilePhone?: string|null;
    homePhone?: string|null;
    email?: string|null;
  };
}

interface FormAddress {
  _id?: string;
  countryDropdown?: string|null;
  countryOther?: string|null;
  province?: string|null;
  state?: string|null;
  street?: string;
  city?: string;
  postalCode?: string;
}

const ADDRESS_PERMANENT = 'permanent';
const ADDRESS_LOCAL = 'local';

@Component({
  components: {
    TextInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    CountryInput,
  }
})
export default class ContactInformation extends Vue implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.contactInfo) editState!: ContactInformationForm;
  @State(state => state.lookups.country) countryLookup!: Country[];
  @State(state => state.lookups.us_state) usStateLookup!: Province[];
  @State(state => state.lookups.province) provinceLookup!: Province[];

  // Properties
  @Prop({ default: false }) newLivingDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('show', { namespace: 'livingDonors' }) private livingDonor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('currentPage', { namespace: 'pageState' }) private currentPage!: any;
  @Getter('permanentAddress', { namespace: 'livingDonors' }) private permanent?: LivingDonorAddress;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('getTelephoneMask', { namespace: 'utilities' }) getTelephoneMask!: string;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['country'];

  get getPostalLabelText(): string {
    const countryCode = this.editState.permanent ? this.editState.permanent.countryDropdown : null;
    if (countryCode == CountryValue.USA) {
      return this.$t('zip_code').toString();
    }
    return this.$t('postal_code').toString();
  }

  // Triggered when all the lookups have been loaded
  public loaded(): void {
    this.$emit('loaded', 'contactInfo');
    this.initializeForm();
  }

  // Initialize the form after the page mounts
  public mounted(): void {
    // this.initializeForm();
  }

  // Initialize the form
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'contactInfo',
      value: this.buildContactInformationForm(this.livingDonor)
    });
  }

  // Translate from relevant parts of the livingDonor data structure to the form layout
  public buildContactInformationForm(livingDonor: LivingDonor): ContactInformationForm {
    const livingDonorProfile = livingDonor.patient_profile || {};
    // Ontario address values MUST be 'CAN' and 'ON' or the API will reject it
    return {
      permanent: this.buildAddressForm(this.permanent),
      contactDetails: {
        mobilePhone: parsePhoneUi(livingDonorProfile.contact_details?.phone_mobile) || null,
        homePhone: parsePhoneUi(livingDonorProfile.contact_details?.phone_primary) || null,
        email: livingDonorProfile.contact_details?.email || null
      },
    };
  }

   // Translate LivingDonor address data to our form structure
  public buildAddressForm(address: LivingDonorAddress|undefined): FormAddress|undefined {
    // Initialize empty FormAddress
    const sanitizedAddress: FormAddress = {
      countryDropdown: this.defaultLookup('country'),
      province: this.defaultLookup('province'),
    };
    // Copy values from the address into our sanitizedAddress if they have one
    if (address) {
      sanitizedAddress._id = address._id;
      sanitizedAddress.countryDropdown = address.country_code || sanitizedAddress.countryDropdown;
      sanitizedAddress.countryOther = address.country_other;
      sanitizedAddress.postalCode = address.postal_code;
      sanitizedAddress.state = address.province_code || sanitizedAddress.province;
      sanitizedAddress.province = address.province_code;
      sanitizedAddress.street = address.street;
      sanitizedAddress.city = address.city;
    }
    return sanitizedAddress;
  }

  // Translate the form structure into the LivingDonor data structure
  public extractPatch(potential_duplicate_profile_confirmed?: boolean): LivingDonor {
    const updatedProfile = {
      addresses: [] as LivingDonorAddress[],
      contact_details: {
        email: this.editState.contactDetails!.email,
        phone_alternate: null,
        phone_mobile: this.editState.contactDetails!.mobilePhone,
        phone_primary: this.editState.contactDetails!.homePhone
      }
    };
    const permanentAddress = this.extractAddressPatch(this.editState.permanent, ADDRESS_PERMANENT);

    if (permanentAddress) {
      updatedProfile.addresses.push(permanentAddress);
    }
    return {
      _id: this.livingDonor._id,
      patient_profile: updatedProfile,
      potential_duplicate_profile_confirmed: potential_duplicate_profile_confirmed || false
    };
  }

  // Translate form data into the Living Donor data structure
  public extractAddressPatch(address?: FormAddress, addressType?: string): LivingDonorAddress|undefined {
    if (!address || !addressType) {
      return undefined;
    }
    const countryCode = address.countryDropdown;
    let provinceCode: string|null = null;
    let countryOther: string|null = null;
    if (countryCode == CountryValue.Canada) {
      provinceCode = address.province || null;
      countryOther = null;
    } else if (countryCode == CountryValue.USA) {
      provinceCode = address.state || null;
      countryOther = null;
    } else if (countryCode == CountryValue.Other) {
      provinceCode = null;
      countryOther = address.countryOther || null;
    }
    return {
      _id: address._id,
      type: addressType,
      city: address.city,
      street: address.street,
      province_code: provinceCode,
      country_code: address.countryDropdown,
      country_other: countryOther,
      postal_code: address.postalCode,
    };
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveContactInfo as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveContactInfo as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'contactInfo');
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractPatch(potential_duplicate_profile_confirmed);
    // Dispatch save action and register the response
    this.$store
      .dispatch('livingDonors/saveLivingDonor', {
        livingDonorId: this.livingDonorId,
        livingDonor: livingDonorPatch
      }).then((success: SaveResult) => {
        // If successful, update the current livingDonor and show success notification
        this.$store.commit('livingDonors/set', success.responseData.living_donor);
        saveProvider.registerSaveResult(success);
        this.initializeForm();
      }).catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      });
  }

  // Clear state and province on country change
  public clearProvince() {
    if (this.editState.permanent) {
      this.editState.permanent.state = null;
      this.editState.permanent.province = null;
    }
  }

  // API response keys on the left, id for our UI on the right
  public get idLookup(): IdLookup {
    // Constant mapping
    return {
      'patient_profile.addresses[permanent].country_code': 'contactinfo-country',
      'patient_profile.addresses[permanent].country_other': 'contactinfo-othercountry',
      'patient_profile.addresses[permanent].province_code': 'province-state',
      'patient_profile.addresses[permanent].street': 'contactinfo-street',
      'patient_profile.addresses[permanent].city': 'contactinfo-city',
      'patient_profile.addresses[permanent].postal_code': 'contactinfo-postalcode',
      'patient_profile.contact_details.phone_primary': 'contactinfo-details-homephone',
      'patient_profile.contact_details.phone_mobile': 'contactinfo-details-mobilephone',
      'patient_profile.contact_details.email': 'contactinfo-details-email',
    };
  }

}
</script>
