import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_4 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_5 = { class: "standard-form-group-large" }
const _hoisted_6 = {
  key: 0,
  class: "standard-form-group-large"
}
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_9 = {
  key: 2,
  class: "standard-form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("fieldset", null, [
    (_ctx.editState)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_select_input, {
              ruleKey: "patient_address.country_code",
              selectId: "address-country",
              name: _ctx.$t('address_field.country'),
              modelValue: _ctx.editState.countryCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.countryCode) = $event)),
              options: _ctx.countryOptions,
              onChange: _ctx.handleCountryChanged,
              filterByLookupRule: true
            }, null, 8, ["name", "modelValue", "options", "onChange"])
          ]),
          (_ctx.editState.countryCode === _ctx.getCountryValue.USA)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_select_input, {
                  ruleKey: "patient_address.province_code",
                  selectId: "address-state",
                  name: _ctx.$t('address_field.state'),
                  validationId: "address-state",
                  modelValue: _ctx.editState.stateCode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.stateCode) = $event)),
                  options: _ctx.stateOptions,
                  filterByLookupRule: true
                }, null, 8, ["name", "modelValue", "options"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.editState.countryCode === _ctx.getCountryValue.Other)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_text_input, {
                  ruleKey: "patient_address.country_other",
                  inputId: "address-othercountry",
                  name: _ctx.$t('address_field.other_country'),
                  modelValue: _ctx.editState.countryOther,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.countryOther) = $event))
                }, null, 8, ["name", "modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.editState.countryCode)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "row-break d-none d-lg-block d-xxl-none" }, null, -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_text_input, {
                    ruleKey: "patient_address.street",
                    inputId: "address-street",
                    name: _ctx.$t('address_field.street_address'),
                    modelValue: _ctx.editState.streetAddress,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.streetAddress) = $event))
                  }, null, 8, ["name", "modelValue"])
                ]),
                (_ctx.editState.showUrbanization)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_text_input, {
                        ruleKey: "patient_address.urbanization",
                        inputId: "address-urbanization",
                        name: _ctx.$t('address_field.urbanization'),
                        modelValue: _ctx.editState.urbanization,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.urbanization) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_text_input, {
                    ruleKey: "patient_address.city",
                    inputId: "address-city",
                    name: _ctx.$t('address_field.city'),
                    modelValue: _ctx.editState.city,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.city) = $event))
                  }, null, 8, ["name", "modelValue"])
                ]),
                (_ctx.editState.showPostalCode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_text_input, {
                        ruleKey: "patient_address.postal_code",
                        inputId: "address-postalcode",
                        name: _ctx.$t('address_field.postal_code'),
                        modelValue: _ctx.editState.postalCode,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.postalCode) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]))
                  : (_ctx.editState.showZipCode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_text_input, {
                          ruleKey: "patient_address.postal_code",
                          inputId: "address-zipcode",
                          name: _ctx.$t('address_field.zip_code'),
                          modelValue: _ctx.editState.zipCode,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.zipCode) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}