import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["disabled", "title"]
const _hoisted_2 = { class: "text-flat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_allocation_controls = _resolveComponent("allocation-controls")!
  const _component_allocation_details = _resolveComponent("allocation-details")!
  const _component_donor_checklist = _resolveComponent("donor-checklist")!
  const _component_allocation_recommendation_listing = _resolveComponent("allocation-recommendation-listing")!
  const _component_allocation_offer_response = _resolveComponent("allocation-offer-response")!
  const _component_allocation_audit_messages = _resolveComponent("allocation-audit-messages")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-allocation-eoffer",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loaded())),
    "show-actions": _ctx.showAuditMessages
  }, {
    actions: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-sm btn-default",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openAuditMessagesModal && _ctx.openAuditMessagesModal(...args))),
        disabled: !_ctx.enableAuditMessages,
        title: _ctx.$t('view_allocation_audit_messages_hover')
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['far', 'file-chart-line'],
          class: "mr-2"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('view_allocation_audit_messages_button')), 1)
      ], 8, _hoisted_1)
    ]),
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('allocation_e_offer')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_allocation_controls, {
        ref: "allocationControls",
        onReloadAllocations: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reloadAllocations())),
        onDisplayOutcomeNotification: _ctx.displayOutcomeNotification
      }, null, 8, ["onDisplayOutcomeNotification"]),
      (!_ctx.isLoadingAllocations && _ctx.allocation && !_ctx.isLoadingAllocation && !_ctx.isInitializing && _ctx.showAllocation)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_allocation_details, {
              ref: "allocationDetails",
              onDisplayOutcomeNotification: _ctx.displayOutcomeNotification
            }, null, 8, ["onDisplayOutcomeNotification"]),
            (_ctx.canReadChecklists)
              ? (_openBlock(), _createBlock(_component_donor_checklist, {
                  key: 0,
                  canSave: _ctx.canWriteChecklists
                }, null, 8, ["canSave"]))
              : _createCommentVNode("", true),
            _createVNode(_component_allocation_recommendation_listing, {
              ref: "allocationRecommendationListings",
              onDisplayOutcomeNotification: _ctx.displayOutcomeNotification,
              onReload: _ctx.reload
            }, null, 8, ["onDisplayOutcomeNotification", "onReload"]),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            (_ctx.allPrimaryBackupOffers.length > 0)
              ? (_openBlock(), _createBlock(_component_allocation_offer_response, {
                  key: 1,
                  ref: "allocationOffers",
                  onDisplayOutcomeNotification: _ctx.displayOutcomeNotification,
                  onReload: _ctx.reload
                }, null, 8, ["onDisplayOutcomeNotification", "onReload"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_allocation_audit_messages, { ref: "allocationAuditMessages" }, null, 512)
    ]),
    _: 1
  }, 8, ["lookupsToLoad", "show-actions"]))
}