import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "alert alert-danger mt-2"
}
const _hoisted_3 = {
  key: 2,
  class: "hr-break"
}
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = { class: "form-group col-sm-4" }
const _hoisted_6 = { class: "form-group col-sm-4" }
const _hoisted_7 = { class: "form-group col-sm-4" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "discontinue-modal",
    ref: "discontinueModal",
    class: "modal-sticky-header",
    onHide: _cache[8] || (_cache[8] = (options) => _ctx.modalEvent(options)),
    centered: true,
    closeButton: false
  }, _createSlots({
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('discontinue_offer_to_selected_recipients')), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-footer-body", { 'justify-content-end': _ctx.errorState }])
      }, [
        (!_ctx.errorState)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-secondary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeModal())),
              disabled: _ctx.isLoadingAllocation
            }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_8))
          : _createCommentVNode("", true),
        (!_ctx.errorState)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-success",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.discontinueOffer())),
              disabled: _ctx.isLoadingAllocation || _ctx.isDiscontinuingOneOffer
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('discontinue_offer')) + " ", 1),
              (_ctx.isDiscontinuingOneOffer)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-spin",
                      icon: ['far', 'spinner-third']
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_9))
          : (_openBlock(), _createElementBlock("button", {
              key: 2,
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-success",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeModal()))
            }, _toDisplayString(_ctx.$t('ok')), 1))
      ], 2)
    ]),
    _: 2
  }, [
    (!_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ]),
          key: "0"
        }
      : {
          name: "body",
          fn: _withCtx(() => [
            (!_ctx.errorState)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                  _createTextVNode(_toDisplayString(_ctx.$t('select_discontinue_details_recipient')), 1),
                  _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (!!_ctx.modalErrorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.modalErrorMessage), 1))
              : _createCommentVNode("", true),
            (!_ctx.errorState)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
            _createVNode(_component_validation_observer, {
              ref: "discontinueValidations",
              autocomplete: "off",
              tag: "form"
            }, {
              default: _withCtx(() => [
                (!_ctx.errorState)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_select_input, {
                          selectId: "discontinue-type",
                          name: _ctx.$t('response_type'),
                          rules: "required",
                          modelValue: _ctx.editState.type,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.type) = $event)),
                          options: _ctx.responseOptions,
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearStateValues(['reason', 'reasonCategory'])))
                        }, null, 8, ["name", "modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_select_input, {
                          "select-id": "discontinue-reason-category",
                          name: _ctx.$t('reason_category'),
                          rules: "required",
                          modelValue: _ctx.editState.reasonCategory,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.reasonCategory) = $event)),
                          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearStateValues(['reason']))),
                          options: _ctx.reasonCategoryOptions
                        }, null, 8, ["name", "modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_select_input, {
                          "select-id": "discontinue-reason",
                          name: _ctx.$t('reason'),
                          rules: "required",
                          modelValue: _ctx.editState.reason,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.reason) = $event)),
                          options: _ctx.reasonOptions
                        }, null, 8, ["name", "modelValue", "options"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 512)
          ]),
          key: "1"
        }
  ]), 1536))
}