import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  autocomplete: "off"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_recipient_sections = _resolveComponent("loading-recipient-sections")!
  const _component_organ_details_section = _resolveComponent("organ-details-section")!
  const _component_referral_section = _resolveComponent("referral-section")!
  const _component_clinical_notes_section = _resolveComponent("clinical-notes-section")!
  const _component_checklist_section = _resolveComponent("checklist-section")!
  const _component_proto_checklists_reorder = _resolveComponent("proto-checklists-reorder")!
  const _component_proto_liver_specific_details = _resolveComponent("proto-liver-specific-details")!
  const _component_donor_acceptability_details = _resolveComponent("donor-acceptability-details")!
  const _component_transplant_details = _resolveComponent("transplant-details")!
  const _component_post_transplant_follow_up = _resolveComponent("post-transplant-follow-up")!

  return (!_ctx.isLoaded)
    ? (_openBlock(), _createBlock(_component_loading_recipient_sections, { key: 0 }))
    : (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createVNode(_component_organ_details_section, {
          ref: "organDetailsSection",
          "new-journey": _ctx.newJourney,
          onLoaded: _cache[0] || (_cache[0] = (ref) => _ctx.loaded(ref))
        }, null, 8, ["new-journey"]),
        (_ctx.isReferralDetailsEnabled && !_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_referral_section, {
              key: 0,
              ref: "referralSection",
              "new-journey": _ctx.newJourney,
              onLoaded: _cache[1] || (_cache[1] = (ref) => _ctx.loaded(ref))
            }, null, 8, ["new-journey"]))
          : _createCommentVNode("", true),
        (_ctx.isClinicalNotesEnabled && !_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_clinical_notes_section, {
              key: 1,
              ref: "clinicalNotesSection",
              "new-journey": _ctx.newJourney,
              onLoaded: _cache[2] || (_cache[2] = (ref) => _ctx.loaded(ref))
            }, null, 8, ["new-journey"]))
          : _createCommentVNode("", true),
        (_ctx.isChecklistsEnabled && !_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_checklist_section, {
              key: 2,
              ref: "checklistSection",
              "new-journey": _ctx.newJourney,
              onLoaded: _cache[3] || (_cache[3] = (ref) => _ctx.loaded(ref))
            }, null, 8, ["new-journey"]))
          : _createCommentVNode("", true),
        (!_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_proto_checklists_reorder, { key: 3 }))
          : _createCommentVNode("", true),
        _createVNode(_component_proto_liver_specific_details),
        (_ctx.isDonorAcceptabilityEnabled && !_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_donor_acceptability_details, {
              key: 4,
              ref: "donorAcceptabilityDetails",
              "new-journey": _ctx.newJourney,
              onLoaded: _cache[4] || (_cache[4] = (ref) => _ctx.loaded(ref))
            }, null, 8, ["new-journey"]))
          : _createCommentVNode("", true),
        (_ctx.isTransplantDetailsEnabled && !_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_transplant_details, {
              key: 5,
              ref: "transplantDetails",
              "new-journey": _ctx.newJourney,
              onLoaded: _cache[5] || (_cache[5] = (ref) => _ctx.loaded(ref))
            }, null, 8, ["new-journey"]))
          : _createCommentVNode("", true),
        (_ctx.isPostTransplantFollowUpEnabled && !_ctx.newJourney)
          ? (_openBlock(), _createBlock(_component_post_transplant_follow_up, {
              key: 6,
              ref: "postTransplantFollowUp",
              onLoaded: _cache[6] || (_cache[6] = (ref) => _ctx.loaded(ref))
            }, null, 512))
          : _createCommentVNode("", true)
      ]))
}