import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary mr-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "override-mapping-modal",
    ref: "overrideMappingModal",
    class: "modal-sticky-header",
    modalClass: "modal-content modal-danger",
    centered: true,
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.finishConfirm()))
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('hla_typing_override_mapping_heading')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('hla_typing_override_mapping_warning')), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('hla_typing_override_mapping_explanation')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('hla_typing_override_mapping_note_about_molecular')), 1)
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "row" }, [
        _createElementVNode("div", { class: "col-md-12" }, [
          _createElementVNode("div", { class: "table-responsive" })
        ])
      ], -1))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t('cancel')), 1),
      _createElementVNode("a", {
        class: "btn btn-success",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.beginConfirm()))
      }, _toDisplayString(_ctx.$t('continue')), 1)
    ]),
    _: 1
  }, 512))
}