import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "side-nav-container" }
const _hoisted_2 = { class: "nav-block-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "nav nav-block flex-column active"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = {
  key: 0,
  class: "nav flex-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_side_nav_toolbar = _resolveComponent("side-nav-toolbar")!
  const _component_user_links = _resolveComponent("user-links")!
  const _component_base_side_nav = _resolveComponent("base-side-nav")!

  return (_openBlock(), _createBlock(_component_base_side_nav, {
    class: _normalizeClass({ closed: _ctx.sideNavClosed })
  }, {
    toolbar: _withCtx(() => _cache[1] || (_cache[1] = [])),
    "side-nav-content": _withCtx(() => [
      _createVNode(_component_side_nav_toolbar, { onToggle: _ctx.toggleSideNav }, {
        "return-link": _withCtx(() => [
          _createVNode(_component_router_link, {
            class: "nav-link no-scroll",
            title: "Return to previous page",
            "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
            to: { name: 'administration' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'arrow-left'],
                "fixed-width": ""
              }),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, " Return to previous page ", -1))
            ]),
            _: 1
          }, 8, ["aria-label"])
        ]),
        _: 1
      }, 8, ["onToggle"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_user_links),
          _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
          (_ctx.checkAllowed('/services/ctr/integration_log', 'GET'))
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                _createElementVNode("li", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    class: "nav-link open",
                    to: { name: 'ctrIntegration' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                        class: "nav-caret",
                        "fixed-width": "",
                        onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
                      }, null, 8, ["icon", "onClick"]),
                      _cache[3] || (_cache[3] = _createTextVNode(" CTR Integration "))
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.show)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                      _createElementVNode("li", {
                        class: "nav-item",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickToggle && _ctx.clickToggle(...args)))
                      }, [
                        _createVNode(_component_router_link, {
                          class: "nav-link",
                          to: { hash: '#administration-ctr' }
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" CTR Event Log ")
                          ])),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}