import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row d-flex justify-content-between" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group-large d-block d-md-flex flex-md-column" }
const _hoisted_4 = {
  key: 0,
  class: "label"
}
const _hoisted_5 = { class: "standard-form-group-large ml-auto align-self-end" }
const _hoisted_6 = { class: "p-datatable p-datatable-custom scrollbox mt-3" }
const _hoisted_7 = { class: "p-datatable-table-container" }
const _hoisted_8 = {
  class: "p-datatable-table",
  role: "table"
}
const _hoisted_9 = {
  class: "p-datatable-thead",
  role: "rowgroup"
}
const _hoisted_10 = {
  style: {"width":"255px","min-width":"255px","overflow":"hidden","text-overflow":"ellipsis"},
  class: "p-datatable-header-cell highlight-yellow-bg",
  role: "columnheader",
  colspan: "2"
}
const _hoisted_11 = { class: "p-datatable-column-header-content" }
const _hoisted_12 = {
  class: "p-datatable-header-cell highlight-blue-bg",
  style: {"width":"255px","min-width":"255px","overflow":"hidden","text-overflow":"ellipsis"},
  colspan: "2",
  role: "columnheader"
}
const _hoisted_13 = { class: "p-datatable-column-header-content" }
const _hoisted_14 = { class: "p-datatable-tbody" }
const _hoisted_15 = {
  class: "",
  tabindex: "0",
  role: "row"
}
const _hoisted_16 = { role: "cell" }
const _hoisted_17 = { role: "cell" }
const _hoisted_18 = { role: "cell" }
const _hoisted_19 = {
  class: "",
  tabindex: "0",
  role: "row"
}
const _hoisted_20 = { role: "cell" }
const _hoisted_21 = { role: "cell" }
const _hoisted_22 = { role: "cell" }
const _hoisted_23 = { role: "cell" }
const _hoisted_24 = { role: "cell" }
const _hoisted_25 = {
  class: "",
  tabindex: "0",
  role: "row"
}
const _hoisted_26 = { role: "cell" }
const _hoisted_27 = { role: "cell" }
const _hoisted_28 = { role: "cell" }
const _hoisted_29 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_30 = { role: "cell" }
const _hoisted_31 = { role: "cell" }
const _hoisted_32 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_33 = { class: "row" }
const _hoisted_34 = { class: "standard-comment-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_group = _resolveComponent("checkbox-group")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-acceptability-criteria",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[26] || (_cache[26] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('title')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('subtitle'),
        "sub-section-id": "sub_donor_acceptability_criteria"
      }, {
        contents: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createBlock(_component_validation_observer, {
                key: 1,
                ref: "validations"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_form_layout, {
                    disabled: !_ctx.enableForm,
                    "form-id": "donor-acceptability-details-form"
                  }, _createSlots({
                    contents: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_checkbox_group, {
                            ruleKey: "donor_acceptability.accept_donor_types",
                            inputId: "accept_donor_types",
                            name: _ctx.$t('preferred_donor_type'),
                            modelValue: _ctx.editState.accept_donor_types,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.accept_donor_types) = $event)),
                            options: _ctx.currentJourney.applicableDonorTypes
                          }, null, 8, ["name", "modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.accept_criteria_conditions, (condition, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "justify-column-between d-flex align-items-center",
                              key: index
                            }, [
                              (!_ctx.isMobile)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`${_ctx.getDonorAcceptabilityCriteriaValue(condition.donor_condition)}`), 1))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_SelectInput, {
                                  ruleKey: "donor_acceptability.accept_criteria_conditions",
                                  selectId: `${condition.donor_condition}`,
                                  name: `${_ctx.getDonorAcceptabilityCriteriaValue(condition.donor_condition)}`,
                                  hideLabel: !_ctx.isMobile,
                                  modelValue: _ctx.editState.accept_criteria_conditions[`${index}`].acceptable,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.editState.accept_criteria_conditions[`${index}`].acceptable) = $event),
                                  options: _ctx.getAcceptDeclineLookup
                                }, null, 8, ["selectId", "name", "hideLabel", "modelValue", "onUpdate:modelValue", "options"])
                              ])
                            ]))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "p-datatable-custom-top" }, null, -1)),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("table", _hoisted_8, [
                            _createElementVNode("thead", _hoisted_9, [
                              _createElementVNode("tr", null, [
                                _cache[27] || (_cache[27] = _createElementVNode("th", {
                                  style: {"width":"80px","min-width":"80px","max-width":"80px","overflow":"hidden","text-overflow":"ellipsis"},
                                  class: "p-datatable-header-cell",
                                  role: "columnheader"
                                }, null, -1)),
                                _createElementVNode("th", _hoisted_10, [
                                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('min_requirements')), 1)
                                ]),
                                _createElementVNode("th", _hoisted_12, [
                                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('max_requirements')), 1)
                                ])
                              ])
                            ]),
                            _createElementVNode("tbody", _hoisted_14, [
                              _createElementVNode("tr", _hoisted_15, [
                                _createElementVNode("td", _hoisted_16, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("age")), 1)
                                ]),
                                _createElementVNode("td", _hoisted_17, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.min_age_yrs",
                                      inputId: "min_age_yrs",
                                      name: _ctx.$t('min_age_yrs'),
                                      append: true,
                                      appendText: _ctx.$t('yr'),
                                      hideLabel: true,
                                      modelValue: _ctx.editState.min_age_yrs,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.min_age_yrs) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _cache[28] || (_cache[28] = _createElementVNode("td", { role: "cell" }, null, -1)),
                                _createElementVNode("td", _hoisted_18, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.max_age_yrs",
                                      inputId: "max_age_yrs",
                                      name: _ctx.$t('max_age_yrs'),
                                      hideLabel: true,
                                      append: true,
                                      appendText: _ctx.$t('yr'),
                                      modelValue: _ctx.editState.max_age_yrs,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.max_age_yrs) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _cache[29] || (_cache[29] = _createElementVNode("td", { role: "cell" }, null, -1))
                              ]),
                              _createElementVNode("tr", _hoisted_19, [
                                _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.$t("weight")), 1),
                                _createElementVNode("td", _hoisted_21, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.min_weight_kg",
                                      inputId: "min_weight_kg",
                                      name: _ctx.$t('min_weight_kg'),
                                      append: true,
                                      appendText: _ctx.$t('kg'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromKg($event?.target?.value, 'min')))),
                                      modelValue: _ctx.editState.min_weight_kg,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.min_weight_kg) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_22, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.min_weight_lb",
                                      inputId: "min_weight_lb",
                                      name: _ctx.$t('min_weight_lb'),
                                      append: true,
                                      appendText: _ctx.$t('lb'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromLb($event?.target?.value, 'min')))),
                                      modelValue: _ctx.editState.min_weight_lb,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.min_weight_lb) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_23, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.max_weight_kg",
                                      inputId: "max_weight_kg",
                                      name: _ctx.$t('max_weight_kg'),
                                      append: true,
                                      appendText: _ctx.$t('kg'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromKg($event?.target?.value, 'max')))),
                                      modelValue: _ctx.editState.max_weight_kg,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.max_weight_kg) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_24, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.max_weight_lb",
                                      inputId: "max_weight_lb",
                                      name: _ctx.$t('max_weight_lb'),
                                      append: true,
                                      appendText: _ctx.$t('lb'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromLb($event?.target?.value, 'max')))),
                                      modelValue: _ctx.editState.max_weight_lb,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.max_weight_lb) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ])
                              ]),
                              _createElementVNode("tr", _hoisted_25, [
                                _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.$t("height")), 1),
                                _createElementVNode("td", _hoisted_27, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.min_height_cm",
                                      inputId: "min_height_cm",
                                      name: _ctx.$t('min_height_cm'),
                                      append: true,
                                      appendText: _ctx.$t('cm'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[11] || (_cache[11] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromCm($event?.target?.value, 'min')))),
                                      modelValue: _ctx.editState.min_height_cm,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.min_height_cm) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_28, [
                                  _createElementVNode("span", _hoisted_29, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.min_height_ft",
                                      inputId: "min_height_ft",
                                      name: _ctx.$t('min_height_ft'),
                                      append: true,
                                      inputClass: "mr-xl-2",
                                      appendText: _ctx.$t('ft'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromFeet($event?.target?.value, _ctx.editState.min_height_in, 'min')))),
                                      modelValue: _ctx.editState.min_height_ft,
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.min_height_ft) = $event)),
                                      integer: true
                                    }, null, 8, ["name", "appendText", "modelValue"]),
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.min_height_in",
                                      inputId: "min_height_in",
                                      name: _ctx.$t('min_height_in'),
                                      append: true,
                                      inputClass: "ml-xl-2",
                                      appendText: _ctx.$t('in'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromInches(_ctx.editState.min_height_ft, $event?.target?.value, 'min')))),
                                      modelValue: _ctx.editState.min_height_in,
                                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.min_height_in) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_30, [
                                  _createElementVNode("span", null, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.max_height_cm",
                                      inputId: "max_height_cm",
                                      name: _ctx.$t('max_height_cm'),
                                      append: true,
                                      appendText: _ctx.$t('cm'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[17] || (_cache[17] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromCm($event?.target?.value, 'max')))),
                                      modelValue: _ctx.editState.max_height_cm,
                                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.max_height_cm) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_31, [
                                  _createElementVNode("span", _hoisted_32, [
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.max_height_ft",
                                      inputId: "max_height_ft",
                                      name: _ctx.$t('max_height_ft'),
                                      append: true,
                                      inputClass: "mr-xl-2",
                                      appendText: _ctx.$t('ft'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[19] || (_cache[19] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromFeet($event?.target?.value, _ctx.editState.max_height_in, 'max')))),
                                      modelValue: _ctx.editState.max_height_ft,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.max_height_ft) = $event)),
                                      integer: true
                                    }, null, 8, ["name", "appendText", "modelValue"]),
                                    _createVNode(_component_number_input, {
                                      ruleKey: "donor_acceptability.max_height_in",
                                      inputId: "max_height_in",
                                      name: _ctx.$t('max_height_in'),
                                      append: true,
                                      inputClass: "ml-xl-2",
                                      appendText: _ctx.$t('in'),
                                      hideLabel: true,
                                      step: "0.1",
                                      onBlur: _cache[21] || (_cache[21] = ($event: any) => (_ctx.performCalculation(_ctx.editState.calculateFromInches(_ctx.editState.max_height_ft, $event?.target?.value, 'max')))),
                                      modelValue: _ctx.editState.max_height_in,
                                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.max_height_in) = $event))
                                    }, null, 8, ["name", "appendText", "modelValue"])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _cache[31] || (_cache[31] = _createElementVNode("hr", null, null, -1)),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createVNode(_component_text_area_input, {
                            inputId: "donor_acceptability_comments",
                            ruleKey: "donor_acceptability.comments",
                            name: _ctx.$t('donor_acceptability_comments'),
                            modelValue: _ctx.editState.comments,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.comments) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ])
                      ])
                    ]),
                    _: 2
                  }, [
                    (!_ctx.newJourney && _ctx.enableSaveToolbar)
                      ? {
                          name: "save",
                          fn: _withCtx(() => [
                            _createVNode(_component_save_toolbar, {
                              show: _ctx.showSaveToolbar,
                              ref: "saveToolbar",
                              class: "card-footer action-row temp-saving row",
                              label: _ctx.$t('save_dac'),
                              cancelButton: true,
                              onSave: _cache[24] || (_cache[24] = ($event: any) => (_ctx.handleSave())),
                              onCancel: _cache[25] || (_cache[25] = ($event: any) => (_ctx.handleCancel()))
                            }, null, 8, ["show", "label"])
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["disabled"])
                ]),
                _: 1
              }, 512))
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}