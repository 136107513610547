import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "progress" }
const _hoisted_5 = ["aria-valuenow"]
const _hoisted_6 = { class: "alert alert-info" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 2,
  class: "alert alert-danger"
}
const _hoisted_9 = { class: "nav action-row mt-0" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "dropdown" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_18 = { class: "dropdown-menu" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["disabled"]
const _hoisted_21 = ["title", "disabled"]
const _hoisted_22 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_23 = { class: "form-check form-check-inline row" }
const _hoisted_24 = {
  key: 0,
  class: "col-md-12 m-0 p-0 mb-2"
}
const _hoisted_25 = ["value", "id", "checked"]
const _hoisted_26 = ["for"]
const _hoisted_27 = { class: "modal-footer-body" }
const _hoisted_28 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "modal-footer-body" }
const _hoisted_31 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_32 = ["disabled"]
const _hoisted_33 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_34 = { class: "modal-footer-body" }
const _hoisted_35 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-success text-right mr-0"
}
const _hoisted_38 = {
  key: 3,
  class: "skeleton-box w-75",
  style: {"height":"2.5rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_add_recipient_modal = _resolveComponent("add-recipient-modal")!
  const _component_discontinue_organs_modal = _resolveComponent("discontinue-organs-modal")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.showControls)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        title: _ctx.$t('allocation_controls'),
        "sub-section-id": "allocation-controls",
        "save-button": false
      }, _createSlots({ _: 2 }, [
        (_ctx.editState)
          ? {
              name: "contents",
              fn: _withCtx(() => [
                _createElementVNode("fieldset", {
                  disabled: _ctx.editState && (_ctx.isLoadingAllocation || _ctx.isLoadingAllocations)
                }, [
                  (_ctx.editState.allocationErrorMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("span", {
                          innerHTML: _ctx.editState.allocationErrorMessage
                        }, null, 8, _hoisted_3)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.running && !_ctx.getTaskError)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", {
                            class: "progress-bar",
                            role: "progressbar",
                            style: _normalizeStyle(_ctx.getPercentageCss),
                            "aria-valuenow": _ctx.getPercentage,
                            "aria-valuemin": "0",
                            "aria-valuemax": "100"
                          }, null, 12, _hoisted_5)
                        ]),
                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", { innerHTML: _ctx.getStatus }, null, 8, _hoisted_7)
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  (_ctx.getTaskError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.getTaskError), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("nav", _hoisted_9, [
                    _createElementVNode("template", null, [
                      (!_ctx.hasDiscontinuedAllocation)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2",
                            disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation || _ctx.offeringState || _ctx.recommendedState || _ctx.running,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.runAllocation()))
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('run_allocation_recommendation')) + " ", 1),
                            (_ctx.isCreatingAllocation)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                  _createVNode(_component_font_awesome_icon, {
                                    class: "fa-1x fa-spin",
                                    icon: ['far', 'spinner-third']
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_10))
                        : _createCommentVNode("", true),
                      (_ctx.hasDiscontinuedAllocation)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            type: "button",
                            class: "btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2",
                            disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation || _ctx.offeringState || _ctx.recommendedState || _ctx.running,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reRunAllocation()))
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('re_run_allocation_recommendation')) + " ", 1),
                            (_ctx.isCreatingAllocation)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                  _createVNode(_component_font_awesome_icon, {
                                    class: "fa-1x fa-spin",
                                    icon: ['far', 'spinner-third']
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_12))
                        : _createCommentVNode("", true),
                      (_ctx.canAccessExclusionFilters)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 2,
                            type: "button",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleModal('exclusionFiltersModal'))),
                            disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation || (_ctx.offeringState && _ctx.hasExclusionRules) || _ctx.running,
                            class: "btn btn-wide btn-lg btn-success btn-sm mt-2 mr-2"
                          }, _toDisplayString(_ctx.$t('override_exclusion_filters')), 9, _hoisted_14))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_15, [
                        (_ctx.allowedAllocationReport)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "button",
                              "data-toggle": "dropdown",
                              disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation || _ctx.isGeneratingAllocationReport || _ctx.running,
                              class: "btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2 dropdown-toggle"
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.allocationReportButtonText) + " ", 1),
                              (_ctx.isGeneratingAllocationReport)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                                    _createVNode(_component_font_awesome_icon, {
                                      class: "fa-1x fa-spin",
                                      icon: ['far', 'spinner-third']
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_16))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedAllocationReportHospitals, (row, idx) => {
                            return (_openBlock(), _createElementBlock("a", {
                              class: "dropdown-item",
                              key: idx,
                              onClick: ($event: any) => (_ctx.downloadAllocationReport(row.code))
                            }, _toDisplayString(row.value), 9, _hoisted_19))
                          }), 128))
                        ])
                      ]),
                      (_ctx.allocation)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 3,
                            type: "button",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addRecipient())),
                            class: "btn btn-wide btn-lg btn-primary btn-sm mt-2 mr-2",
                            disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation || _ctx.isAddingRecipient || _ctx.running
                          }, _toDisplayString(_ctx.$t('add_recipient_allocation')), 9, _hoisted_20))
                        : _createCommentVNode("", true),
                      (_ctx.allocation)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 4,
                            type: "button",
                            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.discontinueOrgans()), ["prevent"])),
                            class: "btn btn-wide btn-lg btn-danger btn-sm mt-2 mr-2",
                            title: _ctx.$t('discontinue_all_offers_in_this_allocation'),
                            disabled: _ctx.isDiscontinueAllocationDisabled || _ctx.running
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('discontinue_allocation')) + " ", 1),
                            (_ctx.isDiscontinuingOneAllocation)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                                  _createVNode(_component_font_awesome_icon, {
                                    class: "fa-1x fa-spin",
                                    icon: ['far', 'spinner-third']
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_21))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_modal_section, {
                      modalId: "exclusion-filters-modal",
                      ref: "exclusionFiltersModal",
                      class: "modal-sticky-header exclusion-filters-modal",
                      centered: true
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('override_exclusion_filters')), 1)
                      ]),
                      body: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, [
                          (!_ctx.hasExclusionRules)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.$t('no_exclusion_rules_available')), 1))
                            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredExclusionRules, (item, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "col-md-6 mb-2",
                                  key: item.id
                                }, [
                                  _createElementVNode("input", {
                                    type: "checkbox",
                                    value: item.id,
                                    class: "form-check-input",
                                    id: `exclusion-rules[${index}]`,
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateExclusionRules($event))),
                                    checked: !_ctx.allocationRulesPayload.includes(item.id)
                                  }, null, 8, _hoisted_25),
                                  _createElementVNode("label", {
                                    class: "form-check-label w-100",
                                    for: `exclusion-rules[${index}]`
                                  }, _toDisplayString(item.description), 9, _hoisted_26)
                                ]))
                              }), 128))
                        ])
                      ]),
                      footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("button", _hoisted_28, _toDisplayString(_ctx.$t('cancel')), 1),
                          _createElementVNode("a", {
                            class: "btn btn-success",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.confirmExclusionFilters()))
                          }, _toDisplayString(_ctx.$t('ok')), 1)
                        ])
                      ]),
                      _: 1
                    }, 512),
                    _createVNode(_component_modal_section, {
                      modalId: "expedited-allocation-modal",
                      ref: "expeditedAllocationModal",
                      class: "modal-sticky-header",
                      centered: true
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('confirm_expedited_allocation_recommendation')), 1)
                      ]),
                      body: _withCtx(() => [
                        _createElementVNode("p", null, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.editState.modalErrorMessage
                          }, null, 8, _hoisted_29)
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('run_expedited_allocation')), 1)
                      ]),
                      footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("button", _hoisted_31, _toDisplayString(_ctx.$t('cancel')), 1),
                          _createElementVNode("button", {
                            class: "btn btn-success",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.checkForPreviousExpeditedAllocation())),
                            disabled: _ctx.isLoadingAllocation || _ctx.isCreatingAllocation
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('run_expedited_allocation_recommendation')) + " ", 1),
                            (_ctx.isCreatingAllocation)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                                  _createVNode(_component_font_awesome_icon, {
                                    class: "fa-1x fa-spin",
                                    icon: ['far', 'spinner-third']
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_32)
                        ])
                      ]),
                      _: 1
                    }, 512),
                    _createVNode(_component_modal_section, {
                      modalId: "ctr-failure-modal",
                      ref: "ctrFailureModal",
                      class: "modal-sticky-header",
                      centered: true
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('confirm_ctr_connection_failed')), 1)
                      ]),
                      body: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('ctr_connection_error.national_donor_id_missing')), 1)
                      ]),
                      footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("button", _hoisted_35, _toDisplayString(_ctx.$t('no')), 1),
                          _createElementVNode("a", {
                            class: "btn btn-success",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.confirmCtrFailure()))
                          }, _toDisplayString(_ctx.$t('yes')), 1)
                        ])
                      ]),
                      _: 1
                    }, 512),
                    _createVNode(_component_modal_section, {
                      modalId: "vca-hla-error-modal",
                      ref: "vcaHlaErrorModal",
                      class: "modal-sticky-header",
                      size: "md",
                      wide: false,
                      centered: true
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('vca_hla_error_title')), 1)
                      ]),
                      body: _withCtx(() => [
                        _createElementVNode("span", {
                          innerHTML: _ctx.editState.modalErrorMessage
                        }, null, 8, _hoisted_36)
                      ]),
                      footer: _withCtx(() => [
                        _createElementVNode("button", _hoisted_37, _toDisplayString(_ctx.$t('ok')), 1)
                      ]),
                      _: 1
                    }, 512),
                    _createVNode(_component_add_recipient_modal, {
                      ref: "addRecipientModal",
                      onReloadAllocation: _cache[9] || (_cache[9] = ($event: any) => (_ctx.reloadAllocation()))
                    }, null, 512),
                    _createVNode(_component_discontinue_organs_modal, {
                      ref: "discontinueOrgansModal",
                      discontinueType: "2",
                      onDisplayOutcomeNotification: _ctx.displayOutcomeNotification
                    }, null, 8, ["onDisplayOutcomeNotification"])
                  ]),
                  (_ctx.isLoadingAllocation)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_38))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["title"]))
    : _createCommentVNode("", true)
}