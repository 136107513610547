<template>
  <div v-if="isImportPatientsEnabled">
    <page-top>
      {{$t('import_title')}}
    </page-top>
    <template v-if="isLoading">
      <div class="content-wrap">
        <div class="container-fluid">
          <loading-import-page />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content-wrap dashboard-widgets">
        <div class="container-fluid">
          <import-file />
          <import-history />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator';
import { Vue } from "vue-facing-decorator";
import PageTop from '@/components/shared/PageTop.vue';
import { i18nMessages } from '@/i18n';
import LoadingImportPage from '@/components/shared/loading-skeletons/LoadingImportPage.vue';
import ImportFile from "@/views/importPatient/ImportFile.vue";
import ImportHistory from "@/views/importPatient/ImportHistory.vue";
import { useCurrentPageStore } from "@/stores/currentPage";
import { UIImportPatients } from "@/UIModels/importPatients";
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";

@Component({
  components: {
    LoadingImportPage,
    PageTop,
    ImportFile,
    ImportHistory,
    FullPageListLayout
  },
  ...i18nMessages([
    require('@/views/importPatient/_locales/import.json'),
  ])
})
export default class ImportPatient extends Vue {
  get isImportPatientsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.importPatients.enabled;
  }

  private isLoading = true;

  // // Hospitals to be loaded by the CardSection component
  // public hospitalsToLoad = ['all'];

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get importPatients(): UIImportPatients {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.importPatients as UIImportPatients;
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    await this.importPatients.load();
    this.$store.dispatch('hospitals/load');
    this.isLoading = false;
  }
}
</script>
