<template>
  <div v-if="isProtoLivingDonorDemographicsEnabled">
    <card-section
      section-id="proto_living_donor_demographics"
      :lookups-to-load="lookupsToLoad"
      :hospitals-to-load="hospitalsToLoad"
      :disabled="!canSave"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body v-if="editState">
      <validation-observer ref="validations">
      <form-layout form-id="ld-demographics-form">
        <template v-slot:contents>
          <sub-section 
            :title="$t('registration_information')" 
            sub-section-id="ld-demographics-internal" 
            :disabled="!canSave"
            :hideDivider="true"
            >
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group-large" v-if="checkPropExists('transplant_centre.hospital_id')">
                  <select-input
                    ruleKey="transplant_centre.hospital_id"
                    select-id="ld-demographics-personal-transplant-program"
                    :name="$t('transplant_program')"
                    v-model="editState.transplant_program" 
                    :options="hospitalOptions"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('transplant_centre.mrn')">
                  <text-input
                    ruleKey="transplant_centre.mrn"
                    inputId="ld-demographics-personal-mrn"
                    :name="$t('mrn')"
                    v-model="editState.mrn"
                  />
                </div>
                <div class="standard-form-group">
                  <text-input
                    inputId="ld-demographics-personal-client_id"
                    :name="$t('client_id')"
                    :disabled="true"
                    v-model="editState.afflo_id"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.ctr.national_recipient_id')">
                  <text-input
                    inputId="ld-demographics-personal-ctr_id"
                    :name="$t('CTR_Number')"
                    :disabled="true"
                    v-model="editState.ctr_id"
                  />
                </div>
                <div class="standard-form-group">
                  <date-input
                    inputId="ld-demographics-personal-registration_date"
                    :name="$t('living_donor_registration_date')"
                    :disabled="true"
                    v-model="editState.registration_date"
                  />
                </div>
                <div class="standard-form-group">
                  <text-input
                    inputId="ld-demographics-personal-registration_time"
                    :name="$t('living_donor_registration_time')"
                    :disabled="true"
                    v-model="editState.registration_time"
                  />
                </div>
                <div v-if="isUrgentRecipientEnabled" class="standard-form-group">
                  <checkbox-input
                    ruleKey="patient_profile.urgent"
                    validationId="urgent"
                    input-id="ld-demographics-internal-urgent"
                    :labelName="$t('urgent_recipient_label')"
                    :disabled="!newRecipient"
                    :title="isUrgent ? $t('urgent_recipient_hover_explanation') : ''"
                    v-model="editState.urgent_recipient"
                    label="Yes"
                  />
                </div>
              </div>
            </template>
          </sub-section>
          <sub-section
            :title="$t('personal_information')"
            sub-section-id="ld-demographics-personal"
            :disabled="!canSave"
            v-if="isPersonalInfoEnabled"
            :hideDivider="true"
          >
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.first_name')">
                  <text-input
                    ruleKey="patient_profile.first_name"
                    inputId="ld-demographics-personal-firstname"
                    :name="$t('first_name')"
                    v-model="editState.first_name"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.middle_name')">
                  <text-input
                    ruleKey="patient_profile.middle_name"
                    inputId="ld-demographics-personal-middlename"
                    :name="$t('middle_name')"
                    v-model="editState.middle_name"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.last_name')">
                  <text-input
                    ruleKey="patient_profile.last_name"
                    inputId="ld-demographics-personal-lastname"
                    :name="$t('last_name')"
                    v-model="editState.last_name"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.preferred_name')">
                  <text-input
                    ruleKey="patient_profile.preferred_name"
                    inputId="ld-demographics-personal-preferred_name"
                    :name="$t('preferred_name')"
                    v-model="editState.preferred_name"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.preferred_pronouns')">
                  <text-input
                    ruleKey="patient_profile.preferred_pronouns"
                    inputId="ld-demographics-personal-preferred_pronouns"
                    :name="$t('preferred_pronouns')"
                    v-model="editState.preferred_pronouns"
                  />
                </div>
              </div>
              <div class="row">
                <template  v-if="checkPropExists('patient_profile.birth.date')">
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="patient_profile.birth.date"
                      inputId="ld-demographics-personal-dob"
                      :name="$t('dob')"
                      v-model="editState.date_of_birth"
                      :minDateLimit="getDobMinDateLimit"
                      :maxDateLimit="getDobMaxDateLimit"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      inputId="ld-demographics-personal-age"
                      :name="$t('age')"
                      :disabled="true"
                      :calculated="true"
                      v-model="editState.age_in_years"
                    />
                  </div>
                </template>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.government_identification.number')">
                  <text-input
                    ruleKey="patient_profile.government_identification.number"
                    inputId="ld-demographics-personal-government_identification_number"
                    :name="$t('government_identification_number')"
                    v-model="editState.government_identification_number"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.race_codes')">
                  <select-input
                    ruleKey="patient_profile.race_codes"
                    selectId="ld-demographics-personal-race"
                    :multiple="true"
                    :disabled="!canSave"
                    :name="$t('race')"
                    v-model="editState.race"
                    :options="raceOptions"
                  />
                </div>
                <select-other-input
                  v-if="checkPropExists('patient_profile.ethnicity_code')"
                  ruleKey="patient_profile.ethnicity_code"
                  select-id="ld-demographics-personal-ethnicity"
                  :name="$t('ethnicity')"
                  validationId="ethnicity_code"
                  v-model="editState.ethnicity"
                  :options="ethnicityLookup"
                  @change="clearEthnicityOther"
                  :otherTitle="$t('ethnicity_other')"
                  :enableOther="showEthnicityOther"
                  colStyling="standard-form-group-with-other"
                  reduceColumnWidth="standard-form-group"
                >
                  <template v-slot:other>
                    <text-input
                      ruleKey="patient_profile.ethnicity_other"
                      input-id="ld-demographics-personal-ethnicityother"
                      :name="$t('ethnicity_other')"
                      v-model="editState.ethnicity_other"
                    />
                  </template>
                </select-other-input>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.indigenous_identity_codes')">
                  <select-input
                    ruleKey="patient_profile.indigenous_identity_codes"
                    selectId="ld-demographics-personal-indigenous_identity_codes"
                    :multiple="true"
                    :disabled="!canSave"
                    :name="$t('indigenous_identity_codes')"
                    v-model="editState.indigenous_identity_codes"
                    :options="indigenousIdentityOptions"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.sex')">
                  <select-input
                    ruleKey="patient_profile.sex"
                    selectId="ld-demographics-internal-sex"
                    :name="$t('sex')"
                    v-model="editState.sex_at_birth"
                    :options="sexOptions"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.gender_sex_different')">
                  <checkbox-input
                    ruleKey="patient_profile.gender_sex_different"
                    validationId="gender_sex_different"
                    input-id="ld-demographics-personal-gender-different"
                    :labelName="$t('gender_is_different')"
                    v-model="editState.gender_sex_different"
                    :label="$t('yes')"
                  />
                </div>
                <select-other-input
                  ruleKey="patient_profile.gender"
                  select-id="ld-demographics-personal-gender"
                  :name="$t('gender')"
                  validationId="gender"
                  v-if="editState.gender_sex_different && checkPropExists('patient_profile.gender')"
                  v-model="editState.gender_identity"
                  :options="genderLookup"
                  @change="clearGenderOther"
                  :otherTitle="$t('gender_other')"
                  colStyling="standard-form-group-with-other"
                  reduceColumnWidth="standard-form-group"
                  :enableOther="checkPropExists('patient_profile.gender_other')"
                >
                  <template v-slot:other>
                    <text-input
                      ruleKey="patient_profile.gender_other"
                      input-id="demographics-personal-other-gender"
                      :name="$t('gender_other')"
                      v-model="editState.gender_other"
                    />
                  </template>
                </select-other-input>
              </div>
              <div class="row" v-if="checkAtLeastOnePropExists(['patient_profile.education.highest_education_level', 'patient_profile.education.academic_grade_level'])">
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.education.highest_education_level')">
                  <select-input
                    ruleKey="patient_profile.education.highest_education_level"
                    selectId="ld-demographics-personal-highest_education_level"
                    :name="$t('highest_education_level')"
                    v-model="editState.highest_education_level"
                    :options="highestEducationLevelOptions"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.education.academic_grade_level')">
                  <select-input
                    ruleKey="patient_profile.education.academic_grade_level"
                    selectId="ld-demographics-personal-academic_grade_level"
                    :name="$t('academic_grade_level')"
                    v-model="editState.academic_grade_level"
                    :options="academicGradeLevelOptions"
                  />
                </div>
              </div>
            </template>
          </sub-section>
        </template>

        <template v-slot:save>
          <save-toolbar
            :show="showSaveToolbar"
            ref="saveLDDemographics"
            :label="newRecipient ? $t('save_recipient') : $t('save_demographics')"
            @save="performSave()"
            :cancelButton="true"
            @cancel="clearForm()"
          />
        </template>
      </form-layout>
      </validation-observer>
    </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
  import { i18nMessages } from "@/i18n";
  import {Getter, State} from "vuex-facing-decorator";
  import {calculateAge} from "@/utils";
  import {Ethnicity, Sex, Gender, InsurancePlanCode, InsuranceType, Province} from '@/store/lookups/types';
  import { GenericCodeValue } from "@/store/types";
  import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
  import { Hospital } from "@/store/hospitals/types";
  import { Recipient, RecipientValidations } from "@/store/recipients/types";
  import AutoCompleteInput from "@/components/shared/AutoCompleteInput.vue";

  const EDITSTATE_KEY = 'editState';

  interface DemographicsForm {
    transplant_program?: string;
    mrn?: string;
    afflo_id?: string;    
    ctr_id?: string;    
    registration_date?: string;
    registration_time?: string;
    urgent_recipient?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    preferred_name?: string;
    preferred_pronouns?: string;
    date_of_birth?: string;
    age_in_years?: string;
    government_identification_number?: string;
    race?: string;
    sex_at_birth?: string;
    gender?: string | null;
    gender_sex_different?: boolean 
    gender_identity?: string | null;
    gender_other?: string | null;
    ethnicity?: string| null;
    ethnicity_other?: string| null;
    highest_education_level?: string| null
    indigenous_identity_codes?: string| null;
    academic_grade_level?: string| null;
}
  
  @Component({
    components: {
      CardSection,
      SubSection,
      TextInput,
      TextAreaInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout,
      DateInput,
      SelectOtherInput,
      AutoCompleteInput
    },
    ...i18nMessages([
      require('@/prototypes/_locales/_livingDonorDemographics.json'),
    ])
  })
  export default class ProtoPaceAndEngagement extends Vue {
    // Getters
    @Getter('isUrgent', { namespace: 'recipients' }) public isUrgent!: boolean;
    @Getter('getDobMinDateLimit', { namespace: 'lookups' }) private getDobMinDateLimit!: string;
    @Getter('getDobMaxDateLimit', { namespace: 'lookups' }) private getDobMaxDateLimit!: string;
    @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Sex[];
    @Getter('getMaskFormatType', { namespace: 'lookups' }) getMaskFormatType!: (code?: number|null) => string;
    @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
    @Getter('checkAtLeastOnePropExists', { namespace: 'validations' }) checkAtLeastOnePropExists!: (ruleKeys: string[]) => boolean;


    // State
    @State(state => state.lookups.province) provinceLookup!: Province[];
    @State(state => state.lookups.ethnicity) ethnicityLookup!: Ethnicity[];
    @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;
    @State(state => state.lookups.gender) genderLookup!: Gender[];
    @State(state => state.lookups.race) raceOptions!: GenericCodeValue[];
    @State(state => state.lookups.indigenous_identity) indigenousIdentityOptions!: GenericCodeValue[];
    @State(state => state.lookups.highest_education_level) highestEducationLevelOptions!: GenericCodeValue[];
    @State(state => state.lookups.academic_grade_level) academicGradeLevelOptions!: GenericCodeValue[];
    @State(state => state.hospitals.all) hospitals!: Hospital[];

    // Properties
    @Prop({ default: false }) newRecipient!: boolean;
    @Prop({ default: false }) canSave!: boolean;


    private title: any = "Living Donor - Demographics";

      // Lookup tables to be loaded by the CardSection component
    public lookupsToLoad = [
      "ethnicity",
      "sex",
      "gender",
      "country",
      "race",
      "indigenous_identity",
      "highest_education_level",
      "academic_grade_level",
    ];

    // Hospitals to be loaded by the CardSection component
    public hospitalsToLoad = ['all'];

    get hospitalOptions(): GenericCodeValue[] {
      if (!this.hospitals) return [];

      const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
        return {
          code: hospital._id,
          value: `${hospital.program_identifier} - ${hospital.hospital_name_info?.name}`,
        };
      });
      return mapped;
    }

    get isUrgentRecipientEnabled(): boolean {
      return useCurrentPageStore().configuration.features.recipientConfig.demographics.registrationInfo.urgentRecipient.enabled;
    }


    get isPersonalInfoEnabled(): boolean {
      return useCurrentPageStore().configuration.features.recipientConfig.demographics.personalInfo.enabled;
    }


    get showSaveToolbar(): boolean {
      if (!this.canSave) return false;
      return (this.isUrgentRecipientEnabled || this.isPersonalInfoEnabled);
    }

    get showEthnicityOther(): boolean {
      return false;
    }

    /**
     * Returns Province dropdown without ontario for the 'other provinces' dropdown
     *
     * @returns {Province[]} list of Provinces
     */
    get otherProvinceDropdown(): Province[] {
        const ontarioIndex = this.provinceLookup.findIndex( element => element.code === "ON");
        return this.provinceLookup.filter((element, index) => {
            return index === ontarioIndex ? false : element;
          }
        );
      }



    get calculatedAge() {
      const livingDonorDoB = this.editState.date_of_birth || null;
      if (livingDonorDoB) {
        return calculateAge(livingDonorDoB);
      }
      return null;
    }


    get isProtoLivingDonorDemographicsEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoLivingDonorDemographics);
    }

    // Each save toolbar will need a unique ref
    get saveToolbar(): SaveToolbar {
      return this.$refs.saveLDDemographics as SaveToolbar;
    }

    // PRIVATE
    private editState: DemographicsForm = {
      transplant_program:  "",
      mrn: "",
      afflo_id: "",    
      ctr_id: "",    
      registration_date: "",
      registration_time: "",
      urgent_recipient: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      preferred_name: "",
      preferred_pronouns: "",
      date_of_birth: "",
      age_in_years: "",
      government_identification_number: "",
      race: "",
      sex_at_birth: "",
      gender: null,
      gender_sex_different: false,
      gender_identity: null,
      gender_other: null,
      ethnicity: null,
      ethnicity_other: null,
      highest_education_level: null,
      indigenous_identity_codes: null,
      academic_grade_level: null
    };

    // Clear values from state given an array of keys
    private clearStateValues(keys: string[]): void {
      if (!Array.isArray(keys) || keys.length === 0) return;
      // For each key, clear the value in our state
      keys.forEach((key: string) => {
        // TODO: TECH_DEBT: remove 'any' in favour of strict typing
        (this.editState as any)[key] = undefined;
      });
    }

    public clearForm(): any {
      this.editState.transplant_program = "";
      this.editState.mrn = "";
      this.editState.afflo_id = "";    
      this.editState.ctr_id = "";    
      this.editState.registration_date = "";
      this.editState.registration_time = "";
      this.editState.urgent_recipient = "";
      this.editState.first_name = "";
      this.editState.middle_name = "";
      this.editState.last_name = "";
      this.editState.preferred_name = "";
      this.editState.preferred_pronouns = "";
      this.editState.date_of_birth = "";
      this.editState.age_in_years = "";
      this.editState.government_identification_number = "";
      this.editState.race = "";
      this.editState.sex_at_birth = "";
      this.editState.gender = null;
      this.editState.gender_sex_different = false;
      this.editState.gender_identity = null;
      this.editState.gender_other = null;
      this.editState.ethnicity = null;
      this.editState.ethnicity_other = null;
      this.editState.highest_education_level = null;
      this.editState.indigenous_identity_codes = null;
      this.editState.academic_grade_level = null;
    }


      // Clear gender other when gender changes
    public clearGenderOther() {
      this.editState.gender_other = '';
    }


        // Clear ethnicity other when ethnicity changes
    public clearEthnicityOther() {
      this.editState.ethnicity_other = '';
    }

   
    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;


    // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
    async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.PaceAndEngagement, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: DemographicsForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        transplant_program: response.transplant_program,
        mrn: response.mrn,
        afflo_id: response.afflo_id,    
        ctr_id: response.ctr_id,    
        registration_date: response.registration_date,
        registration_time: response.registration_time,
        urgent_recipient: response.urgent_recipient,
        first_name: response.first_name,
        middle_name: response.middle_name,
        last_name: response.last_name,
        preferred_name: response.preferred_name,
        preferred_pronouns: response.preferred_pronouns,
        date_of_birth: response.date_of_birth,
        age_in_years: response.age_in_years,
        government_identification_number: response.government_identification_number,
        race: response.race,
        sex_at_birth: response.sex_at_birth,
        gender: response.gender,
        gender_sex_different: response.gender_sex_different,
        gender_identity: response.gender_identity,
        gender_other: response.gender_other,
        ethnicity: response.ethnicity,
        ethnicity_other: response.ethnicity_other,
        highest_education_level: response.highest_education_level,
        indigenous_identity_codes: response.indigenous_identity_codes,
        academic_grade_level: response.academic_grade_level
      };
    }

    public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: DemographicsForm = {
        transplant_program: this.editState.transplant_program,
        mrn: this.editState.mrn,
        afflo_id: this.editState.afflo_id,    
        ctr_id: this.editState.ctr_id,    
        registration_date: this.editState.registration_date,
        registration_time: this.editState.registration_time,
        urgent_recipient: this.editState.urgent_recipient,
        first_name: this.editState.first_name,
        middle_name: this.editState.middle_name,
        last_name: this.editState.last_name,
        preferred_name: this.editState.preferred_name,
        preferred_pronouns: this.editState.preferred_pronouns,
        date_of_birth: this.editState.date_of_birth,
        age_in_years: this.editState.age_in_years,
        government_identification_number: this.editState.government_identification_number,
        race: this.editState.race,
        sex_at_birth: this.editState.sex_at_birth,
        gender: this.editState.gender,
        gender_sex_different: this.editState.gender_sex_different,
        gender_identity: this.editState.gender_identity,
        gender_other: this.editState.gender_other,
        ethnicity: this.editState.ethnicity,
        ethnicity_other: this.editState.ethnicity_other,
        highest_education_level: this.editState.highest_education_level,
        indigenous_identity_codes: this.editState.indigenous_identity_codes,
        academic_grade_level: this.editState.academic_grade_level
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 
  }

</script>

<style>


</style>
