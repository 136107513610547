import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "filteritem" }
const _hoisted_2 = { class: "filteritem" }
const _hoisted_3 = { class: "filteritem" }
const _hoisted_4 = { class: "filteritem" }
const _hoisted_5 = { class: "filteritem" }
const _hoisted_6 = { class: "filteritem" }
const _hoisted_7 = {
  key: 0,
  class: "filteritem last"
}
const _hoisted_8 = { class: "form-check form-check-inline" }
const _hoisted_9 = ["id", "aria-labelledby"]
const _hoisted_10 = ["for"]
const _hoisted_11 = {
  key: 2,
  class: "filteritem last"
}
const _hoisted_12 = { class: "form-check form-check-inline" }
const _hoisted_13 = ["id", "aria-labelledby"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "filteritem" }
const _hoisted_16 = { class: "filteritem" }
const _hoisted_17 = { class: "action-row" }
const _hoisted_18 = {
  key: 4,
  class: "filter-spacer filterShowLarge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showNotesFilter)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, [
              _createVNode(_component_date_input, {
                "input-id": "added_date_from",
                name: _ctx.$t('date_added_from'),
                modelValue: _ctx.selectedFilters.date_from,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilters.date_from) = $event))
              }, null, 8, ["name", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, [
              _createVNode(_component_date_input, {
                "input-id": "added_date_to",
                name: _ctx.$t('date_added_to'),
                modelValue: _ctx.selectedFilters.date_to,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFilters.date_to) = $event))
              }, null, 8, ["name", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, [
              _createVNode(_component_select_input, {
                selectId: "users",
                name: _ctx.$t('author'),
                modelValue: _ctx.selectedFilters.user_id,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFilters.user_id) = $event)),
                options: _ctx.authorOptions
              }, null, 8, ["name", "modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, [
              _createVNode(_component_select_input, {
                selectId: "tags",
                name: _ctx.$t('note_tags'),
                modelValue: _ctx.selectedFilters.note_tags,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedFilters.note_tags) = $event)),
                options: _ctx.tagOptions
              }, null, 8, ["name", "modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, [
              _createVNode(_component_text_input, {
                inputId: "search",
                name: _ctx.$t('keywords'),
                modelValue: _ctx.selectedFilters.keywords,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedFilters.keywords) = $event)),
                placeHolder: _ctx.$t('search')
              }, null, 8, ["name", "modelValue", "placeHolder"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showChecklistFilter)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, [
              _createVNode(_component_select_input, {
                selectId: "checklist_status",
                name: _ctx.$t('status'),
                modelValue: _ctx.selectedFilters.checklist_status,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedFilters.checklist_status) = $event)),
                options: _ctx.statusOptions
              }, null, 8, ["name", "modelValue", "options"])
            ])
          ]),
          (_ctx.excludeCompleted)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    id: `${_ctx.fieldID}-completed`,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedFilters.exclude_completed) = $event)),
                    "aria-labelledby": `${_ctx.fieldID}-completed`,
                    type: "checkbox",
                    class: "form-check-input"
                  }, null, 8, _hoisted_9), [
                    [_vModelCheckbox, _ctx.selectedFilters.exclude_completed]
                  ]),
                  _createElementVNode("label", {
                    for: `${_ctx.fieldID}-completed`,
                    class: "form-check-label"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('exclude_completed')), 1)
                  ], 8, _hoisted_10)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showArchived)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              id: `${_ctx.fieldID}-archived`,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedFilters.include_archived) = $event)),
              "aria-labelledby": `${_ctx.fieldID}-archived`,
              type: "checkbox",
              class: "form-check-input"
            }, null, 8, _hoisted_13), [
              [_vModelCheckbox, _ctx.selectedFilters.include_archived]
            ]),
            _createElementVNode("label", {
              for: `${_ctx.fieldID}-archived`,
              class: "form-check-label"
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('include_archived')), 1)
            ], 8, _hoisted_14)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showImportDetailsModalFilter)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, [
              _createVNode(_component_text_input, {
                inputId: "row_number",
                name: _ctx.$t('row_number'),
                modelValue: _ctx.selectedFilters.row_number,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedFilters.row_number) = $event))
              }, null, 8, ["name", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", null, [
              _createVNode(_component_select_input, {
                selectId: "status",
                name: _ctx.$t('status'),
                modelValue: _ctx.selectedFilters.status,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedFilters.status) = $event)),
                options: _ctx.statusOptions
              }, null, 8, ["name", "modelValue", "options"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary-outline btn-sm",
        onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.saveFilters()), ["prevent"]))
      }, _toDisplayString(_ctx.$t('filter')), 1),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-secondary-outline btn-sm ml-2",
        onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.clearFilters()), ["prevent"]))
      }, _toDisplayString(_ctx.$t('clear_filter')), 1)
    ]),
    (!_ctx.hideSpacer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18))
      : _createCommentVNode("", true)
  ]))
}