import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'edit-recipient', params: { id: _ctx.client_id }},
    target: "_blank"
  }, {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("i", {
        "aria-hidden": "true",
        class: "far fa-fw fa-file-export"
      }, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('recipient_profile')), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}