<template>
  <div v-if="isProtoOffersHistoryEnabled && loaded">
    <card-section
    section-id="proto_offers_history"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <sub-section
        sub-section-id="proto-offers-history-section"
        title="OFFER RESPONSES"
      >
        <template v-slot:contents>
        
          <!-- List of Items, or History List -->
          <div :class="getStyleClass()">
            <DataTable class="offers-table"
            :value="currentOffersTableConfig.data"
            :rows="currentOffersTableConfig.data.length"
            :class="{'scrollbox': true}"
            :rowClass="rowStyle"  
            id="offers-history-table"                                 
            >
              <Column
                v-for="col of currentOffersTableConfig.columns"
                :field="col.field"
                :key="col.field"
                :style="buildColumnStyle(col)"
                :header="col.label"
                >
                <template #body="slotProps">
                  <span v-if="isMobile" class="p-column-title">{{col.label}}</span>
                  <template v-if="col.field == 'offer_date_time'">
                    <span>
                      <a href="javascript:void(0);" tabindex="0" class="table-link"  @click="onTableColClick">{{slotProps.data.offer_date_time}}</a>
                    </span>
                
                  </template>
                  <template v-else-if="col.field == 'response_date_time'">
                    <span class="daytime-value">
                      {{slotProps.data.response_date}} {{slotProps.data.response_time}}
                    </span>
                
                  </template>
                  <template v-else>
                    {{slotProps.data[col.field ]}}
                  </template>
                </template>
              </Column>
              
            </DataTable>
          </div>
        </template>
      </sub-section>
    </template>
  </card-section>
  <proto-compare-modal :selectedDonorId="selectedDonorId" modalId="compare-modal"
  ref="compareModal"/>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';

  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
  
  import FormLayout from '@/components/shared/FormLayout.vue';
  import ProtoCompareModal from '@/prototypes/offers/ProtoCompareModal.vue';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';

  import {
    TableConfig,
  } from '@/types';


  const PAGE_SIZES = [5, 10, 25];
  const EDITSTATE_KEY = 'editState';
  
  @Component({
    components: {
      CardSection,
      SubSection,
      FormLayout,
      ProtoCompareModal,
      DataTable,
      Column,
    },
  })
  export default class ProtoOffersHistory extends Vue {

    private loaded = false;

    private isMobile = false;


  private buildColumnStyle(col: any): string|undefined {
    const styleParts = [];
    if (col.width) {
      styleParts.push(`width: ${col.width}`);
      styleParts.push(`min-width: ${col.width}`);
      styleParts.push(`max-width: ${col.width}`);
    }

    styleParts.push('overflow: hidden');
    styleParts.push('text-overflow: ellipsis');

    return styleParts.join('; ');
  }

  get rowStyle(): any {
    // For selection highlighting, use function driven by _id field (styling purposes only)
    return (row: any): string => {
      const results: string[] = ['offer-row-no-offer', 'tr-link' ,'p-datatable-selectable-row'];
    
      return results.join(' ');
    };
  }

    public checkIfMobile() {
      if (window.innerWidth < 961) {
        this.isMobile = window.innerWidth < 961;
      } else {
        this.isMobile = false;
      }
    }

    public getStyleClass() {
      return { "spaced-list-items": this.isMobile} ;
    } 


    private title: any = "Offer History - Lung";

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;

    private selectedDonorId: any = null;

    mounted(): void {
      this.$store.dispatch('hospitals/load').then(() => {
        this.loaded = true;
      });
    }

    private currentOffers: any = [
    {
      offer_date_time: "05-12-2024 15:00",
      organ: "Liver",
      laterality: "Whole",
      html: true,
      offer_type: "Primary",
      response_date: '05-12-2024',
      response_time: '15:42',
      coordinator: "M. Couch",
      surgeon: "C. Bratton",
      offer_response: 'Declined',
      response_category: "-",
      outcome_disposition: 'Declined',
      notes: "Declined due to size"
    },
    {
      offer_date_time: "03-12-2024 18:00",
      organ: "Liver",
      laterality: "Whole",
      html: true,
      offer_type: "Backup",
      response_date: '03-12-2024',
      response_time: '19:00',
      coordinator: "S. Woods",
      surgeon: "B. Jones",
      offer_response: 'Accepted',
      response_category: "-",
      outcome_disposition: 'Never became primary',
      notes: "-"
    },
    {
      offer_date_time: "25-11-2024 23:00",
      organ: "Liver",
      laterality: "Whole",
      html: true,
      offer_type: "Primary",
      response_date: '25-11-2024',
      response_time: '23:48',
      coordinator: "S. Woods",
      surgeon: "C. Bratton",
      offer_response: 'Declined',
      response_category: "-",
      outcome_disposition: 'Not Transplanted (donor reasons)',
      notes: "-"
    },
    {
      offer_date_time: "14-11-2024 11:00",
      organ: "Liver",
      laterality: "Whole",
      html: true,
      offer_type: "Backup",
      response_date: '14-11-2024',
      response_time: '11:45',
      coordinator: "M. Couch",
      surgeon: "C. Bratton",
      offer_response: 'Accepted',
      response_category: "-",
      outcome_disposition: 'Never became primary',
      notes: "-"
    }
];
    public perPage = 10;
    public currentPageTable = 1;

    get currentOffersTableConfig(): TableConfig {
    return {
      data: this.tableRows || [],

      columns: [
        { label: 'Offer Date Time	', field: 'offer_date_time', width: '180px', modal: true  },
        { label: 'Organ', field: 'organ', width: '100px' },
        { label: 'Laterality', field: 'laterality', width: '80px' },
        { label: 'Offer Type', field: 'offer_type', width: '100px' },
        { label: 'Response Date Time', field: 'response_date_time', width: '180px', html: true},
        { label: 'Coordinator', field: 'coordinator', width: '130px'},
        { label: 'Surgeon', field: 'surgeon', width: '130px', },
        { label: 'Offer Response', field: 'offer_response', width: '135px'},
        { label: 'Response Category', field: 'response_category', width: '160px'},
        { label: 'Outcome / Dispostion', field: 'outcome_disposition', width: '300px'},
        { label: 'Notes', field: 'notes', width: '190px'},
      ],
      empty: 'use the form below to add a new row',
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // Table row click
  public onTableColClick(id: any) {
    this.selectedDonorId = id;
  }

  get isProtoOffersHistoryEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoOffersHistory);
  }

  get tableRows() {
    const currentOffers = this.currentOffers || [];

    if (currentOffers.length > 0) {

    return currentOffers.map((offer: any) => {
      return {
        offer_date_time: offer.offer_date_time || '-',
        organ: offer.organ,
        laterality: offer.laterality,
        offer_type: offer.offer_type,
        response_date_time: '-',
        response_date: offer.response_date,
        response_time: offer.response_time,
        coordinator: offer.coordinator,
        surgeon: offer.surgeon,
        offer_response: offer.offer_response,
        response_category: offer.response_category,
        outcome_disposition: offer.outcome_disposition,
        notes: offer.notes
      };
    });
  }
  }

 
  }



</script>
<style>
 .no-list-style {
  list-style-type: none;
  padding-inline-start: 0;
 }

    .p-datatable-selectable-row.offer-row-no-offer td {
    background-color: rgba(213, 146, 55, 0.08) !important
  }

  .p-datatable-selectable-row.offer-row-no-offer:hover td, 
  .p-datatable-selectable-row.offer-row-no-offer:focus-visible, 
  .tr-link.offer-row-no-offer:focus-within.p-datatable-selectable-row.offer-row-no-offer.tr-active {
    background-color: rgba(255, 240, 152, 0.1) !important
  }

  .p-datatable-hoverable-rows .p-datatable-selectable-row.offer-row-no-offer:is(:hover, :focus-visible) {
    background-color: rgba(255, 240, 152, 0.1) !important
  }

  .p-datatable-selectable-row.offer-row-no-offer:is(:hover, :focus-visible) {
    background-color: rgba(255, 240, 152, 0.1) !important
  }

  .table .offer-row-no-offer.tr-active, table .offer-row-no-offer.tr-active:hover {
    background-color: rgba(255, 240, 152, 0.1) !important
  }
 </style>
