import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!

  return (_openBlock(), _createElementBlock("fieldset", null, [
    (_ctx.editState)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_text_input, {
                ruleKey: "patient_contact_detail.email",
                inputId: "proto-ld-contact-detail-email",
                name: _ctx.$t('contact_detail_field.email'),
                modelValue: _ctx.editState.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.email) = $event)),
                mask: _ctx.getMaskFromRules('patient_contact_detail.email'),
                placeholder: _ctx.$t('contact_detail_placeholder.email')
              }, null, 8, ["name", "modelValue", "mask", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_checkbox_input, {
                inputId: "proto-ld-contact-detail-preferred",
                labelName: _ctx.$t('contact_detail_field.preferred'),
                modelValue: _ctx.editState.preferred,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.preferred) = $event)),
                label: _ctx.$t('yes')
              }, null, 8, ["labelName", "modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_text_area_input, {
                ruleKey: "patient_contact_detail.comments",
                inputId: "proto-ld-contact-detail-comments",
                name: _ctx.$t('contact_detail_field.comments'),
                modelValue: _ctx.editState.comments,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.comments) = $event))
              }, null, 8, ["name", "modelValue"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}