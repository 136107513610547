import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "form-group col-md-12 col-lg-12 col-xl-12" }
const _hoisted_6 = { class: "p-label" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "form-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_donation_destination = _resolveComponent("donation-destination")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donation-information",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('donation_information_title')), 1)
    ]),
    _: 2
  }, [
    (_ctx.editState)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createVNode(_component_sub_section, {
              subSectionId: "donation-information-subsection",
              ref: "saveDonationInformation",
              saveButtonText: _ctx.$t('save_donation_information'),
              saveButton: !_ctx.newLivingDonor,
              onSave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.savePatch())),
              disabled: !_ctx.canSave
            }, _createSlots({ _: 2 }, [
              (_ctx.editState)
                ? {
                    name: "contents",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_select_input, {
                            "select-id": "ld-transplant-program",
                            ruleKey: "living_donor_info.journeys.transplant_program.transplant_hospital_id",
                            name: _ctx.$t('transplant_program_label'),
                            modelValue: _ctx.editState.transplantProgram,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.transplantProgram) = $event)),
                            options: _ctx.ontarioTransplantOptions,
                            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetCoordinatorsList($event)))
                          }, null, 8, ["name", "modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_select_input, {
                            "select-id": "ld-transplant-coordinator",
                            ruleKey: "living_donor_info.journeys.transplant_program.living_donor_coordinator_id",
                            name: _ctx.$t('living_donor_coordinator_label'),
                            modelValue: _ctx.editState.livingDonorCoordinator,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.livingDonorCoordinator) = $event)),
                            isLoadingSelectionValues: _ctx.isLoadingCoordinators,
                            options: _ctx.coordinatorOptions
                          }, null, 8, ["name", "modelValue", "isLoadingSelectionValues", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("label", _hoisted_6, [
                            _createTextVNode(_toDisplayString(_ctx.$t("consented_organs_title")) + " ", 1),
                            _cache[5] || (_cache[5] = _createElementVNode("span", null, [
                              _createElementVNode("i", null, "*")
                            ], -1))
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.organConsents, (organ, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: organ.organCode,
                                class: "standard-form-group"
                              }, [
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_checkbox_input, {
                                    "input-id": `organ-${organ.organCode}-checkbox`,
                                    modelValue: organ.consented,
                                    "onUpdate:modelValue": ($event: any) => ((organ.consented) = $event),
                                    label: _ctx.$t(`${organ.organValue}`),
                                    validationId: `organ-${organ.organCode}-checkbox`,
                                    disabled: _ctx.disableOrganConsent(organ.organValue) || !_ctx.organCodesAllowedByTransplantProgram(organ.organCode),
                                    onChange: ($event: any) => (_ctx.organConsentChanged(organ, index))
                                  }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label", "validationId", "disabled", "onChange"])
                                ]),
                                _createElementVNode("div", null, [
                                  _createVNode(_component_date_input, {
                                    rules: "required_if_filled:@code",
                                    "input-id": `organ-${organ.organCode}-consented-date`,
                                    name: _ctx.$t("consented_date_label"),
                                    crossValues: { code: organ.consented },
                                    modelValue: organ.consentDate,
                                    "onUpdate:modelValue": ($event: any) => ((organ.consentDate) = $event),
                                    validationId: `organ-${organ.organCode}-consented-date`,
                                    disabled: !organ.consented || !_ctx.organCodesAllowedByTransplantProgram(organ.organCode)
                                  }, null, 8, ["input-id", "name", "crossValues", "modelValue", "onUpdate:modelValue", "validationId", "disabled"])
                                ])
                              ]))
                            }), 128))
                          ])
                        ])
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["saveButtonText", "saveButton", "disabled"]),
            (!_ctx.newLivingDonor)
              ? (_openBlock(), _createBlock(_component_donation_destination, {
                  key: 0,
                  ref: "donationDestination",
                  newLivingDonor: _ctx.newLivingDonor,
                  onHandleErrors: _ctx.handleErrors,
                  onClear: _ctx.clear,
                  canSave: _ctx.canSave,
                  onReload: _ctx.reload
                }, null, 8, ["newLivingDonor", "onHandleErrors", "onClear", "canSave", "onReload"]))
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["lookupsToLoad"]))
}