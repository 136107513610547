import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_6 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_7 = { class: "standard-form-group-large" }
const _hoisted_8 = { class: "standard-form-group-large" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: `${_ctx.$t('recovery_information')}`,
    "sub-section-id": "recovery-information",
    ref: "recoveryInformation",
    onLoaded: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loaded())),
    disabled: !_ctx.hasWriteAccess
  }, {
    contents: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')) + ". ", 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_text_input, {
                  inputId: "recovery-information-declaration_hospital",
                  name: `${_ctx.$t('declaration_hospital')}`,
                  modelValue: _ctx.editState.declarationHospital,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.declarationHospital) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_text_input, {
                  inputId: "recovery-information-recovery_hospital",
                  name: `${_ctx.$t('recovery_hospital')}`,
                  modelValue: _ctx.editState.recoveryHospital,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.recoveryHospital) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_date_input, {
                  inputId: "recovery-information-first_declaration_death_date",
                  name: `${_ctx.$t(`${_ctx.typeOfDeath}_first_declaration_death_date`)}`,
                  modelValue: _ctx.editState.firstDeclarationDeathDate,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.firstDeclarationDeathDate) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_time_input, {
                  inputId: "recovery-information-first_declaration_death_time",
                  name: `${_ctx.$t(`${_ctx.typeOfDeath}_first_declaration_death_time`)}`,
                  modelValue: _ctx.editState.firstDeclarationDeathTime,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.firstDeclarationDeathTime) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_date_input, {
                  inputId: "recovery-information-second_declaration_death_date",
                  name: `${_ctx.$t(`${_ctx.typeOfDeath}_second_declaration_death_date`)}`,
                  modelValue: _ctx.editState.secondDeclarationDeathDate,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.secondDeclarationDeathDate) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_time_input, {
                  inputId: "recovery-information-second_declaration_death_time",
                  name: `${_ctx.$t(`${_ctx.typeOfDeath}_second_declaration_death_time`)}`,
                  modelValue: _ctx.editState.secondDeclarationDeathTime,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.secondDeclarationDeathTime) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row-break d-none d-xl-block d-xxl-none" }, null, -1)),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_date_input, {
                  inputId: "recovery-information-cross_clamp_date",
                  name: `${_ctx.$t('cross_clamp_date')}`,
                  modelValue: _ctx.editState.crossClampDate,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.crossClampDate) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_time_input, {
                  inputId: "recovery-information-cross_clamp_time",
                  name: `${_ctx.$t('cross_clamp_time')}`,
                  modelValue: _ctx.editState.crossClampTime,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.crossClampTime) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ])
            ])
          ], 64))
    ]),
    _: 1
  }, 8, ["title", "disabled"]))
}