import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.isProtoRecepientListingsEnabled)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: 'proto-recipients' }
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Patients ")
          ])),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: { name: 'list-recipients' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
          ]),
          _: 1
        }))
  ]))
}