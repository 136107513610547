import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_4 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoHeartVadVladEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "proto_heart_vad_lvad",
        title: "VAD/LVAD"
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: true,
            createText: "Create VAD/LVAD",
            onTableCreateRow: _ctx.clearForm
          }, {
            "button-bar": _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_filter_component, {
                    fieldID: "contactFilter",
                    showFilter: false,
                    showArchived: false
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["onTableCreateRow"]),
          _createVNode(_component_table_list, {
            ref: "vadLvadResultsTable",
            tabbableColumn: "calculated_date",
            "table-id": "echo-result-table",
            "table-config": _ctx.vadLvadeTableConfig,
            onTableRowClick: _ctx.editSelected,
            highlightSelection: true,
            rowStyleClass: _ctx.rowStyleClass
          }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
          _createVNode(_component_form_layout, { "form-id": "vadLvade-form" }, {
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                (_ctx.isNew)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " NEW VAD/LVAD "))
                  : (_openBlock(), _createElementBlock("h5", _hoisted_4, " SELECTED VAD/LVAD "))
              ])
            ]),
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_date_input, {
                    inputId: "vad-implant_date",
                    name: "Implant Date",
                    modelValue: _ctx.editState.vad_implant_date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.vad_implant_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_select_input, {
                    "select-id": "vad_type",
                    name: "VAD Type",
                    options: _ctx.vadTypeOptions,
                    modelValue: _ctx.editState.vad_type,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.vad_type) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_text_input, {
                    "input-id": "vad-brand",
                    name: "Brand",
                    modelValue: _ctx.editState.brand,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.brand) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_date_input, {
                    inputId: "vad-explant_date",
                    name: "Explant Date",
                    modelValue: _ctx.editState.vad_explant_date,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.vad_explant_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_select_input, {
                    "select-id": "vad_raeson",
                    name: "Reason for Explant",
                    options: _ctx.reasonOptions,
                    modelValue: _ctx.editState.reason,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.reason) = $event))
                  }, null, 8, ["options", "modelValue"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveVadLvadResultsTable",
                label: "Save VAD/LVAD",
                cancelButton: true,
                onSave: _ctx.performSave,
                onCancel: _ctx.clearForm
              }, null, 8, ["onSave", "onCancel"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}