<template>
  <div v-if="isProtoLivingDonationEnabled">
  <card-section
    section-id="proto_kidney_living_donation"
  >
    <template v-slot:header>
      Living Donation Information
    </template>
    <template v-slot:body>
      <form>
        <sub-section
          sub-section-id="proto-Kidney-information-section"
        >
        <template v-slot:contents>
        
          <!-- Form layout -->
            <form-layout
            form-id="livingDonation-form">
              <template v-slot:contents>
                <div class="row">
                  <div class="standard-form-group">
                    <checkbox-input 
                      name="directed-ld" 
                      labelName="Directed LD?"
                      inputId="directed-ld" 
                      v-model="editState.directed_ld" 
                      label='Yes' />
                  </div>
                  <div class="standard-form-group"></div>
                  <div class="standard-form-group">
                    <date-input
                      inputId="ld-surgery-date"
                      name='LD Surgery Date'
                      v-model="editState.ld_surgery_date"   
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-form-group">
                    <checkbox-input 
                      name="list-exchange" 
                      labelName="List Exchange?"
                      inputId="list-exchange" 
                      v-model="editState.list_exchange" 
                      label='Yes' />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      inputId="list-exchange-registration-date"
                      name='List Exchange Registration Date'
                      v-model="editState.list_exchange_registration_date"   
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      inputId="list-exchange-surgery-date"
                      name='List Exchange Surgery Date'
                      v-model="editState.list_exchange_surgery_date"   
                    />
                  </div>
                    <div class="standard-form-group">
                      <date-input
                        inputId="list-exchange-removal-date"
                        name='List Exchange Removal Date'
                        v-model="editState.list_exchange_removal_date"   
                      />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-form-group">
                    <checkbox-input 
                      name="kpd" 
                      labelName="KPD?"
                      inputId="kpd" 
                      v-model="editState.kpd" 
                      label='Yes' />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      inputId="kpd-registration-date"
                      name='KPD Registration Date'
                      v-model="editState.kpd_registration_date"   
                    />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      inputId="kpd-surgery-date"
                      name='KPD Surgery Date'
                      v-model="editState.list_exchange_surgery_date"   
                    />
                  </div>
                    <div class="standard-form-group">
                      <date-input
                        inputId="kpd-removal-date"
                        name='KPD Removal Date'
                        v-model="editState.kpd_removal_date"   
                      />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-full-width-group">
                    <text-area-input input-id="living-donations-comments" name="Comments" rows="4" v-model="editState.comments"  />
                  </div>
                </div>
      

              </template>
              <template v-slot:save>
                <save-toolbar
                  :show="true"     
                  ref="saveLivingDonationInformation"
                  label="Save Living Donation Details"
                  :cancelButton="true"
                  @save="performSave()"
                  @cancel="clearForm()"
                />
              </template>
            </form-layout>
          </template>
        </sub-section>
      </form>
    </template>
  </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';


const EDITSTATE_KEY = 'editState';

import {
  SaveProvider,
} from '@/types';

interface LivingDonationInformationForm {
  directed_ld: boolean|null,
  ld_surgery_date: Date|null,
  list_exchange: boolean|null,
  list_exchange_registration_date: Date|null,
  list_exchange_surgery_date: Date|null,
  list_exchange_removal_date: Date|null,
  kpd: boolean|null,
  kpd_registration_date: Date|null,
  kpd_surgery_date: Date|null,
  kpd_removal_date: Date|null,
  comments: string
}


@Component({
  components: {
    CardSection,
    SubSection, 
    CheckboxInput,
    SelectInput,
    SaveToolbar,
    FormLayout,
    TextAreaInput,
    DateInput    
  },
  emits: [
    'cancel',
  ],
})

export default class ProtoLivingDonation extends Vue {

  private editState: LivingDonationInformationForm = {
    directed_ld: false,
    ld_surgery_date: null,
    list_exchange_registration_date: null,
    list_exchange: false,
    list_exchange_surgery_date: null,
    list_exchange_removal_date: null,
    kpd: false,
    kpd_registration_date: null,
    kpd_surgery_date: null,
    kpd_removal_date: null,
    comments: ""
  };

  get isProtoLivingDonationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.KidneyLivingDonation);
  }

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  // Each save toolbar will need a unique ref
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveLivingDonationInformation as SaveToolbar;
  }

   // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
   async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.KidneyLivingDonation, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: LivingDonationInformationForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        directed_ld: response.directed_ld,
        ld_surgery_date: response.ld_surgery_date,
        list_exchange: response.list_exchange,
        list_exchange_registration_date: response.list_exchange_registration_date,
        list_exchange_surgery_date: response.list_exchange_surgery_date,
        list_exchange_removal_date: response.list_exchange_removal_date,
        kpd: response.kpd,
        kpd_registration_date: response.kpd_registration_date,
        kpd_surgery_date: response.kpd_surgery_date,
        kpd_removal_date: response.kpd_removal_date,
        comments: response.comments
       
      };
    }

  public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: LivingDonationInformationForm = {
        directed_ld: this.editState.directed_ld,
        ld_surgery_date: this.editState.ld_surgery_date,
        list_exchange: this.editState.list_exchange,
        list_exchange_registration_date: this.editState.list_exchange_registration_date,
        list_exchange_surgery_date: this.editState.list_exchange_surgery_date,
        list_exchange_removal_date: this.editState.list_exchange_removal_date,
        kpd: this.editState.kpd,
        kpd_registration_date: this.editState.kpd_registration_date,
        kpd_surgery_date: this.editState.kpd_surgery_date,
        kpd_removal_date: this.editState.kpd_removal_date,
        comments: this.editState.comments
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 


    public clearForm(): any {
      this.editState.directed_ld = false;
      this.editState.ld_surgery_date = null;
      this.editState.list_exchange = false;
      this.editState.list_exchange_registration_date = null;
      this.editState.list_exchange_surgery_date = null;
      this.editState.list_exchange_removal_date = null;
      this.editState.kpd = false;
      this.editState.kpd_registration_date = null;
      this.editState.kpd_surgery_date = null;
      this.editState.kpd_removal_date = null;
      this.editState.comments = "";
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveLivingDonationInformation as unknown as SaveProvider;

      saveToolbar.resetSaveToolbar();
    }
    

}
</script>
