import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "time-hours" }
const _hoisted_2 = { class: "time-minutes" }
const _hoisted_3 = { class: "time-minutes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.running)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass([_ctx.expired === false ? 'active' : 'expired', "clock-wrapper"])
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(`${_ctx.expired === false ? '' : '-'}`) + " " + _toDisplayString(_ctx.hours.toString().padStart(2, 0)), 1),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "time-divider" }, " : ", -1)),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.minutes.toString().padStart(2, 0)), 1),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "time-divider" }, " : ", -1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.seconds.toString().padStart(2, 0)), 1)
      ], 2))
    : _createCommentVNode("", true)
}