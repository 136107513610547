import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "form-group col-sm-4" }
const _hoisted_4 = { class: "modal-footer-body" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["onClick", "disabled"]
const _hoisted_7 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "discontinueValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "discontinue-organs-modal",
        ref: "discontinueOrgansModal",
        centered: true,
        size: "md",
        closeButton: false
      }, _createSlots({
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('discontinue_allocation_recommendation')), 1)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal())),
              disabled: _ctx.isLoadingAllocation || _ctx.isDiscontinuingAllAllocations || _ctx.isDiscontinuingOneAllocation
            }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_5),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-success",
              onClick: ($event: any) => (handleSubmit(_ctx.performDiscontinue)),
              disabled: _ctx.isLoadingAllocation || _ctx.isDiscontinuingAllAllocations || _ctx.isDiscontinuingOneAllocation
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('discontinue_donor_allocations')) + " ", 1),
              (_ctx.isDiscontinuingAllAllocations || _ctx.isDiscontinuingOneAllocation)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-spin",
                      icon: ['far', 'spinner-third']
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_6)
          ])
        ]),
        _: 2
      }, [
        (_ctx.editState)
          ? {
              name: "body",
              fn: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.editState.modalType == 1 ? _ctx.$t('confirm_discontinue_all_allocations_for_donor') : _ctx.$t('discontinue_allocation_recommendation')), 1),
                (!!_ctx.modalErrorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.modalErrorMessage), 1))
                  : _createCommentVNode("", true),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_select_input, {
                      selectId: "discontinue-type",
                      name: _ctx.$t('discontinue_reason'),
                      rules: "required",
                      modelValue: _ctx.editState.reason,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.reason) = $event)),
                      options: _ctx.editState.discontinueReasons
                    }, null, 8, ["name", "modelValue", "options"])
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1536)
    ]),
    _: 1
  }, 512))
}