import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "nav action-row mt-0" }
const _hoisted_2 = {
  key: 1,
  class: "row"
}
const _hoisted_3 = { class: "form-group col-sm-4" }
const _hoisted_4 = { class: "form-group col-sm-4" }
const _hoisted_5 = { class: "form-group col-sm-4" }
const _hoisted_6 = { for: "perform-specified-vxm-donor-id" }
const _hoisted_7 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_8 = { class: "modal-footer-body" }
const _hoisted_9 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    id: "perform-specified-vxm",
    ref: "performSpecifiedVxmValdiations",
    autocomplete: "off"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      (_ctx.checkAllowed('/recipients/:recipient_id/history_vxm', 'POST'))
        ? (_openBlock(), _createBlock(_component_sub_section, {
            key: 0,
            title: _ctx.$t('perform_specified_vxm'),
            "sub-section-id": "hla-perform-specified-vxm-controls",
            class: "view-virtual-crossmatch"
          }, {
            contents: _withCtx(() => [
              _createElementVNode("nav", _hoisted_1, [
                _createVNode(_component_save_toolbar, {
                  buttonClass: "btn btn-wide btn-primary mt-0",
                  ref: "performSpecificVxmSaveButton",
                  savingText: _ctx.$t('generating_vxm_results'),
                  successText: _ctx.$t('vxm_results_generated'),
                  defaultErrorText: _ctx.$t('problem_generating_vxm'),
                  onSave: _ctx.openModal,
                  disabled: _ctx.saving,
                  label: _ctx.$t('perform_specified_vxm')
                }, null, 8, ["savingText", "successText", "defaultErrorText", "onSave", "disabled", "label"])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      _createVNode(_component_modal_section, {
        "modal-id": "perform-specified-vxm-dialogue-box",
        ref: "performSpecifiedVxmModal",
        class: "modal-sticky-header",
        centered: true
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('perform_specified_vxm')), 1)
        ]),
        body: _withCtx(() => [
          (!_ctx.editState)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_text_input, {
                    "input-id": "perform-specified-vxm-recipient-id",
                    name: _ctx.$t('recipient_id'),
                    disabled: _ctx.recipientId,
                    modelValue: _ctx.editState.recipientId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.recipientId) = $event)),
                    rules: "required"
                  }, null, 8, ["name", "disabled", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_select_input, {
                    "select-id": "perform-specified-vxm-donor-type",
                    class: "form-group",
                    name: _ctx.$t('donor_type'),
                    modelValue: _ctx.editState.donorType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.donorType) = $event)),
                    rules: "required",
                    options: _ctx.donorTypes
                  }, null, 8, ["name", "modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_validation_provider, {
                    rules: { no_invalid_tags: { tags: _ctx.editState.donorIdTags }, requires_tags: { tags: _ctx.editState.donorIdTags } },
                    name: _ctx.$t('donor_id'),
                    vid: "perform-specified-vxm-donor-id",
                    class: "generic-tags"
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("label", _hoisted_6, [
                        _createTextVNode(_toDisplayString(_ctx.$t('donor_id')) + " ", 1),
                        _cache[3] || (_cache[3] = _createElementVNode("span", null, [
                          _createElementVNode("i", null, "*")
                        ], -1))
                      ]),
                      _createVNode(_component_vue_tags_input, {
                        id: "perform-specified-vxm-donor-id",
                        placeholder: "",
                        "add-on-key": [13, ' '],
                        "allow-edit-tags": false,
                        "autocomplete-min-length": 1,
                        "max-tags": 1,
                        separators: [' '],
                        validation: _ctx.validateDonorIdTag(),
                        modelValue: _ctx.editState.donorIdInput,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.donorIdInput) = $event)),
                        "autocomplete-items": _ctx.filteredDonorIdAutocompleteOptions,
                        onBeforeAddingTag: _ctx.beforeDonorIdChanged,
                        onTagsChanged: _ctx.onDonorIdChanged,
                        tags: _ctx.editState.donorIdTags,
                        class: _normalizeClass({ 'is-invalid': errors[0] })
                      }, null, 8, ["validation", "modelValue", "autocomplete-items", "onBeforeAddingTag", "onTagsChanged", "tags", "class"]),
                      (errors[0])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors, (error, index) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: `perform-specified-vxm-donor-id-error-${index}`
                              }, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: ['far', 'exclamation-circle'],
                                  "fixed-width": ""
                                }),
                                _createTextVNode(" " + _toDisplayString(error), 1)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["rules", "name"])
                ])
              ]))
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('cancel')), 1),
            _createElementVNode("a", {
              class: "btn btn-success",
              onClick: ($event: any) => (handleSubmit(_ctx.confirmSpecifiedVxm))
            }, _toDisplayString(_ctx.$t('confirm')), 9, _hoisted_10)
          ])
        ]),
        _: 2
      }, 1536)
    ]),
    _: 1
  }, 512))
}