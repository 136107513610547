<template>
  <card-section 
    section-id="pancreas-whole-specific-details"
    :key="uiJourney.journeyId">
    <template v-slot:header>
      {{$t('pancreas_specific_details')}}
    </template>
    <template v-slot:body>
      <sub-section
        sub-section-id="psd-general"
        :title="$t('pancreas_info')"
        :disabled="!uiJourney.canSave || newJourney"
        ref="pancreasWholeSpecificDetails"
      >
        <template v-slot:contents>
          <template v-if="isLoading">
            {{$t('loading')}}
          </template>
          <template v-else>
            <!-- Form layout -->
            <form-layout
              :disabled="!enableForm"
              form-id="pancreas-whole-specific-details-form"
            >
              <template v-slot:contents>
                <div class="row">
                  <div class="standard-form-group">
                    <checkbox-input
                      input-id="vessels-required"
                      ruleKey="organ_specific_details.vessels_required"
                      :labelName="$t('vessels_required')"
                      :label="$t('yes')"
                      v-model="editState.vesselsRequired"
                    />
                  </div>
                  <div class="standard-comment-group">
                    <text-area-input
                      inputId="general-comments"
                      ruleKey="organ_specific_details.general_comments"
                      :name="$t('general_comments')"
                      v-model="editState.comments" />
                  </div>
                </div>
              </template>

              <template v-if="!newJourney && enableSaveToolbar" v-slot:save>
                <save-toolbar
                  :show="showSaveToolbar"
                  ref="pancreasSpecificSaveToolbar"
                  class="card-footer action-row temp-saving row"
                  :label="$t('save_pancreas_specific_details')"
                  :cancelButton="true"
                  @save="handleSave()"
                  @cancel="handleCancel()"
                />
              </template>
            </form-layout>
          </template>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<i18n src="@/components/organs/pancreas/_locales/PancreasWholeSpecificDetails.json"></i18n>
<i18n src="./../shared/_locales/common.json"></i18n>

<script lang="ts">
import TextInput from "@/components/shared/TextInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import { Component, Vue, Prop } from "vue-facing-decorator";
import { IdLookup } from '@/store/validations/types';
import CardSection from "@/components/shared/CardSection.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import { SaveResult, APIPermittedActions } from "@/types";
import SubSection from '@/components/shared/SubSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { UIPancreasWholeSpecificDetails } from '@/UIModels/journey/pancreasWholeSpecificDetails';
import { IdLookupProvider } from '@/types';
import { i18nMessages } from "@/i18n";
import { UIJourney } from '@/UIModels/journey';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    TextInput,
    TextAreaInput,
    SubSection,
    CardSection,
    SelectInput,
    CheckboxInput,
    FormLayout,
    SaveToolbar
  },
  ...i18nMessages([
    require('@/components/organs/pancreas/_locales/PancreasWholeSpecificDetails.json'),
    require('./../shared/_locales/common.json'),
  ]),
  emits: [
    'loaded',
    'handleErrors',
    'clear',
  ],
})
export default class PancreasWholeSpecificDetails extends Vue implements IdLookupProvider {
  // Props
  @Prop({ default: false }) newJourney!: boolean;

  private editState: UIPancreasWholeSpecificDetails = UIPancreasWholeSpecificDetails.buildNew();
  private permittedActions: APIPermittedActions[] = [];
  private isLoading = false;

  get uiJourney(): UIJourney {
    return useCurrentPageStore().currentJourney as UIJourney;
  }

  get loaded(): boolean {
    return this.isLoading;
  }
  
  private resetEditState(): void {
    if (!this.editState) return;
    this.editState = this.editState.copyViewModel();
  }

  private async loadSpecificDetails(): Promise<void> {
    const currentPageStore = useCurrentPageStore();
    if (!this.uiJourney) return;

    try {
      const response = await this.uiJourney.loadPancreasWholeSpecificDetails();
      // get permitted actions
      this.permittedActions = response.responseData;
      // get editstate
      this.editState = this.uiJourney.pancreasWholeSpecificDetails as UIPancreasWholeSpecificDetails;
      this.isLoading = false;
      this.$emit('loaded', 'pancreasWholeDetails');
    } catch(uiError: any) {
      this.isLoading = false;
      console.warn('Something unexpected happen when attempting to get pancreas information', uiError);
    }
  }

  mounted(): void {
    this.loadSpecificDetails();
  }

  /**
   * Populates the Pancreas Whole Specific Details form state with data from
   */
  public initializeForm(): void {
    // Begin fetching the recipient's 
    this.$store.dispatch("recipients/get", this.uiJourney.clientId);
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    if (!this.uiJourney) return;

    const params = {
      recipient: this.uiJourney.clientId,
      journey: this.uiJourney.journeyId
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    // re-load details
    this.loadSpecificDetails();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
    this.$emit("handleErrors", errors);
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.resetEditState();
    this.resetSaveToolbar();
    this.$emit('clear', this.editState);
  }

  // Reference to Pancreas Toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.pancreasSpecificSaveToolbar;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  // Dismiss save toolbar success or error indicator
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    // If no permitted actions return false, do not show on read-only or new
    if (this.permittedActions.length === 0) return false;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // General Pancreas Whole Details
    const result = {
      'organ_specific_details.organ_specific_details.general_comments' : 'general-comments',
      'organ_specific_details.organ_specific_details.vessels_required' : 'vessels-required',
    };
    return result;
  }

  // TODO: TECH_DEBT: remove after all organ specific details components are moved into UI view model pattern
  // returns null for original EditOrgan methods
  public extractPatch(): null {
    return null;
  }
}
</script>
