import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "footer",
  class: "content-wrap py-3 mt-2 border-top"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-2" }
const _hoisted_5 = { class: "d-block mb-3" }
const _hoisted_6 = { class: "col-sm-9" }
const _hoisted_7 = { class: "footer-brand float-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, " © " + _toDisplayString(_ctx.currentYear), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-1 text-center" }, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('system')), 1)
        ])
      ])
    ])
  ]))
}