import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_4 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoHeartEchoResultEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "proto-echo-result-section",
        title: _ctx.title
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: true,
            createText: "Create Echo Result",
            onTableCreateRow: _ctx.clearForm
          }, {
            "button-bar": _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_filter_component, {
                    fieldID: "contactFilter",
                    showFilter: false,
                    showArchived: false
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["onTableCreateRow"]),
          _createVNode(_component_table_list, {
            ref: "echoResultsTable",
            tabbableColumn: "calculated_date",
            "table-id": "echo-result-table",
            "table-config": _ctx.echoResultTableConfig,
            onTableRowClick: _ctx.editSelected,
            highlightSelection: true,
            rowStyleClass: _ctx.rowStyleClass
          }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
          _createVNode(_component_form_layout, { "form-id": "echoResult-form" }, {
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                (_ctx.isNew)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " NEW ECHO RESULT "))
                  : (_openBlock(), _createElementBlock("h5", _hoisted_4, " SELECTED ECHO RESULT "))
              ])
            ]),
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_date_input, {
                    inputId: "echo-test_date",
                    name: "Test Date",
                    modelValue: _ctx.editState.echo_test_date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.echo_test_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_number_input, {
                    inputId: "echo-ef",
                    name: "EF",
                    append: true,
                    appendText: "%",
                    step: "0.1",
                    modelValue: _ctx.editState.echo_ef,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.echo_ef) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_number_input, {
                    inputId: "ivsd",
                    name: "IVSd",
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    modelValue: _ctx.editState.ivsd,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.ivsd) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_number_input, {
                    inputId: "lvidd",
                    name: "LVIDd",
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    modelValue: _ctx.editState.lvidd,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.lvidd) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_number_input, {
                    inputId: "lvpwd",
                    name: "LVPWd",
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    modelValue: _ctx.editState.lvpwd,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.lvpwd) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_number_input, {
                    inputId: "echo-fs",
                    name: "FS",
                    append: true,
                    appendText: "%",
                    step: "0.1",
                    modelValue: _ctx.editState.echo_fs,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.echo_fs) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_number_input, {
                    inputId: "ivss",
                    name: "IVSs",
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    modelValue: _ctx.editState.ivss,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.ivss) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_number_input, {
                    inputId: "lvids",
                    name: "LVIDs",
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    modelValue: _ctx.editState.lvids,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.lvids) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_number_input, {
                    inputId: "lvpws",
                    name: "LVPWs",
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    modelValue: _ctx.editState.lvpws,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.lvpws) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_text_area_input, {
                    "input-id": "outcome",
                    name: "Outcome",
                    rows: "4",
                    modelValue: _ctx.editState.outcome,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.outcome) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveEcho",
                label: "Save Echo Result",
                cancelButton: true,
                onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.performSave())),
                onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.clearForm()))
              }, null, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}