<template>
  <div v-if="isProtoChecklistsReorderEnabled">


    <card-section section-id="proto-checklists-reorder"
      v-if="editState">
      <template v-slot:header>
        Checklists (Reorder)
      </template>
      <template v-slot:body>
        <div>
          <fieldset id="phase_status">
            <legend>
              <h4 class="legend-title">
                <span>Checklists</span>
              </h4>
            </legend>
          </fieldset>
        </div>

        <fieldset id="link-to-donor-history">
          <div class="row">

            <div class="col-sm-12">
              <div class="d-flex pb-2 border-bottom">
                  <div>
                    <div class="dropdown" v-if="editState.tasks.length == 1">
                      <button class="btn btn-outline-secondary dropdown-toggle form-select-checklist" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{selectedTasklistName ? selectedTasklistName : editState.tasks[0].task_name}}
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a v-for="(task) in  getTasklistOptions()" :key="task.id" class="dropdown-item"
                          @click.prevent="setTasklist($event, task)" href="#">{{task.task_name}}</a>
                      </div>
                    </div>

                  </div>


              </div>
            </div>
          </div>

          <div class="filterSectionWrapper">
            <div class="filter-section-action-row">
              <proto-filter-component :displayFilter="displayFilter" @updateFilter="updateFilter" :completedFilter="true"
                :checklistFilter="true" fieldID="checklistFilter" :checklistStatusOptions="statusOptions"
                :hideSpacer="false" :setFilters='setFilters' />


            </div>

          </div>

          <div class="row" v-if="selectedTasklistID">
              <div v-for="(task) in editState.tasks" :key="task.task_id" class=" tasklist-body w-100">
                <div class="col-sm-12">
                  <div v-if="selectedTasklistID == task.task_id">
                    <div v-for="(list, index) in task.list_rows" :key="list.list_id" class="right-pane-wrapper">

                      <div v-if="list.list_id == selectedCategory || selectedCategory == 'all'" class="right-pane"
                        :class="index == 0 ? 'first': ''">

                        <ul>
                          <li class="sub-task-group-header">
                            <div class="nav-group">
                              <button type="button" class="btn btn-sm tasklist-accordion-toggle"
                                aria-label="Collapse Group" tabindex="0"
                                @click.prevent="toggleSubList(task.task_id, list.list_id)">

                                <font-awesome-icon :icon="['fas', 'caret-right']" class="tasklist-accordion-icon"
                                  :class="{ rotate: list.active }" />
                              </button>

                              <span class="task-line-text">
                                <span class="task-line-name">{{list.list_name}}</span> </span>                       
                                <div class="completed-items completed-items-small">
                                <span v-if="taskRows" class="completed-value">{{` ${getCompleted(task.task_id, list.list_id)} / ${getTotalSubTask(task.task_id, list.list_id)}  `}}</span>
                                <span class="completed-label">Completed</span>
                              </div>

                    
                            </div>

                            <div class="nav-group-button">
                               
                              <span class="completed-items completed-items-large"> <span v-if="taskRows" class="completed-value">{{` ${getCompleted(task.task_id, list.list_id)} / ${getTotalSubTask(task.task_id, list.list_id)}  `}}</span>
                                <span class="completed-label">Completed</span></span>
                              <div class="action-bar">
                            
                              <button v-if="getTotalSubTask(task.task_id, list.list_id) > 0" type="button"
                              :disabled="isReordering && list.list_id !== idOfCurrentReOrdering"
                              class="btn btn-sm btn-secondary visible-on-desktop"
                              @click.prevent="allowReOrderingOfSection(task.task_id, list.list_id)">
                              {{getReOrderText(list.list_id)}}
                            </button>
                            <button type="button"
                              :class="list.list_id == selectedTaskID ? 'btn-secondary' : 'btn-primary'"
                              class="btn btn-sm createButton" :disabled="isCreating || isReordering"
                              @click.prevent="openCreateListItem(task.task_id, list.list_id)">
                              Create Checklist Item
                            </button> </div>
                            </div>
                          </li>
                        </ul>

                      </div>

                      <div v-if="taskRows && list.active">
                        <button
                            type="button"
                            v-if="canBeReOrdered(list.list_id)"
                            class="btn btn-link"
                            @click="showKeyboardInstructions = !showKeyboardInstructions"
                            @keydown.enter.prevent="showKeyboardInstructions = !showKeyboardInstructions"
                            v-text="keyboardInstructionsButtonText"
                          />
                          <p
                            v-if="showKeyboardInstructions" 
                            id="reorder_instructions"
                            class="text-sm leading-normal mt-2"
                           v-text="`You are reordering ${list.list_name } It can be reordering by drag and drop by selecting at the 'pill handle' and dragging to its new position, or by using the keyboard navigation by selecting a checklist item via the tab, pressing the spacebar to grab it. and using the up and down arrows to change position, then pressing the spacebar to drop`" />

                          <div aria-live="assertive" class="sr-only" v-text="assistiveText" />

                          <draggable :forceFallback="true" v-model="taskRows"
                            :disabled="!isReordering || idOfCurrentReOrdering !== list.list_id" handle=".pill" tag="ul"
                            :move="onMove" v-bind="dragOptions" :group="{ name: 'taskRows', pull: 'clone', put: false }"
                            @change="onchange" @start="isDragging=true" @end="isDragging=false">

                              <li v-for="(row) in taskRows.filter((row) => { return row.task_id == task.task_id && row.list_id == list.list_id; })" :key="row.id"
                                class="my-3 flex-column" :index="index" aria-describedby="reorder_instructions"
                                tabindex="0" :id="`checklist-item-${row.id}`" :class="`${rowStyleClass(row)}`"
                                :draggable="!isMobile" @keydown.space.prevent="grabItem(row)"
                                @keydown.down.prevent="moveItem('movedown', row, list.list_id)"
                                @keydown.up.prevent="moveItem('moveup', row, list.list_id)">

                                <div class="task-wrapper" :id="`task-wrapper-${row.id}`">
                                  <div class="taskView taskDetails" v-if="!row.isNew">
                                      <div
                                        :class="`pill pill-${getCategoryValue(row.status, 'type')}`">
                                        <div v-if="isReordering && canBeReOrdered(row.list_id)" :class="`${canBeReOrdered(row.list_id) ? 'pill-moving' : ''}`">
                                          <div class="sq" />
                                          <div class="sq" />
                                          <div class="sq" />
                                          <div class="sq" />
                                          <div class="sq" />
                                          <div class="sq" />
                                        </div>
                                      </div>
                                    <div class="task-content">
                                      <div class="task-body">
                                        <button class="task-line-text" aria-label="Edit Item" tabindex="0"
                                          :disabled="isReordering" @keydown.down.prevent="toggleEditScreen(row.id, row)"
                                          @click.prevent="toggleEditScreen(row.id, row)">
                                          {{row.name}}
                                        </button>
                                        <div class="ml-auto">

                                          <span
                                            :class="`badge badge-pill badge-checklist badge-${getCategoryValue(row.status, 'type')}`">{{getCategoryValue(row.status, 'name')}}</span>
                                        </div>
                                      </div>
                                      <div class="task-meta">
                                        <ul class="list-inline list-annotation">

                                          <li class="list-inline-item">
                                            <span>Completed Date: </span>
                                            <span>{{row.completed_date && row.completed_date != '' ? formatDateObject(row.completed_date) : "--"}}</span>
                                          </li>

                                          <li class="list-inline-item"><span>Completed By: </span>
                                            <span>{{row.completed_by ? row.completed_by : "--"}}</span></li>

                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div v-if="selectedSubTaskItems.includes(row.id)" class='task-edit task-wrapper-form'>
                                    <div class="taskView">
                                      <div class="task-body">
                                        <legend>
                                          <h4 class="legend-title">
                                            <span>CHECKLIST ITEM</span>
                                          </h4>
                                        </legend>
                                        <proto-task-form @cancel="cancelEdit" :selectedItems="selectedItems" :id="row.id"
                                          @update="updateItem"
                                          :taskOptions="getTasksOptions(task.task_id, task.list_rows)"
                                          :statusOptions="statusOptions" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="task-new task-wrapper-form" :id="`task-wrapper-form-new-${row.id}`"
                                  v-if="row.isNew">
                                  <div class="taskView">
                                    <div class="task-body">
                                      <legend>
                                        <h4 class="legend-title">
                                          <span>NEW CHECKLIST ITEM</span>
                                        </h4>
                                      </legend>
                                      <proto-task-form @cancel="confirmPopup(row.id)" :id="row.id" :isNew="row.isNew"
                                        :selectedItems="[row]" @save="saveItem"
                                        :taskOptions="getTasksOptions(task.task_id, task.list_rows)"
                                        :statusOptions="statusOptions" />
                                    </div>
                                  </div>
                                </div>


                              </li>

                          </draggable>
     
                          <div class="task-wrapper my-4" v-if="isTaskRowEmpty(list.list_id)">
                            <div class="taskView">
                              There are no items to show

                            </div>
                          </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </fieldset>

      </template>
    </card-section>
    <modal-section modalId="checklistCancelModal" ref="checklistCancelModal" class="modal-sticky-header"
      :centered="true" :closeButton="false" size="md" :wide="false">
      <template v-slot:body>
        Are you sure that you want to cancel creating new checklist items

      </template>
      <template v-slot:footer>
        <div class="modal-footer-body">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            Cancel
          </button>
          <a class="btn btn-success" data-dismiss="modal" @click="cancelCreate()">
            OK
          </a>
        </div>
      </template>
    </modal-section>
  </div>
</template>



<script lang="ts">
  import {
    Getter,
    State
  } from 'vuex-facing-decorator';
  import {
    mixins
  } from "vue-facing-decorator";
  import {
    Component,
    Vue,
    Watch,
  } from 'vue-facing-decorator';
  import {
    DateUtilsMixin
  } from "@/mixins/date-utils-mixin";
  import CardSection from "@/components/shared/CardSection.vue";
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import ProtoFilterComponent from '@/prototypes/shared/ProtoFilterComponent.vue';
  import ProtoTaskForm from '@/prototypes/organs/ProtoTaskForm.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import ModalSection from '@/components/shared/ModalSection.vue';
  import ProtoSaveToolbarTop from '@/prototypes/shared/ProtoSaveToolbarTop.vue';
  import { VueDraggableNext } from 'vue-draggable-next';
  import RecipientTaskListData from '@/prototypes/json/RecipientTaskListData.json';
  import { i18nMessages } from '@/i18n';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';

  const TASK_STATE_ITEM_KEY = 'task-state';

  @Component({
    components: {
      CardSection,
      SaveToolbar,
      SelectInput,
      TextAreaInput,
      ProtoFilterComponent,
      TextInput,
      CheckboxInput,
      ProtoTaskForm,
      ModalSection,
      draggable: VueDraggableNext,
      ProtoSaveToolbarTop
    },
    ...i18nMessages([
      require('@/components/organs/shared/_locales/ChecklistSection.json'),
    ]),
  })
  export default class ProtoChecklistsReorder extends mixins(DateUtilsMixin) {
    @State(state => state.pageState.currentPage.taskList) editState!: any;

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;

    private comment: any = "";

    private tasks: any = [];
    private showHidden: any = '';
    private filterStatus = '';
    private selectedCategory: any = "task_1";
    private selectedTasklistID: any = "";
    private selectedTasklistName: any = "";
    private taskRows: any = [];
    private selectedTaskID: any = '';
    private selectedSubTaskItems: any = [];
    private displayFilter = true;
    private setFilters: any = {
      includeCompleted: {value: true}
    };
    private emptyForm = false;
    private selectedGroup = '';
    private name = '';
    private external = '';
    public selectedItems: any = [];
    private isCreating = false;
    private isReordering = false;
    private idOfCurrentReOrdering = '';
    private isDragging = false
    private isGrabbed = false
    private grabbedValue = '';
    private grabbedItem = '';
    private assistiveText = '';
    private currentListName = '';
    private showKeyboardInstructions = false;
    private isMobile = false;


    public checkwidth() {
      this.isMobile = window.innerWidth < 800;
    }


    onMove(evt: any, originalEvent: any) {
      if (evt.relatedContext.element.isNew == true) {
        return false;
      }
    }

    get isProtoChecklistsReorderEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ChecklistReorder);
    }

    get keyboardInstructionsButtonText() {
      return this.showKeyboardInstructions ? 'Hide Reorder Instructions' : 'Show Reorder Instructions';
    }


    private scrollToMethod(id: any): any {
      const location = document.getElementById(id);

      if (location) {
        const elementPosition = location.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 105;

        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }

    private grabItem(item: any) {
      if (this.canBeReOrdered(item.list_id)) {
        if (this.grabbedItem == item.id) {
        this.grabbedItem = '';
        this.handleItemGrabbed(item, item.list_id, 'dropped');
      } else {
        this.handleItemGrabbed(item, item.list_id, 'grabbed');
        this.grabbedItem = item.id;
      }
      }

    }

    getGroupOfItem(listID: any) {
      let chosenList: any = [];
      this.taskRows.forEach((task: any) => {
        if (task.list_id == listID) {
          chosenList.push(task);
        }
      });
      return chosenList;
    }

    private handleItemGrabbed(checklistItem: any, listID: any, type: any) {
      let chosenList = this.getGroupOfItem(listID);

      let findIndex = chosenList.findIndex((element: any) => element.id == checklistItem.id);
      let findListIndex = this.tasks.findIndex((element: any) => element.task_id == checklistItem.task_id);
      let findList = this.tasks[findListIndex].list_rows.find((element: any) => element.list_id == listID);
      this.currentListName = findList.list_name;

      if (type == 'grabbed') {
        this.assistiveText =
          `${checklistItem.name}, grabbed. Current position in ${this.currentListName} checklist: ${this.getItemPosition(chosenList, findIndex)}. Press up and down arrows to change position, spacebar to drop.`;
      } else {
        chosenList = this.getGroupOfItem(listID);
        let findIndex = chosenList.findIndex((element: any) => element.id == checklistItem.id);
        this.assistiveText =
          `${checklistItem.name}, dropped. Final position in  ${this.currentListName} checklist: ${this.getItemPosition(chosenList, findIndex)}`;
      }
    }

    getItemPosition(list: any, index: any) {
      return `${index + 1} of ${list.length}`;
    }

    getCurrentIndex(rowID: any) {
      return this.taskRows.findIndex((e: any) => e.id === rowID);
    }

    checkIndex(index: any, listID: any, direction: any) {
      let findFirstIndex = this.taskRows.findIndex((element: any) => element.list_id == listID);
      let findLastIndex = this.taskRows.findLastIndex((element: any) => element.list_id == listID);
      if (direction == 'down') {
        return index == findLastIndex ? false : true;
      } else if (direction == 'up') {

        return index == findFirstIndex ? false : true;
      }

    }

    moveItem(moveLocation: any, row: any, listID: any) {
      if (this.grabbedItem == row.id) {
        let checkListByListID: any = [];
        let currentIndex = this.getCurrentIndex(row.id);
        let toIndex;
        let findFirstIndex = this.taskRows.findIndex((element: any) => element.list_id == listID);
        let findLastIndex = this.taskRows.findLastIndex((element: any) => element.list_id == listID);
        let taskRows = this.taskRows;
        if (moveLocation == 'movedown') {
          if (currentIndex == findLastIndex - 1 && this.taskRows[findLastIndex].isNew == true) {
            return undefined;
          } else if (currentIndex !== findLastIndex) {
            toIndex = currentIndex + 1;
            taskRows.splice(currentIndex, 1);
            taskRows.splice(toIndex, 0, row);
          }

        } else if (moveLocation == 'moveup') {

          if (currentIndex !== findFirstIndex) {
            toIndex = currentIndex - 1;
            let taskRows = this.taskRows;
            taskRows.splice(currentIndex, 1);
            taskRows.splice(toIndex, 0, row);
          }
        }
        this.taskRows = taskRows;

        const defaultState = {
          tasks: this.tasks,
          task_rows: this.taskRows
        };

        this.protoBucket.setItem(TASK_STATE_ITEM_KEY, defaultState);

        let chosenList = this.getGroupOfItem(listID);

        this.assistiveText =
          `${row.name}. New position in ${this.currentListName} checklist: ${this.getItemPosition(chosenList, toIndex)}`;

        this.$nextTick(() => {
          let item = document.getElementById(`checklist-item-${row.id}`);

          window.setTimeout(function () {
            item!.focus();
          }, 0);
        });
      }

    }

    private toggleModal(ref: string): void {
      const targetModal = this.$refs[ref] as ModalSection;
      targetModal.toggleModal();
    }

    private confirmPopup(id: any): any {
      this.toggleModal('checklistCancelModal');
    }

    onchange() {
      const defaultState = {
        tasks: this.tasks,
        task_rows: this.taskRows
      };

      this.protoBucket.setItem(TASK_STATE_ITEM_KEY, defaultState);
    }

    isTaskRowEmpty(list_id: any): boolean {
      let empty = false;
      const filtered = this.taskRows.filter((option: any) => {
        return option.list_id == list_id;
      });

      if (filtered) {
        let count = 0;
        filtered.map((item: any) => {
          if (item.style && item.style == 'd-none') {
            count = count + 1;
          }
        });

        if (filtered.length == count) {
          empty = true;
        }
      }
      return empty;
    }

    public getCategoryValue(item: any, name: any) {
      if (item !== undefined) {
        if (name == 'name') {
          return this.statusOptions[item as any].value;
        }
        return this.statusOptions[item as any].type;
      }
    }

    public canBeReOrdered(itemID: any, ) {
      if (this.isReordering && itemID == this.idOfCurrentReOrdering) {
        return true;
      }

    }

    private getReOrderText(itemID: any, ) {
      if (!this.isReordering) {
        return 'Reorder Items';
      } else {
        return this.idOfCurrentReOrdering == itemID ? 'Cancel reorder' : 'Reorder Items' ;
      }
    }


    private categoryOptions = [{
        code: "task_1",
        value: 'Consultations'
      }, {
        code: "task_2",
        value: 'Imaging'
      }, {
        code: "task_3",
        value: 'Labs'
      },
      {
        code: "all",
        value: 'All'
      }
    ];


    private statusOptions = [{
        code: 0,
        value: 'New',
        type: 'unstarted'
      }, {
        code: 1,
        value: 'To be Scheduled',
        type: 'in-progress'
      }, {
        code: 2,
        value: 'Scheduled',
        type: 'in-progress'
      }, {
        code: 3,
        value: 'Results Pending',
        type: 'in-progress'
      }, {
        code: 4,
        value: 'Further Follow-Up',
        type: 'in-progress'
      }, {
        code: 5,
        value: 'Complete',
        type: 'completed'
      }, {
        code: 6,
        value: 'Expired',
        type: 'expired'
      }

    ];

    get dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: 'ghost'
      };
    }

    @Watch('$route')
    loadFromStorage() {
      this.loaded();
    }

    removeFilterClass(rows: any) {
      rows.map((row: any) => {
        if (row.style) {
          row.style = '';
        }
      });
    }

    allowReOrderingOfSection(taskID: any, listID: any) {
      if (this.idOfCurrentReOrdering === listID) {
        this.isReordering = false;
        this.idOfCurrentReOrdering = '';
      } else {
        this.isReordering = true;
        this.idOfCurrentReOrdering = listID;
      }
    }


    openCreateListItem(taskID: any, listID: any) {

      this.isCreating = true;
      let taskRows = this.taskRows || [];

      const payload: any = {
        id: Math.random().toString(16).slice(2),
        isNew: true,
        status: '0',
        task_id: taskID,
        list_id: listID,
      };
      let findLastIndex = this.taskRows.findLastIndex((element: any) => element.list_id == listID);


      taskRows.splice(findLastIndex + 1, 0, payload);
      this.removeFilterClass(taskRows);

      this.taskRows = taskRows;

      const defaultState = {
        tasks: this.tasks,
        task_rows: taskRows
      };

      this.protoBucket.setItem(TASK_STATE_ITEM_KEY, defaultState);
      setTimeout(() => {
        this.scrollToMethod(`task-wrapper-form-new-${payload.id}`);
      }, 500);

    }


    saveItem(item: any, type: any) {


      let taskRows = this.taskRows || [];
      this.removeFilterClass(taskRows);

      const defaultState = {
        tasks: this.tasks,
        task_rows: taskRows
      };

      this.protoBucket.setItem(TASK_STATE_ITEM_KEY, defaultState);

      if (type == 'create') {
        this.openCreateListItem(item.task_id, item.list_id);
      }
    }


    updateItem(item: any) {
      let taskRows = this.taskRows || [];
      this.removeFilterClass(taskRows);

      const defaultState = {
        tasks: this.tasks,
        task_rows: taskRows
      };

      this.protoBucket.setItem(TASK_STATE_ITEM_KEY, defaultState);

      // this.cancelEdit(item.id);
      this.isCreating = false;

    }

    cancelEdit(id: any) {
      const selectItemsArray = this.selectedItems.filter((item: any) => {
        return item.id !== id;
      });
      const selectTasksArray = this.selectedSubTaskItems.filter((item: any) => {
        return item !== id;
      });

      this.selectedItems = selectItemsArray;
      this.selectedSubTaskItems = selectTasksArray;
    }

    cancelCreate() {
      let taskRows = this.taskRows || [];
      this.removeFilterClass(taskRows);

      let newArray: any = [];

      taskRows.filter((item: any) => {
        if (item.isNew == false) {
          newArray.push(item);
        } else {
          if (item && item.name) {
            item.isNew = false;
            newArray.push(item);
          }
        }
      });
      this.taskRows = newArray;

      const defaultState = {
        tasks: this.tasks,
        task_rows: newArray
      };

      this.protoBucket.setItem(TASK_STATE_ITEM_KEY, defaultState);

      this.isCreating = false;

      setTimeout(() => {
        this.scrollToMethod('reorder_instructions');
      }, 500);

    }

    private getTasklistOptions() {
      let Options: any = [];
      this.editState.tasks.map((task: any) => {
        Options.push({
          id: task.task_id,
          task_name: task.task_name
        });
      });
      return Options;
    }

    private getTasksOptions(taskListId: any, tasks: any) {
      if (tasks) {
        let Options: any = [];
        tasks.map((task: any) => {
          Options.push({
            code: task.list_id,
            value: task.list_name
          });
        });
        return Options;
      }
    }



    getCompleteStyle(row: any) {
      if (this.setFilters && this.setFilters.includeCompleted) {
        return row.status == "5" ? 'task-completed' : '';
      } else if (row.status == "5") {
               
        return 'd-none';
      } else {
        return 'd-flex';
      }
    }

    getSelectedStyle(row: any) {
      return this.selectedSubTaskItems.length > 0 && this.selectedSubTaskItems.includes(row.id) ? 'task-selected' :
        '';
    }

    getArchivedStatus(row: any) {
      if (this.setFilters && this.setFilters.includeArchived) {
        return row.status === 'archived' ? 'highlight-yellow' : '';
      } else if (row.status === 'archived') {
        return 'd-none';
      } else {
        return '';
      }
    }


    getFilterStatus(row: any) {
      if (this.setFilters && this.setFilters.filterStatus) {
        return row.status !== this.setFilters.filterStatus ? 'd-none' : '';
      } else {
        return '';
      }
    }

    rowStyleClass(row: any) {
      return `${this.getCompleteStyle(row)} ${this.getSelectedStyle(row)} ${this.getFilterStatus(row)} ${this.getArchivedStatus(row)} checklist-item-${row.id}`;
    }

    private updateFilter(filterArray: any) {
      this.setFilters = filterArray;

      let taskRows = this.taskRows || [];
      taskRows.map((task: any) => {
        task.style = '';
      });

      for (const [key, value] of Object.entries(filterArray)) {
        if (key == "filterStatus") {
          let val: any = value;

          taskRows.map((task: any) => {
  
            if (val !== '' && task.status !== val.toString()) {
              task.style = 'd-none';
            }
          });
        }
        else if (key == "includeCompleted" && value == false) {
          let val: any = '5';

          taskRows.map((task: any) => {
  
            if (task.status == val.toString()) {
              task.style = 'd-none';
            }
          });
        }
      }
      this.taskRows = taskRows;
    }

    toggleEditScreen(row_id: any, row: any) {
      if (this.selectedSubTaskItems.includes(row_id)) {
        this.cancelEdit(row_id);
      } else {
        this.selectedSubTaskItems.push(row_id);
        this.selectedItems.push(row);
      }
    }

    private setTasklist(event: any, task: any) {
      this.selectedTasklistID = task.id;
      this.selectedTasklistName = task.task_name;
      if (task.list_rows) {
        this.selectedCategory = task.list_rows[0].list_id;
      }
    }
  
    get tableData() {
      return RecipientTaskListData;
    }

    // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
    async loaded() {
      // Parse tasks from prototype storage if found, otherwise default to JSON file contents
      let formState;
      const defaultState = {
        tasks: this.tableData.rows,
        task_rows: this.tableData.task_rows
      };
      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const items: any = await this.protoBucket.getItem(TASK_STATE_ITEM_KEY);
      if (items) {
        try {
          formState = items;
        } catch (error) {
          console.warn('Warning: Cannot parse tasklist from prototype bucket storage, setting default tasks.', error);
          formState = defaultState;
        }

      } else {
        formState = defaultState;
      }
      this.$store.commit("pageState/set", {
        pageKey: "taskList",
        value: formState,
      });

      this.tasks = formState.tasks || [];
      this.taskRows = formState.task_rows || [];

      if (this.tasks.length == 1) {
        this.selectedCategory = 'all';
        this.selectedTasklistID = this.tasks[0].task_id;
        this.selectedTasklistName = this.tasks[0].task_name;
        const isNew = this.taskRows.find((item: any) => {
          return item.isNew == true;
        });

        if (isNew) {
          this.isCreating = true;
        }
      }
    }

    mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;
      // Make a per-journey prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.ChecklistReorder, { recipientId: this.$route.params.id, journeyId: this.$route.params.organ_id });
      this.loaded();
      this.updateFilter(this.setFilters);
        window.addEventListener('resize', this.checkwidth);
        this.checkwidth();
    }

    getTotalSubTask(task_id: any, list_id: any) {
      let totalCount = 0;
      this.taskRows.map((item: any) => {
        if (item.task_id == task_id && item.list_id == list_id && item.isNew == false) {
          totalCount = totalCount + 1;
        }
      });

      return totalCount;
    }

    getCompleted(task_id: any, list_id: any) {
      let completedCount = 0;

      this.taskRows.map((item: any) => {
        if (item.task_id == task_id && item.list_id == list_id) {
          if (item.status == '5') {
            completedCount = completedCount + 1;
          }
        }
      });

      return completedCount;
    }


    translateCategoryOption(code: any) {
      const found: any = this.categoryOptions.find((item: any) => {
        return item.code == code;
      });
      return found.value;
    }

    public switchTaskList(id: any): void {
      if (id == "referral_screening" || id == "waitlist_tasks") {
        // do nothing
      } else {
        // reset actives
        this.editState.tasks.map((item: any) => {
          item.active = false;
        });

        // enable this active
        this.editState.tasks.map((item: any) => {
          if (item.task_id === id) {
            item.active = true;
          }
        });
      }
    }

    public showAddAction(taskId: any, listId: any, subTaskId: any): void {
      if (subTaskId) {
        this.editState.tasks.map((task: any) => {

          if (task.task_id === taskId) {
            task.list_rows.map((item: any) => {
              if (item.list_id === listId) {
                item.sub_tasks.map((subTask: any) => {
                  if (subTask.sub_task_id == subTaskId) {
                    subTask.active = !subTask.active;
                    this.comment = subTask.comment;
                  } else {
                    subTask.active = false;
                  }
                });

              }
            });
          }

        });
      }
    }


    public setStatus(taskId: any, listId: any, subTaskId: any, event: any): void {
      if (subTaskId) {
        this.editState.tasks.map((task: any) => {

          if (task.task_id === taskId) {
            task.list_rows.map((item: any) => {
              if (item.list_id === listId) {
                item.sub_tasks.map((subTask: any) => {
                  if (subTask.sub_task_id == subTaskId) {
                    subTask.state = event;
                  }
                });

              }
            });
          }

        });
      }
    }

    public toggleSubList(taskId: any, listId: any): void {
      if (taskId) {
        this.editState.tasks.map((task: any) => {
          if (task.task_id === taskId) {
            task.list_rows.map((list: any) => {
              if (list.list_id === listId) {
                list.active = !list.active;
              }
            });
          }
        });
      }
    }

    public saveAndClose(taskId: any, listId: any, subTaskId: any): void {
      this.handleSubmit();
      this.showAddAction(taskId, listId, subTaskId);
    }

    public handleSubmit(): void {
      this.protoBucket.setItem(TASK_STATE_ITEM_KEY, this.editState);
    }
  }

</script>

<style scoped>
  .tasklist-body .sub-task-group-header .nav-group-button .action-bar {
    margin-left: auto;
    display: flex;
  }

  .tasklist-body {
    display: flex;
    margin-top: 0.5rem;
  }

  .tasklist-body .completed {
    background-color: #EDFDEE;
  }

  .tasklist-body .completed .circle svg {
    color: #007f43;
  }

  .tasklist-body a.disabled {

    color: gray;

    pointer-events: none;
  }

  .page-content fieldset .tasklist-body legend {
    padding-top: 1rem;
    width: auto !important;
  }

  .tasklist-body .btn-link {
    border: none;
    font-size: 0.85rem;
    padding: 1rem 0 0;
  }

  .tasklist-body .btn-link:hover, .tasklist-body .btn-link:focus {
    border: none;
    box-shadow: none;
  }

  .tasklist-body .left-pane {
    width: 25%;
    background-color: #f5f5f5;
    overflow-y: auto;
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  }

  .right-pane-wrapper {
    overflow-y: auto;
    width: 100%;
    display: contents;
  }

  .tasklist-body .right-pane {
    flex: 1;
    overflow-y: auto;
    background-color: #f2f2f2;
    border: 1px solid #b9c1c7;
    padding: 0.5rem;
    -webkit-box-shadow: 0 0.25rem 0.25rem rgba(68, 47, 47, .1);
    box-shadow: 0 0.25rem 0.25rem rgba(68, 47, 47, .1);
  }


  .tasklist-body .right-pane-header,
  .tasklist-body .left-pane-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b9c1c7;
    border-bottom: 1px solid #b9c1c7;
    margin-bottom: 1rem;
    padding: 0 0 1rem 0;
  }

  .tasklist-body .left-pane-header {
    padding: 1rem !important;
  }

  .tasklist-body .right-pane-header h6,
  .tasklist-body .left-pane-header h5 {
    margin-bottom: 0;
  }

  .tasklist-body ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .heading-title {
    font-size: 24px;
    font-weight: 600;
  }

  .tasklist-body .right-pane-list li {
    padding: 1rem 0;
    border-bottom: 1px solid #b9c1c7;
  }

  .tasklist-body .sub-task-group-header {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .tasklist-body .sub-task-group-header .nav-group {
    display: flex;
  }

  .tasklist-body .sub-task-group-header .nav-group-button {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  }
  .tasklist-body .sub-task-group-header .nav-group-button .action-bar {
    margin-left: auto;
  }
  .tasklist-body .sub-task-group-header .nav-group-button .action-bar  button {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .completed-items {
    text-align: center;
    font-size: 12px;
    margin-left: auto;
    width: min-content;
  }

  .completed-items .completed-value {
    font-weight: bold;
    font-size: 1rem;
    display: block;
    margin-right: 0 !important;
  }

  .completed-items .completed-label {
    position: relative;
    top: -5px;
  }
  .completed-items-large {
    display: none;
    margin-right: 0.5rem;
  }
  /* .completed-items {
    text-align: center;
    font-size: 12px;
    margin-left: auto;
    width: min-content;
    .completed-value {
      font-weight: bold;
      font-size: 1rem;
      display: block;
    }
    .completed-label {
      position: relative;
      top: -5px;
    }
  }
  .completed-items-large {
    display: none;
    margin-right: 0.5rem;
  } */


  @media screen and (max-width: 435px) {
        .tasklist-body .action-bar {
      font-size: 0.75rem;
      margin-right: 0;
      display: flex;
    }
    .tasklist-body .action-bar button {
      font-size: 86%;
    }
  }


  @media screen and (min-width: 600px) {
    .completed-items-small {
      display: none !important;
    }
    .completed-items-large {
      display: block !important;
    }
    .completed-items {
      font-size: 1rem;
      font-weight: normal;
      margin-left: .2rem;
      background-color: transparent;
      border: none;
      padding: 4px 0;
      width: auto;
    }
    .completed-items .completed-label {
      top: 0;
    }

    .taskView {
      padding: 0.75rem;
    }

    /* .taskView .task-content {
      display: flex;
      flex-direction: column-reverse;
    } */


    .tasklist-body .task-wrapper .task-line-text {
      font-size: 1rem;
      line-height: inherit;
    }
    
    .badge-checklist {  
      border-radius: 10px !important;
      padding: 0.5rem 0.75rem !important;
      font-size: 0.7rem !important;
    }

  }


  @media screen and (min-width: 800px) {

    .tasklist-body .sub-task-group-header {
      flex-direction: row;
      align-items: center;
    }

    .tasklist-body .sub-task-group-header .nav-group {
      max-width: 336px;
    }
  }

  .tasklist-body .tasklist-header {
    font-weight: 600;
    border-bottom: 1px solid #b9c1c7;
  }


  .tasklist-header {
    font-size: 1.4rem;
  }


  .tasklist-body .sub-task-group-header .task-line-text .task-line-name {
    font-size: 1.2rem;
  }

  .tasklist-body .task-line-text {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 0.5rem;
  }

  .tasklist-body .task-line-text:disabled {
    color: #000;
  }

  /* .tasklist-body .task-wrapper .task-line-text {
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 600;
    max-width: 375px;
  } */

  .tasklist-body .task-wrapper .task-line-text:hover,
  .tasklist-body .task-wrapper .task-line-text:focus {
    color: #0059A6;
  }

  .tasklist-body .task-wrapper .task-line-text .task-line-note {
    display: block;
    font-size: 0.9rem;
    font-weight: normal;
    font-style: italic;
  }

  .tasklist-body .task-wrapper {
    display: flex;
    align-items: left;
    -webkit-box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.1), 0 1px 2px 0 rgba(50, 50, 93, 0.06);
    box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.1), 0 1px 2px 0 rgba(50, 50, 93, 0.06);
    flex-direction: column;
  }
  

  .taskView {
    border: 1px solid #b9c1c7;
    width: 100%;
    /* padding: 0; */
    display: flex;
    background-color: #fff;
    /* padding: 1rem 0; */
    /* padding: 0; */
  }

  .flex-column:focus, .flex-column:focus .taskView{
    background-color: rgb(236, 250, 255)
  }

  .taskView:hover,
  .taskView:focus,
  .task-selected .taskDetails {
    background-color: rgb(236, 250, 255)
  }

  .task-wrapper-form .taskView:hover {
    background-color: transparent;
  }

  .task-completed .taskDetails:hover {
    background-color: rgb(236, 250, 255)
  }

  .task-completed .taskDetails {
    background-color: #EDFDEE;
  }

  .task-selected.task-completed .taskDetails {
    background-color: rgb(236, 250, 255)
  }


  .tasklist-body .task-wrapper .task-body {
    width: 100%;
    display: flex;
  }

  .task-wrapper-form .taskView {
    padding-bottom: 1.5rem;
    padding-right: 1.5rem;
  }

  .taskView .task-content {
    /* padding: 0.5rem; */
    width: 100%;
    /* padding-top: 0.25rem; */
  }

  /* .taskView .task-content .task-body {
    display: flex;
    flex-direction: column-reverse;
  } */



  .task-edit.task-wrapper-form .taskView {
    border-top: none;
  }

  .task-new.task-wrapper-form .taskView {
    border: 3px solid #bbaee0;
  }

  .task-wrapper-form .task-body {
    margin-left: 2.5rem;
    /* width: auto !important; */
    flex-direction: column;
    width: 100%;
  }

  .task-wrapper-form .task-body .p-label {
    margin-bottom: 0.375rem;

  }

  .tasklist-body .task-wrapper .task-meta {
    font-size: 0.85rem;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .tasklist-body .form-check {
    margin-top: 0 !important;
    margin-right: 0.5rem;
    padding-left: 0;
  }

  .tasklist-body .form-check .form-check-input {
    position: relative !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  .tasklist-body .task-line-widget {
    border-left: 1px solid #b9c1c7;
    padding: 0 1rem;
  }

  .tasklist-body .task-line-widget .form-group {
    margin-bottom: 0;
    cursor: pointer;
  }

  .tasklist-body .task-line-widget .form-control {
    cursor: pointer;
  }

  .pill {
    width: 20px;
    height: 26px;
    border-radius: 3px;
    margin-right: 1rem;
    border: none;
    color: #fff;
    margin-top: 2px;
    min-width: 20px;
    max-width: 26px;
  }

  .pill-moving {
    cursor: pointer;
    display: grid;
    padding: 4px 5px 11px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 4px;
    position: unset;
    transform: translate(0, 0);
  }

  .pill-moving .sq {
    aspect-ratio: 1 / 1;
    opacity: 0.8;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .badge-checklist {  
    border-radius: 3px !important;
    padding: 0.25rem 0.5rem !important;
    border: 2px solid transparent;
    font-size: 0.6rem !important;
    position: relative;
    top: -1px;
    right: -1px;
  }

  .pill-unstarted {
    background-color: #bbaee0;
  }

  .pill-unstarted.pill-moving:hover {
    background-color: #9c89d2;
  }

  .badge-unstarted {
    background-color: #eae8fe;
    border-color: #bbaee0;
  }

  .pill-in-progress {
    background-color: #76b8ff;
  }

  .pill-in-progress.pill-moving:hover {
    background-color: #439eff;
  }

  .badge-in-progress {
    background-color: #cce5ff;
    border-color: #76b8ff;
  }

  .pill-completed {
    background-color: #96d3a4;
  }

  .pill-completed.pill-moving:hover {
    background-color: #72c485;
  }

  .badge-completed {
    background-color: #d4edda;
    border-color: #96d3a4;
  }

  .badge-expired {
    background-color: #b43f00;
  }

  .sub-task-group-header .btn.btn-sm.link-btn,
  .sub-task-group-header .btn.btn-sm.link-btn:hover {
    box-shadow: none !important;
    border: none !important;
  }

  .tasklist-accordion-toggle {
    padding: 0 !important;
    border: none !important;
    height: min-content;
    padding: 0.1rem 0.25rem !important;
    width: 1.3rem;
  }

  .tasklist-accordion-toggle:hover,
  .tasklist-accordion-toggle:focus {
    outline: none !important;
    box-shadow: none !important;
    opacity: 0.65;
  }

  .tasklist-accordion-toggle:hover svg,
  .tasklist-accordion-toggle:focus svg {
    color: #0059A6;
  }

  .tasklist-accordion-icon {
    font-size: 1.5rem;
    transform: rotate(0deg);
    transition-duration: 0.3s;
    cursor: pointer;
  }

  .tasklist-accordion-icon.rotate {
    transform: rotate(90deg);
    transition-duration: 0.3s;
  }

  .form-select-checklist {
    width: auto !important;
    /* padding-left: .5rem; */
    padding: .375rem .75rem;
    font-size: 1.2rem !important;
    font-weight: 600;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

</style>
