<template>
  <sub-section
    sub-section-id="policy-responses"
    :tabbableValue="$t('response')"
    :total-records="getResponses.length"
    :table-config="responseTableConfig"
    @table-row-click="selectResponse($event)"
    @table-create-row="createResponse()"
    :saveButton="canSave"
    :disabled="!selectedDecisionId && (canSave || newJourney)"
    :save-button-text="$t('save_sub_decision')"
    ref="saveResponsesSection"
    @save="saveResponse()"
    :title="$t('sub_decisions')"
  >
    <template v-slot:contents>
      <fieldset v-if="editState" :disabled="!canSave">
        <legend>
          <h5 v-if="!editState._id" class="legend-title">
            {{$t('new_sub_decision')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('selected_sub_decision')}}
          </h5>
        </legend>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              selectId="response"
              ruleKey="response_type"
              :name="$t('response')"
              :options="decisionResponseTypes"
              v-model="editState.response"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              input-id="response_date"
              ruleKey="response_datetime"
              :name="$t('response_date')"
              v-model="editState.response_date"
            />
          </div>
          <div class="standard-form-group">
            <time-input
              inputId="response_time"
              :name="$t('response_time')"
              rules="required"
              v-model="editState.response_time"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <select-input
              selectId="responding_hospital_id"
              ruleKey="responding_hospital_id"
              :name="$t('responding_transplant_center')"
              v-model="editState.responding_hospital_id"
              :options="respondingTransplantPrograms"
            >
            </select-input>
          </div>
          <div class="standard-form-group-6column-xlarge-only">
            <text-input
              input-id="responding_physician"
              ruleKey="responding_physician"
              :name="$t('physician')"
              v-model="editState.responding_physician"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <text-area-input
              inputId="responding_comments"
              :name="$t('comments')"
              ruleKey="comments"
              v-model="editState.comments"
            />
          </div>
        </div>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="@/components/organs/shared/_locales/PolicyExemptions.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-facing-decorator';
import { RootState, ObjectId, GenericCodeValue } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response } from '@/store/decisions/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';

export interface ResponseForm {
  _id?: string;
  response?: string;
  response_date?: string;  
  response_time?: string;  
  responding_hospital_id?: string;
  responding_physician?: string;
  comments?: string;
}

interface ResponseRow {
  _id?: string;
  response?: string;
  response_date?: string;  
  authorized_user_name?: string;
  responding_hospital_name?: string;
  responding_physician?: string;
}

@Component({
  components: {
    TextInput,
    TextAreaInput,
    DateInput,
    TimeInput,
    SubSection,
    SelectInput,
    SelectOtherInput
  }
})
export default class PolicyResponses extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.responses) editState!: ResponseForm;
  @State(state => state.decisions.listResponses) private responses!: Response[];

  // Getters
  @Getter('getResponses', { namespace: 'decisions' }) getResponses!: Response[];
  @Getter('selectedDecisionId', { namespace: 'decisions' }) selectedDecisionId!: string;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: string, entries: any[]) => boolean;
  @Getter('decisionResponseTypes', { namespace: 'lookups' }) private decisionResponseTypes!: any;
  @Getter('decisionMeldDerFrequency', { namespace: 'lookups' }) private decisionMeldDerFrequency!: any;
  @Getter('regionTransplantOptionsByOrgan', { namespace: 'hospitals' }) hospitalOptions!: (organCode?: number | string) => GenericCodeValue[];
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: CoordinatorOptions[]; 

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Get a string representation the organ_code
   * 
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  get respondingTransplantPrograms(): any {
    return this.hospitalOptions(this.organCode);
  }

  // load validation rules
  public mounted() {
    this.loadResponses();
  }

  /**
   * Reload list of Policy Exemptions.
   *
   */
  public loadResponses(): void {
    // Skip if no top-level decision is selected
    if (!this.selectedDecisionId) {
      this.initializeResponseForm();
      return;
    }

    Promise.all([
      this.$store.dispatch('decisions/indexResponses', { recipientId: this.recipientId, journeyId: this.journeyId, decisionId: this.selectedDecisionId }),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions/${this.selectedDecisionId}/decision_responses`, action: 'new' }),
    ]).finally(() => {
      this.initializeResponseForm();
    }).catch(() => {
      this.initializeResponseForm();
    });
  }

  private translateResponseType(code: string | undefined): string {
    if (!code) return '-';
    const type = this.decisionResponseTypes.find((item: any) => {
      return item.code == code;
    });
    return type ? type.value : '-';
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeResponseForm(record?: Decision): void {
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'responses',
      value: this.buildResponseForm(record)
    });
    this.$emit('clear');
  }

  /**
   * Returns policy exemption form edit state based on policy exemption document
   *
   * @param record policy exemption document fetched from API
   * @returns {HccResultForm} editable form state
   */
  public buildResponseForm(record?: Response): any {
    if (!record) {
      return {};
    }

    return {
      _id: record._id,
      response: record.response_type,
      response_date: this.parseDateUiFromDateTime(record.response_datetime),
      response_time: this.parseTimeUiFromDateTime(record.response_datetime),
      responding_hospital_id: record.responding_hospital_id && record.responding_hospital_id ? record.responding_hospital_id : null,
      responding_physician: record.responding_physician,
      comments: record.comments,
    };
  }

  private getHospitalName(id: any): string|undefined {
    if (!id) return '-';
    const hospitals = this.hospitalOptions(this.organCode);
    const found = hospitals.find((item: any) => {
      return item.code == id;
    });
    return found ? found.value : '-';
  }

  // Gets table data for policy exemptions.
  get responseRows(): ResponseRow[] {
    if (!this.selectedDecisionId) return [];
    if (!this.getResponses) return [];

    const result: ResponseRow[] = [];

    this.getResponses.forEach((record: Response) => {
      const hospitalName = this.getHospitalName(record.responding_hospital_id);

      const row: ResponseRow = {
        _id: record._id,
        response: this.translateResponseType(record.response_type),
        response_date: this.parseDisplayDateTimeUiFromDateTime(record.response_datetime),
        authorized_user_name: record.authorized_user_name || '-',
        responding_hospital_name: hospitalName,
        responding_physician: record.responding_physician,
      };

      result.push(row);
    });
    return result;
  }

  get responseTableConfig(): TableConfig {
    return {
      data: this.responseRows,
      columns: [
        { label: this.$t('response').toString(), field: 'response'},
        { label: this.$t('date_of_response').toString(), field: 'response_date'},
        { label: this.$t('authorized_user').toString(), field: 'authorized_user_name'},
        { label: this.$t('responding_transplant_center').toString(), field: 'responding_hospital_name'},
        { label: this.$t('physician').toString(), field: 'responding_physician'},
      ],
      empty: this.$t('use_form_below_sub_decision').toString(),
      createButton: this.canSave,
      createText: this.$t('create_sub_decision').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'response_type'              : 'response',
      'response_datetime'          : ['response_date', 'response_time'],
      'responding_hospital_id'     : 'responding_hospital_id',
      'responding_hospital_other'  : 'responding_hospital_other',
      'responding_physician'       : 'responding_physician',
      'comments'                   : 'responding_comments',
    };
  }

  //  Builds form edit state based on selected document
  private selectResponse(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id ? event.row._id : undefined;

    if (!selectedId || !this.getResponses) {
      return;
    }
    // Find the selected source document
    const found = this.getResponses.find((each: Decision) => {
      return each._id && each._id === selectedId;
    });
    if (!found) {
      return;
    }

    this.$store.dispatch('validations/loadEdit', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions/${this.selectedDecisionId}/decision_responses/`, action: 'edit', clientId: found._id });

    // Build form state based on selected document
    this.initializeResponseForm(found);
  }

  // Build an empty new form edit state
  private createResponse(): void {
    // Build form state
    this.initializeResponseForm();
  }

  // Saves current form state for policy exemption
  private saveResponse(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveResponsesSection as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'response');
    // Generate payload based on current edit state
    const payload: any = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id,
      decisionId: this.selectedDecisionId,
      response: this.extractPatch(),
    };
    // check for responseId
    if (this.editState._id && this.editState._id) { payload.responseId = this.editState._id; }

    // Dispatch save action and register the response
    this.$store.dispatch('decisions/saveResponse', payload).then((success: SaveResult) => {
      // Clear any errors
      this.$emit('clear');
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Reload index
      this.loadResponses();
      // Reload parent Policy Exemptions
      this.$emit('reloadIndex');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Returns a patch object containing changes for policy exemption document
  private extractPatch(): any {
    if (!this.editState) {
      return {};
    }
    const form = this.editState;

    return {
      response_type: form.response || null,
      response_datetime: this.sanitizeDateTimeApi(form.response_date, form.response_time),
      responding_hospital_id: form.responding_hospital_id || null,
      responding_physician: form.responding_physician || null,
      comments: form.comments || null,
    };
  }
}
</script>
