import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_6 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_7 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_8 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_13 = { class: "action-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_card_section, {
      class: "inverted mb-1",
      "section-id": "table-filter",
      "lookups-to-load": _ctx.lookupsToLoad
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('filter')), 1)
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_select_input, {
              "select-id": "tableFilter.txc",
              name: _ctx.$t('txc'),
              modelValue: _ctx.editState.transplantCenter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.transplantCenter) = $event)),
              options: _ctx.buildTransplantCentreFilter,
              placeholder: _ctx.$t('all')
            }, null, 8, ["name", "modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_text_input, {
              "input-id": "tableFilter.affloId",
              name: _ctx.$t('affloId'),
              modelValue: _ctx.editState.clientId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.clientId) = $event))
            }, null, 8, ["name", "modelValue"])
          ]),
          (_ctx.isBasicNameEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_text_input, {
                  "input-id": "tableFilter.firstName",
                  name: _ctx.$t('firstName'),
                  modelValue: _ctx.editState.firstName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.firstName) = $event))
                }, null, 8, ["name", "modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isBasicNameEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_text_input, {
                  "input-id": "tableFilter.lastName",
                  name: _ctx.$t('lastName'),
                  modelValue: _ctx.editState.lastName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.lastName) = $event))
                }, null, 8, ["name", "modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isMRNEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_text_input, {
                  "input-id": "tableFilter.mrn",
                  name: _ctx.$t('mrn'),
                  modelValue: _ctx.editState.mrn,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.mrn) = $event))
                }, null, 8, ["name", "modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isSexEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_select_input, {
                  "select-id": "tableFilter.sex",
                  name: _ctx.$t('sex'),
                  modelValue: _ctx.editState.sex,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.sex) = $event)),
                  options: _ctx.sexOptionsDropdownValues,
                  placeholder: _ctx.$t('all')
                }, null, 8, ["name", "modelValue", "options", "placeholder"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_select_input, {
              "select-id": "tableFilter.abo",
              name: _ctx.$t('abo'),
              modelValue: _ctx.editState.bloodType,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.bloodType) = $event)),
              options: _ctx.bloodTypeOptionsDropdownValues,
              placeholder: _ctx.$t('all')
            }, null, 8, ["name", "modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_select_input, {
              "select-id": "tableFilter.organCode",
              name: _ctx.$t('organCode'),
              modelValue: _ctx.editState.organCode,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.organCode) = $event)),
              options: _ctx.buildOrganFilter,
              placeholder: _ctx.$t('all')
            }, null, 8, ["name", "modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_select_input, {
              "select-id": "tableFilter.phase",
              name: _ctx.$t('phase'),
              modelValue: _ctx.editState.journeyState,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.journeyState) = $event)),
              options: _ctx.buildJourneyStateFilter,
              placeholder: _ctx.$t('all')
            }, null, 8, ["name", "modelValue", "options", "placeholder"])
          ]),
          (_ctx.isWorkflowStepEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_select_input, {
                  "select-id": "tableFilter.step",
                  name: _ctx.$t('step'),
                  modelValue: _ctx.editState.workflowStep,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.workflowStep) = $event)),
                  options: _ctx.buildWorkflowStepFilter,
                  placeholder: _ctx.$t('all')
                }, null, 8, ["name", "modelValue", "options", "placeholder"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.filterButton && _ctx.filterButton(...args)), ["prevent"])),
            class: "btn btn-wide btn-outline-primary mr-4"
          }, _toDisplayString(_ctx.$t('filter')), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-wide btn-secondary-outline",
            onClick: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearButton && _ctx.clearButton(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t('clear')), 1)
        ])
      ]),
      _: 1
    }, 8, ["lookups-to-load"])
  ]))
}