import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row skeleton-padding-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "standard-form-group" }, [
      _createElementVNode("span", { class: "skeleton-box w-50" }),
      _createElementVNode("span", {
        class: "skeleton-box w-100",
        style: {"height":"30px"}
      })
    ], -1)
  ])))
}