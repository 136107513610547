import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crossmatch_info_section = _resolveComponent("crossmatch-info-section")!
  const _component_crossmatch_sample_section = _resolveComponent("crossmatch-sample-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "serum-crossmatch-results",
    "lookups-to-load": _ctx.lookupsToLoad,
    "laboratories-to-load": _ctx.laboratoriesToLoad,
    onLoaded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loaded())),
    saveButton: false,
    ref: "serumCrossmatch"
  }, {
    header: _withCtx(() => _cache[4] || (_cache[4] = [
      _createTextVNode(" Record Serum Crossmatch Results ")
    ])),
    body: _withCtx(() => [
      _createVNode(_component_crossmatch_info_section, {
        ref: "crossmatchInfo",
        onHandleErrors: _ctx.handleErrors,
        onClear: _ctx.clear,
        onInitializeSection: _cache[0] || (_cache[0] = ($event: any) => (_ctx.initializeForm())),
        "onHook:mounted": _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadedSubsection('crossmatchInfo')))
      }, null, 8, ["onHandleErrors", "onClear"]),
      _createVNode(_component_crossmatch_sample_section, {
        ref: "crossmatchSample",
        onClear: _ctx.clear,
        onHandleErrors: _ctx.handleErrors,
        "onHook:mounted": _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadedSubsection('crossmatchSample')))
      }, null, 8, ["onClear", "onHandleErrors"])
    ]),
    _: 1
  }, 8, ["lookups-to-load", "laboratories-to-load"]))
}