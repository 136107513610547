import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "skeleton-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("nav", { class: "action-row" }, [
        _createElementVNode("span", { class: "skeleton-box skeleton-button" }),
        _createElementVNode("br")
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createStaticVNode("<table><thead><tr><th><span class=\"skeleton-box w-50\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-50\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-50\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-50\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-50\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-100\"></span></th><th><span class=\"skeleton-box w-100\"></span></th></tr></thead></table> ", 2)),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (index) => {
          return _createElementVNode("div", {
            key: index,
            class: "loading_row_container"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "loading_row" }, null, -1)
          ]))
        }), 64))
      ])
    ])
  ]))
}