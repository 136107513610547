import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_referral_details_section = _resolveComponent("referral-details-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "referral-section",
    "lookups-to-load": _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded())),
    disabled: !_ctx.uiJourney.canSave || _ctx.newJourney
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('referral')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_referral_details_section, {
        ref: "referralDetails",
        "new-journey": _ctx.newJourney
      }, null, 8, ["new-journey"])
    ]),
    _: 1
  }, 8, ["lookups-to-load", "disabled"]))
}