import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group-large" }
const _hoisted_6 = { for: "recipientAttachment-clinicalAttachment" }
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 2,
  class: "invalid-feedback",
  id: "recipientAttachment-clinicalAttachment-error"
}
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-full-width-group" }
const _hoisted_12 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_action_toolbar = _resolveComponent("action-toolbar")!
  const _component_loading_form = _resolveComponent("loading-form")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "recipient-documents",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('recipient_documents')), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createBlock(_component_sub_section, {
            key: 1,
            ref: "recipientDocumentsSection",
            title: _ctx.$t("uploaded_documents"),
            "sub-section-id": "recipient-uploaded-files"
          }, {
            contents: _withCtx(() => [
              _createVNode(_component_table_toolbar, {
                createButton: _ctx.uiRecipient.canSave,
                createText: _ctx.$t('attach_new_document'),
                onTableCreateRow: _ctx.onCreateButtonClicked
              }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
              _createVNode(_component_table_list, {
                ref: "recipientDocumentsTable",
                "table-id": "recipientDocuments-uploadedFiles-table",
                "table-config": _ctx.tableConfig,
                tabbableColumn: "dateUploaded",
                onTableRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTableRowClick($event.row))),
                onTableRowDownload: _cache[1] || (_cache[1] = (params) => _ctx.generateDownloadLink(params)),
                highlightSelection: true
              }, null, 8, ["table-config"]),
              _createVNode(_component_validation_observer, { ref: "validations" }, {
                default: _withCtx(() => [
                  _createVNode(_component_form_layout, {
                    disabled: !_ctx.enableForm,
                    "form-id": "recipientAttachment-form"
                  }, {
                    title: _withCtx(() => [
                      _createElementVNode("legend", null, [
                        (_ctx.selection?.isNew)
                          ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('new_recipient_document')), 1))
                          : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('edit_recipient_document')), 1))
                      ])
                    ]),
                    action: _withCtx(() => [
                      _createVNode(_component_action_toolbar, {
                        ref: "actionToolbar",
                        onDestroy: _ctx.destroyFile,
                        permittedActions: _ctx.editState.permittedActions,
                        isDestroying: _ctx.isDestroying,
                        destroyGuidingText: _ctx.getDestroyGuidingText,
                        loading: !_ctx.editState.loaded
                      }, null, 8, ["onDestroy", "permittedActions", "isDestroying", "destroyGuidingText", "loading"])
                    ]),
                    contents: _withCtx(() => [
                      (!_ctx.editState.loaded)
                        ? (_openBlock(), _createBlock(_component_loading_form, { key: 0 }))
                        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_validation_provider, {
                                  ref: "recipientAttachment-clinicalAttachment-provider",
                                  label: _ctx.$t('clinical_attachment'),
                                  name: "recipientAttachment-clinicalAttachment"
                                }, {
                                  default: _withCtx(({ errors }) => [
                                    _createElementVNode("label", _hoisted_6, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('clinical_attachment')) + " ", 1),
                                      _cache[9] || (_cache[9] = _createElementVNode("i", null, "*", -1))
                                    ]),
                                    (!_ctx.editState.isNew)
                                      ? (_openBlock(), _createElementBlock("input", {
                                          key: 0,
                                          type: "text",
                                          disabled: true,
                                          class: "form-control",
                                          value: _ctx.editState.originalFilename
                                        }, null, 8, _hoisted_7))
                                      : (_openBlock(), _createElementBlock("input", {
                                          key: 1,
                                          "input-id": "recipientAttachment-clinicalAttachment",
                                          ref: "fileUploader",
                                          type: "file",
                                          class: _normalizeClass(["form-control", { 'is-invalid': errors[0] }]),
                                          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClinicalAttachmentsChanged($event)))
                                        }, null, 34)),
                                    (errors[0])
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['far', 'exclamation-circle'],
                                            "fixed-width": ""
                                          }),
                                          _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.$t('clinical_attachment'))), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["label"])
                              ]),
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_select_input, {
                                  "select-id": "recipientAttachment-categoryCode",
                                  ruleKey: "recipient_documents.category_code",
                                  name: _ctx.$t("category"),
                                  options: _ctx.attachmentCategoryOptions(false),
                                  modelValue: _ctx.editState.categoryCode,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.categoryCode) = $event))
                                }, null, 8, ["name", "options", "modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_text_input, {
                                  name: _ctx.$t("fileName"),
                                  ruleKey: "recipient_documents.original_filename",
                                  "input-id": "recipientAttachment-fileName",
                                  modelValue: _ctx.editState.filename,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.filename) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row-break d-none d-xl-block" }, null, -1)),
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_text_area_input, {
                                  ruleKey: "recipient_documents.description",
                                  "input-id": "recipientAttachment-description",
                                  name: _ctx.$t("description"),
                                  rows: "4",
                                  modelValue: _ctx.editState.description,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.description) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ])
                            ])
                          ]))
                    ]),
                    save: _withCtx(() => [
                      _createVNode(_component_save_toolbar, {
                        show: _ctx.enableSaveToolbar,
                        ref: "saveDocuments",
                        class: "card-footer action-row temp-saving row",
                        label: _ctx.$t('save_document'),
                        cancelButton: true,
                        onSave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleSave())),
                        onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleCancel()))
                      }, null, 8, ["show", "label"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }, 8, ["title"]))
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}