import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick", "title"]
const _hoisted_2 = ["onClick", "title"]
const _hoisted_3 = ["onClick", "title"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_6 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { for: "attachment-control" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-full-width-group" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-full-width-group" }
const _hoisted_14 = {
  key: 0,
  class: "form-check form-check-inline row"
}
const _hoisted_15 = { class: "col-md-12 m-0 p-0 mb-2" }
const _hoisted_16 = { class: "modal-footer-body" }
const _hoisted_17 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    "sub-section-id": "policy-attachments",
    "table-config": _ctx.attachmentTableConfig,
    onTableCreateRow: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createAttachment())),
    saveButton: _ctx.canSave,
    disabled: !_ctx.selectedDecisionId && (_ctx.canSave || _ctx.newJourney),
    "save-button-text": _ctx.$t('save_attachment'),
    ref: "saveAttachmentSection",
    onSave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveAttachment())),
    title: _ctx.$t('attachments')
  }, {
    "table-cell": _withCtx((props) => [
      (props.column.field == 'fileName')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (props.row.uuid)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.generateDownloadLink(props.row.id)), ["prevent","stop"]),
                  title: _ctx.$t('download_this_attachment')
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "text-grey mr-2",
                    icon: ['far', 'download'],
                    size: "sm",
                    "aria-hidden": "true",
                    "fixed-width": ""
                  }),
                  _createTextVNode(" " + _toDisplayString(props.row.fileName || '-'), 1)
                ], 8, _hoisted_1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(props.row.fileName || '-'), 1)
                ], 64))
          ], 64))
        : (props.column.field == 'editRemove')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (props.row.fileName)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _withModifiers(($event: any) => (_ctx.editFile(props.row)), ["prevent"]),
                      title: _ctx.$t('edit_this_attachment')
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "text-grey mr-2",
                        icon: ['fas', 'pencil'],
                        size: "sm",
                        "aria-hidden": "true",
                        "fixed-width": ""
                      })
                    ], 8, _hoisted_2),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _withModifiers(($event: any) => (_ctx.onDeleteFileClick(props.row)), ["prevent"]),
                      title: _ctx.$t('remove_this_attachment')
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "text-red mr-2",
                        icon: ['far', 'times-circle'],
                        size: "sm",
                        "aria-hidden": "true",
                        "fixed-width": ""
                      })
                    ], 8, _hoisted_3)
                  ], 64))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
    ]),
    contents: _withCtx(() => [
      (_ctx.editState)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 0,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", null, [
              (!_ctx.editState._id)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t('new_attachment')), 1))
                : (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_ctx.$t('selected_attachment')), 1))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.$t('attachment')) + " ", 1),
                  _cache[6] || (_cache[6] = _createElementVNode("i", null, "*", -1))
                ]),
                _createElementVNode("input", {
                  "input-id": "attachment-control",
                  ref: "fileUploader",
                  type: "file",
                  rules: "required",
                  class: "form-control",
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onAttachmentChanged($event)))
                }, null, 544)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_text_input, {
                  name: _ctx.$t("fileName"),
                  "input-id": "attachment_file",
                  ruleKey: "original_filename",
                  modelValue: _ctx.editState.fileName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.fileName) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_text_area_input, {
                  inputId: "attachment_description",
                  ruleKey: "description",
                  name: _ctx.$t('description'),
                  modelValue: _ctx.editState.description,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.description) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ])
          ], 8, _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_component_modal_section, {
        modalId: "delete-documents-modal",
        ref: "deleteModal",
        class: "modal-sticky-header",
        centered: true
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('delete_attachment')), 1)
        ]),
        body: _withCtx(() => [
          (_ctx.editState && _ctx.editState.selectedFileName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('delete_attachment_confirmation', { fileName: _ctx.editState.selectedFileName })), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.$t('cancel')), 1),
            _createElementVNode("a", {
              class: "btn btn-success",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteFile()))
            }, _toDisplayString(_ctx.$t('ok')), 1)
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["table-config", "saveButton", "disabled", "save-button-text", "title"]))
}