import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_4 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-full-width-group" }
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = { class: "standard-full-width-group mb-4" }
const _hoisted_14 = { for: "noteHistoryTable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_action_toolbar = _resolveComponent("action-toolbar")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_AutoCompleteInput = _resolveComponent("AutoCompleteInput")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "notes-section",
    class: "spaced-list-items",
    ref: "notesSection",
    "lookups-to-load": _ctx.lookupsToLoad
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("patient_notes")), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "notes-sub-section",
        title: _ctx.$t('note_history')
      }, {
        guiding_text: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('title_patient_note_guiding_text')), 1)
        ]),
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: _ctx.uiJourney.canSave && !_ctx.newJourney,
            createText: _ctx.$t('create_note'),
            onTableCreateRow: _ctx.handleTableCreateRow
          }, {
            "button-bar": _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_filter_component, {
                    title: _ctx.$t('filter_notes'),
                    fieldID: "notesFilter",
                    showFilter: true,
                    showNotesFilter: true,
                    showArchived: true,
                    tagOptions: _ctx.getNoteTags,
                    authorOptions: _ctx.getUserIds,
                    onSetFilters: _ctx.setFilters
                  }, null, 8, ["title", "tagOptions", "authorOptions", "onSetFilters"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["createButton", "createText", "onTableCreateRow"]),
          _createVNode(_component_table_list, {
            "table-id": "notes-sub-section-table",
            ref: "notesTableList",
            tabbableColumn: "date",
            "table-config": _ctx.tableConfig,
            onTableRowClick: _ctx.handleTableRowClick,
            highlightSelection: true,
            onOnPageNumber: _cache[0] || (_cache[0] = ($event: any) => {_ctx.onPageNumber($event); _ctx.loadData()}),
            onOnPageSize: _cache[1] || (_cache[1] = ($event: any) => {_ctx.onPageSize($event); _ctx.loadData()}),
            mode: "remote",
            rowStyleClass: _ctx.rowStyleClass,
            groupMode: "subheader",
            groupBy: "id",
            isLoading: _ctx.isLoading,
            "total-records": _ctx.totalRecords
          }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass", "isLoading", "total-records"]),
          _createVNode(_component_validation_observer, { ref: "validations" }, {
            default: _withCtx(() => [
              _createVNode(_component_form_layout, {
                disabled: !_ctx.uiJourney.canSave || _ctx.newJourney || _ctx.isArchived,
                "form-id": "notes-sub-section-form"
              }, {
                title: _withCtx(() => [
                  _createElementVNode("legend", null, [
                    (_ctx.isEditing)
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_patient_note')), 1))
                      : (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString(_ctx.$t('new_patient_note')), 1))
                  ]),
                  (!_ctx.newJourney)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.isEditing)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t('selected_patient_note_guiding_text')), 1))
                          : (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t('new_patient_note_guiding_text')), 1))
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                action: _withCtx(() => [
                  _createVNode(_component_action_toolbar, {
                    ref: "actionToolbar",
                    onArchive: _ctx.performArchive,
                    archiveGuidingText: _ctx.$t('archive.guiding_text'),
                    onRestore: _ctx.performRestore,
                    restoreGuidingText: _ctx.$t('restore.guiding_text'),
                    permittedActions: _ctx.permittedActions,
                    isArchiving: _ctx.isArchiving,
                    isRestoring: _ctx.isRestoring,
                    rationaleRequired: true
                  }, null, 8, ["onArchive", "archiveGuidingText", "onRestore", "restoreGuidingText", "permittedActions", "isArchiving", "isRestoring"])
                ]),
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_text_input, {
                        "input-id": "note_subject",
                        ruleKey: "clinical_notes.note_subject",
                        name: _ctx.$t("note_subject"),
                        modelValue: _ctx.editState.note_subject,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.note_subject) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_AutoCompleteInput, {
                        ruleKey: "clinical_notes.note_tags",
                        name: _ctx.$t("note_tags"),
                        options: _ctx.recipientJourneyNoteTags,
                        modelValue: _ctx.editState.note_tags,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.note_tags) = $event)),
                        disabled: !_ctx.uiJourney.canSave || _ctx.newJourney || _ctx.isArchived,
                        inputId: "note_tags",
                        multiple: true,
                        showDropdown: true
                      }, null, 8, ["name", "options", "modelValue", "disabled"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "note_text",
                        ruleKey: "clinical_notes.note_text",
                        name: _ctx.$t("note_text"),
                        modelValue: _ctx.editState.note_text,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.note_text) = $event)),
                        rows: "4"
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  (_ctx.isEditing)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "row-break d-none d-xl-block" }, null, -1)),
                        (_ctx.uiJourney.canSave && !_ctx.newJourney)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_text_input, {
                                  "input-id": "change_rationale",
                                  name: _ctx.$t("change_rationale"),
                                  ruleKey: "clinical_notes.change_rationale",
                                  modelValue: _ctx.editState.change_rationale,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.change_rationale) = $event))
                                }, null, 8, ["name", "modelValue"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row-break d-none d-xl-block" }, null, -1)),
                        _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("previous_edits_rationale")), 1),
                        _createVNode(_component_table_list, {
                          "table-id": "noteHistoryTable",
                          ref: "noteHistoryTable",
                          "table-config": _ctx.tableEditHistoryConfig,
                          rowStyleClass: _ctx.rowHistoryStyleClass
                        }, null, 8, ["table-config", "rowStyleClass"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                save: _withCtx(() => [
                  _createVNode(_component_save_toolbar, {
                    show: _ctx.uiJourney.canSave && !_ctx.newJourney,
                    ref: "notesSaveToolbar",
                    label: _ctx.$t('save_patient_note'),
                    cancelButton: true,
                    onSave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.savePatch())),
                    onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.cancelPatch()))
                  }, null, 8, ["show", "label"])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["lookups-to-load"]))
}