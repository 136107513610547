import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "filter-section-action-row" }
const _hoisted_3 = { class: "filter-section-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_5 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = { class: "standard-form-group" }
const _hoisted_20 = { class: "standard-form-group" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "standard-form-group" }
const _hoisted_23 = { class: "standard-form-group" }
const _hoisted_24 = { class: "standard-form-group" }
const _hoisted_25 = { class: "standard-form-group" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "standard-form-group" }
const _hoisted_28 = { class: "standard-form-group" }
const _hoisted_29 = { class: "standard-form-group" }
const _hoisted_30 = { class: "standard-form-group" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "standard-form-group" }
const _hoisted_33 = { class: "standard-form-group" }
const _hoisted_34 = { class: "standard-form-group" }
const _hoisted_35 = { class: "standard-form-group" }
const _hoisted_36 = { class: "row" }
const _hoisted_37 = { class: "standard-form-group" }
const _hoisted_38 = { class: "standard-form-group" }
const _hoisted_39 = { class: "standard-form-group" }
const _hoisted_40 = { class: "standard-form-group" }
const _hoisted_41 = { class: "standard-form-group" }
const _hoisted_42 = { class: "standard-form-group" }
const _hoisted_43 = { class: "standard-form-group" }
const _hoisted_44 = { class: "standard-form-group" }
const _hoisted_45 = { class: "row" }
const _hoisted_46 = { class: "standard-form-group" }
const _hoisted_47 = { class: "standard-form-group" }
const _hoisted_48 = { class: "standard-form-group" }
const _hoisted_49 = { class: "row" }
const _hoisted_50 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!

  return (_ctx.isProtoLiverSodiumMeldEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", null, [
          _createVNode(_component_sub_section, {
            "sub-section-id": "proto-sodium-meld-section",
            title: _ctx.title
          }, {
            contents: _withCtx(() => [
              _createVNode(_component_table_toolbar, {
                createButton: true,
                createText: "Create Sodium MELD",
                onTableCreateRow: _ctx.clearForm
              }, {
                "button-bar": _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_filter_component, {
                        fieldID: "contactFilter",
                        showFilter: false,
                        showArchived: false
                      })
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["onTableCreateRow"]),
              _createVNode(_component_table_list, {
                ref: "sodiumMeldsTable",
                tabbableColumn: "calculated_date",
                "table-id": "sodium-meld-table",
                "table-config": _ctx.sodiumMeldTableConfig,
                onTableRowClick: _ctx.editSelected,
                highlightSelection: true,
                rowStyleClass: _ctx.rowStyleClass
              }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
              _createVNode(_component_form_layout, { "form-id": "sodiumMeld-form" }, {
                title: _withCtx(() => [
                  _createElementVNode("legend", null, [
                    (_ctx.isNew)
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_4, " NEW SODIUM MELD "))
                      : (_openBlock(), _createElementBlock("h5", _hoisted_5, " SELECTED SODIUM MELD "))
                  ])
                ]),
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_checkbox_input, {
                        name: "single-sample-date-time",
                        labelName: "Use Single Sample Date-time?",
                        inputId: "single-sample-date-time",
                        modelValue: _ctx.editState.use_single_sample_datetime,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.use_single_sample_datetime) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_date_input, {
                        inputId: "single-sample-date",
                        name: "Sample Date",
                        disabled: !_ctx.editState.use_single_sample_datetime,
                        rules: {required: _ctx.editState.use_single_sample_datetime == true},
                        modelValue: _ctx.editState.single_sample_date,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.single_sample_date) = $event))
                      }, null, 8, ["disabled", "rules", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_time_input, {
                        "input-id": "single-sample-time",
                        name: "Sample Time",
                        modelValue: _ctx.editState.single_sample_time,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.single_sample_time) = $event)),
                        disabled: !_ctx.editState.use_single_sample_datetime,
                        rules: {required: _ctx.editState.use_single_sample_datetime == true}
                      }, null, 8, ["modelValue", "disabled", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_checkbox_input, {
                        name: "single-sample-anticoagulated",
                        labelName: "Sample Anticoagulated?",
                        inputId: "single-sample-anticoagulated",
                        modelValue: _ctx.editState.single_sample_anticoagulated,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.single_sample_anticoagulated) = $event)),
                        disabled: !_ctx.editState.use_single_sample_datetime,
                        label: "Yes"
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ]),
                  _cache[38] || (_cache[38] = _createElementVNode("div", { class: "hr-break mt-2" }, null, -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_number_input, {
                        inputId: "total_bilirubin",
                        name: "Total Bilirubin",
                        append: true,
                        rules: "required",
                        appendText: "µmol/L",
                        step: "0.1",
                        modelValue: _ctx.editState.total_bilirubin,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.total_bilirubin) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_date_input, {
                        inputId: "bilirubin-sample-date",
                        name: "Sample Date",
                        rules: "required",
                        modelValue: _ctx.editState.bilirubin_sample_date,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.bilirubin_sample_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_time_input, {
                        "input-id": "bilirubin-sample-time",
                        name: "Sample Time",
                        modelValue: _ctx.editState.bilirubin_sample_time,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.bilirubin_sample_time) = $event)),
                        rules: "required"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_checkbox_input, {
                        name: "bilirubin-sample-anticoagulated",
                        labelName: "Sample Anticoagulated?",
                        inputId: "bilirubin-sample-anticoagulated",
                        modelValue: _ctx.editState.bilirubin_sample_anticoagulated,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.bilirubin_sample_anticoagulated) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _cache[39] || (_cache[39] = _createElementVNode("div", { class: "hr-break mt-2" }, null, -1)),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_number_input, {
                        inputId: "inr",
                        name: "INR",
                        append: true,
                        rules: "required",
                        appendText: "INR",
                        step: "0.1",
                        modelValue: _ctx.editState.inr,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.inr) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_date_input, {
                        inputId: "inr-sample-date",
                        name: "Sample Date",
                        rules: "required",
                        modelValue: _ctx.editState.inr_sample_date,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.inr_sample_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_time_input, {
                        "input-id": "inr-sample-time",
                        name: "Sample Time",
                        modelValue: _ctx.editState.inr_sample_time,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.inr_sample_time) = $event)),
                        rules: "required"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_checkbox_input, {
                        name: "inr-sample-anticoagulated",
                        labelName: "Sample Anticoagulated?",
                        inputId: "inr-sample-anticoagulated",
                        modelValue: _ctx.editState.inr_sample_anticoagulated,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.inr_sample_anticoagulated) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _cache[40] || (_cache[40] = _createElementVNode("div", { class: "hr-break mt-2" }, null, -1)),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_number_input, {
                        inputId: "serum_albumin",
                        name: "Serum Albumin",
                        append: true,
                        rules: "required",
                        appendText: "g/L",
                        step: "0.1",
                        modelValue: _ctx.editState.serum_albumin,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.serum_albumin) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_date_input, {
                        inputId: "serum_albumin-sample-date",
                        name: "Sample Date",
                        rules: "required",
                        modelValue: _ctx.editState.serum_albumin_sample_date,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.serum_albumin_sample_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(_component_time_input, {
                        "input-id": "serum_albumin-sample-time",
                        name: "Sample Time",
                        modelValue: _ctx.editState.serum_albumin_sample_time,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.serum_albumin_sample_time) = $event)),
                        rules: "required"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_component_checkbox_input, {
                        name: "serum_albumin-sample-anticoagulated",
                        labelName: "Sample Anticoagulated?",
                        inputId: "serum_albumin-sample-anticoagulated",
                        modelValue: _ctx.editState.serum_albumin_sample_anticoagulated,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.serum_albumin_sample_anticoagulated) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _cache[41] || (_cache[41] = _createElementVNode("div", { class: "hr-break mt-2" }, null, -1)),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_number_input, {
                        inputId: "serum_creatinine",
                        name: "Serum Creatinine",
                        append: true,
                        rules: "required",
                        appendText: "µmol",
                        step: "0.1",
                        modelValue: _ctx.editState.serum_creatinine,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.serum_creatinine) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_date_input, {
                        inputId: "serum_creatinine-sample-date",
                        name: "Sample Date",
                        rules: "required",
                        modelValue: _ctx.editState.serum_creatinine_sample_date,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.serum_creatinine_sample_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_time_input, {
                        "input-id": "serum_creatinine-sample-time",
                        name: "Sample Time",
                        modelValue: _ctx.editState.serum_creatinine_sample_time,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.serum_creatinine_sample_time) = $event)),
                        rules: "required"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_checkbox_input, {
                        name: "serum_creatinine-sample-anticoagulated",
                        labelName: "Sample Anticoagulated?",
                        inputId: "serum_creatinine-sample-anticoagulated",
                        modelValue: _ctx.editState.serum_creatinine_sample_anticoagulated,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.serum_creatinine_sample_anticoagulated) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _cache[42] || (_cache[42] = _createElementVNode("div", { class: "hr-break mt-2" }, null, -1)),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_number_input, {
                        inputId: "serum_sodium",
                        name: "Serum Sodium",
                        append: true,
                        rules: "required",
                        appendText: "mEq/L",
                        step: "0.1",
                        modelValue: _ctx.editState.serum_sodium,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.serum_sodium) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createVNode(_component_date_input, {
                        inputId: "serum_sodium-sample-date",
                        name: "Sample Date",
                        rules: "required",
                        modelValue: _ctx.editState.serum_sodium_sample_date,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.serum_sodium_sample_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createVNode(_component_time_input, {
                        "input-id": "serum_creatinine-sample-time",
                        name: "Sample Time",
                        modelValue: _ctx.editState.serum_sodium_sample_time,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.serum_sodium_sample_time) = $event)),
                        rules: "required"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createVNode(_component_checkbox_input, {
                        name: "serum_sodium-sample-anticoagulated",
                        labelName: "Sample Anticoagulated?",
                        inputId: "serum_sodium-sample-anticoagulated",
                        modelValue: _ctx.editState.serum_sodium_sample_anticoagulated,
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.serum_sodium_sample_anticoagulated) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_sub_section, { "sub-section-id": "proto-sodium-meld-dialysis-section" }, {
            contents: _withCtx(() => [
              _createVNode(_component_form_layout, { "form-id": "dialysis-form" }, {
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _createVNode(_component_checkbox_input, {
                        name: "on-dialysis",
                        labelName: "On Dialysis?",
                        inputId: "on-dialysis",
                        modelValue: _ctx.editState.on_dialysis,
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.on_dialysis) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_date_input, {
                        inputId: "last-dialysis-date",
                        name: "Last Dialysis Date",
                        disabled: !_ctx.editState.on_dialysis,
                        rules: {required: _ctx.editState.on_dialysis == true},
                        modelValue: _ctx.editState.last_dialysis_date,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editState.last_dialysis_date) = $event))
                      }, null, 8, ["disabled", "rules", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createVNode(_component_select_input, {
                        "select-id": "hepatic-encephalopathy",
                        name: "Hepatic Encephalopathy",
                        options: _ctx.hepaticEncephalopathyOptions,
                        modelValue: _ctx.editState.hepatic_encephalopathy,
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.editState.hepatic_encephalopathy) = $event))
                      }, null, 8, ["options", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_40, [
                      _createVNode(_component_date_input, {
                        inputId: "hepatic-encephalopathy-observation-date",
                        name: "Observation Date",
                        modelValue: _ctx.editState.hepatic_encephalopathy_observation_date,
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.editState.hepatic_encephalopathy_observation_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _createVNode(_component_select_input, {
                        "select-id": "ascites",
                        name: "Ascites",
                        options: _ctx.ascitesOptions,
                        modelValue: _ctx.editState.ascites,
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.editState.ascites) = $event))
                      }, null, 8, ["options", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_42, [
                      _createVNode(_component_date_input, {
                        inputId: "ascites-observation-date",
                        name: "Observation Date",
                        modelValue: _ctx.editState.ascites_observation_date,
                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.editState.ascites_observation_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createVNode(_component_checkbox_input, {
                        name: "tips-procedure",
                        labelName: "TIPS Procedure?",
                        inputId: "tips-procedure",
                        modelValue: _ctx.editState.tips_procedure,
                        "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.editState.tips_procedure) = $event)),
                        label: "Yes"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      _createVNode(_component_date_input, {
                        inputId: "tips-procedure-observation-date",
                        name: "Observation Date",
                        modelValue: _ctx.editState.tips_procedures_observation_date,
                        "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.editState.tips_procedures_observation_date) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_sub_section, {
            "sub-section-id": "proto-sodium-meld-score-section",
            enforceDivider: true
          }, {
            contents: _withCtx(() => [
              _createVNode(_component_form_layout, { "form-id": "dialysis-score-form" }, {
                contents: _withCtx(() => [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, [
                      _createVNode(_component_text_input, {
                        rules: "required",
                        inputId: "sodium-meld-score",
                        name: "Sodium MELD Score",
                        modelValue: _ctx.editState.sodium_meld_score,
                        "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.editState.sodium_meld_score) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_47, [
                      _createVNode(_component_date_input, {
                        inputId: "calculated-date",
                        rules: "required",
                        name: "Calculated Date",
                        modelValue: _ctx.editState.calculated_date,
                        "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.editState.calculated_date) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_48, [
                      _createVNode(_component_date_input, {
                        inputId: "expiration-date",
                        rules: "required",
                        name: "Expiration Date",
                        modelValue: _ctx.editState.expiration_date,
                        "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.editState.expiration_date) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("div", _hoisted_50, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "meld-comments",
                        name: "Comments",
                        rows: "4",
                        modelValue: _ctx.editState.comments,
                        "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.editState.comments) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                save: _withCtx(() => [
                  _createVNode(_component_save_toolbar, {
                    show: true,
                    ref: "saveSodiumMeld",
                    label: "Save Sodium MELD",
                    cancelButton: true,
                    onSave: _cache[36] || (_cache[36] = ($event: any) => (_ctx.performSave())),
                    onCancel: _cache[37] || (_cache[37] = ($event: any) => (_ctx.clearForm()))
                  }, null, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}