import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "p-column-title"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "daytime-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!
  const _component_proto_compare_modal = _resolveComponent("proto-compare-modal")!

  return (_ctx.isProtoCurrentOffersEnabled && _ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, {
          "section-id": "proto_current_offers",
          "lookups-to-load": _ctx.lookupsToLoad
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_sub_section, {
              "sub-section-id": "proto-current-offers-section",
              title: "OFFER RESPONSES"
            }, {
              contents: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.getStyleClass())
                }, [
                  _createVNode(_component_DataTable, {
                    class: _normalizeClass(["offers-table", {'scrollbox': true}]),
                    value: _ctx.currentOffersTableConfig.data,
                    rows: _ctx.currentOffersTableConfig.data.length,
                    rowClass: _ctx.rowStyle,
                    id: "current-offers-table"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentOffersTableConfig.columns, (col, index) => {
                        return (_openBlock(), _createBlock(_component_Column, {
                          field: col.field,
                          key: col.field,
                          style: _normalizeStyle(_ctx.buildColumnStyle(col)),
                          header: col.label
                        }, {
                          body: _withCtx((slotProps) => [
                            (_ctx.isMobile)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(col.label), 1))
                              : _createCommentVNode("", true),
                            (col.field == 'donor_id')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                  _createElementVNode("a", {
                                    href: "javascript:void(0);",
                                    tabindex: "0",
                                    class: "table-link",
                                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTableColClick && _ctx.onTableColClick(...args)))
                                  }, _toDisplayString(slotProps.data[col.field]), 1)
                                ]))
                              : (col.field == 'offer_date_time')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(slotProps.data.offer_date_time), 1))
                                : (col.field == 'response_date')
                                  ? (_openBlock(), _createBlock(_component_date_input, {
                                      key: 3,
                                      inputId: `${col.field} - ${index}`,
                                      name: col.field,
                                      hideLabel: true,
                                      modelValue: slotProps.data.response_date,
                                      "onUpdate:modelValue": ($event: any) => ((slotProps.data.response_date) = $event)
                                    }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue"]))
                                  : (col.field == 'response_time')
                                    ? (_openBlock(), _createBlock(_component_time_input, {
                                        key: 4,
                                        inputId: `${col.field} - ${index}`,
                                        name: col.field,
                                        hideLabel: true,
                                        modelValue: slotProps.data[col.field],
                                        "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                      }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue"]))
                                    : (col.field == 'coordinator')
                                      ? (_openBlock(), _createBlock(_component_select_input, {
                                          key: 5,
                                          "select-id": `${col.field} - ${index}`,
                                          name: col.field,
                                          hideLabel: true,
                                          options: _ctx.coordinatorOptions,
                                          modelValue: slotProps.data[col.field],
                                          "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                        }, null, 8, ["select-id", "name", "options", "modelValue", "onUpdate:modelValue"]))
                                      : (col.field == 'surgeon')
                                        ? (_openBlock(), _createBlock(_component_select_input, {
                                            key: 6,
                                            "select-id": `${col.field} - ${index}`,
                                            name: col.field,
                                            hideLabel: true,
                                            options: _ctx.surgeonOptions,
                                            modelValue: slotProps.data[col.field],
                                            "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                          }, null, 8, ["select-id", "name", "options", "modelValue", "onUpdate:modelValue"]))
                                        : (col.field == 'offer_response')
                                          ? (_openBlock(), _createBlock(_component_select_input, {
                                              key: 7,
                                              "select-id": `${col.field} - ${index}`,
                                              name: col.field,
                                              hideLabel: true,
                                              options: _ctx.offerResponseOptions,
                                              modelValue: slotProps.data[col.field],
                                              "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                            }, null, 8, ["select-id", "name", "options", "modelValue", "onUpdate:modelValue"]))
                                          : (col.field == 'response_category')
                                            ? (_openBlock(), _createBlock(_component_select_input, {
                                                key: 8,
                                                "select-id": `${col.field} - ${index}`,
                                                name: col.field,
                                                hideLabel: true,
                                                options: _ctx.responseCategoryOptions,
                                                modelValue: slotProps.data[col.field],
                                                "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                              }, null, 8, ["select-id", "name", "options", "modelValue", "onUpdate:modelValue"]))
                                            : (col.field == 'outcome_disposition')
                                              ? (_openBlock(), _createBlock(_component_select_input, {
                                                  key: 9,
                                                  "select-id": `${col.field} - ${index}`,
                                                  name: col.field,
                                                  hideLabel: true,
                                                  options: _ctx.outcomeDispostionOptions,
                                                  modelValue: slotProps.data[col.field],
                                                  "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                                }, null, 8, ["select-id", "name", "options", "modelValue", "onUpdate:modelValue"]))
                                              : (col.field == 'notes')
                                                ? (_openBlock(), _createBlock(_component_text_area_input, {
                                                    key: 10,
                                                    "input-id": `${col.field} - ${index}`,
                                                    name: col.field,
                                                    rows: "1",
                                                    hideLabel: true,
                                                    modelValue: slotProps.data[col.field],
                                                    "onUpdate:modelValue": ($event: any) => ((slotProps.data[col.field]) = $event)
                                                  }, null, 8, ["input-id", "name", "modelValue", "onUpdate:modelValue"]))
                                                : (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
                                                    _createTextVNode(_toDisplayString(slotProps.data[col.field ]), 1)
                                                  ], 64))
                          ]),
                          _: 2
                        }, 1032, ["field", "style", "header"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "rows", "rowClass"]),
                  _createVNode(_component_save_toolbar, {
                    show: true,
                    label: "Save Reponses",
                    cancelButton: false,
                    buttonClass: "btn btn-sm btn-wide btn-success",
                    cancelButtonClass: "btn btn-sm btn-wide btn-secondary ml-auto"
                  })
                ], 2)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["lookups-to-load"]),
        _createVNode(_component_proto_compare_modal, {
          selectedDonorId: _ctx.selectedDonorId,
          modalId: "compare-modal",
          ref: "compareModal"
        }, null, 8, ["selectedDonorId"])
      ]))
    : _createCommentVNode("", true)
}