import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_donor_summary_card = _resolveComponent("loading-donor-summary-card")!
  const _component_loading_section = _resolveComponent("loading-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_loading_donor_summary_card),
    _cache[0] || (_cache[0] = _createStaticVNode("<div><span class=\"skeleton-box skeleton-title w-100\"></span><div class=\"card summary-card shadow-none skeleton-padding\"><div class=\"column\"><span class=\"skeleton-box w-100\" style=\"height:100px;\"></span></div></div></div><span class=\"skeleton-box skeleton-title w-100\"></span>", 2)),
    _createVNode(_component_loading_section),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "skeleton-box skeleton-title w-100" }, null, -1)),
    _createVNode(_component_loading_section),
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "skeleton-box skeleton-title w-100" }, null, -1)),
    _createVNode(_component_loading_section)
  ]))
}