import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group-large" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group-large" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group-large" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoSocialHistoryEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "proto-social-history-section",
        title: _ctx.title
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_form_layout, { "form-id": "social_history-form" }, {
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_select_input, {
                    "select-id": "alcohol_use",
                    name: "Alcohol Use",
                    options: _ctx.amountOptions,
                    modelValue: _ctx.editState.alcohol_use,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.alcohol_use) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_text_area_input, {
                    "input-id": "alcohol_use-comment",
                    name: "Alcohol Use Comments",
                    rows: "4",
                    modelValue: _ctx.editState.alcohol_use_comments,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.alcohol_use_comments) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_select_input, {
                    "select-id": "smoking_history",
                    name: "Smoking History",
                    options: _ctx.amountOptions,
                    modelValue: _ctx.editState.smoking_history,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.smoking_history) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_number_input, {
                    inputId: "smoking_rate",
                    name: "Smoking Rate",
                    disabled: _ctx.checkDisabled(),
                    step: "0.5",
                    modelValue: _ctx.editState.smoking_rate,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.smoking_rate) = $event))
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_number_input, {
                    inputId: "smoking_duration",
                    name: "Smoking Duration",
                    disabled: _ctx.checkDisabled(),
                    step: "0.5",
                    modelValue: _ctx.editState.smoking_duration,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.smoking_duration) = $event))
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_text_area_input, {
                    "input-id": "smoking_history_comments",
                    name: "Smoking History Comments",
                    rows: "4",
                    modelValue: _ctx.editState.smoking_history_comments,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.smoking_history_comments) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_select_input, {
                    "select-id": "marijuana_history",
                    name: "History of Marijuana Use",
                    options: _ctx.amountOptions,
                    modelValue: _ctx.editState.marijuana_history,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.marijuana_history) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_text_area_input, {
                    "input-id": "marijuana_use_comments",
                    name: "Marijuana Use Comments",
                    rows: "4",
                    modelValue: _ctx.editState.marijuana_use_comments,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.marijuana_use_comments) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_select_input, {
                    "select-id": "drug_use",
                    name: "Drug Use",
                    options: _ctx.amountOptions,
                    modelValue: _ctx.editState.drug_use,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.drug_use) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_text_area_input, {
                    "input-id": "drug_use_comments",
                    name: "Drug Use Comments",
                    rows: "4",
                    modelValue: _ctx.editState.drug_use_comments,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.drug_use_comments) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveSurgery",
                label: "Save Social History",
                cancelButton: true,
                onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.performSave())),
                onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.clearForm()))
              }, null, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}