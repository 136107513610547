<template>
  <form v-if="isProtoSmallBowelInformationEnabled">
    <sub-section
      sub-section-id="proto_small_bowel_information"
      :title="title"
    >
    <template v-slot:contents>
    
      <!-- Form layout -->
     
        <form-layout
        form-id="small-bowel-Information-form">
          <template v-slot:contents>
            <div class="row">
              <div class="standard-full-width-group">
                <text-area-input 
                  input-id="small-bowel-info-comments" 
                  name="General Comments" 
                  rules=max_length:5000
                  rows="4" 
                  v-model="editState.comments"  />
              </div>
            </div>
   

          </template>
          <template v-slot:save>
            <save-toolbar
              :show="true"     
              ref="saveSmallBowelInformation"
              label="Save Small Bowel Information"
              :cancelButton="true"
              @save="performSave()"
              @cancel="clearForm()"
            />
          </template>


        </form-layout>
        
      </template>
    </sub-section>
  </form>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import {
  SaveProvider,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface SmallBowelInformationForm {
  comments: string
}

@Component({
  components: {
    SubSection,
    TextAreaInput,
    SaveToolbar,
    FormLayout
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoKidneyInformation extends Vue {

  private title: any = "SMALL BOWEL INFORMATION";

  private editState: SmallBowelInformationForm = {
    comments: ""
  };


  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  get isProtoSmallBowelInformationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SmallBowelInformation);
  }

        // Each save toolbar will need a unique ref
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveSmallBowelInformation as SaveToolbar;
  }

  // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
  async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.SmallBowelInformation, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: SmallBowelInformationForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        comments: response.comments
      };
    }

  public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: SmallBowelInformationForm = {
        comments: this.editState.comments
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 


    public clearForm(): any {
      this.editState.comments = "";
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveSmallBowelInformation as unknown as SaveProvider;

      saveToolbar.resetSaveToolbar();
    }
    
 }



</script>

<style>


</style>
