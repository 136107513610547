import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "side-nav",
  class: "page-nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<ul aria-label=\"Toolbar\" class=\"menu-header-nav hidden-sm\"><li class=\"nav-item skeleton-padding-icon\"><span class=\"skeleton-box\" style=\"width:23px;height:23px;\"></span></li><li class=\"nav-item skeleton-padding-icon\"><span class=\"skeleton-box\" style=\"width:23px;height:23px;\"></span></li><li class=\"nav-item skeleton-padding-icon\"><span class=\"skeleton-box\" style=\"width:23px;height:23px;\"></span></li></ul><div class=\"side-nav-container\"><div class=\"skeleton-padding\"><span class=\"skeleton-box w-50\"></span><span class=\"skeleton-spacer\"></span><span class=\"skeleton-box w-25\"></span><span class=\"skeleton-spacer\"></span><span class=\"skeleton-box w-75\"></span></div><hr><div class=\"skeleton-padding\"><span class=\"skeleton-box w-25\"></span><ul><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-100\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-100\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li></ul></div><hr><div class=\"skeleton-padding\"><span class=\"skeleton-box w-25\"></span><ul><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-75\"></li><li class=\"skeleton-spacer\"></li><li class=\"skeleton-box w-100\"></li></ul></div></div>", 2)
  ])))
}