import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import axios from 'axios';
import { UIError } from '@/UIModels/error';
import { APIRoute, EP } from '@/api-endpoints';
import { APIImportPatientInterface, APIImportRowInfoInterface, APIDetailRow } from '@/APIModels/importPatient/types';
import { APIPermittedActions, APISaveImportResponse,
  APIRules, APIShowResponse, SaveResult } from '@/types';
import { buildErrorResult } from '@/utils';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UISuccess } from '@/UIModels/success';

export const UIImportDetailStatusTypesLookup = [
  { code: 'in_progress', value: 'In Progress' },
  { code: 'import_completed', value: 'Import Completed' },
  { code: 'import_failed', value: 'Import Failed' },
];

export class UIImportDetailRow {
  public apiSource: APIDetailRow|null = null;
  public row_number: number|null = null;
  public status: string|null = null;
  public error_message: string|null = null;

  // Define new UI view model structure
  public constructor(apiDetailRow: APIDetailRow|null = null) {
    if (apiDetailRow) this.updateFromAPIResponse(apiDetailRow);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiDetailRow: APIDetailRow) {
    this.apiSource = apiDetailRow;
    this.row_number = apiDetailRow.row_number || 0;
    this.status = apiDetailRow.status;
    this.error_message = apiDetailRow.error_message;
  }
}

export class UIImportRowInfo {
  public apiSource: APIImportRowInfoInterface|null = null;

  public id: string|null = null;
  public detailRows: UIImportDetailRow[] = [];
  public recordCount = 0;
  public permittedActions: APIPermittedActions[] = [];
  public loaded = false;

  // Define new UI view model structure
  public constructor(apiImportRowInfo: APIImportRowInfoInterface|null = null) {
    if (apiImportRowInfo) this.updateFromAPIResponse(apiImportRowInfo);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiImportRowInfo: APIImportRowInfoInterface) {
    this.apiSource = apiImportRowInfo;
  }
 
  // Refresh list of import details
  public async load(params?: any): Promise<void> {
    const importId = params.id || '';
    const url = APIRoute(EP.patient_imports.import_row_infos.index, [[':patient_import_id', importId]]);

    try {
      const response: APIShowResponse<any> = await axios.get(url, { params: params });
      const apiImportRowInfo: APIDetailRow[] = response?.data?.import_row_infos.entries || [];
      this.detailRows = apiImportRowInfo.map((item: APIDetailRow): UIImportDetailRow => {
        return new UIImportDetailRow(item);
      });
      this.recordCount = response.data.record_count;
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
      throw new UIError('import_row_info');
    }
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'import_patients');
    vuexStore.commit('validations/set', { rules: { ['import_patients']: rules } });
  }
}

export class UIImportPatient {
  public apiSource: APIImportPatientInterface|null = null;

  public id: string|null = null;
  public datetime: string|null = null;
  public transplantProgram: string|null = null;
  public fileName: string|null = null;
  public uploadedBy: string|null = null;
  public currentStatus: string|null = null;
  public patientCount: number|null = null;
  public importsFailed: number|null = null;

  // Define new UI view model structure
  public constructor(apiPatient: APIImportPatientInterface|null = null) {
    if (apiPatient) this.updateFromAPIResponse(apiPatient);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiPatient: APIImportPatientInterface) {
    this.apiSource = apiPatient;
    this.id = apiPatient._id || null;
    this.datetime = apiPatient.created_at || null;
    this.transplantProgram = apiPatient.hospital_id || null;
    this.fileName = apiPatient.imported_file?.original_filename || null;
    this.uploadedBy = apiPatient.updated_by || null;
    this.currentStatus = apiPatient.status || null;
    this.patientCount = apiPatient.row_count || 0;
    this.importsFailed = apiPatient.failure_count || 0;
  }
}

export class UIImportFileType {
  public fileName?: string;
  public fileType?: string;
}

export class UIImportFile {
  public importAttachments?: FileList|null = null;
  public transplant_program?: string|null = null;
  public filename: string|null = null;
  public uploadedFiles?: UIImportFileType[]|null = null;

  // Define new UI view model structure
  public constructor() {
    this.importAttachments = null;
    this.transplant_program = null;
    this.filename = null;
    this.uploadedFiles = null;
  }

  // Generate request payload parameters to provide to API for a Recipient Document patch
  public extractPatch(selected: UIImportFile): any {
    const payload: FormData = new FormData();

    payload.append('patient_import[hospital_id]', selected.transplant_program || '');
    payload.append('patient_import[original_filename]', selected.filename || '');

    // We only allow the file to be uploaded on create, not on update
    if (selected.importAttachments && selected.importAttachments[0]) {
      payload.append('patient_import[file]', selected.importAttachments[0]);
    }

    return payload;
  }

  // Save edit state to the backend
  public save(opts: { selected: UIImportFile } ): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {

      const selected: UIImportFile|null = opts.selected || null;
      // check for blank content
      if (!selected) reject((new UIError('import_patients')));

      // continue processing
      const payload = this.extractPatch(selected);

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      const method: any = axios.post;
      const ep = APIRoute(EP.patient_imports.create);

      method(ep, payload, headers).then((response: APISaveImportResponse) => {
        if (response.data.errors) {
          reject((new UIError('import_patients', response)).errorResult);
        } else {
          // Success! We may need to update the current page          
          const currentPageStore = useCurrentPageStore();
          currentPageStore.importPatients?.loadImportPatientHistory();
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        // if 403 alert user, as there will be no server response
        if (errorResponse.response.status === 403) {
          const forbiddenResponse = buildErrorResult(errorResponse.message);
          reject(forbiddenResponse);
        // otherwise send error response
        } else {
          reject((new UIError('import_patients', errorResponse)).errorResult);
        }
      });
    });
  }

  /**
  * As filename is stored in editState's importAttachments object,
  * get that attribute and assign it to the filename attribute if exists
  *
  */
  public setFilenameFromObject(): void {
    if (this.importAttachments && this.importAttachments[0]) {
        this.filename = this.importAttachments[0].name;
      } else {
        this.filename = '';
    }
  }
}

export class UIImportPatients {
  public importPatientHistory: UIImportPatient[] = [];
  public recordCount = 0;

  public loaded = false;

  public permittedActions: APIPermittedActions[] = [];

  // Load resource data and permitted actions
  public async load(): Promise<void> {
    await this.loadImportPatientHistory({
      page_number: 1,
      page_size: 10
    });
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'import_patients');
    vuexStore.commit('validations/set', { rules: { ['import_patients']: rules } });
  }

  public get canSave(): boolean {
    // We have a list of permitted actions, so now we can check for "create" or "update" keywords
    return this.permittedActions.includes(APIPermittedActions.Create) || this.permittedActions.includes(APIPermittedActions.Update);
  }

  public async loadImportPatientHistory(params?: any): Promise<void> {
    const url = APIRoute(EP.patient_imports.index);

    try {
      const response: APIShowResponse<any> = await axios.get(url, { params: params });
      const apiImportHistory: APIImportPatientInterface[] = response?.data?.patient_imports.entries || [];
      this.importPatientHistory = apiImportHistory.map((item: APIImportPatientInterface): UIImportPatient => {
        return new UIImportPatient(item);
      });
      this.recordCount = response.data.record_count;
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
      throw new UIError('import_patients');
    }
  }    
}
