import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "nav action-row"
}
const _hoisted_3 = {
  key: 1,
  class: "nav action-row"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_10 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_11 = { class: "col-xs-12" }
const _hoisted_12 = { class: "modal-footer-body" }
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_ctx.showToolbar || _ctx.loading)
    ? (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("nav", _hoisted_2, _cache[5] || (_cache[5] = [
              _createElementVNode("span", { class: "skeleton-box skeleton-button-left mb-0" }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("nav", _hoisted_3, [
              (_ctx.canArchive)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-wide btn-warning mr-2",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openArchive && _ctx.openArchive(...args)), ["prevent"])),
                    disabled: !_ctx.canArchive
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('action.archive')) + " ", 1),
                    (_ctx.isArchiving)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "fa-1x fa-spin",
                            icon: ['far', 'spinner-third']
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_4))
                : _createCommentVNode("", true),
              (_ctx.canRestore)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: "btn btn-wide btn-primary mr-2",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openRestore && _ctx.openRestore(...args)), ["prevent"])),
                    disabled: !_ctx.canRestore
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('action.restore')) + " ", 1),
                    (_ctx.isRestoring)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "fa-1x fa-spin",
                            icon: ['far', 'spinner-third']
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_6))
                : _createCommentVNode("", true),
              (_ctx.canDestroy)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    type: "button",
                    class: "btn btn-wide btn-danger mr-2",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openDestroy && _ctx.openDestroy(...args)), ["prevent"])),
                    disabled: !_ctx.canDestroy
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.destroyButtonText ? _ctx.destroyButtonText : _ctx.$t('action.destroy')) + " ", 1),
                    (_ctx.isDestroying)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "fa-1x fa-spin",
                            icon: ['far', 'spinner-third']
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_8))
                : _createCommentVNode("", true)
            ])),
        _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
        _createVNode(_component_validation_observer, {
          ref: "actionValidation",
          autocomplete: "off",
          tag: "form"
        }, {
          default: _withCtx(({ handleSubmit }) => [
            _createVNode(_component_modal_section, {
              modalId: "actionModal",
              ref: "actionModal",
              class: "modal-action-modal",
              centered: true,
              closeButton: false,
              size: "md",
              wide: false
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleText), 1)
              ]),
              body: _withCtx(() => [
                _createElementVNode("fieldset", null, [
                  _createElementVNode("small", null, _toDisplayString(_ctx.getGuidingText()), 1),
                  (_ctx.rationaleRequired)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_text_input, {
                            rules: "required",
                            "input-id": "archive-rationale",
                            required: "true",
                            name: _ctx.getLocaleText('rationale'),
                            modelValue: _ctx.rationale,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.rationale) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    class: "btn btn-success",
                    onClick: _withModifiers(($event: any) => (_ctx.rationaleRequired ? handleSubmit(_ctx.performAction): _ctx.performAction()), ["prevent"])
                  }, _toDisplayString(_ctx.$t('action.confirm')), 9, _hoisted_13),
                  _createElementVNode("button", {
                    type: "button",
                    "data-dismiss": "modal",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
                    class: "btn btn-secondary"
                  }, _toDisplayString(_ctx.$t('action.cancel')), 1)
                ])
              ]),
              _: 2
            }, 1536)
          ]),
          _: 1
        }, 512)
      ]))
    : _createCommentVNode("", true)
}