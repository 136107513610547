<template>
  <sub-section v-if="isProtoSurgicalHistoryEnabled"
    sub-section-id="proto-surgical-history-section"
    :title="title"
  >
  <template v-slot:contents>
    
    <!-- Filter Bar + Create Button -->
    <table-toolbar
      :createButton="true"
      createText="Add Previous Surgery"
      @table-create-row="clearForm"
    >
    </table-toolbar>

    <!-- List of Items, or History List -->
    <table-list
      ref="surgeryTable"
      tabbableColumn="calculated_date"
      table-id="surgery-table"
      :table-config="surgeryTableConfig"
      @table-row-click="editSelected"
      :highlightSelection="true"
      :rowStyleClass="rowStyleClass"
    />

    <!-- Form layout -->
   
      <form-layout
      form-id="surgery-form">
        <template v-slot:title>
          <!-- Mode indicator / subsection form title -->
          <legend>
            <h5 v-if="isNew" class="legend-title">
              ADD PREVIOUS SURGERY
            </h5>
            <h5 v-else class="legend-title has-guiding-text">
              SELECTED PREVIOUS SURGERY
            </h5>
          </legend>
        </template>
     

      <template v-slot:contents
        v-if="editState">
        <div class="row">
          <div class="standard-form-group">
            <AutoCompleteInput
              rules="required" 
              name="surgery"
              :options="surgeryOptions"
              v-model="editState.surgery"
              inputId="surgery"
              :showDropdown="true"
              label="Previous Surgery"
              placeholder="Select"
            />
          </div>

          <div class="standard-form-group-large">
            <text-area-input 
              input-id="surgery-comment" 
              name="Comment" 
              rows="4" 
              v-model="editState.comment"
            />
          </div>
        </div>

      </template>
      <template v-slot:save>
        <save-toolbar
          :show="true"     
          ref="saveSurgery"
          label="Save Previous Surgery"
          :cancelButton="true"
          @save="performSave"
          @cancel="clearForm"
        />
      </template>
    </form-layout>    
  </template>
</sub-section>
</template>


<script lang="ts">
import {
Component,
Vue,
Prop,
Watch,
mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import AutoCompleteInput from "@/components/shared/AutoCompleteInput.vue";
import {
SaveProvider,
TableConfig,
} from '@/types';
import { GenericObject } from 'vee-validate';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface SurgicalHistoryForm {
id: string|null,
surgery_date: Date|string|null,
surgery: string|null,
comment: string|null
}

@Component({
components: {
  CardSection,
  FilterComponent,
  SubSection,
  TableToolbar,
  TableList,
  TextAreaInput,
  SaveToolbar,
  FormLayout,
  ActionToolbar,
  DateInput,
  NumberInput,
  TextInput,
  AutoCompleteInput,
  SelectInput
},
emits: [
  'cancel',
],
})
export default class ProtoSurgicalHistorySection extends mixins(DateUtilsMixin) {

private title: any = "SURGICAL HISTORY";

private isNew: any = true;

private editState: SurgicalHistoryForm = {
    id: null,
    surgery_date: null,
    surgery: null,
    comment: null
  };

get isProtoSurgicalHistoryEnabled(): boolean {
  return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SurgicalHistory);
}

// Create a prototype bucket to store arbitrary key/value data
private protoBucket!: UIPrototypeBucket;

private surgeries: any = [];

public perPage = 10;
public currentPageTable = 1;

private translateSurgeryOption(surgery: any) {
  if (!surgery) {
    return;
  }

  const found: any = this.surgeryOptions.find((item: any) => {
    return item.code == surgery;
  });
  if (!found) {
    return;
  }
  return found.value;
}

get surgeryOptions() {
  return [
    {
      code: 'code_1',
      value: 'Coronary artery bypass grafting (CABG x1)'
    }, 
    {
      code: 'code_2',
      value: 'Coronary artery bypass grafting (CABG x2)'
    }, 
    {
      code: 'code_3',
      value: 'Coronary artery bypass grafting (CABG x3)'
    },
    {
      code: 'code_4',
      value: 'Coronary artery bypass grafting (CABG x4)'
    }, 
    {
      code: 'code_5',
      value: 'Percutaneous coronary intervention'
    }, 
    {
      code: 'code_6',
      value: 'Blood transfusion'
    },
    {
      code: 'code_7',
      value: 'Prior kidney donor'
    }
  ];
}

get surgeryTableConfig(): TableConfig {
return {
  data: this.tableRows || [],
  columns: [
    { label: 'Date Added', field: 'surgeryDateDisplayValue',  width: '125px'},
    { label: 'Previous Surgery', field: 'surgeryDisplayValue'},
    { label: 'Comment', field: 'comment'},
  ],
  empty: 'Use the form below to add a previous surgery.',
  sortOptions: {
    enabled: false,
    initialSortBy: [{ field: 'surgery_date', type: 'desc' }]
  },
  pagination: true,
  paginationOptions: {
    enabled: true,
    perPageDropdown: PAGE_SIZES,
    dropdownAllowAll: false,
    position: 'bottom'
  }
};
}
    // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveSurgery as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.SurgicalHistory, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: SurgicalHistoryForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;
  this.surgeries = response;
}

get tableRows() {
  const surgeries = this.surgeries || [];

  if (surgeries.length > 0) {

  return surgeries.map((surgery: any) => {
    return {
      id: surgery.id,
      surgery_date: surgery.surgery_date || null,
      surgery: surgery.surgery || null,
      surgeryDisplayValue: this.translateSurgeryOption(surgery.surgery) || '-',
      surgeryDateDisplayValue: this.parseDisplayDateUi(surgery.surgery_date) || '-',
      comment: surgery.comment || '-'
    };
  });
}
}

public performSave(): any {
// Call the 'startSaving' method to begin save animation
if (this.saveToolbar) this.saveToolbar.startSaving();

let surgeries = this.surgeries || [];

if (this.editState.surgery) {
  const payload: SurgicalHistoryForm = {
    id: Math.random().toString(16).slice(2),
    surgery_date: new Date().toLocaleDateString('en-CA'),
    surgery: this.editState.surgery,
    comment: this.editState.comment
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = surgeries.findIndex((meld: any) => meld.id == this.editState.id);
    
    if (foundIndex != -1) {
      surgeries[foundIndex] = payload;
    }
  } else {  
    surgeries.push(payload);
  }

  this.protoBucket.setItem(EDITSTATE_KEY, surgeries);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
}
} 

private editSelected(row: any) {
  if (!row) return false;

  const rowData = row.row;

  this.editState.id = rowData.id;
  this.editState.surgery_date = rowData.surgery_date || null;
  this.editState.surgery = rowData.surgery || null;
  this.editState.comment = rowData.comment || null;
  this.isNew = false;
}

public clearForm(): any {
  this.isNew = true;
  this.editState.surgery_date = null;
  this.editState.surgery = null;
  this.editState.comment = null;
}

rowStyleClass(row: any) {
// if row editable, change color
return row.editable ? 'tr-highlight-green' : null;
}

// Reference to table
get surgeriesTable(): any {
  return this.$refs.surgeriesTable as any ;
}

  // Saved successfully
  public onSaveSuccess() {
    this.surgeriesTable.resetSelection();
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveSurgery as unknown as SaveProvider;
    saveToolbar.resetSaveToolbar();
  }
}
</script>
