import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "sr-only" }
const _hoisted_3 = { class: "legend-title" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group-large" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "form-group col-auto" }
const _hoisted_12 = { class: "p-label" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = { class: "standard-form-group" }
const _hoisted_20 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "referral-information",
    saveButtonText: `${_ctx.$t('save_referral_information')}`,
    ref: "saveReferralInformation",
    "lookups-to-load": _ctx.lookupsToLoad,
    saveButton: !_ctx.newDonor,
    disabled: !_ctx.canSave,
    onSave: _cache[17] || (_cache[17] = ($event: any) => (_ctx.savePatch())),
    onLoaded: _cache[18] || (_cache[18] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('referral_information')), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            disabled: !_ctx.canSave
          }, [
            _createElementVNode("legend", _hoisted_2, [
              _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t('referral_details_form')), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_number_input, {
                  inputId: "referralInformation-client_id",
                  name: _ctx.$t("client_id"),
                  disabled: _ctx.isDonorIdDisabled,
                  modelValue: _ctx.editState.client_id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.client_id) = $event))
                }, null, 8, ["name", "disabled", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_text_input, {
                  inputId: "referralInformation-national_donor_id",
                  name: _ctx.$t("national_donor_id"),
                  modelValue: _ctx.editState.national_donor_id,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.national_donor_id) = $event)),
                  disabled: true
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_text_input, {
                  ruleKey: "referral.referring_hospital_mrn",
                  inputId: "referralInformation-mrn",
                  name: _ctx.$t("mrn"),
                  modelValue: _ctx.editState.mrn,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.mrn) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "row-break d-none d-xl-block d-xxxl-none" }, null, -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_checkbox_input, {
                  ruleKey: "referral.out_of_province",
                  "input-id": "referralInformation-out_of_province_donation",
                  labelName: _ctx.$t("out_of_province_donation"),
                  modelValue: _ctx.editState.out_of_province_donation,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.out_of_province_donation) = $event)),
                  label: _ctx.$t("affimative"),
                  disabled: "true"
                }, null, 8, ["labelName", "modelValue", "label"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_select_input, {
                  ruleKey: "referral.referring_hospital_id",
                  selectId: "referralInformation-referral_hospital",
                  name: _ctx.$t("referral_hospital"),
                  modelValue: _ctx.editState.referral_hospital,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.referral_hospital) = $event)),
                  options: _ctx.referralHospitalsList,
                  onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setDefaultDelarationHospital())),
                  disabled: !_ctx.isGroupWriteable('donor_referring')
                }, null, 8, ["name", "modelValue", "options", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, [
                  _createTextVNode(_toDisplayString(_ctx.$t("referral_type.title")) + " ", 1),
                  _cache[20] || (_cache[20] = _createElementVNode("i", null, "*", -1))
                ]),
                _createVNode(_component_checkbox_input, {
                  "input-id": "referralInformation-out_of_province",
                  modelValue: _ctx.editState.out_of_province_donation,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.out_of_province_donation) = $event)),
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clearReferralHospital())),
                  label: _ctx.$t("referral_type.referral-type-out-of-province")
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_checkbox_input, {
                  "input-id": "referralInformation-tissue",
                  modelValue: _ctx.editState.tissue,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.tissue) = $event)),
                  label: _ctx.$t("referral_type.referral-type-tissue")
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_checkbox_input, {
                  "input-id": "referralInformation-organ",
                  modelValue: _ctx.editState.organ,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.organ) = $event)),
                  label: _ctx.$t("referral_type.referral-type-organ")
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_checkbox_input, {
                  "input-id": "referralInformation-unknown",
                  modelValue: _ctx.editState.unknown,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.unknown) = $event)),
                  label: _ctx.$t("referral_type.referral-type-unknown")
                }, null, 8, ["modelValue", "label"])
              ]),
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "row-break d-none d-lg-block d-xxl-none" }, null, -1)),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_date_input, {
                  ruleKey: "referral.referral_date",
                  inputId: "referralInformation-referral_date",
                  name: _ctx.$t("referral_date"),
                  modelValue: _ctx.editState.referral_date,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.referral_date) = $event)),
                  disabled: !_ctx.isGroupWriteable('donor_referring')
                }, null, 8, ["name", "modelValue", "disabled"])
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "row-break d-none d-vxl-block d-xxxl-none" }, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_time_input, {
                  inputId: "referralInformation-referral_time",
                  name: _ctx.$t("referral_time"),
                  modelValue: _ctx.editState.referral_time,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.referral_time) = $event)),
                  disabled: !_ctx.isGroupWriteable('donor_referring')
                }, null, 8, ["name", "modelValue", "disabled"])
              ])
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_text_input, {
                  ruleKey: "patient_profile.first_name",
                  inputId: "referralInformation-first_name",
                  name: _ctx.$t("first_name"),
                  modelValue: _ctx.editState.first_name,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.first_name) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_text_input, {
                  ruleKey: "patient_profile.last_name",
                  inputId: "referralInformation-last_name",
                  name: _ctx.$t("last_name"),
                  modelValue: _ctx.editState.last_name,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.last_name) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_select_input, {
                  ruleKey: "patient_profile.sex",
                  selectId: "referralInformation-sex",
                  name: _ctx.$t("sex"),
                  modelValue: _ctx.editState.sex,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.sex) = $event)),
                  options: _ctx.sexOptions,
                  disabled: !_ctx.isGroupWriteable('donor_sex')
                }, null, 8, ["name", "modelValue", "options", "disabled"])
              ]),
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "row-break d-none d-lg-block d-xxxl-none" }, null, -1)),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_date_input, {
                  ruleKey: "patient_profile.birth.date",
                  inputId: "referralInformation-date_of_birth",
                  name: _ctx.$t("date_of_birth"),
                  modelValue: _ctx.editState.date_of_birth,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.date_of_birth) = $event)),
                  minDateLimit: _ctx.getDobMinDateLimit,
                  maxDateLimit: _ctx.getDobMaxDateLimit
                }, null, 8, ["name", "modelValue", "minDateLimit", "maxDateLimit"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_text_input, {
                  disabled: true,
                  inputId: "referralInformation-age",
                  name: _ctx.$t("age"),
                  value: _ctx.newDonor ? _ctx.getNewDonorAge : _ctx.getDonorAge,
                  calculated: true
                }, null, 8, ["name", "value"])
              ])
            ])
          ], 8, _hoisted_1))
    ]),
    _: 1
  }, 8, ["saveButtonText", "lookups-to-load", "saveButton", "disabled"]))
}