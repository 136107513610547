import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "table-responsive-md" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { for: "donorAttachment-clinicalAttachment" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-full-width-group" }
const _hoisted_15 = { class: "standard-full-width-group" }
const _hoisted_16 = {
  key: 0,
  class: "row"
}
const _hoisted_17 = { class: "form-group col-md-12" }
const _hoisted_18 = { class: "form-check form-check-inline row" }
const _hoisted_19 = { class: "col-md-12 m-0 p-0 mb-2" }
const _hoisted_20 = { class: "modal-footer-body" }
const _hoisted_21 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_22 = { class: "form-check form-check-inline row" }
const _hoisted_23 = { class: "col-md-12 m-0 p-0 mb-2" }
const _hoisted_24 = { class: "modal-footer-body" }
const _hoisted_25 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}
const _hoisted_26 = { class: "form-check form-check-inline row" }
const _hoisted_27 = { class: "col-md-12 m-0 p-0 mb-2" }
const _hoisted_28 = { class: "modal-footer-body" }
const _hoisted_29 = {
  type: "button",
  "data-dismiss": "modal",
  class: "btn btn-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-documents",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('donor_documents')), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.editState)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_sub_section, {
              title: _ctx.$t("uploaded_documents"),
              "sub-section-id": "deceasedDonor-uploaded-files"
            }, {
              contents: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", null, [
                        (_ctx.isTableUpdating)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                              _createTextVNode(_toDisplayString(_ctx.$t('loading')) + " ", 1),
                              _createVNode(_component_font_awesome_icon, {
                                class: "fa-1x fa-spin",
                                icon: ['far', 'spinner-third']
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ]),
                (_ctx.hasWriteAccess)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_save_toolbar, {
                          show: !_ctx.isOopDonor,
                          ref: "uploadToCtr",
                          label: _ctx.$t('upload_to_ctr'),
                          disabled: _ctx.selectedDocuments.length == 0 || _ctx.taskInProgress,
                          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleModal('uploadModal'))),
                          class: "action-row temp-saving ml-3 mt-2 mr-2",
                          savingText: _ctx.$t('upload_in_progress'),
                          successText: _ctx.$t('uploaded'),
                          defaultErrorText: _ctx.$t('unable_to_upload_to_ctr')
                        }, null, 8, ["show", "label", "disabled", "savingText", "successText", "defaultErrorText"]),
                        _createVNode(_component_save_toolbar, {
                          ref: "downloadFromCtr",
                          label: _ctx.$t('download_documents'),
                          show: _ctx.isOopDonor,
                          disabled: _ctx.taskInProgress,
                          onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleModal('downloadModal'))),
                          class: "action-row temp-saving ml-3 mt-2 mr-2",
                          savingText: _ctx.$t('download_in_progress'),
                          successText: _ctx.$t('downloaded'),
                          defaultErrorText: _ctx.$t('unable_to_download_from_ctr')
                        }, null, 8, ["label", "show", "disabled", "savingText", "successText", "defaultErrorText"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_sub_section, {
              ref: "uploadDocument",
              "sub-section-id": "deceasedDonor-upload-document",
              saveButton: _ctx.hasWriteAccess,
              "save-button-text": _ctx.editState.id? `${_ctx.$t('save_description')}` : `${_ctx.$t('save_document')}` ,
              onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.uploadDocument()))
            }, {
              contents: _withCtx(() => [
                _createElementVNode("fieldset", {
                  disabled: !_ctx.hasWriteAccess
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("label", _hoisted_10, [
                        _createTextVNode(_toDisplayString(_ctx.$t('clinical_attachment')) + " ", 1),
                        _cache[12] || (_cache[12] = _createElementVNode("i", null, "*", -1))
                      ]),
                      _createElementVNode("input", {
                        "input-id": "donorAttachment-clinicalAttachment",
                        ref: "fileUploader",
                        type: "file",
                        class: "form-control",
                        disabled: !!_ctx.editState.id,
                        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClinicalAttachmentsChanged($event)))
                      }, null, 40, _hoisted_11)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_select_input, {
                        "select-id": "donorAttachment-categoryCode",
                        rules: "required",
                        name: _ctx.$t("category"),
                        options: _ctx.attachmentCategoryOptions(true),
                        modelValue: _ctx.editState.categoryCode,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.categoryCode) = $event))
                      }, null, 8, ["name", "options", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_text_input, {
                        name: _ctx.$t("fileName"),
                        "input-id": "donorAttachment-fileName",
                        rules: "required",
                        modelValue: _ctx.editState.fileName,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.fileName) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "row-break d-none d-xl-block" }, null, -1)),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_text_area_input, {
                        "input-id": "donorAttachment-description",
                        name: _ctx.$t("description"),
                        rules: "required",
                        rows: "4",
                        modelValue: _ctx.editState.description,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.description) = $event))
                      }, null, 8, ["name", "modelValue"])
                    ])
                  ]),
                  (_ctx.hasWriteAccess)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _withDirectives(_createElementVNode("button", {
                            class: "btn btn-danger float-right",
                            type: "button",
                            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.onDeleteFileClick()), ["prevent"]))
                          }, _toDisplayString(_ctx.$t('remove_document')), 513), [
                            [_vShow, _ctx.editState.id]
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_7),
                _createVNode(_component_modal_section, {
                  modalId: "upload-documents-modal",
                  ref: "uploadModal",
                  class: "modal-sticky-header",
                  centered: true
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('upload_documents')), 1)
                  ]),
                  body: _withCtx(() => [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('upload_documents_confirmation')), 1)
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("button", _hoisted_21, _toDisplayString(_ctx.$t('cancel')), 1),
                      _createElementVNode("a", {
                        class: "btn btn-success",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.uploadDocumentsToCtr()))
                      }, _toDisplayString(_ctx.$t('ok')), 1)
                    ])
                  ]),
                  _: 1
                }, 512),
                _createVNode(_component_modal_section, {
                  modalId: "delete-documents-modal",
                  ref: "deleteModal",
                  class: "modal-sticky-header",
                  centered: true
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('delete_document')), 1)
                  ]),
                  body: _withCtx(() => [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.editState.ctrStatus == 'uploaded' ?
                      _ctx.$t('delete_documents_confirmation_ctr', { fileName: _ctx.editState.fileName }) :
                      _ctx.$t('delete_documents_confirmation', { fileName: _ctx.editState.fileName })), 1)
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("button", _hoisted_25, _toDisplayString(_ctx.$t('cancel')), 1),
                      _createElementVNode("a", {
                        class: "btn btn-success",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.deleteFile()))
                      }, _toDisplayString(_ctx.$t('ok')), 1)
                    ])
                  ]),
                  _: 1
                }, 512),
                _createVNode(_component_modal_section, {
                  modalId: "exclusion-filters-modal",
                  ref: "downloadModal",
                  class: "modal-sticky-header",
                  centered: true
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('download_documents')), 1)
                  ]),
                  body: _withCtx(() => [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('download_documents_confirmation')), 1)
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("button", _hoisted_29, _toDisplayString(_ctx.$t('cancel')), 1),
                      _createElementVNode("a", {
                        class: "btn btn-success",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.downloadDocumentsFromCtr()))
                      }, _toDisplayString(_ctx.$t('ok')), 1)
                    ])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            }, 8, ["saveButton", "save-button-text"])
          ], 64))
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}