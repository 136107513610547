<template>
  <sub-section v-if="isProtoOtherCardiacCirculatorySupportEnabled"
    sub-section-id="proto_other_cardiac_circulatory_support"
    title="OTHER CARDIAC CIRCULATORY SUPPORT"
    >
    <template v-slot:contents>
    <!-- Filter Bar + Create Button -->
    <table-toolbar
      :createButton="true"
      createText="Create Circulatory Support"
      @table-create-row="clearForm"
    >
      <template v-slot:button-bar>
        <div class="filter-section-action-row">
          <div class="filter-section-wrapper">
            <filter-component
              fieldID="contactFilter"
              :showFilter="false"
              :showArchived="false"
            />
          </div>
        </div>
      </template>
    </table-toolbar>

    <!-- List of Items, or History List -->
    <table-list
      ref="otherCardiacResultsTable"
      tabbableColumn="calculated_date"
      table-id="other-cardiac-result-table"
      :table-config="otherCardiacCirculatorySupportTableConfig"
      @table-row-click="editSelected"
      :highlightSelection="true"
      :rowStyleClass="rowStyleClass"
    />

    <!-- Form layout -->
  
    <form-layout
    form-id="vadLvade-form">
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="isNew" class="legend-title">
            NEW CIRCULATORY SUPPORT
          </h5>
          <h5 v-else class="legend-title has-guiding-text">
            SELECTED CIRCULATORY SUPPORT
          </h5>
        </legend>
      </template>
      <template v-slot:contents>
        <div class="row">
          <div class="standard-form-group">
            <date-input
              inputId="vad-implant_date"
              name='Implant Date'
              v-model="editState.circulatory_implant_date"   
            />
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="heart_device"
              name='Heart Device'
              :options="heartDeviceOptions"
              v-model="editState.heart_device">              
            </select-input>
          </div>
          <div class="standard-form-group">
            <text-input
              input-id="support-brand"
              name="Brand"
              v-model="editState.brand"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              inputId="vad-explant_date"
              name='Explant Date'
              v-model="editState.circulatory_explant_date"   
            />
          </div>
          <div class="standard-form-group">
            <select-input
              select-id="support_raeson"
              name='Reason for Explant'
              :options="reasonOptions"
              v-model="editState.reason">              
            </select-input>
          </div>

        </div>
      </template>
      <template v-slot:save>
        <save-toolbar
          :show="true"     
          ref="saveOtherCardiacResultsTable"
          label="Save Circulatory Support"
          :cancelButton="true"
          @save="performSave"
          @cancel="clearForm"
        />
      </template>
    </form-layout>    
    </template>
  </sub-section>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from '@/components/shared/TimeInput.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface OtherCardiacCirculatorySupportForm {
  id: string,
  circulatory_implant_date: Date|null,
  heart_device: string | null,
  brand: string | null,
  circulatory_explant_date: Date|null,
  reason: string
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    TimeInput,
    NumberInput,
    SelectInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoVadLvad extends mixins(DateUtilsMixin) {
  private isNew: any = true;
  private isArchiving = false;
  private isRestoring = false;
  private isLoadingActions = false;

  private editState: OtherCardiacCirculatorySupportForm = {
    id: '',
    circulatory_implant_date: null,
    heart_device: '',
    brand: "",
    circulatory_explant_date: null,
    reason: ""
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private otherCardiacCirculatorySupportResults: any = [];

  public perPage = 10;
  public currentPageTable = 1;

  private heartDeviceOptions = [{
    code: 0,
    value: 'Berlin Heart'
    }, {
      code: 1,
      value: 'Defibrillator / Pacemaker'
    },
    {
      code: 2,
      value: 'ECMO'
    },
    {
      code: 3,
      value: 'IABP'
    },
    {
      code: 4,
      value: 'Impella'
    }
  ];

  private reasonOptions = [{
      code: 0,
      value: 'Patient Died'
    },
    {
      code: 1,
      value: 'Patient Recovered'
    },
    {
      code: 2,
      value: 'Patient Request'
    },
    {
      code: 3,
      value: 'Patient Too Sick'
    },{
      code: 4,
      value: 'Patient Transplanted'
    },
    {
      code: 5,
      value: 'Device Exchange / Replacement'
    },
    {
      code: 6,
      value: 'Other'
    }
  ];



  get isProtoOtherCardiacCirculatorySupportEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.OtherCardiacCirculatorySupport);
  }


  get otherCardiacCirculatorySupportTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Implant Date', field: 'implantDateDisplayValue', width: '125px'},
      { label: 'Heart Device', field: 'vadTypeDisplay',  width: '125px' },
      { label: 'Brand', field: 'brand',  width: '125px' },
      { label: 'Explant Date', field: 'explantDateDisplayValue', width: '125px'},        
      { label: 'Reason for Explant', field: 'reasonDisplay',  width: '400px' },
    ],
    empty: 'Use the form below to add a new Cardiac Circulatory Support device.',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'circulatory_implant_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveOtherCardiacResultsTable as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.OtherCardiacCirculatorySupport, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: OtherCardiacCirculatorySupportForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  
  if (!response) return;


  this.otherCardiacCirculatorySupportResults = response;
}

translateCategoryOption(code: any, data: any) {
  const found: any = data.find((item: any) => {
    return item.code == code;
  });
  if (found) {
    return found.value;
  }

}


get tableRows() {
  const otherCardiacCirculatorySupportResults = this.otherCardiacCirculatorySupportResults || [];

  if (otherCardiacCirculatorySupportResults.length > 0) {

  return otherCardiacCirculatorySupportResults.map((result: any) => {
    return {
      id: result.id,
      circulatory_implant_date: result.circulatory_implant_date,
      implantDateDisplayValue: this.parseDisplayDateUi(result.circulatory_implant_date) || '-',
      heart_device: result.heart_device,
      vadTypeDisplay: this.translateCategoryOption(result.heart_device, this.heartDeviceOptions) || '-',
      brand: result.brand || '-',
      circulatory_explant_date: result.circulatory_explant_date,
      explantDateDisplayValue: this.parseDisplayDateUi(result.circulatory_explant_date) || '-',
      reason: result.reason,
      reasonDisplay: this.translateCategoryOption(result.reason, this.reasonOptions) || '-',
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let otherCardiacCirculatorySupportResults = this.otherCardiacCirculatorySupportResults || [];

  const payload: OtherCardiacCirculatorySupportForm = {
    id: Math.random().toString(16).slice(2),
    circulatory_implant_date: this.editState.circulatory_implant_date,
    heart_device: this.editState.heart_device,
    brand: this.editState.brand,
    circulatory_explant_date: this.editState.circulatory_explant_date,
    reason: this.editState.reason
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = otherCardiacCirculatorySupportResults.findIndex((result: any) => result.id == this.editState.id);
    
    if (foundIndex != -1) {
      otherCardiacCirculatorySupportResults[foundIndex] = payload;
    }
  } else {  
    otherCardiacCirculatorySupportResults.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, otherCardiacCirculatorySupportResults);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
  debugger;
    if (row) {  
      this.isNew = false;
      this.editState.id = row.row.id;
      this.editState.circulatory_implant_date = row.row.circulatory_implant_date;
      this.editState.heart_device = row.row.heart_device;
      this.editState.brand = row.row.brand;
      this.editState.circulatory_explant_date = row.row.circulatory_explant_date;
      this.editState.reason = row.row.reason;
    }
  }

  public clearForm(): any {
    this.isNew = true;
    this.editState.circulatory_implant_date = null;
    this.editState.heart_device = "";
    this.editState.brand = "";
    this.editState.circulatory_explant_date = null;
    this.editState.reason = "";
  }

  rowStyleClass(row: any) {
    return row.editable ? 'tr-highlight-green' : null;
  }

  // Reference to table
  get otherCardiacResultsTable(): any {
    return this.$refs.otherCardiacResultsTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.otherCardiacResultsTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.otherCardiacResultsTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveOtherCardiacResultsTable as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}

</script>
