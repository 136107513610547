import { APIPancreasWholeSpecificDetails, APITransplantDetailsInterface } from '@/APIModels/journey/types';
import { APIJourney } from '@/store/recipientJourney/types';
import { APIDonorAcceptabilityDetails, APIChecklist } from '@/APIModels/journey/types';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import { APINewResonse, APIRules, APIShowResponse, SaveResult, APIShowJourneyData } from '@/types';
import { UIChecklist } from '@/UIModels/journey/checklist';
import { UISuccess } from '@/UIModels/success';
import { UIPancreasWholeSpecificDetails } from './journey/pancreasWholeSpecificDetails';
import { UIReferralDetails } from './journey/referralDetails';
import { UITransplantDetails } from '@/UIModels/journey/transplantDetails';
import { UIDonorAcceptabilityDetails } from '@/UIModels/journey/donorAcceptabilityDetails';
import { UIPostTransplantFollowUp } from '@/UIModels/journey/postTransplantFollowUp';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIError } from '@/UIModels/error';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { UISaveable } from './saveable';

export class UIJourney extends UISaveable {
  private loaded: boolean;
  private checklistsLoaded: boolean;
  private referralLoaded: boolean;
  private pancreasWholeSpecificDetailsLoaded: boolean;
  private transplantDetailsLoaded: boolean;
  private donorAcceptabilityDetailsLoaded: boolean;

  public apiSource?: APIJourney;
  public clientId: string|null = null;
  public journeyId: string|null = null;

  public organ: string|null = null;
  public organ_code: number|null = null;
  public donor_acceptability: UIDonorAcceptabilityDetails|null = null;

  public checklists: UIChecklist[] = [];
  public referralDetails: UIReferralDetails|null = null;
  public pancreasWholeSpecificDetails: UIPancreasWholeSpecificDetails|null = null;
  public transplantDetails: UITransplantDetails|null = null;
  public donorAcceptabilityDetails: UIDonorAcceptabilityDetails|null = null;
  public applicableDonorTypes: { [key: string]: any }|null = null;
  public postTransplantFollowUps: UIPostTransplantFollowUp[] = [];

  get isNew(): boolean {
    return !this.journeyId;
  }

  public constructor(clientId: string, journeyId: string|null = null) {
    super();
    this.loaded = false;
    this.referralLoaded = false;
    this.checklistsLoaded = false;
    this.pancreasWholeSpecificDetailsLoaded = false;
    this.transplantDetailsLoaded = false;
    this.donorAcceptabilityDetailsLoaded = false;

    this.clientId = clientId;
    this.journeyId = journeyId;
  }

  // Load resource data and permitted actions
  public async load(opts?: { reload: boolean }): Promise<void> {
    if (this.journeyId) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  public async loadNew(opts?: { reload: boolean }): Promise<void> {
    const url = APIRoute(EP.recipients.create);
    try {
      const response: APINewResonse = await axios.get(url);
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  /**
   * Load everything for the Journey
   * @param opts options object, send reload: true to ensure new data is requested
   */
  public async loadShow(opts?: { reload: boolean }): Promise<void> {
    // NOTE: to force reloading everything, set loaded to false and go through standard load process
    if (opts?.reload) this.loaded = false;

    if (!this.loaded) {
      const clientId = this.clientId;
      if (!clientId) throw new Error('Cannot load journey without client_id');
      const journeyId = this.journeyId;
      if (!journeyId) throw new Error('Cannot load journey without journey_id');
  
      const url = APIRoute(EP.recipients.journeys.show, [[':recipient_id', (clientId as string)], [':journey_id', (journeyId as string)]]);
      try {
        const response: APIShowResponse<APIShowJourneyData> = await axios.get(url);
        const apiJourney: APIJourney = response?.data?.journey;
        this.permittedActions = response.data.permitted_actions;
        this.updateFromAPIResponse(apiJourney);
        this.buildReferralFromAPIJourney();
        this.buildApplicableJourneyTypesFromAPIJourney(apiJourney);
        await this.loadTransplantDetails();
        this.postTransplantFollowUps = await UIPostTransplantFollowUp.loadFor(this);
        // NOTE: here we assume if journey is reloading, we should also reload current recipient as well
        await useCurrentPageStore().currentRecipient?.load({ reload: true });
        this.loaded = true;
      } catch (error: unknown) {
        this.loaded = true;
        console.warn(error);
      }
    }
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'journey');
    vuexStore.commit('validations/set', { rules: { ['journey']: rules } });
  }

  public updateFromAPIResponse(apiJourney: APIJourney) {
    this.apiSource = apiJourney;
    this.journeyId = apiJourney._id || null;
    this.organ = apiJourney.organ || null;
    this.organ_code = apiJourney.organ_code || null;
    this.donor_acceptability = new UIDonorAcceptabilityDetails(apiJourney.donor_acceptability);
  }

  // load checklists
  public loadChecklists(params = {}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.checklistsLoaded) resolve();

      const clientId = this.clientId;
      const journeyId = this.journeyId;
      const url = APIRoute(EP.recipients.journeys.checklists.index, [[':recipient_id', (clientId as string)], [':journey_id', (journeyId as string)]]);
      axios.get(url, { params: params }).then((response: any) => {
        const apiChecklists: APIChecklist[] = response?.data?.checklists || [];
        this.checklists = apiChecklists.map((apiChecklist: APIChecklist): UIChecklist => {
          return new UIChecklist(apiChecklist);
        });
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  }

  // load checklists
  public loadChecklist(checklistId: string|null, params = {}): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const clientId = this.clientId;
      const journeyId = this.journeyId;
      const url = APIRoute(EP.recipients.journeys.checklists.show, [[':recipient_id', (clientId as string)], [':journey_id', (journeyId as string)], [':id', (checklistId as string)]]);
      axios.get(url, { params: params }).then((response: any) => {
        const apiChecklist: APIChecklist = response?.data?.checklist || null;
        const checklist = new UIChecklist(apiChecklist);
        const newResponse: any = {
          data: checklist
        };
        resolve(new UISuccess(newResponse).getSaveResult());
      }).catch((errorResponse: any) => {
        reject((new UIError('checklist', errorResponse)).errorResult);
      });
    });
  }

  private buildReferralFromAPIJourney() {
    this.referralDetails = new UIReferralDetails(this.apiSource?.stage_attributes?.referral);
  }

  private buildApplicableJourneyTypesFromAPIJourney(apiJourney: APIJourney) {
    const applicableTypes = apiJourney.donor_acceptability?.applicable_donor_types || null;
    if (!applicableTypes) return false;
    this.applicableDonorTypes = Object.entries(applicableTypes).map((item: any ) => {
      return {
        code: item[0],
        value: item[1]
      };
    });
  }

  // load pancreas
  public loadPancreasWholeSpecificDetails(params = {}): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      this.pancreasWholeSpecificDetailsLoaded = false;
      const clientId = this.clientId;
      const journeyId = this.journeyId;
      const url = APIRoute(EP.recipients.journeys.show, [[':recipient_id', (clientId as string)], [':journey_id', (journeyId as string)]]);
      axios.get(url, { params: params }).then((response: any) => {
        const apiPancreasWholeSpecificDetails: APIPancreasWholeSpecificDetails = response?.data?.journey || null;
        this.pancreasWholeSpecificDetails = UIPancreasWholeSpecificDetails.buildFromAPIPancreasWholeSpecificDetails(apiPancreasWholeSpecificDetails);
        this.pancreasWholeSpecificDetailsLoaded = true;
        const permittedActions = response?.data.permitted_actions || [];
        const newResponse: any = {
          data: permittedActions
        };
        resolve(new UISuccess(newResponse).getSaveResult());
      }).catch((errorResponse: any) => {
        reject((new UIError('organ_specific_details', errorResponse)).errorResult);
      });  
    });
  }

  // load donor acceptability details
  public loadDonorAcceptabilityDetails(params = {}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.pancreasWholeSpecificDetailsLoaded = false;
      const clientId = this.clientId;
      const journeyId = this.journeyId;
      const url = APIRoute(EP.recipients.journeys.show, [[':recipient_id', (clientId as string)], [':journey_id', (journeyId as string)]]);
      axios.get(url, { params: params }).then((response: any) => {
        const apiDonorAcceptabilityDetails: APIDonorAcceptabilityDetails = response?.data?.journey.donor_acceptability || null;
        this.donorAcceptabilityDetails = new UIDonorAcceptabilityDetails(apiDonorAcceptabilityDetails);
        const permittedActions = response?.data.permitted_actions || [];
        this.donorAcceptabilityDetails.setPermittedActions(permittedActions);
        this.donorAcceptabilityDetailsLoaded = true;
        resolve();
      }).catch((errorResponse: any) => {
        reject((new UIError('donor_acceptability_details', errorResponse)).errorResult);
      });  
    });
  }

  // load transplant details
  public loadTransplantDetails(params = {}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.transplantDetailsLoaded = false;
      const clientId = this.clientId;
      const journeyId = this.journeyId;
      const url = APIRoute(EP.recipients.journeys.transplant.show, [[':recipientId', (clientId as string)], [':journeyId', (journeyId as string)]]);
      axios.get(url, { params: params }).then((response: any) => {
        const apiTransplantDetails: APITransplantDetailsInterface = response?.data?.transplant || null;
        this.transplantDetails = new UITransplantDetails(apiTransplantDetails);
        const permittedActions = response?.data.permitted_actions || [];
        this.transplantDetails.setPermittedActions(permittedActions);
        this.transplantDetailsLoaded = true;
        resolve();
      }).catch((errorResponse: any) => {
        reject((new UIError('transplant_details', errorResponse)).errorResult);
      });  
    });
  }
}
