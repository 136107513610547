import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav-wrapper" }
const _hoisted_4 = { class: "page-content" }
const _hoisted_5 = { class: "content-wrap" }
const _hoisted_6 = { class: "container-fluid" }
const _hoisted_7 = { class: "nav-wrapper" }
const _hoisted_8 = { class: "page-content donor-profile" }
const _hoisted_9 = { class: "form-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_side_nav = _resolveComponent("loading-side-nav")!
  const _component_loading_donor_page = _resolveComponent("loading-donor-page")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_donor_sticky_summary = _resolveComponent("donor-sticky-summary")!
  const _component_side_nav_deceased_donor = _resolveComponent("side-nav-deceased-donor")!
  const _component_donor_summary = _resolveComponent("donor-summary")!
  const _component_donor_data_history = _resolveComponent("donor-data-history")!
  const _component_referral_information = _resolveComponent("referral-information")!
  const _component_donation_information = _resolveComponent("donation-information")!
  const _component_clinical_information = _resolveComponent("clinical-information")!
  const _component_donor_documents = _resolveComponent("donor-documents")!
  const _component_virology_results = _resolveComponent("virology-results")!
  const _component_organ_packaging_forms = _resolveComponent("organ-packaging-forms")!
  const _component_recovery_details = _resolveComponent("recovery-details")!
  const _component_monitor_recovery_outcomes = _resolveComponent("monitor-recovery-outcomes")!
  const _component_exceptional_distribution_disposition = _resolveComponent("exceptional-distribution-disposition")!
  const _component_hla_typing = _resolveComponent("hla-typing")!
  const _component_serum_crossmatch_section = _resolveComponent("serum-crossmatch-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_national_organ_waitlist_modal = _resolveComponent("national-organ-waitlist-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => _cache[31] || (_cache[31] = [
              _createElementVNode("span", {
                class: "skeleton-box",
                style: {"width":"200px"}
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_loading_side_nav),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_loading_donor_page)
                ])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: _ctx.getDonorsUrl }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('donors')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" / " + _toDisplayString(_ctx.deceasedDonorDisplayName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_donor_sticky_summary, {
            ref: "donorStickySummary",
            onLoaded: _cache[0] || (_cache[0] = (ref) => _ctx.loaded(ref) ),
            openNowModal: () => _ctx.openNowModal()
          }, null, 8, ["openNowModal"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_side_nav_deceased_donor),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_validation_observer, {
                    ref: "validations",
                    autocomplete: "off",
                    tag: "form",
                    onSubmit: _cache[30] || (_cache[30] = _withModifiers(() => {}, ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_donor_summary, {
                        ref: "donorSummary",
                        onLoaded: _cache[1] || (_cache[1] = (ref) => _ctx.loaded(ref)),
                        openNowModal: () => _ctx.openNowModal()
                      }, null, 8, ["openNowModal"]),
                      _withDirectives(_createVNode(_component_donor_data_history, { ref: "donorDataHistory" }, null, 512), [
                        [_vShow, _ctx.showDonorDataHistory]
                      ]),
                      _createVNode(_component_referral_information, {
                        ref: "referralInformation",
                        onLoaded: _cache[2] || (_cache[2] = (ref) => _ctx.loaded(ref)),
                        onReload: _ctx.reload,
                        onSave: _cache[3] || (_cache[3] = (details) => _ctx.handleSectionSave(details)),
                        onHandleErrors: _cache[4] || (_cache[4] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('donor_personal')
                      }, null, 8, ["onReload", "canSave"]),
                      _createVNode(_component_donation_information, {
                        ref: "donationInformation",
                        onLoaded: _cache[5] || (_cache[5] = (ref) => _ctx.loaded(ref)),
                        onReload: _ctx.reload,
                        onSave: _cache[6] || (_cache[6] = (details) => _ctx.handleSectionSave(details)),
                        onHandleErrors: _cache[7] || (_cache[7] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('donor_medical')
                      }, null, 8, ["onReload", "canSave"]),
                      _createVNode(_component_clinical_information, {
                        ref: "clinicalInformation",
                        onLoaded: _cache[8] || (_cache[8] = (ref) => _ctx.loaded(ref)),
                        onReload: _ctx.reload,
                        onSave: _cache[9] || (_cache[9] = (details) => _ctx.handleSectionSave(details)),
                        onHandleErrors: _cache[10] || (_cache[10] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.isGroupWriteable('donor_medical')
                      }, null, 8, ["onReload", "canSave"]),
                      (_ctx.groupExists('donor_attachments'))
                        ? (_openBlock(), _createBlock(_component_donor_documents, {
                            key: 0,
                            ref: "donorDocuments",
                            onLoaded: _cache[11] || (_cache[11] = (ref) => _ctx.loaded(ref)),
                            onSave: _cache[12] || (_cache[12] = (details) => _ctx.handleSectionSave(details)),
                            onHandleErrors: _cache[13] || (_cache[13] = (errors) => _ctx.handleErrors(errors)),
                            onClear: _ctx.resetValidationErrors
                          }, null, 8, ["onClear"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_virology_results, {
                        ref: "virology",
                        newRecord: false,
                        virologyType: _ctx.virologyType,
                        onLoaded: _cache[14] || (_cache[14] = (ref) => _ctx.loaded(ref)),
                        onReload: _ctx.reload,
                        onHandleErrors: _cache[15] || (_cache[15] = (errors) => _ctx.handleErrors(errors)),
                        onClear: _ctx.resetValidationErrors,
                        canSave: _ctx.isGroupWriteable('donor_medical')
                      }, null, 8, ["virologyType", "onReload", "onClear", "canSave"]),
                      (_ctx.donorOrganPackagingForms.length > 0 && (_ctx.groupExists('organ_packaging') || _ctx.groupExists('organ_packaging_odsif')))
                        ? (_openBlock(), _createBlock(_component_organ_packaging_forms, {
                            key: 1,
                            ref: "organPackagingForms",
                            onLoaded: _cache[16] || (_cache[16] = (ref) => _ctx.loaded(ref)),
                            onSave: _cache[17] || (_cache[17] = (details) => _ctx.handleSectionSave(details)),
                            onHandleErrors: _cache[18] || (_cache[18] = (errors) => _ctx.handleErrors(errors)),
                            canSave: _ctx.groupExists('organ_packaging') || _ctx.groupExists('organ_packaging_odsif')
                          }, null, 8, ["canSave"]))
                        : _createCommentVNode("", true),
                      (_ctx.groupExists('donor_recovery') || _ctx.groupExists('donor_recovery_date'))
                        ? (_openBlock(), _createBlock(_component_recovery_details, {
                            key: 2,
                            ref: "organRecoveryDetails",
                            onLoaded: _cache[19] || (_cache[19] = (ref) => _ctx.loaded(ref)),
                            onSave: _cache[20] || (_cache[20] = (details) => _ctx.handleSectionSave(details)),
                            onHandleErrors: _cache[21] || (_cache[21] = (errors) => _ctx.handleErrors(errors)),
                            onClear: _ctx.resetValidationErrors,
                            canSave: _ctx.isGroupWriteable('donor_recovery') || _ctx.isGroupWriteable('donor_recovery_date')
                          }, null, 8, ["onClear", "canSave"]))
                        : _createCommentVNode("", true),
                      (_ctx.showMonitorRecoveryOutcomes)
                        ? (_openBlock(), _createBlock(_component_monitor_recovery_outcomes, {
                            key: 3,
                            ref: "monitorRecoveryOutcomes",
                            onLoaded: _cache[22] || (_cache[22] = (ref) => _ctx.loaded(ref))
                          }, null, 512))
                        : _createCommentVNode("", true),
                      (_ctx.showExdDisposition)
                        ? (_openBlock(), _createBlock(_component_exceptional_distribution_disposition, {
                            key: 4,
                            ref: "exdConfirmationForm",
                            onLoaded: _cache[23] || (_cache[23] = (ref) => _ctx.loaded(ref))
                          }, null, 512))
                        : _createCommentVNode("", true),
                      _createVNode(_component_hla_typing, {
                        ref: "hlaTypingDonor",
                        "enable-donor": true,
                        onLoaded: _cache[24] || (_cache[24] = (ref) => _ctx.loaded(ref)),
                        onReload: _ctx.reload,
                        onSave: _cache[25] || (_cache[25] = (details) => _ctx.handleSectionSave(details)),
                        onHandleErrors: _cache[26] || (_cache[26] = (errors) => _ctx.handleErrors(errors)),
                        canSave: _ctx.checkAllowed('/donors/:donor_id/hla_typing', 'POST')
                      }, null, 8, ["onReload", "canSave"]),
                      _createVNode(_component_serum_crossmatch_section, {
                        ref: "serumCrossmatch",
                        "enable-donor": true,
                        onLoaded: _cache[27] || (_cache[27] = (ref) => _ctx.loaded(ref)),
                        onSave: _cache[28] || (_cache[28] = (details) => _ctx.handleSectionSave(details)),
                        onHandleErrors: _cache[29] || (_cache[29] = (errors) => _ctx.handleErrors(errors)),
                        onClear: _ctx.resetValidationErrors
                      }, null, 8, ["onClear"]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_save_toolbar, {
                          ref: "saveDonor",
                          label: _ctx.$t('save_donor'),
                          show: _ctx.canSaveGetter(false),
                          onSave: _ctx.performSave
                        }, null, 8, ["label", "show", "onSave"])
                      ])
                    ]),
                    _: 1
                  }, 512)
                ])
              ])
            ]),
            (_ctx.canAccessNowList)
              ? (_openBlock(), _createBlock(_component_national_organ_waitlist_modal, {
                  key: 0,
                  ref: "nationalOrganWaitlistModal"
                }, null, 512))
              : _createCommentVNode("", true)
          ])
        ], 64))
  ]))
}