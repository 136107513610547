import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_list = _resolveComponent("table-list")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "patientImportHistoryModal",
    ref: "patientImportHistoryModal",
    class: "patient-import-history-modal",
    centered: true,
    closeButton: true,
    size: "lg",
    wide: true
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('data_import_history')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_table_list, {
        ref: "importHistoryTable",
        "table-id": "import-history-table",
        "table-config": _ctx.tableConfig,
        firstColumnTabbable: true,
        "total-records": _ctx.patientImportHistory.recordCount || 0,
        onOnPageNumber: _cache[0] || (_cache[0] = ($event: any) => {_ctx.onPageNumber($event); _ctx.loadData()}),
        onOnPageSize: _cache[1] || (_cache[1] = ($event: any) => {_ctx.onPageSize($event); _ctx.loadData()}),
        mode: "remote"
      }, null, 8, ["table-config", "total-records"])
    ]),
    _: 1
  }, 512))
}