import Vue from 'vue';
import { MutationTree } from 'vuex';
import { idComparator } from '@/utils';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { RecipientsState, Recipient, RecipientOopTransplant, SignificantEventDecision, RecipientAttachment } from '@/store/recipients/types';

export const mutations: MutationTree<RecipientsState> = {
  set(state, recipient) {
    // Clone recipient data and sanitize the clone
    const sanitizedRecipient: Recipient = Object.assign({}, recipient);
   
    // Store sanitized clone
    state.selectedRecipient = sanitizedRecipient;
  },
  clearRecipient(state) {
    state.selectedRecipient = undefined;
  },
  setValidationRules(state, validations) {
    if (state.selectedRecipient !== undefined && state.selectedRecipient !== null) {
      state.selectedRecipient.validations = validations;
    }
  },
  setList(state, recipients) {
    state.recipientsList = recipients;
  },
  setMeasurements(state, measurements) {
    state.selectedRecipient!.measurements = measurements;
  },
  setRecipientAttachments(state, attachments) {
    state.attachments = attachments;
  },
  setDialysisProcedures(state, dialysisProcedures) {
    state.dialysis_procedures = dialysisProcedures;
  },
  setOutOfProvinceJourneys(state, outOfProvinceJourneys) {
    state.outOfProvinceJourneys = outOfProvinceJourneys;
  },
  clearOutOfProvinceJourneys(state) {
    state.outOfProvinceJourneys = undefined;
  },
  selectPreviousTransplant(state: RecipientsState, transplant: RecipientOopTransplant): void {
    state.selectedPreviousTransplant = transplant;
  },
  clearPreviousTransplant(state: RecipientsState): void {
    state.selectedPreviousTransplant = undefined;
  },
  clearDecisionEvents(state: RecipientsState): void {
    state.decisionEvents = undefined;
  },
  setDecisionEvents(state: RecipientsState, { recipientId, events }: { recipientId: string; events: SignificantEventDecision[] }): void {
    state.decisionEvents = events;
  },
  addUploadedFileToList(state, { originalId, attachment }) {
    const attachments = state.selectedRecipient?.attachments || [];
    attachments.push(attachment);
    if(state.selectedRecipient)
    state.selectedRecipient.attachments = attachments;
  },
  setRecipientDeath(state, death) {
    state.selectedDeath = death;
  },
  clearRecipientDeath(state) {
    state.selectedDeath = undefined;
  },
  removeUploadedFileFromList(state, { id }) {
    const index = state.selectedRecipient!.attachments!.findIndex( (item:any) => item._id === id );
    if (index > -1) {
      state.selectedRecipient!.attachments!.splice(index, 1);
    }
  },
  setCtrLogs(state, ctrLogs) {
    state.ctrLogs = ctrLogs;
  },
  clearCtrLogs(state) {
    state.ctrLogs = [];
  },
  setRecipientDiagnosticsHla(state, diagnosticsHla) {
    state.selectedDiagnosticsHla = diagnosticsHla;
  },
  clearRecipientDiagnosticsHla(state) {
    state.selectedDiagnosticsHla = undefined;
  },
};
