import { APIPermittedActions } from '@/types';

export class UISaveable {
  public id: string|null = null;
  public permittedActions: string[] = [];

  get isNew(): boolean {
    return !this.id;
  }

  public get canSave(): boolean {
    // We have a list of permitted actions, so now we can check for "create" or "update" keywords
    return this.permittedActions.includes(APIPermittedActions.Create) || this.permittedActions.includes(APIPermittedActions.Update);
  }  
}
