<template>
  <div class="sub-section">
    <fieldset :id="subSectionId" :disabled="disabled">
      <legend v-if="subtitle && subtitle.length > 0" :aria-describedby="`${subSectionId}-note`">
        <collapsible-heading
          v-if="subSubSection"
          tag="h5"
          class="legend-title d-inline"
          :heading="title"
          :collapsible="collapsible"
          v-model="collapsed"
        />
        <collapsible-heading
          v-else
          class="legend-title d-inline"
          :heading="title"
          :collapsible="collapsible"
          v-model="collapsed"
        />
        <small :id="`${subSectionId}-note`" class="form-text text-muted d-inline">
          {{subtitle}}
        </small>
      </legend>
      <legend v-else-if="title.length > 0">
        <collapsible-heading
          v-if="subSubSection"
          tag="h5"
          :heading="title"
          :collapsible="collapsible"
          v-model="collapsed"
        />
        <collapsible-heading
          v-else
          :heading="title"
          :collapsible="collapsible"
          v-model="collapsed"
        />
      </legend>
      <template v-if="hasGuidingText">
        <p class="mt-2">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          <slot name="guiding_text"/>
        </p>
      </template>
    </fieldset>
    <fieldset v-if="isLoading || isParentLoading" v-show="!collapsed">
      <div v-if="!hideDivider" class="divider" />
    </fieldset>
    <fieldset v-else :disabled="disabled" v-show="!collapsed">
      <slot name="contents" />
      <save-toolbar
        :class="saveToolbarClass"
        :show="saveButton"
        :buttonClass="saveButtonClass"
        :disabled="disabled"
        :label="saveButtonText"
        :ref="saveToolbarRef()"
        @save="performSave"
        :cancelButton="cancelButton"
        @cancel="performCancel()"
      />
    </fieldset>
    <div v-if="!hideDivider" :class="`divider ${ enforceDivider ? 'enforce-divider' : ''}`" />
  </div>
</template>

<script lang="ts">
import { TableConfig } from '@/types';
import TextInput from "@/components/shared/TextInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import CollapsibleHeading from '@/components/shared/CollapsibleHeading.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { SaveProvider, SaveResult } from '@/types';
import { i18nMessages } from '@/i18n';

export interface Options {
  [key: string]: string | undefined;
}

@Component({
  components: {
    SaveToolbar,
    TextInput,
    SelectInput,
    CollapsibleHeading,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/single.json'),
    require('@/components/shared/_locales/SubSection.json'),
  ]),
  emits: [
    'loaded',
    'table-row-click',
    'on-column-filter',
    'on-page-change',
    'on-per-page-change',
    'on-sort-change',
    'table-create-row',
    'save',
    'cancel',
  ],
})
export default class SubSection extends Vue implements SaveProvider {
  @Prop({ required: true }) subSectionId!: string;
  @Prop({ required: false }) subtitle!: string;
  @Prop({ default: null }) tableConfig!: TableConfig|null;
  @Prop({ default: '' }) title!: string;
  @Prop({ default: false }) saveButton!: boolean; // show save button
  @Prop({ default: 'Save' }) saveButtonText!: string; // text on save button
  @Prop({ default: false }) subSubSection!: boolean;
  @Prop({ required: false }) confirmation?: string;
  @Prop({ required: false }) tabbableValue?: string;
  @Prop({ required: false }) mode?: string;
  @Prop({ default: false }) highlightSelection!: boolean; // when true, highlight selected rows
  @Prop({ default: 'row' }) tableWrapperClass?: string;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ required: false }) preconfirmation?: string|null; // optional warning text to show before 'confirmation' warning
  @Prop({ required: false }) rowStyleClass?: string|((row: any) => string)
  @Prop({ default: '' }) saveToolbarClass?: string;
  @Prop({ default: false }) collapsible?: boolean; // button to collapse or reveal subsection (table and contents)
  @Prop({ default: false }) defaultCollapsed?: boolean; // when true, subsection will initially be rendered as already collapsed
  @Prop({ default: false }) isParentLoading?: boolean; // optional flag to override loading state
  @Prop({ default: false }) cancelButton?: boolean;
  @Prop({ default: 'btn btn-wide btn-success sec-submit' }) saveButtonClass?: string;
  @Prop({ default: false }) hideDivider!: boolean; // When true, sub-section divider is hidden
  @Prop({ default: false }) enforceDivider!: boolean; // When true, sub-section divider is enforced, use when there is multiple subsections with save toolbars within a card

  // Lookup properties
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];

  // Public attributes;
  public isLoading = false;
  public collapsed = false;

  //sorting and search
  public sortParams: Options = {};
  public searchParams: Options = {};

  // pagination
  public currentPage = 1;
  public currentPerPage = 25;

  // Private attributes
  private lookupsLoaded: string[] = [];
  private laboratoriesLoaded: string[] = [];
  private hospitalsLoaded: string[] = [];

  private selectedIds: any[] = [];
  selectionSync: any = null;

  get numberOfItemsToLoad(): number {
    const lookupsCheck = this.lookupsToLoad || [];
    const labsCheck = this.laboratoriesToLoad || [];
    const hospitalCheck = this.hospitalsToLoad || [];
    return lookupsCheck.length + labsCheck.length + hospitalCheck.length;
  }

get numberOfItemsLoaded(): number {
  return this.lookupsLoaded.length + this.laboratoriesLoaded.length + this.hospitalsLoaded.length;
}

  get hasGuidingText() {
    return !!this.$slots.guiding_text;
  }

  get getNameOfExpandedField(): string|null {
    if (!this.tableConfig) return null;
    const found = this.tableConfig.columns.find((item: any) => {
      return item.expanded === true;
    });
    return found ? found.field : null;
  }

  public mounted(): void {
    // If sub-section is collapsible, and should default to collapsed, set initial state 
    if (this.collapsible && this.defaultCollapsed) this.collapsed = true;

    // Load any lookups if we have them
    if (this.numberOfItemsToLoad > 0) {
      this.getLookups(this.lookupsToLoad, this.laboratoriesToLoad, this.hospitalsToLoad);
    } else {
      this.$emit('loaded');
    }
  }

  labelMatchesTabbableValue(label: string, tabbable: string|undefined): boolean {
    if (!tabbable) { return false; }
    if (!label) { return false; }
    return label.replace(/\s/g, '') === tabbable.replace(/\s/g, '');
  }

  getLookups(lookupsToLoad: string[], laboratoriesToLoad: string[], hospitalsToLoad: string[]) {
    this.isLoading = true;

    lookupsToLoad.forEach(item => {
      // load them
      this.$store.dispatch('lookups/queueLookup', {lookup: item})
      .then(() => {
        this.lookupsLoaded.push(item);
        this.checkLoaded();
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });

    laboratoriesToLoad.forEach(item => {
      // load them
      this.$store.dispatch('laboratories/load', item)
      .then(() => {
        this.laboratoriesLoaded.push(item);
        this.checkLoaded();
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });

    hospitalsToLoad.forEach(item => {
      this.$store.dispatch('hospitals/load')
      .then(() => {
        this.hospitalsLoaded.push(item);
        this.checkLoaded();
      })
      .catch(() => {
        this.isLoading = false;
        this.$emit('loaded');
      });
    });
  }

  private checkLoaded(): void {
    if (this.numberOfItemsLoaded === this.numberOfItemsToLoad) {
      this.isLoading = false;
      this.$emit('loaded');
    }
  }

  get styleClass() {
    if (this.isParentLoading) {
      return 'vgt-table table table-bordered table-hover bordered table-loading';
    }
    // By default use constant table style
    if (!this.highlightSelection) {
      return 'vgt-table table table-bordered table-hover bordered';
    }
    // For selection highlighting, use detailed table design
    return 'vgt-table table table-bordered table-hover bordered table-with-detail';
  }

  get rowStyle(): any {
    // Permit customization of row style class using props
    if (this.rowStyleClass) return this.rowStyleClass;

    // By default use constant row style class
    if (!this.highlightSelection) {
      return 'tr-link';
    }

    // For selection highlighting, use function driven by _id field (styling purposes only)
    return (row: any): string => {
      const id: any = row._id || this.$t('unknown_caps').toString();
      const selected = this.selectedIds.includes(id);
      const result = selected ? 'tr-active' : 'tr-link' ;
      return result;
    };
  }

  // Helper methods
  get paginationOptions(): any {
    if (!this.tableConfig || !this.tableConfig.pagination) {
      // No pagination
      return {};
    } else if (!this.tableConfig.paginationOptions) {
      // Default options if pagination: true
      return {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: true,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('table.results').toString(),
        ofLabel: this.$t('table.of').toString(),
      };
    } else {
      return this.tableConfig.paginationOptions;
    }
  }

  /**
   * @returns {object} sorting properties for table based on sub-section properties
   */
  get sortOptions(): any {
    if (!this.tableConfig || !this.tableConfig.sortOptions){
      // No sorting
      return { enabled: false };
    } else {
      // Custom options specified in table config
      return this.tableConfig.sortOptions;
    }
  }

  /**
   * @returns {object} searching properties for table based on sub-section properties
   */
  get searchOptions(): any {
    if (!this.tableConfig || !this.tableConfig.searchOptions){
      // No searching
      return { enabled: false };
    } else {
      // Custom options specified in table config
      return this.tableConfig.searchOptions;
    }
  }

   /**
   * @returns {object} paging, filtering and sorting options whithin each event.
   */
  get tableParams() {
    return {
      currentPage: this.currentPage,
      currentPerPage: this.currentPerPage,
      searchParams: this.searchParams,
      sortParams: this.sortParams
    };
  }

  // Event handlers
  public onRowClick(event: any) {
    const row = event.row;
    // Update internal sense of which rows are selected (only used for styling purposes)
    let id = null;
    if (row.id) { id = row.id; }
    if (row._id) { id = row._id; }
    if (!id) { id = this.$t('unknown_caps').toString(); }

    // send id or UNKNOWN
    this.selectedIds = [id];
    // Emit event to parent, which will handle functional logic separate from styling
    this.$emit('table-row-click', { row: row });
  
  }

  private searchDebounce: any;

  /**
   * Triggers the filtering search query per column
   * @param {any} event, the column to filter
   * @param {string} field, the text to query
  */
  public updateFilters(event: any, field: string){
    this.currentPage = 1;
    this.searchParams[field] = event;

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    this.searchDebounce = setTimeout(() => {
      _vm.$emit('on-column-filter', this.tableParams);
    }, 1000);

    this.$forceUpdate();
  }

  /**
   * Triggers the change of page
   * @param {any} event the event for paging
  */
  public onPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.currentPerPage = event.currentPerPage;
    this.$emit('on-page-change', this.tableParams);
  }

  /**
   * Triggers the per page change
   * @param {any} event the event for per page change
  */
  public onPerPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.currentPerPage = event.currentPerPage;
    this.$emit('on-per-page-change', this.tableParams);
  }

  /**
   * Triggers the sort change
   * @param {any} event the event for sorting
  */
  public onSortChange(event: any) {
    if (event.length === 0) {
      return;
    }

    let column = this.tableConfig?.columns.find((c) => {
      return c.field === event[0].field;
    });

    if (column.sortable){
      this.currentPage = 1;
      this.sortParams = { sort_by: event[0].field, sort_type: event[0].type };
      this.$emit('on-sort-change', this.tableParams);
    }
  }

  public onCreateRow(event: any) {
    this.selectionSync = null;
    this.selectedIds = [];
    this.$emit('table-create-row');
  }

  // Generate a unique reference for the save toolbar using the card section ID
  saveToolbarRef(): string {
    return `save-${this.subSectionId}`;
  }

  // Generate a unique reference for the table component
  get tableRef(): string {
    return `${this.subSectionId}-table`;
  }

  // Use dynamic reference to refer to the save toolbar associated with this specific card section
  private saveToolbar(): SaveToolbar {
    return this.$refs[this.saveToolbarRef()] as SaveToolbar;
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Show pre-confirmation prompt if necessary
    if (!!this.preconfirmation) {
      const preconfirmed = confirm(this.preconfirmation);
      if (!preconfirmed) {
        // Cancel save if not pre-confirmed
        return;
      }
    }
    // Show confirmation prompt if necessary
    if (!!this.confirmation) {
      const confirmed = confirm(this.confirmation);
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
    }
    // Show saving notification
    this.saveToolbar().startSaving();
    // Report save event to parent
    this.$emit('save');
  }

  // Handle cancel triggered by local cancel button
  public performCancel(): void {
    const confirmed = confirm(this.$t('confirm_cancel').toString());
    if (!confirmed) return;

    // Report cancel event to parent
    this.$emit('cancel');
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    // Show appropriate saving notification if the saveToolbar is still visible
    if (this.saveToolbar()) this.saveToolbar().stopSaving(result);
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Reset save toolbar if is visible
    if (this.saveToolbar()) {
      this.saveToolbar().reset();
    }
  }
}
</script>
