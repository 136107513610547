import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "filteritem" }
const _hoisted_2 = { class: "filteritem" }
const _hoisted_3 = { class: "filteritem" }
const _hoisted_4 = { class: "filteritem" }
const _hoisted_5 = { class: "filteritem" }
const _hoisted_6 = { class: "filteritem" }
const _hoisted_7 = { class: "filteritem" }
const _hoisted_8 = {
  key: 2,
  class: "filteritem"
}
const _hoisted_9 = {
  key: 3,
  class: "filteritem last"
}
const _hoisted_10 = {
  key: 0,
  class: "form-check form-check-inline"
}
const _hoisted_11 = ["id", "aria-labelledby"]
const _hoisted_12 = ["for"]
const _hoisted_13 = {
  key: 1,
  class: "form-check form-check-inline"
}
const _hoisted_14 = ["id", "aria-labelledby"]
const _hoisted_15 = ["for"]
const _hoisted_16 = { class: "btn-group" }
const _hoisted_17 = {
  key: 1,
  class: "filter-spacer filterShowLarge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_proto_multi_select_input = _resolveComponent("proto-multi-select-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.serologyFilter)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_date_input, {
              inputId: "startDate",
              name: "Start Date",
              modelValue: _ctx.startDate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$parent.$emit('moveScroll', _ctx.startDate)))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_date_input, {
              inputId: "endDate",
              name: "End Date (Optional)",
              modelValue: _ctx.endDate,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endDate) = $event))
            }, null, 8, ["modelValue"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.notesFilter)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_date_input, {
              "input-id": "added_date_from",
              name: "Added Date from",
              modelValue: _ctx.addedDateFrom,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addedDateFrom) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_date_input, {
              "input-id": "added_date_to",
              name: "Added Date",
              modelValue: _ctx.addedDateTo,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addedDateTo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_select_input, {
              selectId: "users",
              name: "Author",
              modelValue: _ctx.author,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.author) = $event)),
              options: _ctx.notesAuthorOptions
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_proto_multi_select_input, {
              "select-id": "tags",
              "aria-label": "Options",
              name: "Notes Tags",
              placeholder: "Select Tags",
              showToolTip: false,
              toolTipText: `Select tags to help find this note or capture important elements.`,
              modelValue: _ctx.filterTag,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filterTag) = $event)),
              options: _ctx.notesTagOptions,
              displayType: "comma",
              showToggleAll: "false"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_text_input, {
              inputId: "search",
              name: "Keyword",
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.search) = $event)),
              placeHolder: "search"
            }, null, 8, ["modelValue"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.checklistFilter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("span", null, [
            _createVNode(_component_select_input, {
              selectId: "checklist_status",
              name: "Status",
              modelValue: _ctx.filterStatus,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filterStatus) = $event)),
              options: _ctx.checklistStatusOptions
            }, null, 8, ["modelValue", "options"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.serologyFilter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_ctx.archivedFilter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  id: `${_ctx.fieldID}-archive`,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.includeArchived) = $event)),
                  "aria-labelledby": `${_ctx.fieldID}-archive`,
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 8, _hoisted_11), [
                  [_vModelCheckbox, _ctx.includeArchived]
                ]),
                _createElementVNode("label", {
                  for: `${_ctx.fieldID}-archive`,
                  class: "form-check-label"
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("span", null, " Include Archived ", -1)
                ]), 8, _hoisted_12)
              ]))
            : _createCommentVNode("", true),
          (_ctx.completedFilter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  id: `${_ctx.fieldID}-completed`,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.includeCompleted) = $event)),
                  "aria-labelledby": `${_ctx.fieldID}-archive`,
                  type: "checkbox",
                  class: "form-check-input"
                }, null, 8, _hoisted_14), [
                  [_vModelCheckbox, _ctx.includeCompleted]
                ]),
                _createElementVNode("label", {
                  for: `${_ctx.fieldID}-completed`,
                  class: "form-check-label"
                }, _cache[14] || (_cache[14] = [
                  _createElementVNode("span", null, " Include Completed ", -1)
                ]), 8, _hoisted_15)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary-outline btn-sm",
        onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.saveFilters()), ["prevent"]))
      }, _toDisplayString("Filter")),
      (_ctx.appliedFilters.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-primary-outline btn-sm ml-2",
            onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.clearFilters()), ["prevent"]))
          }, _toDisplayString("Clear")))
        : _createCommentVNode("", true),
      (!_ctx.hideSpacer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17))
        : _createCommentVNode("", true)
    ])
  ]))
}