export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "save_demographics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Demographics"])},
        "demographics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demographics"])},
        "registration_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTRATION INFORMATION"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "living_donor_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Registration Date"])},
        "living_donor_registration_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Registration Time (EST)"])},
        "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "middle_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
        "ethnicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity"])},
        "ethnicity_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity - Other"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "gender_is_different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender is different than Biological Sex"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "gender_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender - Other"])},
        "insurance_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Type"])},
        "other_insurance_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Insurance Details"])},
        "other_province_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Province Insurance"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])}
      },
      "fr": {
        "save_demographics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les données démographiques"])},
        "demographics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données démographiques"])},
        "registration_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTRATION INFORMATION"])},
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Afflo"])},
        "living_donor_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'inscription du donneur vivant"])},
        "living_donor_registration_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de l'inscription du donneur vivant"])},
        "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données personelles"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "middle_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second prénom "])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
        "ethnicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Race/Originale raciale"])},
        "ethnicity_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Race/Originale raciale - Autre"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "gender_is_different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre si dfférent du sexe biologique "])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
        "gender_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre - Autre"])},
        "insurance_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'assuance"])},
        "other_insurance_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres détails de l'assurance"])},
        "other_province_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance d'une autre province"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'assurance"])}
      },
      "en_tgln": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])}
      },
      "fr_tgln": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])}
      },
      "en_tq": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
