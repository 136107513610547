import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-sm-12 col-md-10 col-lg-7 col-xl-6" }
const _hoisted_4 = {
  id: "warning-banner",
  class: "card shadow-none"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "login-title" }
const _hoisted_7 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('error_title')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("missing_configuration")), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}