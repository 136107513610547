<template>
  <card-section
    section-id="hla-serum"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded"
    :show-actions="true"
  >
    <template v-slot:header>
      {{$t('serum_summary')}} &amp; {{$t('review')}}
    </template>
    <template v-slot:actions>
      <button
        type="button"
        class="btn btn-sm btn-default"
        @click="openExportDataModal"
        :title="$t('serum_summary_export_data_hover')"
      >
        <font-awesome-icon :icon="['far', 'table']" class="mr-2" /> {{ $t('serum_summary_export_data_button') }}
      </button>
    </template>
    <template v-slot:body v-if="editState">
      <validation-observer>
        <!-- Cumulative Serum Results -->
        <sub-section
          :title="$t('cumulative_serum_results')"
          sub-section-id="serumsummary-cumulative"
          class="cumulative-sera">
          <template v-slot:contents>
            <fieldset>
              <div class="row fieldset">
                <!-- Column headings -->
                <div class="standard-form-group-large">
                  <h6 class="legend-title mb-3">
                    {{$t('class_1')}}
                  </h6>
                  <div class="form-group">
                    <!-- Unacceptable -->
                      <hla-input-group
                        inputId="serumsummary-cumulative-class1-unacceptable"
                        :name="$t('unacceptable')"
                        v-model="editState.cumulativeAntibodies!.class1_unacceptable"
                        inputClass="hla-unacceptable"
                        @selected="(selectedAntibody: any) => toggleSelectedAntibody(selectedAntibody)"
                        :disabled="true"
                        :highlightSelection="true"
                        :selectedAntibodies="editState.selectedAntibodies"
                        :alleleSpecific="editState.cumulativeAntibodies!.class1_unacceptable_allele_specific"
                        :alphaBeta="editState.cumulativeAntibodies!.class1_unacceptable_alpha_beta"
                        :enable-epitopes="true"
                        :epitopes="editState.cumulativeAntibodies!.epitopes_unacceptable"
                      />
                     <!-- Indeterminate -->
                      <hla-input-group
                      inputId="serumsummary-cumulative-class1-indeterminate"
                      :name="$t('indeterminate')"
                      v-model="editState.cumulativeAntibodies!.class1_indeterminate"
                      inputClass="hla-indeterminate"
                      @selected="(selectedAntibody: any) => toggleSelectedAntibody(selectedAntibody)"
                      :disabled="true"
                      :highlightSelection="true"
                      :selectedAntibodies="editState.selectedAntibodies"
                      :alleleSpecific="editState.cumulativeAntibodies!.class1_indeterminate_allele_specific"
                      :alphaBeta="editState.cumulativeAntibodies!.class1_indeterminate_alpha_beta"
                      :enable-epitopes="true"
                      :epitopes="editState.cumulativeAntibodies!.epitopes_indeterminate"
                    />
                     <!-- Possible Allele Specific (low-res legacy data only found in historical records) -->
                    <hla-input
                      v-if="showClass1PossibleAlleleSpecific"
                      inputId="serumsummary-cumulative-class1-possible-allele-specific"
                      :name="$t('possible_allele_specific_field')"
                      v-model="editState.cumulativeAntibodies!.class1_possible_allele_specific"
                      inputClass="hla-possible-allele-specific"
                      @selected="(selectedAntibody: any) => toggleSelectedAntibody(selectedAntibody)"
                      :disabled="true"
                      :highlightSelection="true"
                      :selectedAntibodies="editState.selectedAntibodies"
                      :calculated="true"
                      :calculatedText="$t('legacy_data_indicator')"
                      :calculatedHoverText="$t('legacy_data_explanation')"
                    />
                  </div>
                </div>
                <div class="standard-form-group-large">
                  <h6 class="legend-title mb-3">
                    {{$t('class_2')}}
                  </h6>
               
                <!-- Unacceptable -->
                  <div class="form-group">
                    <hla-input-group
                      inputId="serumsummary-cumulative-class2-unacceptable"
                      :name="$t('unacceptable')"
                      v-model="editState.cumulativeAntibodies!.class2_unacceptable"
                      inputClass="hla-unacceptable"
                      @selected="(selectedAntibody: any) => toggleSelectedAntibody(selectedAntibody)"
                      :disabled="true"
                      :highlightSelection="true"
                      :selectedAntibodies="editState.selectedAntibodies"
                      :alleleSpecific="editState.cumulativeAntibodies!.class2_unacceptable_allele_specific"
                      :alphaBeta="editState.cumulativeAntibodies!.class2_unacceptable_alpha_beta"
                    />
                    <!-- Indeterminate -->
                    <hla-input-group
                      inputId="serumsummary-cumulative-class2-indeterminate"
                      :name="$t('indeterminate')"
                      v-model="editState.cumulativeAntibodies!.class2_indeterminate"
                      inputClass="hla-indeterminate"
                      @selected="(selectedAntibody: any) => toggleSelectedAntibody(selectedAntibody)"
                      :disabled="true"
                      :highlightSelection="true"
                      :selectedAntibodies="editState.selectedAntibodies"
                      :alleleSpecific="editState.cumulativeAntibodies!.class2_indeterminate_allele_specific"
                      :alphaBeta="editState.cumulativeAntibodies!.class2_indeterminate_alpha_beta"
                    />
                     <!-- Possible Allele Specific (low-res legacy data only found in historical records) -->
                    <hla-input
                      v-if="showClass2PossibleAlleleSpecific"
                      inputId="serumsummary-cumulative-class2-possible-allele-specific"
                      :name="$t('possible_allele_specific_field')"
                      v-model="editState.cumulativeAntibodies!.class2_possible_allele_specific"
                      inputClass="hla-possible-allele-specific"
                      @selected="(selectedAntibody: any) => toggleSelectedAntibody(selectedAntibody)"
                      :disabled="true"
                      :highlightSelection="true"
                      :selectedAntibodies="editState.selectedAntibodies"
                      :calculated="true"
                      :calculatedText="$t('legacy_data_indicator')"
                      :calculatedHoverText="$t('legacy_data_explanation')"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </template>
        </sub-section>
        <!-- Entries -->
        <sub-section
          :title="$t('entries')"
          sub-section-id="serumsummary-entries"
        >
          <template v-slot:contents>
            <fieldset>
              <!-- Selected Antibodies -->
              <h5 class="legend-title">
                {{$t('selected_antibodies')}}
              </h5>
              <nav class="nav action-row cumulative-sera">
                <button
                  type="button"
                  class="btn btn-light btn-sm mt-2 mr-2"
                  @click.prevent="removeAllSelectedAntibodies()"
                  :disabled="zeroAntibodiesSelected"
                  :class="{ disabled: zeroAntibodiesSelected }" >
                  {{$t('clear_filters')}}
                </button>
                <hla-input
                  v-if="!zeroAntibodiesSelected"
                  v-model="editState.selectedAntibodies"
                  inputId="serumsummary-entries-selectedantibodies"
                  inputClass="hla-cumulative"
                  :name="$t('selected_antibodies')"
                  @selected="(selectedAntibody: any) => removeSelectedAntibody(selectedAntibody)"
                  :hideLabel="true"
                  :disabled="true" />
              </nav>
              <!-- Testing Method -->
              <div class="row">
                <div class="standard-form-group">
                  <select-input
                    selectId="serumsummary-entries-testingmethod"
                    :name="$t('testing_method')"
                    v-model="editState.testingMethod"
                    :options="hlaTestingMethodLookup"
                    :undefined-text="$t('all')"
                  />
                </div>
              </div>
              <!-- Selected Antibodies Filter Table -->
              <hla-antibody-filter-table
                id="serumsummary-entries-table"
                v-if="editState"
                :table-config="hlaSerumSummaryReviewTableConfig"
                :testing-method="editState.testingMethod"
                :selected-antibodies="editState.selectedAntibodies"
              />
            </fieldset>
          </template>
        </sub-section>
        <!-- View Current & Cumulative Antibodies Modal -->
        <modal-section
          modal-id="hla-serum-summary-export-data-popup"
          ref="exportDataModel"
          class="modal-sticky-header"
          :centered="true"
        >
          <template v-slot:title>
            {{ $t('serum_summary_export_data_heading') }}
          </template>
          <template v-slot:body>
            <div class="row mb-3">
              <div class="col-12">
                {{ $t('serum_summary_export_data_explanation') }}
              </div>
            </div>
            <!-- Current Antibodies plain text table -->
            <sub-section
              sub-section-id="hla-serum-summary-export-data-current-table"
              :title="$t('serum_summary_export_data_current_heading')"
              :table-config="exportDataCurrentTableConfig"
              row-style-class="no-hover no-pointer"
              :collapsible="true"
              :default-collapsed="true"
            />
            <!-- Cumulative Antibodies plain text table -->
            <sub-section
              sub-section-id="hla-serum-summary-export-data-cumulative-table"
              :title="$t('serum_summary_export_data_cumulative_heading')"
              :table-config="exportDataCumulativeTableConfig"
              row-style-class="no-hover no-pointer"
              :collapsible="true"
            />
          </template>
          <template v-slot:footer>
            <div class="modal-footer-body">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-secondary"
              >
                {{ $t('cancel') }}
              </button>
            </div>
          </template>
        </modal-section>
      </validation-observer>
    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import HlaInput from '@/components/shared/HlaInput.vue';
import { Laboratory } from '@/store/laboratories/types';
import TextInput from '@/components/shared/TextInput.vue';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { idComparator } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { Component, Watch } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import HlaAntibodyFilterTable from '@/components/hla/HlaAntibodyFilterTable.vue';
import { Recipient, RecipientDiagnosticsHla, AntibodiesCumulative } from '@/store/recipients/types';
import { LabHLAAntibody, HlaAntibodyData, HlaAntibodyTestingMethod, HLAAntibodiesForm, HlaAntibodyDataForm } from '@/store/labs/types';
import { i18nMessages } from "@/i18n";

// Form schema
interface HlaSerumSummaryReviewForm {
  cumulativeAntibodies?: HlaAntibodyDataForm;
  selectedAntibodies?: string[];
  testingMethod?: number|null;
  hlaAntibodiesTestOpenInModal?: HLAAntibodiesForm;
}

interface HlaAntibodiesFilterRow {
  _id?: string;
  sampleCode: string;
  sampleDrawDate: string;
  laboratory: string;
  cpra1: string;
  cpra2: string;
  combinedCpra: string;
  selected_unacceptable: string[];
  selected_indeterminate: string[];
  selected_possible_allele_specific: string[];
}

interface ViewCumulativeAntibodyRow {
  reactivity: string;
  class1: string;
  class2: string;
}

// Types of modal switching
enum ModalSwitch {
  Previous,
  Next
}

@Component({
  components: {
    CardSection,
    SubSection,
    SelectInput,
    TextInput,
    TextAreaInput,
    NumberInput,
    HlaInput,
    ModalSection,
    HlaInputGroup,
    HlaAntibodyFilterTable,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/HlaSerumSummaryReview.json'),
  ]),
})
export default class HlaSerumSummaryReview extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.labs.hlaAntibodies) private hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.labs.selectedHlaSerumSummaryReview) private selectedHlaSerumSummaryReview!: LabHLAAntibody;
  @State(state => state.pageState.currentPage.hlaSerumSummaryReview) editState!: HlaSerumSummaryReviewForm;
  @State(state => state.recipients.selectedRecipient) private selectedRecipient!: Recipient;
  @State(state => state.lookups.laboratory_hla_antibody_test_kits) private hlaAntibodyTestKitLookup!: HlaAntibodyTestKit[];
  @State(state => state.recipients.selectedRecipient.diagnostics.hla) private recipientDiagnosticsHla!: RecipientDiagnosticsHla;
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = [
    'hla_testing_method',
    'hla_dictionary_epitopes',
    'laboratory_hla_antibody_test_kits',
    'hla_antibody_allele_group_overrides',
  ];

  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['hla'];

  // Getters
  @Getter('hla_testing_method', { namespace: 'lookups' }) private hlaTestingMethodLookup: any;
  @Getter('currentHlaAntibodyTest', { namespace: 'labs' }) private currentHlaAntibodyTest!: LabHLAAntibody|null;
  @Getter('buildHLAAntibodiesForm', { namespace: 'labs' }) buildHLAAntibodiesForm!: (hlaAntibodiesData: LabHLAAntibody) => HLAAntibodiesForm;
  @Getter('lookupHlaAntibodyTestingKit', { namespace: 'lookups' }) lookupHlaAntibodyTestingKit!: (code: string|null) => HlaAntibodyTestKit|null;

  // Initialize form when card section has loaded
  private loaded(): void {
    // Load the labs after card section has finished loading relevant lookups
    const recipientId = this.selectedRecipient.client_id;
    this.$store.dispatch('labs/loadHlaAntibodies', recipientId).then(() => {
      this.initializeForm();
      this.$emit('loaded', 'hlaSerumSummaryReview');
    }).catch(() => {
      console.warn('Could not load recipient HLA Antibody labs');
    });
  }

  // Watchers
  @Watch('hlaAntibodies')
  private onHlaAntibodiesChanged(): void {
    this.initializeForm();
  }
  @Watch('selectedRecipient')
  private onSelectedRecipientChanged(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    // Deselect any HLA Antibodies Test if one is selected
    this.$store.commit('labs/selectHlaSerumSummaryReview', undefined);
    // Populate editable form state with values extracted from selected recipient
    this.$store.commit('pageState/set', {
      pageKey: 'hlaSerumSummaryReview',
      value: this.extractHlaSerumSummaryReviewForm(this.selectedRecipient, this.hlaAntibodies)
    });
  }

  // Translate just the HLA Cumulative Antibody data based on the form layout
  private buildHlaCumulativeAntibodiesDataForm(recipientDiagnosticsHla: RecipientDiagnosticsHla): HlaAntibodyDataForm {
    const cumulative: AntibodiesCumulative = recipientDiagnosticsHla.cumulative_antibodies || {};
    const class1 = cumulative.class1 || { antibodies: {}, epitopes: {} };
    const class2 = cumulative.class2 || { antibodies: {}, epitopes: {} };
    const epitopes = class1.epitopes || {};
    return {
      class1_unacceptable: class1.antibodies.unacceptable_allele_group,
      class1_unacceptable_allele_specific: class1.antibodies.unacceptable_allele_specific,
      class1_unacceptable_alpha_beta: class1.antibodies.unacceptable_alpha_beta,
      class1_indeterminate: class1.antibodies.indeterminate_allele_group,
      class1_indeterminate_allele_specific: class1.antibodies.indeterminate_allele_specific,
      class1_indeterminate_alpha_beta: class1.antibodies.indeterminate_alpha_beta,
      class1_possible_allele_specific: class1.antibodies.possible_allele_specific,
      class2_unacceptable: class2.antibodies.unacceptable_allele_group,
      class2_unacceptable_allele_specific: class2.antibodies.unacceptable_allele_specific,
      class2_unacceptable_alpha_beta: class2.antibodies.unacceptable_alpha_beta,
      class2_indeterminate: class2.antibodies.indeterminate_allele_group,
      class2_indeterminate_allele_specific: class2.antibodies.indeterminate_allele_specific,
      class2_indeterminate_alpha_beta: class2.antibodies.indeterminate_alpha_beta,
      class2_possible_allele_specific: class2.antibodies.possible_allele_specific,
      epitopes_unacceptable: epitopes.unacceptable,
      epitopes_indeterminate: epitopes.indeterminate,
    };
  }

  // Translate from relevant parts of the HLA Antibody Lab data structure to the form layout
  private extractHlaSerumSummaryReviewForm(recipient: Recipient, hlaAntibodies: LabHLAAntibody[]): HlaSerumSummaryReviewForm {
    // Retrieve and transform all values needed for the form
    const result: HlaSerumSummaryReviewForm = {
      cumulativeAntibodies: (!recipient.diagnostics!.hla ? {} : this.buildHlaCumulativeAntibodiesDataForm(recipient.diagnostics!.hla)),
      selectedAntibodies: [],
      testingMethod: HlaAntibodyTestingMethod.Sab
    };
    return result;
  }

  // Event handlers
  private removeAllSelectedAntibodies(): void {
    this.editState.selectedAntibodies = [];
  }
  private toggleSelectedAntibody(selectedAntibody: any): void {
    if (this.editState && this.editState.selectedAntibodies) {
      // Check whether or not the antibody has already been selected
      if (!this.editState.selectedAntibodies.includes(selectedAntibody)) {
        // Add antibody to selection
        this.editState.selectedAntibodies.push(selectedAntibody);
      } else {
        // Remove antibody from selection
        const indexOfGene = this.editState.selectedAntibodies.indexOf(selectedAntibody);
        this.editState.selectedAntibodies.splice(indexOfGene, 1);
      }
    }
  }
  private removeSelectedAntibody(selectedAntibody: any): void {
    if (this.editState && this.editState.selectedAntibodies) {
      // Remove antibody from selection
      const indexOfGene = this.editState.selectedAntibodies.indexOf(selectedAntibody);
      this.editState.selectedAntibodies.splice(indexOfGene, 1);
    }
  }
  private openHlaAntibodiesTestModalFromTable(selectedRow: any): void {
    // Get HLA Antibodies Test ID from the table row referenced in the select event
    const selectedHlaAntibodiesId = selectedRow._id && selectedRow._id ? selectedRow._id : undefined;
    // Retrieve the HLA Antibodies Test object from the Vue-X Store
    const foundHlaAntibodies: LabHLAAntibody|undefined = this.hlaAntibodies.find((each: LabHLAAntibody) => {
      return each._id && each._id === selectedHlaAntibodiesId;
    });
    // Check if we successfully found the HLA Antibodies Test object
    if (foundHlaAntibodies) {
      this.openHlaAntibodiesTestModal(foundHlaAntibodies);
    }
  }
  private openHlaAntibodiesTestModal(hlaAntibodiesTest: LabHLAAntibody): void {
    // Store the selected HLA Antibodies Test object
    this.$store.commit('labs/selectHlaSerumSummaryReview', hlaAntibodiesTest);
    // Extract form state from the selected object
    this.editState.hlaAntibodiesTestOpenInModal = this.buildHLAAntibodiesForm(this.selectedHlaSerumSummaryReview);
    // Begin showing the modal
    const hlaAntibodiesModal = this.$refs.hlaAntibodiesModal as ModalSection;
    hlaAntibodiesModal.showModal();
  }
  private previousHlaAntibodiesTestModal(): void {
    // Begin switching the modal
    const hlaAntibodiesModal = this.$refs.hlaAntibodiesModal as ModalSection;
    hlaAntibodiesModal.switchModal(ModalSwitch.Previous);
  }
  private nextHlaAntibodiesTestModal(): void {
    // Begin switching the modal
    const hlaAntibodiesModal = this.$refs.hlaAntibodiesModal as ModalSection;
    hlaAntibodiesModal.switchModal(ModalSwitch.Next);
  }
  private onHlaAntibodiesTestModalSwitched(options?: any): void {
    if (this.selectedHlaSerumSummaryReview) {
      // Use filtered rows from table component so that modal switch order logically corresponds to row order
      const table = this.$refs.serumSummaryEntriesTable as unknown as { filteredRows: any[] };
      const filterRowsFromTable: HlaAntibodiesFilterRow[] = table.filteredRows[0].children;
      // Determine index of selected test with respect to the filtered rows
      const indexOfCurrentTest = filterRowsFromTable.findIndex((test: any) => {
        return idComparator(test, this.selectedHlaSerumSummaryReview);
      });
      if (indexOfCurrentTest > -1) {
        if (options == ModalSwitch.Previous) {
          // Identify previous row with respect to filtering, sorting, etc.
          let previousFilterRow: HlaAntibodiesFilterRow;
          if (indexOfCurrentTest < 1) {
            previousFilterRow = filterRowsFromTable[filterRowsFromTable.length - 1];
          } else {
            previousFilterRow = filterRowsFromTable[indexOfCurrentTest - 1];
          }
          if (previousFilterRow) {
            // Fetch direct reference to source API document regardless of filtering
            const previousTest = this.hlaAntibodies.find((test: any) => {
              return idComparator(test, previousFilterRow);
            });
            if (previousTest) {
              // Select previous test
              this.openHlaAntibodiesTestModal(previousTest);
            }
          }
        } else if (options == ModalSwitch.Next) {
          // Identify next row with respect to filtering, sorting, etc.
          let nextFilterRow: HlaAntibodiesFilterRow;
          if (indexOfCurrentTest > filterRowsFromTable.length - 2) {
            nextFilterRow = filterRowsFromTable[0];
          } else {
            nextFilterRow = filterRowsFromTable[indexOfCurrentTest + 1];
          }
          if (nextFilterRow) {
            // Fetch direct reference to source API document regardless of filtering
            const nextTest = this.hlaAntibodies.find((test: any) => {
              return idComparator(test, nextFilterRow);
            });
            if (nextTest) {
              // Select next test
              this.openHlaAntibodiesTestModal(nextTest);
            }
          }
        }
      }
    }
  }

  // Open export data modal for copying plaintext antibody data
  private openExportDataModal(): void {
    const exportDataModel = this.$refs.exportDataModel as ModalSection;
    if (!exportDataModel) return;

    exportDataModel.showModal();
  }

  // Get method dropdown options as string-based values so it is visible in the disabled select input
  get hlaTestingMethodOptions(): GenericCodeValue[] {
    const methods = this.hlaTestingMethodLookup || [];
    const sanitizedMethods = methods.map((method: NumericCodeValue): GenericCodeValue => {
      return { code: method.code.toString(), value: method.value };
    });
    return sanitizedMethods;
  }

  // Conditions for toggling form areas
  get zeroAntibodiesSelected(): boolean {
    return !this.editState.selectedAntibodies || this.editState.selectedAntibodies.length === 0;
  }

  // Display Class 1 legacy data?
  get showClass1PossibleAlleleSpecific(): boolean {
    if (!this.editState || !this.editState.cumulativeAntibodies) return false;

    const values = this.editState.cumulativeAntibodies!.class1_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display Class 2 legacy data?
  get showClass2PossibleAlleleSpecific(): boolean {
    if (!this.editState || !this.editState.cumulativeAntibodies) return false;

    const values = this.editState.cumulativeAntibodies!.class2_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display legacy data column in filter table?
  get showPossibleAlleleSpecificColumn(): boolean {
    return this.showClass1PossibleAlleleSpecific || this.showClass2PossibleAlleleSpecific;
  }

  // Display legacy data rows in 'view' popup?
  get showPossibleAlleleSpecificRows(): boolean {
    return this.showClass1PossibleAlleleSpecific || this.showClass2PossibleAlleleSpecific;
  }

  get tableColumns(): { label: string; field: string; width?: string }[] {
    // Columns that are always present
    const result = [
      { label: this.$t('sample_code').toString(), field: 'sampleCode' },
      { label: this.$t('sample_draw_date').toString(), field: 'sampleDrawDate' },
      { label: this.$t('laboratory').toString(), field: 'laboratory' },
      { label: this.$t('cpra_pra_class_i').toString(), field: 'cpra1' },
      { label: this.$t('cpra_pra_class_ii').toString(), field: 'cpra2' },
      { label: this.$t('combined_cpra').toString(), field: 'combinedCpra' },
      { label: this.$t('unacceptable').toString(), field: 'selected_unacceptable', width: '10%' },
      { label: this.$t('indeterminate').toString(), field: 'selected_indeterminate', width: '10%' },
    ];

    // Possible Allele Specific column only shown for low-res legacy data found in historical records
    if (this.showPossibleAlleleSpecificColumn) {
      result.push(
        { label: this.$t('possible_allele_specific_column').toString(), field: 'selected_possible_allele_specific', width: '10%' },
      );
    }
    return result;
  }

  // Filter table configuration
  get hlaSerumSummaryReviewTableConfig(): TableConfig {
    return {
      data: this.hlaAntibodies,
      columns: this.tableColumns,
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Current Antibodies Class I column heading
  get exportCurrentClass1Column(): string {
    if (!this.currentHlaAntibodyTest) return this.$t('class_i').toString();

    const sampleDate = this.currentHlaAntibodyTest?.sample_date;
    const parsed = this.parseDisplayDateUi(sampleDate) || '-';
    return `${this.$t('class_i')}, ${this.$t('sample_date').toString()} ${parsed}`;
  }

  // Current Antibodies Class II column heading
  get exportCurrentClass2Column(): string {
    if (!this.currentHlaAntibodyTest) return this.$t('class_ii').toString();

    const sampleDate = this.currentHlaAntibodyTest?.sample_date;
    const parsed = this.parseDisplayDateUi(sampleDate) || '-';
    return `${this.$t('class_ii')}, ${this.$t('sample_date').toString()} ${parsed}`;
  }

  // Cumulative Antibodies Class I column heading
  get exportCumulativeClass1Column(): string {
    return this.$t('class_i').toString();
  }

  // Cumulative Antibodies Class II column heading
  get exportCumulativeClass2Column(): string {
    return this.$t('class_ii').toString();
  }

  // Generate plain text representation of a single string array
  private plainTextArray(arr?: string[]): string {
    if (!arr || arr.length === 0) return '';

    // NOTE: Tp-13895 We need to use Cw rather than C when displaying the copy popup
    const mapped = arr.map((raw: string): string => {
      return raw.replace(/C/, "Cw");
    });

    return mapped.join(' ').trim();
  }

  // Generate rows of plain text data based on recipient's most recent HLA Antibody Test
  get currentAntibodyRows(): ViewCumulativeAntibodyRow[] {
    if (!this.currentHlaAntibodyTest || !this.currentHlaAntibodyTest.antibodies) return [];

    // Generate multiple entries in row array, based on a single HLA antibody test
    const rows: ViewCumulativeAntibodyRow[] = [];
    const antibodies: HlaAntibodyData = this.currentHlaAntibodyTest.antibodies;

    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('allele_group')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_group),
    });
    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('allele_specific')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_specific),
    });
    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('epitope')}`,
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.unacceptable),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.unacceptable),
    });
    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('alpha_beta')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_alpha_beta),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('allele_group')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_group),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('allele_specific')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_specific),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('epitope')}`,
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.indeterminate),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.indeterminate),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('alpha_beta')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_alpha_beta),
    });
    if (this.showPossibleAlleleSpecificRows) {
      rows.push({
        reactivity: this.$t('possible_allele_specific_row').toString(),
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.possible_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.possible_allele_specific),
      });
    }
    return rows;
  }

  // Generate rows of plain text data based on recipient's cumulative HLA diagnostics
  get cumulativeAntibodyRows(): ViewCumulativeAntibodyRow[] {
    if (!this.currentHlaAntibodyTest) return [];

    // Generate multiple entries in row array, based on a recipient-level HLA diganostics
    const rows: ViewCumulativeAntibodyRow[] = [];
    const antibodies: AntibodiesCumulative= this.recipientDiagnosticsHla.cumulative_antibodies || {};

    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('allele_group')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_group),
    });
    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('allele_specific')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_specific),
    });
    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('epitope')}`,
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.unacceptable),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.unacceptable),
    });
    rows.push({
        reactivity: `${this.$t('unacceptable')} ${this.$t('alpha_beta')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_alpha_beta),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('allele_group')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_group),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('allele_specific')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_specific),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('epitope')}`,
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.indeterminate),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.indeterminate),
    });
    rows.push({
        reactivity: `${this.$t('indeterminate')} ${this.$t('alpha_beta')}`,
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_alpha_beta),
    });
    if (this.showPossibleAlleleSpecificRows) {
      rows.push({
        reactivity: this.$t('possible_allele_specific_row').toString(),
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.possible_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.possible_allele_specific),
      });
    }
    return rows;
  }

  // Current Antibodies plain text table configuration
  get exportDataCurrentTableConfig(): TableConfig {
    return {
      data: this.currentAntibodyRows,
      columns: [
        {
          label: this.$t('serum_summary_export_data_reactivity_column').toString(),
          field: 'reactivity',
          width: '24%',
        },
        {
          label: this.exportCurrentClass1Column,
          field: 'class1',
          width: '38%',
        },
        {
          label: this.exportCurrentClass2Column,
          field: 'class2',
          width: '38%',
        },
      ],
      empty: this.$t('serum_summary_export_data_empty').toString(),
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Cumulative Antibodies plain text table configuration
  get exportDataCumulativeTableConfig(): TableConfig {
    return {
      data: this.cumulativeAntibodyRows,
      columns: [
        {
          label: this.$t('serum_summary_export_data_reactivity_column').toString(),
          field: 'reactivity',
          width: '24%',
        },
        {
          label: this.exportCumulativeClass1Column,
          field: 'class1',
          width: '38%',
        },
        {
          label: this.exportCumulativeClass2Column,
          field: 'class2',
          width: '38%',
        },
      ],
      empty: this.$t('serum_summary_export_data_empty').toString(),
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Row style class for Filter Table highlighting
  private hlaSerumSummaryReviewRowStyleClass(row: any): string {
    if (row.highlight) {
      return 'set-link filterRow filterRowMatch';
    } else {
      return 'set-link filterRow';
    }
  }

  private includesAntibody(sourceAntibodies: string[], targetAntibody: string): boolean {
    // Check for target antibody in source antibodies
    return sourceAntibodies.includes(targetAntibody);
  }

  private saving(event: any) {
    this.$emit('saving', event);
  }

  private handleErrors(event: any) {
    this.$emit('handleErrors', event);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};
    return result;
  }


}
</script>

<style>
  table.vgt-table tr.clickable.no-pointer {
    cursor: unset;
  }
  table.vgt-table tr.clickable.no-pointer td div span {
    cursor: text;
  }
</style>
