import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sr-only" }
const _hoisted_2 = { class: "side-nav-container" }
const _hoisted_3 = {
  key: 0,
  class: "nav-block-wrap"
}
const _hoisted_4 = { class: "nav flex-column nav-block active" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-link no-scroll" }
const _hoisted_7 = { class: "nav flex-column" }
const _hoisted_8 = {
  class: "nav-link",
  href: "#organ-details-section"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_side_nav_toolbar = _resolveComponent("side-nav-toolbar")!
  const _component_selected_recipient_journey_links = _resolveComponent("selected-recipient-journey-links")!
  const _component_proto_offers_links = _resolveComponent("proto-offers-links")!
  const _component_selected_recipient_profile_links = _resolveComponent("selected-recipient-profile-links")!
  const _component_selected_recipient_hla_links = _resolveComponent("selected-recipient-hla-links")!
  const _component_base_side_nav = _resolveComponent("base-side-nav")!

  return (_openBlock(), _createBlock(_component_base_side_nav, {
    class: _normalizeClass({ closed: _ctx.sideNavClosed })
  }, {
    toolbar: _withCtx(() => _cache[1] || (_cache[1] = [])),
    "side-nav-content": _withCtx(() => [
      _createVNode(_component_side_nav_toolbar, { onToggle: _ctx.toggleSideNav }, {
        "return-link": _withCtx(() => [
          _createVNode(_component_router_link, {
            class: "nav-link no-scroll",
            title: _ctx.$t('return_to_prev'),
            "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
            to: { name: 'edit-recipient', params: { id: _ctx.recipient.client_id }, hash: '#organ-referral' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'arrow-left'],
                "fixed-width": ""
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('return_to_prev')), 1)
            ]),
            _: 1
          }, 8, ["title", "aria-label", "to"])
        ]),
        _: 1
      }, 8, ["onToggle"]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRecipientJourneysList, (journey) => {
          return (_openBlock(), _createBlock(_component_selected_recipient_journey_links, {
            key: journey.id,
            onToggle: _ctx.toggleSideNav,
            journeyName: journey.name,
            journeyId: journey.id,
            organCode: journey.organCode,
            transplantedDate: journey.transplantedDate
          }, null, 8, ["onToggle", "journeyName", "journeyId", "organCode", "transplantedDate"]))
        }), 128)),
        (_ctx.newOrgan)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createElementVNode("li", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "nav-caret",
                      icon: ['fas', (_ctx.show ? 'chevron-down' : 'chevron-right')],
                      "fixed-width": "",
                      onClick: _withModifiers(_ctx.toggleNav, ["prevent"])
                    }, null, 8, ["icon", "onClick"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.newOrgan)), 1)
                  ])
                ]),
                _withDirectives(_createElementVNode("ul", _hoisted_7, [
                  _createElementVNode("div", null, [
                    _createElementVNode("li", {
                      class: "nav-item",
                      onToggle: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideNav && _ctx.toggleSideNav(...args)))
                    }, [
                      _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('journey_overview', { organ: _ctx.$t(_ctx.newOrgan+"1").toString() })), 1)
                    ], 32)
                  ])
                ], 512), [
                  [_vShow, _ctx.show]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
        (_ctx.isProtoRecipientOffersEnabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_proto_offers_links, { onToggle: _ctx.toggleSideNav }, {
                "profile-return-link": _withCtx(() => [
                  _createVNode(_component_router_link, {
                    class: "no-scroll open",
                    style: {"color":"#2e2e2e !important"},
                    to: { name: 'proto-offers-pages', params: { id: _ctx.recipient.client_id } }
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Offers ")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ]),
                _: 1
              }, 8, ["onToggle"]),
              _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_selected_recipient_profile_links, { onToggle: _ctx.toggleSideNav }, {
          "profile-return-link": _withCtx(() => [
            _createVNode(_component_router_link, {
              class: "no-scroll open",
              style: {"color":"#2e2e2e !important"},
              to: { name: 'edit-recipient', params: { id: _ctx.recipient.client_id } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('profile')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _: 1
        }, 8, ["onToggle"]),
        _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
        _createVNode(_component_selected_recipient_hla_links, { onToggle: _ctx.toggleSideNav }, null, 8, ["onToggle"])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}