import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav-wrapper" }
const _hoisted_4 = { class: "page-content" }
const _hoisted_5 = { class: "content-wrap" }
const _hoisted_6 = { class: "container-fluid" }
const _hoisted_7 = {
  key: 0,
  class: "nav-wrapper"
}
const _hoisted_8 = { class: "page-content" }
const _hoisted_9 = {
  key: 1,
  class: "nav-wrapper"
}
const _hoisted_10 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_side_nav = _resolveComponent("loading-side-nav")!
  const _component_loading_donor_page = _resolveComponent("loading-donor-page")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_donor_sticky_summary = _resolveComponent("donor-sticky-summary")!
  const _component_side_nav_donor_organ = _resolveComponent("side-nav-donor-organ")!
  const _component_donor_summary = _resolveComponent("donor-summary")!
  const _component_coordinator_offers = _resolveComponent("coordinator-offers")!
  const _component_deceased_donor_offers = _resolveComponent("deceased-donor-offers")!
  const _component_national_organ_waitlist_modal = _resolveComponent("national-organ-waitlist-modal")!
  const _component_offer_outcome_modal = _resolveComponent("offer-outcome-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("span", {
                class: "skeleton-box",
                style: {"width":"200px"}
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_loading_side_nav),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_loading_donor_page)
                ])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: _ctx.getDonorsUrl }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('donors')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _cache[6] || (_cache[6] = _createTextVNode(" / ")),
              _createVNode(_component_router_link, {
                to: { name: 'edit-deceased-donor', params: { id: _ctx.clientId } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.deceasedDonorDisplayName), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" / " + _toDisplayString(_ctx.donorOrganName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_donor_sticky_summary, {
            ref: "donorStickySummary",
            onLoaded: _cache[0] || (_cache[0] = (ref) => _ctx.loaded(ref) ),
            openNowModal: () => _ctx.openNowModal()
          }, null, 8, ["openNowModal"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.showCombinedOffersView)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_side_nav_donor_organ),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_donor_summary, {
                        ref: "donorSummary",
                        onLoaded: _cache[1] || (_cache[1] = (ref) => _ctx.loaded(ref)),
                        openNowModal: () => _ctx.openNowModal()
                      }, null, 8, ["openNowModal"]),
                      _createVNode(_component_coordinator_offers)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_side_nav_donor_organ),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_donor_summary, {
                        ref: "donorSummary",
                        onLoaded: _cache[2] || (_cache[2] = (ref) => _ctx.loaded(ref)),
                        openNowModal: () => _ctx.openNowModal(),
                        onDisplayOutcomeNotification: _ctx.displayOutcomeNotification
                      }, null, 8, ["openNowModal", "onDisplayOutcomeNotification"]),
                      _createVNode(_component_deceased_donor_offers, {
                        ref: "allocations",
                        onReload: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reload())),
                        onLoaded: _cache[4] || (_cache[4] = (ref) => _ctx.loaded(ref)),
                        onDisplayOutcomeNotification: _ctx.displayOutcomeNotification
                      }, null, 8, ["onDisplayOutcomeNotification"])
                    ])
                  ]))
            ]),
            (_ctx.canAccessNowList)
              ? (_openBlock(), _createBlock(_component_national_organ_waitlist_modal, {
                  key: 0,
                  ref: "nationalOrganWaitlistModal"
                }, null, 512))
              : _createCommentVNode("", true)
          ])
        ], 64)),
    _createVNode(_component_offer_outcome_modal, { ref: "offerOutcome" }, null, 512)
  ]))
}