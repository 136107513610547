<template>
  <div v-if="isProtoHeartSpecificDetailsEnabled">
  <card-section
    section-id="proto_heart_specific_details"
  >
    <template v-slot:header>
      Heart Specific Details
    </template>
    <template v-slot:body>
      <form>
        <proto-heart-cardiac-catheterization />
        <proto-heart-echo-results />
        <proto-heart-myocardial-perfusion />
        <proto-heart-vad-vlad />
        <proto-heart-other-cardiac-circulatory-support />
      </form>
    </template>
  </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import ProtoHeartEchoResults from '@/prototypes/organs/heart/ProtoHeartEchoResults.vue';
import ProtoHeartMyocardialPerfusion from '@/prototypes/organs/heart/ProtoHeartMyocardialPerfusion.vue';
import ProtoHeartCardiacCatheterization from '@/prototypes/organs/heart/ProtoHeartCardiacCatheterization.vue';
import ProtoHeartOtherCardiacCirculatorySupport from '@/prototypes/organs/heart/ProtoHeartOtherCardiacCirculatorySupport.vue';
import ProtoHeartVadVlad from '@/prototypes/organs/heart/ProtoHeartVadVlad.vue';



@Component({
  components: {
    CardSection,
    ProtoHeartEchoResults,
    ProtoHeartMyocardialPerfusion,
    ProtoHeartCardiacCatheterization,
    ProtoHeartOtherCardiacCirculatorySupport,
    ProtoHeartVadVlad,
  }
})
export default class ProtoHeartSpecificDetails extends Vue {


  get isProtoHeartSpecificDetailsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.HeartSpecificDetails);
  }
}
</script>
