<template>
  <card-section
    section-id="referral-section"
    :lookups-to-load="lookupsToLoad"
    @loaded="loaded()"
    :disabled="!uiJourney.canSave || newJourney"
  >
    <template v-slot:header>
      {{$t('referral')}}
    </template>
    <template v-slot:body>
      <!-- referral details / referring physician details -->
      <referral-details-section 
        ref="referralDetails"
        :new-journey="newJourney"
      />
    </template>
  </card-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import ReferralDetailsSection from '@/components/organs/shared/ReferralDetailsSection.vue';
import { i18nMessages } from '@/i18n';
import { UIJourney } from '@/UIModels/journey';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    CardSection,
    ReferralDetailsSection,
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/ReferralSection.json'),
    require('@/components/_locales/Organs.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class ReferralSection extends Vue {
  // Properties
  @Prop({ default: false }) newJourney!: boolean;

  private lookupsToLoad = [
    'country',
    'organization_type',
    'professional_type',
    'referral_source',
  ];

  get uiJourney(): UIJourney {
    return useCurrentPageStore().currentJourney as UIJourney;
  }

  // Called after all lookups are loaded
  private loaded(): void {
    this.$emit('loaded', 'referralSection');
  }
}
</script>
