import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoOfferStatisticsEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_offers_statistics" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_form_layout, { formId: "proto_offers_statistics-section" }, {
              contents: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  title: "OFFERS",
                  "sub-section-id": "proto_offers_statistics-internal",
                  hideDivider: true
                }, {
                  contents: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "row" }, [
                      _createElementVNode("div", { class: "summary-list" }, [
                        _createElementVNode("dl", null, [
                          _createElementVNode("dt", null, "Offer Rate"),
                          _createElementVNode("dd", null, "6 / month")
                        ]),
                        _createElementVNode("dl", null, [
                          _createElementVNode("dt", null, "Totals"),
                          _createElementVNode("dd", null, "33 offers (11 primary, 22 backup)")
                        ])
                      ])
                    ], -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}