<template>
  <card-section
    :lookupsToLoad="lookupsToLoad"
    @loaded="handleLoaded"
    section-id="gci">
    <template v-slot:header>
      {{$t('general_clinical_information')}}
    </template>
    <template v-slot:body>
      <proto-blood-type-form 
        :canSave="uiRecipient.canSave"
        v-if="isProtoCpraEnabled"/>
      <template v-else>
        <blood-type-form
          ref="bloodTypeForm"
          v-if="isRecipientGeneralClinicalBloodEnabled"
          :newRecipient=newRecipient
          :canSave="uiRecipient.canSave"
        />
      </template>
      <measurements-form
        ref="measurementsForm"
        v-if="isRecipientGeneralClinicalMeasurementsEnabled"
        :newRecipient=newRecipient
        :canSave="uiRecipient.canSave"
      />
    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State } from 'vuex-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { RecipientValidations } from '@/store/recipients/types';
import { i18nMessages } from "@/i18n";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import BloodTypeForm from '@/components/recipients/_BloodTypeForm.vue';
import MeasurementsForm from "@/components/recipients/_MeasurementsForm.vue";
import ProtoBloodTypeForm from '@/prototypes/profile/_ProtoBloodTypeform.vue';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIRecipient } from '@/UIModels/recipient';

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput,
    BloodTypeForm,
    MeasurementsForm,
    ProtoBloodTypeForm
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class GeneralClinicalInformation extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;

  public lookupsToLoad = ['blood_type', 'rh_indicator'];

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  get isProtoCpraEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ShowBloodType);
  }

  // Check if Recipient General Clinical Blood is enabled
  get isRecipientGeneralClinicalBloodEnabled(): boolean {
    const currentPageStore = useCurrentPageStore();
    if (!currentPageStore.configuration) return false;

    return currentPageStore.configuration.features.recipientConfig.recipientGeneralClinical.bloodType.enabled;
  }

  // Check if Recipient General Clinical Measurements is enabled
  get isRecipientGeneralClinicalMeasurementsEnabled(): boolean {
    const currentPageStore = useCurrentPageStore();
    if (!currentPageStore.configuration) return false;

    return currentPageStore.configuration.features.recipientConfig.recipientGeneralClinical.measurements.enabled;
  }

  // Triggered when all the lookups have been loaded
  private handleLoaded(): void {
    this.$emit('loaded', 'generalClinical');
  }

  public resetSaveToolbar(): void {
    const bloodType = this.$refs.bloodTypeForm as any;
    if (bloodType) { bloodType.resetSaveToolbar(); }

    const measurementsForm = this.$refs.measurementsForm as any;
    if (measurementsForm) { measurementsForm.resetSaveToolbar(); }
  }

}
</script>


