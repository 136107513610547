import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12 transportation-section" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_6 = { class: "standard-form-group form-group-checkbox" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "standard-form-group-large" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "standard-form-group-large form-group-checkbox" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-12 col-sm-6 mb-2" }
const _hoisted_22 = { class: "col-12 col-sm-6 align-vertical" }
const _hoisted_23 = { class: "col-12 col-sm-6 mb-2" }
const _hoisted_24 = { class: "col-12 col-sm-6 align-vertical" }
const _hoisted_25 = { class: "col-12 col-sm-6 mb-2" }
const _hoisted_26 = { class: "col-12 col-sm-6 align-vertical" }
const _hoisted_27 = { class: "col-12 col-sm-6 mb-2" }
const _hoisted_28 = { class: "col-12 col-sm-6 align-vertical" }
const _hoisted_29 = { class: "col-12 col-sm-6 mb-2" }
const _hoisted_30 = { class: "col-12 col-sm-6 align-vertical" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "standard-form-group-3-quarters" }
const _hoisted_33 = { class: "row" }
const _hoisted_34 = { class: "standard-form-group-3-quarters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoTransportationAndMobilityEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_transportation_and_mobility" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "proto-transportation-and-mobility-form" }, {
              contents: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  title: "TRANSPORTATION AND MOBILITY",
                  "sub-section-id": "proto-transportation-and-mobility-internal",
                  hideDivider: true
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("fieldset", null, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _cache[19] || (_cache[19] = _createElementVNode("label", null, "Patient has own transportation?", -1)),
                              _createVNode(_component_boolean_radio_input, {
                                ruleKey: "hasTransportation",
                                "input-id": "hasTransportation",
                                acceptId: true,
                                class: "mt-4",
                                declineId: false,
                                acceptLabel: _ctx.$t('yes'),
                                declineLabel: _ctx.$t('no'),
                                modelValue: _ctx.editState.hasTransportation,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.hasTransportation) = $event))
                              }, null, 8, ["acceptLabel", "declineLabel", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                              _cache[20] || (_cache[20] = _createElementVNode("label", null, "Flags", -1)),
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "avoid-cities",
                                    hideLabel: "true",
                                    labelName: "Avoid cities",
                                    inputId: "avoid-cities",
                                    modelValue: _ctx.editState.avoidCities,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.avoidCities) = $event)),
                                    label: "Avoid cities"
                                  }, null, 8, ["modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "avoid-highways",
                                    hideLabel: "true",
                                    labelName: "Avoid highways",
                                    inputId: "highways",
                                    modelValue: _ctx.editState.avoidHighways,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.avoidHighways) = $event)),
                                    label: "Avoid highways"
                                  }, null, 8, ["modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "gas-money-sensitivity",
                                    hideLabel: "true",
                                    labelName: "Gas money sensitivity",
                                    inputId: "gas-money-sensitivity",
                                    modelValue: _ctx.editState.gasMoneySensitivity,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.gasMoneySensitivity) = $event)),
                                    label: "Gas money sensitivity"
                                  }, null, 8, ["modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "mobility-challenges",
                                    hideLabel: "true",
                                    labelName: "Mobility challenges",
                                    inputId: "mobility-challenges",
                                    modelValue: _ctx.editState.mobilityChallenges,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.mobilityChallenges) = $event)),
                                    label: "Mobility challenges"
                                  }, null, 8, ["modelValue"])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_number_input, {
                                    inputId: "distance-miles",
                                    name: "Max Distance (miles)",
                                    modelValue: _ctx.editState.maxMiles,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.maxMiles) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_18, [
                                  _createVNode(_component_number_input, {
                                    inputId: "max-time",
                                    name: "Max Time (minutes)",
                                    modelValue: _ctx.editState.maxMinuites,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.maxMinuites) = $event))
                                  }, null, 8, ["modelValue"])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _cache[21] || (_cache[21] = _createElementVNode("label", null, "Preferred Transportation Methods", -1)),
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "method-car",
                                    hideLabel: "true",
                                    labelName: "Car",
                                    inputId: "method-car",
                                    modelValue: _ctx.editState.methodCar,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.methodCar) = $event)),
                                    label: "Car"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  _createVNode(_component_text_input, {
                                    inputId: "car-details",
                                    name: "car-details",
                                    hideLabel: "true",
                                    modelValue: _ctx.editState.carDetails,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.carDetails) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_23, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "method-public",
                                    hideLabel: "true",
                                    labelName: "Public Transportation",
                                    inputId: "method-public",
                                    modelValue: _ctx.editState.methodPublic,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.methodPublic) = $event)),
                                    label: "Public Transportation"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_24, [
                                  _createVNode(_component_text_input, {
                                    inputId: "public-details",
                                    name: "public-details",
                                    hideLabel: "true",
                                    modelValue: _ctx.editState.publicDetails,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.publicDetails) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_25, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "method-ride",
                                    hideLabel: "true",
                                    labelName: "Ride Share",
                                    inputId: "method-ride",
                                    modelValue: _ctx.editState.methodRide,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.methodRide) = $event)),
                                    label: "Ride Share"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_26, [
                                  _createVNode(_component_text_input, {
                                    inputId: "ride-details",
                                    name: "ride-details",
                                    hideLabel: "true",
                                    modelValue: _ctx.editState.rideDetails,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.rideDetails) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_27, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "method-medicaid",
                                    hideLabel: "true",
                                    labelName: "Medicaid Transportation",
                                    inputId: "method-medicaid",
                                    modelValue: _ctx.editState.methodMedicaid,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.methodMedicaid) = $event)),
                                    label: "Medicaid Transportation"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_28, [
                                  _createVNode(_component_text_input, {
                                    inputId: "medicaid-details",
                                    name: "medicaid-details",
                                    hideLabel: "true",
                                    modelValue: _ctx.editState.medicaidDetails,
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.medicaidDetails) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_29, [
                                  _createVNode(_component_checkbox_input, {
                                    name: "method-other",
                                    hideLabel: "true",
                                    labelName: "Other",
                                    inputId: "method-other",
                                    modelValue: _ctx.editState.methodOther,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.methodOther) = $event)),
                                    label: "Other"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_30, [
                                  _createVNode(_component_text_input, {
                                    inputId: "other-details",
                                    name: "other-details",
                                    hideLabel: "true",
                                    modelValue: _ctx.editState.otherDetails,
                                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.otherDetails) = $event))
                                  }, null, 8, ["modelValue"])
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_text_area_input, {
                                "input-id": "additional-information",
                                name: "Mobility Impairments and Devices",
                                rows: "4",
                                showToolTip: true,
                                toolTipText: "Describe the patient's mobility impairments and devices and how they impact transportation and scheduling.",
                                modelValue: _ctx.editState.mobilityInpairmentsDevices,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.mobilityInpairmentsDevices) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createVNode(_component_text_area_input, {
                                "input-id": "inpairments-devices",
                                name: "Comments",
                                rows: "4",
                                modelValue: _ctx.editState.comments,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.comments) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveTransportation",
                  label: `Save ${_ctx.title}`,
                  cancelButton: true,
                  onSave: _ctx.performSave
                }, null, 8, ["label", "onSave"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}