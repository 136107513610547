import Vue from 'vue';
import { ObjectId } from '@/store/types';
import Vuex, { StoreOptions } from 'vuex';

// TODO: clean up any types when data is finalized

export interface TaskState {
  currentTask?: TaskType;
  percentage?: number;
  status?: string|undefined;
  errorState?: errorType;
}

export interface TaskType {
  category?: CategoryType;
  finish_datetime?: string;
  response?: string;
  start_datetime?: string;
  status?: string;
  task_identifier?: string;
  user_id?: string;
  _id?: string;
}

export enum CategoryType {
  Pending   = 'pending',
  Completed = 'completed',
}

export interface errorType {
  statusCode?: number;
  error?: string;
  message?: string;
}
