<template>
  <div v-if="isProtoCurrentOffersEnabled && loaded">
    <card-section
    section-id="proto_current_offers"
    :lookups-to-load="lookupsToLoad"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <sub-section
        sub-section-id="proto-current-offers-section"
        title="OFFER RESPONSES"
      >
        <template v-slot:contents>
          <div :class="getStyleClass()">
            <DataTable class="offers-table"
            :value="currentOffersTableConfig.data"
            :rows="currentOffersTableConfig.data.length"
            :class="{'scrollbox': true}"
            :rowClass="rowStyle"   
            id="current-offers-table"                                
            >
              <Column
                v-for="col, index of currentOffersTableConfig.columns"
                :field="col.field"
                :key="col.field"
                :style="buildColumnStyle(col)"
                :header="col.label"
                >
                <template #body="slotProps">
                  <span v-if="isMobile" class="p-column-title">{{col.label}}</span>
                  <template v-if="col.field == 'donor_id'">
                    <span>
                      <a href="javascript:void(0);" tabindex="0" class="table-link"  @click="onTableColClick">{{slotProps.data[col.field]}}</a>
                    </span>
                
                  </template>
                  <template v-else-if="col.field == 'offer_date_time'">
                    <span class="daytime-value">
                      {{slotProps.data.offer_date_time}}
                    </span>
                
                  </template>
                  <template v-else-if="col.field == 'response_date'">               
                    <date-input
                      :inputId="`${col.field} - ${index}`"
                      :name="col.field"
                      :hideLabel="true"
                      v-model="slotProps.data.response_date"
                    />
                  </template>
                  <template v-else-if="col.field == 'response_time'">
                    <time-input
                      :inputId="`${col.field} - ${index}`"
                      :name="col.field"
                      :hideLabel="true"
                      v-model="slotProps.data[col.field]"
                    />
                  </template>

                  <template v-else-if="col.field == 'coordinator'">    
                    <select-input
                      :select-id="`${col.field} - ${index}`"
                        :name="col.field"
                      :hideLabel="true"
                      :options="coordinatorOptions"
                      v-model="slotProps.data[col.field]"
                      >    
                    </select-input>
                  </template>
                  <template v-else-if="col.field == 'surgeon'">
                  
                    <select-input
                      :select-id="`${col.field} - ${index}`"
                      :name="col.field"
                      :hideLabel="true"
                      :options="surgeonOptions"
                      v-model="slotProps.data[col.field]"
                      >    
                    </select-input>
                  </template>
                  <template v-else-if="col.field == 'offer_response'">
                  
                  <select-input
                    :select-id="`${col.field} - ${index}`"
                    :name="col.field"
                    :hideLabel="true"
                    :options="offerResponseOptions"
                    v-model="slotProps.data[col.field]"
                    >    
                  </select-input>
                </template>
                <template v-else-if="col.field == 'response_category'">  
                  <select-input
                    :select-id="`${col.field} - ${index}`"
                    :name="col.field"
                    :hideLabel="true"
                    :options="responseCategoryOptions"
                    v-model="slotProps.data[col.field]"
                    >    
                  </select-input>
                </template>
                <template v-else-if="col.field == 'outcome_disposition'">
                  
                  <select-input
                    :select-id="`${col.field} - ${index}`"
                    :name="col.field"
                    :hideLabel="true"
                    :options="outcomeDispostionOptions"
                    v-model="slotProps.data[col.field]"
                    >    
                  </select-input>
                </template>
                <template v-else-if="col.field == 'notes'">
                  
                  <text-area-input
                    :input-id="`${col.field} - ${index}`"
                    :name="col.field"
                    rows='1'
                    :hideLabel="true"
                    v-model="slotProps.data[col.field]"
                  />
                  
                </template>
                  <template v-else>
                    {{slotProps.data[col.field ]}}
                  </template>
                </template>
              </Column>
              
            </DataTable>
            <save-toolbar
              :show="true"
          
              label="Save Reponses"
              :cancelButton="false"
              buttonClass="btn btn-sm btn-wide btn-success"
              cancelButtonClass="btn btn-sm btn-wide btn-secondary ml-auto"
            />
          </div>
        </template>
      </sub-section>
    </template>
  </card-section>
  <proto-compare-modal :selectedDonorId="selectedDonorId" modalId="compare-modal"
  ref="compareModal"/>
 
  </div>
</template>

<script lang="ts">

import { parseDateUiFromDateTime, parseTimeUiFromDateTime } from '@/utilities/date-utils';

  import {
    Component,
    Vue,
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import { TableConfig } from '@/types';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import ProtoCompareModal from '@/prototypes/offers/ProtoCompareModal.vue';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import DateInput from "@/components/shared/DateInput.vue";
  import TimeInput from "@/components/shared/TimeInput.vue";

  const PAGE_SIZES = [5, 10, 25];
  const EDITSTATE_KEY = 'editState';
    
@Component({
  components: {
    CardSection,
      SubSection,
      FormLayout,
      ProtoCompareModal,
      DataTable,
      Column,
      DateInput,
      TimeInput,
      SelectInput,
      TextAreaInput,
      SaveToolbar
  },
})
export default class ProtoCurrentOffers extends Vue {

  private loaded = false;
  private lookupsToLoad = [];
  private title: any = "Current Offers - Lung";
  public perPage = 10;
  public currentPageTable = 1;

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;
  private selectedDonorId: any = null;

  public checkIfMobile() {
    if (window.innerWidth < 961) {
      this.isMobile = window.innerWidth < 961;
    } else {
      this.isMobile = false;
    }
  }

  private coordinatorOptions = [{
      code: 1,
      value: 'M. Couch'
    }, {
      code: 2,
      value: 'S. Wood'
    }, {
      code: 3,
      value: 'S. McPhee'
    }, {
      code: 4,
      value: 'A. Johnston'
    }];


    private surgeonOptions = [{
      code: 0,
      value: 'B. Jones'
    }, {
      code: 1,
      value: 'C. Bratton'
    }, {
      code: 2,
      value: 'H. Payne'
    }, {
      code: 3,
      value: 'M. Bridger'
    },
    {
      code: 4,
      value: 'W. Johnston'
    }];


    private offerResponseOptions = [{
      code: 0,
      value: 'Accepted'
    }, {
      code: 1,
      value: 'Accepted with conditions'
    }, {
      code: 2,
      value: 'Declined'
    }, {
      code: 3,
      value: 'Extension Required'
    },
    {
      code: 4,
      value: 'W. Johnston'
    }];

    private responseCategoryOptions = [{
      code: 0,
      value: 'Pending Diagnosis'
    }, {
      code: 1,
      value: 'Pending Donor Information'
    }]

    private outcomeDispostionOptions = [{
      code: 0,
      value: 'Offer not primary'
    }, {
      code: 1,
      value: 'Offer withdrawn'
    }, {
      code: 2,
      value: 'Not transplanted (patient reasons)'
    }, {
      code: 3,
      value: 'Not transplanted (donor reasons)'
    },
    {
      code: 4,
      value: 'Not transplanted (logistics)'
    },
    {
      code: 5,
      value: 'Transplanted'
    }];

    private isMobile = false;


    public getStyleClass() {
      return { "spaced-list-items": this.isMobile} ;
    } 

    mounted(): void {
      this.$store.dispatch('hospitals/load').then(() => {
        this.loaded = true;
      });

      this.checkIfMobile();
      window.addEventListener('resize', this.checkIfMobile);
    }

    private currentOffers: any = [
    {
      donor_id: "552288",
      offer_date_time: "11-12-2024 14:00",
      organ: "Liver",
      laterality: "Whole",
      html: true,
      offer_type: "Primary",
      response_date: parseDateUiFromDateTime('2024-12-11T14:00:00.000Z'),
      response_time: parseTimeUiFromDateTime('2024-12-11T14:00:00.000Z'),
      coordinator: 1,
      surgeon: 1,
      offer_response: 1,
      response_category: 0,
    },
    {
      donor_id: "552454",
      offer_date_time: "12-12-2024 07:30",
      organ: "Liver",
      laterality: "Whole",
      html: true,
      offer_type: "Backup",
      response_date: parseDateUiFromDateTime('2024-12-12T14:00:00.000Z'),
      response_time: parseTimeUiFromDateTime('2024-12-12T14:00:00.000Z'),
      coordinator: 2,
      surgeon: 1,
      offer_response: null,
      response_category: null,
    }
  ];

  private buildColumnStyle(col: any): string|undefined {
    const styleParts = [];
    if (col.width) {
      styleParts.push(`width: ${col.width}`);
      styleParts.push(`min-width: ${col.width}`);
      styleParts.push(`max-width: ${col.width}`);
    }

    styleParts.push('overflow: hidden');
    styleParts.push('text-overflow: ellipsis');

    return styleParts.join('; ');
  }

  get rowStyle(): any {
    // For selection highlighting, use function driven by _id field (styling purposes only)
    return (row: any): string => {
      const results: string[] = ['offer-row-no-offer', 'tr-link' ,'p-datatable-selectable-row'];
    
      return results.join(' ');
    };
  }

  get currentOffersTableConfig(): TableConfig {
    return {
      data: this.tableRows || [],

      columns: [
        { label: 'Donor', field: 'donor_id', width: '100px', modal: true },
        { label: 'Offer Date Time	', field: 'offer_date_time', width: '180px' },
        { label: 'Organ', field: 'organ', width: '100px' },
        { label: 'Laterality', field: 'laterality', width: '100px' },
        { label: 'Offer Type', field: 'offer_type', width: '100px' },
        { label: 'Response Date', field: 'response_date', width: '180px', html: true},
        { label: 'Response Time', field: 'response_time', width: '130px', html: true},
        { label: 'Coordinator', field: 'coordinator', width: '150px', html: true},
        { label: 'Surgeon', field: 'surgeon', width: '150px', html: true},
        { label: 'Offer Response', field: 'offer_response', width: '150px', html: true},
        { label: 'Response Category', field: 'response_category', width: '150px', html: true},
        { label: 'Outcome / Dispostion', field: 'outcome_disposition', width: '180px', html: true},
        { label: 'Notes', field: 'notes', width: '150px', html: true},
      ],
      empty: 'use the form below to add a new row',
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // Table row click
  public onTableColClick(id: any) {
    this.selectedDonorId = id;
  }

  get isProtoCurrentOffersEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoCurrentOffers);
  }

  // Show the Recipient/Donor comparison pop-up for the specified row
  get tableRows() {
    const currentOffers = this.currentOffers || [];

    if (currentOffers.length > 0) {
      return currentOffers.map((offer: any) => {
        return {
          donor_id: offer.donor_id,
          offer_date_time: offer.offer_date_time || '-',
          organ: offer.organ,
          laterality: offer.laterality,
          offer_type: offer.offer_type,
          response_date: offer.response_date,
          response_time: offer.response_time,
          coordinator: offer.coordinator,
          surgeon: offer.surgeon,
          offer_response: offer.offer_response,
          response_category: offer.response_category,
          outcome_disposition:  offer.outcome_disposition,
          notes: offer.notes
        };
      });
    }
  }
}
</script>
<style scoped>
  @media (max-width: 960px) {
    .p-column-title {
      display: block;
      width: 100%;
    }
    .daytime-value {
      text-align: right;
      text-wrap: nowrap;
    }
  }
</style>
