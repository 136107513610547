import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "form-group col-sm-12"
}
const _hoisted_4 = {
  key: 0,
  class: "simple-list"
}
const _hoisted_5 = { class: "form-group col-sm-6" }
const _hoisted_6 = { class: "modal-footer-body" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["onClick", "disabled"]
const _hoisted_9 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "offerResponseAcceptValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "offer-response-accept",
        ref: "offerResponseAccept",
        centered: true,
        closeButton: false
      }, _createSlots({
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('confirm_that_you_want')), 1)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-secondary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeModal())),
              disabled: _ctx.isLoadingAllocation || _ctx.isRespondingOffer
            }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_7),
            _createElementVNode("button", {
              class: "btn btn-success",
              onClick: _withModifiers(($event: any) => (handleSubmit(_ctx.confirmChanges)), ["prevent"]),
              disabled: _ctx.isLoadingAllocation || _ctx.isRespondingOffer
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('confirm_and_save_response')) + " ", 1),
              (_ctx.isRespondingOffer)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-spin",
                      icon: ['far', 'spinner-third']
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_8)
          ])
        ]),
        _: 2
      }, [
        (!_ctx.editState)
          ? {
              name: "body",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ]),
              key: "0"
            }
          : {
              name: "body",
              fn: _withCtx(() => [
                (_ctx.offerResponseErrorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.offerResponseErrorMessage), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, [
                  _createElementVNode("span", {
                    innerHTML: _ctx.$t('donor_exceptional_distribution_confirmation')
                  }, null, 8, _hoisted_2)
                ]),
                (_ctx.getExdReasonList)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "p-label" }, "Reasons for Exceptional Distribution", -1)),
                      (_ctx.getExdReasonList)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getExdReasonList, (reason, index) => {
                              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(reason), 1))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_checkbox_input, {
                    rules: "required|must_be_checked",
                    "input-id": "exceptional_distribution",
                    modelValue: _ctx.editState.exceptional_distribution,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.exceptional_distribution) = $event)),
                    labelName: _ctx.$t('accept_exceptional_distribution'),
                    label: "Yes"
                  }, null, 8, ["modelValue", "labelName"])
                ]),
                _createVNode(_component_select_other_input, {
                  rules: "required",
                  selectId: "exd_reason_codes",
                  name: _ctx.$t('reason'),
                  modelValue: _ctx.editState.exd_reason_code,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.exd_reason_code) = $event)),
                  options: _ctx.exdAcceptanceReasons,
                  colStyling: "form-group selectWithOther col-12",
                  otherTitle: _ctx.$t('other'),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearExdAcceptanceOther()))
                }, {
                  other: _withCtx(() => [
                    _createVNode(_component_text_input, {
                      rules: "required",
                      "input-id": "exd_reason_other",
                      name: _ctx.$t('other'),
                      modelValue: _ctx.editState.exd_reason_other,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.exd_reason_other) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["name", "modelValue", "options", "otherTitle"])
              ]),
              key: "1"
            }
      ]), 1536)
    ]),
    _: 1
  }, 512))
}