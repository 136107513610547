import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group standard-form-group-checkbox" }
const _hoisted_4 = { class: "standard-form-group col-12 col-md-8 col-lg-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_save_toolbar_top = _resolveComponent("proto-save-toolbar-top")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_proto_save_toolbar_top, {
      class: "save-toolbar action-row mb-0 temp-saving d-flex",
      showCancelCreateClass: "btn btn-sm btn-wide mb-3 btn-secondary ml-auto",
      cancelCreateText: _ctx.isNew ? "Cancel Create New Item" : "Close Item Details",
      showCancelCreateButton: "true",
      onCancel: _ctx.cancelItem
    }, null, 8, ["cancelCreateText", "onCancel"]),
    _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("fieldset", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_text_input, {
            "input-id": "item-name",
            name: "Item Name",
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            rules: "required"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_checkbox_input, {
            name: "external",
            labelName: "External",
            inputId: "external",
            modelValue: _ctx.external,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.external) = $event)),
            label: "Yes"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_select_input, {
            "select-id": "checklistGroup",
            "aria-label": "Item Status",
            "undefined-text": "Select",
            options: _ctx.statusOptions,
            name: "Item Status",
            modelValue: _ctx.status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.status) = $event)),
            onChange: _ctx.handleStatusChange
          }, null, 8, ["options", "modelValue", "onChange"])
        ])
      ])
    ]),
    _createVNode(_component_save_toolbar, {
      class: "save-toolbar action-row mb-0 temp-saving d-md-flex",
      buttonClass: "btn btn-success sec-submit",
      label: "Save Item Details",
      showSaveAndCreate: _ctx.isNew,
      saveAndCreateText: `Save & Create New Item`,
      showCancelButton: "true",
      cancelButtonText: "Cancel",
      onCancel: _ctx.clearForm,
      onSaveAndCreate: _ctx.saveAndCreate,
      onSave: _ctx.saveNewItem,
      show: true,
      cancelButton: true,
      ref: "protoTaskForm"
    }, null, 8, ["showSaveAndCreate", "onCancel", "onSaveAndCreate", "onSave"])
  ]))
}