import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_3 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "legend-title" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-full-width-group" }
const _hoisted_8 = { for: "link-to-recipient-search" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "text" }
const _hoisted_11 = {
  key: 0,
  class: "hint"
}
const _hoisted_12 = {
  key: 1,
  class: "invalid-feedback"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "legend-title" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "standard-form-group" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = { class: "standard-form-group" }
const _hoisted_20 = { class: "standard-form-group" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "standard-form-group" }
const _hoisted_23 = { class: "standard-form-group" }
const _hoisted_24 = { class: "standard-form-group" }
const _hoisted_25 = { class: "standard-form-group-large" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "legend-title" }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "standard-form-group" }
const _hoisted_30 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "link-to-recipient",
    ref: "saveLinkToRecipient",
    "save-button-text": _ctx.$t('save_link_to_recipient_button'),
    "lookups-to-load": _ctx.lookupsToLoad,
    "save-button": !_ctx.newLivingDonor,
    disabled: _ctx.isDisabled,
    onSave: _cache[14] || (_cache[14] = ($event: any) => (_ctx.savePatch()))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('link_to_recipient_header')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "link-to-recipient-history",
        title: _ctx.$t('link_to_recipient_history_title'),
        disabled: _ctx.isDisabled,
        "tabbable-value": _ctx.$t('link_date_column'),
        "table-config": _ctx.linkToRecipientTableConfig,
        "is-parent-loading": !_ctx.editState,
        onTableRowClick: _ctx.onTableRowClick,
        onTableCreateRow: _ctx.onTableCreateRow
      }, {
        contents: _withCtx(() => [
          (_ctx.editState)
            ? (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
                _createElementVNode("legend", null, [
                  (!_ctx.selectedIntendedRecipient)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_link_to_recipient_title')), 1))
                    : (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t('selected_link_to_recipient_title')), 1))
                ]),
                (_ctx.editState.recipientSearch)
                  ? (_openBlock(), _createElementBlock("fieldset", _hoisted_4, [
                      _createElementVNode("legend", null, [
                        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('recipient_search_title')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_validation_provider, {
                            vid: "link-to-recipient-search",
                            class: "generic-tags"
                          }, {
                            default: _withCtx(({ errors }) => [
                              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('link_to_recipient_search_field')), 1),
                              _createVNode(_component_vue_tags_input, {
                                id: "link-to-recipient-search",
                                class: _normalizeClass({ 'select-multi': true, 'is-invalid': errors[0] }),
                                modelValue: _ctx.editState.recipientSearch.searchTerm,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.recipientSearch.searchTerm) = $event)),
                                tags: _ctx.editState.recipientSearch.tags,
                                "add-on-key": [13],
                                "autocomplete-items": _ctx.recipientSearchOptions,
                                "max-tags": 1,
                                "autocomplete-min-length": 1,
                                placeholder: _ctx.recipientSearchPlaceholder,
                                onTagsChanged: _ctx.onTagsChanged,
                                onBeforeAddingTag: _ctx.onBeforeAddingTag,
                                "add-only-from-autocomplete": true,
                                disabled: _ctx.isDisabled || !!_ctx.selectedIntendedRecipient
                              }, {
                                "autocomplete-item": _withCtx((props) => [
                                  _createElementVNode("div", {
                                    onClick: ($event: any) => (props.performAdd(props.item))
                                  }, [
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(props.item.text), 1),
                                    (props.item.hint)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(props.item.hint), 1))
                                      : _createCommentVNode("", true)
                                  ], 8, _hoisted_9)
                                ]),
                                _: 2
                              }, 1032, ["class", "modelValue", "tags", "autocomplete-items", "placeholder", "onTagsChanged", "onBeforeAddingTag", "disabled"]),
                              (_ctx.isLoadingRecipientList)
                                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                    key: 0,
                                    style: {"float":"right","top":"-26px","position":"relative","right":"24px","margin-bottom":"-26px"},
                                    icon: ['far', 'spinner-third'],
                                    "fixed-width": "",
                                    spin: "",
                                    "aria-hidden": "true"
                                  }))
                                : _createCommentVNode("", true),
                              (errors[0])
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['far', 'exclamation-circle'],
                                      "fixed-width": ""
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, null)), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                (_ctx.editState.recipientDetails)
                  ? (_openBlock(), _createElementBlock("fieldset", _hoisted_13, [
                      _createElementVNode("legend", null, [
                        _createElementVNode("h5", _hoisted_14, _toDisplayString(_ctx.$t('recipient_details_title')), 1)
                      ]),
                      (_ctx.isLoadingSelectedRecipient)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "row skeleton-padding-text" }, [
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ])
                            ], -1)),
                            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "row skeleton-padding-text" }, [
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ]),
                              _createElementVNode("div", { class: "standard-form-group-large" }, [
                                _createElementVNode("span", { class: "skeleton-box w-50" }),
                                _createElementVNode("span", {
                                  class: "skeleton-box w-100",
                                  style: {"height":"30px"}
                                })
                              ])
                            ], -1))
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createVNode(_component_date_input, {
                                  "input-id": "link-to-recipient-date",
                                  name: _ctx.$t('link_date_field'),
                                  modelValue: _ctx.editState.recipientDetails.linkDate,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.recipientDetails.linkDate) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-tgln-id",
                                  name: _ctx.$t('recipient_id_field'),
                                  modelValue: _ctx.editState.recipientDetails.recipientId,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.recipientDetails.recipientId) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-first-name",
                                  name: _ctx.$t('recipient_first_name_field'),
                                  modelValue: _ctx.editState.recipientDetails.firstName,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.recipientDetails.firstName) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_19, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-last-name",
                                  name: _ctx.$t('recipient_last_name_field'),
                                  modelValue: _ctx.editState.recipientDetails.lastName,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.recipientDetails.lastName) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_20, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-sex",
                                  name: _ctx.$t('recipient_sex_field'),
                                  modelValue: _ctx.editState.recipientDetails.sex,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.recipientDetails.sex) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-date-of-birth",
                                  name: _ctx.$t('recipient_date_of_birth_field'),
                                  modelValue: _ctx.editState.recipientDetails.dateOfBirth,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.recipientDetails.dateOfBirth) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-insurance",
                                  name: _ctx.$t('recipient_insurance_number_field'),
                                  modelValue: _ctx.editState.recipientDetails.insuranceNumber,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.recipientDetails.insuranceNumber) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_24, [
                                _createVNode(_component_text_input, {
                                  "input-id": "link-to-recipient-organization",
                                  name: _ctx.$t('recipient_referring_organization_field'),
                                  modelValue: _ctx.editState.recipientDetails.referringOrganizationName,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.recipientDetails.referringOrganizationName) = $event)),
                                  disabled: true
                                }, null, 8, ["name", "modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_25, [
                                _createVNode(_component_select_input, {
                                  "select-id": "link-to-recipient-donor-relationship",
                                  name: _ctx.$t('recipient_donor_relationship_field'),
                                  modelValue: _ctx.editState.recipientDetails.donorRelationshipToRecipient,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.recipientDetails.donorRelationshipToRecipient) = $event)),
                                  options: _ctx.relationshipLookup,
                                  rules: "required"
                                }, null, 8, ["name", "modelValue", "options"])
                              ])
                            ])
                          ], 64))
                    ]))
                  : _createCommentVNode("", true),
                _cache[18] || (_cache[18] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                (_ctx.editState.unlinkRecipient)
                  ? (_openBlock(), _createElementBlock("fieldset", {
                      key: 2,
                      disabled: _ctx.isUnlinkAreaDisabled
                    }, [
                      _createElementVNode("legend", null, [
                        _createElementVNode("h5", _hoisted_27, _toDisplayString(_ctx.$t('unlink_recipient_title')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createVNode(_component_checkbox_input, {
                            "input-id": "link-to-recipient-unlink",
                            "label-name": _ctx.$t("unlink_field"),
                            label: _ctx.$t("yes"),
                            modelValue: _ctx.editState.unlinkRecipient.unlinkDonorAndRecipient,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.unlinkRecipient.unlinkDonorAndRecipient) = $event)),
                            onChange: _ctx.onUnlinkChanged,
                            disabled: _ctx.isUnlinkFieldDisabled
                          }, null, 8, ["label-name", "label", "modelValue", "onChange", "disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_date_input, {
                            "input-id": "link-to-recipient-remove-date",
                            name: _ctx.$t('remove_date_field'),
                            disabled: true,
                            modelValue: _ctx.editState.unlinkRecipient.removeDate,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.unlinkRecipient.removeDate) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createVNode(_component_select_other_input, {
                          "select-id": "link-to-recipient-reason-for-unlinking",
                          "col-styling": "standard-form-group-with-other",
                          rules: "required_if:@unlinkDonorAndRecipient",
                          "cross-values": { unlinkDonorAndRecipient: _ctx.editState.unlinkRecipient.unlinkDonorAndRecipient },
                          name: _ctx.$t("reason_for_unlinking_field"),
                          "other-title": _ctx.$t("reason_for_unlinking_other_field"),
                          options: _ctx.livingDonorUnlinkReasonsLookup,
                          modelValue: _ctx.editState.unlinkRecipient.reasonForUnlinking,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.unlinkRecipient.reasonForUnlinking) = $event)),
                          disabled: _ctx.isReasonForUnlinkingDisabled
                        }, {
                          other: _withCtx(() => [
                            _createVNode(_component_text_input, {
                              rules: "required_if:@isOtherSelected",
                              "cross-values": { isOtherSelected: _ctx.isReasonForUnlinkingOther },
                              "input-id": "link-to-recipient-reason-if-other",
                              name: _ctx.$t("reason_for_unlinking_other_field"),
                              modelValue: _ctx.editState.unlinkRecipient.reasonIfOther,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.unlinkRecipient.reasonIfOther) = $event))
                            }, null, 8, ["cross-values", "name", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["cross-values", "name", "other-title", "options", "modelValue", "disabled"])
                      ])
                    ], 8, _hoisted_26))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "disabled", "tabbable-value", "table-config", "is-parent-loading", "onTableRowClick", "onTableCreateRow"])
    ]),
    _: 1
  }, 8, ["save-button-text", "lookups-to-load", "save-button", "disabled"]))
}