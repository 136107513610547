import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "administration-ctr",
    onLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => _cache[3] || (_cache[3] = [
      _createTextVNode(" CTR Event Log ")
    ])),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        mode: "remote",
        title: _ctx.$t('ctr_sync_events'),
        "sub-section-id": "administration-ctr-events",
        tabbableValue: "Date",
        "total-records": _ctx.getCtrCount,
        "table-config": _ctx.ctrIntegrationLogTableConfig(),
        onOnPageChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePagination($event))),
        onOnPerPageChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePagination($event)))
      }, {
        "table-cell": _withCtx((props) => [
          (props.column.field == 'entityId')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (props.row.link)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "table-link",
                      to: { name: props.row.link, params: { id: props.row.entityId } },
                      title: `Link to ${props.row.type} Page`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.formattedRow[props.column.field]), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "title"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.formattedRow[props.column.field]), 1))
              ], 64))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.formattedRow[props.column.field]), 1))
        ]),
        _: 1
      }, 8, ["title", "total-records", "table-config"])
    ]),
    _: 1
  }))
}