<template>
  <div>
    <loading-profile-page v-if="!dispatchEventsComplete" />
    <div v-else-if="editState">
      <validation-observer ref="validations" autocomplete="off" tag="form">
      <card-section
        ref="userProfile"
        section-id="user-profile"
        :lookups-to-load="lookupsToLoad"
        @loaded="loaded()"
        >
        <template v-slot:header v-if="editState && editState.user">
          {{ $t("user_profile") }}
        </template>
        <template v-slot:body v-if="editState && editState.user">
            <fieldset id="administration-user-account">
              <legend>
                <h4 class="legend-title">
                  {{ $t("personal_information") }}
                </h4>
              </legend>
              <div class="row">
                <div class="col-12">
                  <dl class="row">
                    <dt class="standard-form-group">{{ $t('name') }}</dt>
                    <dd class="standard-form-group-large">{{ getUserFullName }}</dd>
                  </dl>
                  <dl class="row">
                    <dt class="standard-form-group">{{ $t('email') }}</dt>
                    <dd class="standard-form-group-large">{{ editState.user.email }} </dd>
                  </dl>
                  <dl class="row">
                    <dt class="standard-form-group">{{ $t('roles') }}</dt>
                    <dd class="standard-form-group-large" v-html='getRoleNames'/>
                  </dl>
                  <dl class="row" v-if="editState.user.effective_date">
                    <dt class="standard-form-group">{{ $t('access_started') }}</dt>
                    <dd class="standard-form-group-large">{{ parseDisplayDateUi(editState.user.effective_date) }}</dd>
                  </dl>
                </div>
              </div>

            </fieldset>

            <fieldset id="administration-user-notifications">
              <legend>
                <h4 class="legend-title">
                  {{ $t("manage_delivery_mechanisms") }}
                </h4>
              </legend>
              <p>{{ $t("manage_delivery_methods_instructions")}}</p>
              <div class="row">
                <div class="standard-form-group-6column-large-up">
                  <text-input
                    input-id="cell"
                    :name="$t('cell_number')"
                    :mask="getTelephoneMask"
                    v-model="editState.user.sms"
                  />
                </div>
              </div>
              <p>{{ $t("manage_delivery_methods_note") }}</p>
            </fieldset>
      </template>
    </card-section>
    <card-section
      ref="saveNotifications"
      section-id="user-notifications"
      v-if="editState && editState.user && editState.user.notification_options"
      >
      <template v-slot:header>
        {{ $t("notifications") }}
      </template>
      <template v-slot:body v-if="editState && editState.user">
          <form>
            <fieldset id="administration-user-notifications">
              <legend>
                <h4 class="legend-title">
                  {{ $t("manage_notifications") }}
                </h4>
              </legend>
              <p>{{$t("notifications_mechanisms_blank")}}</p> 
              <div class="table-responsive-md">

              <!-- notifications start -->
              <template v-if="!editState.user.not_grouped">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>
                        {{$t("notification")}}
                      </th>
                      <th v-if="showRequiredMethodsColumn">
                        {{$t("primary_mechanisms")}}
                        <br/>
                        <small><b class="text-red">*</b> {{ $t("select_at_least_one")}}</small>
                      </th>
                      <th v-if="showOptionalMethodsColumn">
                        {{$t("optional_mechanisms")}}
                      </th>
                    </tr>
                  </thead>

                  <!-- group -->
                  <tbody v-for="(entry, index) in Object.entries(editState.user.notification_options_grouped)" :key="`${index}_notification_group`">
                    <tr>
                      <th colspan="2">
                        {{$t(entry[0])}}
                      </th>
                    </tr>

                    <!-- build inputs for communication mechanisms roles give them -->
                    <tr v-for="(channel, index) in editState.user.notification_lookup" :key="channel.code">
                      <template v-if="channel.sub_group == entry[0]">
                        <!-- show channel name -->
                        <td v-html="$t(channel.value)" />
                        <td v-if="showRequiredMethodsColumn">
                          <!-- show required mechanisms -->
                          <div v-for="(mechanism) in channel.required_delivery_mechanisms" :key="mechanism">
                            <!-- only show communication mechanisms user has -->
                            <checkbox-input
                              class="full-width"
                              :input-id="`user-notifications-required-${channel.code}-${mechanism}`"
                              v-model="editState.user.notification_options[index].required_delivery_mechanisms[mechanism]"
                              :label='$t(mechanism)'
                            />
                          </div>
                        </td>
                        <td v-if="showOptionalMethodsColumn">
                          <!-- show optional mechanisms -->
                          <div v-for="(mechanism) in channel.optional_delivery_mechanisms" :key="mechanism">
                            <!-- only show communication mechanisms user has -->
                            <checkbox-input
                              class="full-width"
                              :input-id="`user-notifications-optional-${channel.code}-${mechanism}`"
                              v-model="editState.user.notification_options[index].optional_delivery_mechanisms[mechanism]"
                              :label='$t(mechanism)'
                            />
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>

                </table>
              </template>
              <!-- notifications end -->

              <!-- notifications start -->
              <template v-else>
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>
                        {{$t("notification")}}
                      </th>
                      <th v-if="showRequiredMethodsColumn">
                        {{$t("primary_mechanisms")}}
                        <br/>
                        <small><b class="text-red">*</b> {{ $t("select_at_least_one") }}</small>
                      </th>
                      <th v-if="showOptionalMethodsColumn">
                        {{$t("optional_mechanisms")}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- build inputs for communication mechanisms roles give them -->
                    <tr v-for="(channel, index) in editState.user.notification_lookup" :key="index">
                      <template v-if="channel && editState.user.notification_options[index]">
                        <!-- show channel name -->
                        <td v-html="$t(channel.value)" />
                        <td v-if="showRequiredMethodsColumn">
                          <!-- show required mechanisms -->
                          <div v-for="(mechanism) in channel.required_delivery_mechanisms" :key="mechanism">
                            <!-- only show communication mechanisms user has -->
                            <checkbox-input
                              class="full-width"
                              :input-id="`user-notifications-required-${channel.code}`"
                              v-model="editState.user.notification_options[index].required_delivery_mechanisms[mechanism]"
                              :label='$t(mechanism)'
                            />
                          </div>
                        </td>
                        <td v-if="showOptionalMethodsColumn">
                          <!-- show optional mechanisms -->
                          <div v-for="(mechanism) in channel.optional_delivery_mechanisms" :key="mechanism">
                            <!-- only show communication mechanisms user has -->
                            <checkbox-input
                              class="full-width"
                              :input-id="`user-notifications-optional-${channel.code}-${mechanism}`"
                              v-model="editState.user.notification_options[index].optional_delivery_mechanisms[mechanism]"
                              :label='$t(mechanism)'
                            />
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </template>
              <!-- notifications end -->

              </div>
            </fieldset>
          </form>
        </template>
      </card-section>

      <div class="form-btns" v-if="editState && editState.user">
        <save-toolbar
          ref="userProfileToolbar"
          :label="$t('save_profile')"
          @save="performSave()"
        />
      </div>
      </validation-observer>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { Getter, State } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import { SaveResult } from '@/types';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Hospital } from '@/store/hospitals/types';
import { UserDetails, UserList } from '@/store/userAccounts/types';
import { ObjectId } from '@/store/types';
import { UserCoordinator } from '@/store/users/types';
import { NotificationDeliveryMechanism, NotificationSubscription, NotifiableEventChannel, NotificationOption } from "@/store/users/types";
import LoadingProfilePage from '@/components/shared/loading-skeletons/LoadingProfilePage.vue';
import { NotificationSubGroups } from '@/store/lookups/types';
import { i18nMessages } from "@/i18n";

interface UserRow {
  _id?: string|null,
  oauth2_user_identifier?: string|null,
  first_name?: string|null,
  last_name?: string|null,
  role_names?: string|null,
  effective_date?: string|null,
  expiry_date?: string|null;
}

interface UserForm {
  _id?: string|null,
  oauth2_user_identifier?: string|null,
  first_name?: string|null,
  last_name?: string|null,
  role_names?: string[]|null,
  coordinator_id?: string|null;
  coordinator?: UserCoordinator|null;
  responsible_physician_id?: string|null;
  responsible_physician?: UserCoordinator|null;
  surgical_person_id?: string|null,
  effective_date?: string|null,
  expiry_date?: string|null,
  notification_delivery_mechanisms?: NotificationDeliveryMechanism[],
  notification_subscriptions?: NotificationSubscription[],
  notification_lookup?: NotifiableEventChannel[],
  notification_options?: NotificationOption[],
  notification_options_grouped?: any,
  email?: string|null;
  sms?: string|null;
  pager?: string|null;
  dashboard?: string|null;
  not_grouped?: boolean|undefined;
}

@Component({
  components: {
    SaveToolbar,
    CardSection,
    SubSection,
    TextInput,
    SelectInput,
    CheckboxInput,
    DateInput,
    LoadingProfilePage
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/_locales/ManageAccount.json'),
    require('../_locales/NotificationSubscriptions.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class UserProfile extends mixins(DateUtilsMixin, ValidationUtilsMixin) {
  @State(state => state.userAccounts.selected) private selected!: UserDetails;
  @State(state => state.userAccounts.userList) private userList!: UserList;
  @State(state => state.pageState.currentPage.userAccounts) private editState!: any;
  @State(state => state.lookups.notifiable_event_channels) notifiableEventChannels!: NotifiableEventChannel[];

  @Getter('getUser', { namespace: 'users' }) private getUser!: any;
  @Getter('getSelected', { namespace: 'userAccounts' }) private getSelected!: UserDetails;
  @Getter('getRoles', { namespace: 'users' }) private getRoles!: [];
  @Getter('findNotificationChannelsFromLookupByRoles', { namespace: 'lookups' }) private findNotificationChannelsFromLookupByRoles!: (roles?: string[]) => NotifiableEventChannel[];
  @Getter('ontarioTransplantOptions', { namespace: 'hospitals' }) hospitalOptions!: any[];
  @Getter('getHospitalById', { namespace: 'hospitals' }) private getHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: { code: string; value: string, hospital_assignments: string[], expiry_date: string|undefined }[];  
  @Getter('surgicalPersonOptions', { namespace: 'surgicalPersons' }) surgicalPersonOptions!: { code: string; value: string, hospital_assignments: string[] }[];
  @Getter('getTelephoneMask', { namespace: 'utilities' }) getTelephoneMask!: string;

  private dispatchEventsComplete = false;

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = ['notifiable_event_channels', 'notification_channels'];

  private dirtyRoles = false;

  get getUserFullName(): string|undefined {
    if (!this.editState || !this.editState.user) return undefined;
    const full_name: string[] = [];
    if (this.editState.user.first_name) full_name.push(this.editState.user.first_name);
    if (this.editState.user.last_name) full_name.push(this.editState.user.last_name);
    return full_name ? full_name.join(' ') : undefined;
  }

  get getRoleNames(): string|undefined {
    if (!this.editState || !this.editState.user) return undefined;
    return this.editState.user.role_names ? this.editState.user.role_names.sort().join('<br> ') : undefined;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens administrationUsers#loaded
   * @emits loaded
   */
  private loaded(): void {
    this.$emit('loaded', 'userProfile');
    this.updateNotificationOptions();
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private mounted(): void {
    Promise.all([
      this.$store.dispatch('userAccounts/getProfile'),
      this.$store.dispatch('hospitals/load'),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
      this.resetToolbar();
      this.loadUserProfile();
    });
  }

  /**
   * Populates the User's profile into the form state.
   */
  private loadUserProfile(): void {
    const selected = this.getSelected;
    if (selected) {
      // Get user & build form state
      // Save it to the editState
      this.$store.commit('pageState/set', {
        pageKey: 'userAccounts',
        value: { user: this.buildAccountForm(selected) }
      });
      this.dirtyRoles = false;        
    }
  }

  // Modify the measurements to fit our format
  private buildAccountForm(userDetails: UserDetails): UserForm {
    this.resetValidationErrors();

    const id = userDetails._id ? userDetails._id : undefined;

    // get user account current settings along with lookup
    const notification_delivery_mechanisms = userDetails.notification_delivery_mechanisms || [];
    const notification_subscriptions = userDetails.notification_subscriptions;
    
    // get channels user has access to
    const notification_lookup = this.findNotificationChannelsFromLookupByRoles(userDetails.role_names || []);

    const user: UserForm = {
      _id: id,
      oauth2_user_identifier: userDetails.oauth2_user_identifier,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      role_names: userDetails.role_names,
      effective_date: this.parseDateUi(userDetails.effective_date),
      expiry_date: this.parseDateUi(userDetails.expiry_date),
      coordinator_id: userDetails.coordinator_id,
      coordinator: userDetails.coordinator,
      responsible_physician_id: userDetails.responsible_physician_id,
      responsible_physician: userDetails.responsible_physician,
      notification_delivery_mechanisms: notification_delivery_mechanisms,
      notification_subscriptions: notification_subscriptions,
      notification_lookup: notification_lookup,
      email: this.getDeliveryMechanism('email', notification_delivery_mechanisms),
      sms: this.getDeliveryMechanism('sms', notification_delivery_mechanisms),
      pager: this.getDeliveryMechanism('pager', notification_delivery_mechanisms),
      dashboard: this.getDeliveryMechanism('dashboard', notification_delivery_mechanisms)
    };

    return user;
  }

  private getDeliveryMechanism(key: string, notification_delivery_mechanisms: any): any {
    const details = notification_delivery_mechanisms.find((item: any) => { return item.mechanism == key; });
    return details ? details.contact_info : '';
  }

  private buildNotificationOptions(user: UserForm) :NotificationOption[] {
    const notification_subscriptions = user.notification_subscriptions || [];
    const notification_options: any[] = [];

    if (!(Array.isArray(user.notification_lookup))) return [];
    user.notification_lookup.map((channel: NotifiableEventChannel) => {
      const option: any = {
        code: channel.code,
        required_delivery_mechanisms: {}, // for required user inputs
        optional_delivery_mechanisms: {}, // for optional user inputs
      };
      // get default delivery mechanisms for channel
      const default_delivery_mechanisms = this.getDefaultDeliveryMechanism(channel.code);

      if (channel.required_delivery_mechanisms && channel.required_delivery_mechanisms.length > 0) {
        channel.required_delivery_mechanisms.map((mechanism) => {
          if (this.dirtyRoles) {
            // use default
            const found = default_delivery_mechanisms ? default_delivery_mechanisms.includes(mechanism) : false;
            option.required_delivery_mechanisms[mechanism] = found;
          } else {
            // get existing value
            const found = notification_subscriptions.find((item: any) => { return item.channel_code == channel.code; }) || null;
            option.required_delivery_mechanisms[mechanism] = found && found.required_delivery_mechanisms && found.required_delivery_mechanisms.includes(mechanism) ? true : false;
          }
        });
      }
      if (channel.optional_delivery_mechanisms && channel.optional_delivery_mechanisms.length > 0) {
        channel.optional_delivery_mechanisms.map((mechanism) => {
          if (this.dirtyRoles) {
            // use default
            const found = default_delivery_mechanisms ? default_delivery_mechanisms.includes(mechanism) : false;
            option.optional_delivery_mechanisms[mechanism] = found;
          } else {
            // get existing value
            const found = notification_subscriptions.find((item: any) => { return item.channel_code == channel.code; }) || null;
            option.optional_delivery_mechanisms[mechanism] = found && found.optional_delivery_mechanisms && found.optional_delivery_mechanisms.includes(mechanism) ? true : false;
          }
        });
      }

      option.sub_group = this.getNotificationSubGroupByCode(channel.code) == 'undefined' ? 'Z_UNGROUPED': this.getNotificationSubGroupByCode(channel.code);

      notification_options.push(option);
    });

    return notification_options;
  }

  private getDefaultDeliveryMechanism(code: number|undefined): string[] {
    const channels = this.notifiableEventChannels || [];
    if (!code || !channels) return [];
    const channel = channels.find((item: any) => { return item.code == code; });
    return channel && channel.default_delivery_mechanisms ? channel.default_delivery_mechanisms : [];
  }

  getNotificationSubGroupByCode(code: number|undefined): string|undefined {
    const subGroups = NotificationSubGroups as any;
    let subGroup = 'Z_UNGROUPED'; // default for items with no group

    for (const [key, value] of Object.entries(subGroups)) { 
      const a = value as any;
      if ( a.includes(code) ) {
        subGroup = key;
      }
    }

    return subGroup;
  }

  /**
   * Getter method to determine whether to show the Required Methods column
   *
   * Defaults to false
   * Checks to see if any of the notifications have any keys in the required_delivery_mechanisms
   * If any notification has a key in required_delivery_mechanisms then sets it to true
   */
  private get showRequiredMethodsColumn() : boolean {
    let showRequired = false;

    this.editState.user.notification_options.filter((notification: NotificationOption) => {
      const requiredDeliveryMechanisms = notification.required_delivery_mechanisms || {};
      if (Object.keys(requiredDeliveryMechanisms).length > 0) {
        showRequired = true;
      }
    });

    return showRequired;
  } 

  /**
   * Getter method to determine whether to show the Optional Methods column
   *
   * Defaults to false
   * Checks to see if any of the notifications have any keys in the optional_delivery_mechanisms
   * If any notification has a key in optional_delivery_mechanisms then sets it to true
   */
  private get showOptionalMethodsColumn() : boolean {
    let showOptional = false;

    this.editState.user.notification_options.filter((notification: NotificationOption) => {
      const optionalDeliveryMechanisms = notification.optional_delivery_mechanisms || {};
      if (Object.keys(optionalDeliveryMechanisms).length > 0) {
        showOptional = true;
      }
    });

    return showOptional;
  } 

  private updateNotificationOptions(): void {
    const notification_lookup = this.findNotificationChannelsFromLookupByRoles(this.editState.user.role_names || []);
    // Sort the notification lookup by the translated text value
    const sorted_notification_lookup = notification_lookup.sort((a :any, b: any) => {
      // if don't have an order use value
      if (a.order === null) {
        return this.$t(a.value).toString().localeCompare(this.$t(b.value).toString());
      // otherwise use order attribute
      } else {
        return this.$t(a.order).toString().localeCompare(this.$t(b.order).toString());
      }
    });

    /**
     * START: TEMPORARILY REMOVE PAGER AND ROBOCALL OPTIONS 
     * 
     * Implementation of pager and robocall notifications for surgical users and transplant coordinators will be after go live. 
     * Until these are implemented, these options should be hidden from the subscriptions page for these types of notifications.
     */
    const filtered_notification_lookup = sorted_notification_lookup.map((option: NotifiableEventChannel) => {
      const optional_delivery_mechanisms: string[] = [];
      if (!option || !option.optional_delivery_mechanisms) return null;
      option.optional_delivery_mechanisms?.forEach((mechanism: string) => {
        if (mechanism != 'robocall' && mechanism != 'pager') {
          optional_delivery_mechanisms.push(mechanism);
        }
      });

      const required_delivery_mechanisms: string[] = [];
      option.required_delivery_mechanisms?.forEach((mechanism: string) => {
        if (mechanism != 'robocall' && mechanism != 'pager') {
          required_delivery_mechanisms.push(mechanism);
        }
      });

      return {
        value: option.value,
        code: option.code,
        required_delivery_mechanisms: required_delivery_mechanisms,
        optional_delivery_mechanisms: optional_delivery_mechanisms,
        sub_group: this.getNotificationSubGroupByCode(option.code)
      };
    });
    this.editState.user.notification_lookup = filtered_notification_lookup;
    // END: TEMPORARILY REMOVE PAGER AND ROBOCALL OPTIONS 

    const notification_options = this.buildNotificationOptions(this.editState.user);
    this.editState.user.notification_options = notification_options;

    // generate grouping for notifications

    // group array by sub_group name
    const notification_options_grouped = (notification_options as any).reduce(function (r: any, a: any) {
      r[a.sub_group] = r[a.sub_group] || [];
      r[a.sub_group].push(a);
    return r;
    }, Object.create(null));

   const notification_options_grouped_and_sorted = Object.keys(notification_options_grouped)
    .sort()
    .reduce((acc, key) => ({
        ...acc, [key]: notification_options_grouped[key]
    }), {});

    // store result into notification_options_grouped 
    this.editState.user.notification_options_grouped = notification_options_grouped_and_sorted;

    // check for groups
    if (Object.entries(notification_options_grouped_and_sorted).length > 0) {
      const not_grouped = Object.entries(notification_options_grouped_and_sorted)[0][0] == 'undefined';
      this.editState.user.not_grouped = not_grouped;
    } else {
      const not_grouped = true;
      this.editState.user.not_grouped = not_grouped;
    }

  }

  private get extractSubscriptionChoices() : NotificationSubscription[] {
    if (!this.editState.user.notification_options) return [];

    const newSubscriptions: any[] = [];

    this.editState.user.notification_options.map((item: any) => {
      if (item) {
        const sub: any = {
          channel_code: item.code,
          paused: null, 
          effective_date: null, 
          expiry_date: null
        };

        const required_delivery_mechanisms: string[] = [];
        for (const [key, value] of Object.entries(item.required_delivery_mechanisms)) {
          if (value) { required_delivery_mechanisms.push(key); }
        }

        const optional_delivery_mechanisms: string[] = [];
        for (const [key, value] of Object.entries(item.optional_delivery_mechanisms)) {
          if (value) { optional_delivery_mechanisms.push(key); }
        }

        // we pull from required_delivery_mechanisms & optional_delivery_mechanisms but we push to delivery_mechanisms
        sub.delivery_mechanisms = [...required_delivery_mechanisms, ...optional_delivery_mechanisms];

        newSubscriptions.push(sub);
      }
    });

    return newSubscriptions;
  }

  private get extractDetailsMechanisms(): any[] {
    const mechanisms = [];
    mechanisms.push({mechanism: 'email', contact_info: this.editState.user.email});
    mechanisms.push({mechanism: 'sms', contact_info: this.editState.user.sms});
    mechanisms.push({mechanism: 'pager', contact_info: this.editState.user.pager});
    mechanisms.push({mechanism: 'dashboard', contact_info: this.editState.user.dashboard});    
    return mechanisms;
  }

  // Returns a RecipientMeasurement patch request payload or null if the measurement edit state is empty
  public extractPatch(): UserDetails|null {
    const oauthId = this.editState.user.oauth2_user_identifier ? this.editState.user.oauth2_user_identifier.replace(/\s/g, '') : null;

    const result = {
      _id: this.editState.user._id,
      oauth2_user_identifier: oauthId,
      first_name: this.editState.user.first_name,
      last_name: this.editState.user.last_name,
      role_names: this.editState.user.role_names,
      coordinator_id: this.editState.user.coordinator_id,
      surgical_person_id: this.editState.user.surgical_person_id,
      effective_date: this.sanitizeDateApi(this.editState.user.effective_date),
      expiry_date: this.sanitizeDateApi(this.editState.user.expiry_date),
      notification_delivery_mechanisms: this.extractDetailsMechanisms,
      notification_subscriptions: this.extractSubscriptionChoices
    };

    return result;
  }

  /**
   * Saves the User section (selected or new item)
   *
   */
  private performSave() {
    // Refer to the save provider that handles this form area
    const saveToolbar = this.$refs.userProfileToolbar as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Generate payload based on current edit state
    const userPayload = {
      clientId: this.editState.user._id,
      userDetails: this.extractPatch()
    };
    // Clear previous errors
    this.resetValidationErrors();
    // Dispatch save action and register the response
    this.$store.dispatch('userAccounts/saveProfile', userPayload).then((success: SaveResult) => {
      this.$store.commit('userAccounts/set', success.responseData.profile);
      this.registerSaveResult(success); // and load the user profile
    }).catch((error: SaveResult) => {      
      this.registerSaveResult(error);
    });
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    const validations = this.$refs.validations as any;
    if (validations) { validations.setErrors({}); }
  }

  private resetToolbar() {
    const saveToolbar = this.$refs.userProfileToolbar as SaveToolbar;
    if (saveToolbar) { saveToolbar.reset(); }
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditRecipient updates the currently selected recipient), zero records, or multiple records.
     */
    if (result.success) {
      this.loadUserProfile();
      this.updateNotificationOptions();
    } else {
      // Handle errors
      this.handleErrors(result);
    }

    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.userProfileToolbar as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup = this.idLookup;

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }

  public get idLookup(): IdLookup {
    const mapping: { [key: string]: string } = {
      'cell'                      : 'cell',
      'pager'                     : 'pager',
    };

    this.notifiableEventChannels.forEach((item: any) => {
        mapping[`notification_subscriptions[${item.code}].delivery_mechanisms`] = `user-notifications-required-${item.code}`;
    });

    return mapping;
  }
}
</script>
