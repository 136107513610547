import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group-large" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_6 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_9 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_10 = {
  key: 3,
  class: "standard-form-group"
}
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = {
  key: 4,
  class: "standard-form-group"
}
const _hoisted_14 = {
  key: 5,
  class: "standard-form-group"
}
const _hoisted_15 = {
  key: 6,
  class: "standard-form-group"
}
const _hoisted_16 = {
  key: 0,
  class: "serology-reponsive"
}
const _hoisted_17 = { class: "table table-bordered table-hover" }
const _hoisted_18 = { class: "form-radio" }
const _hoisted_19 = ["for"]
const _hoisted_20 = { class: "sr-only" }
const _hoisted_21 = ["id", "value", "onUpdate:modelValue"]
const _hoisted_22 = { class: "form-radio" }
const _hoisted_23 = ["for"]
const _hoisted_24 = { class: "sr-only" }
const _hoisted_25 = ["id", "value", "onUpdate:modelValue"]
const _hoisted_26 = { key: 0 }
const _hoisted_27 = ["colspan"]
const _hoisted_28 = ["disabled"]
const _hoisted_29 = {
  key: 1,
  class: "row"
}
const _hoisted_30 = {
  key: 0,
  class: "check-markers"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["id", "onUpdate:modelValue"]
const _hoisted_33 = { class: "form-check-label form-check-label-main" }
const _hoisted_34 = {
  key: 0,
  class: "check-marker-display"
}
const _hoisted_35 = {
  key: 0,
  class: "check-toggle d-block"
}
const _hoisted_36 = {
  key: 1,
  class: "check-toggle d-block"
}
const _hoisted_37 = { key: 2 }
const _hoisted_38 = ["for"]
const _hoisted_39 = ["placeholder", "id", "onUpdate:modelValue"]
const _hoisted_40 = {
  key: 0,
  class: "check-markers"
}
const _hoisted_41 = ["onClick"]
const _hoisted_42 = ["id", "onUpdate:modelValue"]
const _hoisted_43 = { class: "form-check-label" }
const _hoisted_44 = {
  key: 0,
  class: "check-marker-display"
}
const _hoisted_45 = {
  key: 0,
  class: "check-toggle d-block"
}
const _hoisted_46 = {
  key: 1,
  class: "check-toggle d-block"
}
const _hoisted_47 = {
  key: 2,
  class: "virology-comments"
}
const _hoisted_48 = ["for"]
const _hoisted_49 = ["placeholder", "id", "onUpdate:modelValue"]
const _hoisted_50 = {
  key: 3,
  class: "col-sm-12 col-md-6 col-lg-4 viralogy-section"
}
const _hoisted_51 = { class: "check-markers virology-codes-additional" }
const _hoisted_52 = { for: `virology-codes-secondary` }
const _hoisted_53 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_boolean_radio_input = _resolveComponent("boolean-radio-input")!
  const _component_radio_group = _resolveComponent("radio-group")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    ref: "saveVirology",
    onLoaded: _cache[19] || (_cache[19] = ($event: any) => (_ctx.loaded())),
    onSave: _cache[20] || (_cache[20] = ($event: any) => (_ctx.savePatch())),
    "section-id": "virology",
    saveButton: !_ctx.newRecord || (_ctx.canSave && !_ctx.donorFromItransplant && !_ctx.newRecord),
    lookupsToLoad: _ctx.lookupsToLoad,
    saveButtonText: _ctx.$t(`save-${_ctx.virologyOrSerology}-results`),
    "laboratories-to-load": _ctx.laboratoriesToLoad
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.virologyOrSerology}_results`)), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_validation_observer, null, {
        default: _withCtx(() => [
          (_ctx.isLivingDonor)
            ? (_openBlock(), _createBlock(_component_sub_section, {
                key: 0,
                title: _ctx.$t('virology_comments'),
                ref: "saveVirologyComments",
                onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveCommentsPatch())),
                "sub-section-id": "ld-virology-comments",
                saveButton: !_ctx.newRecord,
                disabled: !_ctx.canSave || _ctx.donorFromItransplant,
                saveButtonText: _ctx.$t('save_comments')
              }, {
                contents: _withCtx(() => [
                  (_ctx.editState)
                    ? (_openBlock(), _createElementBlock("fieldset", {
                        key: 0,
                        disabled: !_ctx.canSave
                      }, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_text_area_input, {
                              name: _ctx.$t('comments'),
                              inputId: "living-donor-virology-comments",
                              modelValue: _ctx.editState.virologyComments,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.virologyComments) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ])
                      ], 8, _hoisted_1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["title", "saveButton", "disabled", "saveButtonText"]))
            : _createCommentVNode("", true),
          _createVNode(_component_sub_section, {
            title: _ctx.$t(`${_ctx.virologyOrSerology}_results`),
            "sub-section-id": "virology-results",
            tabbableValue: _ctx.getTabbableValue,
            onTableCreateRow: _cache[17] || (_cache[17] = ($event: any) => (_ctx.createVirologyResult())),
            "table-config": _ctx.virologyResultsTableConfig,
            onTableRowClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.selectVirologyResult($event)))
          }, {
            contents: _withCtx(() => [
              (_ctx.editState)
                ? (_openBlock(), _createElementBlock("fieldset", {
                    key: 0,
                    disabled: !_ctx.canSave || _ctx.donorFromItransplant
                  }, [
                    _createElementVNode("legend", null, [
                      (!_ctx.selectedVirology)
                        ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t(`new_${_ctx.virologyOrSerology}_results`)), 1))
                        : (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_ctx.$t(`selected_${_ctx.virologyOrSerology}_results`)), 1))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      (!_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createBlock(_component_select_other_input, {
                            key: 0,
                            "select-id": "lab_code",
                            ruleKey: "virology_labs.lab_code",
                            name: _ctx.$t(`lab_code`),
                            valueKey: "lab_code",
                            textKey: "name",
                            rules: "required",
                            validationId: "lab_code",
                            modelValue: _ctx.editState.lab_code,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.lab_code) = $event)),
                            options: _ctx.laboratoryOptions,
                            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedLabCode($event))),
                            otherTitle: _ctx.$t(`lab_other`),
                            colStyling: "standard-form-group-with-other"
                          }, {
                            other: _withCtx(() => [
                              _createVNode(_component_text_input, {
                                inputId: "lab_other",
                                ruleKey: "virology_labs.lab_other",
                                validationId: "lab_other",
                                name: _ctx.$t(`lab_other`),
                                modelValue: _ctx.editState.lab_other,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.lab_other) = $event))
                              }, null, 8, ["name", "modelValue"])
                            ]),
                            _: 1
                          }, 8, ["name", "modelValue", "options", "otherTitle"]))
                        : _createCommentVNode("", true),
                      (!_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_date_input, {
                              ruleKey: "virology_labs.test_date",
                              inputId: "virology-results-test_date",
                              name: _ctx.$t(`test_date`),
                              modelValue: _ctx.editState.test_date,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.test_date) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_time_input, {
                              ruleKey: "virology_labs.test_time",
                              rules: "required",
                              inputId: "virology-results-test_time",
                              name: _ctx.$t(`test_time`),
                              modelValue: _ctx.editState.test_time,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.test_time) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_text_input, {
                              ruleKey: "virology_labs.test_code",
                              inputId: "virology-results-test_code",
                              name: _ctx.$t(`sample_identifier`),
                              modelValue: _ctx.editState.test_code,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.test_code) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_date_input, {
                          ruleKey: "virology_labs.sample_date",
                          inputId: "virology-results-sample_date",
                          name: _ctx.$t(`sample_date`),
                          modelValue: _ctx.editState.sample_date,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.sample_date) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_time_input, {
                          ruleKey: "virology_labs.sample_time",
                          inputId: "virology-results-sample_time",
                          name: _ctx.$t(`sample_time`),
                          crossValues: { deceasedDonor: _ctx.isDeceasedDonor },
                          modelValue: _ctx.editState.sample_time,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.sample_time) = $event))
                        }, null, 8, ["name", "crossValues", "modelValue"])
                      ]),
                      (_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_select_input, {
                              selectId: "virology-results-sample_type",
                              ruleKey: "virology_labs.sample_type",
                              name: _ctx.$t(`sample_type`),
                              modelValue: _ctx.editState.sample_type,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.sample_type) = $event)),
                              options: _ctx.sampleTypes
                            }, null, 8, ["name", "modelValue", "options"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_checkbox_input, {
                              inputId: "virology-results-from_mother",
                              ruleKey: "virology_labs.from_mother",
                              labelName: _ctx.$t(`from_mother`),
                              modelValue: _ctx.editState.from_mother,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.from_mother) = $event)),
                              label: _ctx.$t(`affirmative`)
                            }, null, 8, ["labelName", "modelValue", "label"])
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isDeceasedDonor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createVNode(_component_text_input, {
                              ruleKey: "virology_labs.sample_number",
                              inputId: "virology-results-sample_code",
                              name: _ctx.$t(`sample_code`),
                              modelValue: _ctx.editState.sample_number,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.sample_number) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                    (_ctx.isLivingDonorOrDeceasedDonor && _ctx.editState.results && _ctx.getVirologyCodesCombined)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("table", _hoisted_17, [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _cache[21] || (_cache[21] = _createElementVNode("td", null, null, -1)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.virologyResultOptions, (resultOption) => {
                                  return (_openBlock(), _createElementBlock("td", {
                                    key: `result-option-${resultOption.value}`,
                                    class: "td-center-align"
                                  }, _toDisplayString(_ctx.$t(resultOption.value)), 1))
                                }), 128))
                              ])
                            ]),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.results, (virusCode) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: virusCode.code
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(_ctx.getVirologyCombinedLabel(virusCode.code)), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.virologyResultOptions, (resultOption) => {
                                    return (_openBlock(), _createElementBlock("td", {
                                      key: `result-radio-${virusCode.code}-${resultOption.value}`,
                                      class: "td-radio"
                                    }, [
                                      _createElementVNode("div", _hoisted_18, [
                                        _createElementVNode("label", {
                                          for: `virology-results-${virusCode.code}-initial_result-${resultOption.value}`,
                                          class: "form-radio-label"
                                        }, [
                                          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('toggle_row')), 1),
                                          _withDirectives(_createElementVNode("input", {
                                            id: `virology-results-${virusCode.code}-initial_result-${resultOption.code}`,
                                            type: "radio",
                                            class: "form-radio-input",
                                            value: resultOption.code,
                                            "onUpdate:modelValue": ($event: any) => ((_ctx.editState.results[virusCode.code].initial_result) = $event)
                                          }, null, 8, _hoisted_21), [
                                            [_vModelRadio, _ctx.editState.results[virusCode.code].initial_result]
                                          ])
                                        ], 8, _hoisted_19)
                                      ])
                                    ]))
                                  }), 128))
                                ]))
                              }), 128)),
                              (_ctx.showSecondaryTestMarkers)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.secondary_results, (virusCode) => {
                                      return (_openBlock(), _createElementBlock("tr", {
                                        key: virusCode.code
                                      }, [
                                        _createElementVNode("td", null, _toDisplayString(virusCode.value), 1),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.virologyResultOptions, (resultOption) => {
                                          return (_openBlock(), _createElementBlock("td", {
                                            key: `result-radio-${virusCode.code}-${resultOption.value}`,
                                            class: "td-radio"
                                          }, [
                                            _createElementVNode("div", _hoisted_22, [
                                              _createElementVNode("label", {
                                                for: `virology-results-secondary_results-${virusCode.code}-additional-test-marker-${resultOption.value}`,
                                                class: "form-radio-label"
                                              }, [
                                                _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('toggle_row')), 1),
                                                _withDirectives(_createElementVNode("input", {
                                                  id: `virology-results-secondary-${virusCode.code}-additional-test-marker-${resultOption.code}`,
                                                  type: "radio",
                                                  class: "form-radio-input",
                                                  value: resultOption.code,
                                                  "onUpdate:modelValue": ($event: any) => (((_ctx.editState.secondary_results || {})[virusCode.code].initial_result) = $event)
                                                }, null, 8, _hoisted_25), [
                                                  [_vModelRadio, (_ctx.editState.secondary_results || {})[virusCode.code].initial_result]
                                                ])
                                              ], 8, _hoisted_23)
                                            ])
                                          ]))
                                        }), 128))
                                      ]))
                                    }), 128)),
                                    (_ctx.availableSecondaryResults.length > 0)
                                      ? (_openBlock(), _createElementBlock("tr", _hoisted_26, [
                                          _createElementVNode("td", null, _toDisplayString(_ctx.$t('virology-codes-secondary')), 1),
                                          _createElementVNode("td", {
                                            colspan: _ctx.virologyResultOptions.length
                                          }, [
                                            _createVNode(_component_select_input, {
                                              textKey: "value",
                                              name: _ctx.$t('additional_test_marker'),
                                              valueKey: "code",
                                              modelValue: _ctx.selectedSecondaryResult,
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedSecondaryResult) = $event)),
                                              options: _ctx.availableSecondaryResults,
                                              selectId: "virology-codes-secondary",
                                              hideLabel: true,
                                              class: "virology-codes-secondary-select"
                                            }, null, 8, ["name", "modelValue", "options"]),
                                            _createElementVNode("button", {
                                              type: "button",
                                              class: "btn btn-primary btn-sm virology-codes-secondary-button",
                                              onClick: _cache[14] || (_cache[14] = event => _ctx.addSecondaryResult(event)),
                                              disabled: !_ctx.availableSecondaryResults
                                            }, _toDisplayString(_ctx.$t('add')), 9, _hoisted_28)
                                          ], 8, _hoisted_27)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isLivingDonorOrDeceasedDonor)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          (_ctx.editState.results && _ctx.getVirologyCodesCombined)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.editState.results, (virusCode) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: virusCode.code,
                                  class: "col-sm-12 col-md-6 col-lg-4 viralogy-section"
                                }, [
                                  (_ctx.editState.results[virusCode.code])
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                        _createElementVNode("div", {
                                          class: "form-check form-check-inline",
                                          onClick: ($event: any) => (_ctx.toggleVirology(virusCode.code, _ctx.editState.results))
                                        }, [
                                          _withDirectives(_createElementVNode("input", {
                                            type: "checkbox",
                                            class: "form-check-input form-check-input-main",
                                            id: `virology-results-${virusCode.code}-checkbox`,
                                            "onUpdate:modelValue": ($event: any) => ((_ctx.editState.results[virusCode.code].tested) = $event)
                                          }, null, 8, _hoisted_32), [
                                            [_vModelCheckbox, _ctx.editState.results[virusCode.code].tested]
                                          ]),
                                          _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.getVirologyCombinedLabel(virusCode.code)), 1),
                                          (_ctx.editState.results[virusCode.code].tested)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                                (_ctx.editState.results[virusCode.code].positive || _ctx.editState.results[virusCode.code].initial_result == 'P')
                                                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                      key: 0,
                                                      icon: ['far', 'plus-circle'],
                                                      "fixed-width": "",
                                                      class: "text-green"
                                                    }))
                                                  : _createCommentVNode("", true),
                                                (_ctx.editState.results[virusCode.code].positive === false || _ctx.editState.results[virusCode.code].initial_result == 'N')
                                                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                      key: 1,
                                                      icon: ['far', 'minus-circle'],
                                                      "fixed-width": "",
                                                      class: "text-red"
                                                    }))
                                                  : _createCommentVNode("", true)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ], 8, _hoisted_31),
                                        (_ctx.editState.results[virusCode.code].tested && !_ctx.isDeceasedDonor)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                              _createVNode(_component_boolean_radio_input, {
                                                "input-id": `virology-results-${virusCode.code}-positive`,
                                                acceptId: "positive",
                                                declineId: "negative",
                                                acceptLabel: _ctx.$t(`positive`),
                                                declineLabel: _ctx.$t(`negative`),
                                                modelValue: _ctx.editState.results[virusCode.code].positive,
                                                "onUpdate:modelValue": ($event: any) => ((_ctx.editState.results[virusCode.code].positive) = $event),
                                                rules: _ctx.editState.results[virusCode.code].tested ? 'required' : ''
                                              }, null, 8, ["input-id", "acceptLabel", "declineLabel", "modelValue", "onUpdate:modelValue", "rules"])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_ctx.editState.results[virusCode.code].tested && _ctx.isDeceasedDonor)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                              _createVNode(_component_radio_group, {
                                                "input-id": `virology-results-${virusCode.code}-initial_result`,
                                                options: _ctx.virologyResultOptions,
                                                modelValue: _ctx.editState.results[virusCode.code].initial_result,
                                                "onUpdate:modelValue": ($event: any) => ((_ctx.editState.results[virusCode.code].initial_result) = $event),
                                                rules: _ctx.editState.results[virusCode.code].tested ? 'required' : ''
                                              }, null, 8, ["input-id", "options", "modelValue", "onUpdate:modelValue", "rules"])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_ctx.editState.results[virusCode.code].tested)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                              _createElementVNode("label", {
                                                for: `virology-results-${virusCode.code}-comments`,
                                                class: "sr-only"
                                              }, _toDisplayString(_ctx.$t(`comments`)), 9, _hoisted_38),
                                              _withDirectives(_createElementVNode("textarea", {
                                                ruleKey: "virology-labs-results-comments",
                                                rows: "2",
                                                class: "form-control",
                                                placeholder: _ctx.$t(`comments`),
                                                id: `virology-results-${virusCode.code}-comments`,
                                                "onUpdate:modelValue": ($event: any) => ((_ctx.editState.results[virusCode.code].comments) = $event)
                                              }, null, 8, _hoisted_39), [
                                                [_vModelText, _ctx.editState.results[virusCode.code].comments]
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.editState.secondary_results && _ctx.getVirologyCodesSecondary && _ctx.showSecondaryResults)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.editState.secondary_results, (virusCode) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: virusCode.code,
                            class: "col-sm-12 col-md-6 col-lg-4 viralogy-section"
                          }, [
                            (_ctx.editState.secondary_results[virusCode.code])
                              ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                  _createElementVNode("div", {
                                    class: "form-check form-inline",
                                    onClick: ($event: any) => (_ctx.toggleVirology(virusCode.code, _ctx.editState.secondary_results))
                                  }, [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "checkbox",
                                      class: "form-check-input",
                                      id: `virology-results-${virusCode.code}-checkbox`,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState.secondary_results[virusCode.code].tested) = $event)
                                    }, null, 8, _hoisted_42), [
                                      [_vModelCheckbox, _ctx.editState.secondary_results[virusCode.code].tested]
                                    ]),
                                    _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.getVirologySecondaryLabel(virusCode.code)), 1),
                                    (_ctx.editState.secondary_results[virusCode.code].tested)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                          (_ctx.editState.secondary_results[virusCode.code].positive || _ctx.editState.secondary_results[virusCode.code].initial_result == 'P')
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 0,
                                                icon: ['far', 'plus-circle'],
                                                "fixed-width": "",
                                                class: "text-green"
                                              }))
                                            : _createCommentVNode("", true),
                                          (_ctx.editState.secondary_results[virusCode.code].positive === false || _ctx.editState.secondary_results[virusCode.code].initial_result == 'N')
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 1,
                                                icon: ['far', 'minus-circle'],
                                                "fixed-width": "",
                                                class: "text-red"
                                              }))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 8, _hoisted_41),
                                  (_ctx.editState.secondary_results[virusCode.code].tested)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                        _createVNode(_component_boolean_radio_input, {
                                          ruleKey: "virology_labs.secondary_results.positive",
                                          "input-id": `virology-results-${virusCode.code}-positive`,
                                          acceptId: "positive",
                                          declineId: "negative",
                                          acceptLabel: _ctx.$t(`positive`),
                                          declineLabel: _ctx.$t(`negative`),
                                          modelValue: _ctx.editState.secondary_results[virusCode.code].positive,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState.secondary_results[virusCode.code].positive) = $event)
                                        }, null, 8, ["input-id", "acceptLabel", "declineLabel", "modelValue", "onUpdate:modelValue"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.editState.secondary_results[virusCode.code].tested)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                                        _createVNode(_component_radio_group, {
                                          "input-id": `virology-results-${virusCode.code}-initial_result`,
                                          options: _ctx.virologyResultOptions,
                                          modelValue: _ctx.editState.secondary_results[virusCode.code].initial_result,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState.secondary_results[virusCode.code].initial_result) = $event),
                                          rules: _ctx.editState.secondary_results[virusCode.code].tested ? 'required' : ''
                                        }, null, 8, ["input-id", "options", "modelValue", "onUpdate:modelValue", "rules"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.editState.secondary_results[virusCode.code].tested && !_ctx.isLivingDonorOrDeceasedDonor)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                        _createElementVNode("label", {
                                          for: `virology-results-${virusCode.code}-comments`,
                                          class: "sr-only"
                                        }, _toDisplayString(_ctx.$t(`comments`)), 9, _hoisted_48),
                                        _withDirectives(_createElementVNode("textarea", {
                                          ruleKey: "virology_labs.secondary_results.comments",
                                          rows: "2",
                                          class: "form-control",
                                          placeholder: _ctx.$t(`comments`),
                                          id: `virology-results-${virusCode.code}-comments`,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState.secondary_results[virusCode.code].comments) = $event)
                                        }, null, 8, _hoisted_49), [
                                          [_vModelText, _ctx.editState.secondary_results[virusCode.code].comments]
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      : _createCommentVNode("", true),
                    (_ctx.availableSecondaryResults.length > 0 && _ctx.showSecondaryResults)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                          _createElementVNode("div", _hoisted_51, [
                            _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.$t(`virology-codes-secondary`)), 1),
                            _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                            _createVNode(_component_select_input, {
                              textKey: "value",
                              name: "Additional Test Marker",
                              valueKey: "code",
                              modelValue: _ctx.selectedSecondaryResult,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedSecondaryResult) = $event)),
                              options: _ctx.availableSecondaryResults,
                              selectId: "virology-codes-secondary",
                              hideLabel: true,
                              class: "virology-codes-secondary-select"
                            }, null, 8, ["modelValue", "options"]),
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-primary btn-sm virology-codes-secondary-button",
                              onClick: _cache[16] || (_cache[16] = event => _ctx.addSecondaryResult(event)),
                              disabled: !_ctx.availableSecondaryResults
                            }, _toDisplayString(_ctx.$t('add')), 9, _hoisted_53)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title", "tabbableValue", "table-config"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["saveButton", "lookupsToLoad", "saveButtonText", "laboratories-to-load"]))
}