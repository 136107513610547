import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "legend-title" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group-large" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "standard-form-group" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "standard-form-group" }
const _hoisted_19 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_20 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_21 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_22 = { class: "standard-form-group-large" }
const _hoisted_23 = {
  key: 0,
  class: "standard-form-group-large"
}
const _hoisted_24 = { class: "standard-form-group" }
const _hoisted_25 = {
  key: 1,
  class: "standard-form-group"
}
const _hoisted_26 = {
  key: 2,
  class: "standard-form-group"
}
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "standard-form-group" }
const _hoisted_29 = { class: "standard-form-group" }
const _hoisted_30 = { class: "standard-form-group" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('referral_details'),
    "sub-section-id": "journey-referral-section"
  }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createVNode(_component_validation_observer, { ref: "validations" }, {
              default: _withCtx(() => [
                _createVNode(_component_form_layout, {
                  "form-id": "journey-referral",
                  disabled: !_ctx.uiJourney.canSave || _ctx.newJourney
                }, {
                  contents: _withCtx(() => [
                    _createVNode(_component_form_layout, {
                      "form-id": "journey-referral-details",
                      disabled: !_ctx.uiJourney.canSave || _ctx.newJourney
                    }, {
                      contents: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_date_input, {
                              ruleKey: "referral_details.received_date",
                              name: _ctx.$t('referral_received_date'),
                              "input-id": "rd-recieved-date",
                              modelValue: _ctx.editState.receivedDate,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.receivedDate) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_select_input, {
                              ruleKey: "referral_details.referral_source",
                              "select-id": "rd-referral-source",
                              name: _ctx.$t('referral_source'),
                              options: _ctx.referralSourceOptions,
                              modelValue: _ctx.editState.referralSource,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.referralSource) = $event))
                            }, null, 8, ["name", "options", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_select_input, {
                              ruleKey: "referral_details.stage_attributes.journey.failed_organ.primary_diagnosis_code",
                              "select-id": "rd-primary-diagnosis",
                              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkSecondDiagnosis(_ctx.editState.primaryDiagnosisCode))),
                              name: _ctx.$t('primary_diagnosis'),
                              modelValue: _ctx.editState.primaryDiagnosisCode,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.primaryDiagnosisCode) = $event)),
                              options: _ctx.diseaseCodeLookup
                            }, null, 8, ["name", "modelValue", "options"])
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_date_input, {
                              ruleKey: "referral_details.stage_attributes.journey.failed_organ.primary_diagnosis_date",
                              "input-id": "rd-primary-diagnosis-date",
                              name: _ctx.$t('primary_diagnosis_date'),
                              modelValue: _ctx.editState.primaryDiagnosisDate,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.primaryDiagnosisDate) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_select_input, {
                              ruleKey: "referral_details.stage_attributes.journey.failed_organ.secondary_diagnosis_code",
                              "select-id": "rd-secondary-diagnosis",
                              name: _ctx.$t('secondary_diagnosis'),
                              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.checkSecondDiagnosisForBlankValue(_ctx.editState.secondaryDiagnosisCode))),
                              modelValue: _ctx.editState.secondaryDiagnosisCode,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.secondaryDiagnosisCode) = $event)),
                              options: _ctx.secondDiseaseCodeLookup
                            }, null, 8, ["name", "modelValue", "options"])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_date_input, {
                              ruleKey: "referral_details.stage_attributes.journey.failed_organ.secondary_diagnosis_date",
                              "input-id": "rd-secondary-diagnosis-date",
                              name: _ctx.$t('secondary_diagnosis_date'),
                              modelValue: _ctx.editState.secondaryDiagnosisDate,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.secondaryDiagnosisDate) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_form_layout, {
                      "form-id": "referral-details-physician",
                      disabled: !_ctx.uiJourney.canSave || _ctx.newJourney
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("legend", null, [
                          _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.$t('referring_physician_details')), 1)
                        ])
                      ]),
                      contents: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_text_input, {
                              ruleKey: "referral_details.referrer.organization.name",
                              name: _ctx.$t('referring_organization_name'),
                              "input-id": "rd-referring-name",
                              modelValue: _ctx.editState.referringOrganizationName,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.referringOrganizationName) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_select_input, {
                              ruleKey: "referral_details.referrer.organization.type_code",
                              "select-id": "rd-referring-type",
                              name: _ctx.$t('referring_organization_type'),
                              options: _ctx.organizationTypeLookup,
                              modelValue: _ctx.editState.referringOrganizationType,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.referringOrganizationType) = $event))
                            }, null, 8, ["name", "options", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_select_input, {
                              ruleKey: "referral_details.referrer.professional.type_code",
                              "select-id": "rd-professional-type",
                              name: _ctx.$t('referring_health_care_professional_type'),
                              options: _ctx.professionalTypeLookup,
                              modelValue: _ctx.editState.referringProfessionalType,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.referringProfessionalType) = $event))
                            }, null, 8, ["name", "options", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_text_input, {
                              ruleKey: "referral_details.referrer.professional.first_name",
                              "input-id": "rd-first-name",
                              name: _ctx.$t('referrer_first_name'),
                              modelValue: _ctx.editState.firstName,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.firstName) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_text_input, {
                              ruleKey: "referral_details.referrer.professional.last_name",
                              "input-id": "rd-last-name",
                              name: _ctx.$t('referrer_last_name'),
                              modelValue: _ctx.editState.lastName,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.lastName) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_select_input, {
                              ruleKey: "referral_details.referrer.address.country",
                              selectId: "rd-country",
                              name: _ctx.$t('referrer_address.country'),
                              modelValue: _ctx.editState.country,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.country) = $event)),
                              options: _ctx.countryOptions,
                              onChange: _ctx.handleCountryChanged,
                              filterByLookupRule: true
                            }, null, 8, ["name", "modelValue", "options", "onChange"])
                          ]),
                          (_ctx.editState.country === _ctx.getCountryValue.Canada)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createVNode(_component_select_input, {
                                  ruleKey: "referral_details.referrer.address.province",
                                  selectId: "rd-province",
                                  name: _ctx.$t('referrer_address.province_or_territory'),
                                  validationId: "rd-province",
                                  modelValue: _ctx.editState.province,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editState.province) = $event)),
                                  options: _ctx.provinceOptions
                                }, null, 8, ["name", "modelValue", "options"])
                              ]))
                            : (_ctx.editState.country === _ctx.getCountryValue.USA)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                  _createVNode(_component_select_input, {
                                    ruleKey: "referral_details.referrer.address.province",
                                    selectId: "rd-state",
                                    name: _ctx.$t('referrer_address.state_or_territory'),
                                    validationId: "rd-state",
                                    modelValue: _ctx.editState.state,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editState.state) = $event)),
                                    options: _ctx.stateOptions
                                  }, null, 8, ["name", "modelValue", "options"])
                                ]))
                              : (_ctx.editState.country === _ctx.getCountryValue.Other)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                    _createVNode(_component_text_input, {
                                      ruleKey: "referral_details.referrer.address.other_country",
                                      inputId: "rd-other-country",
                                      name: _ctx.$t('referrer_address.other_country'),
                                      modelValue: _ctx.editState.otherCountry,
                                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editState.otherCountry) = $event))
                                    }, null, 8, ["name", "modelValue"])
                                  ]))
                                : _createCommentVNode("", true),
                          (_ctx.editState.country)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _cache[26] || (_cache[26] = _createElementVNode("div", { class: "row-break d-none d-lg-block d-xxl-none" }, null, -1)),
                                _createElementVNode("div", _hoisted_22, [
                                  _createVNode(_component_text_input, {
                                    ruleKey: "referral_details.referrer.address.street",
                                    inputId: "rd-street-address",
                                    name: _ctx.$t('referrer_address.street_address'),
                                    modelValue: _ctx.editState.streetAddress,
                                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editState.streetAddress) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                (_ctx.editState.showUrbanization)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                      _createVNode(_component_text_input, {
                                        ruleKey: "referral_details.referrer.address.urbanization",
                                        inputId: "rd-urbanization",
                                        name: _ctx.$t('referrer_address.urbanization'),
                                        modelValue: _ctx.editState.urbanization,
                                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editState.urbanization) = $event))
                                      }, null, 8, ["name", "modelValue"])
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_24, [
                                  _createVNode(_component_text_input, {
                                    ruleKey: "referral_details.referrer.address.city",
                                    inputId: "rd-city",
                                    name: _ctx.$t('referrer_address.city'),
                                    modelValue: _ctx.editState.city,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editState.city) = $event))
                                  }, null, 8, ["name", "modelValue"])
                                ]),
                                (_ctx.editState.showPostalCode)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                      _createVNode(_component_text_input, {
                                        ruleKey: "referral_details.referrer.address.postal_code",
                                        inputId: "rd-postal-code",
                                        name: _ctx.$t('referrer_address.postal_code'),
                                        modelValue: _ctx.editState.postalCode,
                                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editState.postalCode) = $event))
                                      }, null, 8, ["name", "modelValue"])
                                    ]))
                                  : (_ctx.editState.showZipCode)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                        _createVNode(_component_text_input, {
                                          ruleKey: "referral_details.referrer.address.postal_code",
                                          inputId: "rd-zip-code",
                                          name: _ctx.$t('referrer_address.zip_code'),
                                          modelValue: _ctx.editState.zipCode,
                                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editState.zipCode) = $event))
                                        }, null, 8, ["name", "modelValue"])
                                      ]))
                                    : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createVNode(_component_text_input, {
                              ruleKey: "referral_details.referrer.organization.phone_number",
                              inputId: "rd-phone-number",
                              name: _ctx.$t('referrer_contact.phone_number'),
                              modelValue: _ctx.editState.phoneNumber,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editState.phoneNumber) = $event)),
                              mask: _ctx.getMaskFromRules('referral_details.referrer.organization.phone_number')
                            }, null, 8, ["name", "modelValue", "mask"])
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_text_input, {
                              ruleKey: "referral_details.referrer.organization.fax_number",
                              inputId: "rd-fax-number",
                              name: _ctx.$t('referrer_contact.fax_number'),
                              modelValue: _ctx.editState.faxNumber,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editState.faxNumber) = $event)),
                              mask: _ctx.getMaskFromRules('referral_details.referrer.organization.fax_number')
                            }, null, 8, ["name", "modelValue", "mask"])
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_text_input, {
                              ruleKey: "referral_details.referrer.organization.email",
                              inputId: "rd-email",
                              name: _ctx.$t('referrer_contact.email'),
                              modelValue: _ctx.editState.emailAddress,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editState.emailAddress) = $event)),
                              mask: _ctx.getMaskFromRules('referral_details.referrer.organization.email')
                            }, null, 8, ["name", "modelValue", "mask"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_component_text_area_input, {
                              ruleKey: "referral_details.comments",
                              inputId: "rd-comments",
                              name: _ctx.$t('referral_comments'),
                              modelValue: _ctx.editState.comments,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editState.comments) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  save: _withCtx(() => [
                    _createVNode(_component_save_toolbar, {
                      show: _ctx.showSaveToolbar,
                      ref: "saveReferralDetails",
                      label: _ctx.$t('save_referral_details'),
                      onSave: _ctx.handleSave,
                      cancelButton: true,
                      onCancel: _ctx.handleCancel
                    }, null, 8, ["show", "label", "onSave", "onCancel"])
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }, 512)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title"]))
}