<template>
  <sub-section
  v-if="isProtoHeartEchoResultEnabled"
    sub-section-id="proto-echo-result-section"
    :title="title"
  >
  <template v-slot:contents>
    
    <!-- Filter Bar + Create Button -->
    <table-toolbar
      :createButton="true"
      createText="Create Echo Result"
      @table-create-row="clearForm"
    >
      <template v-slot:button-bar>
        <div class="filter-section-action-row">
          <div class="filter-section-wrapper">
            <filter-component
              fieldID="contactFilter"
              :showFilter="false"
              :showArchived="false"
            />
          </div>
        </div>
      </template>
    </table-toolbar>

    <!-- List of Items, or History List -->
    <table-list
      ref="echoResultsTable"
      tabbableColumn="calculated_date"
      table-id="echo-result-table"
      :table-config="echoResultTableConfig"
      @table-row-click="editSelected"
      :highlightSelection="true"
      :rowStyleClass="rowStyleClass"
    />

    <!-- Form layout -->
  
    <form-layout
    form-id="echoResult-form">
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="isNew" class="legend-title">
            NEW ECHO RESULT
          </h5>
          <h5 v-else class="legend-title has-guiding-text">
            SELECTED ECHO RESULT
          </h5>
        </legend>
      </template>
      <template v-slot:contents>
        <div class="row">
          <div class="standard-form-group">
            <date-input
              inputId="echo-test_date"
              name='Test Date'
              v-model="editState.echo_test_date"   
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="echo-ef"
              name="EF"
              :append="true"
              appendText="%"
              step="0.1"
              v-model="editState.echo_ef"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="ivsd"
              name="IVSd"
              :append="true"
              appendText="cm"
              step="0.1"
              v-model="editState.ivsd"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="lvidd"
              name="LVIDd"
              :append="true"
              appendText="cm"
              step="0.1"
              v-model="editState.lvidd"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="lvpwd"
              name="LVPWd"
              :append="true"
              appendText="cm"
              step="0.1"
              v-model="editState.lvpwd"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="echo-fs"
              name="FS"
              :append="true"
              appendText="%"
              step="0.1"
              v-model="editState.echo_fs"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="ivss"
              name="IVSs"
              :append="true"
              appendText="cm"
              step="0.1"
              v-model="editState.ivss"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="lvids"
              name="LVIDs"
              :append="true"
              appendText="cm"
              step="0.1"
              v-model="editState.lvids"
            />
          </div>
          <div class="standard-form-group">
            <number-input
              inputId="lvpws"
              name="LVPWs"
              :append="true"
              appendText="cm"
              step="0.1"
              v-model="editState.lvpws"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-large">
            <text-area-input input-id="outcome" name="Outcome" rows="4" v-model="editState.outcome"  />
          </div>
        </div>
      </template>
      <template v-slot:save>
        <save-toolbar
          :show="true"     
          ref="saveEcho"
          label="Save Echo Result"
          :cancelButton="true"
          @save="performSave()"
          @cancel="clearForm()"
        />
      </template>
    </form-layout>    
    </template>
  </sub-section>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from '@/components/shared/TimeInput.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface EchoResultsForm {
  id: string,
  echo_test_date: Date|null,
  echo_ef: number | null
  ivsd: number | null
  lvidd: number | null,
  lvpwd: number | null,
  echo_fs: number | null,
  ivss: number | null,
  lvids: number | null,
  lvpws: number | null,
  outcome: string,
  
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    TimeInput,
    NumberInput,
    SelectInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoEchoResults extends mixins(DateUtilsMixin) {

  private title: any = "ECHO RESULT";
  private isNew: any = true;
  private isArchiving = false;
  private isRestoring = false;
  private isLoadingActions = false;

  private editState: EchoResultsForm = {
    id: '',
    echo_test_date: null,
    echo_ef: null,
    ivsd: null, 
    lvidd: null,
    lvpwd: null,
    echo_fs: null,
    ivss: null,
    lvids: null,
    lvpws: null,
    outcome: "",
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private echoResults: any = [];

  public perPage = 10;
  public currentPageTable = 1;

  get isProtoHeartEchoResultEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.HeartEchoResult);
  }

  get echoResultTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Test Date', field: 'calculatedDateDisplayValue', width: '125px'},
      { label: 'EF', field: 'echo_ef',  width: '125px' },
      { label: 'IVSd', field: 'ivsd',  width: '125px' },
      { label: 'IVIDd', field: 'lvidd',  width: '125px'},
      { label: 'LVPWd', field: 'lvpwd',  width: '125px'},
      { label: 'FS', field: 'echo_fs',  width: '125px' },
      { label: 'IVSs', field: 'ivss',  width: '125px' },   
      { label: 'LVIDs', field: 'lvids',  width: '125px' },        
      { label: 'LVPWd', field: 'lvpws',  width: '125px' },
    ],
    empty: 'use the form below to add a new row',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'echo_test_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveEcho as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.HeartEchoResult, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: EchoResultsForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.echoResults = response;
}

get tableRows() {
  const echoResults = this.echoResults || [];

  if (echoResults.length > 0) {

  return echoResults.map((result: any) => {
    return {
      id: result.id,
      echo_test_date: result.echo_test_date,
      calculatedDateDisplayValue: this.parseDisplayDateUi(result.echo_test_date) || '-',
      echo_ef: result.echo_ef || '-',
      ivsd: result.ivsd || '-',
      lvidd: result.lvidd || '-',
      lvpwd: result.lvpwd || '-',
      echo_fs: result.echo_fs || '-',
      ivss: result.ivss || '-',
      lvids: result.lvids || '-',
      lvpws: result.lvpws || '-',
      outcome: result.outcome
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let echoResults = this.echoResults || [];

  const payload: EchoResultsForm = {
    id: Math.random().toString(16).slice(2),
    echo_test_date: this.editState.echo_test_date,
    echo_ef: this.editState.echo_ef,
    ivsd: this.editState.ivsd,
    lvidd: this.editState.lvidd,
    lvpwd: this.editState.lvpwd,
    echo_fs: this.editState.ivsd,
    ivss: this.editState.ivss,
    lvids: this.editState.lvids,
    lvpws: this.editState.lvpws,
    outcome: this.editState.outcome
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = echoResults.findIndex((result: any) => result.id == this.editState.id);
    
    if (foundIndex != -1) {
      echoResults[foundIndex] = payload;
    }
  } else {  
    echoResults.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, echoResults);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
    if (row) {  
      this.editState.id = row.row.id;
      this.editState.echo_test_date = row.row.echo_test_date;
      this.editState.echo_ef = row.row.echo_ef;
      this.editState.ivsd = row.row.ivsd;
      this.editState.lvidd = row.row.lvidd;
      this.editState.lvpwd = row.row.lvpwd;
      this.editState.echo_fs = row.row.echo_fs;
      this.editState.ivss = row.row.ivss;
      this.editState.lvids = row.row.lvids;
      this.editState.lvpws = row.row.lvpws;
      this.editState.outcome = row.row.outcome;
      this.isNew = false;
    }
  }

  public clearForm(): any {
    this.isNew = false;
    this.editState.echo_test_date = null;
    this.editState.echo_ef = null;
    this.editState.ivsd = null;
    this.editState.lvidd = null;
    this.editState.lvpwd = null;
    this.editState.echo_fs = null;
    this.editState.ivss = null;
    this.editState.lvids = null;
    this.editState.lvpws = null;
    this.editState.outcome = "";
  }

  rowStyleClass(row: any) {
  // if row editable, change color
  return row.editable ? 'tr-highlight-green' : null;
}

  // Reference to table
  get echoResultsTable(): any {
    return this.$refs.echoResultsTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.echoResultsTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.echoResultsTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveEcho as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}

</script>
