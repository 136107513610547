import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card summary-card" }
const _hoisted_2 = { class: "row skeleton-padding-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "skeleton-box skeleton-title w-100" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (n, index) => {
          return _createElementVNode("div", {
            class: "standard-form-group",
            key: index
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "skeleton-box w-50" }, null, -1),
            _createElementVNode("span", {
              class: "skeleton-box w-100",
              style: {"height":"30px"}
            }, null, -1)
          ]))
        }), 64))
      ]),
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"row skeleton-padding-text\"><div class=\"standard-full-width-group\"><span class=\"skeleton-box w-50\"></span><span class=\"skeleton-box w-100\" style=\"height:30px;\"></span></div></div><div class=\"row skeleton-padding-text\"><div class=\"standard-form-group\"><span class=\"skeleton-box w-50\"></span><span class=\"skeleton-box w-100\" style=\"height:30px;\"></span></div><div class=\"standard-form-group\"><span class=\"skeleton-box w-50\"></span><span class=\"skeleton-box w-100\" style=\"height:30px;\"></span></div><hr></div><div class=\"row skeleton-padding-text\"><div class=\"col-12\"><span class=\"skeleton-box w-100 col-xs-12\" style=\"height:50px;\"></span></div><br><div class=\"standard-form-group-large\"><span class=\"skeleton-box w-50\"></span><span class=\"skeleton-box w-100\" style=\"height:30px;\"></span></div><div class=\"standard-form-group\"><span class=\"skeleton-box w-50\"></span><span class=\"skeleton-box w-100\" style=\"height:30px;\"></span></div></div>", 3))
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "skeleton-box skeleton-button-left" }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
  ]))
}