import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { id: "link-to-donor-history" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12" }
const _hoisted_5 = { class: "d-flex pb-2 border-bottom" }
const _hoisted_6 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_7 = {
  class: "btn btn-outline-secondary dropdown-toggle form-select-checklist",
  type: "button",
  id: "dropdownMenuButton",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_8 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuButton"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "filterSectionWrapper" }
const _hoisted_11 = { class: "filter-section-action-row" }
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = { class: "col-sm-12" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "sub-task-group-header" }
const _hoisted_16 = { class: "nav-group" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "task-line-text" }
const _hoisted_19 = { class: "task-line-name" }
const _hoisted_20 = { class: "completed-items completed-items-small" }
const _hoisted_21 = {
  key: 0,
  class: "completed-value"
}
const _hoisted_22 = { class: "nav-group-button" }
const _hoisted_23 = { class: "completed-items completed-items-large" }
const _hoisted_24 = {
  key: 0,
  class: "completed-value"
}
const _hoisted_25 = { class: "action-bar" }
const _hoisted_26 = ["disabled", "onClick"]
const _hoisted_27 = ["disabled", "onClick"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = ["textContent"]
const _hoisted_30 = ["textContent"]
const _hoisted_31 = ["textContent"]
const _hoisted_32 = ["index", "id", "draggable", "onKeydown"]
const _hoisted_33 = ["id"]
const _hoisted_34 = {
  key: 0,
  class: "taskView taskDetails"
}
const _hoisted_35 = { class: "task-content" }
const _hoisted_36 = { class: "task-body" }
const _hoisted_37 = ["disabled", "onKeydown", "onClick"]
const _hoisted_38 = { class: "ml-auto" }
const _hoisted_39 = { class: "task-meta" }
const _hoisted_40 = { class: "list-inline list-annotation" }
const _hoisted_41 = { class: "list-inline-item" }
const _hoisted_42 = { class: "list-inline-item" }
const _hoisted_43 = {
  key: 1,
  class: "task-edit task-wrapper-form"
}
const _hoisted_44 = { class: "taskView" }
const _hoisted_45 = { class: "task-body" }
const _hoisted_46 = ["id"]
const _hoisted_47 = { class: "taskView" }
const _hoisted_48 = { class: "task-body" }
const _hoisted_49 = {
  key: 2,
  class: "task-wrapper my-4"
}
const _hoisted_50 = { class: "modal-footer-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_filter_component = _resolveComponent("proto-filter-component")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_proto_task_form = _resolveComponent("proto-task-form")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_card_section = _resolveComponent("card-section")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_ctx.isProtoChecklistsReorderEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.editState)
          ? (_openBlock(), _createBlock(_component_card_section, {
              key: 0,
              "section-id": "proto-checklists-reorder"
            }, {
              header: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Checklists (Reorder) ")
              ])),
              body: _withCtx(() => [
                _cache[15] || (_cache[15] = _createElementVNode("div", null, [
                  _createElementVNode("fieldset", { id: "phase_status" }, [
                    _createElementVNode("legend", null, [
                      _createElementVNode("h4", { class: "legend-title" }, [
                        _createElementVNode("span", null, "Checklists")
                      ])
                    ])
                  ])
                ], -1)),
                _createElementVNode("fieldset", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", null, [
                          (_ctx.editState.tasks.length == 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.selectedTasklistName ? _ctx.selectedTasklistName : _ctx.editState.tasks[0].task_name), 1),
                                _createElementVNode("div", _hoisted_8, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTasklistOptions(), (task) => {
                                    return (_openBlock(), _createElementBlock("a", {
                                      key: task.id,
                                      class: "dropdown-item",
                                      onClick: _withModifiers(($event: any) => (_ctx.setTasklist($event, task)), ["prevent"]),
                                      href: "#"
                                    }, _toDisplayString(task.task_name), 9, _hoisted_9))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_proto_filter_component, {
                        displayFilter: _ctx.displayFilter,
                        onUpdateFilter: _ctx.updateFilter,
                        completedFilter: true,
                        checklistFilter: true,
                        fieldID: "checklistFilter",
                        checklistStatusOptions: _ctx.statusOptions,
                        hideSpacer: false,
                        setFilters: _ctx.setFilters
                      }, null, 8, ["displayFilter", "onUpdateFilter", "checklistStatusOptions", "setFilters"])
                    ])
                  ]),
                  (_ctx.selectedTasklistID)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editState.tasks, (task) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: task.task_id,
                            class: "tasklist-body w-100"
                          }, [
                            _createElementVNode("div", _hoisted_13, [
                              (_ctx.selectedTasklistID == task.task_id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(task.list_rows, (list, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: list.list_id,
                                        class: "right-pane-wrapper"
                                      }, [
                                        (list.list_id == _ctx.selectedCategory || _ctx.selectedCategory == 'all')
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 0,
                                              class: _normalizeClass(["right-pane", index == 0 ? 'first': ''])
                                            }, [
                                              _createElementVNode("ul", null, [
                                                _createElementVNode("li", _hoisted_15, [
                                                  _createElementVNode("div", _hoisted_16, [
                                                    _createElementVNode("button", {
                                                      type: "button",
                                                      class: "btn btn-sm tasklist-accordion-toggle",
                                                      "aria-label": "Collapse Group",
                                                      tabindex: "0",
                                                      onClick: _withModifiers(($event: any) => (_ctx.toggleSubList(task.task_id, list.list_id)), ["prevent"])
                                                    }, [
                                                      _createVNode(_component_font_awesome_icon, {
                                                        icon: ['fas', 'caret-right'],
                                                        class: _normalizeClass(["tasklist-accordion-icon", { rotate: list.active }])
                                                      }, null, 8, ["class"])
                                                    ], 8, _hoisted_17),
                                                    _createElementVNode("span", _hoisted_18, [
                                                      _createElementVNode("span", _hoisted_19, _toDisplayString(list.list_name), 1)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_20, [
                                                      (_ctx.taskRows)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(` ${_ctx.getCompleted(task.task_id, list.list_id)} / ${_ctx.getTotalSubTask(task.task_id, list.list_id)}  `), 1))
                                                        : _createCommentVNode("", true),
                                                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "completed-label" }, "Completed", -1))
                                                    ])
                                                  ]),
                                                  _createElementVNode("div", _hoisted_22, [
                                                    _createElementVNode("span", _hoisted_23, [
                                                      (_ctx.taskRows)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(` ${_ctx.getCompleted(task.task_id, list.list_id)} / ${_ctx.getTotalSubTask(task.task_id, list.list_id)}  `), 1))
                                                        : _createCommentVNode("", true),
                                                      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "completed-label" }, "Completed", -1))
                                                    ]),
                                                    _createElementVNode("div", _hoisted_25, [
                                                      (_ctx.getTotalSubTask(task.task_id, list.list_id) > 0)
                                                        ? (_openBlock(), _createElementBlock("button", {
                                                            key: 0,
                                                            type: "button",
                                                            disabled: _ctx.isReordering && list.list_id !== _ctx.idOfCurrentReOrdering,
                                                            class: "btn btn-sm btn-secondary visible-on-desktop",
                                                            onClick: _withModifiers(($event: any) => (_ctx.allowReOrderingOfSection(task.task_id, list.list_id)), ["prevent"])
                                                          }, _toDisplayString(_ctx.getReOrderText(list.list_id)), 9, _hoisted_26))
                                                        : _createCommentVNode("", true),
                                                      _createElementVNode("button", {
                                                        type: "button",
                                                        class: _normalizeClass([list.list_id == _ctx.selectedTaskID ? 'btn-secondary' : 'btn-primary', "btn btn-sm createButton"]),
                                                        disabled: _ctx.isCreating || _ctx.isReordering,
                                                        onClick: _withModifiers(($event: any) => (_ctx.openCreateListItem(task.task_id, list.list_id)), ["prevent"])
                                                      }, " Create Checklist Item ", 10, _hoisted_27)
                                                    ])
                                                  ])
                                                ])
                                              ])
                                            ], 2))
                                          : _createCommentVNode("", true),
                                        (_ctx.taskRows && list.active)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                              (_ctx.canBeReOrdered(list.list_id))
                                                ? (_openBlock(), _createElementBlock("button", {
                                                    key: 0,
                                                    type: "button",
                                                    class: "btn btn-link",
                                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showKeyboardInstructions = !_ctx.showKeyboardInstructions)),
                                                    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.showKeyboardInstructions = !_ctx.showKeyboardInstructions), ["prevent"]), ["enter"])),
                                                    textContent: _toDisplayString(_ctx.keyboardInstructionsButtonText)
                                                  }, null, 40, _hoisted_29))
                                                : _createCommentVNode("", true),
                                              (_ctx.showKeyboardInstructions)
                                                ? (_openBlock(), _createElementBlock("p", {
                                                    key: 1,
                                                    id: "reorder_instructions",
                                                    class: "text-sm leading-normal mt-2",
                                                    textContent: _toDisplayString(`You are reordering ${list.list_name } It can be reordering by drag and drop by selecting at the 'pill handle' and dragging to its new position, or by using the keyboard navigation by selecting a checklist item via the tab, pressing the spacebar to grab it. and using the up and down arrows to change position, then pressing the spacebar to drop`)
                                                  }, null, 8, _hoisted_30))
                                                : _createCommentVNode("", true),
                                              _createElementVNode("div", {
                                                "aria-live": "assertive",
                                                class: "sr-only",
                                                textContent: _toDisplayString(_ctx.assistiveText)
                                              }, null, 8, _hoisted_31),
                                              _createVNode(_component_draggable, _mergeProps({
                                                forceFallback: true,
                                                modelValue: _ctx.taskRows,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.taskRows) = $event)),
                                                disabled: !_ctx.isReordering || _ctx.idOfCurrentReOrdering !== list.list_id,
                                                handle: ".pill",
                                                tag: "ul",
                                                move: _ctx.onMove,
                                                ref_for: true
                                              }, _ctx.dragOptions, {
                                                group: { name: 'taskRows', pull: 'clone', put: false },
                                                onChange: _ctx.onchange,
                                                onStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDragging=true)),
                                                onEnd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDragging=false))
                                              }), {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskRows.filter((row) => { return row.task_id == task.task_id && row.list_id == list.list_id; }), (row) => {
                                                    return (_openBlock(), _createElementBlock("li", {
                                                      key: row.id,
                                                      class: _normalizeClass(["my-3 flex-column", `${_ctx.rowStyleClass(row)}`]),
                                                      index: index,
                                                      "aria-describedby": "reorder_instructions",
                                                      tabindex: "0",
                                                      id: `checklist-item-${row.id}`,
                                                      draggable: !_ctx.isMobile,
                                                      onKeydown: [
                                                        _withKeys(_withModifiers(($event: any) => (_ctx.grabItem(row)), ["prevent"]), ["space"]),
                                                        _withKeys(_withModifiers(($event: any) => (_ctx.moveItem('movedown', row, list.list_id)), ["prevent"]), ["down"]),
                                                        _withKeys(_withModifiers(($event: any) => (_ctx.moveItem('moveup', row, list.list_id)), ["prevent"]), ["up"])
                                                      ]
                                                    }, [
                                                      _createElementVNode("div", {
                                                        class: "task-wrapper",
                                                        id: `task-wrapper-${row.id}`
                                                      }, [
                                                        (!row.isNew)
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                                              _createElementVNode("div", {
                                                                class: _normalizeClass(`pill pill-${_ctx.getCategoryValue(row.status, 'type')}`)
                                                              }, [
                                                                (_ctx.isReordering && _ctx.canBeReOrdered(row.list_id))
                                                                  ? (_openBlock(), _createElementBlock("div", {
                                                                      key: 0,
                                                                      class: _normalizeClass(`${_ctx.canBeReOrdered(row.list_id) ? 'pill-moving' : ''}`)
                                                                    }, _cache[9] || (_cache[9] = [
                                                                      _createElementVNode("div", { class: "sq" }, null, -1),
                                                                      _createElementVNode("div", { class: "sq" }, null, -1),
                                                                      _createElementVNode("div", { class: "sq" }, null, -1),
                                                                      _createElementVNode("div", { class: "sq" }, null, -1),
                                                                      _createElementVNode("div", { class: "sq" }, null, -1),
                                                                      _createElementVNode("div", { class: "sq" }, null, -1)
                                                                    ]), 2))
                                                                  : _createCommentVNode("", true)
                                                              ], 2),
                                                              _createElementVNode("div", _hoisted_35, [
                                                                _createElementVNode("div", _hoisted_36, [
                                                                  _createElementVNode("button", {
                                                                    class: "task-line-text",
                                                                    "aria-label": "Edit Item",
                                                                    tabindex: "0",
                                                                    disabled: _ctx.isReordering,
                                                                    onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.toggleEditScreen(row.id, row)), ["prevent"]), ["down"]),
                                                                    onClick: _withModifiers(($event: any) => (_ctx.toggleEditScreen(row.id, row)), ["prevent"])
                                                                  }, _toDisplayString(row.name), 41, _hoisted_37),
                                                                  _createElementVNode("div", _hoisted_38, [
                                                                    _createElementVNode("span", {
                                                                      class: _normalizeClass(`badge badge-pill badge-checklist badge-${_ctx.getCategoryValue(row.status, 'type')}`)
                                                                    }, _toDisplayString(_ctx.getCategoryValue(row.status, 'name')), 3)
                                                                  ])
                                                                ]),
                                                                _createElementVNode("div", _hoisted_39, [
                                                                  _createElementVNode("ul", _hoisted_40, [
                                                                    _createElementVNode("li", _hoisted_41, [
                                                                      _cache[10] || (_cache[10] = _createElementVNode("span", null, "Completed Date: ", -1)),
                                                                      _createElementVNode("span", null, _toDisplayString(row.completed_date && row.completed_date != '' ? _ctx.formatDateObject(row.completed_date) : "--"), 1)
                                                                    ]),
                                                                    _createElementVNode("li", _hoisted_42, [
                                                                      _cache[11] || (_cache[11] = _createElementVNode("span", null, "Completed By: ", -1)),
                                                                      _createElementVNode("span", null, _toDisplayString(row.completed_by ? row.completed_by : "--"), 1)
                                                                    ])
                                                                  ])
                                                                ])
                                                              ])
                                                            ]))
                                                          : _createCommentVNode("", true),
                                                        (_ctx.selectedSubTaskItems.includes(row.id))
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                                              _createElementVNode("div", _hoisted_44, [
                                                                _createElementVNode("div", _hoisted_45, [
                                                                  _cache[12] || (_cache[12] = _createElementVNode("legend", null, [
                                                                    _createElementVNode("h4", { class: "legend-title" }, [
                                                                      _createElementVNode("span", null, "CHECKLIST ITEM")
                                                                    ])
                                                                  ], -1)),
                                                                  _createVNode(_component_proto_task_form, {
                                                                    onCancel: _ctx.cancelEdit,
                                                                    selectedItems: _ctx.selectedItems,
                                                                    id: row.id,
                                                                    onUpdate: _ctx.updateItem,
                                                                    taskOptions: _ctx.getTasksOptions(task.task_id, task.list_rows),
                                                                    statusOptions: _ctx.statusOptions
                                                                  }, null, 8, ["onCancel", "selectedItems", "id", "onUpdate", "taskOptions", "statusOptions"])
                                                                ])
                                                              ])
                                                            ]))
                                                          : _createCommentVNode("", true)
                                                      ], 8, _hoisted_33),
                                                      (row.isNew)
                                                        ? (_openBlock(), _createElementBlock("div", {
                                                            key: 0,
                                                            class: "task-new task-wrapper-form",
                                                            id: `task-wrapper-form-new-${row.id}`
                                                          }, [
                                                            _createElementVNode("div", _hoisted_47, [
                                                              _createElementVNode("div", _hoisted_48, [
                                                                _cache[13] || (_cache[13] = _createElementVNode("legend", null, [
                                                                  _createElementVNode("h4", { class: "legend-title" }, [
                                                                    _createElementVNode("span", null, "NEW CHECKLIST ITEM")
                                                                  ])
                                                                ], -1)),
                                                                _createVNode(_component_proto_task_form, {
                                                                  onCancel: ($event: any) => (_ctx.confirmPopup(row.id)),
                                                                  id: row.id,
                                                                  isNew: row.isNew,
                                                                  selectedItems: [row],
                                                                  onSave: _ctx.saveItem,
                                                                  taskOptions: _ctx.getTasksOptions(task.task_id, task.list_rows),
                                                                  statusOptions: _ctx.statusOptions
                                                                }, null, 8, ["onCancel", "id", "isNew", "selectedItems", "onSave", "taskOptions", "statusOptions"])
                                                              ])
                                                            ])
                                                          ], 8, _hoisted_46))
                                                        : _createCommentVNode("", true)
                                                    ], 42, _hoisted_32))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1040, ["modelValue", "disabled", "move", "onChange"]),
                                              (_ctx.isTaskRowEmpty(list.list_id))
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_49, _cache[14] || (_cache[14] = [
                                                    _createElementVNode("div", { class: "taskView" }, " There are no items to show ", -1)
                                                  ])))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_modal_section, {
          modalId: "checklistCancelModal",
          ref: "checklistCancelModal",
          class: "modal-sticky-header",
          centered: true,
          closeButton: false,
          size: "md",
          wide: false
        }, {
          body: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode(" Are you sure that you want to cancel creating new checklist items ")
          ])),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_50, [
              _cache[17] || (_cache[17] = _createElementVNode("button", {
                type: "button",
                "data-dismiss": "modal",
                class: "btn btn-secondary"
              }, " Cancel ", -1)),
              _createElementVNode("a", {
                class: "btn btn-success",
                "data-dismiss": "modal",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelCreate()))
              }, " OK ")
            ])
          ]),
          _: 1
        }, 512)
      ]))
    : _createCommentVNode("", true)
}