import axios from 'axios';
import store from '@/store';
import { ActionTree } from 'vuex';
import { APIPaginatedSearchResult, SaveResult } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { Recipient } from '@/store/recipients/types';
import { prefixErrors, buildErrorResult } from '@/utils';
import { OrganWaitlistMedicalStatusValue, WaitlistFactorCodeValue } from '../lookups/types';
import { RecipientJourney, JourneyState, JourneyDurations, WaitlistDecision, PostTransplantTransferPayload, 
  ClinicalNoteModel, ClinicalNoteVersionModel, ClinicalNoteView, ClinicalNoteVersionView, 
  CurrentStateHistory, APIStateHistoryResponse, 
  APINotesFilterOptionsInterface, UINotesFilterOptionsInterface} from '@/store/recipientJourney/types';
import i18n from '@/i18n';
import { UISuccess } from '@/UIModels/success';
import { UIError } from '@/UIModels/error';
import { useCurrentPageStore } from '@/stores/currentPage';

/**
 * Formats clinical note data for view
 * 
 * @param record clinical note
 * @param dateMethod date method function (functions cannot access getters)
 * @returns {ClinicalNoteView} formatted clinical note for viewing
 */
function formatClinicalNoteForView(record: ClinicalNoteModel, dateMethod: any): ClinicalNoteView {
  // format version history
  let versionHistory: ClinicalNoteVersionView[] = [];
  if (record.version_history) {
    const history = record.version_history.map((item: ClinicalNoteVersionModel) => {

      let change_rationale = null;
      switch(item.action_type) {
        case 'create':
          change_rationale = i18n.t('original_entry');
          break;
        default:
          change_rationale = item.change_rationale;
          break;
      }

      return {
        date: dateMethod(item.action_datetime),
        user: item.created_by,
        change_rationale: change_rationale
      };
    });
    versionHistory = history;
  }

  // format note
  const result: ClinicalNoteView = {
    id: record._id ? record._id : undefined,
    date: dateMethod(record.created_at), // AP-712 the added date will remain unchanged, regardless of update
    user: record.created_by,
    note_subject: record.note_subject,
    note_tags: record.note_tags,
    note_text: record.note_text,
    status: record.archived_at ? i18n.t('archived') : i18n.t('active'),
    version_history: versionHistory,
    archived_at: record.archived_at
  };
  return result;
}

export const actions: ActionTree<JourneyState, RootState> = {
  getJourney({ commit, getters, rootGetters }, organId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const journeys: RecipientJourney[] = rootGetters['recipients/recipientJourneys'];
      const journey = journeys.find(recipientJourney => (recipientJourney._id ? recipientJourney._id : '') === organId);
      if (journey) {
        commit('setJourney', journey);
        resolve();
      } else {
        reject();
      }
    });
  },
  saveJourney({ commit, getters }, { recipientId, journeyId, journey }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any;
      let url: string;
      if (journeyId) {
        method = axios.patch;
        url = APIRoute(EP.recipients.journeys.update, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      } else {
        method = axios.post;
        url = APIRoute(EP.recipients.journeys.create, [[':recipientId', recipientId]]);
      }
      method(url, { journey }).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveReferralDetails({ getters }, { journeyId, recipientId, journey }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const ep = APIRoute(EP.recipients.journeys.referral.update, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      const payload = { journey };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject((new UIError('referral_details', response)).errorResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveReferralDecision({ getters }, { journeyId, recipientId, referralAttributes }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const ep = APIRoute(EP.recipients.journeys.referral.update, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      const payload = { referral_attributes: referralAttributes };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveMedicalAssessment({ getters }, { journeyId, recipientId, medicalAssessment }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const  ep = APIRoute(EP.recipients.journeys.assessment.update, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      const payload = { assessment_attributes: medicalAssessment };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
         // Handle server-side validation errors
         const saveResult = buildErrorResult(response.data.errors);
         reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveConsultation({ getters }, { journeyId, recipientId, consultation }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const  ep = APIRoute(EP.recipients.journeys.consultation.update, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      const payload = { assessment_attributes: consultation };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  /**
   * Save HCC criteria decision to liver journey waitlist attributes
   *
   * If recipient has HCC lab results, a user can decide that the recipient's liver journey is
   * within HCC criteria. This opens up the HCC score calculations for the journey's SMC Score,
   * which affects allocation ranking. However, it also makes the journey at risk of waitlist HCC
   * suspensions if lab results expire, which prevents wait time accumulating for the journey.
   *
   * @param journeyId journey object ID
   * @param recipientId recipient client ID
   * @param withinCriteria boolean flag indicating true if journey is within HCC criteria
   *
   * @returns {Promise<SaveResult>} resolves if save is successful, rejects if errors encountered
   */
  saveHccCriteria({ getters }, { journeyId, recipientId, withinCriteria }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const ep = APIRoute(EP.recipients.journeys.waitlist.hcc_in_criteria, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      /**
       * API expects a boolean flag called 'hcc_within_criteria':
       *
       * - if true, API will set 'liver_hcc_in_criteria_date' waitlist factor to a date
       * - if false, API will set 'liver_hcc_in_criteria_date' to null
       *
       * Therefore, if HCC is within criteria we send 'true' and let API handle the rest.
       *
       * Note: this property is a top-level activity context flag, separate from the data models.
       */
      const payload = { hcc_within_criteria: withinCriteria };
      // Send asynchronously
      axios.put(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  addToWaitlist({ getters }, { journeyId, recipientId, waitlistAttributes, prefix }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      // 'Adding to Waitlist' action happens in Assessment stage, and has its own dedicated endpoint
      const  ep = APIRoute(EP.recipients.journeys.assessment.addToWaitlist, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      const payload = { waitlist_attributes: waitlistAttributes };
      // Send asynchronously
      // Add to waitlist counts as a PUT request
      axios.put(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, prefix);
          store.commit('journeyState/setValidationErrors', saveResult.validationErrors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveWaitlist({ getters }, { journeyId, recipientId, waitlistAttributes, prefix }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const  ep = APIRoute(EP.recipients.journeys.waitlist.update, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      const payload = { waitlist_attributes: waitlistAttributes };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, prefix);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveOverrideWaitTime({ getters }, { journeyId, recipientId, waitlistAttributes, prefix }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const  ep = APIRoute(EP.recipients.journeys.waitlist.overrideWaitTime, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      const payload = { waitlist_attributes: waitlistAttributes };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, prefix);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveRemoveFromWaitlist({ getters }, { journeyId, recipientId, waitlistAttributes, prefix }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const  ep = APIRoute(EP.recipients.journeys.waitlist.removeFromWaitlist, [[':journey_id', journeyId], [':recipient_id', recipientId]]);
      const payload = { waitlist_attributes: waitlistAttributes };
      // Send asynchronously
      axios.put(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, prefix);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  // Get allocation details given a clientId, organCode and allocationId
  getTransplant({ commit, getters }, { journeyId, recipientId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.transplant.show, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
            commit('setTransplantDetails', response.data.transplant);
            resolve();
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // Get allocation details given a clientId, organCode and allocationId
  getTransplantOop({ commit, getters }, { journeyId, recipientId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.outOfProvince.journeys.transplant.show, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
            commit('setTransplantDetails', response.data.transplant);
            resolve();
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveTransplantDetails({ rootGetters, getters }, { journeyId, recipientId, transplantAttributes, prefix }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const ep = APIRoute(EP.recipients.journeys.transplant.update, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      const payload = { transplant_attributes: transplantAttributes };
      // Send asynchronously
      axios.patch(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
           // Generate error for time field if date failed
           const result: string[] = response.data.errors;
           for(const error in result)
           {
            const fieldExists = error.includes("date")? error : null;
            if(fieldExists)
            {
              const newTimeField = error.replace("date","time");
              response.data.errors[newTimeField] = response.data.errors[error];
            }
           }
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, prefix);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadJourneyDurations({ commit },{ recipientId, journeyId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Clear previous response
      commit('clearJourneyDurations');
      // Prepare request
      const url = APIRoute(EP.recipients.journeys.durations.index, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process successful response
        const journeyDurations: JourneyDurations = response.data.durations;
        commit('setJourneyDurations', journeyDurations);
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  },
  loadWaitlistDecisions({ commit, getters }, { recipientId, journeyId }: { recipientId: string, journeyId: string }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Clear previously loaded waitlist decisions as well as any previous selection of one of them
      commit('clearWaitlistDecisions');

      // Prepare request
      const url = APIRoute(EP.recipients.journeys.waitlist_decisions.index, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process successful response
        const decisions: WaitlistDecision[] = response.data.significant_event_decision;
        // Filter out medical status = 0 rows, because from user perspective these are covered by medical holds
        // Note: this is being done in UI, so that these rows are not excluded from API calculation of dates and days
        const filtered = decisions.filter((decision: WaitlistDecision) => {
          const isZeroRow = decision.stage_factor_code === WaitlistFactorCodeValue.MedicalStatus && decision.to === OrganWaitlistMedicalStatusValue.OnHold;
          return !isZeroRow;
        });
        // API handles the sorting, but the end result is an array sorted in ascending order.
        // Here we need to reverse the order, because in waitlist history UI we want to show the newest at the top
        filtered.reverse();
        commit('setWaitlistDecisions', filtered);

        // Select most recent Medical Status or Medical Hold
        if (filtered.length > 0) {
          const target: WaitlistDecision|null = getters.latestMedicalHoldOrMedicalStatus;
          commit('selectWaitlistDecision', target);
        }

        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  },
  createCluster({ commit }, { recipientId, journeyId, clusterPatch }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const ep = APIRoute(EP.recipients.journeys.cluster.create, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      axios.put(ep, { journey: clusterPatch }).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          const responseErrors = response.data.errors;
          // If the error is missing the parameter keys they haven't made a choice
          if (typeof responseErrors === 'string' && response.data.errors.includes('parameter keys')) {
            reject({ success: false, validationErrors:  {"related_journeys": "validation.messages.not_blank"} });
          }
          // Handle server-side validation errors
          reject({ success: false, validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadPostTransplantFollowUps({ commit }, { journeyId, recipientId }): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.index, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        commit('setPostTransplantFollowUps', response.data.follow_ups);
        resolve(response);
      });
    });
  },
  savePostTransplantFollowUp({ getters }, { journeyId, recipientId, followUp, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      let method: any;
      let ep: string;
      const payload = { follow_up: followUp };

      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.update, [[':journeyId', journeyId], [':recipientId', recipientId], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.create, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      }

      // Send asynchronously
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  unCluster({ commit }, { recipientId, journeyId }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint
      const ep = APIRoute(EP.recipients.journeys.cluster.uncluster, [[':journeyId', journeyId], [':recipientId', recipientId]]);
      axios.put(ep).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const unClusterError = {'related-journeys': [response.data.errors]};
          reject({ success: false , errorMessages: [response.data.errors], validationErrors: unClusterError });
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadWaitTimeOverrideEvents({ commit }, { recipientId, journeyId }: { recipientId: string, journeyId: string }): Promise<void> {
    return new Promise<void>((resolve) => {
      // Clear previous events and selection
      commit('clearWaitTimeOverrideEvents');
      commit('selectWaitTimeOverrideEvent', undefined);
      // Prepare request
      const url = APIRoute(EP.recipients.journeys.wait_time_override_events.index, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Store events from successful response
        const events: WaitlistDecision[] = response.data.significant_event_decision;
        commit('setWaitTimeOverrideEvents', events);
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
      });
    });
  },
  clearTransplantData({ rootGetters }, { recipientId, journeyId }: { recipientId: string, journeyId: string }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup endpoint and payload
      const ep = APIRoute(EP.recipients.journeys.transplant.delete, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      axios.delete(ep).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Success
          resolve({ success: true, responseData: response.data });
        } else {
          // Handle activity errors
          reject(buildErrorResult(response?.data?.errors));
        }
      }).catch((error: any) => {
        // System errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  /**
   * Request API create active transfer as part of Transfer Listing feature. There are three types:
   *
   * 1. Single-to-single (Normal Flow)
   * 2. Single-to-cluster (Alt Flow)
   * 3. Cluster-to-cluster (Alt Flow)
   *
   * This action handles all three types of active transfers. Transfer Status objects created as a
   * result of the normal flow single-to-single transfer will be assigned the 'active' transfer
   * type, whereas Transfer Status objects created from the two cluster-related alternative flows
   * will instead have the 'cluster' transfer type.
   */
  createTransferListing({ commit, getters }, { recipientId, journeyId, otherProgramJourneyId }: { recipientId: string, journeyId: string, otherProgramJourneyId: string }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Figure out which endpoint to use, based on whether the journeys are single or clustered
      const isFromJourneyClustered = getters.isClusteredById(journeyId);
      const isToJourneyClustered = getters.isClusteredById(otherProgramJourneyId);
      let ep: string;
      if (isFromJourneyClustered) {
        // Cluster-to-cluster
        ep = APIRoute(EP.recipients.journeys.transferStatuses.activeTransfer.clusterToCluster, [[':recipient_id', recipientId], [':id', journeyId]]);
      } else if (isToJourneyClustered) {
        // Single-to-cluster
        ep = APIRoute(EP.recipients.journeys.transferStatuses.activeTransfer.singleToCluster, [[':recipient_id', recipientId], [':id', journeyId]]);
      } else {
        // Single-to-single
        ep = APIRoute(EP.recipients.journeys.transferStatuses.activeTransfer.singleToSingle, [[':recipient_id', recipientId], [':id', journeyId]]);
      }
      // Prepare information to send
      const payload = { other_program_journey_id: otherProgramJourneyId };
      // Send asynchronously
      axios.put(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  /**
   * Transfer Follow-Up permissions for Post-Transplant journey based on 1.3.10 v4.5 flows:
   *
   * 1. Extension Flow - Post-Transplant Transfer
   * 2. Extension Flow - Post-Transplant Transfer (Cluster)
   */
   createFollowUpTransfer({ commit, getters }, { recipientId, journeyId, transferStatus, prefix }: { recipientId: string, journeyId: string, transferStatus: PostTransplantTransferPayload, prefix: string }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Figure out which endpoint to use, based on whether the journey is single or clustered
      const isJourneyClustered = getters.isClusteredById(journeyId);
      let ep: string;
      if (isJourneyClustered) {
        // Cluster Transfer
        ep = APIRoute(EP.recipients.journeys.transferStatuses.postTransplant.clusterFollowUpTransfer, [[':recipient_id', recipientId], [':id', journeyId]]);
      } else {
        // Single Transfer
        ep = APIRoute(EP.recipients.journeys.transferStatuses.postTransplant.singleFollowUpTransfer, [[':recipient_id', recipientId], [':id', journeyId]]);
      }
      // Prepare information to send
      const payload = { transfer_status: transferStatus };
      // Send asynchronously
      axios.put(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, prefix);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  loadClinicalNotes({ commit, getters, rootGetters }, { recipientId, journeyId, params }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.clinical_notes.index, [
        [':recipient_id', recipientId],
        [':journey_id', journeyId],
      ]);
      axios.get(url, { params: params }).then((response: any) => {
        const searchResult: APIPaginatedSearchResult<ClinicalNoteModel> = response.data.clinical_notes;
        const formattedEntries = searchResult.entries.map((record: ClinicalNoteModel) => {
          return formatClinicalNoteForView(record, rootGetters['utilities/parseFormattedDateTimeUi']);
        });
        const responseData = {
          records: formattedEntries,
          count: searchResult.count,
        };
        resolve({ success: true, responseData: responseData });
      }).catch((error: any) => {
        console.warn(error);
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  loadClinicalNote({ commit, getters, rootGetters }, { recipientId, journeyId, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.clinical_notes.show, [
        [':recipient_id', recipientId],
        [':journey_id', journeyId],
        [':id', id]
      ]);
      axios.get(url).then((response: any) => {
        const record: ClinicalNoteModel = response.data.clinical_note;
        const formattedRecord = formatClinicalNoteForView(record, rootGetters['utilities/parseFormattedDateTimeUi']);
        const responseData = {
          record: formattedRecord,
          permitted_actions: response.data.permitted_actions
        };
        resolve({ success: true, responseData: responseData });
      }).catch((error: any) => {
        console.warn(error);
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveClinicalNote({ getters }, { payload, recipientId, journeyId, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any; // will be function
      let ep: string;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.clinical_notes.update, [[':recipient_id', recipientId], [':journey_id', journeyId], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.clinical_notes.create, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      }
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // rather than send back a standard response, convert it to something the view would understand
          // tbd
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  // Archive or Restore Clinical Note
  filingClinicalNote({ getters, rootGetters }, { payload, process, recipientId, journeyId, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any; // will be function
      let ep: string;
      // if archive
      if (process === 'archive') {
        method = axios.put;
        ep = APIRoute(EP.recipients.journeys.clinical_notes.archive, [[':recipient_id', recipientId], [':journey_id', journeyId], [':id', id]]);
      // else restore
      } else {
        method = axios.put;
        ep = APIRoute(EP.recipients.journeys.clinical_notes.restore, [[':recipient_id', recipientId], [':journey_id', journeyId], [':id', id]]);
      }
      method(ep, payload).then((response: any) => {
        const record: ClinicalNoteModel = response.data.clinical_note;
        const formattedRecord = formatClinicalNoteForView(record, rootGetters['utilities/parseFormattedDateTimeUi']);
        const responseData = {
          record: formattedRecord,
          permitted_actions: response.data.permitted_actions
        };
        resolve({ success: true, responseData: responseData });
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  loadFilterOptions({ commit, getters, rootGetters }, { recipientId, journeyId }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.clinical_notes.filterOptions, [
        [':recipient_id', recipientId],
        [':journey_id', journeyId],
      ]);
      axios.get(url).then((response: any) => {
        const apiOptions: APINotesFilterOptionsInterface = response.data && response.data.filter_options ? response.data.filter_options : {};
        const uiOptions: UINotesFilterOptionsInterface = {
          date_from: apiOptions.date_from || null,
          date_to: apiOptions.date_to || null,
          user_id: apiOptions.user_id || null,
          note_tags: apiOptions.note_tags || null,
          keywords: null,
        };
        const newResponse: any = {
          data: uiOptions
        };
        resolve(new UISuccess(newResponse).getSaveResult());
      }).catch((error: any) => {
        console.warn(error);
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  loadStateHistoryIndex({ commit, getters, rootGetters }, { recipientId, journeyId, params }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.state_history.index, [
        [':recipient_id', recipientId],
        [':journey_id', journeyId]
      ]);
      axios.get(url, { params: params }).then((response: any) => {
        const searchResult: APIPaginatedSearchResult<CurrentStateHistory> = response.data.state_history;
        const responseData = {
          records: searchResult.entries,
          count: searchResult.count,
        };
        resolve({ success: true, responseData: responseData });
      }).catch((error: any) => {
        console.warn(error);
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  loadStateHistory({ commit, getters, rootGetters }, { recipientId, journeyId, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.state_history.show, [
        [':recipient_id', recipientId],
        [':journey_id', journeyId],
        [':id', id]
      ]);
      axios.get(url).then((response: any) => {
        const responseData: APIStateHistoryResponse = response.data;
        resolve({ success: true, responseData: responseData });
      }).catch((error: any) => {
        console.warn(error);
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  saveStateHistory({ getters }, { payload, recipientId, journeyId, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any; // will be function
      let ep: string;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.state_history.update, [[':recipient_id', recipientId], [':journey_id', journeyId], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.state_history.create, [[':recipient_id', recipientId], [':journey_id', journeyId]]);
      }
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // TODO: TECH_DEBT remove recipientJourney actions in favour of UI view models and pinia client state
          useCurrentPageStore().currentRecipient?.load({ reload: true });
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
};
