import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content-wrap"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  key: 1,
  class: "content-wrap"
}
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-12" }
const _hoisted_7 = { class: "page-title mb-2" }
const _hoisted_8 = { class: "mb-3 text-dark" }
const _hoisted_9 = { class: "page-content p-0" }
const _hoisted_10 = { class: "form-row" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "form-row" }
const _hoisted_16 = { class: "col form-inline" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { class: "card summary-card" }
const _hoisted_24 = { class: "card-header" }
const _hoisted_25 = { class: "media align-items-center" }
const _hoisted_26 = { class: "media-left" }
const _hoisted_27 = { class: "media-body flex-row align-items-center" }
const _hoisted_28 = { class: "summary-name" }
const _hoisted_29 = { class: "card-body" }
const _hoisted_30 = {
  key: 0,
  class: "row"
}
const _hoisted_31 = { class: "col-12 pb-3" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col-md-4 col-lg-4 col-xl-3" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col-md-4 col-lg-4 col-xl-3" }
const _hoisted_36 = { class: "col-md-4 col-lg-4 col-xl-3" }
const _hoisted_37 = { class: "col-md-4 col-lg-4 col-xl-3" }
const _hoisted_38 = { class: "col-md-4 col-lg-4 col-xl-3" }
const _hoisted_39 = { class: "row" }
const _hoisted_40 = { class: "col-12" }
const _hoisted_41 = { class: "form-inline action-row mb-0" }
const _hoisted_42 = { class: "form-group mr-2 mb-0" }
const _hoisted_43 = ["disabled"]
const _hoisted_44 = { class: "form-group mr-2 mb-0" }
const _hoisted_45 = ["download"]
const _hoisted_46 = { class: "col-12" }
const _hoisted_47 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_report_view = _resolveComponent("loading-report-view")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_column_config = _resolveComponent("column-config")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('waitlist')), 1)
      ]),
      _: 1
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_loading_report_view)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_validation_observer, {
              id: "waitlist-report-filters",
              ref: "waitlistReportFilters",
              autocomplete: "off",
              tag: "form"
            }, {
              default: _withCtx(({ handleSubmit }) => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('customizable_waitlist')), 1),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('form_below_text')), 1),
                    _createElementVNode("p", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('detailed_text')) + " ", 1),
                      _createVNode(_component_router_link, { to: { name: 'reports'} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('reports_page')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                _createElementVNode("div", _hoisted_9, [
                  (!_ctx.editState || !_ctx.editState.filters)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_select_input, {
                              "select-id": "waitlist-report-filters-organ",
                              name: _ctx.$t('organ'),
                              modelValue: _ctx.editState.filters.organ,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.filters.organ) = $event)),
                              options: _ctx.waitlistOrganOptions,
                              rules: "required",
                              disabled: _ctx.waitlistOrganOptions && _ctx.waitlistOrganOptions.length <= 1,
                              onChange: _ctx.onOrganFilterChange
                            }, null, 8, ["name", "modelValue", "options", "disabled", "onChange"])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_select_input, {
                              multiple: true,
                              name: "Transplant Program",
                              label: _ctx.$t('transplant_program'),
                              selectId: "waitlist-report-filters-transplant-program",
                              validationId: "waitlist-report-filters-transplant-program",
                              placeholder: `${_ctx.editState.filters.transplantPrograms.length == 0 ? _ctx.$t('all'): ''}`,
                              disabled: _ctx.waitlistTransplantProgramOptionsAsLookup && _ctx.waitlistTransplantProgramOptionsAsLookup.length <= 1,
                              options: _ctx.waitlistTransplantProgramOptionsAsLookup,
                              saveWithAttribute: "text",
                              tagClass: "invalid-transplant-program",
                              modelValue: _ctx.editState.filters.transplantPrograms,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.filters.transplantPrograms) = $event)),
                              uppercase: true
                            }, null, 8, ["label", "placeholder", "disabled", "options", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_select_input, {
                              "select-id": "waitlist-report-filters-donor-type",
                              name: _ctx.$t('preferred_donor_type'),
                              value: _ctx.editState.filters.donorType,
                              options: _ctx.waitlistDonorTypeOptions,
                              undefinedText: "All",
                              disabled: _ctx.disablePreferredDonorTypeFilter
                            }, null, 8, ["name", "value", "options", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_select_input, {
                              multiple: true,
                              name: "Blood Type",
                              label: _ctx.$t('blood_type'),
                              selectId: "waitlist-report-filters-blood-type",
                              validationId: "waitlist-report-filters-blood-type",
                              placeholder: `${_ctx.editState.filters.bloodTypes.length == 0 ? _ctx.$t('all'): ''}`,
                              disabled: _ctx.isCoordinator,
                              options: _ctx.waitlistBloodTypeOptionsAsLookup,
                              saveWithAttribute: "text",
                              modelValue: _ctx.editState.filters.bloodTypes,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.filters.bloodTypes) = $event)),
                              uppercase: true
                            }, null, 8, ["label", "placeholder", "disabled", "options", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _createVNode(_component_checkbox_input, {
                                "input-id": "waitlist-report-filters-cluster",
                                modelValue: _ctx.editState.filters.cluster,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.filters.cluster) = $event)),
                                label: _ctx.$t('include_cluster_recipients')
                              }, null, 8, ["modelValue", "label"])
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_checkbox_input, {
                                "input-id": "waitlist-report-filters-serum-hold",
                                modelValue: _ctx.editState.filters.serumHold,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.filters.serumHold) = $event)),
                                label: _ctx.$t('include_recipients_serum_hold')
                              }, null, 8, ["modelValue", "label"])
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_checkbox_input, {
                                "input-id": "waitlist-report-filters-medical-hold",
                                modelValue: _ctx.editState.filters.medicalHold,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.filters.medicalHold) = $event)),
                                label: _ctx.$t('include_recipients_medical_hold')
                              }, null, 8, ["modelValue", "label"])
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createVNode(_component_checkbox_input, {
                                "input-id": "waitlist-report-filters-suspended",
                                modelValue: _ctx.editState.filters.suspended,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.filters.suspended) = $event)),
                                label: _ctx.$t('include_suspended_recipients')
                              }, null, 8, ["modelValue", "label"])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_save_toolbar, {
                              class: "col-12 mt-2 action-row",
                              buttonClass: "btn btn-success",
                              ref: "generateWaitlistReport",
                              savingText: _ctx.$t('generating'),
                              successText: _ctx.$t('report_generated'),
                              defaultErrorText: _ctx.$t('error_text'),
                              onSave: ($event: any) => (handleSubmit(_ctx.generateWaitlistReport))
                            }, {
                              label: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: ['far', 'file-chart-line'],
                                  class: "mr-2"
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('generate_custom_report')), 1)
                              ]),
                              _: 2
                            }, 1032, ["savingText", "successText", "defaultErrorText", "onSave"])
                          ])
                        ])
                      ], 64)),
                  _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)),
                  _createVNode(_component_sub_section, { subSectionId: "waitlist-results-summary" }, {
                    contents: _withCtx(() => [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: ['far', 'file-chart-line'],
                                "fixed-width": ""
                              })
                            ]),
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("h3", _hoisted_28, _toDisplayString(_ctx.$t('custom_report_summary')), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                          (!_ctx.waitlistQueryResult)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('generate_form_text')), 1)
                              ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("div", _hoisted_32, [
                                  _createElementVNode("dl", _hoisted_33, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('patients_matching_criteria')), 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.waitlistResultSummary.totalMatching), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("dl", _hoisted_35, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('cluster_recipients')), 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.waitlistResultSummary.totalClustered), 1)
                                  ]),
                                  _createElementVNode("dl", _hoisted_36, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('recipients_on_medical_hold')), 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.waitlistResultSummary.totalMedicalHold), 1)
                                  ]),
                                  _createElementVNode("dl", _hoisted_37, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('recipients_on_serum_hold')), 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.waitlistResultSummary.totalSerumHold), 1)
                                  ]),
                                  _createElementVNode("dl", _hoisted_38, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('suspended_recipients')), 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.waitlistResultSummary.totalSuspended), 1)
                                  ])
                                ])
                              ], 64))
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", _hoisted_42, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-primary btn-sm",
                            disabled: !_ctx.waitlistQueryResult,
                            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.printSummary && _ctx.printSummary(...args)))
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['far', 'print'],
                              class: "mr-2"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('print')), 1)
                          ], 8, _hoisted_43)
                        ]),
                        _createElementVNode("div", _hoisted_44, [
                          _createElementVNode("a", {
                            href: "#",
                            download: `csv_report_${(new Date()).toLocaleDateString()}.csv`,
                            ref: "waitlistCsvReport",
                            class: _normalizeClass(`btn btn-primary btn-sm ${!_ctx.waitlistQueryResult ? 'disabled' : ''}`),
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.downloadCSV && _ctx.downloadCSV(...args)))
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['far', 'file-csv'],
                              class: "mr-2"
                            }),
                            _cache[10] || (_cache[10] = _createTextVNode(" Download CSV "))
                          ], 10, _hoisted_45)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 512)
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_46, [
            _createVNode(_component_column_config, {
              columnKey: "waitlist",
              "config-id": "waitlist-report-columns",
              options: _ctx.waitlistGridColumns,
              modelValue: _ctx.editState.selectedColumns,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.selectedColumns) = $event))
            }, null, 8, ["options", "modelValue"])
          ]),
          _createVNode(_component_sub_section, {
            subSectionId: "waitlist-report-grid",
            title: "",
            tableWrapperClass: "",
            tableConfig: _ctx.waitlistGridTableConfig
          }, {
            "table-cell": _withCtx((props) => [
              (props.column.field == 'client_id')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "table-link",
                    to: { name: 'edit-recipient', params: { id: props.row.client_id } },
                    title: `Link to Recipient Page`,
                    alt: props.row.client_id,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.formattedRow[props.column.field]), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "alt"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(props.formattedRow[props.column.field] != null ? props.formattedRow[props.column.field] : '-'), 1))
            ]),
            _: 1
          }, 8, ["tableConfig"])
        ]))
  ]))
}