import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_address_form = _resolveComponent("address-form")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    "sub-section-id": "proto_living_donor_patient_address",
    title: _ctx.$t('patient_address_title')
  }, {
    contents: _withCtx(() => [
      _createVNode(_component_table_toolbar, {
        createButton: _ctx.showCreateButton,
        createText: _ctx.$t('address_create_button'),
        onTableCreateRow: _ctx.handleTableCreateRow
      }, {
        "button-bar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_filter_component, {
                fieldID: "protoLDAddressFilter",
                showFilter: true,
                showArchived: true,
                onSetFilters: ($event: any) => ([])
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["createButton", "createText", "onTableCreateRow"]),
      _createVNode(_component_table_list, {
        ref: "protoLDPatientAddressTable",
        "table-id": "proto-ld-patient-address-table",
        "table-config": _ctx.patientAddressTableConfig,
        onTableRowClick: _ctx.editSelected,
        highlightSelection: true,
        tabbableColumn: "lastUpdatedDisplay",
        rowStyleClass: _ctx.rowStyleClass
      }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
      _createVNode(_component_address_form, {
        "form-id": "proto-ld-patient-address-form",
        ref: "protoLDPatientAddressForm",
        selection: _ctx.selection,
        newRecipient: _ctx.newRecipient,
        canSave: _ctx.canSave,
        onSave: _ctx.performSave,
        onCancel: _ctx.resetSelection
      }, null, 8, ["selection", "newRecipient", "canSave", "onSave", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}