import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-flat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_allocation_controls = _resolveComponent("allocation-controls")!
  const _component_allocation_details = _resolveComponent("allocation-details")!
  const _component_donor_checklist = _resolveComponent("donor-checklist")!
  const _component_allocation_recommendation_listing = _resolveComponent("allocation-recommendation-listing")!
  const _component_allocation_offer_response = _resolveComponent("allocation-offer-response")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "donor-allocation-eoffer",
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loaded()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('allocation_e_offer')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_allocation_controls, {
        ref: "allocationControls",
        onReloadAllocations: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reloadAllocations()))
      }, null, 512),
      (!_ctx.isLoadingAllocations)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.allocation)
              ? (_openBlock(), _createBlock(_component_allocation_details, {
                  key: 0,
                  ref: "allocationDetails"
                }, null, 512))
              : _createCommentVNode("", true),
            (_ctx.allocation && _ctx.canReadChecklists)
              ? (_openBlock(), _createBlock(_component_donor_checklist, {
                  key: 1,
                  canSave: _ctx.canWriteChecklists
                }, null, 8, ["canSave"]))
              : _createCommentVNode("", true),
            (_ctx.allocation)
              ? (_openBlock(), _createBlock(_component_allocation_recommendation_listing, {
                  key: 2,
                  ref: "allocationRecommendationListings"
                }, null, 512))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
      (_ctx.allocation && _ctx.allPrimaryBackupOffers.length > 0)
        ? (_openBlock(), _createBlock(_component_allocation_offer_response, {
            key: 1,
            ref: "allocationOffers"
          }, null, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}