<template>
  <div v-if="isProtoSmallBowelSpecificDetailsEnabled">
    <card-section
      section-id="proto_small_bowel_specific_details"
    >
      <template v-slot:header>
        Small Bowel Specific Details
      </template>
      <template v-slot:body>
        <proto-small-bowel-information />
      </template>
    </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ProtoSmallBowelInformation from '@/prototypes/organs/bowel/ProtoSmallBowelInformation.vue';

import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';


@Component({
  components: {
    CardSection,
    ProtoSmallBowelInformation
  }
})
export default class ProtoKidneySpecificDetails extends Vue {
  get isProtoSmallBowelSpecificDetailsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SmallBowelSpecificDetails);
  }
}
</script>
