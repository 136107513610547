<template>
  <sub-section
    :title="`${$t('organ_recovery_data')}`"
    sub-section-id="organ-recovery-data"
    @save="savePatch()"
    :save-button-text="`${$t('save_recovery_details')}`"
    :saveButton="canSave"
    :disabled="!canSave"
    ref="saveOrganRecoveryData"
    @loaded="loaded()"
    >
    <template v-slot:contents v-if="editState">
      <fieldset :disabled="!canSave" class="zero-min-width" :class="{ active: donorConsentedOrganRecovery.length > 0 }">
        <div>
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th rowspan="2" scope="col" width="10%">
                  {{$t('recovery_organ')}}
                </th>
                <th rowspan="2" scope="col" class="text-center reduce-width" width="10%">
                  {{$t('recovery_recovered')}}
                </th>
                <th rowspan="2" scope="col" class="text-center reduce-width" width="10%">
                  {{$t('recovery_vivo')}}
                </th>
                <th rowspan="2" scope="col" class="text-center middle-width" width="10%">
                  {{$t('recovery_vessels_recovered')}}
                </th>
                <th rowspan="2" scope="col" class="text-center" width="10%">
                  {{$t('recovery_transplant_destination')}}
                </th>
                <th rowspan="2" scope="col" class="text-center">
                  {{$t('recovery_outofprovince_transplant_destination')}}
                </th>
                <th rowspan="2" scope="col" class="text-center" v-if="deceasedDonor.consented_for_research">
                  {{$t('recovery_for_research_destination')}}
                </th>
                <th colspan="4" scope="col" class="text-center">
                  {{$t('recovery_organ_delivery_status')}}
                </th>
              </tr>
              <tr>
                <th scope="col" class="text-center date-width">
                  {{$t('recovery_shipped')}}
                </th>
                <th scope="col" class="text-center">
                  {{$t('recovery_shipping_method')}}
                </th>
                <th scope="col" class="text-center date-width">
                  {{$t('recovery_shipping_delivered')}}
                </th>
                <th scope="col" class="text-center">
                  {{$t('recovery_shipping_received_by')}}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="donorConsentedOrganRecovery.length === 0">
                <td colspan="100%" class="text-center">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                  {{$t('recovery_no_donations')}}
                </td>
              </template>
              <template v-else v-for="(rowName, idx) in donorConsentedOrganRecovery">
                <tr v-bind:key="idx" v-if="editState[rowName.organ_donation_id]">
                  <th>{{ rowName.label}}</th>
                  <td class="td-check reduce-width" width="10%">
                    <table-checkbox-input
                      :input-id="`or-recovered-${rowName.organ_donation_id}`"
                      v-model="editState[rowName.organ_donation_id].recovered"
                      :label="$t('toggle_selection_recovered') + rowName.label + $t('for_cooler_sheet')"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                  </td>
                  <td class="td-check reduce-width" width="10%">
                    <table-checkbox-input
                      :input-id="`or-ex-vivo-used-${rowName.organ_donation_id}`"
                      v-model="editState[rowName.organ_donation_id].exVivoUsed"
                      :label="$t('toggle_selection_ex_vivo') + rowName.label + $t('for_cooler_sheet')"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                  </td>
                  <td class="td-check middle-width" width="10%">
                    <table-checkbox-input
                      :input-id="`or-vessels-recovered-transplant-${rowName.organ_donation_id}`"
                      v-model="editState[rowName.organ_donation_id].vesselsRecoveredForTransplant"
                      :label="$t('toggle_selection_vessels') + rowName.label + $t('for_cooler_sheet')"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                  </td>
                  <td width="10%">
                    {{editState[rowName.organ_donation_id].transplantDestinationIdentifier}}
                  </td>
                  <td>
                    <select-input
                      :selectId="`or-oop-transplant-destination-${rowName.organ_donation_id}`"
                      :name="$t('drop_down_for') + rowName.organ_donation_id + $t('out_of_province')"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].oopTransplantDestination"
                      :options="outOfProvinceTransplantOptions"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                  </td>
                  <td v-if="deceasedDonor.consented_for_research">
                    <select-input
                      :selectId="`or-research-destination-${rowName.organ_donation_id}`"
                      :name="$t('drop_down_for') + rowName.label + $t('recovered_for_research')"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].recoveredResearchDestination"
                      :options="hospitalOptions(rowName.organ_code)"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                  </td>
                  <td>
                    <date-input
                      :inputId="`or-shipped-date-${rowName.organ_donation_id}`"
                      :name="$t('date_field_for') + rowName.label + $t('organ_delivery_status_date_shipped')"
                      direction="left"
                      class="date-width"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].odsShippedDate"
                      :rules="{ required: editState[rowName.organ_donation_id].odsShippedTime }"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                    <time-input
                      :inputId="`or-shipped-time-${rowName.organ_donation_id}`"
                      :name="$t('time_field_for') + rowName.label + $t('organ_delivery_status_time_shipped')"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].odsShippedTime"
                      :rules="{ required: editState[rowName.organ_donation_id].odsShippedDate }"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                    <checkbox-input
                      :inputId="`or-export-organ-${idx}`"
                      :name="$t('hsh_oop_export')"
                      :label="$t('hsh_oop_export')"
                      v-model="editState[rowName.organ_donation_id].exportOop"
                      v-if="isHeartOfferOop(rowName.organ_donation_id)"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                    <checkbox-input
                      :inputId="`or-export-organ-${idx}`"
                      :name="$t('hsp_oop_export')"
                      :label="$t('hsp_oop_export')"
                      v-model="editState[rowName.organ_donation_id].exportOop"
                      v-if="isKidneyOfferOop(rowName.organ_donation_id)"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    />
                  </td>
                  <td>
                    <select-other-input
                      :selectId="`or-shipping-method-${rowName.organ_donation_id}`"
                      :name="$t('drop_down_for') + rowName.label + $t('shipping_method')"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].odsShippingMethod"
                      :options="OrganDeliveryStatusLookup"
                      :stackInputs="true"
                      colStyling="form-group selectWithOther col-12"
                      @change="clearShippingMethodOther(rowName.organ_donation_id)"
                      :disabled="!isGroupWriteable('donor_recovery')"
                    > <br/><br/>
                      <template v-slot:other>
                        <text-input
                          :input-id="`or-shipping-method-other-${rowName.organ_donation_id}`"
                          :name="$t('text_field_for') + rowName.label + $t('shipping_method_other')"
                          :hideLabel="true"
                          v-model="editState[rowName.organ_donation_id].odsShippingMethodOther"
                          :disabled="!isGroupWriteable('donor_recovery')"
                        />
                      </template>
                    </select-other-input>
                  </td>
                  <td>
                    <date-input
                      :inputId="`or-delivered-date-${rowName.organ_donation_id}`"
                      :name="$t('date_field_for') + rowName.label + $t('organ_delivery_status_date_delivered')"
                      :hideLabel="true"
                       direction="left"
                      class="date-width"
                      :rules="{ required: editState[rowName.organ_donation_id].odsDeliveredTime }"
                      v-model="editState[rowName.organ_donation_id].odsDeliveredDate"
                      :disabled="!isGroupWriteable('donor_recovery_date')"
                    />
                    <time-input
                      :inputId="`or-delivered-time-${rowName.organ_donation_id}`"
                      :name="$t('time_field_for') + rowName.label + $t('organ_delivery_status_time_delivered')"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].odsDeliveredTime"
                      :rules="{ required: editState[rowName.organ_donation_id].odsDeliveredTime }"
                      :disabled="!isGroupWriteable('donor_recovery_date')"
                    />
                  </td>
                  <td>
                    <text-input
                      :inputId="`or-received-by-${rowName.organ_donation_id}`"
                      :name="$t('text_field_for') + rowName.label + $t('organ_delivery_received_by')"
                      :hideLabel="true"
                      v-model="editState[rowName.organ_donation_id].odsReceivedBy"
                      :disabled="!isGroupWriteable('donor_recovery_date')"
                    />
                  </td>
                </tr>
              </template>

            </tbody>
          </table>
        </div>

        <div>
          <table class="table table-bordered table-hover" v-if="vesselsForBankingId">
            <thead>
              <tr>
                <th rowspan="2" scope="col" width="10%">
                  {{$t('recovery_organ')}}
                </th>
                <th rowspan="2" scope="col" class="text-center reduce-width">
                  {{$t('recovery_recovered')}}
                </th>
                <th rowspan="2" scope="col" class="text-center">
                  {{$t('recovery_destination')}}
                </th>
                <th colspan="2" scope="col" class="text-center">
                  {{$t('recovery_organ_transport_status')}}
                </th>
              </tr>
              <tr>
                <th scope="col" class="text-center">
                  {{$t('recovery_organ_transport_shipped')}}
                </th>
                <th scope="col" class="text-center">
                  {{$t('recovery_organ_transport_delivered')}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{{$t('recovery_vessels_for_banking')}}</th>
                <td class="td-check reduce-width">
                  <table-checkbox-input
                    input-id="or-vessels-banking-recovered"
                    :label="$t('toggle_selection_vessels_banking')"
                    v-model="editState[vesselsForBankingId].recovered"
                    :disabled="!isGroupWriteable('donor_recovery')"
                  />
                </td>
                <td>
                  <select-input
                    selectId="or-vessels-banking-destination`"
                    :name="$t('drop_down_vessels_banking')"
                    :hideLabel="true"
                    :options="ontarioTransplantOptions"
                    v-model="editState[vesselsForBankingId].transplantDestination"
                    :disabled="!isGroupWriteable('donor_recovery')"
                  />
                </td>
                <td>
                  <date-input
                    input-id="or-vessels-banking-shipped-date"
                    :validationId="`or-shipped-date-${vesselsForBankingId}`"
                    :name="$t('date_field_transport_shipped_date')"
                    :hideLabel="true"
                    v-model="editState[vesselsForBankingId].odsShippedDate"
                    :rules="{ required: editState[vesselsForBankingId].odsShippedTime }"
                    :disabled="!isGroupWriteable('donor_recovery')"
                  />
                  <time-input
                    input-id="or-vessels-banking-shipped-time"
                    :validationId="`or-shipped-time-${vesselsForBankingId}`"
                    :name="$t('time_field_transport_shipped_time')"
                    :hideLabel="true"
                    v-model="editState[vesselsForBankingId].odsShippedTime"
                    :rules="{ required: editState[vesselsForBankingId].odsShippedDate }"
                    :disabled="!isGroupWriteable('donor_recovery')"
                  />
                </td>
                <td>
                  <date-input
                    input-id="or-vessels-banking-delivered-date"
                    :validationId="`or-delivered-date-${vesselsForBankingId}`"
                    :name="$t('date_field_transport_delivered_date')"
                    :hideLabel="true"
                    v-model="editState[vesselsForBankingId].odsDeliveredDate"
                    :rules="{ required: editState[vesselsForBankingId].odsDeliveredTime }"
                    :disabled="!isGroupWriteable('donor_recovery')"
                  />
                  <time-input
                    input-id="or-vessels-banking-delivered-time"
                    :validationId="`or-delivered-time-${vesselsForBankingId}`"
                    :name="$t('time_field_transport_delivered_time')"
                    :hideLabel="true"
                    v-model="editState[vesselsForBankingId].odsDeliveredTime"
                    :rules="{ required: editState[vesselsForBankingId].odsDeliveredDate }"
                    :disabled="!isGroupWriteable('donor_recovery')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <modal-section
          v-if="editState"
          modalId="export-outcome-modal"
          ref="exportOutcomeModal"
          :modalClass="`modal-content ${modalState.organShipModal ? modalState.organShipModal.style : ''}`"
          :centered="true"
        >
          <template v-slot:title>
            {{ $t('export_to_ctr_modal_title') }}
          </template>
          <template v-slot:body v-if="modalState.organShipModal">
            {{modalState.organShipModal.body}}
          </template>
          <template v-slot:footer>
            <div class="modal-footer-body">
              <button
                type=button
                class="btn btn-success"
                @click="toggleModal()"
              >
                {{ $t('ok') }}
              </button>
            </div>
          </template>
        </modal-section>
      </fieldset>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/RecoveryDetails.json"></i18n>
<i18n src="../_locales/Organs.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { ObjectId } from "@/store/types";
import { Getter, State } from 'vuex-facing-decorator';
import { GenericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { faLoveseat } from "@fortawesome/pro-solid-svg-icons";
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import TableCheckboxInput from '@/components/shared/TableCheckboxInput.vue';
import { Hospital, ACTIVE_REGION_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { DeceasedDonor, DeceasedDonorOrganRecovery, DonorConsentedOrganRecoveryForm, 
DeceasedDonorOrganDonations, PromiseResult, OrganDonationResponseType } from '@/store/deceasedDonors/types';
import { useCurrentPageStore } from "@/stores/currentPage";

// Form schemas
export interface OrganDonationsForm {
  [key: string]: OrganRecoveryForm;
}

interface ModalContentState {
  style?: string;
  body?: string;
}

interface OrganRecoveryForm {
  _id?: string;
  recovered?: boolean;
  exVivoUsed?: boolean;
  vesselsRecoveredForTransplant?: boolean;
  transplantDestinationIdentifier?: string;
  transplantDestination?: string|null;
  oopTransplantDestination?: string;
  recoveredResearchDestination?: string;
  odsShippedDate?: string|null;
  odsShippedTime?: string|null;
  odsShippingMethod?: number;
  odsShippingMethodOther?: string;
  odsDeliveredDate?: string|null;
  odsDeliveredTime?: string|null;
  odsReceivedBy?: string;
  exportOop?: boolean;
}

@Component({
  components: {
    SubSection,
    CardSection,
    TextInput,
    DateInput,
    TimeInput,
    SelectInput,
    ModalSection,
    CheckboxInput,
    SelectOtherInput,
    TableCheckboxInput,
  }
})
// TODO: still waiting on data for this components so it will need to be reworked a bit soon
export default class OrganRecoveryData extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.hospitals.all) private hospitals!: Hospital[];
  @State(state => state.lookups.organ_delivery_status) OrganDeliveryStatusLookup!: GenericCodeValue[];
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.organRecovery) editState!: { [key: string]: OrganRecoveryForm };

  // TODO: TECH_DEBT: remove 'any' in favour of string typing
  @State(state => state.pageState.currentPage) modalState!: any;

  @Getter('province', { namespace: 'lookups' }) private provinceLookup: any;
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('organRecoveryRowData', { namespace: 'deceasedDonors' }) private organRecoveryRowData: any;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('ontarioTransplantOptions', { namespace: 'hospitals' }) ontarioTransplantOptions!: any[];
  @Getter('getAllTransplantProgramsByOrgan', { namespace: 'hospitals' }) getAllTransplantProgramsByOrgan!: (organ_code: number[]) => any;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;

  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['country', 'organ_delivery_status'];

  // Build options list for Transplant Province
  get outOfProvinceTransplantOptions(): { code: string; value: string }[] {
    if (!this.provinceLookup) {
      return [];
    }
    // Exclude Ontario
    const filtered = this.provinceLookup.filter((option: { code: string; value: string }) => {
      return !useCurrentPageStore().configuration.regions.includes(option.code);
    });
    return filtered;
  }

  // get the id for the vessels for banking organ donation
  get vesselsForBankingId(): string|undefined {
    if(!this.deceasedDonor?.organ_donations) {
      return undefined;
    }
    const vessels = this.deceasedDonor.organ_donations!.find((element: DeceasedDonorOrganDonations) => {
      return element.organ_code == OrganCodeValue.VesselsForBanking;
    });
    return vessels?._id;
  }

  /**
   * Get a select-input friendly list of ontario hospital transplant programs for the specified organ
   *
   * Create a list of filtered transplant hospitals containing the code and hospital name
   *
   * @returns {[{code: number, value: string}]} collection of transplant hospitals
   */
  public hospitalOptions(organ_code: number): { code: string; value: string }[] {
    return this.getAllTransplantProgramsByOrgan([organ_code]).map((hospital: Hospital) => {
      return {
        code: hospital._id,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
      };
    });
  }


  // Clear Shipping Method other when Shipping Method changes
  public clearShippingMethodOther(organ_donation_id: string) {
    this.editState[organ_donation_id].odsShippingMethodOther = undefined;
  }

  // public

  public buildOrganRecoveryForm(deceasedDonor?: DeceasedDonor): OrganDonationsForm {
    const organDonations = deceasedDonor?.organ_donations || [];
    const result: OrganDonationsForm = Object.assign({}, this.buildDonorOrganRecovery(organDonations));

    return result;
  }

  public buildDonorOrganRecovery(organDonations?: DeceasedDonorOrganDonations[]): OrganDonationsForm {
    const organRecoveryObject: OrganDonationsForm = {};

    this.donorConsentedOrganRecovery.forEach((organ: any, index: any) => {
      const organRecovery = organDonations!.find((organDonation: DeceasedDonorOrganDonations) => {
        return organDonation?._id === organ.organ_donation_id;
      });


      if (organRecovery && organRecovery.organ_recovery) {
        const recoveredOrgan = organRecovery.organ_recovery[0];

        // test if exporting OOP (hsh heart or hsp kidney)
        const exportOop = this.isHeartOfferOop(organ.organ_donation_id) || this.isKidneyOfferOop(organ.organ_donation_id);

        organRecoveryObject[organ.organ_donation_id] = {
          recovered: recoveredOrgan.organ_recovered,
          exVivoUsed: recoveredOrgan.ex_vivo,
          vesselsRecoveredForTransplant: recoveredOrgan.vessels_recovered,
          transplantDestinationIdentifier: recoveredOrgan.destination_program_identifier,
          oopTransplantDestination: recoveredOrgan.destination_oop,
          recoveredResearchDestination: recoveredOrgan.destination_research ? recoveredOrgan.destination_research : undefined,
          odsShippedDate: recoveredOrgan.shipped_date ? this.parseDateUiFromDateTime(recoveredOrgan.shipped_date) : null,
          odsShippedTime: recoveredOrgan.shipped_date ? this.parseTimeUiFromDateTime(recoveredOrgan.shipped_date) : null,
          odsShippingMethod: recoveredOrgan.shipping_method_code,
          odsShippingMethodOther: recoveredOrgan.shipping_method_other,
          odsDeliveredDate: recoveredOrgan.delivered_date ? this.parseDateUiFromDateTime(recoveredOrgan.delivered_date) : null,
          odsDeliveredTime: recoveredOrgan.delivered_date ? this.parseTimeUiFromDateTime(recoveredOrgan.delivered_date) : null,
          odsReceivedBy: recoveredOrgan.received_by,
          // We look for this value specifically when attempting to ship, all non OOP rows should not have a value here
          exportOop: exportOop ? organRecovery.exported_oop : undefined
        };
      } else {
        organRecoveryObject[organ.organ_donation_id] = {
          recovered: false,
          exVivoUsed: false,
          vesselsRecoveredForTransplant: false,
          // TODO: transplantDestination: recoveredOrgan.,
          oopTransplantDestination: '',
          recoveredResearchDestination: undefined,
          odsShippedDate: undefined,
          odsShippedTime: undefined,
          // TODO: odsShippingMethod: recoveredOrgan.,
          odsDeliveredDate: undefined,
          odsDeliveredTime: undefined,
          odsReceivedBy: '',
          exportOop: false,
        };
      }
    });

    // get vessels to build form
    let vessels;
    if (this.deceasedDonor?.organ_donations) {
      vessels = this.deceasedDonor.organ_donations!.find((element: DeceasedDonorOrganDonations) => {
        return element.organ_code == OrganCodeValue.VesselsForBanking;
      });
    }

    // build vessels for banking form
    if (vessels && vessels.organ_recovery && vessels._id) {
      const vesselsRecovery = vessels.organ_recovery[0];
      organRecoveryObject[vessels?._id] = {
        recovered: vesselsRecovery.organ_recovered,
        transplantDestination: vesselsRecovery.destination_program ? vesselsRecovery.destination_program : undefined,
        odsShippedDate: vesselsRecovery.shipped_date ? this.parseDateUiFromDateTime(vesselsRecovery.shipped_date) : null,
        odsShippedTime: vesselsRecovery.shipped_date ? this.parseTimeUiFromDateTime(vesselsRecovery.shipped_date) : null,
        odsDeliveredDate: vesselsRecovery.delivered_date ? this.parseDateUiFromDateTime(vesselsRecovery.delivered_date) : null,
        odsDeliveredTime: vesselsRecovery.delivered_date ? this.parseTimeUiFromDateTime(vesselsRecovery.delivered_date) : null,
      };
    }

    return organRecoveryObject;
  }

  /**
   * Get a list of organs that are consented to build the organ recovery table
   *
   * Create a filtered list of consented organs
   *
   * @returns {DonorConsentedOrganRecoveryForm[]} collection of consented organs
   */
  get donorConsentedOrganRecovery(): DonorConsentedOrganRecoveryForm[] {
    // Just in case we don't have the organ_donations object ready yet
    if (!this.deceasedDonor?.organ_donations) return [];

    const donatedOrgans = this.deceasedDonor.organ_donations;
    if (!donatedOrgans) return [];

    // Filter out Vessels for Banking, because it has its own separate table
    const filtered: DeceasedDonorOrganDonations[] = donatedOrgans.filter((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation.organ_code != OrganCodeValue.VesselsForBanking;
    });

    // Sort organ donations, so that organ rows for the same organ are grouped together in a certain order
    const sorted = filtered.sort((a: DeceasedDonorOrganDonations, b: DeceasedDonorOrganDonations) => {
      // Primary sort by numeric Organ Code
      let result = Math.sign((a.organ_code || 0) - (b.organ_code || 0));
      if (result != 0) return result;

      // Secondary sort by numeric Organ Specification
      result = Math.sign((a.organ_specification_code || 0) - (b.organ_specification_code || 0));
      if (result != 0) return result;

      // Tertiary sort by Created At date
      const aCreatedAtTimestamp = !!a.created_at ? new Date(a.created_at).getTime() : 0;
      const bCreatedAtTimestamp = !!b.created_at ? new Date(b.created_at).getTime() : 0;
      result = Math.sign(aCreatedAtTimestamp - bCreatedAtTimestamp);
      return result;
    });

    // Map organ donations to the recovery table edit state format
    const organs: DonorConsentedOrganRecoveryForm[] = sorted.map((organDonation: DeceasedDonorOrganDonations) => {
      const organName = this.getOrganSpecificationName(organDonation.organ_code, organDonation.organ_specification_code);
      return {
        label: this.$t(organName).toString(),
        organ_code: organDonation.organ_code || 0,
        organ_specification_code: organDonation.organ_specification_code || null,
        organ_donation_id: organDonation?._id || '',
      };
    });
    return organs;
  }

  /**
   * Return true if the row contains a Heart intended for OOP export
   *
   * @returns {boolean} true if offer is for an OOP Heart
   */
  private isHeartOfferOop(organDonationId: string): boolean {
    // Find the organ_donation item matching the row
    const organDonation = (this.deceasedDonor?.organ_donations || []).find((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation?._id === organDonationId;
    });
    // If none return false
    if (!organDonation) return false;
    // If this is a Heart and oop_recipient
    if (organDonation.organ_code === OrganCodeValue.Heart && organDonation.organ_offer?.oop_recipient) return true;
    return false;
  }

  /**
   * Return true if the row contains a Kindey intended for OOP export
   *
   * @returns {boolean} true if offer is for an OOP Kidney
   */
  private isKidneyOfferOop(organDonationId: string): boolean {
    // Find the organ_donation item matching the row
    const organDonation = (this.deceasedDonor?.organ_donations || []).find((organDonation: DeceasedDonorOrganDonations) => {
      return organDonation?._id === organDonationId;
    });
    // If none return false
    if (!organDonation) return false;
    // If this is a Kidney and oop_recipient
    if (organDonation.organ_code === OrganCodeValue.Kidney && organDonation.organ_offer?.oop_recipient) return true;
    return false;
  }

  // Event handlers
  private mounted(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM).then(() => {
      this.initializeForm();
    });
    this.$emit('loaded', 'donorDataHistory');
  }

  // Public methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'organRecovery',
      value: this.buildOrganRecoveryForm(this.deceasedDonor)
    });
  }


  /**
  * Builds an array of Organ Donations using the OrganRecoveryForm editstate
  *
  * @param organRecoveryObject the OrganRecoveryForm object
  */
  public organRecoveryObjectToArray(organRecoveryObject: OrganDonationsForm): DeceasedDonorOrganDonations[] {
    const organRecoveryArray: DeceasedDonorOrganDonations[] = [];
    this.donorConsentedOrganRecovery.forEach((organ: DonorConsentedOrganRecoveryForm, index: number) => {
      const objectElement = organRecoveryObject[organ.organ_donation_id];

      const organ_donation = {
        _id: organ.organ_donation_id,
        organ_code: organ.organ_code,
        organ_specification_code: organ.organ_specification_code,
        organ_recovery: [this.organRecoveryFormToOrganDonation(objectElement)]
      };
      organRecoveryArray.push(organ_donation);
    });

    let vessels_for_banking = {};

    // build vessels for banking object from editState (organRecoveryObject)
    if (this.vesselsForBankingId) {
      const vessels_recovery = organRecoveryObject[this.vesselsForBankingId] as OrganRecoveryForm;

      vessels_for_banking = {
        _id: this.vesselsForBankingId,
        organ_code: OrganCodeValue.VesselsForBanking,
        organ_recovery: [{
          organ_recovered: vessels_recovery.recovered,
          destination_program: vessels_recovery.transplantDestination ? vessels_recovery.transplantDestination : null,
          shipped_date: vessels_recovery.odsShippedDate ? this.sanitizeDateTimeApi(vessels_recovery.odsShippedDate, vessels_recovery.odsShippedTime || undefined) : null,
          delivered_date: vessels_recovery.odsDeliveredDate ? this.sanitizeDateTimeApi(vessels_recovery.odsDeliveredDate, vessels_recovery.odsDeliveredTime || undefined) : null,
        }]
      };
    }
    // push vessels for banking to array for saving
    organRecoveryArray.push(vessels_for_banking);

    return organRecoveryArray;
  }

  public organRecoveryFormToOrganDonation(recoveryForm: OrganRecoveryForm): DeceasedDonorOrganRecovery {
    return {
      organ_recovered: recoveryForm.recovered,
      ex_vivo: recoveryForm.exVivoUsed,
      vessels_recovered: recoveryForm.vesselsRecoveredForTransplant,
      destination_oop: recoveryForm.oopTransplantDestination,
      destination_research: ( recoveryForm.recoveredResearchDestination ? recoveryForm.recoveredResearchDestination : null),
      shipped_date: recoveryForm.odsShippedDate ? this.sanitizeDateTimeApi(recoveryForm.odsShippedDate, recoveryForm.odsShippedTime || undefined) : null,
      shipping_method_code: recoveryForm.odsShippingMethod,
      shipping_method_other: recoveryForm.odsShippingMethodOther,
      delivered_date: recoveryForm.odsDeliveredDate ? this.sanitizeDateTimeApi(recoveryForm.odsDeliveredDate, recoveryForm.odsDeliveredTime || undefined) : null,
      received_by: recoveryForm.odsReceivedBy
    };
  }

  public extractOrganRecoveryForm(organDonation?: OrganDonationsForm): DeceasedDonor {
    const organRecoveryArray = organDonation ? this.organRecoveryObjectToArray(organDonation) : [];
    const result: DeceasedDonor = {
      organ_donations: organRecoveryArray
    };
    return result;
  }


   /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Heart Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveOrganRecoveryData as unknown as SaveProvider;
    // Report to parent that saving has began - this should the card section ref
    this.$emit('save', 'saveOrganRecoveryData');
    // Generate payload based on current edit state
    const donorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: this.clientId, donor: donorPatch }).then((success: SaveResult) => {

      // build shipping list (collection of promises to ship organs)
      const shippingList = this.buildShippingList();
      // process list
      Promise.allSettled(shippingList).then((results: PromiseSettledResult<SaveResult>[]) => {
        // if something was shipped, handle the outcome
        if (shippingList.length > 0) {
          // have to cast promise array as typescript does not know what type it is
          const typedResults = results as PromiseResult[];
          const fulfilled = typedResults.filter((item: PromiseResult) => { return item.value?.status === 200;} );
          const rejected = typedResults.filter((item: PromiseResult) => { return item.value?.status !== 200;} );
          // // as long as organs have been shipped, check and show outcome modal
          if (rejected.length > 0) {
            // if rejected, show this
            this.showOutcomeModalForShippedOrgan(rejected[0]);
          } else {
            // otherwise show fulfilled popup
            this.showOutcomeModalForShippedOrgan(fulfilled[0]);
          }
        }

        // refresh results and return
        this.$store.dispatch('deceasedDonors/get', this.clientId).then(() => {
          this.$store.dispatch('deceasedDonors/loadOrganDonations', this.clientId).then(() => {
            this.$store.dispatch('deceasedDonors/loadExdConfirmations', this.clientId);
              saveProvider.registerSaveResult(success);
              this.$emit('clear');
              this.initializeForm();
              return results;
          });
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  private showOutcomeModalForShippedOrgan(result: PromiseResult): void {
    const shipResult: OrganDonationResponseType|null = result && result.value ? result.value : null;
    if (shipResult && shipResult.data) {
      // Check the return value of organ_donation(s) to determine if the organ(s) were shipped or not
      const exportedOopResult = !!shipResult.data?.organ_donation?.exported_oop;
      // If we have an organ_donation object in the response then we shipped
      this.displayOutcomeModal(!!shipResult.data?.organ_donation, exportedOopResult);
      // Fetch our donor again so we can show the new exportOop value
    }
  }

  // Attempt to ship/unship our organ if we have any exportOop flag set
  public buildShippingList(): Promise<SaveResult>[] {
    // Array of ids with the exportOop value needed to ship
    const exportOopRows: { [key: string]: boolean }[] = [];

    // Only add items that have an exportOop value (there should only ever be one here)
    for (const [key, value] of Object.entries(this.editState)) {
      if (value.exportOop != undefined) exportOopRows.push({ [key]: !!value.exportOop });
    }
    // Array of shippable organs
    const organsToShip: Promise<SaveResult>[] = [];
    // If we have any exportOopRows
    if ((exportOopRows || []).length > 0) {
      // Check each row for a shipped organ, in case multiple organs are shipped at once
      for (const exportOopRow of exportOopRows) {
        // First key is the organDonationId, First value is the exportOop value
        const [organDonationId, exportOop] = [Object.keys(exportOopRow)[0], Object.values(exportOopRow)[0]];
        // Find the existing value for exported_oop
        const existingExportOopValue = this.deceasedDonor.organ_donations?.find((organDonation: DeceasedDonorOrganDonations) => {
          return organDonation._id === organDonationId;
        });
        // Check if we have a change in value
        const exportOopChanged = existingExportOopValue?.exported_oop != exportOop;
        // If changed, add to promise array of organs to ship
        if (exportOopChanged) {
          organsToShip.push(this.$store.dispatch('deceasedDonors/shipOrgan', { donorId: this.clientId, organDonationId: organDonationId, ship: exportOop }));
        }
      }
    }
    return organsToShip;
  }

  // Display a success notification after a successful export to CTR action
  private displayOutcomeModal(success: boolean, ship: boolean): void {
    // Initialize our modal state
    this.modalState.organShipModal = { style: '', body: '' };
    // Change the content based on the success
    const modalContent = {
      style: `modal-${ success ? 'success' : 'danger' }`,
      body: success ? this.$t(`export_to_ctr_success_${ship ? 'ship' : 'unship'}`) : this.$t('export_to_ctr_fail'),
    };
    this.modalState.organShipModal = modalContent;
    // Show our modal if the ref exists
    if (this.$refs.exportOutcomeModal) (this.$refs.exportOutcomeModal as ModalSection).showModal();
  }

  // Toggle the modal
  private toggleModal(): void {
    // Clear our modal state
    this.modalState.organShipModal = { style: '', body: '' };
    // Close our modal
    (this.$refs.exportOutcomeModal as ModalSection).toggleModal();
  }

  /**
   * Gets changes from the editState as a patch for the journey's Heart Specific Details
   *
   * If the edit state doesn't exist return null
   *
   * @returns {DeceasedDonor} object containing field changes
   */
  public extractPatch(): DeceasedDonor {
    if (!this.editState) {
      return {};
    } else {
      return this.extractOrganRecoveryForm(this.editState);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const organRecoveryData = this.$refs.saveOrganRecoveryData as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    organRecoveryData.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result: { [key: string]: string } = {};
    const organDonations = this.deceasedDonor?.organ_donations || [];
    organDonations.forEach((or: DeceasedDonorOrganDonations, index: number) => {
      const organDonationId = or?._id;
      if (organDonationId) {
        result[`organ_donations[${organDonationId}].organ_recovery[0].organ_recovered`]       = `or-recovered-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].ex_vivo`]               = `or-ex-vivo-used-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].vessels_recovered`]     = `or-vessels-recovered-transplant-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].destination_oop`]       = `or-transplant-destination-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].destination_research`]  = `or-research-destination-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipped_date`]          = `or-shipped-date-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipped_time`]          = `or-shipped-time-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.shipped_date`]                      = `or-shipped-date-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.shipped_date`]                      = `or-shipped-time-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipping_method_code`]  = `or-shipping-method-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].shipping_method_other`] = `or-shipping-method-other-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].delivered_date`]        = `or-delivered-date-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].delivered_time`]        = `or-delivered-time-${organDonationId}`;
        result[`organ_donations[${organDonationId}].organ_recovery[0].received_by`]           = `or-received-by-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.delivered_date`]                    = `or-delivered-date-${organDonationId}`;
        result[`organ_donations.${index}.organ_recovery.0.delivered_date`]                    = `or-delivered-time-${organDonationId}`;
      }
    });
    return result;
  }

}
</script>
