import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "form-text text-muted",
  title: "Calculated"
}
const _hoisted_4 = { class: "mobile-spacing-wrapper" }
const _hoisted_5 = ["id", "readonly", "step", "min", "max"]
const _hoisted_6 = { class: "input-group-append" }
const _hoisted_7 = { class: "input-group-text" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["id", "readonly", "step", "min", "max"]
const _hoisted_12 = ["id"]
const _hoisted_13 = ["id"]
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("label", {
        for: _ctx.inputId,
        class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
      }, [
        _createElementVNode("span", {
          innerHTML: _ctx.label || _ctx.name
        }, null, 8, _hoisted_2),
        _cache[1] || (_cache[1] = _createTextVNode("  ")),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_validation_asterisk, {
              key: 0,
              rules: _ctx.formRules,
              crossValues: _ctx.crossValues,
              ruleKey: _ctx.ruleKey
            }, null, 8, ["rules", "crossValues", "ruleKey"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1),
      (_ctx.calculated)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              class: "text-grey",
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.calculatedText), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, [
        (_ctx.append)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`input-group mb-3 ${_ctx.inputClass}`)
            }, [
              _withDirectives(_createElementVNode("input", _mergeProps({
                id: _ctx.inputId,
                type: "text",
                class: {
              'is-invalid': !_ctx.disabled && errors[0], 
              'form-control': !_ctx.isReadOnly(_ctx.readonly), 
              'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly),
              'is-warning': _ctx.showWarning
            }
              }, field, {
                readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
              }, _toHandlers(_ctx.inputEvents(), true), {
                step: _ctx.step,
                min: _ctx.min,
                max: _ctx.max
              }), null, 16, _hoisted_5), [
                [_directive_maska, _ctx.getMask]
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.appendText), 1)
              ]),
              (errors[0])
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "invalid-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                  ], 8, _hoisted_8))
                : _createCommentVNode("", true),
              (!errors[0] && _ctx.showWarning)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "warning-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createElementVNode("span", { innerHTML: _ctx.getWarningMessage }, null, 8, _hoisted_10)
                  ], 8, _hoisted_9))
                : _createCommentVNode("", true)
            ], 2))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _withDirectives(_createElementVNode("input", _mergeProps({
                id: _ctx.inputId,
                type: "text",
                class: { 
            'is-invalid': !_ctx.disabled && errors[0], 
            'form-control': !_ctx.isReadOnly(_ctx.readonly), 
            'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly),
            'is-warning': _ctx.showWarning
          }
              }, field, {
                readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
              }, _toHandlers(_ctx.inputEvents(), true), {
                step: _ctx.step,
                min: _ctx.min,
                max: _ctx.max
              }), null, 16, _hoisted_11), [
                [_directive_maska, _ctx.getMask]
              ]),
              (errors[0] && !_ctx.disabled)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "invalid-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                  ], 8, _hoisted_12))
                : _createCommentVNode("", true),
              (!errors[0] && _ctx.showWarning)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "warning-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createElementVNode("span", { innerHTML: _ctx.getWarningMessage }, null, 8, _hoisted_14)
                  ], 8, _hoisted_13))
                : _createCommentVNode("", true)
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}