import Vue from 'vue';
import { MutationTree } from 'vuex';
import { LivingDonor, LivingDonorIntendedRecipient, LivingDonorsState, LivingDonorRecoveryInfo } from '@/store/livingDonors/types';

export const mutations: MutationTree<LivingDonorsState> = {
  set(state, livingDonor) {
    // Clone LivingDonor data and sanitize the clone
    const sanitizedLivingDonor: LivingDonor = Object.assign({}, livingDonor);

    // Store sanitized clone
    state.selectedLivingDonor = sanitizedLivingDonor;
  },
  clearLivingDonor(state) {
    state.selectedLivingDonor = undefined;
  },
  setList(state, livingDonors) {
    state.livingDonorsList = livingDonors;
  },
  setMeasurements(state, measurements) {
    state.selectedLivingDonor!.measurements = measurements;
  },
  selectIntendedRecipient(state: LivingDonorsState, intendedRecipient: LivingDonorIntendedRecipient|null) {
    state.selectedIntendedRecipient = intendedRecipient;
  },
  setRecoveryInfo(state: LivingDonorsState, { journeyId, recoveryInfo }): void {
    // Clone LivingDonor data and sanitize the clone
    const sanitizedLivingDonor: LivingDonor = Object.assign({}, state.selectedLivingDonor);
    
    // Now with that data replace the relevant journey with new recovery_info
    sanitizedLivingDonor.living_donor_info!.journeys!.map((journey: any) => {
      if (journey._id == journeyId) { journey.recovery_info = recoveryInfo; }
    });

    // Store the sanitized and updated clone
    state.selectedLivingDonor = sanitizedLivingDonor;
  },
};
