import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_4 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoHeartMyocardialPerfusionEnabled)
    ? (_openBlock(), _createBlock(_component_sub_section, {
        key: 0,
        "sub-section-id": "proto_heart_myocardial_perfusion",
        title: _ctx.title
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: true,
            createText: "Create Myocardial Perfusion Scan",
            onTableCreateRow: _ctx.clearForm
          }, {
            "button-bar": _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_filter_component, {
                    fieldID: "contactFilter",
                    showFilter: false,
                    showArchived: false
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["onTableCreateRow"]),
          _createVNode(_component_table_list, {
            ref: "myocardialPerfusionTable",
            tabbableColumn: "scan_date",
            "table-id": "myocardial-perfusion-table",
            "table-config": _ctx.myocardialPerfusionTableConfig,
            onTableRowClick: _ctx.editSelected,
            highlightSelection: true,
            rowStyleClass: _ctx.rowStyleClass
          }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
          _createVNode(_component_form_layout, { "form-id": "echoResult-form" }, {
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                (_ctx.isNew)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " NEW MYOCARDIAL PERFUSION SCAN "))
                  : (_openBlock(), _createElementBlock("h5", _hoisted_4, " SELECTED MYOCARDIAL PERFUSION SCAN "))
              ])
            ]),
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_date_input, {
                    inputId: "myocardial-scan_date",
                    name: "Scan Date",
                    modelValue: _ctx.editState.scan_date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.scan_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_select_input, {
                    "select-id": "rhythm",
                    name: "Rhythm",
                    options: _ctx.rhythmOptions,
                    modelValue: _ctx.editState.rhythm,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.rhythm) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_select_input, {
                    "select-id": "arrhythmias",
                    name: "Arrhythmias",
                    options: _ctx.arrhythmiasOptions,
                    modelValue: _ctx.editState.arrhythmias,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.arrhythmias) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_select_input, {
                    "select-id": "iv_contrast",
                    name: "IV Contrast",
                    options: _ctx.yesNoOptions,
                    modelValue: _ctx.editState.iv_contrast,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.iv_contrast) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_select_input, {
                    "select-id": "ischemia",
                    name: "Ischemia",
                    options: _ctx.yesNoOptions,
                    modelValue: _ctx.editState.ischemia,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.ischemia) = $event))
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_number_input, {
                    inputId: "heart_rate",
                    name: "Heart Rate",
                    append: true,
                    appendText: "bpm",
                    modelValue: _ctx.editState.heart_rate,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.heart_rate) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_number_input, {
                    inputId: "heart_bp_systolic",
                    name: "BP Systolic",
                    append: true,
                    appendText: "mmHg",
                    modelValue: _ctx.editState.bp_systolic,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.bp_systolic) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_number_input, {
                    inputId: "heart_bp_diastolic",
                    name: "BP Diastolic",
                    append: true,
                    appendText: "mmHg",
                    modelValue: _ctx.editState.bp_diastolic,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.bp_diastolic) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_text_area_input, {
                    "input-id": "outcome",
                    name: "Outcome",
                    rows: "4",
                    modelValue: _ctx.editState.outcome,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.outcome) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveScan",
                label: "Save Myocardial Perfusion Scan",
                cancelButton: true,
                onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.performSave())),
                onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clearForm()))
              }, null, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}