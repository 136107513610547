import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card tip-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "content-wrap" }
const _hoisted_7 = { class: "container-fluid" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "form-group col-md-12 col-lg-5" }
const _hoisted_10 = { class: "form-group col-md-12 col-lg-5" }
const _hoisted_11 = { class: "form-group col-md-12 col-lg-2" }
const _hoisted_12 = { class: "form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    subSectionId: "dialysis-report",
    lookupsToLoad: _ctx.lookupsToLoad
  }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'file-chart-line'],
                      "fixed-width": ""
                    })
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "media-body flex-row align-items-center" }, [
                    _createElementVNode("h3", { class: "tip-report-name" }, " Dialysis Report ")
                  ], -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_validation_observer, {
                      id: "dialysis-filters",
                      ref: "DialysisReportFilters",
                      autocomplete: "off",
                      tag: "form"
                    }, {
                      default: _withCtx(({ handleSubmit }) => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_select_input, {
                              selectId: "dialysis-region",
                              name: _ctx.$t('transplant_hospital'),
                              modelValue: _ctx.editState.transplantHospital,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.transplantHospital) = $event)),
                              options: _ctx.transplantHospitals,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.transplantHospital)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_select_input, {
                              selectId: "dialysis-hospital",
                              name: _ctx.$t('dialysis_provider'),
                              modelValue: _ctx.editState.dialysisProvider,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.dialysisProvider) = $event)),
                              options: _ctx.dialysisProviders,
                              multiple: true,
                              numeric: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.dialysisProvider)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_select_input, {
                              selectId: "dialysis-blood_type",
                              name: _ctx.$t('blood_type'),
                              modelValue: _ctx.editState.bloodType,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.bloodType) = $event)),
                              options: _ctx.bloodTypeLookup,
                              multiple: true,
                              placeholder: _ctx.allOptionsPlaceholderValue(_ctx.editState.bloodType)
                            }, null, 8, ["name", "modelValue", "options", "placeholder"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_save_toolbar, {
                            class: "form-inline action-row",
                            buttonClass: "btn btn-success",
                            ref: "generateReport",
                            savingText: "Generating",
                            successText: "Report generated",
                            defaultErrorText: "There was a problem generating the report",
                            onSave: ($event: any) => (handleSubmit(_ctx.generateReport))
                          }, {
                            label: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, {
                                icon: ['far', 'file-chart-line'],
                                class: "mr-2"
                              }),
                              _cache[4] || (_cache[4] = _createTextVNode(" Generate Dialysis Report "))
                            ]),
                            _: 2
                          }, 1032, ["onSave"])
                        ])
                      ]),
                      _: 1
                    }, 512)
                  ])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["lookupsToLoad"]))
}