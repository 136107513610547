<template>
  <sub-section
    :title="`${$t('recovery_information')}`"
    sub-section-id="recovery-information"
    ref="recoveryInformation"
    @loaded="loaded()"
    :disabled="!hasWriteAccess"
    >
    <template v-slot:contents>
      <template v-if="!editState">
        {{$t('loading')}}.
      </template>
      <template v-else>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <text-input
              inputId="recovery-information-declaration_hospital"
              :name="`${$t('declaration_hospital')}`"
              v-model="editState.declarationHospital"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group">
            <text-input
              inputId="recovery-information-recovery_hospital"
              :name="`${$t('recovery_hospital')}`"
              v-model="editState.recoveryHospital"
              :disabled="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group-6column-xlarge-only">
            <date-input
              inputId="recovery-information-first_declaration_death_date"
              :name="`${$t(`${typeOfDeath}_first_declaration_death_date`)}`"
              v-model="editState.firstDeclarationDeathDate"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group-6column-xlarge-only">
            <time-input
              inputId="recovery-information-first_declaration_death_time"
              :name="`${$t(`${typeOfDeath}_first_declaration_death_time`)}`"
              v-model="editState.firstDeclarationDeathTime"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group-large">
            <date-input
              inputId="recovery-information-second_declaration_death_date"
              :name="`${$t(`${typeOfDeath}_second_declaration_death_date`)}`"
              v-model="editState.secondDeclarationDeathDate"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group-large">
            <time-input
              inputId="recovery-information-second_declaration_death_time"
              :name="`${$t(`${typeOfDeath}_second_declaration_death_time`)}`"
              v-model="editState.secondDeclarationDeathTime"
              :disabled="true"
            />
          </div>
          <div class="row-break d-none d-xl-block d-xxl-none"></div>
          <div class="standard-form-group">
            <date-input
              inputId="recovery-information-cross_clamp_date"
              :name="`${$t('cross_clamp_date')}`"
              v-model="editState.crossClampDate"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group">
            <time-input
              inputId="recovery-information-cross_clamp_time"
              :name="`${$t('cross_clamp_time')}`"
              v-model="editState.crossClampTime"
              :disabled="true"
            />
          </div>
        </div>
      </template>
    </template>
  </sub-section>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/RecoveryDetails.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { Hospital } from '@/store/hospitals/types';
import { isMasked } from '@/utils';

export interface RecoveryInformationPageState {
  declarationHospital?: string|null;
  recoveryHospital?: string|null;
  firstDeclarationDeathDate?: string;
  firstDeclarationDeathTime?: string;
  secondDeclarationDeathDate?: string;
  secondDeclarationDeathTime?: string;
  crossClampDate?: string;
  crossClampTime?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    TimeInput,
    SubSection,
  }
})
export default class RecoveryInformation extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.recoveryInformation) editState!: RecoveryInformationPageState;
  @State(state => state.hospitals.transplantProgram) private hospitals!: Hospital[];

  // Getters
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  get hasWriteAccess(): boolean {
    return this.isGroupWriteable("allocation");
  }

  /**
   * Return string value of donor deceased type
   *
   * @returns {string} string value of donor type
   */
  get typeOfDeath(): string {
    return this.deceasedDonor.death?.neurological_death ? 'NDD' : 'DCD';
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.initializeForm();
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recoveryInformation#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'recoveryInformation');
  }

  /**
   * Populates the Recovery Information form state with data from the selected Deceased Donor.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'recoveryInformation',
      value: this.buildRecoveryInformationPageState(this.deceasedDonor)
    });
  }

  /**
   * Generates Recovery Information form state based on a Deceased Donor document
   *
   * @param deceasedDonor Deceased Donor document provided by API
   * @returns {RecoveryInformationPageState} page state for Recovery Information
   */
  public buildRecoveryInformationPageState(deceasedDonor?: DeceasedDonor): RecoveryInformationPageState {
    if (!deceasedDonor) { return {}; }

    // Cautiously get data from source donor document
    const death = deceasedDonor.death || {};
    const organRetrievalSurgery = deceasedDonor.organ_retrieval_surgery || {};

    const declarationHospitalId = death.hospital_id_declared ? death.hospital_id_declared : undefined;
    const retrievalHospitalId = organRetrievalSurgery.retrieving_hospital_id ? organRetrievalSurgery.retrieving_hospital_id : undefined;
    const declarationHospitalBlankValue = isMasked(declarationHospitalId) ? declarationHospitalId : '-';
    const retrievalHospitalBlankValue = isMasked(retrievalHospitalId) ? retrievalHospitalId : '-';

    // create array for adding declaration hospital and recovery hospital ids to
    const hospitalIds = [];
    if (declarationHospitalId) {
      hospitalIds.push(declarationHospitalId);
    }
    if (retrievalHospitalId) {
      hospitalIds.push(retrievalHospitalId);
    }
    // dispatch hospitals get action to store all hospitals in array
    if (declarationHospitalId || retrievalHospitalId) {
      this.$store.dispatch("hospitals/get", hospitalIds);
    }
    // find the declaration hospital in the hospitals state
    const declarationHospital = this.hospitals.find((item: Hospital) => {
      return declarationHospitalId === item._id;
    });
    // find the recovery hospital in the hospitals state
    const retrievalHospital = this.hospitals.find((item: Hospital) => {
      return retrievalHospitalId === item._id;
    });

    // Return parameters extracted from data document based on structure of form state interface
    return {
      declarationHospital: declarationHospital?.hospital_name_info.name || declarationHospitalBlankValue,
      recoveryHospital: retrievalHospital?.hospital_name_info.name || retrievalHospitalBlankValue,
      firstDeclarationDeathDate: this.parseDateUiFromDateTime(death.first_declare_date),
      firstDeclarationDeathTime: this.parseTimeUiFromDateTime(death.first_declare_date),
      secondDeclarationDeathDate: this.parseDateUiFromDateTime(death.second_declare_date),
      secondDeclarationDeathTime: this.parseTimeUiFromDateTime(death.second_declare_date),
      crossClampDate: this.parseDateUiFromDateTime(organRetrievalSurgery.cross_clamp_date),
      crossClampTime: this.parseTimeUiFromDateTime(organRetrievalSurgery.cross_clamp_date),
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'death.first_declare_date'                 : 'recovery-information-first_declaration_death_date',
    'death.first_declare_time'                 : 'recovery-information-first_declaration_death_time',
    'death.second_declare_date'                : 'recovery-information-second_declaration_death_date',
    'death.second_declare_time'                : 'recovery-information-second_declaration_death_time',
    'organ_retrieval_surgery.cross_clamp_date' : 'recovery-information-cross_clamp_date',
    'organ_retrieval_surgery.cross_clamp_time' : 'recovery-information-cross_clamp_time'
  }
 
}
</script>

