import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav nav-tabs border-bottom" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = {
  id: "RecCompareTab",
  class: "nav-link",
  href: "#DemoGraph",
  "data-toggle": "tab",
  "aria-controls": "Demographics"
}
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = {
  id: "RecHLATab",
  class: "nav-link",
  href: "#RecHLA",
  "data-toggle": "tab",
  "aria-controls": "Compare"
}
const _hoisted_7 = { class: "ml-auto" }
const _hoisted_8 = {
  key: 0,
  class: "ml-3 mr-3"
}
const _hoisted_9 = {
  id: "recipientModalTabContent",
  class: "tab-content"
}
const _hoisted_10 = {
  id: "recDetails",
  class: "tab-pane fade show active p-3",
  role: "tabpanel",
  "aria-labelledby": "recDetailsTab"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-6" }
const _hoisted_13 = { class: "modal-body-title-sm" }
const _hoisted_14 = { class: "row dl-display" }
const _hoisted_15 = { class: "col-md-12" }
const _hoisted_16 = { class: "row dl-display" }
const _hoisted_17 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_20 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_24 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_25 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_26 = {
  key: 0,
  class: "col-md-6 col-lg-4 col-xl-3"
}
const _hoisted_27 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_28 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_29 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_30 = { class: "col-md-12" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-md-6" }
const _hoisted_33 = { class: "modal-body-title-sm" }
const _hoisted_34 = { class: "row dl-display" }
const _hoisted_35 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_36 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_37 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_38 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_39 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_40 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_41 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_42 = {
  key: 0,
  class: "col-md-6 col-lg-4 col-xl-3"
}
const _hoisted_43 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_44 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_45 = {
  key: 1,
  class: "col-md-6 col-lg-4 col-xl-3"
}
const _hoisted_46 = {
  key: 2,
  class: "col-md-6 col-lg-4 col-xl-3"
}
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "col-md-6" }
const _hoisted_49 = { class: "modal-body-title-sm" }
const _hoisted_50 = { class: "row" }
const _hoisted_51 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_52 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_53 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_54 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_55 = { class: "row" }
const _hoisted_56 = { class: "col-md-12" }
const _hoisted_57 = { class: "modal-body-title-sm" }
const _hoisted_58 = { class: "row dl-display" }
const _hoisted_59 = { class: "col-md-12" }
const _hoisted_60 = { class: "row" }
const _hoisted_61 = { class: "col-md-12" }
const _hoisted_62 = { class: "modal-body-title-sm" }
const _hoisted_63 = { class: "row dl-display" }
const _hoisted_64 = { class: "col-md-12" }
const _hoisted_65 = { class: "table-responsive-md" }
const _hoisted_66 = { class: "table table-bordered" }
const _hoisted_67 = { scope: "col" }
const _hoisted_68 = { scope: "col" }
const _hoisted_69 = { scope: "col" }
const _hoisted_70 = { scope: "col" }
const _hoisted_71 = { class: "table-responsive-md" }
const _hoisted_72 = { class: "row dl-display" }
const _hoisted_73 = { class: "col-md-12 col-lg-12 col-xl-12" }
const _hoisted_74 = { class: "table-responsive-md" }
const _hoisted_75 = { class: "table table-bordered" }
const _hoisted_76 = { scope: "col" }
const _hoisted_77 = { scope: "col" }
const _hoisted_78 = { scope: "col" }
const _hoisted_79 = { scope: "col" }
const _hoisted_80 = { scope: "col" }
const _hoisted_81 = { scope: "row" }
const _hoisted_82 = { scope: "row" }
const _hoisted_83 = { scope: "row" }
const _hoisted_84 = { class: "table-responsive-md" }
const _hoisted_85 = { class: "table table-bordered" }
const _hoisted_86 = {
  scope: "col",
  width: "10%"
}
const _hoisted_87 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_88 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_89 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_90 = {
  scope: "col",
  width: "22.5%"
}
const _hoisted_91 = {
  key: 0,
  colspan: "100%",
  class: "text-center"
}
const _hoisted_92 = { class: "row" }
const _hoisted_93 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_94 = { class: "col-md-6 col-lg-4 col-xl-3" }
const _hoisted_95 = {
  id: "DemoGraph",
  class: "tab-pane fade p-3",
  role: "tabpanel",
  "aria-labelledby": "RecCompareTab"
}
const _hoisted_96 = { class: "row" }
const _hoisted_97 = { class: "col-12" }
const _hoisted_98 = { class: "card-title sr-only" }
const _hoisted_99 = { class: "row no-gutters sticky-headings" }
const _hoisted_100 = { class: "col-6" }
const _hoisted_101 = { class: "col-title" }
const _hoisted_102 = { class: "col-6" }
const _hoisted_103 = { class: "col-title" }
const _hoisted_104 = { class: "row no-gutters" }
const _hoisted_105 = { class: "col-md-6" }
const _hoisted_106 = { class: "hla-block-title" }
const _hoisted_107 = { class: "col-md-6" }
const _hoisted_108 = { class: "hla-block-title" }
const _hoisted_109 = { class: "col-md-12" }
const _hoisted_110 = { class: "table table-hover table-sm mt-3" }
const _hoisted_111 = {
  scope: "row",
  width: "20%"
}
const _hoisted_112 = { width: "30%" }
const _hoisted_113 = {
  scope: "row",
  width: "20%"
}
const _hoisted_114 = { width: "30%" }
const _hoisted_115 = { scope: "row" }
const _hoisted_116 = { scope: "row" }
const _hoisted_117 = { scope: "row" }
const _hoisted_118 = { scope: "row" }
const _hoisted_119 = { scope: "row" }
const _hoisted_120 = { scope: "row" }
const _hoisted_121 = { scope: "row" }
const _hoisted_122 = { scope: "row" }
const _hoisted_123 = { scope: "row" }
const _hoisted_124 = { scope: "row" }
const _hoisted_125 = {
  key: 0,
  class: "simple-list"
}
const _hoisted_126 = { scope: "row" }
const _hoisted_127 = { scope: "row" }
const _hoisted_128 = { scope: "row" }
const _hoisted_129 = { scope: "row" }
const _hoisted_130 = { scope: "row" }
const _hoisted_131 = { scope: "row" }
const _hoisted_132 = { scope: "row" }
const _hoisted_133 = { scope: "row" }
const _hoisted_134 = { scope: "row" }
const _hoisted_135 = { key: 0 }
const _hoisted_136 = { scope: "row" }
const _hoisted_137 = { key: 1 }
const _hoisted_138 = { scope: "row" }
const _hoisted_139 = { scope: "row" }
const _hoisted_140 = { scope: "row" }
const _hoisted_141 = { scope: "row" }
const _hoisted_142 = { class: "row no-gutters" }
const _hoisted_143 = { class: "col-md-6" }
const _hoisted_144 = { class: "hla-block-title" }
const _hoisted_145 = { class: "col-md-6" }
const _hoisted_146 = { class: "hla-block-title" }
const _hoisted_147 = { class: "col-md-12" }
const _hoisted_148 = { class: "table table-hover table-sm mt-3" }
const _hoisted_149 = {
  scope: "row",
  width: "20%"
}
const _hoisted_150 = { width: "30%" }
const _hoisted_151 = { scope: "row" }
const _hoisted_152 = { scope: "row" }
const _hoisted_153 = { scope: "row" }
const _hoisted_154 = { scope: "row" }
const _hoisted_155 = { scope: "row" }
const _hoisted_156 = { scope: "row" }
const _hoisted_157 = { scope: "row" }
const _hoisted_158 = { scope: "row" }
const _hoisted_159 = { scope: "row" }
const _hoisted_160 = { scope: "row" }
const _hoisted_161 = { scope: "row" }
const _hoisted_162 = { scope: "row" }
const _hoisted_163 = { scope: "row" }
const _hoisted_164 = { scope: "row" }
const _hoisted_165 = { scope: "row" }
const _hoisted_166 = { scope: "row" }
const _hoisted_167 = { scope: "row" }
const _hoisted_168 = { scope: "row" }
const _hoisted_169 = { scope: "row" }
const _hoisted_170 = { scope: "row" }
const _hoisted_171 = {
  id: "RecHLA",
  class: "tab-pane fade p-3",
  role: "tabpanel",
  "aria-labelledby": "RecHLATab"
}
const _hoisted_172 = { class: "row" }
const _hoisted_173 = { class: "col-12" }
const _hoisted_174 = { class: "card-title sr-only" }
const _hoisted_175 = { class: "row sticky-headings" }
const _hoisted_176 = { class: "col-6" }
const _hoisted_177 = { class: "col-title" }
const _hoisted_178 = { class: "col-6" }
const _hoisted_179 = { class: "col-title" }
const _hoisted_180 = { class: "row" }
const _hoisted_181 = { class: "col-md-6" }
const _hoisted_182 = { class: "hla-block-title" }
const _hoisted_183 = { class: "col-md-6" }
const _hoisted_184 = { class: "hla-block-title" }
const _hoisted_185 = {
  key: 0,
  class: "col-md-6"
}
const _hoisted_186 = { class: "hla-block-title" }
const _hoisted_187 = { class: "form-group" }
const _hoisted_188 = { class: "form-group" }
const _hoisted_189 = { class: "row" }
const _hoisted_190 = { class: "col-md-6" }
const _hoisted_191 = { class: "hla-block-title" }
const _hoisted_192 = { class: "col-md-6" }
const _hoisted_193 = { class: "hla-block-title" }
const _hoisted_194 = {
  key: 0,
  class: "col-md-6"
}
const _hoisted_195 = { class: "hla-block-title" }
const _hoisted_196 = { class: "form-group" }
const _hoisted_197 = { class: "form-group" }
const _hoisted_198 = {
  key: 0,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_recipient_link = _resolveComponent("recipient-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_organ_icon = _resolveComponent("organ-icon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_hla_virtual_crossmatch_result = _resolveComponent("hla-virtual-crossmatch-result")!
  const _component_hla_input_group = _resolveComponent("hla-input-group")!
  const _component_hla_input = _resolveComponent("hla-input")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "recipientInformation-modal",
    ref: "recipientInformationModal",
    class: "modal-sticky-tabs",
    onHide: _cache[20] || (_cache[20] = ($event: any) => (_ctx.clearRecipientDetailsState()))
  }, _createSlots({
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('recipient_info')), 1)
    ]),
    _: 2
  }, [
    (_ctx.recipientDetails && _ctx.donorDetails)
      ? {
          name: "body",
          fn: _withCtx(() => [
            _createElementVNode("form", null, [
              _createElementVNode("ul", _hoisted_1, [
                _createElementVNode("li", _hoisted_2, [
                  _createElementVNode("a", {
                    id: "RecDetailsTab",
                    class: "nav-link active",
                    href: "#recDetails",
                    ref: "recipientDetailsTab",
                    "data-toggle": "tab",
                    "aria-controls": "Recipient Details"
                  }, _toDisplayString(_ctx.$t('recipient_details')), 513)
                ]),
                _createElementVNode("li", _hoisted_3, [
                  _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('compare')), 1)
                ]),
                _createElementVNode("li", _hoisted_5, [
                  _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('hla')), 1)
                ]),
                (_ctx.showRecipientLinks)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("li", _hoisted_7, [
                        _createTextVNode(_toDisplayString(_ctx.recipientDetails.out_of_province) + " ", 1),
                        _createVNode(_component_recipient_link, {
                          client_id: _ctx.recipientDetails.recipient.client_id
                        }, null, 8, ["client_id"])
                      ]),
                      (!_ctx.recipientDetails.recipient.out_of_province)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                            _createVNode(_component_router_link, {
                              to: { name: 'recipient-hla', params: { id: _ctx.recipientDetails.recipient.client_id }},
                              target: "_blank"
                            }, {
                              default: _withCtx(() => [
                                _cache[21] || (_cache[21] = _createElementVNode("i", {
                                  "aria-hidden": "true",
                                  class: "far fa-fw fa-file-export"
                                }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('recipient_hla')), 1)
                              ]),
                              _: 1
                            }, 8, ["to"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("dl", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('recipient')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("dl", _hoisted_15, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('allocation_step')), 1),
                      _createElementVNode("dd", null, [
                        (_ctx.recipientDetails.recipient.added_manually)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t('manually_added_recipient')) + " ", 1),
                              _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.recipientDetails.recipient.added_manually_reason), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.recipientDetails.recipient.ranking_category || '-'), 1)
                            ], 64))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("dl", _hoisted_17, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('listed_for')), 1),
                      _createElementVNode("dd", null, [
                        _createVNode(_component_organ_icon, {
                          "organ-code": _ctx.allocation.organ_code,
                          "compare-modal-icon": true
                        }, null, 8, ["organ-code"]),
                        _createElementVNode("span", {
                          class: "ml-1",
                          innerHTML: _ctx.describeOrganListedFor
                        }, null, 8, _hoisted_18)
                      ])
                    ]),
                    _createElementVNode("dl", _hoisted_19, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('recipient_id')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.client_id || '-'), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_20, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('recipient_name')), 1),
                      (_ctx.recipientDetails.recipient.last_name && _ctx.recipientDetails.recipient.first_name)
                        ? (_openBlock(), _createElementBlock("dd", _hoisted_21, _toDisplayString(_ctx.recipientDetails.recipient.last_name) + ", " + _toDisplayString(_ctx.recipientDetails.recipient.first_name), 1))
                        : (_openBlock(), _createElementBlock("dd", _hoisted_22, _toDisplayString(_ctx.recipientDetails.recipient.last_name || '-'), 1))
                    ]),
                    _createElementVNode("dl", _hoisted_23, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('national_recipient_id')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.national_recipient_id || '-'), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_24, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('transplant_program')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.getRecipientTransplantProgram || '-'), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_25, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('medical_status')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseMedicalStatus(_ctx.recipientDetails.recipient.medical_status)), 1)
                    ]),
                    (_ctx.checkIfOrganSpecificAllocation('heart'))
                      ? (_openBlock(), _createElementBlock("dl", _hoisted_26, [
                          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('secondary_status')), 1),
                          _createElementVNode("dd", null, _toDisplayString(_ctx.parseSecondaryMedicalStatus(_ctx.recipientDetails.recipient.secondary_medical_status) || '-'), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("dl", _hoisted_27, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('wait_time')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parsePossibleZeroValue(_ctx.recipientDetails.recipient.wait_days)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_28, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('list_date')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseDate(_ctx.recipientDetails.recipient.listing_date) || '-'), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_29, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('primary_diagnosis')), 1),
                      _createElementVNode("dd", null, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.recipientDetails.recipient.primary_diagnosis || '-'), 1)
                      ])
                    ]),
                    _createElementVNode("dl", _hoisted_30, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('comments')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.comments || '-'), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("dl", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('general_information')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("dl", _hoisted_35, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('sex')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.sexValue(_ctx.recipientDetails.recipient.gender)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_36, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('age')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.age), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_37, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('dob')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseDate(_ctx.recipientDetails.recipient.dob)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_38, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('height')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseHeight(_ctx.recipientDetails.recipient.height)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_39, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('weight')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseWeight(_ctx.recipientDetails.recipient.weight)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_40, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('bmi')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseBmi(_ctx.recipientDetails.recipient.bmi)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_41, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('blood_type')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.blood_type || '-'), 1)
                    ]),
                    (_ctx.checkIfOrganSpecificAllocation('kidney'))
                      ? (_openBlock(), _createElementBlock("dl", _hoisted_42, [
                          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('accept_a2_or_a2b')), 1),
                          _createElementVNode("dd", null, _toDisplayString(_ctx.parseNullValue(_ctx.recipientDetails.recipient.donor_acceptability.a2_or_a2b_donor)), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("dl", _hoisted_43, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('donor_type')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDonorType), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_44, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('mrn')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.mrn || '-'), 1)
                    ]),
                    (_ctx.isLungAllocation)
                      ? (_openBlock(), _createElementBlock("dl", _hoisted_45, [
                          _createElementVNode("dt", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('total_lung_capacity_actual')) + " ", 1),
                            _cache[23] || (_cache[23] = _createElementVNode("span", {
                              class: "text-red",
                              title: "Only shown for lung"
                            }, [
                              _createElementVNode("u", null, "(*)")
                            ], -1))
                          ]),
                          _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.actual_total_lung_capacity ? _ctx.recipientDetails.recipient.actual_total_lung_capacity + 'L' : '-'), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isLungAllocation)
                      ? (_openBlock(), _createElementBlock("dl", _hoisted_46, [
                          _createElementVNode("dt", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('total_lung_capacity_predicted')) + " ", 1),
                            _cache[24] || (_cache[24] = _createElementVNode("span", {
                              class: "text-red",
                              title: "Only shown for lung"
                            }, [
                              _createElementVNode("u", null, "(*)")
                            ], -1))
                          ]),
                          _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.predicted_total_lung_capacity ? _ctx.recipientDetails.recipient.predicted_total_lung_capacity + 'L' : '-'), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("dl", _hoisted_48, [
                      _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t('virology')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("dl", _hoisted_51, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('cmv')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.cmv)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_52, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('ebv')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.ebv)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_53, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('hbs_ag')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.hbv_surface_ag)), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_54, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('hcv')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.hcv_ab)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_55, [
                    _createElementVNode("dl", _hoisted_56, [
                      _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$t('patient_hla_comments')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_58, [
                    _createElementVNode("dl", _hoisted_59, [
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.hla_comments || '-'), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("dl", _hoisted_61, [
                      _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.$t('donor_acceptance_comments')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("dl", _hoisted_64, [
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.donor_acceptability.comments || '-'), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_65, [
                    _createElementVNode("table", _hoisted_66, [
                      _createElementVNode("caption", null, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.$t('referred_organ_section')), 1)
                      ]),
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_67, _toDisplayString(_ctx.$t('organ')), 1),
                          _createElementVNode("th", _hoisted_68, _toDisplayString(_ctx.$t('registration_type')), 1),
                          _createElementVNode("th", _hoisted_69, _toDisplayString(_ctx.$t('registration_status')), 1),
                          _createElementVNode("th", _hoisted_70, _toDisplayString(_ctx.$t('medical_status')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipientDetails.recipient.associated_journeys, (organ, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `recipient_associated_journey-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _cache[25] || (_cache[25] = _createElementVNode("i", { class: "fas fa-fw fa-heart" }, null, -1)),
                              _cache[26] || (_cache[26] = _createTextVNode()),
                              _createElementVNode("strong", null, _toDisplayString(organ.organ), 1)
                            ]),
                            _createElementVNode("td", null, _toDisplayString(organ.registration_type), 1),
                            _createElementVNode("td", null, [
                              _createElementVNode("strong", null, _toDisplayString(organ.registration_status), 1)
                            ]),
                            _createElementVNode("td", null, _toDisplayString(`${organ.medical_status_code} - ${organ.medical_status_desc}`), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("table", null, [
                      _createElementVNode("caption", null, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.$t('pra_section')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_72, [
                        _createElementVNode("dl", _hoisted_73, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('cumulative_cpra')), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.parsePossibleZeroValue(_ctx.recipientDetails.recipient.cpra)), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_74, [
                    _createElementVNode("table", _hoisted_75, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_76, _toDisplayString(_ctx.$t('pra')), 1),
                          _createElementVNode("th", _hoisted_77, _toDisplayString(_ctx.$t('current_pra')), 1),
                          _createElementVNode("th", _hoisted_78, _toDisplayString(_ctx.$t('months_old')), 1),
                          _createElementVNode("th", _hoisted_79, _toDisplayString(_ctx.$t('peak_pra')), 1),
                          _createElementVNode("th", _hoisted_80, _toDisplayString(_ctx.$t('months_old')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_81, _toDisplayString(_ctx.$t('class_i')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class1_current_value), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class1_current_months), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class1_peak_value), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class1_peak_months), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_82, _toDisplayString(_ctx.$t('class_ii')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class2_current_value), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class2_current_months), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class2_peak_value), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.class2_peak_months), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_83, _toDisplayString(_ctx.$t('total')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.total_current_value), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.total_current_months), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.total_peak_value), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.pra.total_peak_months), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_84, [
                    _createElementVNode("table", _hoisted_85, [
                      _createElementVNode("caption", null, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.$t('vxm_section')), 1)
                      ]),
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_86, _toDisplayString(_ctx.$t('vxm')), 1),
                          _createElementVNode("th", _hoisted_87, _toDisplayString(_ctx.$t('class_status', {class: _ctx.$t('class_i'), status: _ctx.$t('current')})), 1),
                          _createElementVNode("th", _hoisted_88, _toDisplayString(_ctx.$t('class_status', {class: _ctx.$t('class_i'), status: _ctx.$t('cumulative')})), 1),
                          _createElementVNode("th", _hoisted_89, _toDisplayString(_ctx.$t('class_status', {class: _ctx.$t('class_ii'), status: _ctx.$t('current')})), 1),
                          _createElementVNode("th", _hoisted_90, _toDisplayString(_ctx.$t('class_status', {class: _ctx.$t('class_ii'), status: _ctx.$t('cumulative')})), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (!_ctx.recipientDetails.recipient.vxm.current)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_91, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: ['far', 'exclamation-circle'],
                                "fixed-width": ""
                              }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('no_data')), 1)
                            ]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('result')), 1),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_virtual_crossmatch_result, {
                                    result: _ctx.recipientDetails.recipient.vxm.current.class1.result
                                  }, null, 8, ["result"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_virtual_crossmatch_result, {
                                    result: _ctx.recipientDetails.recipient.vxm.cumulative.class1.result
                                  }, null, 8, ["result"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_virtual_crossmatch_result, {
                                    result: _ctx.recipientDetails.recipient.vxm.current.class2.result
                                  }, null, 8, ["result"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_virtual_crossmatch_result, {
                                    result: _ctx.recipientDetails.recipient.vxm.cumulative.class2.result
                                  }, null, 8, ["result"])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('unacceptable_dsa')), 1),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-unacceptable_class1_current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class1.unacceptable_allele_group,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class1.unacceptable_allele_group) = $event)),
                                    enableEpitopes: true,
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.current.class1.unacceptable_allele_specific,
                                    epitopes: _ctx.recipientDetails.recipient.vxm.current.class1.unacceptable_epitopes,
                                    readonly: true,
                                    inputClass: "hla-input hla-unacceptable border-0",
                                    name: _ctx.$t('unacceptable'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class1.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-unacceptable_class1_cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class1.unacceptable_allele_group,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class1.unacceptable_allele_group) = $event)),
                                    enableEpitopes: true,
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.cumulative.class1.unacceptable_allele_specific,
                                    epitopes: _ctx.recipientDetails.recipient.vxm.cumulative.class1.unacceptable_epitopes,
                                    readonly: true,
                                    inputClass: "hla-input hla-unacceptable border-0",
                                    name: _ctx.$t('unacceptable'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class1.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-unacceptable_class2_current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class2.unacceptable_allele_group,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class2.unacceptable_allele_group) = $event)),
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.current.class2.unacceptable_allele_specific,
                                    alphaBeta: _ctx.recipientDetails.recipient.vxm.current.class2.unacceptable_alpha_beta,
                                    readonly: true,
                                    inputClass: "hla-input hla-unacceptable border-0",
                                    name: _ctx.$t('unacceptable'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class2.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-unacceptable_class2_cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class2.unacceptable_allele_group,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class2.unacceptable_allele_group) = $event)),
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.cumulative.class2.unacceptable_allele_specific,
                                    alphaBeta: _ctx.recipientDetails.recipient.vxm.cumulative.class2.unacceptable_alpha_beta,
                                    readonly: true,
                                    inputClass: "hla-input hla-unacceptable border-0",
                                    name: _ctx.$t('unacceptable'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class2.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "vxm"])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('indeterminate_dsa')), 1),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-indeterminate_class1_current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class1.indeterminate_allele_group,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class1.indeterminate_allele_group) = $event)),
                                    enableEpitopes: true,
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.current.class1.indeterminate_allele_specific,
                                    epitopes: _ctx.recipientDetails.recipient.vxm.current.class1.indeterminate_epitopes,
                                    readonly: true,
                                    inputClass: "hla-input hla-indeterminate border-0",
                                    name: _ctx.$t('indeterminate'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class1.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-indeterminate_class1_cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class1.indeterminate_allele_group,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class1.indeterminate_allele_group) = $event)),
                                    enableEpitopes: true,
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.cumulative.class1.indeterminate_allele_specific,
                                    epitopes: _ctx.recipientDetails.recipient.vxm.cumulative.class1.indeterminate_epitopes,
                                    readonly: true,
                                    inputClass: "hla-input hla-indeterminate border-0",
                                    name: _ctx.$t('indeterminate'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class1.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "epitopes", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-indeterminate_class2_current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class2.indeterminate_allele_group,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class2.indeterminate_allele_group) = $event)),
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.current.class2.indeterminate_allele_specific,
                                    alphaBeta: _ctx.recipientDetails.recipient.vxm.current.class2.indeterminate_alpha_beta,
                                    readonly: true,
                                    inputClass: "hla-input hla-indeterminate border-0",
                                    name: _ctx.$t('indeterminate'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class2.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "vxm-summary-table-indeterminate_class2_cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class2.indeterminate_allele_group,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class2.indeterminate_allele_group) = $event)),
                                    alleleSpecific: _ctx.recipientDetails.recipient.vxm.cumulative.class2.indeterminate_allele_specific,
                                    alphaBeta: _ctx.recipientDetails.recipient.vxm.cumulative.class2.indeterminate_alpha_beta,
                                    readonly: true,
                                    inputClass: "hla-input hla-indeterminate  border-0",
                                    name: _ctx.$t('indeterminate'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class2.result
                                  }, null, 8, ["modelValue", "alleleSpecific", "alphaBeta", "name", "vxm"])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('possible_allele_specific')), 1),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-possible-allele-specific-class1-current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class1.possible_allele_specific,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class1.possible_allele_specific) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-possible-allele-specific border-0",
                                    name: _ctx.$t('possible_allele_specific'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class1.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-possible-allele-specific-class1-cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class1.possible_allele_specific,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class1.possible_allele_specific) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-possible-allele-specific border-0",
                                    name: _ctx.$t('possible_allele_specific'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class1.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-possible-allele-specific-class2-current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class2.possible_allele_specific,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class2.possible_allele_specific) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-possible-allele-specific border-0",
                                    name: _ctx.$t('possible_allele_specific'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class2.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-possible-allele-specific-class2-cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class2.possible_allele_specific,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class2.possible_allele_specific) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-possible-allele-specific border-0",
                                    name: _ctx.$t('possible_allele_specific'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class2.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('untested')), 1),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-untested-class1-current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class1.untested,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class1.untested) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-untested border-0",
                                    name: _ctx.$t('untested'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class1.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-untested-class1-cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class1.untested,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class1.untested) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-untested border-0",
                                    name: _ctx.$t('untested'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class1.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-untested-class2-current",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.current.class2.untested,
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.current.class2.untested) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-untested border-0",
                                    name: _ctx.$t('untested'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.current.class2.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ]),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_hla_input, {
                                    inputId: "vxm-summary-table-untested-class2-cumulative",
                                    modelValue: _ctx.recipientDetails.recipient.vxm.cumulative.class2.untested,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.recipientDetails.recipient.vxm.cumulative.class2.untested) = $event)),
                                    readonly: true,
                                    inputClass: "hla-input hla-untested border-0",
                                    name: _ctx.$t('untested'),
                                    "hide-label": true,
                                    vxm: _ctx.recipientDetails.recipient.vxm.cumulative.class2.result
                                  }, null, 8, ["modelValue", "name", "vxm"])
                                ])
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('messages')), 1),
                                _createElementVNode("td", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipientDetails.recipient.vxm.current.class1.messages, (message, index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: "hla-vxm-message",
                                      key: `vxm-summary-table-messages-class1-current-${index}`
                                    }, _toDisplayString(message), 1))
                                  }), 128))
                                ]),
                                _createElementVNode("td", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipientDetails.recipient.vxm.cumulative.class1.messages, (message, index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: "hla-vxm-message",
                                      key: `vxm-summary-table-messages-class1-cummulative-${index}`
                                    }, _toDisplayString(message), 1))
                                  }), 128))
                                ]),
                                _createElementVNode("td", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipientDetails.recipient.vxm.current.class2.messages, (message, index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: "hla-vxm-message",
                                      key: `vxm-summary-table-messages-class2-current-${index}`
                                    }, _toDisplayString(message), 1))
                                  }), 128))
                                ]),
                                _createElementVNode("td", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipientDetails.recipient.vxm.cumulative.class2.messages, (message, index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: "hla-vxm-message",
                                      key: `vxm-summary-table-messages-class2-cumulative-${index}`
                                    }, _toDisplayString(message), 1))
                                  }), 128))
                                ])
                              ])
                            ], 64))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_92, [
                    _createElementVNode("dl", _hoisted_93, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('current_serum_date')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.parseDate(_ctx.recipientDetails.recipient.vxm.current_serum_date || '') || '-'), 1)
                    ]),
                    _createElementVNode("dl", _hoisted_94, [
                      _createElementVNode("dt", null, _toDisplayString(_ctx.$t('total_serum')), 1),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.recipientDetails.recipient.total_valid_hla_antibody_labs != null ? _ctx.recipientDetails.recipient.total_valid_hla_antibody_labs: '-'), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_95, [
                  _createElementVNode("div", _hoisted_96, [
                    _createElementVNode("div", _hoisted_97, [
                      _createElementVNode("h3", _hoisted_98, _toDisplayString(_ctx.$t('compare')), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_99, [
                          _createElementVNode("div", _hoisted_100, [
                            _createElementVNode("h4", _hoisted_101, _toDisplayString(_ctx.$t('recipient')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_102, [
                            _createElementVNode("h4", _hoisted_103, _toDisplayString(_ctx.$t('donor')), 1)
                          ]),
                          _cache[27] || (_cache[27] = _createElementVNode("div", { class: "col-12" }, [
                            _createElementVNode("hr")
                          ], -1))
                        ]),
                        _createElementVNode("div", _hoisted_104, [
                          _createElementVNode("div", _hoisted_105, [
                            _createElementVNode("h5", _hoisted_106, _toDisplayString(_ctx.$t('demographics_section')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_107, [
                            _createElementVNode("h5", _hoisted_108, _toDisplayString(_ctx.$t('demographics_section')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_109, [
                            _createElementVNode("table", _hoisted_110, [
                              _createElementVNode("tbody", null, [
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_111, _toDisplayString(_ctx.$t('age')), 1),
                                  _createElementVNode("td", _hoisted_112, _toDisplayString(_ctx.recipientDetails.recipient.age), 1),
                                  _createElementVNode("th", _hoisted_113, _toDisplayString(_ctx.$t('age')), 1),
                                  _createElementVNode("td", _hoisted_114, _toDisplayString(_ctx.donorDetails.donor.age), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_115, _toDisplayString(_ctx.$t('gender')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.sexValue(_ctx.recipientDetails.recipient.gender)), 1),
                                  _createElementVNode("th", _hoisted_116, _toDisplayString(_ctx.$t('gender')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.sexValue(_ctx.donorDetails.donor.gender)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_117, _toDisplayString(_ctx.$t('primary_diagnosis')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.primary_diagnosis || '-'), 1),
                                  _createElementVNode("th", _hoisted_118, _toDisplayString(_ctx.$t('cause_of_death')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('stroke')), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_119, _toDisplayString(_ctx.$t('abo')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.blood_type), 1),
                                  _createElementVNode("th", _hoisted_120, _toDisplayString(_ctx.$t('abo')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.blood_type), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_121, _toDisplayString(_ctx.$t('accept_abo_incompatible')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseNullValue(_ctx.recipientDetails.recipient.donor_acceptability.abo_incompatible)), 1),
                                  _cache[28] || (_cache[28] = _createElementVNode("td", null, null, -1)),
                                  _cache[29] || (_cache[29] = _createElementVNode("td", null, null, -1))
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_122, _toDisplayString(_ctx.$t('accept_a2_or_a2b')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseNullValue(_ctx.recipientDetails.recipient.donor_acceptability.a2_or_a2b_donor)), 1),
                                  _cache[30] || (_cache[30] = _createElementVNode("td", null, null, -1)),
                                  _cache[31] || (_cache[31] = _createElementVNode("td", null, null, -1))
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[32] || (_cache[32] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[33] || (_cache[33] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('donor_type')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.neurological_death ? _ctx.$t('ndd') : _ctx.$t('dcd')), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_123, _toDisplayString(_ctx.$t('ecd_consented')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseNullValue(_ctx.recipientDetails.recipient.donor_acceptability.ecd_donor)), 1),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('ecd_donor')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.ecd ? _ctx.$t('yes').toString() : _ctx.$t('no').toString()), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[34] || (_cache[34] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[35] || (_cache[35] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('exceptional_distribution')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.exceptional_distribution ? _ctx.$t('yes').toString() : _ctx.$t('no').toString()), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[36] || (_cache[36] = _createElementVNode("td", null, null, -1)),
                                  _cache[37] || (_cache[37] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_124, _toDisplayString(_ctx.$t('exceptional_distribution_reason')), 1),
                                  _createElementVNode("td", null, [
                                    (_ctx.exdReasonValues)
                                      ? (_openBlock(), _createElementBlock("ul", _hoisted_125, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exdReasonValues, (reason, index) => {
                                            return (_openBlock(), _createElementBlock("li", {
                                              key: `exd-${index}`
                                            }, _toDisplayString(reason), 1))
                                          }), 128))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[38] || (_cache[38] = _createElementVNode("td", null, null, -1)),
                                  _cache[39] || (_cache[39] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_126, _toDisplayString(_ctx.$t('exceptional_distribution_other_reason')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.exd_reason_details_other || '-'), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[40] || (_cache[40] = _createElementVNode("td", null, null, -1)),
                                  _cache[41] || (_cache[41] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_127, _toDisplayString(_ctx.$t('exceptional_distribution_travel_reason')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.exd_reason_details_travel || '-'), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[42] || (_cache[42] = _createElementVNode("td", null, null, -1)),
                                  _cache[43] || (_cache[43] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_128, _toDisplayString(_ctx.$t('exceptional_distribution_transmission_risk')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.exd_reason_details_transmission || '-'), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_129, _toDisplayString(_ctx.$t('height')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.out_of_province ? '-' : _ctx.parseHeight(_ctx.recipientDetails.recipient.height)), 1),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('height')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.out_of_province ? '-' : _ctx.parseHeight(_ctx.donorDetails.donor.height)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('weight')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseWeight(_ctx.recipientDetails.recipient.weight)), 1),
                                  _createElementVNode("th", _hoisted_130, _toDisplayString(_ctx.$t('weight')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseWeight(_ctx.donorDetails.donor.weight)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_131, _toDisplayString(_ctx.$t('minimum_acceptable_donor_weight')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseWeight(_ctx.recipientDetails.recipient.donor_acceptability.min_weight)), 1),
                                  _cache[44] || (_cache[44] = _createElementVNode("td", null, null, -1)),
                                  _cache[45] || (_cache[45] = _createElementVNode("td", null, null, -1))
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_132, _toDisplayString(_ctx.$t('maximum_acceptable_donor_weight')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseWeight(_ctx.recipientDetails.recipient.donor_acceptability.max_weight)), 1),
                                  _cache[46] || (_cache[46] = _createElementVNode("td", null, null, -1)),
                                  _cache[47] || (_cache[47] = _createElementVNode("td", null, null, -1))
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_133, _toDisplayString(_ctx.$t('comments')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.donor_acceptability.comments || '-'), 1),
                                  _cache[48] || (_cache[48] = _createElementVNode("td", null, null, -1)),
                                  _cache[49] || (_cache[49] = _createElementVNode("td", null, null, -1))
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('bmi')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseBmi(_ctx.recipientDetails.recipient.bmi)), 1),
                                  _createElementVNode("th", _hoisted_134, _toDisplayString(_ctx.$t('bmi')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseBmi(_ctx.donorDetails.donor.bmi)), 1)
                                ]),
                                (_ctx.allocationOrganName=='Lung')
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_135, [
                                      _createElementVNode("th", _hoisted_136, [
                                        _createTextVNode(_toDisplayString(_ctx.$t('total_lung_capacity_actual')) + " ", 1),
                                        _cache[50] || (_cache[50] = _createElementVNode("span", {
                                          class: "text-red",
                                          title: "Only shown for lung"
                                        }, [
                                          _createElementVNode("u", null, "(*)")
                                        ], -1))
                                      ]),
                                      _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.actual_total_lung_capacity ? _ctx.recipientDetails.recipient.actual_total_lung_capacity + 'L' : '-'), 1),
                                      _cache[51] || (_cache[51] = _createElementVNode("td", null, null, -1)),
                                      _cache[52] || (_cache[52] = _createElementVNode("td", null, null, -1))
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.allocationOrganName=='Lung')
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_137, [
                                      _createElementVNode("th", _hoisted_138, [
                                        _createTextVNode(_toDisplayString(_ctx.$t('total_lung_capacity_predicted')) + " ", 1),
                                        _cache[53] || (_cache[53] = _createElementVNode("span", {
                                          class: "text-red",
                                          title: "Only shown for lung"
                                        }, [
                                          _createElementVNode("u", null, "(*)")
                                        ], -1))
                                      ]),
                                      _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.predicted_total_lung_capacity ? _ctx.recipientDetails.recipient.predicted_total_lung_capacity + 'L' : '-'), 1),
                                      _createElementVNode("th", _hoisted_139, [
                                        _createTextVNode(_toDisplayString(_ctx.$t('total_lung_capacity_predicted')) + " ", 1),
                                        _cache[54] || (_cache[54] = _createElementVNode("span", {
                                          class: "text-red",
                                          title: "Only shown for lung"
                                        }, [
                                          _createElementVNode("u", null, "(*)")
                                        ], -1))
                                      ]),
                                      _createElementVNode("td", null, _toDisplayString(_ctx.donorDetails.donor.predicted_total_lung_capacity ? _ctx.donorDetails.donor.predicted_total_lung_capacity + 'L' : '-'), 1),
                                      _cache[55] || (_cache[55] = _createElementVNode("td", null, null, -1))
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_140, _toDisplayString(_ctx.$t('previous_transplants')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.previous_transplants), 1),
                                  _cache[56] || (_cache[56] = _createElementVNode("td", null, null, -1)),
                                  _cache[57] || (_cache[57] = _createElementVNode("td", null, null, -1))
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_141, _toDisplayString(_ctx.$t('recipient_comments')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.recipientDetails.recipient.comments), 1),
                                  _cache[58] || (_cache[58] = _createElementVNode("td", null, null, -1)),
                                  _cache[59] || (_cache[59] = _createElementVNode("td", null, null, -1))
                                ])
                              ])
                            ])
                          ]),
                          _cache[60] || (_cache[60] = _createElementVNode("div", { class: "col-12" }, [
                            _createElementVNode("hr")
                          ], -1))
                        ]),
                        _createElementVNode("div", _hoisted_142, [
                          _createElementVNode("div", _hoisted_143, [
                            _createElementVNode("h5", _hoisted_144, _toDisplayString(_ctx.$t('virology_results')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_145, [
                            _createElementVNode("h5", _hoisted_146, _toDisplayString(_ctx.$t('serology_results')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_147, [
                            _createElementVNode("table", _hoisted_148, [
                              _createElementVNode("tbody", null, [
                                _createElementVNode("tr", null, [
                                  _cache[61] || (_cache[61] = _createElementVNode("th", {
                                    scope: "row",
                                    width: "20%"
                                  }, null, -1)),
                                  _cache[62] || (_cache[62] = _createElementVNode("td", { width: "30%" }, null, -1)),
                                  _createElementVNode("th", _hoisted_149, _toDisplayString(_ctx.$t('nat_hiv')), 1),
                                  _createElementVNode("td", _hoisted_150, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hiv_nat)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[63] || (_cache[63] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[64] || (_cache[64] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_151, _toDisplayString(_ctx.$t('nat_hcv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hcv_nat)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[65] || (_cache[65] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[66] || (_cache[66] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_152, _toDisplayString(_ctx.$t('nat_hbv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hbv_nat)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_153, _toDisplayString(_ctx.$t('cmv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.cmv)), 1),
                                  _createElementVNode("th", _hoisted_154, _toDisplayString(_ctx.$t('cmv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.cmv)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_155, _toDisplayString(_ctx.$t('hep_surface', {hep: _ctx.$t('hep_b'), surface: _ctx.$t('surface_ag')})), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.hbv_surface_ag)), 1),
                                  _createElementVNode("th", _hoisted_156, _toDisplayString(_ctx.$t('hep_surface', {hep: _ctx.$t('hep_b'), surface: _ctx.$t('surface_ag')})), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hbv_surface_ag)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_157, _toDisplayString(_ctx.$t('hep_surface', {hep: _ctx.$t('hep_b'), surface: _ctx.$t('surface_ab')})), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.hbv_surface_ab)), 1),
                                  _createElementVNode("th", _hoisted_158, _toDisplayString(_ctx.$t('hep_surface', {hep: _ctx.$t('hep_b'), surface: _ctx.$t('surface_ab')})), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hbv_surface_ab)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_159, _toDisplayString(_ctx.$t('hep_surface', {hep: _ctx.$t('hep_b'), surface: _ctx.$t('core_ab')})), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.hep_ab_core)), 1),
                                  _createElementVNode("th", _hoisted_160, _toDisplayString(_ctx.$t('hep_surface', {hep: _ctx.$t('hep_b'), surface: _ctx.$t('core_ab')})), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hep_ab_core)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_161, _toDisplayString(_ctx.$t('hcv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.hcv_ab)), 1),
                                  _createElementVNode("th", _hoisted_162, _toDisplayString(_ctx.$t('hcv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hcv_ab)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[67] || (_cache[67] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[68] || (_cache[68] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_163, _toDisplayString(_ctx.$t('hiv_i_ii')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.hiv_1_2)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[69] || (_cache[69] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[70] || (_cache[70] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_164, _toDisplayString(_ctx.$t('htlv1')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.htlv1)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[71] || (_cache[71] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[72] || (_cache[72] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_165, _toDisplayString(_ctx.$t('htlv2')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.htlv2)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[73] || (_cache[73] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[74] || (_cache[74] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_166, _toDisplayString(_ctx.$t('syphilis')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.syphilis)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[75] || (_cache[75] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[76] || (_cache[76] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_167, _toDisplayString(_ctx.$t('toxoplasma')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.toxoplasma)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", _hoisted_168, _toDisplayString(_ctx.$t('ebv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.recipientDetails.recipient.virology.ebv)), 1),
                                  _createElementVNode("th", _hoisted_169, _toDisplayString(_ctx.$t('ebv')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.ebv)), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _cache[77] || (_cache[77] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                  _cache[78] || (_cache[78] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("th", _hoisted_170, _toDisplayString(_ctx.$t('wnv_pcr')), 1),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.parseVirology(_ctx.donorDetails.donor.virology.wnv_pcr)), 1)
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_171, [
                  _createElementVNode("div", _hoisted_172, [
                    _createElementVNode("div", _hoisted_173, [
                      _createElementVNode("h3", _hoisted_174, _toDisplayString(_ctx.$t('hla')), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_175, [
                          _createElementVNode("div", _hoisted_176, [
                            _createElementVNode("h4", _hoisted_177, _toDisplayString(_ctx.$t('recipient')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_178, [
                            _createElementVNode("h4", _hoisted_179, _toDisplayString(_ctx.$t('donor')), 1)
                          ]),
                          _cache[79] || (_cache[79] = _createElementVNode("div", { class: "col-12" }, [
                            _createElementVNode("hr")
                          ], -1))
                        ]),
                        _createElementVNode("div", _hoisted_180, [
                          _createElementVNode("div", _hoisted_181, [
                            _createElementVNode("h5", _hoisted_182, _toDisplayString(_ctx.$t('class_i_typing')), 1),
                            _cache[80] || (_cache[80] = _createElementVNode("div", { class: "table-responsive" }, null, -1))
                          ]),
                          _createElementVNode("div", _hoisted_183, [
                            _createElementVNode("h5", _hoisted_184, _toDisplayString(_ctx.$t('class_i_typing')), 1),
                            _cache[81] || (_cache[81] = _createElementVNode("div", { class: "table-responsive" }, null, -1))
                          ])
                        ]),
                        (_ctx.recipientDetails.recipient.antibodies && _ctx.recipientDetails.recipient.antibodies.cumulative.class1 && _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_185, [
                              _cache[82] || (_cache[82] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                              _createElementVNode("h5", _hoisted_186, _toDisplayString(_ctx.$t('class_i_antibodies')), 1),
                              _createElementVNode("div", _hoisted_187, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "serumsummary-modal-antibodies_class1_unacceptable",
                                  name: _ctx.$t('unacceptable'),
                                  modelValue: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_allele_group,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_allele_group) = $event)),
                                  enableEpitopes: true,
                                  alleleSpecific: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_allele_specific,
                                  alphaBeta: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.unacceptable_alpha_beta,
                                  epitopes: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.epitopes.unacceptable,
                                  readonly: true,
                                  inputClass: "hla-input hla-unacceptable border-0"
                                }, null, 8, ["name", "modelValue", "alleleSpecific", "alphaBeta", "epitopes"])
                              ]),
                              _createElementVNode("div", _hoisted_188, [
                                _createVNode(_component_hla_input_group, {
                                  inputId: "serumsummary-modal-antibodies_class1_indeterminate",
                                  name: _ctx.$t('indeterminate'),
                                  modelValue: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_allele_group,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_allele_group) = $event)),
                                  enableEpitopes: true,
                                  alleleSpecific: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_allele_specific,
                                  alphaBeta: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.antibodies.indeterminate_alpha_beta,
                                  epitopes: _ctx.recipientDetails.recipient.antibodies.cumulative.class1.epitopes.indeterminate,
                                  readonly: true,
                                  inputClass: "hla-input hla-indeterminate border-0"
                                }, null, 8, ["name", "modelValue", "alleleSpecific", "alphaBeta", "epitopes"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _cache[86] || (_cache[86] = _createElementVNode("hr", null, null, -1)),
                        _createElementVNode("div", _hoisted_189, [
                          _createElementVNode("div", _hoisted_190, [
                            _createElementVNode("h5", _hoisted_191, _toDisplayString(_ctx.$t('class_ii_typing')), 1),
                            _cache[83] || (_cache[83] = _createElementVNode("div", { class: "table-responsive" }, null, -1))
                          ]),
                          _createElementVNode("div", _hoisted_192, [
                            _createElementVNode("h5", _hoisted_193, _toDisplayString(_ctx.$t('class_ii_typing')), 1),
                            _cache[84] || (_cache[84] = _createElementVNode("div", { class: "table-responsive" }, null, -1))
                          ]),
                          (_ctx.recipientDetails.recipient.antibodies && _ctx.recipientDetails.recipient.antibodies.cumulative.class2 && _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_194, [
                                _cache[85] || (_cache[85] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                                _createElementVNode("h5", _hoisted_195, _toDisplayString(_ctx.$t('class_ii_antibodies')), 1),
                                _createElementVNode("div", _hoisted_196, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "serumsummary-modal-antibodies_class2_unacceptable",
                                    name: _ctx.$t('unacceptable'),
                                    modelValue: _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_allele_group,
                                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_allele_group) = $event)),
                                    alleleSpecific: _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_allele_specific,
                                    alphaBeta: _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.unacceptable_alpha_beta,
                                    readonly: true,
                                    inputClass: "hla-input hla-unacceptable border-0"
                                  }, null, 8, ["name", "modelValue", "alleleSpecific", "alphaBeta"])
                                ]),
                                _createElementVNode("div", _hoisted_197, [
                                  _createVNode(_component_hla_input_group, {
                                    inputId: "serumsummary-modal-antibodies_class2_indeterminate",
                                    name: _ctx.$t('indeterminate'),
                                    modelValue: _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_allele_group,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_allele_group) = $event)),
                                    alleleSpecific: _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_allele_specific,
                                    alphaBeta: _ctx.recipientDetails.recipient.antibodies.cumulative.class2.antibodies.indeterminate_alpha_beta,
                                    readonly: true,
                                    inputClass: "hla-input hla-indeterminate border-0"
                                  }, null, 8, ["name", "modelValue", "alleleSpecific", "alphaBeta"])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : {
          name: "body",
          fn: _withCtx(() => [
            (!!_ctx.recipientDetailsErrorMessage && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_198, _toDisplayString(_ctx.recipientDetailsErrorMessage), 1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
                ], 64))
          ]),
          key: "1"
        }
  ]), 1536))
}