import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-select-checklist-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "form-select-checklist"
}
const _hoisted_3 = {
  key: 1,
  class: "current-checklist-total"
}
const _hoisted_4 = {
  key: 0,
  class: "filter-section-action-row"
}
const _hoisted_5 = { class: "filter-section-wrapper" }
const _hoisted_6 = {
  key: 1,
  class: "row"
}
const _hoisted_7 = { class: "col-12 checklist-body w-100" }
const _hoisted_8 = { class: "right-pane" }
const _hoisted_9 = { class: "sub-task-group-header" }
const _hoisted_10 = { class: "nav-group" }
const _hoisted_11 = ["aria-label", "onClick", "title"]
const _hoisted_12 = { class: "task-line-text" }
const _hoisted_13 = { class: "task-line-name" }
const _hoisted_14 = { class: "nav-group-button" }
const _hoisted_15 = { class: "completed-items" }
const _hoisted_16 = { class: "completed-label" }
const _hoisted_17 = {
  key: 0,
  class: "completed-value"
}
const _hoisted_18 = {
  key: 0,
  class: "action-bar"
}
const _hoisted_19 = ["disabled", "onClick"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["id"]
const _hoisted_22 = ["id"]
const _hoisted_23 = { class: "taskView taskDetails" }
const _hoisted_24 = { class: "taskview-heading" }
const _hoisted_25 = { class: "task-title" }
const _hoisted_26 = ["aria-label", "onClick"]
const _hoisted_27 = { class: "ml-auto" }
const _hoisted_28 = ["aria-label"]
const _hoisted_29 = { class: "task-meta" }
const _hoisted_30 = { class: "list-inline list-annotation" }
const _hoisted_31 = { class: "list-inline-item" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "list-inline-item" }
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = { class: "list-inline-item" }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = { class: "list-inline-item" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = { class: "task-content" }
const _hoisted_40 = {
  key: 0,
  class: "task-outcomes-section"
}
const _hoisted_41 = {
  key: 0,
  class: "task-edit task-wrapper-form"
}
const _hoisted_42 = { class: "taskView" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { class: "notification notification-success notification-inline button-area taskview-notification" }
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = { class: "btn-close" }
const _hoisted_47 = ["aria-label", "onClick"]
const _hoisted_48 = { key: 0 }
const _hoisted_49 = { class: "taskView-empty my-4" }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { class: "notification notification-success notification-inline button-area taskview-notification" }
const _hoisted_52 = ["innerHTML"]
const _hoisted_53 = { class: "btn-close" }
const _hoisted_54 = ["aria-label", "onClick"]
const _hoisted_55 = { key: 0 }
const _hoisted_56 = { class: "task-new task-wrapper-form" }
const _hoisted_57 = { class: "taskView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_more_less = _resolveComponent("more-less")!
  const _component_checklist_task_form = _resolveComponent("checklist-task-form")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "checklist-section",
    ref: "checklistSection",
    "lookups-to-load": _ctx.lookupsToLoad
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("checklists")), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        "sub-section-id": "checklists-sub-section",
        title: _ctx.$t('checklists_subtitle')
      }, {
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.currentChecklists)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_select_input, {
                    selectId: "checklist-items",
                    name: _ctx.$t('checklist_name'),
                    hideLabel: true,
                    textKey: "name",
                    valueKey: "id",
                    modelValue: _ctx.selectedChecklistId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedChecklistId) = $event)),
                    options: _ctx.currentChecklists
                  }, null, 8, ["name", "modelValue", "options"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.selectedChecklistId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('total_items_completed')) + ": " + _toDisplayString(_ctx.editState.checklist_task_completed_count || '0') + " " + _toDisplayString(_ctx.$t('of')) + " " + _toDisplayString(_ctx.editState.checklist_task_count || '0'), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.selectedChecklistId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_filter_component, {
                    fieldID: "checklistFilter",
                    showFilter: true,
                    showChecklistFilter: true,
                    checklistStatus: true,
                    excludeCompleted: true,
                    onSetFilters: _ctx.filterData,
                    statusOptions: _ctx.recipientJourneyChecklistItemStatus,
                    hideSpacer: true
                  }, null, 8, ["onSetFilters", "statusOptions"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.selectedChecklistId && _ctx.editState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.editState)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.editState.groups, (group) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: group.id as string,
                          class: "right-pane-wrapper"
                        }, [
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "btn btn-sm tasklist-accordion-toggle",
                                    "aria-label": _ctx.$t('collapse_group'),
                                    onClick: _withModifiers(($event: any) => (_ctx.toggleGroup(group.id as string)), ["prevent"]),
                                    title: _ctx.$t('collapse_group')
                                  }, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['fas', 'caret-right'],
                                      class: _normalizeClass(["tasklist-accordion-icon", { rotate: group.active }])
                                    }, null, 8, ["class"])
                                  ], 8, _hoisted_11),
                                  _createElementVNode("span", _hoisted_12, [
                                    _createElementVNode("span", _hoisted_13, _toDisplayString(group.name), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("span", _hoisted_15, [
                                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('completed')) + ":", 1),
                                    (group.tasks)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(group.checklist_task_completed_count || '0') + " " + _toDisplayString(_ctx.$t("of")) + " " + _toDisplayString(group.checklist_task_count || '0'), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  (_ctx.uiJourney.canSave)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                        _createElementVNode("button", {
                                          disabled: group.showNew,
                                          type: "button",
                                          class: "btn btn-sm createButton btn-primary",
                                          onClick: _withModifiers(($event: any) => (_ctx.createChecklistItem(group)), ["prevent"])
                                        }, _toDisplayString(_ctx.$t('create_checklist_item')), 9, _hoisted_19)
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ])
                          ]),
                          (group.active)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createElementVNode("ul", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.tasks, (task) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: (task.id as string)
                                    }, [
                                      _createElementVNode("li", {
                                        tabindex: "0",
                                        id: `checklist-item-${task.id}`,
                                        class: _normalizeClass(["my-3 flex-column", `${task.getCompletedStyle} ${task.getSelectedStyle}`])
                                      }, [
                                        _createElementVNode("div", {
                                          class: "task-wrapper",
                                          id: `task-wrapper-${task.id}`
                                        }, [
                                          _createElementVNode("div", {
                                            class: _normalizeClass(`pill pill-full-height pill-${task.getStatusClass}`)
                                          }, null, 2),
                                          _createElementVNode("div", _hoisted_23, [
                                            _createElementVNode("div", _hoisted_24, [
                                              _createElementVNode("div", {
                                                class: _normalizeClass(`pill-normal pill pill-${task.getStatusClass}`)
                                              }, null, 2),
                                              _createElementVNode("div", _hoisted_25, [
                                                _createElementVNode("button", {
                                                  class: "task-line-text",
                                                  "aria-label": _ctx.$t('checklist_item_name'),
                                                  tabindex: "0",
                                                  onClick: _withModifiers(($event: any) => (_ctx.toggleTask(group.id as string, task.id as string)), ["prevent"])
                                                }, _toDisplayString(task.name), 9, _hoisted_26)
                                              ]),
                                              _createElementVNode("div", _hoisted_27, [
                                                _createElementVNode("span", {
                                                  class: _normalizeClass(`badge badge-pill badge-checklist badge-${task.getStatusClass}`),
                                                  "aria-label": _ctx.$t('checklist_item_status')
                                                }, _toDisplayString(_ctx.translateStatus(task.status)), 11, _hoisted_28)
                                              ])
                                            ]),
                                            _createElementVNode("div", _hoisted_29, [
                                              _createElementVNode("ul", _hoisted_30, [
                                                _createElementVNode("li", _hoisted_31, [
                                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('completed_date')) + ": ", 1),
                                                  _createElementVNode("span", {
                                                    innerHTML: task.completed_date ? `<strong>${_ctx.translateCompletedDate(task.completed_date)}</strong>` : `-`
                                                  }, null, 8, _hoisted_32)
                                                ]),
                                                _createElementVNode("li", _hoisted_33, [
                                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('completed_by')) + ": ", 1),
                                                  _createElementVNode("span", {
                                                    innerHTML: task.completed_by_user_id ? `<strong>${task.completed_by_user_id}</strong>` : `-`
                                                  }, null, 8, _hoisted_34)
                                                ]),
                                                (_ctx.isOutcomesEnabled)
                                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                      _createElementVNode("li", _hoisted_35, [
                                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('service_date')) + ": ", 1),
                                                        _createElementVNode("span", {
                                                          innerHTML: task.currentOutcome?.service_date ? `<strong>${_ctx.parseDisplayDateUiFromDateTime(task.currentOutcome?.service_date)}</strong>` : `-`
                                                        }, null, 8, _hoisted_36)
                                                      ]),
                                                      _createElementVNode("li", _hoisted_37, [
                                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('expiry_date')) + ": ", 1),
                                                        _createElementVNode("span", {
                                                          innerHTML: task.currentOutcome?.expiration_date ? `<strong>${_ctx.parseDisplayDateUiFromDateTime(task.currentOutcome?.expiration_date)}</strong>` : `-`
                                                        }, null, 8, _hoisted_38)
                                                      ])
                                                    ], 64))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _createElementVNode("div", _hoisted_39, [
                                              (_ctx.isOutcomesEnabled)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                                    _createVNode(_component_more_less, {
                                                      inlineTitle: `${_ctx.$t('outcome')}: `,
                                                      descriptionText: task.currentOutcome?.outcome,
                                                      highlight: true,
                                                      blankResponse: "-",
                                                      inline: true
                                                    }, null, 8, ["inlineTitle", "descriptionText"])
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ])
                                          ])
                                        ], 8, _hoisted_22),
                                        (task.active)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                              _createElementVNode("div", _hoisted_42, [
                                                _createVNode(_component_checklist_task_form, {
                                                  disabled: !_ctx.uiJourney.canSave,
                                                  ref_for: true,
                                                  ref: task.getTaskReferenceId,
                                                  selection: task,
                                                  showNew: task.id ? false : true,
                                                  checklistId: _ctx.editState.id,
                                                  onSuccess: _ctx.successEdit,
                                                  onCancel: _ctx.cancelEdit,
                                                  groupId: group.id
                                                }, null, 8, ["disabled", "selection", "showNew", "checklistId", "onSuccess", "onCancel", "groupId"])
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ], 10, _hoisted_21),
                                      (group.showHiddenMessage && (task.id === group.showHiddenMessageNear))
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_43, [
                                            _createVNode(_Transition, {
                                              name: "fade-slide",
                                              mode: "out-in"
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_44, [
                                                  _createElementVNode("p", null, [
                                                    _createVNode(_component_font_awesome_icon, {
                                                      icon: ['far', 'check-circle'],
                                                      "fixed-width": "",
                                                      "aria-hidden": "true"
                                                    }),
                                                    _cache[1] || (_cache[1] = _createTextVNode("  ")),
                                                    _createElementVNode("span", {
                                                      innerHTML: group.showHiddenMessage
                                                    }, null, 8, _hoisted_45)
                                                  ]),
                                                  _createElementVNode("div", _hoisted_46, [
                                                    _createElementVNode("button", {
                                                      ref_for: true,
                                                      ref: "dismissShowHiddenMessage",
                                                      type: "button",
                                                      class: "btn btn-sm",
                                                      "aria-label": group.showHiddenMessage,
                                                      onClick: ($event: any) => (group.resetShowHiddenMessage())
                                                    }, [
                                                      _createVNode(_component_font_awesome_icon, {
                                                        icon: ['far', 'times'],
                                                        "fixed-width": "",
                                                        "aria-hidden": "true"
                                                      })
                                                    ], 8, _hoisted_47)
                                                  ])
                                                ])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  }), 128)),
                                  (group.tasks.length === 0)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_48, [
                                        _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t('no_checklist_items_match_the_current_filter')), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (group.showHiddenMessage && !group.showHiddenMessageNear)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_50, [
                                        _createVNode(_Transition, {
                                          name: "fade-slide",
                                          mode: "out-in"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_51, [
                                              _createElementVNode("p", null, [
                                                _createVNode(_component_font_awesome_icon, {
                                                  icon: ['far', 'check-circle'],
                                                  "fixed-width": "",
                                                  "aria-hidden": "true"
                                                }),
                                                _cache[2] || (_cache[2] = _createTextVNode("  ")),
                                                _createElementVNode("span", {
                                                  innerHTML: group.showHiddenMessage
                                                }, null, 8, _hoisted_52)
                                              ]),
                                              _createElementVNode("div", _hoisted_53, [
                                                _createElementVNode("button", {
                                                  ref_for: true,
                                                  ref: "dismissShowHiddenMessage",
                                                  type: "button",
                                                  class: "btn btn-sm",
                                                  "aria-label": group.showHiddenMessage,
                                                  onClick: ($event: any) => (group.resetShowHiddenMessage())
                                                }, [
                                                  _createVNode(_component_font_awesome_icon, {
                                                    icon: ['far', 'times'],
                                                    "fixed-width": "",
                                                    "aria-hidden": "true"
                                                  })
                                                ], 8, _hoisted_54)
                                              ])
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                (group.showNew && !_ctx.isSavingAndCreatingAnother)
                                  ? (_openBlock(), _createElementBlock("ul", _hoisted_55, [
                                      _createElementVNode("li", null, [
                                        _createElementVNode("div", _hoisted_56, [
                                          _createElementVNode("div", _hoisted_57, [
                                            _createVNode(_component_checklist_task_form, {
                                              disabled: !_ctx.uiJourney.canSave,
                                              ref_for: true,
                                              ref: group.newTask.getTaskReferenceId,
                                              selection: group.newTask,
                                              showNew: true,
                                              checklistId: _ctx.editState.id,
                                              groupId: group.id,
                                              onSuccess: _ctx.successNew,
                                              onSuccessNewCreateAnother: _ctx.successNewCreateAnother,
                                              onCancel: _ctx.cancelNew
                                            }, null, 8, ["disabled", "selection", "checklistId", "groupId", "onSuccess", "onSuccessNewCreateAnother", "onCancel"])
                                          ])
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true),
                                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["lookups-to-load"]))
}