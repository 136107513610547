import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "standard-form-group" }
const _hoisted_3 = { class: "standard-form-group-large" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = {
  key: 0,
  class: "standard-form-group"
}
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "form-group col-sm-12" }
const _hoisted_19 = { class: "modal-footer-body" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = ["onClick", "disabled"]
const _hoisted_22 = {
  key: 0,
  class: "pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "opoTransplantDetailsValidations",
    autocomplete: "off",
    tag: "form"
  }, {
    default: _withCtx(({ handleSubmit }) => [
      _createVNode(_component_modal_section, {
        modalId: "opo-transplant-details",
        ref: "opoTransplantDetailsModalSection",
        class: "modal-sticky-header",
        centered: true,
        closeButton: false
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('transplant_details_popup_title')), 1)
        ]),
        body: _withCtx(() => [
          (!_ctx.editState)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.editState.display)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-opo-code",
                            name: _ctx.$t('opo-transplant-opo-code'),
                            disabled: true,
                            modelValue: _ctx.editState.display.opoCode,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.display.opoCode) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-opo-name",
                            name: _ctx.$t('opo-transplant-opo-name'),
                            disabled: true,
                            modelValue: _ctx.editState.display.opoName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.display.opoName) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-province",
                            name: _ctx.$t('opo-transplant-province'),
                            disabled: true,
                            modelValue: _ctx.editState.display.province,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.display.province) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-country",
                            name: _ctx.$t('opo-transplant-country'),
                            disabled: true,
                            modelValue: _ctx.editState.display.country,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.display.country) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-offered-organ",
                            name: _ctx.$t('opo-transplant-offered-organ'),
                            disabled: true,
                            modelValue: _ctx.editState.display.offeredOrgan,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.display.offeredOrgan) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-offer-time",
                            name: _ctx.$t('opo-transplant-offer-time'),
                            disabled: true,
                            modelValue: _ctx.editState.display.offerTime,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.display.offerTime) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-offer-response",
                            name: _ctx.$t('opo-transplant-offer-response'),
                            disabled: true,
                            modelValue: _ctx.editState.display.offerResponse,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.display.offerResponse) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_text_input, {
                            inputId: "opo-transplant-offer-response-time",
                            name: _ctx.$t('opo-transplant-offer-response-time'),
                            disabled: true,
                            modelValue: _ctx.editState.display.offerResponseTime,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.display.offerResponseTime) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
                (_ctx.editState.input)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_select_input, {
                            selectId: "opo-transplant-donor-id",
                            name: _ctx.$t('opo-transplant-donor-id'),
                            modelValue: _ctx.editState.input.donorId,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.input.donorId) = $event)),
                            options: _ctx.donorIdOptions,
                            rules: "required"
                          }, null, 8, ["name", "modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_number_input, {
                            inputId: "opo-transplant-recipient-age",
                            name: _ctx.$t('opo-transplant-recipient-age'),
                            modelValue: _ctx.editState.input.recipientAge,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.input.recipientAge) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_select_input, {
                            selectId: "opo-transplant-recipient-gender",
                            name: _ctx.$t('opo-transplant-recipient-gender'),
                            modelValue: _ctx.editState.input.recipientGender,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.input.recipientGender) = $event)),
                            options: _ctx.genderOptions
                          }, null, 8, ["name", "modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_date_input, {
                            inputId: "opo-transplant-transplant-date",
                            name: _ctx.$t('opo-transplant-transplant-date'),
                            modelValue: _ctx.editState.input.transplantDate,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editState.input.transplantDate) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ]),
                        (_ctx.showTransplantType)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createVNode(_component_select_input, {
                                selectId: "opo-transplant-transplant-type",
                                name: _ctx.$t('opo-transplant-transplant-type'),
                                modelValue: _ctx.editState.input.transplantType,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editState.input.transplantType) = $event)),
                                options: _ctx.transplantTypeOptions
                              }, null, 8, ["name", "modelValue", "options"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_text_area_input, {
                            inputId: "opo-transplant-comment",
                            name: _ctx.$t('opo-transplant-comment'),
                            modelValue: _ctx.editState.input.comment,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editState.input.comment) = $event))
                          }, null, 8, ["name", "modelValue"])
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              type: "button",
              "data-dismiss": "modal",
              class: "btn btn-secondary",
              onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.closeModalSection && _ctx.closeModalSection(...args))),
              disabled: _ctx.isSaving
            }, _toDisplayString(_ctx.$t('cancel')), 9, _hoisted_20),
            _createElementVNode("button", {
              class: "btn btn-success",
              onClick: _withModifiers(($event: any) => (handleSubmit(_ctx.saveOpoTransplantDetails)), ["prevent"]),
              disabled: _ctx.isSaving
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('save_transplant_details_button_label')) + " ", 1),
              (_ctx.isSaving)
                ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-spin",
                      icon: ['far', 'spinner-third']
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_21)
          ])
        ]),
        _: 2
      }, 1536)
    ]),
    _: 1
  }, 512))
}