import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-footer-body" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "checklist-history-modal",
    ref: "checklistHistoryModal",
    centered: true
  }, _createSlots({
    body: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('checklist_history_title_table')), 1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
      _createVNode(_component_sub_section, {
        "sub-section-id": "list-donors",
        title: "",
        mode: "remote",
        "total-records": _ctx.checklistHistoryTableConfig.data.count,
        "table-config": _ctx.checklistHistoryTableConfig
      }, null, 8, ["total-records", "table-config"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": _ctx.$t('close'),
          class: "btn btn-secondary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        }, _toDisplayString(_ctx.$t('close')), 9, _hoisted_2)
      ])
    ]),
    _: 2
  }, [
    (_ctx.records)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('checklist_history_title')), 1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1536))
}