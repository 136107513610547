<template>
  <base-widget :title="$t('bookmarks')" :widget="uiWidget">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-active-donor">
        <font-awesome-icon :icon="['fas', 'user']" />
      </div>
    </template>
    <template v-slot:linkID>
      <widget-links options-id="idBookmarks" section-id="bodyBookmarks" />
    </template>
    <template v-slot:options>
      <widget-options id="idBookmarks" :widget="uiWidget" />
    </template>
    <template v-slot:widget-contents>
      <widget-section id="bodyBookmarks">
        <template v-slot:body>
          <table-toolbar
            :createButton="tableConfig.createButton"
            :createText="tableConfig.createText"
            @table-create-row="onTableCreateRow"
          />
          <table-list
            table-id="bookmarks-table"
            ref="bookmarksTable"
            :table-config="tableConfig"
            @table-row-click="onTableRowClick"
            :highlightSelection="true"
            :firstColumnTabbable="true"
          />
          <bookmark-form
            :widget="uiWidget"
            :selection="selection"
            @success="onSaveSuccess"
            @deleted="onDeleted"
          />
        </template>
      </widget-section>
    </template>
  </base-widget>
</template>

<script lang="ts">
import WidgetSection from '@/components/dashboard/widgets/shared/WidgetSection.vue';
import WidgetOptions from '@/components/dashboard/widgets/shared/WidgetOptions.vue';
import WidgetLinks from '@/components/dashboard/widgets/shared/WidgetLinks.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import BookmarkForm from '@/components/dashboard/widgets/widgetBookmarks/BookmarkForm.vue';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { SortType, TableConfig } from '@/types';
import { i18nMessages } from '@/i18n';
import { Component, Vue } from 'vue-facing-decorator';
import { sortByDates } from '@/utils';
import { WidgetName } from '@/APIModels/dashboardWidgets/types';
import { UIBookmark } from '@/UIModels/dashboard/widgetBookmarks/bookmark';
import { UIWidgetBookmarks } from '@/UIModels/dashboard/widgetBookmarks';
import { parseDisplayDateUi, parseDisplayDateUiFromDateTime, parseDateFromDateObject, stringToDateObject } from '@/utilities/date-utils';

interface BookmarkWidgetRow {
  id: string;
  createdAt: Date|null;
  dueDate: Date|null;
  name: string;
  link: string;
}

@Component({
  components: {
    WidgetSection,
    WidgetOptions,
    WidgetLinks,
    TableToolbar,
    TableList,
    BookmarkForm,
    BaseWidget,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('@/components/dashboard/widgets/_locales/WidgetBookmarks.json'),
  ]),
  emits: [
    'reloadDashboard',
  ]
})
export default class WidgetBookmarks extends Vue {
  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  // Selection instance to provide to child form component
  private selection = new UIListFormSelection();

  get uiWidget(): UIWidgetBookmarks {
    return useCurrentPageStore().dashboard.widgetByName(WidgetName.Bookmarks) as UIWidgetBookmarks;
  }
  // Reference to table
  get bookmarksTable(): TableList {
    return this.$refs.bookmarksTable as TableList;
  }

  get uiBookmarkEntries(): UIBookmark[] {
    if (!this.uiWidget) return [];
    return this.uiWidget.entries || [];
  }

  // Create button click
  public onTableCreateRow() {
    this.bookmarksTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Table row click
  public onTableRowClick(event: any) {
    const listItem = this.uiBookmarkEntries.find((uiBookmarkEntry: UIBookmark) => { return uiBookmarkEntry.id === event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
  }

  // Saved successfully
  public onSaveSuccess() {
    this.bookmarksTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Deleted item
  public onDeleted() {
    this.bookmarksTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  get tableData(): BookmarkWidgetRow[] {
    const rows = this.uiBookmarkEntries.map((uiBookmarkEntry: UIBookmark): BookmarkWidgetRow => {
      return {
        id: uiBookmarkEntry.id,
        createdAt: uiBookmarkEntry.createdAt ? stringToDateObject(uiBookmarkEntry.createdAt) : null,
        dueDate: uiBookmarkEntry.dueDate ? stringToDateObject(uiBookmarkEntry.dueDate) : null,
        name: uiBookmarkEntry.name,
        link: uiBookmarkEntry.link,
      };
    });
    return rows;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: sortByDates(this.tableData, [
        { fieldName: 'dueDate', sortType: SortType.Ascending, nullSortType: SortType.Ascending },
        { fieldName: 'createdAt', sortType: SortType.Ascending, nullSortType: SortType.Ascending },
      ]),
      columns: [
        { label: this.$t('created_at').toString(), field: 'createdAt', formatter: this.formatDate },
        { label: this.$t('due_date').toString(), field: 'dueDate', formatter: this.formatDate },
        { label: this.$t('name').toString(), field: 'name' },
        { label: this.$t('link').toString(), field: 'link', htmlLink: true },
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: [
          { field: 'dueDate', type: SortType.Ascending },
          { field: 'createdAt', type: SortType.Ascending } 
        ]
      },
      empty: this.$t('use_form_below').toString(),
      createButton: true,
      createText: this.$t('create_bookmark').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: [10, 25, 50],
        defaultPageSize: 25,
        position: 'bottom'
      }
    };
  }

  formatDate(date: Date|null): string|undefined {
    return parseDateFromDateObject(date);
  }  
}
</script>
