<template>
  <card-section
    section-id="import-file-card"
    :showLegend="false"
    >
    <template v-slot:body>
      <sub-section 
        :title="$t('import_history')" 
        sub-section-id="import-history-section"
        >
        <template v-slot:contents>
          <!-- List of Items, or History List -->
          <table-list
            ref="importHistoryTable"
            table-id="import-history-table"
            :table-config="tableHistoryConfig"
            @table-row-click="importHistoryRowClick($event)"
            :highlightSelection="true"
            :firstColumnTabbable="true"
            :total-records="importPatients.recordCount || 0"
            @onPageNumber="onPageNumber($event); loadData()"
            @onPageSize="onPageSize($event); loadData()"
            mode="remote"
          />
          <import-detail-modal ref="importDetailModal" />
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, mixins } from "vue-facing-decorator";
import { i18nMessages } from '@/i18n';
import SubSection from "@/components/shared/SubSection.vue";
import TableList from '@/components/shared/TableList.vue';
import { TableConfig } from '@/types';
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIImportPatients, UIImportPatient } from '@/UIModels/importPatients';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Hospital } from "@/store/hospitals/types";
import { GenericCodeValue } from "@/store/types";
import CardSection from '@/components/shared/CardSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import ImportDetailModal from "@/views/importPatient/ImportDetailModal.vue";
import { RemotePaginationMixin } from "@/mixins/remote-pagination-mixin";

const HISTORY_PAGE_SIZES = [10, 25, 50];
const HISTORY_DEFAULT_PAGE_SIZE = HISTORY_PAGE_SIZES[0];

interface TableRow {
  id: string|null;
  date: string|null;
  time: string|null;
  transplantProgram: string|null;
  fileName: string|null;
  uploadedBy: string|null;
  currentStatus: string|null;
  patientCount: number|null;
  importsFailed: number|null;
}

class UIModalDetailFilter {
  private first_name: string|null = null;
  private last_name: string|null = null;
  private date_of_birth: string|null = null;
}

@Component({
  components: {
    CardSection,
    SubSection,
    TableList,
    ModalSection,
    DateInput,
    TextInput,
    ImportDetailModal
  },
  ...i18nMessages([
    require('@/views/importPatient/_locales/import.json'),
  ])
})
export default class ImportHistory extends mixins(DateUtilsMixin, RemotePaginationMixin) {
  // State
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  private isLoading = true;
  private editState: UIModalDetailFilter = new UIModalDetailFilter();

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get importPatients(): UIImportPatients {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.importPatients as UIImportPatients;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  get importHistoryTable(): TableList {
    return this.$refs.importHistoryTable as TableList;
  }

  get importDetailModal(): ImportDetailModal {
    return this.$refs.importDetailModal as ImportDetailModal;
  }

  importHistoryRowClick(event: any): void {
    const row = event ? event.row : null;
    this.importDetailModal.openModal(row);
  }

  get tableHistoryRows(): TableRow[] {
    const rows: TableRow[] = this.importPatients.importPatientHistory.map((item: UIImportPatient) => {
      return {
        id: item.id,
        date: this.parseDisplayDateUiFromDateTime(item.datetime) || '-',
        time: this.parseTimeUiFromDateTime(item.datetime) || '-',
        transplantProgram: this.buildTransplantCentreValue(item.transplantProgram),
        fileName: item.fileName || '-',
        uploadedBy: item.uploadedBy || '-',
        currentStatus: this.$t(item.currentStatus as string).toString() || '-',
        patientCount: item.patientCount,
        importsFailed: item.importsFailed,
      };
    });
    return rows;
  }

  get buildTransplantCentreFilter(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: hospital.program_identifier,
      };
    });
    return mapped;
  }

  private buildTransplantCentreValue(hospitalId: string|null): string|null {
    if (!hospitalId) return '-';

    const hospitalDisplayValue = this.buildTransplantCentreFilter.find((option: GenericCodeValue) => { 
      return option.code === hospitalId; 
    });
    if (!hospitalDisplayValue) return '-';

    return hospitalDisplayValue.value;
  }

  get tableHistoryConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('date')}`,
        field: 'date',
        sortable: false,
      },
      { label: `${this.$t('time')}`,
        field: 'time',
        sortable: false,
      },
      { label: `${this.$t('transplant_program')}`,
        field: 'transplantProgram',
        sortable: false,
      },
      { label: `${this.$t('file_name')}`,
        field: 'fileName',
        sortable: false,
      },
      { label: `${this.$t('uploaded_by')}`,
        field: 'uploadedBy',
        sortable: false,
      },
      { label: `${this.$t('current_status')}`,
        field: 'currentStatus',
        sortable: false,
      },
      { label: `${this.$t('patient_count')}`,
        field: 'patientCount',
        sortable: false,
      },
      { label: `${this.$t('imports_failed')}`,
        field: 'importsFailed',
        sortable: false
      },
    ];

    return {
      data: this.tableHistoryRows,
      columns: tableConfig,
      empty: this.$t('no_history').toString(),
      pagination: true,
      sortOptions: {
        enabled: false
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: HISTORY_PAGE_SIZES,
        defaultPageSize: HISTORY_DEFAULT_PAGE_SIZE,
        setCurrentPage: this.pageNumber,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  get hospitalOptions(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: `${hospital.program_identifier} - ${hospital.hospital_name_info?.name}`,
      };
    });
    return mapped;
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  private modalFilterButton(): void {
    // filter
  }

  private modalClearButton(): void {
    this.editState = new UIModalDetailFilter();
  }

  /**
   * Loads recipients list
   * NOTE: custom search parameters and sort options determined by local component state
   */
   public async loadData(): Promise<void> {
    this.isLoading = true;
    const params = { 
      page_number: this.pageNumber,
      page_size: this.perPage,
    };
    await this.importPatients.loadImportPatientHistory(params);
    this.isLoading = false;
  }

  private mounted(): void {
    this.perPage = HISTORY_DEFAULT_PAGE_SIZE;
  }
}
</script>
