import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-block mb-2 mt-3" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-12" }
const _hoisted_8 = { class: "mb-2 font-weight-600" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group-6column-xlarge-only" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "standard-form-group" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "mb-2 font-weight-600" }
const _hoisted_17 = { class: "list-counter checklist-form-elements" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = {
  key: 0,
  class: "col-md-2"
}
const _hoisted_20 = { class: "col-md-6 checklistColumncheckbox" }
const _hoisted_21 = { class: "d-flex align-items-center" }
const _hoisted_22 = { class: "mr-5" }
const _hoisted_23 = { class: "mr-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checklist_history_modal = _resolveComponent("checklist-history-modal")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checklist_text_area = _resolveComponent("checklist-text-area")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t('checklists'),
    "sub-section-id": "donor-checklist",
    tabbableValue: _ctx.$t('checklist_date'),
    "table-config": _ctx.checklistTableConfig,
    onTableRowClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectChecklist($event))),
    saveButton: _ctx.saveButtonDisplay && _ctx.fields,
    disabled: _ctx.isLoadingAllocation || !_ctx.canSave,
    "save-button-text": _ctx.saveChecklistButtonText(),
    lookupsToLoad: _ctx.lookupsToLoad,
    ref: "saveChecklist",
    onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.savePatch()))
  }, {
    "button-bar": _withCtx(() => [
      _createVNode(_component_checklist_history_modal, { ref: "checklistHistoryModal" }, null, 512),
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-light btn-sm",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAllocation('Pre'))),
          disabled: !_ctx.allowPreAllocationChecklist
        }, _toDisplayString(_ctx.$t('create_pre_allocation_checklist')), 9, _hoisted_2),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-light btn-sm ml-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAllocation('Post'))),
          disabled: !_ctx.allowPostAllocationChecklist
        }, _toDisplayString(_ctx.isReAllocationChecklist ? _ctx.$t('create_re_allocation_checklist').toString(): _ctx.$t('create_post_allocation_checklist').toString()), 9, _hoisted_3),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-light btn-sm ml-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openChecklistHistory())),
          disabled: _ctx.getChecklistHistory.length === 0,
          "data-toggle": "modal",
          "data-target": "#checklistHistory"
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'history'],
            "fixed-width": ""
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('checklist_history')), 1)
        ], 8, _hoisted_4)
      ])
    ]),
    contents: _withCtx(() => [
      (!_ctx.editState || !_ctx.allocation)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
        : (_openBlock(), _createElementBlock("fieldset", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('identifying_information')), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_text_input, {
                  inputId: "doa-allocation-id",
                  name: _ctx.$t('allocation_id'),
                  disabled: true,
                  modelValue: _ctx.allocation.client_id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.allocation.client_id) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_text_input, {
                  inputId: "doa-allocation-run-date",
                  name: _ctx.$t('allocation_run_date_time'),
                  disabled: true,
                  modelValue: _ctx.editState.allocationRunDate,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.allocationRunDate) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_text_input, {
                  inputId: "doa-allocation-donor-id",
                  name: _ctx.$t('donor_id'),
                  disabled: true,
                  modelValue: _ctx.editState.donorId,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.donorId) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_text_input, {
                  inputId: "doa-allocation-organ",
                  name: _ctx.$t('consented_organ'),
                  disabled: true,
                  modelValue: _ctx.editState.consentedOrgan,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.consentedOrgan) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_text_input, {
                  inputId: "doa-allocation-first-name",
                  name: _ctx.$t('donor_first_name'),
                  disabled: true,
                  modelValue: _ctx.editState.donorFirstName,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.donorFirstName) = $event))
                }, null, 8, ["name", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_text_input, {
                  inputId: "doa-allocation-last-name",
                  name: _ctx.$t('donor_last_name'),
                  disabled: true,
                  modelValue: _ctx.editState.donorLastName,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.donorLastName) = $event))
                }, null, 8, ["name", "modelValue"])
              ])
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "hr-break" }, null, -1)),
            (_ctx.fields)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.allocationChecklistTitle), 1),
                  _createElementVNode("ol", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedConfirmations, (confirmation, idx) => {
                      return (_openBlock(), _createElementBlock("li", { key: idx }, [
                        _createElementVNode("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(confirmation.fields, (field, j) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: j,
                              class: "form-group col-md-2 checklistColumnInput"
                            }, [
                              (field.labelOnly)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                                    _createElementVNode("label", null, _toDisplayString(field.label), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (!field.labelOnly && !field.textArea)
                                ? (_openBlock(), _createBlock(_component_text_input, {
                                    key: 1,
                                    inputId: 'chk-' + field.name,
                                    name: field.label,
                                    disabled: true,
                                    class: "col-md-2",
                                    modelValue: field.value,
                                    "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                                  }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true),
                              (!field.labelOnly && field.textArea)
                                ? (_openBlock(), _createBlock(_component_checklist_text_area, {
                                    key: 2,
                                    inputId: 'chk-' + field.name,
                                    name: field.label,
                                    modelValue: field.value,
                                    label: field.label
                                  }, null, 8, ["inputId", "name", "modelValue", "label"]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128)),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createVNode(_component_checkbox_input, {
                                  labelName: _ctx.$t('csc_1_confirmation'),
                                  inputId: confirmation.checkField + '-csc1-confirm',
                                  disabled: _ctx.disableCsc1Confirmation,
                                  modelValue: _ctx.fields.csc1[confirmation.checkField],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.fields.csc1[confirmation.checkField]) = $event),
                                  label: _ctx.$t('i_confirm')
                                }, null, 8, ["labelName", "inputId", "disabled", "modelValue", "onUpdate:modelValue", "label"])
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(_component_checkbox_input, {
                                  labelName: _ctx.$t('csc_2_confirmation'),
                                  inputId: confirmation.checkField + '-csc2-confirm',
                                  disabled: _ctx.disableCsc2Confirmation,
                                  modelValue: _ctx.fields.csc2[confirmation.checkField],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.fields.csc2[confirmation.checkField]) = $event),
                                  label: _ctx.$t('i_confirm')
                                }, null, 8, ["labelName", "inputId", "disabled", "modelValue", "onUpdate:modelValue", "label"])
                              ])
                            ])
                          ])
                        ]),
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "hr-break" }, null, -1))
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }, 8, ["title", "tabbableValue", "table-config", "saveButton", "disabled", "save-button-text", "lookupsToLoad"]))
}