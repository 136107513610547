<template>
  <!-- Sub-section container -->
    <sub-section
      sub-section-id="proto_living_donor_patient_address"
      :title="$t('patient_address_title')"
    >
    <template v-slot:contents>
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="showCreateButton"
        :createText="$t('address_create_button')"
        @table-create-row="handleTableCreateRow"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="protoLDAddressFilter"
                :showFilter="true"
                :showArchived="true"
                @setFilters="[]"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="protoLDPatientAddressTable"
        table-id="proto-ld-patient-address-table"
        :table-config="patientAddressTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        tabbableColumn="lastUpdatedDisplay"
        :rowStyleClass="rowStyleClass"
        
      />
      <!-- Form layout -->
      <address-form
        form-id="proto-ld-patient-address-form"
        ref="protoLDPatientAddressForm"
        :selection="selection"
        :newRecipient="newRecipient"
        :canSave="canSave"
        @save="performSave"
        @cancel="resetSelection"
      />
    </template>
  </sub-section>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { UIAddress } from "@/UIModels/address";
import { AddressCategoryLookup, Country, Province } from '@/store/lookups/types';
import { SaveResult } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from '@/components/shared/filter/FilterComponent.vue';
import TableList from '@/components/shared/TableList.vue';
import AddressForm from '@/prototypes/livingDonor/contactInformation/protoLDAddress/ProtoLDPatientAddressForm.vue';
import { UIError } from '@/UIModels/error';
import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { i18nMessages } from '@/i18n';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { sortByDate } from '@/utils';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

interface PatientAddressComponentRow {
  id: string|null;
  lastUpdatedDisplay: string;
  categoryType: string;
  status: string;
  isNew: boolean;
  countryCode: string;
  streetAddress: string;
  city: string;
  stateCode: string;
  provinceCode: string;
  zipCode: string;
  postalCode: string;
  countryOther: string;
  urbanization: string;
}
const EDITSTATE_KEY = 'editState';
// What are all the possible per-page sizes allowed in the table list pagination bar?
const PAGE_SIZES = [5, 10, 25];

@Component({
  components: {
    SubSection,
    TableToolbar,
    FilterComponent,
    TableList,
    AddressForm,
  },
  ...i18nMessages([
    require('@/prototypes/livingDonor/contactInformation/_locales/ProtoLDPatientAddress.json'),
  ]),
})
export default class PatientAddress extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.address_categories) addressCategories!: AddressCategoryLookup[];
  @State(state => state.lookups.country) countryLookup!: Country[];
  @State(state => state.lookups.us_state) usStateLookup!: Province[];
  @State(state => state.lookups.province) provinceLookup!: Province[];

  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('country', { namespace: 'lookups' }) private countryOptions!: Country[];

  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private addresses: any = [];

  private selection: PatientAddressComponentRow = {
    id: "",
    lastUpdatedDisplay: "",
    categoryType: "",
    isNew: true,
    status: "",
    countryCode:"",
    streetAddress: "",
    city: "",
    stateCode: "",
    provinceCode: "",
    zipCode: "",
    postalCode: "",
    countryOther: "",
    urbanization: ""
  }

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private editSelected(row: any) {
    if (row) { 
      this.selection.id = row.row.id;
      this.selection.categoryType = row.row.categoryType;
      this.selection.countryCode = row.row.countryCode;
      this.selection.streetAddress = row.row.streetAddress;
      this.selection.city = row.row.city;
      this.selection.stateCode = row.row.stateCode;
      this.selection.provinceCode = row.row.provinceCode;
      this.selection.zipCode = row.row.zipCode;
      this.selection.postalCode = row.row.postalCode;
      this.selection.countryOther = row.row.countryOther;
      this.selection.isNew = false;
      this.selection.status = row.row.status;
      this.selection.urbanization = row.row.urbanization;
     }
  }

  public performSave(payload: any): any {
    // Call the 'startSaving' method to begin save animation

    let addresses = this.addresses || [];

    if (!this.selection.isNew ){  
      payload.id = this.selection.id;
      const foundIndex = addresses.findIndex((contact: any) => contact.id == this.selection.id);
      
      if (foundIndex != -1) {
        addresses[foundIndex] = payload;
      }
    } else {  
      addresses.push(payload);
    }
    
    this.protoBucket.setItem(EDITSTATE_KEY, addresses); 
    this.resetSelection();   
  } 

  private resetSelection(): any {
    this.selection.id = "";
    this.selection.categoryType = "";
    this.selection.countryCode = "";
    this.selection.streetAddress = "";
    this.selection.city = "";
    this.selection.status = "";
    this.selection.isNew = true;
    this.selection.stateCode= "";
    this.selection.provinceCode = "";
    this.selection.zipCode = "";
    this.selection.postalCode = "";
    this.selection.countryOther = "";
    this.selection.isNew = true;
    this.selection.urbanization = '';
  }

  get showCreateButton(): boolean {
    return this.canSave && !this.newRecipient;
  }

  // Configure the list table
  get patientAddressTableConfig(): TableConfig {
    return {
      data: this.tableRows || [],
      columns: [
        { label: this.$t('address_last_updated').toString(), field: 'lastUpdatedDisplay', width: '125px'},
        { label: this.$t('address_category_name').toString(), field: 'categoryType',  width: '185px' },
        { label: this.$t('address_description').toString(), field: 'description' },
        { label: this.$t('address_status').toString(), field: 'status', width: '105px'},
      ],
      empty: this.$t('address_table_empty').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  get tableRows() {
    const contactInformation = this.addresses || [];
   
    if (contactInformation.length > 0) {

    return contactInformation.map((address: any) => {
      return {
        lastUpdatedDisplay: address.lastUpdatedDateObject,
        id: address.id,
        categoryType: address.categoryType ? this.lookupValue(address.categoryType, 'address_categories') : '-',
        description: this.getAddressInfo(address),
        status: address.status && address.status == 'status.active'? 'Active' : '-',
        countryCode: address.countryCode,
        streetAddress: address.streetAddress,
        city: address.city,
        stateCode: address.stateCode,
        provinceCode: address.provinceCode,
        zipCode: address.zipCode,
        postalCode: address.postalCode,
        countryOther: address.countryOther,
        urbanization: address.urbanization,
      };
    });
   }
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: PatientAddressComponentRow): string|null {
    if (!row) return null;
    return null;
  }


  // Clear selected address based on a create button click event
  private getAddressInfo(address: any): any {
    // need to work out home urbanization works in address not sure if needed for prototype
    let countryValue;
    this.countryLookup.map((country: any) => {
      if (country.code == address.countryCode) {
        countryValue =  country.value;
        }
      });

    if (address.countryCode =="USA") {
      let stateValue;
       this.usStateLookup.map((state: any) => {
        if (state.code == address.stateCode) {
          stateValue =  state.value;
          }
        });

      return `${address.streetAddress}, ${address.city}, ${stateValue}, ${countryValue}, ${address.zipCode}`;

    } else if (address.countryCode == "XX" ) {
      return `${address.streetAddress}, ${address.city}, ${address.countryOther}`;
    }
    else {
      if (address.streetAddress !== null && address.city  !== null  && address.postalCode  !== null ) {
        let provinceValue;
        this.provinceLookup.map((province: any) => {
          if (province.code == address.provinceCode) {
            provinceValue =  province.value;
            }
          });
        return `${address.streetAddress}, ${address.city}, ${provinceValue},  ${countryValue}, ${address.postalCode}`;
      }  else {
        return '-';
      }
      
    }
  }
  
  private handleTableCreateRow(): void {
    this.resetSelection();
  }


  // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
  async mounted() {
    // No-op if prototypes not enabled
    if (!useCurrentPageStore().configuration.prototypes) return;

    // Make a per-recipient prototype bucket
    this.protoBucket = new UIPrototypeBucket(PROTOTYPES.ProtoLDPatientAddress, { recipientId: this.$route.params.id });

    // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
    const response: PatientAddressComponentRow = await this.protoBucket.getItem(EDITSTATE_KEY);
    if (!response) return;

    this.addresses = response;
  }
}
</script>
