<template>
  <form v-if="isProtoKidneyInformationEnabled">
    <sub-section
      sub-section-id="proto-Kidney-information-section"
      :title="title"
    >
    <template v-slot:contents>
    
      <!-- Form layout -->
     
        <form-layout
        form-id="kidneyInformation-form">
          <template v-slot:contents>
            <div class="row">
              <div class="standard-form-group">
                <date-input
                  inputId="serum-creatinine-date"
                  name='Serum Creatinine Date'
                  v-model="editState.serum_creatinine_date"   
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  inputId="kidney-serum_creatinine"
                  name="Serum Creatinine"
                  :append="true"
                  appendText="µmol/L"
                  step="0.1"
                  v-model="editState.serum_creatinine"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="a1c-date"
                  name='A1C Date'
                  v-model="editState.a1c_date"   
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  inputId="a1c-value"
                  name="A1C"
                  :append="true"
                  appendText="%"
                  step="0.1"
                  v-model="editState.a1c_value"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="serum-albumin-date"
                  name='Serum Albumin Date'
                  v-model="editState.serum_albumin_date"   
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  inputId="kidney-serum_albumin"
                  name="Serum Albumin"
                  :append="true"
                  appendText="g/dL"
                  step="0.1"
                  v-model="editState.serum_albumin"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="serum-bun-date"
                  name='Serum BUN Date'
                  v-model="editState.serum_bun_date"   
                />
              </div>
              <div class="standard-form-group">
                <number-input
                  inputId="serum-bun"
                  name="Serum BUN"
                  :append="true"
                  appendText="mg/dL"
                  step="0.1"
                  v-model="editState.serum_bun"
                />
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="urine-output"
                  name='Urine Output'
                  :options="urineOutputOptions"
                  v-model="editState.urine_output">              
                </select-input>
              </div>
              <div class="standard-form-group">
                <select-input
                  select-id="urine-output-volume"
                  name='Output Volume'
                  :options="urineOutputVolumneOptions"
                   :disabled="!editState.urine_output"
                  v-model="editState.urine_output_volume">              
                </select-input>
              </div>

            </div>
            <div class="row">
              <div class="standard-full-width-group">
                <text-area-input input-id="kidney-info-comments" name="Comments" rows="4" v-model="editState.comments"  />
              </div>
            </div>
   

          </template>
          <template v-slot:save>
            <save-toolbar
              :show="true"     
              ref="saveKidneyInformation"
              label="Save Kidney Information"
              :cancelButton="true"
              @save="performSave()"
              @cancel="clearForm()"
            />
          </template>


        </form-layout>
        
      </template>
    </sub-section>
  </form>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
  SaveProvider,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface KidneyInformationForm {
  serum_creatinine_date: Date|null,
  serum_creatinine: number | null,
  a1c_date: Date|null,
  a1c_value: number | null,
  serum_albumin_date: Date|null,
  serum_albumin: number | null,
  serum_bun_date: Date|null,
  serum_bun: number | null,
  urine_output: boolean | null,
  urine_output_volume: number | null,
  comments: string
}

@Component({
  components: {
    SubSection,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
    DateInput,
    NumberInput,
    SelectInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoKidneyInformation extends Vue {

  private title: any = "KIDNEY INFORMATION";

  private editState: KidneyInformationForm = {
    serum_creatinine_date: null,
    serum_creatinine: null,
    a1c_date: null,
    a1c_value: null,
    serum_albumin_date: null,
    serum_albumin: null,
    serum_bun_date: null,
    serum_bun: null,
    urine_output: null,
    urine_output_volume: null,
    comments: ""
  };

  private urineOutputOptions = [{
    code: 0,
    value: 'Yes'
    }, {
      code: 1,
      value: 'No'
    }
  ];

  private urineOutputVolumneOptions = [{
      code: 0,
      value: '< 50 ml'
    }, 
    {
      code: 1,
      value: '50 - 500 ml'
    },  
    {
      code: 2,
      value: '> 500 ml'
    }
  ];

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  get isProtoKidneyInformationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.KidneyInformation);
  }

        // Each save toolbar will need a unique ref
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveKidneyInformation as SaveToolbar;
  }

  // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
  async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.KidneyInformation, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: KidneyInformationForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        serum_creatinine_date: response.serum_creatinine_date,
        serum_creatinine: response.serum_creatinine,
        a1c_date: response.a1c_date,
        a1c_value: response.a1c_value,
        serum_albumin_date: response.serum_albumin_date,
        serum_albumin: response.serum_albumin,
        serum_bun_date: response.serum_bun_date,
        serum_bun: response.serum_bun,
        urine_output: response.urine_output,
        urine_output_volume: response.urine_output_volume,
        comments: response.comments
       
      };
    }

  public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: KidneyInformationForm = {
        serum_creatinine_date: this.editState.serum_creatinine_date,
        serum_creatinine: this.editState.serum_creatinine,
        a1c_date: this.editState.a1c_date,
        a1c_value: this.editState.a1c_value,
        serum_albumin_date: this.editState.serum_albumin_date,
        serum_albumin: this.editState.serum_albumin,
        serum_bun_date: this.editState.serum_bun_date,
        serum_bun: this.editState.serum_bun,
        urine_output: this.editState.urine_output,
        urine_output_volume: this.editState.urine_output_volume,
        comments: this.editState.comments
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 


    public clearForm(): any {
      this.editState.serum_creatinine_date = null;
      this.editState.serum_creatinine = null;
      this.editState.a1c_date = null;
      this.editState.a1c_value = null;
      this.editState.serum_albumin_date = null;
      this.editState.serum_albumin = null;
      this.editState.serum_bun_date = null;
      this.editState.serum_bun = null;
      this.editState.urine_output = null;
      this.editState.urine_output_volume = null;
      this.editState.comments = "";
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveKidneyInformation as unknown as SaveProvider;

      saveToolbar.resetSaveToolbar();
    }
    
 }



</script>

<style>


</style>
