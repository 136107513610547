import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_form = _resolveComponent("loading-form")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": "recipientEgfr-form"
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            (_ctx.selection?.isNew)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('egfr_new_record')), 1))
              : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('egfr_selected_record')), 1))
          ])
        ]),
        contents: _withCtx(() => [
          (!_ctx.editState.loaded)
            ? (_openBlock(), _createBlock(_component_loading_form, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_date_input, {
                      ruleKey: "patient_egfr.date",
                      inputId: "egfr_date",
                      name: _ctx.$t('egfr_date'),
                      modelValue: _ctx.editState.date,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.date) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_number_input, {
                      ruleKey: "patient_egfr.egfr",
                      inputId: "egfr_value",
                      name: _ctx.$t('egfr_egfr'),
                      append: true,
                      appendText: "mL/min/1.73m²",
                      step: "0.1",
                      modelValue: _ctx.editState.egfr,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.egfr) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_text_area_input, {
                      ruleKey: "patient_egfr.comments",
                      "input-id": "egfr_comments",
                      name: _ctx.$t('egfr_comments'),
                      rows: "4",
                      modelValue: _ctx.editState.comments,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.comments) = $event))
                    }, null, 8, ["name", "modelValue"])
                  ])
                ])
              ]))
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.enableSaveToolbar,
            ref: "saveToolbar",
            class: "card-footer action-row temp-saving row",
            label: _ctx.$t('egfr_save_record'),
            cancelButton: true,
            onSave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSave())),
            onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleCancel()))
          }, null, 8, ["show", "label"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 512))
}