<template>
  <card-section 
    section-id="checklist-section" 
    ref="checklistSection"
    :lookups-to-load="lookupsToLoad"
    >
    <template v-slot:header>
      {{ $t("checklists") }}
    </template>
    <template v-slot:body>
      <!-- Sub-section container -->
      <sub-section sub-section-id="checklists-sub-section" :title="$t('checklists_subtitle')">
        <template v-slot:contents>
          <div class="form-select-checklist-wrapper">
            <div v-if="currentChecklists" class="form-select-checklist">
              <select-input
                selectId="checklist-items" 
                :name="$t('checklist_name')" 
                :hideLabel="true"
                textKey="name"
                valueKey="id"
                v-model="selectedChecklistId" 
                :options="currentChecklists" 
              />
            </div>
            <div v-if="selectedChecklistId"
              class="current-checklist-total">{{ $t('total_items_completed') }}: {{ editState.checklist_task_completed_count || '0' }} {{ $t('of')}} {{ editState.checklist_task_count || '0' }}</div>
          </div>

          <div class="filter-section-action-row" v-if="selectedChecklistId">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="checklistFilter" 
                :showFilter="true"
                :showChecklistFilter="true"
                :checklistStatus="true"
                :excludeCompleted="true" 
                @setFilters="filterData"
                :statusOptions='recipientJourneyChecklistItemStatus'
                :hideSpacer="true"
              />
            </div>
          </div>

          <div class="row" v-if="selectedChecklistId && editState" >
            <div class="col-12 checklist-body w-100">
              
              <template v-if="editState">
                <div v-for="(group) in editState.groups" :key="group.id as string" class="right-pane-wrapper">
                  <div class="right-pane">
                    <ul>
                      <li class="sub-task-group-header">
                        <div class="nav-group">
                          <button type="button" class="btn btn-sm tasklist-accordion-toggle"
                            :aria-label="$t('collapse_group')"
                            @click.prevent="toggleGroup(group.id as string)" :title="$t('collapse_group')">

                            <font-awesome-icon :icon="['fas', 'caret-right']" class="tasklist-accordion-icon" :class="{ rotate: group.active }" />
                          </button>

                          <span class="task-line-text">
                            <span class="task-line-name">{{group.name}}</span> 
                          </span>  
                               
                        </div>
                        <div class="nav-group-button">

                          <!-- completed bar -->
                          <span class="completed-items">
                            <span class="completed-label">{{ $t('completed') }}:</span>
                            <span v-if="group.tasks" class="completed-value"> {{ group.checklist_task_completed_count || '0' }} {{ $t("of") }} {{ group.checklist_task_count || '0' }}</span>
                          </span>

                          <div class="action-bar" v-if="uiJourney.canSave">
                             <button
                                :disabled="group.showNew" 
                                type="button"
                                class="btn btn-sm createButton btn-primary" 
                                @click.prevent="createChecklistItem(group)">
                                {{$t('create_checklist_item') }}
                              </button> 
                            </div>
                          </div>
                      </li>
                    </ul>
                  </div>
                <div v-if="group.active">
                  <ul>
                    <!-- for each task -->
                    <div v-for="(task) in group.tasks" :key="(task.id as string)">
                      <li 
                        tabindex="0" 
                        :id="`checklist-item-${task.id}`" 
                        class="my-3 flex-column"
                        :class="`${task.getCompletedStyle} ${task.getSelectedStyle}`"
                      >
                        <div class="task-wrapper" :id="`task-wrapper-${task.id}`">
                          <div :class="`pill pill-full-height pill-${task.getStatusClass}`"></div>
                          
                          <div class="taskView taskDetails">
                            <div class="taskview-heading">
                              <div :class="`pill-normal pill pill-${task.getStatusClass}`"></div>
                              
                              <div class="task-title">
                                <button class="task-line-text" :aria-label="$t('checklist_item_name')" tabindex="0"
                                  @click.prevent="toggleTask(group.id as string, task.id as string)">
                                  {{task.name}}
                                </button>
                              </div>
                              
                              <div class="ml-auto">
                                <span 
                                :class="`badge badge-pill badge-checklist badge-${task.getStatusClass}`"
                                :aria-label="$t('checklist_item_status')"
                                >{{translateStatus(task.status)}}</span>
                              </div>
                            </div>
                          
                            <div class="task-meta">
                              <ul class="list-inline list-annotation">
                                <li class="list-inline-item">
                                  <span>{{ $t('completed_date')}}: </span>
                                  <span v-html="task.completed_date ? `<strong>${translateCompletedDate(task.completed_date)}</strong>` : `-`"/>
                                </li>
                                <li class="list-inline-item">
                                  <span>{{ $t('completed_by')}}: </span>
                                  <span v-html="task.completed_by_user_id ? `<strong>${task.completed_by_user_id}</strong>` : `-`"/>
                                </li>
                                <template v-if="isOutcomesEnabled">
                                  <li class="list-inline-item">
                                    <span>{{ $t('service_date')}}: </span>
                                    <span v-html="task.currentOutcome?.service_date ? `<strong>${parseDisplayDateUiFromDateTime(task.currentOutcome?.service_date)}</strong>` : `-`"/>
                                  </li>
                                  <li class="list-inline-item">
                                    <span>{{ $t('expiry_date')}}: </span>
                                    <span v-html="task.currentOutcome?.expiration_date ? `<strong>${parseDisplayDateUiFromDateTime(task.currentOutcome?.expiration_date)}</strong>` : `-`"/>
                                  </li>
                                </template>
                              </ul>
                            </div>

                            <div class="task-content">
                            
                              <div v-if="isOutcomesEnabled" class="task-outcomes-section">
                                <more-less 
                                  :inlineTitle="`${$t('outcome')}: `"
                                  :descriptionText="task.currentOutcome?.outcome"
                                  :highlight="true"
                                  blankResponse="-"
                                  :inline="true"
                                />
                              </div>
                            </div>
                          
                          </div>
                        </div>
                        <!-- There is separate styling for task-edit and task-new as the new task has a thick border around it -->
                        <div v-if="task.active" class="task-edit task-wrapper-form">
                          <div class="taskView">

                            <checklist-task-form
                              :disabled="!uiJourney.canSave"
                              :ref="task.getTaskReferenceId"
                              :selection="task"
                              :showNew="task.id ? false : true"
                              :checklistId="editState.id"
                              @success="successEdit"
                              @cancel="cancelEdit"
                              :groupId="group.id"
                              
                            />
                          </div>
                        </div>
                      </li>

                      <!-- hidden task message where the task was -->
                      <li v-if="group.showHiddenMessage && (task.id === group.showHiddenMessageNear)">
                        <transition name="fade-slide" mode="out-in">
                          <div class="notification notification-success notification-inline button-area taskview-notification">
                            <p>
                              <font-awesome-icon :icon="['far', 'check-circle']" fixed-width aria-hidden="true" />&nbsp;
                              <span v-html="group.showHiddenMessage"></span>
                            </p>
                            <div class="btn-close">
                              <button
                                ref="dismissShowHiddenMessage"
                                type="button"
                                class="btn btn-sm"
                                :aria-label="group.showHiddenMessage"
                                @click="group.resetShowHiddenMessage()"
                              >
                                <font-awesome-icon :icon="['far', 'times']" fixed-width aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </transition>
                      </li>
                    </div>

                    <!-- empty group -->
                    <template v-if="group.tasks.length === 0">
                      <li>
                        <div class="taskView-empty my-4">
                          {{ $t('no_checklist_items_match_the_current_filter') }}
                        </div>
                      </li>
                    </template>

                    <!-- hidden task message -->
                    <template v-if="group.showHiddenMessage && !group.showHiddenMessageNear">
                      <li>
                        <transition name="fade-slide" mode="out-in">
                          <div class="notification notification-success notification-inline button-area taskview-notification">
                            <p>
                              <font-awesome-icon :icon="['far', 'check-circle']" fixed-width aria-hidden="true" />&nbsp;
                              <span v-html="group.showHiddenMessage"></span>
                            </p>
                            <div class="btn-close">
                              <button
                                ref="dismissShowHiddenMessage"
                                type="button"
                                class="btn btn-sm"
                                :aria-label="group.showHiddenMessage"
                                @click="group.resetShowHiddenMessage()"
                              >
                                <font-awesome-icon :icon="['far', 'times']" fixed-width aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </transition>
                      </li>
                    </template>
                  </ul>

                  <!-- new task -->
                  <ul v-if="group.showNew && !isSavingAndCreatingAnother">
                    <li>
                      <div class="task-new task-wrapper-form">
                        <div class="taskView">
                          <checklist-task-form
                            :disabled="!uiJourney.canSave"
                            :ref="group.newTask.getTaskReferenceId"
                            :selection="group.newTask"
                            :showNew="true"
                            :checklistId="editState.id"
                            :groupId="group.id"
                            @success="successNew"
                            @successNewCreateAnother="successNewCreateAnother"
                            @cancel="cancelNew"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>

                  <br/>
                </div>

              </div>
              </template>
            </div>
       
          </div>
        </template>
      </sub-section>
      
    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Organ } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { RecipientJourney, UIFilterAssignments } from '@/store/recipientJourney/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIError } from '@/UIModels/error';
import { UIChecklist, UIChecklistGroup, UIChecklistTask } from "@/UIModels/journey/checklist";
import { GenericCodeValue } from '@/store/types';
import ChecklistTaskForm from "@/components/organs/shared/checklist/ChecklistTaskForm.vue";
import SelectInput from '@/components/shared/SelectInput.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import { i18nMessages } from "@/i18n";
import { APIChecklistTaskInterface, APIChecklistCounts } from "@/APIModels/journey/types";
import { UIConfiguration } from '@/UIModels/configuration';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import MoreLess from '@/components/shared/MoreLessComponent.vue';

@Component({
  components: {
    CardSection,
    SubSection,
    ChecklistTaskForm,
    SelectInput,
    FilterComponent,
    MoreLess
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/ChecklistSection.json'),
    require('@/components/organs/shared/_locales/common.json'),
  ]),
})

export default class ChecklistSection extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.lookups.recipient_journey_checklist_item_status) recipientJourneyChecklistItemStatus!: GenericCodeValue[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  @Prop({ default: false }) newJourney!: boolean

  private selectedChecklistId: string|null = null
  private editState: UIChecklist = new UIChecklist();

  // Property used to hide 'new form' during 'save and create another' flow so that it re-mounts entire form
  // NOTE: this prevents visual glitch in select input (see AP-1507)
  private isSavingAndCreatingAnother = false;

  lookupsToLoad = ['recipient_journey_checklist_item_status', 'checklist_task_outcome_imaging_received'];
  private isLoading = true;

  get uiJourney(): UIJourney {
    return useCurrentPageStore().currentJourney as UIJourney;
  }

  get isOutcomesEnabled(): boolean {
    return this.currentConfiguration.features.journeyConfig.checklists.outcomes.enabled;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Which Journey view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  // Get UI checklist view model from current page store
  get currentChecklists(): UIChecklist[] {
    const uiJourney = this.currentJourney;
    if (!uiJourney) return [];

    return uiJourney.checklists as UIChecklist[];
  }

  private filters: UIFilterAssignments = {
    exclude_completed: false,
    checklist_status: null
  };

  private filterData(filterObject: UIFilterAssignments) {
    this.filters = filterObject;
    this.resetEditState();
  }

  translateStatus(status: string|null): string {
    if (!status) return '-';
    const found = this.recipientJourneyChecklistItemStatus.find((item: GenericCodeValue) => {
      return item.code === status;
    });
    return found ? found.value : '-';
  }

  translateCompletedDate(completed_date: string|null): string {
    return this.parseDisplayDateUi(completed_date) || '-';
  }

  mounted() {
    this.initEditState();
  }

  buildFilterParams(): any {
    const params: any = {};
    if (this.filters.checklist_status) { params['status'] = this.filters.checklist_status; }
    if (this.filters.exclude_completed) { params['exclude_completed'] = 'true'; }
    return params;
  }

  private async initEditState(): Promise<void> {
    this.isLoading = true;
    const uiRecipient = this.currentRecipient;
    if (!uiRecipient) return;

    const uiJourney = this.currentJourney;
    if (!uiJourney) return;

    // build params
    const params = this.buildFilterParams();

    // load checklists
    uiJourney.loadChecklists().then(() => {
      // if checklist found, select the first one
      if (uiJourney.checklists.length > 0) {
        // select first checklist
        this.selectedChecklistId = uiJourney.checklists[0].id;
        // load checklist
        uiJourney.loadChecklist(this.selectedChecklistId, params).then((successResponse) => {
          const checklist = successResponse.responseData as UIChecklist;
          this.editState = checklist.copyViewModel();
        });
      }
      
      this.isLoading = false;
    }).catch((uiError: UIError) => {
      this.isLoading = false;
      console.warn('Something unexpected happen when attempting to get checklist information', uiError);
    });
  }

  private async resetEditState(savedTask: UIChecklistTask|null = null, isNew = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      const uiRecipient = this.currentRecipient;
      if (!uiRecipient) return;

      const uiJourney = this.currentJourney;
      if (!uiJourney) return;

      // build params
      const params = this.buildFilterParams();

      // load checklist
      uiJourney.loadChecklist(this.selectedChecklistId, params).then((successResponse) => {
        const checklist = successResponse.responseData as UIChecklist;
        this.editState = checklist.copyViewModelAndRetainNewItems(this.editState);

        // if recovering from a saved task, check if it's going to be hidden by a filter and set the flag
        if (savedTask && savedTask.isTaskHiddenByFilters(this.filters)) {
          this.editState.setShowHiddenMessage(savedTask);
          // we can't rely on knowing if the task is new or existing so use a parameter
          if (!isNew) {
            // if existing, find where if originally appeared and show it there
            this.editState.setShowHiddenMessageNear(savedTask);
          }
        }
        // finished loading
        this.isLoading = false;
        resolve();
      }).catch((uiError: UIError) => {
        console.warn('Something unexpected happen when attempting to get checklist information', uiError);
        this.isLoading = false;
        reject();
      });
    });
  }

  createChecklistItem(group: UIChecklistGroup): void {
    group.showNew = true;
    group.resetShowHiddenMessage();
  }

  async successEdit(apiTask: APIChecklistTaskInterface, uiTask: UIChecklistTask, globalCounts: APIChecklistCounts, groupCounts: APIChecklistCounts): Promise<void> {
    if (uiTask.isTaskHiddenByFilters(this.filters)) {
      await this.resetEditState(uiTask);
    } else {
      this.editState.updateCounts(globalCounts);
      this.editState.groups.map((group: UIChecklistGroup) => {
        if (group.id === uiTask.group_id) {
          group.updateCounts(groupCounts);
          group.tasks.map((task: UIChecklistTask) => {
            if (task.id === uiTask.id) {
              task.replaceWithAPIChecklistTask(apiTask);
            }
          });
        }
      });
    }
  }

  cancelEdit(task: UIChecklistTask): void {
    this.editState.groups.map((group: UIChecklistGroup) => {
      if (group.id === task.group_id) {
        group.tasks.map((_task: UIChecklistTask) => {
          if (_task.id === task.id) {
            _task.copyViewModel();
          }
        });
      }
    });
  }

  async successNewCreateAnother(apiTask: APIChecklistTaskInterface, uiTask: UIChecklistTask, globalCounts: APIChecklistCounts, groupCounts: APIChecklistCounts): Promise<void> {
    // Hide new form, reset previous task
    this.isSavingAndCreatingAnother = true;
    await this.resetEditState(uiTask, true);

    // Load previous task into an edit form, and show success indication
    // if found task, open it 
    const foundTask = this.editState.findTaskInChecklist(uiTask);
    if (foundTask) { 
      foundTask.toggle();

      // show fake success message, use timeout as element will not be ready in time
      setTimeout(() => {
        const ref: any = this.$refs[foundTask.getTaskReferenceId];
        if (ref && ref.length > 0) {
          ref[0].showFakeSuccess();
        }
      }, 150);
    }

    // if not the hidden banner will show using group-level 'showHiddenMessage'
    // i.e. specific edge case where previous task is excluded by filters

    // in both cases re-open and reset the new form
    const foundGroup = this.editState.findGroupById(uiTask.group_id);
    if (!foundGroup) { return; }
    foundGroup.showNew = true;
    foundGroup.newTask = new UIChecklistTask();

    // show new form
    setTimeout(() => {
      this.isSavingAndCreatingAnother = false;
      const refId = foundGroup.newTask;
      const ref: any = this.$refs[refId.getTaskReferenceId];
      if (ref && ref.length > 0) {
        ref[0].resetFormErrors();
        ref[0].resetSaveToolbar();
        ref[0].handleSuccessOutcome();
      }
    }, 150);

    this.editState.updateCounts(globalCounts);
    foundGroup.updateCounts(groupCounts);
  }

  // apiTask fully created task
  // uiTask old temporary task
  async successNew(apiTask: APIChecklistTaskInterface, uiTask: UIChecklistTask, globalCounts: APIChecklistCounts, groupCounts: APIChecklistCounts): Promise<void> {
    await this.resetEditState(uiTask, true);

    // if found task, open it 
    const successfullyCreatedTask = new UIChecklistTask(apiTask);
    const foundTask = this.editState.findTaskInChecklist(successfullyCreatedTask);

    if (foundTask) { 
      foundTask.toggle();

      // show fake success message, use timeout as element will not be ready in time
      setTimeout(() => {
        const ref: any = this.$refs[foundTask.getTaskReferenceId];
        if (ref.length > 0) {
          ref[0].showFakeSuccess();
          ref[0].handleSuccessOutcome();
        }
      }, 150);
    }

    // if not the hidden banner will show

    // in both cases close the new form
    const foundGroup = this.editState.findGroupById(uiTask.group_id);
    if (!foundGroup) { return; }
    foundGroup.showNew = false;

    this.editState.updateCounts(globalCounts);
    foundGroup.updateCounts(groupCounts);
  }

  cancelNew(task: UIChecklistTask): void {
    this.editState.groups.map((group: UIChecklistGroup) => {
      if (group.id === task.group_id) {
        group.showNew = false;
      }
    });
  }

  public toggleGroup(group_id: string): void {
    if (!group_id) return;
    this.editState.groups.map((group: UIChecklistGroup) => {
      if (group.id == group_id) { 
        group.active = !group.active;
      }
    });
  }

  public toggleTask(group_id: string, task_id: string): void {
    if (!group_id) return;
    if (!task_id) return;

    this.editState.groups.map((group: UIChecklistGroup) => {
      if (group.id == group_id) {
        group.tasks.map((task: UIChecklistTask) => {
          if (task.id == task_id) {
            task.active = !task.active;
          }
        }); 
      }
    });
  }
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
