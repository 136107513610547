import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "table table-bordered table-hover" }
const _hoisted_3 = {
  rowspan: "2",
  scope: "col",
  width: "10%"
}
const _hoisted_4 = {
  rowspan: "2",
  scope: "col",
  class: "text-center reduce-width",
  width: "10%"
}
const _hoisted_5 = {
  rowspan: "2",
  scope: "col",
  class: "text-center reduce-width",
  width: "10%"
}
const _hoisted_6 = {
  rowspan: "2",
  scope: "col",
  class: "text-center middle-width",
  width: "10%"
}
const _hoisted_7 = {
  rowspan: "2",
  scope: "col",
  class: "text-center",
  width: "10%"
}
const _hoisted_8 = {
  rowspan: "2",
  scope: "col",
  class: "text-center"
}
const _hoisted_9 = {
  key: 0,
  rowspan: "2",
  scope: "col",
  class: "text-center"
}
const _hoisted_10 = {
  colspan: "4",
  scope: "col",
  class: "text-center"
}
const _hoisted_11 = {
  scope: "col",
  class: "text-center date-width"
}
const _hoisted_12 = {
  scope: "col",
  class: "text-center"
}
const _hoisted_13 = {
  scope: "col",
  class: "text-center date-width"
}
const _hoisted_14 = {
  scope: "col",
  class: "text-center"
}
const _hoisted_15 = {
  key: 0,
  colspan: "100%",
  class: "text-center"
}
const _hoisted_16 = {
  class: "td-check reduce-width",
  width: "10%"
}
const _hoisted_17 = {
  class: "td-check reduce-width",
  width: "10%"
}
const _hoisted_18 = {
  class: "td-check middle-width",
  width: "10%"
}
const _hoisted_19 = { width: "10%" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 0,
  class: "table table-bordered table-hover"
}
const _hoisted_22 = {
  rowspan: "2",
  scope: "col",
  width: "10%"
}
const _hoisted_23 = {
  rowspan: "2",
  scope: "col",
  class: "text-center reduce-width"
}
const _hoisted_24 = {
  rowspan: "2",
  scope: "col",
  class: "text-center"
}
const _hoisted_25 = {
  colspan: "2",
  scope: "col",
  class: "text-center"
}
const _hoisted_26 = {
  scope: "col",
  class: "text-center"
}
const _hoisted_27 = {
  scope: "col",
  class: "text-center"
}
const _hoisted_28 = { class: "td-check reduce-width" }
const _hoisted_29 = { class: "modal-footer-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_table_checkbox_input = _resolveComponent("table-checkbox-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_other_input = _resolveComponent("select-other-input")!
  const _component_modal_section = _resolveComponent("modal-section")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: `${_ctx.$t('organ_recovery_data')}`,
    "sub-section-id": "organ-recovery-data",
    onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.savePatch())),
    "save-button-text": `${_ctx.$t('save_recovery_details')}`,
    saveButton: _ctx.canSave,
    disabled: !_ctx.canSave,
    ref: "saveOrganRecoveryData",
    onLoaded: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loaded()))
  }, _createSlots({ _: 2 }, [
    (_ctx.editState)
      ? {
          name: "contents",
          fn: _withCtx(() => [
            _createElementVNode("fieldset", {
              disabled: !_ctx.canSave,
              class: _normalizeClass(["zero-min-width", { active: _ctx.donorConsentedOrganRecovery.length > 0 }])
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("table", _hoisted_2, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('recovery_organ')), 1),
                      _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('recovery_recovered')), 1),
                      _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t('recovery_vivo')), 1),
                      _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('recovery_vessels_recovered')), 1),
                      _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('recovery_transplant_destination')), 1),
                      _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('recovery_outofprovince_transplant_destination')), 1),
                      (_ctx.deceasedDonor.consented_for_research)
                        ? (_openBlock(), _createElementBlock("th", _hoisted_9, _toDisplayString(_ctx.$t('recovery_for_research_destination')), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('recovery_organ_delivery_status')), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t('recovery_shipped')), 1),
                      _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t('recovery_shipping_method')), 1),
                      _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$t('recovery_shipping_delivered')), 1),
                      _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t('recovery_shipping_received_by')), 1)
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_ctx.donorConsentedOrganRecovery.length === 0)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['far', 'exclamation-circle'],
                            "fixed-width": ""
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('recovery_no_donations')), 1)
                        ]))
                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.donorConsentedOrganRecovery, (rowName, idx) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (_ctx.editState[rowName.organ_donation_id])
                              ? (_openBlock(), _createElementBlock("tr", { key: idx }, [
                                  _createElementVNode("th", null, _toDisplayString(rowName.label), 1),
                                  _createElementVNode("td", _hoisted_16, [
                                    _createVNode(_component_table_checkbox_input, {
                                      "input-id": `or-recovered-${rowName.organ_donation_id}`,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].recovered,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].recovered) = $event),
                                      label: _ctx.$t('toggle_selection_recovered') + rowName.label + _ctx.$t('for_cooler_sheet'),
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label", "disabled"])
                                  ]),
                                  _createElementVNode("td", _hoisted_17, [
                                    _createVNode(_component_table_checkbox_input, {
                                      "input-id": `or-ex-vivo-used-${rowName.organ_donation_id}`,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].exVivoUsed,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].exVivoUsed) = $event),
                                      label: _ctx.$t('toggle_selection_ex_vivo') + rowName.label + _ctx.$t('for_cooler_sheet'),
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label", "disabled"])
                                  ]),
                                  _createElementVNode("td", _hoisted_18, [
                                    _createVNode(_component_table_checkbox_input, {
                                      "input-id": `or-vessels-recovered-transplant-${rowName.organ_donation_id}`,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].vesselsRecoveredForTransplant,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].vesselsRecoveredForTransplant) = $event),
                                      label: _ctx.$t('toggle_selection_vessels') + rowName.label + _ctx.$t('for_cooler_sheet'),
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, null, 8, ["input-id", "modelValue", "onUpdate:modelValue", "label", "disabled"])
                                  ]),
                                  _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.editState[rowName.organ_donation_id].transplantDestinationIdentifier), 1),
                                  _createElementVNode("td", null, [
                                    _createVNode(_component_select_input, {
                                      selectId: `or-oop-transplant-destination-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('drop_down_for') + rowName.organ_donation_id + _ctx.$t('out_of_province'),
                                      hideLabel: true,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].oopTransplantDestination,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].oopTransplantDestination) = $event),
                                      options: _ctx.outOfProvinceTransplantOptions,
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, null, 8, ["selectId", "name", "modelValue", "onUpdate:modelValue", "options", "disabled"])
                                  ]),
                                  (_ctx.deceasedDonor.consented_for_research)
                                    ? (_openBlock(), _createElementBlock("td", _hoisted_20, [
                                        _createVNode(_component_select_input, {
                                          selectId: `or-research-destination-${rowName.organ_donation_id}`,
                                          name: _ctx.$t('drop_down_for') + rowName.label + _ctx.$t('recovered_for_research'),
                                          hideLabel: true,
                                          modelValue: _ctx.editState[rowName.organ_donation_id].recoveredResearchDestination,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].recoveredResearchDestination) = $event),
                                          options: _ctx.hospitalOptions(rowName.organ_code),
                                          disabled: !_ctx.isGroupWriteable('donor_recovery')
                                        }, null, 8, ["selectId", "name", "modelValue", "onUpdate:modelValue", "options", "disabled"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("td", null, [
                                    _createVNode(_component_date_input, {
                                      inputId: `or-shipped-date-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('date_field_for') + rowName.label + _ctx.$t('organ_delivery_status_date_shipped'),
                                      direction: "left",
                                      class: "date-width",
                                      hideLabel: true,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].odsShippedDate,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsShippedDate) = $event),
                                      rules: { required: _ctx.editState[rowName.organ_donation_id].odsShippedTime },
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue", "rules", "disabled"]),
                                    _createVNode(_component_time_input, {
                                      inputId: `or-shipped-time-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('time_field_for') + rowName.label + _ctx.$t('organ_delivery_status_time_shipped'),
                                      hideLabel: true,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].odsShippedTime,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsShippedTime) = $event),
                                      rules: { required: _ctx.editState[rowName.organ_donation_id].odsShippedDate },
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue", "rules", "disabled"]),
                                    (_ctx.isHeartOfferOop(rowName.organ_donation_id))
                                      ? (_openBlock(), _createBlock(_component_checkbox_input, {
                                          key: 0,
                                          inputId: `or-export-organ-${idx}`,
                                          name: _ctx.$t('hsh_oop_export'),
                                          label: _ctx.$t('hsh_oop_export'),
                                          modelValue: _ctx.editState[rowName.organ_donation_id].exportOop,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].exportOop) = $event),
                                          disabled: !_ctx.isGroupWriteable('donor_recovery')
                                        }, null, 8, ["inputId", "name", "label", "modelValue", "onUpdate:modelValue", "disabled"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.isKidneyOfferOop(rowName.organ_donation_id))
                                      ? (_openBlock(), _createBlock(_component_checkbox_input, {
                                          key: 1,
                                          inputId: `or-export-organ-${idx}`,
                                          name: _ctx.$t('hsp_oop_export'),
                                          label: _ctx.$t('hsp_oop_export'),
                                          modelValue: _ctx.editState[rowName.organ_donation_id].exportOop,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].exportOop) = $event),
                                          disabled: !_ctx.isGroupWriteable('donor_recovery')
                                        }, null, 8, ["inputId", "name", "label", "modelValue", "onUpdate:modelValue", "disabled"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createVNode(_component_select_other_input, {
                                      selectId: `or-shipping-method-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('drop_down_for') + rowName.label + _ctx.$t('shipping_method'),
                                      hideLabel: true,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].odsShippingMethod,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsShippingMethod) = $event),
                                      options: _ctx.OrganDeliveryStatusLookup,
                                      stackInputs: true,
                                      colStyling: "form-group selectWithOther col-12",
                                      onChange: ($event: any) => (_ctx.clearShippingMethodOther(rowName.organ_donation_id)),
                                      disabled: !_ctx.isGroupWriteable('donor_recovery')
                                    }, {
                                      other: _withCtx(() => [
                                        _createVNode(_component_text_input, {
                                          "input-id": `or-shipping-method-other-${rowName.organ_donation_id}`,
                                          name: _ctx.$t('text_field_for') + rowName.label + _ctx.$t('shipping_method_other'),
                                          hideLabel: true,
                                          modelValue: _ctx.editState[rowName.organ_donation_id].odsShippingMethodOther,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsShippingMethodOther) = $event),
                                          disabled: !_ctx.isGroupWriteable('donor_recovery')
                                        }, null, 8, ["input-id", "name", "modelValue", "onUpdate:modelValue", "disabled"])
                                      ]),
                                      default: _withCtx(() => [
                                        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
                                      ]),
                                      _: 2
                                    }, 1032, ["selectId", "name", "modelValue", "onUpdate:modelValue", "options", "onChange", "disabled"])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createVNode(_component_date_input, {
                                      inputId: `or-delivered-date-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('date_field_for') + rowName.label + _ctx.$t('organ_delivery_status_date_delivered'),
                                      hideLabel: true,
                                      direction: "left",
                                      class: "date-width",
                                      rules: { required: _ctx.editState[rowName.organ_donation_id].odsDeliveredTime },
                                      modelValue: _ctx.editState[rowName.organ_donation_id].odsDeliveredDate,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsDeliveredDate) = $event),
                                      disabled: !_ctx.isGroupWriteable('donor_recovery_date')
                                    }, null, 8, ["inputId", "name", "rules", "modelValue", "onUpdate:modelValue", "disabled"]),
                                    _createVNode(_component_time_input, {
                                      inputId: `or-delivered-time-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('time_field_for') + rowName.label + _ctx.$t('organ_delivery_status_time_delivered'),
                                      hideLabel: true,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].odsDeliveredTime,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsDeliveredTime) = $event),
                                      rules: { required: _ctx.editState[rowName.organ_donation_id].odsDeliveredTime },
                                      disabled: !_ctx.isGroupWriteable('donor_recovery_date')
                                    }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue", "rules", "disabled"])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createVNode(_component_text_input, {
                                      inputId: `or-received-by-${rowName.organ_donation_id}`,
                                      name: _ctx.$t('text_field_for') + rowName.label + _ctx.$t('organ_delivery_received_by'),
                                      hideLabel: true,
                                      modelValue: _ctx.editState[rowName.organ_donation_id].odsReceivedBy,
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.editState[rowName.organ_donation_id].odsReceivedBy) = $event),
                                      disabled: !_ctx.isGroupWriteable('donor_recovery_date')
                                    }, null, 8, ["inputId", "name", "modelValue", "onUpdate:modelValue", "disabled"])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 256))
                  ])
                ])
              ]),
              _createElementVNode("div", null, [
                (_ctx.vesselsForBankingId)
                  ? (_openBlock(), _createElementBlock("table", _hoisted_21, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t('recovery_organ')), 1),
                          _createElementVNode("th", _hoisted_23, _toDisplayString(_ctx.$t('recovery_recovered')), 1),
                          _createElementVNode("th", _hoisted_24, _toDisplayString(_ctx.$t('recovery_destination')), 1),
                          _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t('recovery_organ_transport_status')), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t('recovery_organ_transport_shipped')), 1),
                          _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.$t('recovery_organ_transport_delivered')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('recovery_vessels_for_banking')), 1),
                          _createElementVNode("td", _hoisted_28, [
                            _createVNode(_component_table_checkbox_input, {
                              "input-id": "or-vessels-banking-recovered",
                              label: _ctx.$t('toggle_selection_vessels_banking'),
                              modelValue: _ctx.editState[_ctx.vesselsForBankingId].recovered,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState[_ctx.vesselsForBankingId].recovered) = $event)),
                              disabled: !_ctx.isGroupWriteable('donor_recovery')
                            }, null, 8, ["label", "modelValue", "disabled"])
                          ]),
                          _createElementVNode("td", null, [
                            _createVNode(_component_select_input, {
                              selectId: "or-vessels-banking-destination`",
                              name: _ctx.$t('drop_down_vessels_banking'),
                              hideLabel: true,
                              options: _ctx.ontarioTransplantOptions,
                              modelValue: _ctx.editState[_ctx.vesselsForBankingId].transplantDestination,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState[_ctx.vesselsForBankingId].transplantDestination) = $event)),
                              disabled: !_ctx.isGroupWriteable('donor_recovery')
                            }, null, 8, ["name", "options", "modelValue", "disabled"])
                          ]),
                          _createElementVNode("td", null, [
                            _createVNode(_component_date_input, {
                              "input-id": "or-vessels-banking-shipped-date",
                              validationId: `or-shipped-date-${_ctx.vesselsForBankingId}`,
                              name: _ctx.$t('date_field_transport_shipped_date'),
                              hideLabel: true,
                              modelValue: _ctx.editState[_ctx.vesselsForBankingId].odsShippedDate,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState[_ctx.vesselsForBankingId].odsShippedDate) = $event)),
                              rules: { required: _ctx.editState[_ctx.vesselsForBankingId].odsShippedTime },
                              disabled: !_ctx.isGroupWriteable('donor_recovery')
                            }, null, 8, ["validationId", "name", "modelValue", "rules", "disabled"]),
                            _createVNode(_component_time_input, {
                              "input-id": "or-vessels-banking-shipped-time",
                              validationId: `or-shipped-time-${_ctx.vesselsForBankingId}`,
                              name: _ctx.$t('time_field_transport_shipped_time'),
                              hideLabel: true,
                              modelValue: _ctx.editState[_ctx.vesselsForBankingId].odsShippedTime,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState[_ctx.vesselsForBankingId].odsShippedTime) = $event)),
                              rules: { required: _ctx.editState[_ctx.vesselsForBankingId].odsShippedDate },
                              disabled: !_ctx.isGroupWriteable('donor_recovery')
                            }, null, 8, ["validationId", "name", "modelValue", "rules", "disabled"])
                          ]),
                          _createElementVNode("td", null, [
                            _createVNode(_component_date_input, {
                              "input-id": "or-vessels-banking-delivered-date",
                              validationId: `or-delivered-date-${_ctx.vesselsForBankingId}`,
                              name: _ctx.$t('date_field_transport_delivered_date'),
                              hideLabel: true,
                              modelValue: _ctx.editState[_ctx.vesselsForBankingId].odsDeliveredDate,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState[_ctx.vesselsForBankingId].odsDeliveredDate) = $event)),
                              rules: { required: _ctx.editState[_ctx.vesselsForBankingId].odsDeliveredTime },
                              disabled: !_ctx.isGroupWriteable('donor_recovery')
                            }, null, 8, ["validationId", "name", "modelValue", "rules", "disabled"]),
                            _createVNode(_component_time_input, {
                              "input-id": "or-vessels-banking-delivered-time",
                              validationId: `or-delivered-time-${_ctx.vesselsForBankingId}`,
                              name: _ctx.$t('time_field_transport_delivered_time'),
                              hideLabel: true,
                              modelValue: _ctx.editState[_ctx.vesselsForBankingId].odsDeliveredTime,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState[_ctx.vesselsForBankingId].odsDeliveredTime) = $event)),
                              rules: { required: _ctx.editState[_ctx.vesselsForBankingId].odsDeliveredDate },
                              disabled: !_ctx.isGroupWriteable('donor_recovery')
                            }, null, 8, ["validationId", "name", "modelValue", "rules", "disabled"])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.editState)
                ? (_openBlock(), _createBlock(_component_modal_section, {
                    key: 0,
                    modalId: "export-outcome-modal",
                    ref: "exportOutcomeModal",
                    modalClass: `modal-content ${_ctx.modalState.organShipModal ? _ctx.modalState.organShipModal.style : ''}`,
                    centered: true
                  }, _createSlots({
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('export_to_ctr_modal_title')), 1)
                    ]),
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-success",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleModal()))
                        }, _toDisplayString(_ctx.$t('ok')), 1)
                      ])
                    ]),
                    _: 2
                  }, [
                    (_ctx.modalState.organShipModal)
                      ? {
                          name: "body",
                          fn: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.modalState.organShipModal.body), 1)
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["modalClass"]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "save-button-text", "saveButton", "disabled"]))
}