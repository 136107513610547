import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content-wrap"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav list-patients action-row" }
const _hoisted_4 = { class: "ml-auto" }
const _hoisted_5 = { class: "action-row d-flex" }
const _hoisted_6 = { class: "element-left" }
const _hoisted_7 = {
  key: 0,
  class: "highlight-scroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_top = _resolveComponent("page-top")!
  const _component_loading_list_view = _resolveComponent("loading-list-view")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_table_filter = _resolveComponent("table-filter")!
  const _component_column_config = _resolveComponent("column-config")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_full_page_list_layout = _resolveComponent("full-page-list-layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
      ]),
      _: 1
    }),
    (!_ctx.recipients)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_loading_list_view)
          ])
        ]))
      : (_openBlock(), _createBlock(_component_full_page_list_layout, { key: 1 }, {
          content: _withCtx(() => [
            _createElementVNode("nav", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.isImportPatientsEnabled)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_router_link, {
                        class: _normalizeClass(["btn btn-success", { disabled: !_ctx.canCreate }]),
                        to: { name: 'import' }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('patient_data_uploads')), 1)
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      _cache[5] || (_cache[5] = _createTextVNode("   "))
                    ], 64))
                  : _createCommentVNode("", true),
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["btn btn-primary", { disabled: !_ctx.canCreate }]),
                  to: { name: 'new-recipient' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('add_new_recipient')), 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ])
            ]),
            _createVNode(_component_table_filter, { onFilterList: _ctx.setSearchParams }, null, 8, ["onFilterList"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.$t("system_returned")) + " ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.recipients?.count || 0), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('recipients')), 1)
              ]),
              (_ctx.columnsFilter)
                ? (_openBlock(), _createBlock(_component_column_config, {
                    key: 0,
                    class: "ml-auto",
                    columnKey: "recipients",
                    "config-id": "-list-columns",
                    options: _ctx.recipientsColumns,
                    hiddenOptions: _ctx.hiddenOptions,
                    modelValue: _ctx.columnsFilter.selectedColumns,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columnsFilter.selectedColumns) = $event))
                  }, null, 8, ["options", "hiddenOptions", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isOverflowed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createTextVNode(_toDisplayString(_ctx.$t('scroll_right_to_see_more')) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'chevron-right'],
                    class: "nav-caret",
                    "fixed-width": ""
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_table_list, {
              "table-id": "list-recipient",
              horizontalScroll: true,
              listingView: true,
              mode: "remote",
              showTopScrollBar: true,
              firstColumnTabbable: true,
              "total-records": _ctx.recipients?.count || 0,
              "table-config": _ctx.recipientTableConfig,
              highlightSelection: true,
              onOnPageNumber: _cache[1] || (_cache[1] = ($event: any) => {_ctx.onPageNumber($event); _ctx.loadData()}),
              onOnPageSize: _cache[2] || (_cache[2] = ($event: any) => {_ctx.onPageSize($event); _ctx.loadData()}),
              onOnSortChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterList($event))),
              onTableRowClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectRecipient($event))),
              isLoading: _ctx.isLoading || !_ctx.recipients,
              jumpToPage: true
            }, null, 8, ["total-records", "table-config", "isLoading"])
          ]),
          _: 1
        }))
  ]))
}