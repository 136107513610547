<template>
  <!-- Form layout -->
  <validation-observer ref="validations">
    <form-layout
      :disabled="!enableForm"
      form-id="post-transplant-follow-up-form"
    >
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="selection.isNew" class="legend-title">
            {{$t('new_follow_up')}}
          </h5>
          <h5 v-else class="legend-title has-guiding-text">
            {{$t('selected_follow_up')}}
          </h5>
        </legend>
      </template>

      <template v-slot:contents>
        <div class="row">
          <div class="standard-form-group">
            <date-input
              ruleKey="post_transplant_follow_ups.entry_date"
              :name="$t('entry_date')"
              input-id="post_transplant_follow_up_entry_date"
              v-model="editState.entry_date"
              :disabled="true"
            />
          </div>
          <div class="standard-form-group">
            <select-input
              ruleKey="post_transplant_follow_ups.follow_up_type"
              select-id="post_transplant_follow_up_type"
              :name="$t('follow_up_type')"
              :options="followUpTypeLookup"
              v-model="editState.follow_up_type"
            />
          </div>
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.years_since_transplant')">
            <text-input
              ruleKey="post_transplant_follow_ups.years_since_transplant"
              :name="$t('years_since_transplant')"
              input-id="post_transplant_follow_up_years_since_transplant"
              v-model="editState.years_since_transplant"
              :cross-values="{ follow_up_type: editState.follow_up_type }"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              ruleKey="post_transplant_follow_ups.recipient_status_code"
              select-id="post_transplant_follow_up_recipient_status_code"
              :name="$t('recipient_status_code')"
              :options="followUpRecipientStatusLookup"
              v-model="editState.recipient_status_code"
            />
          </div>
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.follow_up_date')">
            <date-input
              ruleKey="post_transplant_follow_ups.follow_up_date"
              :name="$t('date_last_seen')"
              input-id="post_transplant_follow_up_follow_up_date"
              v-model="editState.follow_up_date"
              :cross-values="{ recipient_status_code: editState.recipient_status_code }"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-form-group">
            <select-input
              ruleKey="post_transplant_follow_ups.graft_status_code"
              select-id="post_transplant_follow_up_graft_status_code"
              :name="$t('graft_status_code')"
              :options="followUpGraftStatusLookup"
              v-model="editState.graft_status_code"
              :cross-values="{ recipient_status_code: editState.recipient_status_code }"
            />
          </div>
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.graft_rejection_date')">
            <date-input
              ruleKey="post_transplant_follow_ups.graft_rejection_date"
              :name="$t('graft_rejection_date')"
              input-id="post_transplant_follow_up_graft_rejection_date"
              v-model="editState.graft_rejection_date"
              :cross-values="{ graft_status_code: editState.graft_status_code }"
            />
          </div>
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.donor_specific_antibody')">
            <select-input
              ruleKey="post_transplant_follow_ups.donor_specific_antibody"
              select-id="post_transplant_follow_up_donor_specific_antibody"
              :name="$t('donor_specific_antibody')"
              :options="followUpDonorSpecificAntibodyLookup"
              v-model="editState.donor_specific_antibody"
              :cross-values="{ graft_status_code: editState.graft_status_code }"
            />
          </div>

          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.graft_failure_date')">
            <date-input
              ruleKey="post_transplant_follow_ups.graft_failure_date"
              :name="$t('graft_failure_date')"
              input-id="post_transplant_follow_up_graft_failure_date"
              v-model="editState.graft_failure_date"
              :cross-values="{ graft_status_code: editState.graft_status_code }"
            />
          </div>
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.graft_failure_cause_code')">
            <select-input
              ruleKey="post_transplant_follow_ups.graft_failure_cause_code"
              select-id="post_transplant_follow_up_graft_failure_cause_code"
              :name="$t('graft_failure_cause_code')"
              :options="followUpGraftFailureCauseLookup"
              v-model="editState.graft_failure_cause_code"
              :cross-values="{ graft_status_code: editState.graft_status_code }"
            />
          </div>
        </div>

        <div class="row">
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.lost_to_follow_up_date')">
            <date-input
              ruleKey="post_transplant_follow_ups.lost_to_follow_up_date"
              :name="$t('lost_to_follow_up_date')"
              input-id="post_transplant_follow_up_lost_to_follow_up_date"
              v-model="editState.lost_to_follow_up_date"
              :cross-values="{ recipient_status_code: editState.recipient_status_code }"
            />
          </div>
          <div class="standard-form-group"
            v-if="onlyIfValue(editState, 'post_transplant_follow_ups.lost_to_follow_up_reason')">
            <select-input
              ruleKey="post_transplant_follow_ups.lost_to_follow_up_reason"
              select-id="post_transplant_follow_up_lost_to_follow_up_reason"
              :name="$t('lost_to_follow_up_reason')"
              :options="followUpLossOfContactReasonLookup"
              v-model="editState.lost_to_follow_up_reason"
              :cross-values="{ recipient_status_code: editState.recipient_status_code }"
            />
          </div>
        </div>

      </template>

      <template v-slot:save>
        <save-toolbar
          :show="showSaveToolbar"
          ref="saveToolbar"
          :label="$t('save_follow_up')"
          :cancelButton="true"
          @save="handleSave"
          @cancel="handleCancel"
        />
      </template>
    </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Prop, Watch, mixins } from "vue-facing-decorator";
import FormLayout from '@/components/shared/FormLayout.vue';
import { i18nMessages } from "@/i18n";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { UIPostTransplantFollowUp } from '@/UIModels/journey/postTransplantFollowUp';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { SaveResult, APIPermittedActions } from "@/types";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { parseFormErrors } from '@/utils';
import { IdLookup } from '@/store/validations/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import { GenericCodeValue } from '@/store/types';
import { RulesQuery } from '@/types';
import { Rules } from '@/store/validations/types';

@Component({
  components: {
    FormLayout,
    SaveToolbar,
    DateInput,
    TextInput,
    SelectInput
  },
  ...i18nMessages([
    require('./../_locales/common.json'),
    require('./../_locales/PostTransplantFollowUp.json'),
  ]),
  emits: [
    'success',
  ],
})

export default class PostTransplantFollowUpForm extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.follow_up_donor_specific_antibody) private followUpDonorSpecificAntibodyLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_graft_failure_cause) private followUpGraftFailureCauseLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_graft_status) private followUpGraftStatusLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_loss_of_contact_reason) private followUpLossOfContactReasonLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_provider) private followUpProviderLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_recipient_status) private followUpRecipientStatusLookup!: GenericCodeValue[];
  @State(state => state.lookups.follow_up_type) private followUpTypeLookup!: GenericCodeValue[];

  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('onlyIfValue', { namespace: 'validations' }) private onlyIfValue!: (editState: any, targetField: string) => boolean;

  // Selection instance
  @Prop({ required: true }) selection!: UIListFormSelection;

  // Editable view model for the form
  private editState = new UIPostTransplantFollowUp();
  private permittedActions: string[] = [];

  private isLoadingForm = false;
  private isSuccessfullySaving = false;

  get uiJourney(): UIJourney {
    return useCurrentPageStore().currentJourney as UIJourney;
  }

  // Can we enable the form?
  get enableForm(): boolean {
    if (this.currentJourney.isNew) return false;
    return this.permittedActionsAllowCreateOrUpdate && this.uiJourney.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    if (this.currentJourney.isNew) return false;
    return this.permittedActionsAllowCreateOrUpdate && this.uiJourney.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    if (this.currentJourney.isNew) return false;
    return this.permittedActionsAllowCreateOrUpdate && this.uiJourney.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    if (!this.editState) return false;
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Which Journey view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  // Process change to "selection" prop
  // NOTE: this will initialize Follow Up Form
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(_newSelection: UIPostTransplantFollowUp, oldSelection?: UIPostTransplantFollowUp): void {
    if (!oldSelection) return;

    this.resetEditState();
    this.resetFormErrors();

    // Reset save toolbar (unless it is showing success indicator)
    if (!this.isSuccessfullySaving) this.resetSaveToolbar();
    this.isSuccessfullySaving = false;
  }

  // Initialize form edit state by requesting a copy of the "selection" view model
  private async resetEditState(): Promise<void> {
    this.isLoadingForm = true;

    const newEditState = new UIPostTransplantFollowUp();
    const query = this.selection.isNew ? this.ruleQueryDefaults : this.ruleQueryEditState;
    await newEditState.load({ uiJourney: this.currentJourney, id: this.selection.id as string });

    this.editState = newEditState;
    this.isLoadingForm = false;
  }

  // What validation rule query parameters are needed based on lookup defaults?
  get ruleQueryDefaults(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    const selectedFollowUpType = this.editState.follow_up_type;
    const selectedRecipientStatusCode = this.editState.recipient_status_code;
    const selectedGraftStatusCode = this.editState.graft_status_code;

    if (selectedFollowUpType) params['follow_up_type'] = selectedFollowUpType;
    if (selectedRecipientStatusCode) params['recipient_status_code'] = selectedRecipientStatusCode;
    if (selectedGraftStatusCode) params['graft_status_code'] = selectedGraftStatusCode;

    return params;
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams: any = { 
      selected: this.editState,
      recipient: this.currentRecipient,
      journey: this.currentJourney
    };
    
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Clear selection
  private async handleSuccess(success: SaveResult): Promise<void> {
    this.isSuccessfullySaving = true;
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    this.$emit('success', success);
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    this.resetEditState();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveToolbar;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    this.resetEditState();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const mapping: IdLookup = {
      'post_transplant_follow_ups.entry_date'               : 'post_transplant_follow_up_entry_date',
      'post_transplant_follow_ups.follow_up_type'           : 'post_transplant_follow_up_type',
      'post_transplant_follow_ups.years_since_transplant'   : 'post_transplant_follow_up_years_since_transplant',
      'post_transplant_follow_ups.graft_status_code'        : 'post_transplant_follow_up_graft_status_code',
      'post_transplant_follow_ups.follow_up_date'           : 'post_transplant_follow_up_follow_up_date',
      'post_transplant_follow_ups.graft_rejection_date'     : 'post_transplant_follow_up_graft_rejection_date',
      'post_transplant_follow_ups.graft_rejection_category' : 'post_transplant_follow_up_graft_rejection_category',
      'post_transplant_follow_ups.graft_failure_date'       : 'post_transplant_follow_up_graft_failure_date',
      'post_transplant_follow_ups.graft_failure_cause_code' : 'post_transplant_follow_up_graft_failure_cause_code',
      'post_transplant_follow_ups.recipient_status_code'    : 'post_transplant_follow_up_recipient_status_code',
      'post_transplant_follow_ups.donor_specific_antibody'  : 'post_transplant_follow_up_donor_specific_antibody',
      'post_transplant_follow_ups.lost_to_follow_up_date'   : 'post_transplant_follow_up_lost_to_follow_up_date',
      'post_transplant_follow_ups.lost_to_follow_up_reason' : 'post_transplant_follow_up_lost_to_follow_up_reason',

    };
    return mapping;
  }
}
</script>

