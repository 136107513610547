import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "side-nav-container" }
const _hoisted_2 = { class: "nav-block-wrap" }
const _hoisted_3 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_side_nav_toolbar = _resolveComponent("side-nav-toolbar")!
  const _component_base_side_nav = _resolveComponent("base-side-nav")!

  return (_openBlock(), _createBlock(_component_base_side_nav, {
    class: _normalizeClass({ closed: _ctx.sideNavClosed })
  }, {
    toolbar: _withCtx(() => _cache[0] || (_cache[0] = [])),
    "side-nav-content": _withCtx(() => [
      _createVNode(_component_side_nav_toolbar, { onToggle: _ctx.toggleSideNav }, {
        "return-link": _withCtx(() => [
          _createVNode(_component_router_link, {
            class: "nav-link no-scroll",
            title: "Return to previous page",
            "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
            to: { name: 'administration' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'arrow-left'],
                "fixed-width": ""
              }),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, " Return to previous page ", -1))
            ]),
            _: 1
          }, 8, ["aria-label"])
        ]),
        _: 1
      }, 8, ["onToggle"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", {
            class: _normalizeClass(["nav nav-block flex-column", { 'active': true }])
          }, [
            _createElementVNode("li", _hoisted_3, [
              _createVNode(_component_router_link, {
                class: "nav-link no-scroll open",
                to: { name: 'profilePage' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'chevron-right'],
                    class: "nav-caret",
                    "fixed-width": ""
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("user_profile")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}